<div class="news">
  <div class="container">
    <div class="news__main">
      <app-content-title [label]="'HEADER.NEWSCHILD'|translate" class="app-block__head"></app-content-title>
      <div class="new__breadcrumb">
        <p><span routerLink="/">{{'COMMON.HOME'|translate}}</span> > <span>{{'HEADER.NEWS'|translate}}</span></p>
      </div>
      <div class="news__outstanding">
        <ng-container *ngFor="let blog of ableNews">
          <div routerLink="/news/{{blog.metaTitle}}" class="news__container">
            <img class="news__img" [src]="blog.image">
            <p class="news__title">{{blog.name}}</p>
            <p class="news__description">{{blog.description}}</p>
          </div>
        </ng-container>
      </div>

      <div class="slide-carousel">
        <div class="container">
          <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig"
            (init)="slickInit($event)" (breakpoint)="breakpoint($event)" (afterChange)="afterChange($event)"
            (beforeChange)="beforeChange($event)">
            <div ngxSlickItem *ngFor="let slide of ableNews" class="slide">
              <div class="slide-carousel-card" routerLink="/news/{{slide.metaTitle}}">
                <div class="slide__upper">
                  <img class="slide__img" [src]="slide.image ">
                </div>
              </div>
            </div>
          </ngx-slick-carousel>
        </div>
      </div>

      <div class="news__item">
        <ng-container *ngFor="let blog of ableNews">
          <div class="news-list__item" routerLink="/news/{{blog.metaTitle}}">
            <img class="news-list__img" [src]="blog.image">
            <p class="news-list__title">{{blog.name}}</p>
            <p class="news-list__description">{{blog.description}}</p>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
