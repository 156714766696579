import { now } from ".";

export const BSH_RESPONS = [
  { id: 1, value: 'goi_1', label: 'PRODUCTS.GOLD', label_api: 'BSH Gói Vàng' },
  { id: 2, value: 'goi_2', label: 'PRODUCTS.PLATIUM', label_api: 'BSH Gói Platinum' },
]

export const BSH_DURATIONS = [
  // { id: 1, value: '6', label: 'PRODUCTS.6_MONTH' },
  { id: 2, value: '12', label: 'PRODUCTS.12_MONTH' },
]

export const BSH_DYNAMIC_POLICY = [
  { id: 1, value: 'goi_1', val_1: '500.000', val_2: '5.000.000', val_3: '50.000.000', val_4: '50.000.000' },
  { id: 2, value: 'goi_2', val_1: '1.000.000', val_2: '10.000.000', val_3: '100.000.000', val_4: '100.000.000' },
]

export const BSH_FORM_TEMPLATE = {
  nv: 'BSH01',
  kieU_HD: 'G',
  ttoan: '',
  ten: '',
  cmt: '',
  files: {
    front: '',
    back: '',
  },
  nG_SINH: '',
  ngaY_HL: now,
  ngaY_KT: '',
  gioi: '',
  mobi: '',
  email: '',
  dchi: '',
  nguoiGioiThieu: '',
  tensanpham: 'Bảo hiểm trợ cấp nằm viện BSH',
  goisanpham: '',
  thoihan: '',
  iddangky: '',
  magioithieu: '',
  moiquanhe: '',
  user_Code: '',
}
