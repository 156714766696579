import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-usp-card',
  templateUrl: './usp-card.component.html',
  styleUrls: ['./usp-card.component.css']
})
export class UspCardComponent implements OnInit {
  @Input() icon = ''
  @Input() label = ''
  @Input() child = ''
  @Input() color = ''
  @Input() border = ''
  @Input() isBorder = false

  constructor() { }

  ngOnInit(): void {
  }

}
