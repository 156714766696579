import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ChangeContentService {

  constructor() { }
  onChangeContentLangToEn(url:string){
    switch(url){
      case '/news/kiem-tien-thuc-de':{
        url = '/news/earn-money-easily'
        break;
      }
      case '/news/nen-tang-san-bao-hiem-truc-tuyen-tai-viet-nam':{
        url = '/news/online-insurance-platform-in-vietnam'
        break;
      }
      case '/news/one-stop-shoping':{
        url = '/news/why-is-one-stop-shopping-so-hot'
        break;
      }
      case '/news/khoi-dau-moi':{
        url = '/news/new-start'
        break;
      }
      case '/news/tu-hao-vinh-du':{
        url = '/news/proud---honored'
        break;
      }
      case '/news/5-nam-1-chang-duong':{
        url = '/news/5-years---1-journey'
        break;
      }
      case '/news/cong-nghe-so-trong-giao-dich-bao-hiem-dau-tien-tai-viet-nam':{
        url = '/news/digital-technology-in-the-first-insurance-transaction-in-vietnam'
        break;
      }
    }
    return url
  }
  onChangeContentLangToVi(url:string){
    switch(url){
      case '/news/earn-money-easily':{
        url = '/news/kiem-tien-thuc-de'
        break;
      }
      case '/news/online-insurance-platform-in-vietnam':{
        url = '/news/nen-tang-san-bao-hiem-truc-tuyen-tai-viet-nam'
        break;
      }
      case '/news/why-is-one-stop-shopping-so-hot':{
        url = '/news/one-stop-shoping'
        break;
      }
      case '/news/new-start':{
        url = '/news/khoi-dau-moi'
        break;
      }
      case '/news/proud---honored':{
        url = '/news/tu-hao-vinh-du'
        break;
      }
      case '/news/5-years---1-journey':{
        url = '/news/5-nam-1-chang-duong'
        break;
      }
      case '/news/digital-technology-in-the-first-insurance-transaction-in-vietnam':{
        url = '/news/cong-nghe-so-trong-giao-dich-bao-hiem-dau-tien-tai-viet-nam'
        break;
      }
    }
    return url
  }
}
