<br><br>
<app-content-title [label]="'HEADER.POLICYCHILD3'|translate" class="app-block__head"></app-content-title>
<div class="policy">
    <p>{{'POLICY.1'|translate}}</p>
    <p>{{'POLICY.2'|translate}}</p>
    <p>{{'POLICY.3'|translate}}</p>
    <p>{{'POLICY.4'|translate}}</p>
    <p>{{'POLICY.5'|translate}}</p>
    <p>{{'POLICY.6'|translate}}</p>
    <p>{{'POLICY.7'|translate}}</p>
    <p>{{'POLICY.8'|translate}}</p>
    <p>{{'POLICY.9'|translate}}</p>
    <p>{{'POLICY.10'|translate}}</p>
    <p>{{'POLICY.11'|translate}}</p>
    <p>{{'POLICY.12'|translate}}</p>
    <p>{{'POLICY.13'|translate}}</p>
    <p>{{'POLICY.14'|translate}}</p>
    <p>{{'POLICY.15'|translate}}</p>
    <p>{{'POLICY.16'|translate}}</p>
    <p>{{'POLICY.17'|translate}}</p>
    <p>{{'POLICY.18'|translate}}</p>
    <p>{{'POLICY.19'|translate}}</p>
    <p>{{'POLICY.20'|translate}}</p>
    <p>{{'POLICY.21'|translate}}</p>
    <p>{{'POLICY.22'|translate}}</p>
    <p>{{'POLICY.23'|translate}}</p>
    <p>{{'POLICY.24'|translate}}</p>
    <p>{{'POLICY.25'|translate}}</p>
    <p>{{'POLICY.26'|translate}}</p>
    <p>{{'POLICY.27'|translate}}</p>
    <p>{{'POLICY.28'|translate}}</p>
    <p>{{'POLICY.29'|translate}}</p>
    <p>{{'POLICY.30'|translate}}</p>
    <p>{{'POLICY.31'|translate}}</p>
    <p>{{'POLICY.32'|translate}}</p>
    <p>{{'POLICY.33'|translate}}</p>
    <p>{{'POLICY.34'|translate}}</p>
    <p>{{'POLICY.35'|translate}}</p>
    <p>{{'POLICY.36'|translate}}</p>
    <p>{{'POLICY.37'|translate}}</p>
    <p>{{'POLICY.38'|translate}}</p>
    <p>{{'POLICY.39'|translate}}</p>
    <p>{{'POLICY.40'|translate}}</p>
    <p>{{'POLICY.41'|translate}}</p>
    <p>{{'POLICY.42'|translate}}</p>
    <p>{{'POLICY.43'|translate}}</p>
    <p>{{'POLICY.44'|translate}}</p>
    <p>{{'POLICY.45'|translate}}</p>
    <p>{{'POLICY.46'|translate}}</p>
    <p>{{'POLICY.47'|translate}}</p>
    <p>{{'POLICY.48'|translate}}</p>
    <p>{{'POLICY.49'|translate}}</p>
    <p>{{'POLICY.50'|translate}}</p>
    <p>{{'POLICY.51'|translate}}</p>
    <p>{{'POLICY.52'|translate}}</p>
    <p>{{'POLICY.53'|translate}}</p>
    <p>{{'POLICY.54'|translate}}</p>
    <p>{{'POLICY.55'|translate}}</p>
    <p>{{'POLICY.56'|translate}}</p>
    <p>{{'POLICY.57'|translate}}</p>
    <p>{{'POLICY.58'|translate}}</p>
    <p>{{'POLICY.59'|translate}}</p>
    <p>{{'POLICY.60'|translate}}</p>
    <p>{{'POLICY.61'|translate}}</p>
    <p>{{'POLICY.62'|translate}}</p>
    <p>{{'POLICY.63'|translate}}</p>
    <p>{{'POLICY.64'|translate}}</p>
    <p>{{'POLICY.65'|translate}}</p>
    <p>{{'POLICY.66'|translate}}</p>
    <p>{{'POLICY.67'|translate}}</p>
    <p>{{'POLICY.68'|translate}}</p>
    <p>{{'POLICY.69'|translate}}</p>
    <p>{{'POLICY.70'|translate}}</p>
    <span>{{'POLICY.71'|translate}}</span>
    <br><span>Email: <a href="mailto:support@finlife.com.vn">support@finlife.com.vn</a></span>
    <br><span>Hotline: <a href="tell:02888815818">02888815818</a></span>
    <br><span>Website: <a href="https://finme.vn/">https://finme.vn/</a></span>
    <p>{{'POLICY.72'|translate}}</p>
    <p>{{'POLICY.73'|translate}}</p>
    <p>{{'POLICY.70'|translate}}</p>
    <span>{{'POLICY.71'|translate}}</span>
    <br><span>Email: <a href="mailto:support@finlife.com.vn">support@finlife.com.vn</a></span>
    <br><span>Hotline: <a href="tell:02888815818">02888815818</a></span>
    <br><span>Website: <a href="https://finme.vn/">https://finme.vn/</a></span>
    <p>{{'POLICY.74'|translate}}</p>
    <p>{{'POLICY.75'|translate}}</p>
    <p>{{'POLICY.76'|translate}}</p>
    <p>{{'POLICY.77'|translate}}<a href="tell:02888815818">02888815818</a>{{'POLICY.78'|translate}}<a
            href="mailto:support@finlife.com.vn">support@finlife.com.vn</a>{{'POLICY.79'|translate}}</p>
    <p>{{'POLICY.80'|translate}}</p>
    <p>{{'POLICY.81'|translate}}</p>
    <p>{{'POLICY.82'|translate}}</p>
    <p>{{'POLICY.83'|translate}}</p>
    <p>{{'POLICY.84'|translate}}</p>
    <p>{{'POLICY.85'|translate}}</p>
    <p>{{'POLICY.86'|translate}}</p>
    <p>{{'POLICY.87'|translate}}</p>
    <p>{{'POLICY.88'|translate}}</p>
    <p>{{'POLICY.89'|translate}}</p>
    <p>{{'POLICY.90'|translate}}</p>
    <p>{{'POLICY.91'|translate}}</p>
    <p>{{'POLICY.92'|translate}}</p>
    <p>{{'POLICY.93'|translate}}</p>
    <p>{{'POLICY.94'|translate}}</p>
    <p>{{'POLICY.95'|translate}}</p>
    <p>{{'POLICY.96'|translate}}</p>
</div>