import { ApiService } from '@services/api.service';
import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { ITContent } from '@models/content.model';
import { ContentService } from '@services/content/content.service';
import { CONTENT_TEMPLATE } from 'src/utils/constant';
import { TranslateService } from '@ngx-translate/core';
import { ChangeContentService } from '@services/lang/change-content.service';

@Component({
  selector: 'app-content-detail',
  templateUrl: './content-detail.component.html',
  styleUrls: ['./content-detail.component.css']
})
export class ContentDetailComponent implements OnInit {
  lastestBlogs: ITContent[] = []
  blog: ITContent = {
    ...CONTENT_TEMPLATE
  }
  CurrentLang:any;
  ableNews:any = []
  url: any = ''
  constructor(
    private titleService: Title,
    private _service: ContentService,
    private router: Router,
    private meta: Meta,
    private ChangeService: ChangeContentService,
    public _translate: TranslateService
  ) {
    this.CurrentLang = _translate.getDefaultLang();
    _translate.onLangChange.subscribe((e:any)=>{
      this.CurrentLang= e.lang;
      if (this.CurrentLang=='vi'){
        router.navigate([ChangeService.onChangeContentLangToVi(router.url)]);
        this.getContent(ChangeService.onChangeContentLangToVi(router.url).slice(6))
      }
      else {
        router.navigate([ChangeService.onChangeContentLangToEn(router.url)]);
        this.getContent(ChangeService.onChangeContentLangToEn(router.url).slice(6))
      }
      this.getContents()
    })
    this.router.events.subscribe(event => {

      if (event instanceof NavigationEnd) {
        this.url = event.url
        if ((this.url.includes('-easily')||this.url.includes('insurance')||this.url.includes('why-is')||this.url.includes('-start')||this.url.includes('proud')||this.url.includes('journey'))&&this.CurrentLang=='vi'){
          this._translate.setDefaultLang('en');
          this._translate.use('en');
          this.CurrentLang = 'en';
        }
        if ((this.url.includes('thuc-de')||this.url.includes('bao-hiem')||this.url.includes('-shoping')||this.url.includes('dau-moi')||this.url.includes('tu-hao')||this.url.includes('chang-duong'))&&this.CurrentLang=='en'){
          this._translate.setDefaultLang('vi');
          this._translate.use('vi');
          this.CurrentLang = 'vi';
        }
        this.getContent(event.url.slice(6))
      }
    })
  }

  ngOnInit(): void {
    this.getContents()
  }
  slickInit(e: any) {}
  breakpoint(e: any) {}
  afterChange(e: any) {}
  beforeChange(e: any) {}


  getContent(meta: string) {
    this._service.getContentbyMeta(meta, this.CurrentLang).subscribe((res: any) => {
      this.blog = res;
      this.titleService.setTitle(res.name)
      this.meta.updateTag({ property: 'og:image', content: res.image })
    });
  }

  getContents(){
    this._service.getContents(this.CurrentLang).subscribe(data=> {
      this.lastestBlogs = data;
      this.ableNews = [];
      for(let i = 0; i < this.lastestBlogs.length; i++) {
       if(this.lastestBlogs[i].status == true){
        this.ableNews.push(this.lastestBlogs[i]);
       }
      }
    })
  }
  latestblogsConfig = {
    slidesToShow: 3,
    autoplay: true,
    autoplaySpeed: 2000,
    speed: 1500,
    slidesToScroll: 1,
    dots: true,
    prevArrow: false,
    nextArrow: false,
    pauseOnHover: true,
  }
}
