export const now:any = new Date().toISOString().substring(0,10)
export const year:any = new Date().setFullYear(new Date().getFullYear() + 1)
export const eff:any = new Date(year).toISOString().substring(0,10)


//Product Nhà Tư Nhân
export const NTN_HOUSEPRICE = [
  {
    id: 1,
    value: 1,
    label: 'PRODUCTS.300M',
  },
  {
    id: 2,
    value: 2,
    label: 'PRODUCTS.500M',
  },
  {
    id: 3,
    value: 3,
    label: 'PRODUCTS.1000M  1 tỷ',
  },
  {
    id: 4,
    value: 4,
    label: 'PRODUCTS.1500M  1,5 tỷ',
  },
  {
    id: 5,
    value: 5,
    label: 'PRODUCTS.2000M  2 tỷ',
  },
  {
    id: 6,
    value: 6,
    label: 'PRODUCTS.2500M  2.5 tỷ',
  },
  {
    id: 7,
    value: 7,
    label: 'PRODUCTS.3000M  3 tỷ',
  },
  {
    id: 8,
    value: 8,
    label: 'PRODUCTS.3500M  3.5 tỷ',
  },
  {
    id: 9,
    value: 9,
    label: 'PRODUCTS.4000M  4 tỷ',
  },
  {
    id: 10,
    value: 10,
    label: 'PRODUCTS.4500M  4.5 tỷ',
  },
  {
    id: 11,
    value: 11,
    label: 'PRODUCTS.5000M  5 tỷ',
  },
]

export const NTN_PROPERTY = [
  {
    id: 1,
    value: 1,
    label: 'PRODUCTS.100M',
  },
  {
    id: 2,
    value: 2,
    label: 'PRODUCTS.200M',
  },
  {
    id: 3,
    value: 3,
    label: 'PRODUCTS.300M',
  },
  {
    id: 4,
    value: 4,
    label: 'PRODUCTS.400M',
  },
  {
    id: 5,
    value: 5,
    label: 'PRODUCTS.500M',
  },
  {
    id: 6,
    value: 6,
    label: 'PRODUCTS.600M',
  },
  {
    id: 7,
    value: 7,
    label: 'PRODUCTS.700M',
  },
  {
    id: 8,
    value: 8,
    label: 'PRODUCTS.800M',
  },
  {
    id: 9,
    value: 9,
    label: 'PRODUCTS.900M',
  },
  {
    id: 10,
    value: 10,
    label: 'PRODUCTS.2000M',
  },
]

//Products UTV
export const UTV_RELATIVE = [
  {
    id: 1,
    value: 1,
    label: 'PRODUCTS.REL1',
  },
  {
    id: 2,
    value: 2,
    label: 'PRODUCTS.REL2',
  },
  {
    id: 3,
    value: 3,
    label: 'PRODUCTS.REL3',
  },
  {
    id: 4,
    value: 4,
    label: 'PRODUCTS.REL4',
  },
  {
    id: 5,
    value: 5,
    label: 'PRODUCTS.REL5',
  },
  {
    id: 6,
    value: 6,
    label: 'PRODUCTS.REL6',
  },
]

export const UTV_INSURANCE = [
  {
    id: 1,
    value: 1,
    label: 'Pinkcare_Lemon ',
  },
  {
    id: 2,
    value: 2,
    label: 'Pinkcare_Orange  ',
  },
  {
    id: 3,
    value: 3,
    label: 'Pinkcare_Pomelo  ',
  },
]

//Product UT
export const UT_RELATIVE = [
  {
    id: 1,
    value: 1,
    label: 'PRODUCTS.REL1',
  },
  {
    id: 2,
    value: 2,
    label: 'PRODUCTS.REL2',
  },
  {
    id: 3,
    value: 3,
    label: 'PRODUCTS.REL3',
  },
  {
    id: 4,
    value: 4,
    label: 'PRODUCTS.REL4',
  },
  {
    id: 5,
    value: 5,
    label: 'PRODUCTS.REL5',
  },
  {
    id: 6,
    value: 6,
    label: 'PRODUCTS.REL6',
  },
]

export const UT_INSURANCE = [
  {
    id: 1,
    value: 1,
    label: 'Pinkcare_Lemon ',
  },
  {
    id: 2,
    value: 2,
    label: 'Pinkcare_Orange  ',
  },
  {
    id: 3,
    value: 3,
    label: 'Pinkcare_Pomelo  ',
  },
]

//Product TLTS
export const TLTS_RELATIVE = [
  {
    id: 1,
    value: 1,
    label: 'PRODUCTS.REL1',
  },
  {
    id: 2,
    value: 2,
    label: 'PRODUCTS.REL2',
  },
  {
    id: 3,
    value: 3,
    label: 'PRODUCTS.REL3',
  },
  {
    id: 4,
    value: 4,
    label: 'PRODUCTS.REL4',
  },
  {
    id: 5,
    value: 5,
    label: 'PRODUCTS.REL5',
  },
  {
    id: 6,
    value: 6,
    label: 'PRODUCTS.REL6',
  },
]

export const TLTS_INSURANCE = [
  {
    id: 1,
    value: 1,
    label: 'Finlife_Tương lai tươi sáng_1',
  },
  {
    id: 2,
    value: 2,
    label: 'PFinlife_Tương lai tươi sáng_2',
  },
  {
    id: 3,
    value: 3,
    label: 'Finlife_Tương lai tươi sáng_3',
  },
]

export const FEE_TEMPLATE = {
  res: '',
  status: true,
  thue: 0,
  phi: '',
  tongphi: ''
}

export const VOUCHER_TEMPLATE = {
  id: 0,
  status: true,
  loaiVoucher: '',
  maVoucher: '',
  phanTram: 0
}

export const voucherInfor = [
  {
      id: 1,
      bhId: 'BHLKDT1',
      type: "BHLKDT",
      name: "Bảo hiểm liên kết đầu tư",
      category: "Insuarance",
      title: "Voucher Bảo hiểm liên kết đầu tư trị giá 10 triệu",
      price_vnd: 10000000,       
  },
  {
      id: 2,
      bhId: 'BHLKDT2',
      type: "BHLKDT",
      name: "Bảo hiểm liên kết đầu tư",
      category: "Insuarance",
      title: "Voucher Bảo hiểm liên kết đầu tư trị giá 20 triệu",
      price_vnd: 20000000,      
  },
  {
      id: 3,
      bhId: 'BHLKDT3',
      type: "BHLKDT",
      name: "Bảo hiểm liên kết đầu tư",
      category: "Insuarance",
      title: "Voucher Bảo hiểm liên kết đầu tư trị giá 30 triệu",
      price_vnd: 30000000,       
  },
  {
      id: 4,
      bhId: 'BHSK1',
      type: "BHSK",
      name: "Bảo hiểm sức khỏe",
      category: "Insuarance",
      title: "Voucher 500k cho khách hàng thanh toán hợp đồng trên 2 triệu",
      price_vnd: 500000,      
  },
  {
      id: 5,
      bhId: 'BHSK2',
      type: "BHSK",
      name: "Bảo hiểm sức khỏe",
      category: "Insuarance",
      title: "Voucher 1000k cho khách hàng thanh toán hợp đồng trên 3 triệu",
      price_vnd: 1000000,      
  },
  {
      id: 6,
      bhId: 'BHPNT1',
      type: "BHPNT",
      name: "Bảo hiểm phi nhân thọ",
      category: "Insuarance",
      title: "Bảo hiểm hỗ trợ viện phí 350K cho khách hàng với hóa đơn 1 triệu",
      price_vnd: 350000,       
  },
  {
      id: 7,
      bhId: 'BHPN2',
      type: 'BHPNT',
      name: "Bảo hiểm phi nhân thọ",
      category: "Insuarance",
      title: "Bảo hiểm hỗ trợ viện phí 700K cho khách hàng với hóa đơn 2 triệu",
      price_vnd: 700000,       
  },
  {
      id: 8,
      bhId: 'BHPNT3',
      type: "BHPNT",
      name: "Bảo hiểm phi nhân thọ",
      category: "Insuarance",
      title: "Voucher 30k bảo hiểm TNDS xe máy",
      price_vnd: 30000,       
  },
  {
      id: 9,
      bhId: 'BHPNT4',
      type: "BHPNT",
      name: "Bảo hiểm phi nhân thọ",
      category: "Insuarance",
      title: "Voucher 100k bảo hiểm TNDS ô tô 5 chỗ",
      price_vnd: 100000,        
  },
]

export const PAYMENT_TEMPLATE = {
  tongThanhToan: '',
  ipKhachHang: '::1',
  magioithieu: '',
  user_Code: '',
  idDangKy: '',
  hoten: '',
  ngaysinh: '',
  ngaytao: '',
  diachi: '',
  sodienthoai: '',
  cccd: '',
  email: '',
  sanPham: '',
  thietBi: '',
  loaibaohiem: '',
  goibaohiem: '',
  thoihan: '',
  tongphi: '',
  hinhthucthanhtoan: '',
  thoigianthanhtoan: '',
  trangthai: '',
  moiquanhe: '',
  gioitinh: '',
  webview: '',
}

export const PRODUCT_TEMPLATE = {
  id: 0,
  name: '',
  code: '',
  metaTitle: '',
  description: '',
  image: '',
  moreImages: '',
  price: '',
  promotionPrice: '',
  includedVAT: '',
  quantity: 0,
  categoryID: 0,
  detail: '',
  warranty: '',
  createdDate: '',
  createdBy: '',
  modifiedDate: '',
  modifiedBy: '',
  metaKeywords: '',
  metaDescriptions: '',
  status: false,
  topHot: '',
  viewCount: '',
  noibat: false,
  muanhieu: false,
  icon: '',
  tenCongTy: '',
  heSo: 0,
  noiDung: '',
  quyTrinh: '',
  gioiThieu: '',
  loiIch: '',
  hoaHong: '',
  stt: 0,
}
