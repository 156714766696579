<section class="container">
    <div class="signContract">
        <div class="card">
            <h3>XÁC THỰC TÀI KHOẢN</h3>
            <div class="img">
                <img src="../../../assets/frontend/images/success.png" alt="success">
            </div>
            <h2>Bạn đã đăng ký xác thực Thành công</h2>
            <p>Thông tin sẽ được gửi về qua tin nhắn theo số điện thoại bạn đã đăng ký</p>
        </div>
    </div>
</section>
