<div class="adminlogin">
    <div class="screen">
      <div class="screen__content">
        <form class="login" #f="ngForm">
          <div class="login__field">
            <span class="material-icons login__icon">person</span>
            <input required type="text" id="username" class="login__input" value="{{login.username}}"
              [(ngModel)]="login.username" name="username" placeholder="Số điện thoại">
          </div>
          <div class="login__field">
            <span class="material-icons login__icon">lock</span>
            <input required minlength="6" type="password" id="password" class="login__input" value="{{login.password}}" [(ngModel)]="login.password"
          name="password" placeholder="Mật khẩu">
          </div>
          <button class="button login__submit" (click)="onSubmit(f, validWarming)">
            <span class="button__text">Đăng nhập</span>
            <span class="material-icons button__icon">chevron_right</span>
          </button>
        </form>
      </div>
      <div class="screen__background">
        <span class="screen__background__shape screen__background__shape4"></span>
        <span class="screen__background__shape screen__background__shape3"></span>
        <span class="screen__background__shape screen__background__shape2"></span>
        <span class="screen__background__shape screen__background__shape1"></span>
      </div>
    </div>
  </div>
  
  <ng-template #validWarming let-modal>
    <div class="modal-header">
      <h4 class="modal-title">Thông báo</h4>
    </div>
    <div class="modal-body">
      <p>Vui lòng nhập thông tin trước khi đăng nhập!</p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary" (click)="modal.close('Close click')">Đồng ý</button>
    </div>
  </ng-template>