import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, Observable, of, switchMap } from 'rxjs';
import { environment } from '@environments/environment';
import { API_PATH } from 'src/utils/constant';
import { User } from '@models/user.model';

@Injectable({
  providedIn: 'root'
})
export class SignContractService {

  constructor(private _http: HttpClient,) { }

  getUserbyID(ma_nv: string, lang: string): Observable<User> {
    const url = lang === 'en' ? environment.enURL : environment.apiURL
    return this._http.get(`${url}${API_PATH.GET_CTV}/${ma_nv}`)
      .pipe(catchError((error) => {
        return of(error);
      }), switchMap((response) => {
        return of(response);
      }));
  }

  putUserbyID(data:User, lang: string): Observable<User> {
    const url = lang === 'en' ? environment.enURL : environment.apiURL
    return this._http.put(`${url}${API_PATH.PUT_CTV}`, data)
      .pipe(catchError((error) => {
        return of(error);
      }), switchMap((response) => {
        return of(response);
      }));
  }
}
