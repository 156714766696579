<section *ngIf="isActive" class="container">
    <div class="signContract">
        <div class="card">
            <h3>KÍCH HOẠT TÀI KHOẢN THÀNH CÔNG</h3>
            <div class="img">
                <img src="../../../assets/frontend/images/success.png" alt="success">
            </div>
            <h2>Bạn đã kích hoạt thành công tài khoản!</h2>
            <p>Sẽ được chuyển đến trang tải app dành cho Cộng tác viên trong giây lát...</p>
        </div>
    </div>
</section>
<section *ngIf="!isActive" class="container">
</section>
