<div class="usp-card-template" ngClass="{{color}}">
  <div class="">
    <span class="material-icons" ngClass="{{color}}">{{icon}}</span>
  </div>
  <div class="">
    <span class="usp-card__label">{{label}}</span>
    <br>
    <span class="usp-card__label child">{{child}}</span>
  </div>
</div>
