import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-investors-manager',
  templateUrl: './investors-manager.component.html',
  styleUrls: ['./investors-manager.component.css']
})
export class InvestorsManagerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void { }

}
