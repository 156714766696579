export const MICCARE_RESPONS = [
  { id: 1, value: 1, label: 'PRODUCTS.BRONZE', label_api: 'MICCARE Gói Đồng' },
  { id: 2, value: 2, label: 'PRODUCTS.SILVER', label_api: 'MICCARE Gói Bạc' },
  { id: 3, value: 3, label: 'PRODUCTS.GOLD', label_api: 'MICCARE Gói Vàng' },
  { id: 4, value: 4, label: 'PRODUCTS.PLATIUM', label_api: 'MICCARE Gói Bạch Kim' },
  { id: 5, value: 5, label: 'PRODUCTS.DIAMOND', label_api: 'MICCARE Gói Kim Cương' },
]

export const MICCARE_MAIN_RULES = [
  {
    id: 1,
    label: 'PRODUCTS.MICCARE.1',
    values: [
      { groupID: '1', fee: '100,000,000' },
      { groupID: '2', fee: '200,000,000' },
      { groupID: '3', fee: '300,000,000' },
      { groupID: '4', fee: '500,000,000' },
      { groupID: '5', fee: '1,000,000,000' },
    ],
  },
  {
    id: 2,
    label: 'PRODUCTS.MICCARE.2',
    values: [
      { groupID: '1', fee: '10,000,000' },
      { groupID: '2', fee: '20,000,000' },
      { groupID: '3', fee: '30,000,000' },
      { groupID: '4', fee: '50,000,000' },
      { groupID: '5', fee: '100,000,000' },
    ],
  },
  {
    id: 3,
    label: 'PRODUCTS.MICCARE.3',
    values: [
      { groupID: '1', fee: '35,000,000' },
      { groupID: '2', fee: '50,000,000' },
      { groupID: '3', fee: '100,000,000' },
      { groupID: '4', fee: '150,000,000' },
      { groupID: '5', fee: '200,000,000' },
    ],
  },
]

export const MICCARE_EXTENSION_RULES = [
  {
    id: 1,
    name: 'bs1',
    label: 'PRODUCTS.MICCARE.4',
    values: [
      { groupID: '1', fee: '5,000,000' },
      { groupID: '2', fee: '7,000,000' },
      { groupID: '3', fee: '10,000,000' },
      { groupID: '4', fee: '15,000,000' },
      { groupID: '5', fee: '20,000,000' },
    ],
  },
  {
    id: 2,
    name: 'bs2',
    label: 'PRODUCTS.MICCARE.5',
    values: [
      { groupID: '1', fee: '1,000,000' },
      { groupID: '2', fee: '2,000,000' },
      { groupID: '3', fee: '3,000,000' },
      { groupID: '4', fee: '5,000,000' },
      { groupID: '5', fee: '10,000,000' },
    ],
  },
  {
    id: 3,
    name: 'bs3',
    label: 'PRODUCTS.MICCARE.6',
    values: [
      { groupID: '1', fee: '0' },
      { groupID: '2', fee: '0' },
      { groupID: '3', fee: '10,000,000' },
      { groupID: '4', fee: '20,000,000' },
      { groupID: '5', fee: '30,000,000' },
    ],
  },
  {
    id: 4,
    name: 'bs4',
    label: 'PRODUCTS.MICCARE.7',
    values: [
      { groupID: '1', fee: '50,000,000' },
      { groupID: '2', fee: '100,000,000' },
      { groupID: '3', fee: '150,000,000' },
      { groupID: '4', fee: '250,000,000' },
      { groupID: '5', fee: '500,000,000' },
    ],
  },
]

export const MICCARE_CUSTOMER_TEMPLATE = {
  lkh: 'C',
  ten: '',
  cmt: '',
  gioi: '',
  ng_sinh: '',
  dchi: '',
  mobi: '',
  email: '',
}

export const MICCARE_TTSK_TEMPLATE = {
  ck: 'K',
  cc: 'K',
  cu: 'K',
  tt1C: 'K',
  tt2C: 'K',
  tt3C: 'K',
  tt4C: 'K',
  tt5C: 'K',
  tt6C: 'K',
  tt7C: 'K',
  tt8C: 'K',
  tt9C: 'K',
  tt10C: 'K',
  tt11C: 'K',
  tt12C: 'K',
  tt13C: 'K',
  tt14C: 'K',
  tt15C: 'K',
  tt16C: 'K',
  tt17C: 'K',
  tt18C: 'K',
  tt19C: 'K',
  tt20C: 'K',
  tt21C: 'K',
  tt22C: 'K',
  tt23C: 'K',
  tt24C: 'K',
  tt25C: 'K',
  tt26C: 'K',
  tt27C: 'K',
  tt28C: 'K',
  tt29C: 'K',
  tt30C: 'K',
  tt31C: 'K',
  tt32C: 'K',
  tt33C: 'K',
  tt34C: 'K',
  tt35C: 'K',
  tt36C: 'K',
  tt37C: 'K',
  tt38C: 'K',
  tt39C: 'K',
  tt40C: 'K',
  tt41C: 'K',
  tt42C: 'K',
  tt43C: 'K',
  tt44C: 'K',
  tt45C: 'K',
  tt46C: 'K',
  tt47C: 'K',
  tt48C: 'K',
  tt49C: 'K',
  tt50C: 'K',
  tt51C: 'K',
  tt52C: 'K',
  tt53C: 'K',
  tt54C: 'K',
  tt55C: 'K',
  tt56C: 'K',
  tt57C: 'K',
  tt58C: 'K',
  tt59C: 'K',
  tt60C: 'K',
  tt61C: 'K',
  tt62C: 'K',
  tt63C: 'K',
  tt64C: 'K',
  tt65C: 'K',
  tt66C: 'K',
  tt67C: 'K',
  tt68C: 'K',
  tt69C: 'K',
  tt70C: 'K',
  tt71C: 'K',
}

export const MICCARE_DKBS_TEMPLATE = {
  bs1: 'K',
  bs2: 'K',
  bs3: 'K',
  bs4: 'K',
}
