import { eff, now } from "."

export const MEDIPLUS_DURATIONS = [{ id: 1, value: 12, label: 'PRODUCTS.12_MONTH' }]

export const MEDIPLUS_RESPONS = [
  { id: 1, value: 1, label: 'PRODUCTS.BRONZE', label_api: 'Mediplus Gói Đồng' },
  { id: 2, value: 2, label: 'PRODUCTS.SILVER', label_api: 'Mediplus Gói Bạc' },
  { id: 3, value: 3, label: 'PRODUCTS.TITAN', label_api: 'Mediplus Gói Titan' },
  { id: 4, value: 4, label: 'PRODUCTS.GOLD', label_api: 'Mediplus Gói Vàng' },
  { id: 5, value: 5, label: 'PRODUCTS.PLATIUM', label_api: 'Mediplus Gói Bạch Kim' },
]

export const MEDIPLUS_CLIENT_TYPES = [
  { id: 1, value: 1, label: 'COMMON.PERSONAL' },
  { id: 2, value: 2, label: 'COMMON.COMPANY' },
]

export const MEDIPLUS_PROTECTOR_TYPES = [
  { id: 1, value: 1, label: 'PRODUCT.MEDIPLUS_1' },
  { id: 2, value: 2, label: 'PRODUCT.MEDIPLUS_2' },
]

export const MEDIPLUS_LIST_CUSTOMER_TEMPLATE = [
  {
    dS_TEN: '',
    dS_CMT: '',
    dS_NG_SINH: '',
    dS_GIOI: '',
    dS_MOBI: '',
    dS_EMAIL: '',
  },
  {
    dS_TEN: '',
    dS_CMT: '',
    dS_NG_SINH: '',
    dS_GIOI: '',
    dS_MOBI: '',
    dS_EMAIL: '',
  },
  {
    dS_TEN: '',
    dS_CMT: '',
    dS_NG_SINH: '',
    dS_GIOI: '',
    dS_MOBI: '',
    dS_EMAIL: '',
  },
  {
    dS_TEN: '',
    dS_CMT: '',
    dS_NG_SINH: '',
    dS_GIOI: '',
    dS_MOBI: '',
    dS_EMAIL: '',
  },
  {
    dS_TEN: '',
    dS_CMT: '',
    dS_NG_SINH: '',
    dS_GIOI: '',
    dS_MOBI: '',
    dS_EMAIL: '',
  },
]

export const MEDIPLUS_ITEM_CUSTOMER_TEMPLATE = {
  dS_TEN: '',
  dS_CMT: '',
  dS_NG_SINH: '',
  dS_GIOI: '',
  dS_MOBI: '',
  dS_EMAIL: '',
}

export const MEDIPLUS_BILL_TEMPLATE = {
  ngaY_HL: now,
  ngaY_KT: eff,
  muc: '1',
  han: '12'
}
