import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-content-title',
  templateUrl: './content-title.component.html',
  styleUrls: ['./content-title.component.css']
})
export class ContentTitleComponent implements OnInit {
  @Input() h1: boolean = false;
  @Input() upper = '';
  @Input() label = '';
  @Input() line: boolean = true
  @Input() center: boolean = true

  constructor() { }

  ngOnInit(): void {
  }

}
