<div class="login__page">
    <p class="login__head">Đăng ký thông tin voucher</p>
    <div class="login__contain">
      <div class="login__form">
        <p class="login__form-label">Đăng ký thông tin voucher</p>
        <ng-container [ngTemplateOutlet]="registerForm"></ng-container>
      </div>
  
      <div class="login__contain--left">
        <div class="login__banner mobile-hidden">
          <img class="login__thumb" src="../../../assets/frontend/images/bu/term-form-graphic2.png"
            alt="insurance for family">
        </div>
        <div class="login__pointer">
          <div class="login__pointer-block">
            <app-pointat *ngFor="let point of pointers" [icon]="point.icon" [label]="point.label|translate"
              [childLabel]="point.childLabel|translate"></app-pointat>
          </div>
          <p class="login__pointer--addition">
            {{'LOGIN.ADDITION'|translate}}
          </p>
        </div>
      </div>
    </div>
  </div>
  
  <ng-template #validWarming let-modal>
    <div class="modal-header">
      <h4 class="modal-title">{{'LOGIN.NOTI'|translate}}</h4>
    </div>
    <div class="modal-body">
      <p>{{message}}</p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary" (click)="modal.close('Close click')">{{'LOGIN.OK'|translate}}</button>
    </div>
  </ng-template>
  
  <ng-template #success let-modal>
    <div class="modal-header">
      <h4 class="modal-title">{{'LOGIN.NOTI'|translate}}</h4>
    </div>
    <div class="modal-body">
      <p>{{ message|translate }}</p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary" (click)="modal.close('Close click')">{{'LOGIN.OK'|translate}}</button>
    </div>
  </ng-template>
  
  
  <ng-template #registerForm>
    <form [formGroup]="register">
      <ng-container>
        <div class="text-field">
          <label for="hoten">{{ 'INFORMATIONS.FULL_NAME' | translate }} *</label>
          <input type="text" id="hoten" name="hoten" formControlName="hoten"
            placeholder="{{ 'INFORMATIONS.FULL_NAME' | translate }}" (ngModelChange)="onCapitalize()"/>
          <div class="invalid">
            <ng-container *ngIf="r['hoten']?.touched || submitted">
              <small *ngIf="r['hoten']?.hasError('required')">{{ error.required|translate }}</small>
            </ng-container>
          </div>
        </div>
  
        <div class="text-field">
            <label for="so_dien_thoai">{{ 'INFORMATIONS.PHONE_NUMBER' | translate }} *</label>
            <input type="text" id="so_dien_thoai" name="so_dien_thoai" [pattern]="valid.PHONE"
              formControlName="so_dien_thoai" placeholder="{{ 'INFORMATIONS.PHONE_NUMBER' | translate }}" />
            <div class="invalid">
              <ng-container *ngIf="r['so_dien_thoai']?.touched || submitted">
                <small *ngIf="r['so_dien_thoai']?.hasError('required')">{{ error.required|translate }}</small>
                <small *ngIf="r['so_dien_thoai']?.hasError('pattern')">{{ error.phone|translate }}</small>
              </ng-container>
            </div>
          </div>

          <div class="text-field">
            <label for="email">Email *</label>
            <input type="text" id="email" name="email" [pattern]="valid.EMAIL1"  formControlName="email"
              placeholder="Email" />
            <div class="invalid">
              <ng-container *ngIf="r['email']?.touched || submitted">
                <small *ngIf="r['email']?.hasError('required')">{{ error.required|translate }}</small>
                <small *ngIf="r['email']?.hasError('pattern')">{{ error.email|translate }}</small>
              </ng-container>
            </div>
          </div>
  
        <div class="text-field">
          <label for="meetVoucher">Mã voucher</label>
          <input type="text" id="meetVoucher" name="meetVoucher" [readonly]="true"
            formControlName="meetVoucher"/>
          <div class="invalid">
          </div>
        </div>

        <div class="text-field">
            <label for="meetVoucherInfo">Thông tin voucher</label>
            <input type="text" id="meetVoucherInfo" name="meetVoucherInfo" [readonly]="true"
              formControlName="meetVoucherInfo" />
            <div class="invalid">
            </div>
          </div>
      </ng-container>

  
      <div class="d-flex justify-content-around">
        <button class="btn btn-primary" [disabled]="register.invalid||!isTrueVoucher" (click)="onRegister(register, validWarming)">
          Đăng ký
        </button>
      </div>
      <br>
    </form>
  </ng-template>