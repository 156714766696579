import { ContentService } from '@services/content/content.service';
import { Component, OnInit } from '@angular/core'
import { ITContent } from '@models/content.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.css'],
})
export class NewsComponent implements OnInit {
  contents: ITContent[] = []
  CurrentLang:any;
  ableNews:any = []
  slideConfig = {
    slidesToShow: 3,
    autoplay: true,
    autoplaySpeed: 1000,
    speed: 1000,
    slidesToScroll: 1,
    dots: true,
    prevArrow: false,
    nextArrow: false,
    responsive: [
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  slickInit(e: any) {}
  breakpoint(e: any) {}
  afterChange(e: any) {}
  beforeChange(e: any) {}

  constructor(
    private _service: ContentService,
    public _translate: TranslateService
  ) {this.CurrentLang = _translate.getDefaultLang();
    _translate.onLangChange.subscribe((e:any)=>{
      this.CurrentLang= e.lang;
      this.getContents()
    })}

  ngOnInit(): void {
    this.getContents()
  }

  getContents(){
    this._service.getContents(this.CurrentLang).subscribe(data=> {
      this.contents = data;
      this.ableNews = [];
      for(let i = 0; i < this.contents.length; i++) {
       if(this.contents[i].status == true){
        this.ableNews.push(this.contents[i]);
       }
      }
    })
  }

}
