export const MEDIPLUS_STEPS = [
  { id: 1, value: 1, label: 'STEP.LIMIT' },
  { id: 2, value: 2, label: 'STEP.BUYER' },
  { id: 3, value: 3, label: 'STEP.PAYMENT' },
]

export const OTO_STEPS = [
  { id: 1, value: 1, label: 'STEP.VIHICLE' },
  { id: 2, value: 2, label: 'STEP.LIMIT' },
  { id: 3, value: 3, label: 'STEP.OWNER' },
  { id: 4, value: 4, label: 'STEP.PAYMENT' },
]

export const MOTO_STEPS = [
  { id: 1, value: 1, label: 'STEP.VIHICLE' },
  { id: 2, value: 2, label: 'STEP.LIMIT' },
  { id: 3, value: 3, label: 'STEP.OWNER' },
  { id: 4, value: 4, label: 'STEP.PAYMENT' },
]

export const MICCARE_STEPS = [
  { id: 1, value: 1, label: 'STEP.LIMIT' },
  { id: 2, value: 2, label: 'STEP.CLIENT' },
  { id: 3, value: 3, label: 'STEP.STATUS' },
  { id: 4, value: 4, label: 'STEP.DECLARE' },
  { id: 5, value: 5, label: 'STEP.PAYMENT' },
]

export const NTN_STEPS = [
  { id: 1, value: 1, label: 'STEP.CLIENT' },
  { id: 2, value: 2, label: 'STEP.INFO' },
  { id: 3, value: 3, label: 'STEP.PAYMENT' },
]

export const VCD_STEPS = [
  { id: 1, value: 1, label: 'STEP.CLIENT' },
  { id: 2, value: 2, label: 'STEP.PRODUCT' },
  { id: 3, value: 3, label: 'STEP.CONFIRM' },
  { id: 4, value: 4, label: 'STEP.PAYMENT' },
]

export const TLTS_STEPS = [
  { id: 1, value: 1, label: 'STEP.CLIENT' },
  { id: 2, value: 2, label: 'STEP.PRODUCT' },
  { id: 3, value: 3, label: 'STEP.CONFIRM' },
  { id: 4, value: 4, label: 'STEP.PAYMENT' },
]

export const BHSK_STEPS = [
  { id: 1, value: 1, label: 'STEP.CLIENT' },
  { id: 2, value: 2, label: 'STEP.PRODUCT' },
  { id: 3, value: 3, label: 'STEP.CONFIRM' },
  { id: 4, value: 4, label: 'STEP.PAYMENT' },
]

export const UTV_STEPS = [
  { id: 1, value: 1, label: 'STEP.CLIENT' },
  { id: 2, value: 2, label: 'STEP.PRODUCT' },
  { id: 3, value: 3, label: 'STEP.CONFIRM' },
  { id: 4, value: 4, label: 'STEP.PAYMENT' },
]

export const UT_STEPS = [
  { id: 1, value: 1, label: 'STEP.CLIENT' },
  { id: 2, value: 2, label: 'STEP.PRODUCT' },
  { id: 3, value: 3, label: 'STEP.CONFIRM' },
  { id: 4, value: 4, label: 'STEP.PAYMENT' },
]

export const SUCKHOE_STEPS = [
  { id: 1, value: 1, label: 'STEP.CLIENT' },
  { id: 2, value: 2, label: 'STEP.PRODUCT' },
  { id: 3, value: 3, label: 'STEP.CONFIRM' },
  { id: 4, value: 4, label: 'STEP.PAYMENT' },
]
export const BHTCNV_STEPS = [
  { id: 1, value: 1, label: 'STEP.LIMIT' },
  { id: 2, value: 2, label: 'STEP.BUYER' },
  { id: 3, value: 3, label: 'STEP.PAYMENT' },
]
