<ng-container *ngIf="product">
  <section class="container payment">
    <div class="payment__switchcase" [ngSwitch]="product.metaTitle">
      <div *ngSwitchCase="'bao-hiem-suc-khoe'">
        <app-baohiemsuckhoe [isWebview]="isWebview"></app-baohiemsuckhoe>
      </div>
      <div *ngSwitchCase="'bao-hiem-suc-khoe-a-care'">
        <app-baohiemacare [isWebview]="isWebview"></app-baohiemacare>
      </div>
      <div *ngSwitchCase="'bao-hiem-mediplus'">
        <app-mediplus [isWebview]="isWebview"></app-mediplus>
      </div>
      <div *ngSwitchCase="'bao-hiem-mic-care'">
        <app-miccare [isWebview]="isWebview"></app-miccare>
      </div>
      <div *ngSwitchCase="'bao-hiem-tnds-xe-mo-to'">
        <app-motobike [isWebview]="isWebview" [code]="product.code"></app-motobike>
      </div>
      <div *ngSwitchCase="'bao-hiem-pti-tnds-xe-may'">
        <app-motobike [isWebview]="isWebview" [code]="product.code"></app-motobike>
      </div>
      <div *ngSwitchCase="'bao-hiem-tnds-xe-o-to'">
        <app-car [isWebview]="isWebview" [code]="product.code"></app-car>
      </div>
      <div *ngSwitchCase="'bao-hiem-tnds-xe-o-to-bsh'">
        <app-car [isWebview]="isWebview" [code]="product.code"></app-car>
      </div>
      <div *ngSwitchCase="'bao-hiem-tro-cap-nam-vien'">
        <app-baohiemtrocapnamvien [isWebview]="isWebview" [code]="product.code"></app-baohiemtrocapnamvien>
      </div>
      <div *ngSwitchCase="'bao-hiem-y-te-nang-cao-bsh'">
        <app-baohiemytecaocap [isWebview]="isWebview" [code]="product.code"></app-baohiemytecaocap>
      </div>
      <div *ngSwitchCase="'bao-hiem-tro-cap-nam-vien-bsh'">
        <app-baohiemtrocapnamvien [isWebview]="isWebview" [code]="product.code"></app-baohiemtrocapnamvien>
      </div>
      <div *ngSwitchCase="'bao-hiem-tai-nan-ca-nhan'">
        <app-baohiemtainancanhan [isWebview]="isWebview" [code]="product.code"></app-baohiemtainancanhan>
      </div>
      <div *ngSwitchCase="'bao-hiem-tai-nan-ca-nhan-bsh'">
        <app-baohiemtainancanhan-bsh [isWebview]="isWebview" [code]="product.code"></app-baohiemtainancanhan-bsh>
      </div>
      <div *ngSwitchDefault>
        <app-loading></app-loading>
      </div>
    </div>

  </section>
</ng-container>
