import { LANGUAGES } from 'src/utils/constant';

import { Component, Input, OnInit, TemplateRef, ViewEncapsulation } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from '@environments/environment';
import { ITCart } from '@models/cart.model';
import { ITCategory } from '@models/category.model';
import { ITProduct } from '@models/product.model';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { CartService } from '@services/cart/cart.service';
import { TokenService } from '@services/token.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class HeaderComponent implements OnInit {
  prodEnv: boolean = environment.production
  baseUrl: string = environment.baseURL
  userInfo: any
  @Input() isLoggedIn = false
  @Input() toggle = false
  @Input() isHome = false
  @Input() isAdmin: boolean = false
  @Input() isCol: boolean = false
  @Input() icon = 'menu'
  @Input() categories: ITCategory[] = []
  @Input() products: ITProduct[] = []

  public isCollapsed = false;
  public isCategoryCollapsed = false;
  public isContactCollapsed = false;
  public isPolicyCollapsed = false;
  public isCompareCollapsed = false;
  public isNewsCollapsed = false;
  language: string = 'EN';
  languageIcon: string = 'fa-solid fa-earth-asia';
  languages = LANGUAGES
  activedLang: any = {}
  url: any;
  notifications: any[] = []

  constructor(
    private offcanvasService: NgbOffcanvas,
    private _token: TokenService,
    private router: Router,
    public translate: TranslateService,
    private _service: CartService,
  ) {
    translate.addLangs(['vi', 'en']);
    translate.setDefaultLang('vi');
    translate.use('vi');
    let browserLang = translate.getDefaultLang();
    this.activedLang = this.languages.find(item => item.value === browserLang)

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.url = event.url
        if (this.url.includes('admin') && this.url !== '/admin/login') {
          this.getContracts()
        }

        if (this.url.includes('bao-hiem') && this.activedLang != 'vi') {
          this.switchLang('vi')
        }
        if (this.url.includes('insurance') && this.activedLang != 'en') {
          this.switchLang('en')
        }
      }
    })

    translate.onLangChange.subscribe((e: any) => {
      let browserLang = translate.getDefaultLang();
      this.activedLang = this.languages.find(item => item.value === browserLang)
    })
  }

  ngOnInit() {
    this._token.getUser() && (this.userInfo = this._token.getUser())
    this.translate.use('vi')
  }

  onToggle() {
    !this.toggle
      ? (this.toggle = true)
      : (this.toggle = false)

    this.icon = this.toggle ? 'close' : 'menu'
  }

  openSidebar(sidebar: TemplateRef<any>) {
    this.offcanvasService.open(sidebar, { panelClass: 'sidebar', backdropClass: 'sidebar_bd' });
  }

  openAdmin() {
    window.location.href = 'admin/dashboard'
  }


  switchLang(lang: string): void {
    this.translate.setDefaultLang(lang);
    this.translate.use(lang)
    this.activedLang = this.languages.find(item => item.value === lang)
  }

  onLogOut() {
    this._token.signOut()
    window.location.href = '/'
  }

  private getContracts() {
    this._service.getContracts(1, 6, 'Đã thanh toán').subscribe((res: any) => {
      this.notifications = res.map((item: ITCart) => {
        return {
          id: item.id_hoso,
          name: item.loaibaohiem,
          read: false
        }
      })
    })
  }
}
