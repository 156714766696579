<ng-template #content let-modal>
  <div routerLink="/compare" (click)="modal.close()">
    <div class="home-modal">
      <img class="modal-body compare__modal" [src]="'HOME_BANNERS.IMG'|translate" alt="home_banner_compare">
    </div>
    <!-- <div class="compare__modal__content">
      <p>SO SÁNH BẢO HIỂM TRỰC TIẾP</p>
      <p>Giúp khách hàng dễ dàng lựa chọn gói bảo hiểm theo yêu cầu</p>
      <p>
        Giúp khách hàng nhìn thấy được lợi ích của các gói bảo hiểm một cách
        trực quan nhất
      </p>
      <p>
        Giúp khách hàng tránh xa những rủi ro không đáng cố khi mua các gói bảo
        hiểm
      </p>
    </div> -->
  </div>
  <div (click)="modal.close()" class="btn--quit">
    <span class="test">{{ 'COMMON.CLOSE' | translate }}</span>
    <span class="material-icons">close</span>
  </div>
</ng-template>

<div class="top">
  <div class="top-fold">
    <div class="container">
      <div class="top-fold__flat" ngClass="{{ isHidden ? '' : 'top-fold__hidden' }}">
        <h1 class="top-fold__head">
          {{ 'HOME.TOP_FOLD_FIRST' | translate }}
          <br />
          {{ 'HOME.TOP_FOLD_LAST' | translate }}
        </h1>
        <div class="top-fold__usp">
          <app-usp-card *ngFor="let usp of usps" [icon]="usp.icon" [isBorder]="true" [color]="usp.color"
            [label]="usp.label|translate" [child]="usp.child|translate"></app-usp-card>
        </div>
      </div>

      <div class="top-fold__block" ngClass="{{ isHidden ? 'top-fold__hidden' : '' }}">
        <img src="../../../assets/images/home-v1/pb-promise-new.png" alt="finme-xin-chao" class="top-fold__img" />
        <div class="top-fold__right">
          <div class="top-fold__right--promise">
            <img src="../../../assets/images/home-v1/pb-promise-ico-new.svg" alt="finme-xin-hua"
              class="top-fold__img--handshake" />
            <p class="top-fold__promise">{{ 'HOME.TOP_FOLD_PROMISE' | translate }}</p>
          </div>
          <p class="top-fold__promise--mid-text" i18n>
            {{ 'HOME.TOP_FOLD_PROMISE_TOP' | translate }}
            <br />
            <span>{{ 'HOME.TOP_FOLD_PROMISE_BOTTOM' | translate }}</span>
          </p>
          <button id="top-fold__more" (click)="openModalFold(fold_modal)"
            [ngClass]="innerWidth > 1024 ? 'btn btn-primary' : 'btn btn-link'">
            {{ 'COMMON.MORE' | translate }}
          </button>
        </div>
        <app-button [isIcon]="true" [icon]="'close'" [getStyle]="'close span'" (click)="onSetHidden()"
          class="close-button">
        </app-button>
      </div>

      <ng-template #fold_modal let-offcanvas>
        <div class="offcanvas-body">
          <div class="modal-fold">
            <img src="../../../assets/images/home-v1/pb-promise-ico-new.svg" alt="finme-xin-hua" class="" />
            <div class="modal-fold__promise">
              <h3>
                {{'PROMISE.FINME' | translate}}
                <span>{{'PROMISE.ALWAY' | translate}}</span>
              </h3>
              <p>{{'PROMISE.COMMIT' | translate}}</p>
            </div>
          </div>
          <div class="offcanvas-body__first-sentence">
            <p class="offcanvas-body__first">"One Stop Shoping"</p>
            <ul>
              <li>
                <p>
                  {{'PROMISE.CONTENT' | translate}}
                </p>
              </li>
            </ul>
          </div>
          <div class="offcanvas-body__inner">
            <ul>
              <li *ngFor="let item of promise_inner">
                <span class="ico">
                  <img src="../../../assets/images/3-01.png" alt="">
                </span>
                <p class="text" style="padding: 15px;">
                  {{ item.label| translate }}
                  <span>{{ item.content | translate }}</span>
                </p>
              </li>
            </ul>
          </div>
        </div>
        <div class="offcanvas-body__overflow">
          <ul>
            <li *ngFor="let item of promise_inner">
              <span class="ico">
                <img src="../../../assets/images/3-01.png" alt="">
              </span>
              <p class="text" style="padding: 15px;">
                <span>{{ item.label |translate }}</span>
                <br />
                {{ item.content | translate }}
              </p>
            </li>
          </ul>
        </div>
      </ng-template>
    </div>
  </div>

  <div class="products">
    <div class="container">
      <div class="flex space loader">
        <ngx-skeleton-loader *ngIf="products.length === 0" [count]="innerWidth < 640 ? 6 : 12" [theme]="innerWidth < 640
              ? {
                  margin: '.3rem .3rem 0 .3rem',
                  width: '116px',
                  height: '116px',
                  'border-radius': '1rem'
                }
              : {
                  margin: '0 .82rem .82rem .82rem',
                  width: '160px',
                  height: '160px',
                  'border-radius': '0.5rem'
                }
        "></ngx-skeleton-loader>
      </div>
      <div class="product-list">
        <div class="product" *ngFor="let item of products; index as i"
          [ngClass]="innerWidth < 640 && (item.stt > 6 && i > 5) ? 'product__overflow' : ''">
          <div>
            <app-product-card *ngIf="item.stt" [partner]="partnerID" [meta]="item.metaTitle" [image]="item.image" [name]="item.name">
            </app-product-card>
          </div>
          <ul *ngFor="let li of categories" ngClass="{{ innerWidth > 1024 ? '' : 'hidden' }}">
            <li *ngIf="li.id === item.categoryID">&#x25cf; {{ li.name }}</li>
          </ul>
        </div>
      </div>
      <button id="products__more" class="btn btn-light" (click)="openModalProduct(product_modal)">
        {{'PRODUCT.MORE'|translate}}
      </button>
    </div>

    <ng-template #product_modal let-offcanvas>
      <div class="offcanvas-header line">
        <p class="offcanvas-title">{{'PRODUCT.NAME'|translate}}</p>
        <button type="button" class="btn-close" aria-label="Close" (click)="offcanvas.dismiss('Cross click')"></button>
      </div>
      <div class="offcanvas-body">
        <ul class="ul__cate" *ngFor="let cate of categories">
          <li class="li__cate" ngClass="{{ cate.id % 2 === 0 ? 'blue_bg' : '' }}">
            <p class="offcanvas-body__header">{{ cate.name }}</p>
            <ul class="ul__prod">
              <li class="li__prod" *ngFor="let prod of products">
                <app-product-card *ngIf="cate.id === prod.categoryID" [meta]="prod.metaTitle" [image]="prod.image"
                  [name]="prod.name" (click)="offcanvas.dismiss('Cross click')"></app-product-card>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </ng-template>
  </div>
</div>

<div class="categories">
  <div class="container">
    <span class="categories__head">{{'CATEGORIES.MORE'|translate}}</span>
    <div class="categories__list">
      <ngx-skeleton-loader *ngIf="products.length === 0" count="10" [theme]="{
                margin: '0 10px 16px 0',
                width: '150px',
                height: '54px',
                'border-radius': '0.5rem'
        }"></ngx-skeleton-loader>
      <div class="prod__item" *ngFor="let prod of products" ngClass="{{ prod.muanhieu ? '' : 'hidden' }}">
        <div class="cate__item" *ngFor="let cate of categories">
          <ul class="cate__item--ul">
            <li *ngIf="prod.categoryID === cate.id && prod.muanhieu" routerLink="/san-pham/{{prod.metaTitle}}">
              {{ prod.name }}<br>
              <span *ngIf="prod.muanhieu && cate.id === prod.categoryID">
                {{ cate.name }}
              </span>
            </li>
          </ul>

        </div>
      </div>
    </div>
  </div>
</div>

<div class="categories-carousel">
  <div class="container">
    <!-- <ngb-carousel *ngIf="categories" [showNavigationIndicators]="true" [showNavigationArrows]="false">
      <ng-template ngbSlide *ngFor="let cate of categories">
        <app-category-carousel-card [icon]="cate.icon" [name]="cate.name" [logo]="cate.logo" [slogan]="cate.slogan"></app-category-carousel-card>
      </ng-template>
    </ngb-carousel> -->

    <ngx-skeleton-loader *ngIf="categories.length === 0" count="{{ innerWidth < 640 ? 1 : 3 }}" [theme]="{
        margin: '0 8px',
        width: '360px',
        height: '180px',
        'border-radius': '1rem',
        'background-color': '#7080fa'
      }"></ngx-skeleton-loader>
    <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig" (init)="slickInit($event)"
      (breakpoint)="breakpoint($event)" (afterChange)="afterChange($event)" (beforeChange)="beforeChange($event)">
      <div ngxSlickItem *ngFor="let cate of categories" class="slide">
        <div class="category-carousel-card">
          <div class="category__upper">
            <div>
              <img class="category__icon" src="../../../assets{{ cate.icon }}" alt="{{ cate.name }}" />
              <span class="category__name">{{ cate.name }}</span>
            </div>
            <img alt="" class="category__logo" src="../../../assets{{ cate.logo }}" />
          </div>
          <p class="category__slogan">{{ cate.slogan }}</p>
        </div>
      </div>
    </ngx-slick-carousel>
  </div>
</div>

<div class="infomations-block">
  <div class="container">
    <h2 class="infomations-block__head">
      {{'INFORMATIONS.HEAD'|translate}}
    </h2>
    <div class="infomations-block__head-desktop">
      {{'INFORMATIONS.HEAD1'|translate}}
      <br />
      {{'INFORMATIONS.HEAD2'|translate}}
      <br />
      {{'INFORMATIONS.HEAD3'|translate}}
      <br />
      {{'INFORMATIONS.HEAD4'|translate}}
    </div>
    <div class="infomations-block__right">
      <app-info-card *ngFor="let info of informations" id="infomations-item_{{ info.id }}" [color]="info.color"
        [image]="info.position" [firstLetter]="info.firstLetter|translate" [innerContent]="info.innerContent|translate"></app-info-card>
    </div>
  </div>
</div>

<div class="insurance-carousel">
  <div class="container">
    <div class="insurance-slider">
      <ngb-carousel *ngIf="banners" [showNavigationIndicators]="true">
        <ng-template ngbSlide *ngFor="let image of banners">
          <div class="picsum-img-wrapper">
            <img [src]="image.image|translate" alt="insurance" class="insurance-carousel__img" />
          </div>
        </ng-template>
      </ngb-carousel>
    </div>

    <div class="insurance-readonly">
      <img *ngFor="let image of banners" [src]="image.image|translate" alt="insurance" class="insurance-readonly__img" />
    </div>
  </div>
</div>

<!-- <div class="promise">
  <div class="container">
    <app-content-title [label]="'PROMISE.LABEL'|translate" class="app-block__head"></app-content-title>
    <p class="promise__content">
      Là website bán bảo hiểm trực tuyến cho các khách hàng trên toàn quốc cung cấp những sản phẩm bảo hiểm tốt nhất tới
      khách hàng. Mô hình đại lý bảo hiểm công nghệ đầu tiên tại Việt Nam. Trợ lý ảo đầu tiên trong lĩnh vực bảo hiểm.
      Sàn bán bảo hiểm trực tuyến. Nơi mà giải pháp công nghệ số về bảo hiểm đầu tiên được lên ngôi. Nơi thu nhập được
      nâng cao lên một tầm cao mới. Đăng ký thành viên hưởng hoa hồng trực tuyến. Hỗ trợ bồi thường trực tuyến cho khách
      hàng bảo hiểm. Tạo dựng một mạng lưới khách hàng vững chắc, cùng sự chăm sóc tận tình thân thiết.
    </p>
    <button type="button" id="promise__content-more" class="btn btn-link" (click)="openModalPromise(promise_modal)">Chi
      tiết</button>

    <ng-template #promise_modal let-offcanvas>
      <div class="offcanvas-header line">
        <p class="offcanvas-title__modal">{{'PROMISE.MODAL'|translate}}</p>
        <button type="button" class="btn-close" aria-label="Close" (click)="offcanvas.dismiss('Cross click')"></button>
      </div>
      <div class="offcanvas-body">
        <p class="promise-modal__content">
          Mua bảo hiểm tại Finme Nhanh - Gọn - Tiết kiệm thời gian Nhanh gọn nhẹ, giao dịch trực tuyến, ko tốn hồ sơ
          giấy tờ, thời gian đi lại Được mua trực tiếp các sản phẩm Bảo hiểm chính hãng của các công ty bảo hiểm hàng
          đầu tại Việt Nam Hãng bảo hiểm uy tín, ngân hàng uy tín (VBI, PTI, MIC...) Tham gia vào mạng lưới cộng tác
          viên và kiếm thêm thu nhập nhờ giới thiệu khách hàng hoặc trực tiếp bán sản phẩm...
        </p>
      </div>
    </ng-template>

    <div class="promise-card">
      <app-promise-card *ngFor="let promise of promises" [label]="promise.label" [childLabel]="promise.child"
        [getStyle]="promise.position"></app-promise-card>
    </div>
    <div class="news-carousel">
      <div class="container">
        <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slidenewConfig"
          (init)="slickInit($event)" (breakpoint)="breakpoint($event)" (afterChange)="afterChange($event)"
          (beforeChange)="beforeChange($event)">
          <div ngxSlickItem *ngFor="let blog of ableNews">
            <div class="news-carousel-card" routerLink="/news/{{blog.metaTitle}}">
              <div class="news__upper">
                <img class="news__img" [src]="blog.image" />
              </div>
              <p class="news__title">{{blog.name}}</p>
              <p class="news__content">{{blog.description}}</p>
            </div>
          </div>
        </ngx-slick-carousel>
      </div>
    </div>
  </div>
</div> -->

<div class="app-block bg-blue">
  <div class="container">
    <div class="app-block__description">
      <app-content-title [label]="'APP.LABEL'|translate" [center]="false" [line]="false" class="app-block__head">
      </app-content-title>
      <p class="app-block__child-head">
        {{'APP.HEAD'|translate}}
      </p>
      <div class="app-block__policies">
        <div class="app-block__policy" *ngFor="let policy of policies">
          <span class="material-icons">star_border</span>
          <p class="app-block__policy-label">{{ policy.label | translate }}</p>
        </div>
      </div>
      <div class="app-block__download">
        <span>{{'APP.DOWNLOAD'|translate}}</span>
        <div class="app-block__download--url">
          <a target="_blank" href="https://play.google.com/store/apps/details?id=com.abank.kitiapp&hl=vi">
            <img src="../../../assets/images/gg play.png" alt="on CH Play" />
          </a>
          <a target="_blank"
            href="https://apps.apple.com/vn/app/finme-pro-b%E1%BA%A3o-hi%E1%BB%83m-s%E1%BB%91/id6449207017?l=vi">
            <img src="../../../assets/images/App Store.png" alt="on App Store" />
          </a>
        </div>
      </div>
    </div>
    <img src="../../../assets/Upload/images/Iphone4.png" alt="finme-app" class="app-block__thumb"
      (click)="openModalPromise(download_modal)" />
    <a class="btn btn-primary" id="app-block__install" (click)="openModalPromise(download_modal)" role="button">
      {{'APP.BUTTON'|translate}}
    </a>
  </div>

  <ng-template #download_modal let-offcanvas>
    <div class="offcanvas-header line">
      <p class="offcanvas-title__modal">{{'APP.LABEL'|translate}}</p>
      <button type="button" class="btn-close" aria-label="Close" (click)="offcanvas.dismiss('Cross click')"></button>
    </div>
    <div class="offcanvas-body">
      <div class="app-block__download--url">
        <span>{{'APP.DOWNLOAD'|translate}}</span>
        <a target="_blank" href="https://play.google.com/store/apps/details?id=com.abank.kitiapp&hl=vi">
          <img src="../../../assets/images/gg play.png" alt="on CH Play" />
        </a>

        <a target="_blank"
          href="https://apps.apple.com/vn/app/finme-pro-b%E1%BA%A3o-hi%E1%BB%83m-s%E1%BB%91/id6449207017?l=vi">
          <img src="../../../assets/images/App Store.png" alt="on App Store" />
        </a>
      </div>
    </div>
  </ng-template>
</div>

<!-- <div class="feedback">
  <div class="container">
    <app-content-title [upper]="'Những gì khách hàng của chúng tôi'" [label]="'đang nói'" class="feedback__head">
    </app-content-title>

    <div class="feedback__carousel">
      <ngb-carousel *ngIf="feedbacks" [showNavigationIndicators]="true">
        <ng-template ngbSlide *ngFor="let item of feedbacks">
          <div class="picsum-img-wrapper">
            <app-customers [image]="item.image" [name]="item.customer" [feedback]="item.feedback"></app-customers>
          </div>
        </ng-template>
      </ngb-carousel>
    </div>

    <div class="feedback__scroller" *ngIf="feedbacks">
      <div class="" *ngFor="let item of feedbacks">
        <app-customers [image]="item.image" [name]="item.customer" [feedback]="item.feedback"></app-customers>
      </div>
    </div>
  </div>
</div> -->

<div class="ourpartner">
  <div class="container">
    <p class="ourpartner__head">{{'PARTNER.HEAD'|translate}}</p>
    <p class="ourpartner__child-head">
      {{'PARTNER.CHILDHEAD'|translate}}
    </p>
    <div class="partners">
      <img *ngFor="let partner of ourPartners" src="{{ partner.image }}" alt="{{ partner.name }}" class="partner" />
    </div>
  </div>
</div>

<div class="help">
  <div class="container">
    <app-content-title [upper]="'HELP.UPPER'|translate" [label]="'HELP.LABEL'|translate" class="help__head">
    </app-content-title>
    <div class="help__block">
      <img src="../../../assets/images/home-v1/help.png" alt="finme-ho-tro" class="help__thumb" />
      <div class="">
        <p class="help__content">
          {{'HELP.CONTENT'|translate}}
        </p>
        <app-help-card *ngFor="let info of helpInfo" [href]="info.href" [icon]="info.icon" [head]="info.head|translate"
          [info]="info.info"></app-help-card>
      </div>
    </div>
  </div>
</div>

<div class="brands">
  <div class="container">
    <app-content-title [label]="'BRANDS'|translate" class="brands__head"></app-content-title>
    <div class="brands__brand">
      <a href="{{ brand.url }}" class="brand__url" *ngFor="let brand of brands">
        <img src="{{ brand.image }}" alt="{{ brand.name }}" class="brand__img" />
      </a>
    </div>
  </div>
</div>
