import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router'
import { User } from '@models/user.model';
import { SignContractService } from '@services/sign-contract.service';
import { ToastService } from '@services/toast.service';

@Component({
  selector: 'app-sign-contract',
  templateUrl: './sign-contract.component.html',
  styleUrls: ['./sign-contract.component.css']
})
export class SignContractComponent implements OnInit {
  @Input() isWebview: boolean = false
  user: User = {
    ma_nv: '',
    ho_ten: '',
    cmnd: '',
    so_dien_thoai: '',
    email: '',
    ma_nvgt: '',
    ma_tvht: '',
    k_referral_id: '',
    k_status: 0
  }
  users:any=[]
  isCollapsed: boolean = true;
  isCollapsed0: boolean = true;
  isChecked: boolean = false;
  day: any = new Date().getDate();
  month: any = new Date().getMonth() + 1;
  year: any;
  fullYear: any = new Date().getFullYear().toString();
  contractID: any;
  name: string = '';
  birthday: string = '';
  cardID: string = '';
  tax: string = '';
  address: string = '';
  email: string = '';
  phone: string = '';
  userCode: string = '';
  currentLang: string = 'vi'
  constructor(
    private route: ActivatedRoute,
    private _sign: SignContractService,
    public _toast: ToastService,
    private _router: Router,
  ) { }

  ngOnInit(): void {
    this.getParams()
    this.year = this.fullYear.slice(2, 4)
    if (this.month < 10) {
      this.month = `0${this.month}`
    }
    else {
      this.month = this.month.toString()
    }
    this.contractID = Math.round(Math.random() * 1000);
    if (this.contractID < 10) {
      this.contractID = `00${this.contractID}`
    }
    else if (this.contractID < 100) {
      this.contractID = `0${this.contractID}`
    }
    else {
      this.contractID = this.contractID.toString()
    }
    this.contractID = `${this.day}${this.month}${this.year}${this.contractID}`;
  }

  getParams() {
    // Note: Below 'queryParams' can be replaced with 'params' depending on your requirements
    this.route.queryParams.subscribe((params) => {
      this.name = params['name']
      this.birthday = params['birthday']
      this.cardID = params['cardID']
      this.tax = params['tax']
      this.address = params['address']
      this.email = params['email']
      this.phone = params['phone']
      this.userCode = params['usercode']
    })
  }

  getUserbyID(ma_nv: string) {
    this._sign.getUserbyID(ma_nv, 'vi').subscribe(res => {
      this.users = res
      this.user = this.users[0]
      this.putUserbyID()
    })
  }

  putUserbyID() {
    let data = {...this.user};
    data.k_status = 3
    this._sign.putUserbyID(data, 'vi').subscribe(res => {
      this._router.navigateByUrl('/webview/notification')
    })
  }

  onClickToSign(){
    this.getUserbyID(this.userCode);
  }
}
