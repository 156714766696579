import { OTO_STEPS } from './../../../utils/steps/index';
import { AuthService } from '@services/auth/auth.service';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ERROR, VALID } from 'src/utils/validation';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import { LOGIN_POINTERS, LOGIN_USPS } from 'src/utils/constant';
import { TokenService } from '@services/token.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastService } from '@services/toast.service';
import { ProtectService } from '@services/protect/protect.service';
import { ApiService } from '@services/api.service';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { CommonService } from '@services/common/common.service';
import { data } from 'jquery';

@Component({
  selector: 'app-contract-meetvoucher',
  templateUrl: './contract-meetvoucher.component.html',
  styleUrls: ['./contract-meetvoucher.component.css']
})
export class ContractMeetvoucherComponent implements OnInit {
  isShow: boolean = false
  showType: string = 'password'
  isRegister: boolean = false
  isLoggedIn: boolean = false;
  isLoginFailed: boolean = false;
  isForget: boolean = false;
  submitted: boolean = false;
  message: string = '';
  subLabel: string = 'COMMON.LOGIN'
  session: any
  hasParent: boolean = true
  disableInput: boolean = false
  meetVoucher: any = ''

  login: FormGroup = this._formBuilder.group({
    username: ['', Validators.required],
    password: ['', Validators.required],
  })

  getPass: FormGroup = this._formBuilder.group({
    verify: ['', Validators.required],
    email: ['', [Validators.required,]],
    mat_khau: ['', [Validators.required, Validators.minLength(8)]],
    xac_nhan: ['', [Validators.required, Validators.minLength(8)]],
  },
    {
      validators: [this._service.match('mat_khau', 'xac_nhan')]
    })

  register: FormGroup = this._formBuilder.group(
    {
      hoten: ['', Validators.required],
      so_dien_thoai: ['', [Validators.required, Validators.pattern(VALID.PHONE)]],
      // verify: ['', Validators.required],
      email: ['', [Validators.required,]],
      meetVoucher: [''],
      meetVoucherInfo: [''],
    },
    {
      validators: [this._service.match('mat_khau', 'xac_nhan')]
    }
  )
  windowRef: any;
  phone: any;
  user: any;
  errorVerify: boolean = true
  errorVerify2: boolean = false
  public stepValue = 1
  private mailOTP: string = ''
  isReadOnly: boolean = false
  isMailVerify: boolean = false
  isNotValid: boolean = true
  meetVoucherData: any
  isTrueVoucher: boolean = false

  constructor(
    private _common: CommonService,
    private titleService: Title,
    private _service: AuthService,
    private tokenStorage: TokenService,
    private _formBuilder: FormBuilder,
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private _router: Router,
    public _toast: ToastService,
    private win: ProtectService,
    private api: ApiService

  ) {
    this.handleUserCode()
  }

  ngOnInit(): void {
    this.meetVoucher = this.route.snapshot.paramMap.get('meetvoucher')
    this.getMeetVoucherInfo(this.meetVoucher)
    this.register.controls['meetVoucher'].setValue(this.meetVoucher)
  }

  // convenience getter for easy access to form fields
  get l() { return this.login.controls; }
  get g() { return this.getPass.controls; }
  get r() { return this.register.controls; }

  handleUserCode() {
    const user = this.route.snapshot.params['user']
    user ? (
      this.disableInput = true,
      this.r['nguoi_gioi_thieu'].setValue(user),
      this.titleService.setTitle('Finme - Register'),
      this.subLabel = 'COMMON.REGISTER',
      this.isRegister = true
    ) : (
      this.disableInput = false,
      this.hasParent = false
    )
  }

  getMeetVoucherInfo(voucher: string) {
    this._common.getMeetVoucherInfo(voucher).subscribe((data) => {
      this.meetVoucherData = data
      this.isTrueVoucher = true
      if (data.data.type == 'BHLKDT1') {
        this.register.controls['meetVoucherInfo'].setValue('Voucher Bảo hiểm liên kết đầu tư trị giá 10 triệu')
      } else if (data.data.type == 'BHLKDT2') {
        this.register.controls['meetVoucherInfo'].setValue('Voucher Bảo hiểm liên kết đầu tư trị giá 20 triệu')
      } else if (data.data.type == 'BHLKDT3') {
        this.register.controls['meetVoucherInfo'].setValue('Voucher Bảo hiểm liên kết đầu tư trị giá 30 triệu')
      } else {
        this.register.controls['meetVoucherInfo'].setValue('Voucher không đúng!')
        this.isTrueVoucher = false
      }
    })
    this.meetVoucherData ? null : this.register.controls['meetVoucherInfo'].setValue('Voucher không đúng!')
  }

  // onLogin(f: any, warming: TemplateRef<any>, notice: TemplateRef<any>) {
  //   this.submitted = true

  //   if (f.valid) {
  //     const payload = {
  //       username: this.login.controls['username'].value,
  //       password: this.login.controls['password'].value
  //     }

  //     this._service.onLogin(payload).subscribe(data => {
  //       if (data.token) {
  //         this._toast.show('LOGIN.SUCCESS', { icon: 'check_circle', classname: 'bg-success text-light' });

  //         this.tokenStorage.saveToken(data.token);
  //         this.tokenStorage.saveUser({
  //           role: data.role,
  //           username: data.username,
  //           ma_nv: data.ma_nv,
  //           ho_ten: data.ho_ten,
  //         });

  //         this.isLoginFailed = false;
  //         this.isLoggedIn = true;
  //         window.location.href = '/'
  //       } else {
  //         this._toast.show('LOGIN.FAIL', { icon: 'error', classname: 'bg-danger text-light' });
  //         this.message = 'LOGIN.NOTI_CONTENT2'
  //         this.modalService.open(notice, { centered: true })
  //       }
  //     })
  //   } else this.modalService.open(warming, { centered: true })
  // }

  onRegister(f: any, warming: TemplateRef<any>) {
    this.submitted = true
    let dataRef: any = undefined
    if (f.valid) {
      const payload = {
        id: 0,
        ho_Ten: this.register.controls['hoten'].value,
        sdt: this.register.controls['so_dien_thoai'].value,
        email: this.register.controls['email'].value,
        voucher_Meet: this.register.controls['meetVoucher'].value,
        thong_Tin_Voucher: this.register.controls['meetVoucherInfo'].value
      }
      console.log(payload)
      this.api.postMeetVoucherInfo(payload).subscribe(
        data => {
          dataRef = data
          this.message = 'Đăng ký thành công!'
          this.modalService.open(warming, { centered: true })
        }
      )
      if (dataRef == undefined) {
        this.message = 'Lỗi: Voucher đã được đăng ký hãy kiểm tra lại!'
        this.modalService.open(warming, { centered: true })
      }
      // this._service.onRegister(payload).subscribe(
      //   data => {
      //     // console.log(data);
      //     if (data.message === 'ok') {
      //       this._toast.show('LOGIN.RES_SUCCESS', { icon: 'check_circle', classname: 'bg-success text-light' });
      //       this.message = 'LOGIN.RES_SUCCESS_CONTENT'
      //       this.modalService.open(warming, { centered: true })
      //       this.formHandler(false)
      //       this._router.navigateByUrl('/download')
      //     }
      //     else if (data.message === 'Email không hợp lệ') {
      //       this._toast.show('ERROR.EMAIL', { icon: 'error', classname: 'bg-danger text-light' });
      //     } else {
      //       this._toast.show('LOGIN.RES_FAIL', { icon: 'error', classname: 'bg-danger text-light' });
      //       this._toast.show('LOGIN.SAMEID', { icon: 'error', classname: 'bg-danger text-light' });
      //       this.message = 'LOGIN.SAMEID_CONTENT'
      //       this.modalService.open(warming, { centered: true })
      //     }
      //   })
    }
  }

  // onSendCode() {
  //   let phone = ''
  //   if (this.r['so_dien_thoai'].value) {
  //     phone = this.r['so_dien_thoai'].value.replace('0', '+84')
  //   }
  //   const appVerifier = this.windowRef.recaptchaVerifier;
  //   // const appVerifier = '';

  //   firebase.auth().signInWithPhoneNumber(phone, appVerifier)
  //     .then((result: any) => {
  //       this.windowRef.confirmationResult = result;
  //       this._toast.show('LOGIN.SEND', { icon: 'check_circle', classname: 'bg-success text-light' });
  //       this._toast.show('LOGIN.CHECK_PHONE', { icon: 'smartphone', classname: 'bg-white text-body' });
  //     })
  //     .catch((error: any) => {
  //       this._toast.show('LOGIN.SEND_FAIL', { icon: 'error', classname: 'bg-danger text-light' });
  //     });
  // }

  // sendMailOTP() {
  //   if (this.mailOTP == '') {
  //     this._service.getEmailOTP(this.getPass.controls['email'].value).subscribe(data => {
  //       if (data.res && data.otp) {
  //         this.mailOTP = data.otp;
  //         this.isReadOnly = true
  //         this._toast.show('LOGIN.SEND', { icon: 'check_circle', classname: 'bg-success text-light' });
  //         setTimeout(() => {
  //           this.mailOTP = ''
  //         }, 300000)
  //       }
  //       else {
  //         this._toast.show('LOGIN.SEND_FAIL', { icon: 'error', classname: 'bg-danger text-light' });
  //       }
  //     })
  //   }
  //   else {
  //     this._toast.show('LOGIN.SENT', { icon: 'error', classname: 'bg-danger text-light' });
  //   }
  // }

  // onVerify2() {
  //   if (this.getPass.controls['verify'].value.length < 4 && this.isMailVerify == false) {
  //     return
  //   }
  //   if (this.getPass.controls['verify'].value == this.mailOTP) {
  //     this._toast.show('LOGIN.VERIFY_SUCCESS', { icon: 'check_circle', classname: 'bg-success text-light' });
  //     this.isMailVerify = true
  //   }
  //   else {
  //     this.isMailVerify = false
  //     this._toast.show('LOGIN.VERIFY_FAIL', { icon: 'error', classname: 'bg-danger text-light' });
  //   }
  //   this.onCheckValid()
  // }
  // onCheckValid() {
  //   (this.isMailVerify && this.getPass.controls['mat_khau'].value == this.getPass.controls['xac_nhan'].value) ? this.isNotValid = false : this.isNotValid = true
  // }

  // onChangePass() {
  //   let payload = {
  //     email: this.getPass.controls['email'].value,
  //     mat_khau: this.getPass.controls['mat_khau'].value
  //   }
  //   this._service.onChangePass(payload).subscribe(data => {
  //     if (data.message == 'ok') {
  //       this._toast.show('COMMON.CHANGE_PASS_S', { icon: 'error', classname: 'bg-success text-light' });
  //       setTimeout(() => window.location.href = '/login', 2000)
  //     }
  //     else {
  //       this._toast.show('CHANGE_PASS_F', { icon: 'error', classname: 'bg-danger text-light' });
  //     }
  //   })
  // }


  onCapitalize(): void {
    this.register.controls['hoten'].setValue(this._common.onCapitalize(this.register.controls['hoten'].value))
  }

  // onVerify() {
  //   if (this.r['verify'].value.length < 6) {
  //     return
  //   }

  //   this.windowRef.confirmationResult.confirm(this.r['verify'].value)
  //     .then((result: any) => {
  //       this.user = result.user
  //       this.errorVerify = false
  //       this._toast.show('LOGIN.VERIFY_SUCCESS', { icon: 'check_circle', classname: 'bg-success text-light' });
  //     })
  //     .catch((error: any) => {
  //       console.log(error, "Incorrect code entered?")
  //       this.errorVerify = true
  //       this._toast.show('LOGIN.VERIFY_FAIL', { icon: 'error', classname: 'bg-danger text-light' });
  //     });
  // }

  // onShow() {
  //   this.isShow = !this.isShow
  //   this.showType = this.isShow ? 'text' : 'password'
  // }

  // formHandler(isRegister: boolean) {
  //   this.isRegister = isRegister
  //   this.subLabel = isRegister ? 'COMMON.REGISTER' : 'COMMON.LOGIN'
  //   this.titleService.setTitle(`Finme - Login`)
  // }

  // formHandler1() {
  //   this.isForget = !this.isForget
  //   this.subLabel = 'LOGIN.RECOVER'
  //   this.titleService.setTitle(`Finme - Get your password`)
  // }

  onController(f: any, content: TemplateRef<any>) {


    this.modalService.open(content, { centered: true })

  }

  valid = VALID
  error = ERROR
  pointers = LOGIN_POINTERS
  usps = LOGIN_USPS
}
