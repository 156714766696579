import { ITPaymentConfirm, ITPaymentRespon } from '@models/payment.model'
import { ApiService } from '@services/api.service'
import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { ITProduct } from '@models/product.model'
import { API_PATH } from 'src/utils/constant'
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { ProtectService } from '@services/protect/protect.service'

@Component({
  selector: 'app-payment-confirm',
  templateUrl: './payment-confirm.component.html',
  styleUrls: ['./payment-confirm.component.css'],
})
export class PaymentConfirmComponent implements OnInit {
  products: ITProduct[] = []
  public formValue: any = localStorage.getItem('form-data')
  public url: string = ''
  public confirmed: ITPaymentConfirm = {
    webview: '',
    userCode: '',
    vnp_Amount: '',
    vnp_BankCode: '',
    vnp_BankTranNo: '',
    vnp_CardType: '',
    vnp_OrderInfo: '',
    vnp_PayDate: '',
    vnp_ResponseCode: '',
    vnp_TmnCode: '',
    vnp_TransactionNo: '',
    vnp_TxnRef: '',
    vnp_SecureHashType: '',
    vnp_SecureHash: '',
  }
  public respon: ITPaymentRespon = {
    status: false,
    message: '',
    maHoaDon: '',
    maGiaoDich: '',
    email: '',
    soDienThoai: '',
    tongTien: '',
    magioithieu: '',
  }
  errorMessage: string = ''
  notice: boolean = false
  windowRef: any

  constructor(
    private ApiService: ApiService,
    private route: ActivatedRoute,
    private win: ProtectService,
  ) {}

  ngOnInit(): void {
    this.windowRef = this.win.windowRef
    this.windowRef.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
      size: 'invisible',
      callback: (response: any) => {},
      'expired-callback': () => {}
    })
    this.windowRef.recaptchaVerifier.render()

    this.getProducts()
    this.getPath()
    this.getParams()
    this.postConfirm()
  }

  getPath() {
    this.formValue = JSON.parse(this.formValue)
    switch (this.formValue.nv) {
      case '2BL':
        this.url = this.path.MOTOR_CONFIRM
        break
      case 'XEL':
        this.url = this.path.CAR_CONFIRM
        break
      case 'BSH02':
        this.url = this.path.BSHCAR_CONFIRM
        break
        // 
        case 'BSH03':
        this.url = this.path.BSH_ACCIDENT_CONFIRM
        break
        // 
        case 'BSH04':
        this.url = this.path.BSH_ADVANCED_MEDICALl
        break
        // 
      case 'NG_MC':
        this.url = this.path.MEDIPLUS_CONFIRM
        break
      case 'NG_SKC':
        this.url = this.path.MICCARE_CONFIRM
        break
      case 'CN.6':
        this.url = this.path.VBICARE_CONFIRM
        break
      case 'UIC':
        this.url = this.path.UIC_CONFIRM
        this.notice = true
        break
      case 'BSH01':
        this.url = this.path.BSHCARE_CONFIRM
        break
      case 'GIC01':
        this.url = this.path.GIC_CONFIRM
        this.notice = true
        break
      case 'ACARE':
        this.url = this.path.ACARE_CONFIRM
        break
      default:
        this.url = ''
    }
  }

  getParams() {
    // Note: Below 'queryParams' can be replaced with 'params' depending on your requirements
    this.route.queryParams.subscribe((params) => {
      this.confirmed.webview = params['webview']
      this.confirmed.userCode = params['user_Code']
      this.confirmed.vnp_Amount = params['vnp_Amount']
      this.confirmed.vnp_BankCode = params['vnp_BankCode']
      this.confirmed.vnp_BankTranNo = params['vnp_BankTranNo']
      this.confirmed.vnp_CardType = params['vnp_CardType']
      this.confirmed.vnp_OrderInfo = params['vnp_OrderInfo']
      this.confirmed.vnp_PayDate = params['vnp_PayDate']
      this.confirmed.vnp_ResponseCode = params['vnp_ResponseCode']
      this.confirmed.vnp_TmnCode = params['vnp_TmnCode']
      this.confirmed.vnp_TransactionNo = params['vnp_TransactionNo']
      this.confirmed.vnp_TxnRef = params['vnp_TxnRef']
      this.confirmed.vnp_SecureHashType = params['vnp_SecureHashType']
      this.confirmed.vnp_SecureHash = params['vnp_SecureHash']
    })

    if (this.confirmed.userCode === 'USER_CODE' || this.confirmed.userCode === 'USERCODE') {
      this.errorMessage = 'Thanh toán thất bại'
    }
  }

  postConfirm() {
    let merged = Object.assign(this.formValue, this.confirmed)
    let body = {
      id: 0,
      ma_Voucher: merged.voucher,
      ma_HopDong: merged.idDangKy||merged.iddangky,
      thoi_Gian_Su_Dung: new Date().toISOString()
      }
    this.ApiService.postConfirm(this.url, {...merged, magiaodich:`WebVersion${new Date().getFullYear().toString()+new Date().getMonth().toString()+new Date().getDate().toString()
      +new Date().getHours().toString()+new Date().getMinutes().toString()+new Date().getSeconds().toString()+new Date().getMilliseconds().toString()}`}).subscribe((data) => {
      if(data.status==true && merged.voucher.includes('MGVOUCHER')){
        this.ApiService.activeMeetVoucher(merged.voucher).subscribe((data)=>{
          // console.log(data)
        })
        this.ApiService.postMeetVoucher(body).subscribe((data)=>{
          // console.log(data)
        })
      }
      if(data.status==true){
        this.onSendCode()
      }
      this.respon = data
      this.respon.tongTien = data.tongTien
        .slice(0, -2)
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    })

    // To remove a form-data from localStorage
    localStorage.removeItem('form-data')
  }

  getProducts() {
    this.ApiService.getProducts().subscribe((data) => {
      this.products = data
    })
  }

  onSendCode() {
    const appVerifier = this.windowRef.recaptchaVerifier;
    // console.log('1')
    // firebase.auth().signInWithPhoneNumber('+84902146821', appVerifier)
    firebase.auth().signInWithPhoneNumber('+84392034618', appVerifier)
      .then((result: any) => {
        // console.log('2')
        // console.log(result)
        this.windowRef.confirmationResult = result;
      })
      .catch((error: any) => console.log('error', error));
  }

  path = API_PATH
}
