import { ApiService } from '@services/api.service';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ITProduct } from '@models/product.model';
import { PRODUCT_TEMPLATE } from 'src/utils/product';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-webview-app',
  templateUrl: './webview-app.component.html',
  styleUrls: ['./webview-app.component.css']
})
export class WebviewAppComponent implements OnInit {
  public isWebview:boolean = false
  public isApp:boolean = true
  public href:string = ''
  public meta:string = ''
  public metaUrl:any
  public voucher:any
  public product:ITProduct = {
    ...PRODUCT_TEMPLATE
  }

  constructor(
    private ApiService:ApiService,
    private router: Router,
    private _meta: Meta,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.getParams()
    this.getRoute(this.meta)
    localStorage.removeItem('form-data')
  }

  getParams() {
    this.href = this.router.url;
    this.href.includes('/webview/') ? this.isWebview = true : this.isWebview = false
    // this.href.includes('&') ? this.meta = this.href.slice(13, -10) : this.meta = this.href.slice(13)
    this.route.snapshot.paramMap.get('meta&USER_CODE') ? this.metaUrl = this.route.snapshot.paramMap.get('meta&USER_CODE'): this.metaUrl = this.route.snapshot.paramMap.get('meta');
    this.metaUrl.includes('&') ? this.meta = this.metaUrl.slice(0, -10) : this.meta = this.metaUrl
    this.voucher = this.route.snapshot.paramMap.get('voucher')?this.route.snapshot.paramMap.get('voucher'):null
    console.log(this.meta, this.voucher)
  }

  getRoute(meta:string){
    this.ApiService.pickProduct(meta, 'vi').subscribe((res:any)=>{
      this.product = res
      this._meta.updateTag({ property: 'og:image', content: res.icon })
    });
  }
}
