import { Component, Input, OnInit } from '@angular/core';
import { environment } from '@environments/environment';
import { ITCategory } from '@models/category.model';
import { ITProduct } from '@models/product.model';

@Component({
  selector: 'app-mega-menu',
  templateUrl: './mega-menu.component.html',
  styleUrls: ['./mega-menu.component.css']
})
export class MegaMenuComponent implements OnInit {
  prodEnv:boolean = environment.production
  public isProductCollapsed = true;
  public isPolicyCollapsed = true;
  public isContactCollapsed = true;

  @Input() categories: ITCategory[] = []
  @Input() products: ITProduct[] = []

  constructor() { }

  ngOnInit(): void {
  }
}
