import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.css']
})
export class ButtonComponent implements OnInit {
  @Input() label = ''
  @Input() icon = ''
  @Input() isIcon = false
  @Input() isFill = false
  @Input() isBorder = false
  @Input() getStyle = ''

  constructor() { }

  ngOnInit(): void {
  }

}
