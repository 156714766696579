import { now } from "../product"
import { GIC_DURATIONS, GIC_DYNAMIC_POLICY, GIC_RESPONS, BSH03_RESPONS, BSH03_DYNAMIC_POLICY, BSH03_DURATIONS, BSH04_DURATIONS, BSH04_RESPONS, BSH04_DYNAMIC_POLICY } from "../product/accident"
import { BSH_DURATIONS, BSH_DYNAMIC_POLICY, BSH_RESPONS } from "../product/bsh"
import { UIC_DURATIONS, UIC_DYNAMIC_POLICY, UIC_RESPONS } from "../product/uic"

export const API_PATH = {
  AUTH: 'api/Authentication/login',
  REGISTER: 'api/Authentication/DangKyCongTacVien',
  GET_MAIL_OTP: 'api/Authentication/GetOTP',
  CHANGE_PASS: 'api/Authentication/Update_MatKhau',
  GPAYREGISTER: 'api/Gpay/VA_Create',
  ACTIVE: 'api/Authentication/ActiveAccount',
  GET_FEE: 'api/GetPhi',
  AREA: 'api/KhuVuc',
  GETVCMEET: 'v1/internal/voucher/checkInforVoucher',
  ACTIVEVCMEET: 'v1/internal/voucher/useVoucher',
  POSTVCMEET: 'api/FinShop_Meet',
  POSTVCMEETINFO: 'api/FinShop_TuVan',

  MOTOR_FEE: 'api/GetPhi/Moto',
  MOTOR_CONFIRM: 'api/ThanhToan/Moto_PaymentConfirm',
  CAR_FEE: 'api/GetPhi/Oto',
  CAR_CONFIRM: 'api/ThanhToan/Car_PaymentConfirm',
  BSHCAR_FEE: 'api/GetPhi/Oto_BSH',
  BSHCAR_CONFIRM: 'api/ThanhToan/Car_BSH_PaymentConfirm',
  BSH_ACCIDENT_CONFIRM: 'api/ThanhToan/BSH_TaiNan_PaymentConfirm',
  BSH_ADVANCED_MEDICALl: 'api/ThanhToan/BSH_YTe_PaymentConfirm',
  MEDIPLUS_FEE: 'api/GetPhi/Mediplus',
  MEDIPLUS_CONFIRM: 'api/ThanhToan/MediPlus_PaymentConfirm',
  MICCARE_FEE: 'api/GetPhi/MicCare',
  MICCARE_CONFIRM: 'api/ThanhToan/MicCare_PaymentConfirm',
  VBICARE_FEE: 'api/GetPhi/SucKhoe',
  VBICARE_CONFIRM: 'api/ThanhToan/SucKhoe_PaymentConfirm',
  UIC_FEE: 'api/GetPhi/UIC',
  UIC_CONFIRM: 'api/ThanhToan/UIC_PaymentConfirm',
  BSHCARE_FEE: 'api/GetPhi/BSH',
  BSHCARE_CONFIRM: 'api/ThanhToan/BSH_PaymentConfirm',
  GIC_FEE: 'api/GetPhi/GIC',
  BSH03_FEE: 'api/GetPhi/BSH_TaiNan',
  BSH04_FEE: 'api/GetPhi/BSH_YTe',
  GIC_CONFIRM: 'api/ThanhToan/GIC_PaymentConfirm',
  ACARE_FEE: 'api/GetPhi/ACare',
  ACARE_CONFIRM: 'api/ThanhToan/ACare_PaymentConfirm',

  CAR_BRANDS: 'api/HangXe',
  CAR_SERIES: 'api/HieuXe',
  PRODUCT_LIST: 'api/Product',
  PRODUCTBYNAME:'api/Voucher/getSanPham',
  COMPANIES: 'api/Product/Congty',
  CATEGORIES: 'api/ProductCategory',
  PAYMENT: 'api/ThanhToan/Payment',

  VOUCHER: 'api/Voucher',
  VOUCHER_BY_ID: 'api/Voucher/',
  DISCOUNT: 'api/Voucher/phantram/',
  APPDISCOUNT: 'api/VoucherApp/GetDataByMaVoucher/',
  LAUNCHER: 'api/Voucher/nguoigioithieu/',
  LAUNCHER_PHONE: 'api/Authentication/GetCongTacVienSDT/',

  UPLOAD: 'api/Upload/upload',
  UPLOADS: 'api/Upload/uploads',
  CONTRACT: 'api/DangKySanPham',
  ACC: 'api/DangKySanPham_TichLuy',
  PUTMATERIAL:'api/DangKySanPham/DangKy_VatChatXe/Put',
  MATERIALLIST: 'api/DangKySanPham/DangKy_VatChatXe/GetAll',
  MATERIAL : 'api/DangKySanPham/DangKy_VatChatXe/Get',
  USER_LIST: 'api/Authentication/GetF1CongTacVien',
  NGUOI_DS: 'api/DangKySanPham/GetDangKy_NGUOI_DS',
  PERSONP: 'api/DangKySanPham/GetDangKy_Acare',
  BSHCar_CONTRACT: 'api/DangKySanPham/GetDangKy_OtoBSH',
  CONTENT: 'api/Content',
  GET_CTV: 'api/Authentication/GetCongTacVien',
  PUT_CTV: 'api/Authentication/CapNhatCongTacVien',
  UPDATE_K_MANAGER: 'api/Authentication/Update_K_Manager',

  DASHBOARD: 'api/Common/ThongKe',
  CONTRACT_PAGE_NUMBERS: 'api/DangKySanPham/SoluongPage',

  COMPARE_PACKAGES: 'api/SoSanh/Goi',
  COMPARE_PARTNERS: 'api/SoSanh/NCC',
  COMPARE_AGES: 'api/SoSanh/Tuoi',
  COMPARE_FEE_RANGE: 'api/SoSanh/KhoangGia',
  COMPARE_QLGOI: 'api/SoSanh/QuyenLoi_Goi',
  COMPARE_POLICIES: 'api/SoSanh/QuyenLoi_List',
  COMPARE_PRODUCTIONS: 'api/SoSanh/SanPham_TatCa',
  COMPARE_BY_FEE_RANGE: 'api/SoSanh/SoSanh_KhoangGia',

  VBICARE_PACKAGES: 'api/p/list_insurance_package?nhom=FINLIFE',
}

export const GENDERS = [
  { id: 1, value: 'Nam', vie: 'NAM', label: 'COMMON.MALE', label_api: 'Nam' },
  { id: 2, value: 'Nữ', vie: 'NU', label: 'COMMON.FEMALE', label_api: 'Nữ' },
  // { id: 3, value: 'other', vie: 'KHAC', label: 'Khác' },
]

export const CONTENT_STEPS = [
  { id: 0, value: 0, label: 'SWITCHER.LABEL1', icon: 'redeem', href: 'body' },
  { id: 1, value: 1, label: 'SWITCHER.LABEL2', icon: 'info', href: 'loiIch' },
  { id: 2, value: 2, label: 'SWITCHER.LABEL3', icon: 'gpp_good', href: 'quyTrinh' },
  { id: 3, value: 3, label: 'SWITCHER.LABEL4', icon: 'more', href: 'gioiThieu' },
]

export const HOME_PROMISE_INNER = [
  {
    id: 1,
    label: '+ 30.000',
    content:
      'PROMISEINNER.CONTENT1',
  },
  {
    id: 2,
    label: 'PROMISEINNER.LABEL2',
    content:
      'PROMISEINNER.CONTENT2',
    image: '../../../assets/Upload/images/trademark/B.png',
  },
]

export const HOME_USPS = [
  {
    id: 1,
    color: 'purple',
    icon: 'checklist_rtl',
    label: 'USP.LABEL1',
    child: '',
  },
  {
    id: 2,
    color: 'orange',
    icon: 'flash_on',
    label: 'USP.LABEL2',
    child: 'USP.CHILD2',
  },
]

export const HOME_BANNERS = [
  { id: 1, image: 'HOME_BANNERS.IMG1' },
  { id: 2, image: 'HOME_BANNERS.IMG2' },
  { id: 3, image: 'HOME_BANNERS.IMG3' },
]

export const HOME_INFORMATIONS = [
  {
    id: 1,
    position: 'firer',
    color: 'purple',
    firstLetter: '+ 30.000',
    innerContent: 'INFORMATIONS.CONTENT1',
  },
  {
    id: 2,
    position: 'glass',
    color: 'cyan',
    firstLetter: 'INFORMATIONS.FIRST2',
    innerContent: 'INFORMATIONS.CONTENT2',
  },
  {
    id: 3,
    position: 'emoji',
    color: 'green',
    firstLetter: 'INFORMATIONS.FIRST3',
    innerContent: 'INFORMATIONS.CONTENT3',
  },
  {
    id: 4,
    position: 'woman',
    color: 'orange',
    firstLetter: 'INFORMATIONS.FIRST4',
    innerContent: 'INFORMATIONS.CONTENT4',
  },
]

export const HOME_PROMISES = [
  {
    id: 1,
    position: 'lowest-price',
    label: 'APP.LABEL1',
    child: 'Đảm bảo',
  },
  {
    id: 2,
    position: 'unbiased-advice',
    label: 'APP.LABEL2',
    child: 'Giữ khách hàng là trên hết',
  },
  {
    id: 3,
    position: 'reliable',
    label: 'APP.LABEL3',
    child: 'Được điều chỉnh bởi Finme',
  },
  {
    id: 4,
    position: 'claim-support',
    label: 'APP.LABEL4',
    child: 'Không căng thẳng',
  },
  {
    id: 5,
    position: 'happy-help',
    label: 'APP.LABEL5',
    child: 'Mỗi ngày trong tuần',
  },
]

export const HOME_POLICIES = [
  {
    id: 1,
    label: 'Cung cấp các sản phẩm bảo hiểm trực tuyến cho các tư vấn bảo hiểm.',
  },
  { id: 2, label: 'Nhận đăng ký thành viên, nhận hoa hồng trực tiếp.' },
  { id: 3, label: 'Giới thiệu thành viên hưởng thu nhập thụ động mãi mãi.' },
  { id: 4, label: 'Hỗ trợ trợ lý ảo đầu tiên tại Việt Nam.' },
]

export const HOME_FEEDBACKS = [
  {
    id: 1,
    customer: 'Nguyễn Bình Minh',
    image: '../../../assets/Upload/file/em minh it.jpeg',
    feedback:
      'Ứng dụng Finme khiến tôi rất vừa ý với những Áp dụng công nghệ làm đổi mới lĩnh vực bảo hiểm.',
  },
  {
    id: 2,
    customer: 'Nguyễn Kim Hồng',
    image: '../../../assets/Upload/file/z354598b9d8.jpg',
    feedback:
      'Là nơi khởi đầu lĩnh vực bảo hiểm số và giúp khách hàng yên tâm hơn.',
  },
  {
    id: 3,
    customer: 'Tào Nhật Ninh',
    image: '../../../assets/Upload/file/Ninh.png',
    feedback: 'Ứng dụng Finme tiên phong về công nghệ lĩnh vực bảo hiểm.',
  },
]

export const HOME_OURPARTNERS = [
  {
    id: 1,
    name: 'BSH',
    image: '../../../assets/Upload/images/trademark/BSH-13.png',
  },
  {
    id: 2,
    name: 'FWD',
    image: '../../../assets/Upload/images/trademark/FWD-11.png',
  },
  {
    id: 3,
    name: 'BestB',
    image: '../../../assets/Upload/images/trademark/BestB-14.png',
  },
  {
    id: 4,
    name: 'Fubon',
    image: '../../../assets/Upload/images/trademark/Fubon-12.png',
  },
  {
    id: 5,
    name: 'MIC',
    image: '../../../assets/Upload/images/trademark/MIC-10.png',
  },
  {
    id: 6,
    name: 'PTI',
    image: '../../../assets/Upload/images/trademark/PTI-09.png',
  },
  {
    id: 7,
    name: 'AAA',
    image: '../../../assets/Upload/images/trademark/AAA-01.png',
  },
  {
    id: 8,
    name: 'BAOVIET',
    image: '../../../assets/Upload/images/trademark/BAOVIET-06.png',
  },
  {
    id: 9,
    name: 'BIC',
    image: '../../../assets/Upload/images/trademark/BIC-03.png',
  },
  {
    id: 10,
    name: 'BIC1',
    image: '../../../assets/Upload/images/trademark/BIC-04.png',
  },
  {
    id: 11,
    name: 'GIC',
    image: '../../../assets/Upload/images/trademark/GIC-07.png',
  },
  {
    id: 12,
    name: 'LIBERTY',
    image: '../../../assets/Upload/images/trademark/Liberty-02.png',
  },
  {
    id: 13,
    name: 'PVI',
    image: '../../../assets/Upload/images/trademark/PVI-05.png',
  },
  {
    id: 14,
    name: 'UIC',
    image: '../../../assets/Upload/images/trademark/UIC-08.png',
  },
]

export const HOME_HELPINFO = [
  {
    id: 1,
    href: 'mailto:support@finlife.com.vn',
    icon: 'send',
    head: 'HELP.HEAD1',
    info: 'support@finlife.com.vn',
  },
  {
    id: 2,
    href: 'tell:02888815818',
    icon: 'call',
    head: 'HELP.HEAD2',
    info: '02888815818',
  },
]

export const HOME_BRANDS = [
  {
    id: 1,
    name: 'Youtube',
    url: 'https://www.youtube.com/watch?v=RZJ8zBLBnbI',
    image: '../../../assets/images/new-home/LogoYoutube-01.png',
  },
  {
    id: 2,
    name: 'CafeF',
    url:
      'https://cafef.vn/finlife-xuat-sac-dat-giai-thuong-sao-khue-2022-20220427160621281.chn',
    image: '../../../assets/images/new-home/LogoCafef-01.png',
  },
]

export const LOGIN_POINTERS = [
  { id: 1, icon: 'ico-1', label: 'LOGIN_POINTERS.LABEL1', childLabel: 'LOGIN_POINTERS.CHILD1' },
  { id: 2, icon: 'ico-2', label: 'LOGIN_POINTERS.LABEL2', childLabel: 'LOGIN_POINTERS.CHILD2' },
  { id: 3, icon: 'ico-3', label: 'LOGIN_POINTERS.LABEL3', childLabel: 'LOGIN_POINTERS.CHILD3' },
]

export const LOGIN_USPS = [
  {
    id: 1,
    color: 'purple',
    icon: 'medication_liquid',
    label: 'LOGIN_USPS.LABEL1',
    child: '',
  },
  {
    id: 2,
    color: 'green',
    icon: 'account_balance_wallet',
    label: 'LOGIN_USPS.LABEL2',
    child: 'LOGIN_USPS.CHILD2',
  },
  {
    id: 3,
    color: 'orange',
    icon: 'health_and_safety',
    label: 'LOGIN_USPS.LABEL3',
    child: '',
  },
]

export const BOOLEAN_SELECT = [
  { id: 1, value: true, label: 'True', vie: 'Hiện' },
  { id: 2, value: false, label: 'False', vie: 'Ẩn' },
]

export const STATUS_SELECTOR = [
  { id: 1, value: 'Thành Công', label: 'Thành Công' },
  { id: 2, value: 'Chờ xử lý', label: 'Chờ xử lý' },
  { id: 3, value: 'Hủy hợp đồng', label: 'Hủy hợp đồng' },
]

export const STATUS_SELECTOR_A = [
  { id: 1, value: 'Thành công', label: 'Thành công' },
  { id: 2, value: 'Chờ xử lý', label: 'Chờ xử lý' },
  { id: 3, value: 'Thất bại', label: 'Thất bại' },
  { id: 4, value: 'Đáo hạn', label: 'Đáo hạn' },
]

export const STATUS_SELECTOR_M = [
  { id: 1, value: 'Chưa liên hệ', label: 'Chưa liên hệ' },
  { id: 2, value: 'Đã tư vấn', label: 'Đã tư vấn' },
]

export const STATUS_USER = [
  { id: 1, value: 0, label: 'Chưa xác thực' },
  { id: 2, value: 1, label: 'Đã xác thực' },
  { id: 3, value: 2, label: 'Đã khóa' },
  { id: 4, value: 3, label: 'Chờ xác thực' },
]

export const CATEGORY_TEMPLATE = {
  id: 0,
  name: '',
  metaTitle: '',
  parentID: 0,
  displayOrder: '',
  image: '',
  detail: '',
  seoTitle: '',
  createdDate: '',
  createdBy: '',
  modifiedDate: '',
  modifiedBy: '',
  metaKeywords: '',
  metaDescriptions: '',
  status: false,
  showOnHome: '',
  icon: '',
  iconWhite: '',
  logo: '',
  slogan: '',
  stt: 0,
}

export const CONTENT_TEMPLATE = {
  id: 0,
  name: '',
  metaTitle: '',
  description: '',
  image: '',
  categoryID: 0,
  detail: '',
  warranty: 0,
  createdDate: '',
  createdBy: '',
  modifiedDate: '',
  modifiedBy: '',
  metaKeywords: '',
  metaDescriptions: '',
  status: true,
  topHot: now,
  viewCount: 0,
  tags: '',
  language: '',
}

export const PRODUCT_TEMPLATE = {
  id: 0,
  name: '',
  code: '',
  metaTitle: '',
  description: '',
  image: '',
  moreImages: '',
  price: '',
  promotionPrice: '',
  includedVAT: '',
  quantity: 0,
  categoryID: 0,
  detail: '',
  warranty: '',
  createdDate: '',
  createdBy: '',
  modifiedDate: '',
  modifiedBy: '',
  metaKeywords: '',
  metaDescriptions: '',
  status: true,
  topHot: '',
  viewCount: '',
  noibat: true,
  muanhieu: true,
  icon: '',
  tenCongTy: '',
  heSo: 0,
  noiDung: '',
  quyTrinh: '',
  gioiThieu: '',
  loiIch: '',
  hoaHong: '',
  stt: 0,
}

export const DATA_TO_DEFINE = [
  {
    id: 1,
    code: 'VAS01',
    nv: 'VAS01',
    name: 'Bảo hiểm trụ cột gia đình',
    durations: UIC_DURATIONS,
    respons: UIC_RESPONS,
    dynamic: UIC_DYNAMIC_POLICY,
    path: API_PATH.UIC_FEE
  },
  {
    id: 2,
    code: 'VBI01',
    nv: 'VBI01',
    name: 'Bảo hiểm VBI Vì cộng đồng',
    durations: UIC_DURATIONS,
    respons: UIC_RESPONS,
    dynamic: UIC_DYNAMIC_POLICY,
    path: API_PATH.UIC_FEE
  },
  {
    id: 3,
    code: 'MIC05',
    nv: 'MIC05',
    name: 'Bảo hiểm Nhà tư nhân',
    durations: UIC_DURATIONS,
    respons: UIC_RESPONS,
    dynamic: UIC_DYNAMIC_POLICY,
    path: API_PATH.UIC_FEE
  },
  {
    id: 4,
    code: 'VBI02',
    nv: 'VBI02',
    name: 'Bảo hiểm Ung thư',
    durations: UIC_DURATIONS,
    respons: UIC_RESPONS,
    dynamic: UIC_DYNAMIC_POLICY,
    path: API_PATH.UIC_FEE
  },
  {
    id: 5,
    code: 'VBI03',
    nv: 'VBI03',
    name: 'Bảo hiểm Tương lai tươi sáng',
    durations: UIC_DURATIONS,
    respons: UIC_RESPONS,
    dynamic: UIC_DYNAMIC_POLICY,
    path: API_PATH.UIC_FEE
  },
  {
    id: 6,
    code: 'VBI05',
    nv: 'VBI05',
    name: 'Bảo hiểm Ung thư vú',
    durations: UIC_DURATIONS,
    respons: UIC_RESPONS,
    dynamic: UIC_DYNAMIC_POLICY,
    path: API_PATH.UIC_FEE
  },
  {
    id: 7,
    code: 'UIC01',
    nv: 'UIC',
    name: 'Bảo hiểm trợ cấp nằm viện UIC',
    durations: UIC_DURATIONS,
    respons: UIC_RESPONS,
    dynamic: UIC_DYNAMIC_POLICY,
    path: API_PATH.UIC_FEE
  },
  {
    id: 8,
    code: 'BSH01',
    nv: 'BSH01',
    name: 'Bảo hiểm trợ cấp nằm viện BSH',
    durations: BSH_DURATIONS,
    respons: BSH_RESPONS,
    dynamic: BSH_DYNAMIC_POLICY,
    path: API_PATH.BSHCARE_FEE,
    thoihan: '12',
    isChangeDate: true,
    isDefaultFee: true,
  },
  {
    id: 9,
    code: 'GIC01',
    nv: 'GIC01',
    name: 'Bảo hiểm tai nạn cá nhân GIC',
    durations: GIC_DURATIONS,
    respons: GIC_RESPONS,
    dynamic: GIC_DYNAMIC_POLICY,
    path: API_PATH.GIC_FEE,
    thoihan: '12',
    isChangeDate: true,
    isDefaultFee: true,
  },
  {
    id: 10,
    code: 'MIC01',
    nv: '2BL',
    name: 'Bảo hiểm TNDS xe mô tô MIC',
    path: API_PATH.MOTOR_FEE,
  },
  {
    id: 11,
    code: 'PTI01',
    nv: 'PTI01',
    name: 'Bảo hiểm TNDS xe mô tô PTI',
    path: API_PATH.MOTOR_FEE,
  },
  {
    id: 12,
    code: 'BSH03',
    nv: 'BSH03',
    name: 'Bảo hiểm tai nạn cá nhân BSH',
    durations: BSH03_DURATIONS,
    respons: BSH03_RESPONS,
    dynamic: BSH03_DYNAMIC_POLICY,
    path: API_PATH.BSH03_FEE,
    thoihan: '12',
    isChangeDate: true,
    isDefaultFee: true,
  },
  {
    id: 13,
    code: 'BSH04',
    nv: 'BSH04',
    name: 'Bảo hiểm y tế nâng cao BSH',
    durations: BSH04_DURATIONS,
    respons: BSH04_RESPONS,
    dynamic: BSH04_DYNAMIC_POLICY,
    path: API_PATH.BSH04_FEE,
    thoihan: '12',
    isChangeDate: true,
    isDefaultFee: true,
  },
]


export const LANGUAGES = [
  {
    id: 1,
    value: 'vi',
    name: 'Tiếng Việt',
    path: '../../assets/frontend/images/vietnam.png',
  },
  {
    id: 2,
    value: 'en',
    name: 'English',
    path: '../../assets/frontend/images/united-kingdom.png',
  },
]
