<div class="admin-body">
    <div class="row page-titles mx-0">
      <div class="col p-md-0">
        <ol class="breadcrumb">
          <li class="breadcrumb-item" routerLink="/collaborators/managerF1">Quản lý</li>
          <li class="breadcrumb-item active">Hợp đồng</li>
        </ol>
      </div>
    </div>
  
    <div class="admin__container">
      <div class="flex space">
        <span class="admin__container-head">Danh sách Hợp đồng</span>
        <button type="button" class="btn btn-success btn-sm export" [appExport]="contracts" fileName="contracts">Xuất
          Excel</button>
      </div>
  
      <!-- <form class="filter" [formGroup]="filter" *ngIf="!prodEnv">
        <div class="text-field">
          <label for="idProduct">Sản phẩm</label>
          <select name="idProduct" id="idProduct" formControlName="idProduct" formControlName="idProduct" (selectionchange)="getContracts()">
            <option value="" selected disabled>Chọn sản phẩm</option>
            <option *ngFor="let opt of products" [value]="opt.name">{{opt.name}}</option>
          </select>
        </div>
  
        <div class="text-field">
          <label for="idCategory">Danh mục</label>
          <select name="idCategory" id="idCategory" formControlName="idCategory" formControlName="idCategory" (selectionchange)="getContracts()">
            <option value="" selected disabled>Chọn danh mục</option>
            <option *ngFor="let opt of categories" [value]="opt.name">{{opt.name}}</option>
          </select>
        </div>
  
        <div class="text-field">
          <label for="fromDate">Từ ngày</label>
          <input type="date" id="fromDate" name="fromDate" (change)="getContracts()" formControlName="fromDate" formControlName="fromDate" />
        </div>
  
        <div class="text-field">
          <label for="toDate">Đến ngày</label>
          <input type="date" id="toDate" name="toDate" formControlName="toDate" formControlName="toDate" (change)="getContracts()" />
        </div>
  
        <div class="text-field">
          <label for="keyword">Từ khóa</label>
          <input type="text" id="keyword" name="keyword" formControlName="keyword" formControlName="keyword" placeholder="Nhập tên khách hàng" (blur)="getContracts()" />
        </div>
  
        <div class="text-field">
          <label for="status">Trạng thái</label>
          <select name="status" id="status" formControlName="status" formControlName="status" (selectionchange)="getContracts()">
            <option *ngFor="let opt of statusSeletor" [value]="opt.value">{{opt.label}}</option>
          </select>
        </div>
      </form> -->
  
      <table class="table table-striped">
        <thead>
          <tr>
            <th scope="col" sortable="id" (sort)="onSort()">ID</th>
            <th scope="col" sortable="hddtId" (sort)="onSort()">Mã HĐĐT</th>
            <th scope="col" class="start">Họ tên</th>
            <th scope="col">Số điện thoại</th>
            <th scope="col" sortable="loaibaohiem" (sort)="onSort()">Loại bảo hiểm</th>
            <th scope="col" sortable="goibaohiem" (sort)="onSort()">Gói bảo hiểm</th>
            <th scope="col" sortable="thoihan" (sort)="onSort()">Thời hạn</th>
            <th scope="col" sortable="ngaytao" (sort)="onSort()">Ngày tạo</th>
            <th scope="col" sortable="tongphi" (sort)="onSort()">Tổng phí</th>
            <th scope="col" sortable="trangthai" (sort)="onSort()">Trạng thái</th>
            <th scope="col">Chức năng</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let cont of contracts; index as i">
            <th scope="row">{{ cont.id }}</th>
            <th scope="row">{{ cont.id_hoso }}</th>
            <td class="start">{{ cont.hoten }}</td>
            <td>{{ cont.sodienthoai }}</td>
            <td>{{ cont.loaibaohiem|translate }}</td>
            <td>{{ cont.goibaohiem|translate }}</td>
            <td>{{ cont.thoihan|translate }} tháng</td>
            <td>{{ cont.ngaytao | date:'dd/MM/yyyy' }}</td>
            <td>{{ cont.tongphi }} VND</td>
            <!-- <td>{{ cont.tongphi | currency:'VND':'code':'1':'vi-VI' }}</td> -->
            <td>{{ cont.trangthai }}</td>
            <td>
              <span class="material-icons" title="Xem"
                (click)="dialogHandler('view', cont, dialog); getContractPersonsListbyID(cont.id)">visibility</span>
              <!-- <span class="material-icons" title="Sửa"
                (click)="dialogHandler('edit', cont, dialog); getContractPersonsListbyID(cont.id)"
                *ngIf="cont.trangthai !== 'Hủy hợp đồng'">edit</span> -->
            </td>
          </tr>
        </tbody>
      </table>
  
      <div class="flex space">
        <div class="">
          <!-- <span>Hiển thị</span>
          <select name="pageSize" id="pageSize" [value]="pageSize" [(ngModel)]="pageSize"
            (ngModelChange)="getContractPages(); getContracts()">
            <option value=10>10</option>
            <option value=15>15</option>
            <option value=20>20</option>
            <option value=25>25</option>
          </select>
          <label for="pageSize">hợp đồng.</label> -->
        </div>
  
        <ngb-pagination [collectionSize]="total" [(page)]="page" [maxSize]="pageSize" [rotate]="true"
          [boundaryLinks]="true" (pageChange)="getContracts()"></ngb-pagination>
      </div>
  
      <ngx-skeleton-loader *ngIf="contracts.length < 1" count="9" [theme]="{
          margin: '4px 0',
          width: '100%',
          height: '54px',
          'border-radius': '8px'
        }"></ngx-skeleton-loader>
    </div>
  
  </div>
  
  <ng-template #dialog let-modal>
    <div class="modal-header px-4">
      <h4 class="modal-title m-0 fs-4">{{ dialogType === 'view' ? 'Chi tiết' : 'Cập nhật' }} thông tin hợp đồng</h4>
      <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
      <ngb-accordion #acc="ngbAccordion" activeIds="main-infomations">
        <ngb-panel title="Thông tin cơ bản" id="main-infomations">
          <ng-template ngbPanelContent>
            <form class="dialog" [formGroup]="form">
              <div class="text-field">
                <label for="hddtId">Mã hợp đồng đối tác<span class="required">*</span></label>
                <input [readonly]="disableInput" required type="text" id="hddtId" name="hddtId"
                  formControlName="hddtId" />
                <div class="invalid">
                  <small
                    *ngIf="form.controls['hddtId'].touched && form.controls['hddtId'].hasError('required')">{{error.required}}</small>
                </div>
              </div>
  
              <div class="text-field">
                <label for="hoten">Họ và tên<span class="required">*</span></label>
                <input [readonly]="disableInput" required type="text" id="hoten" name="hoten" formControlName="hoten" />
                <div class="invalid">
                  <small
                    *ngIf="form.controls['hoten'].touched && form.controls['hoten'].hasError('required')">{{error.required}}</small>
                </div>
              </div>
  
              <div class="text-field">
                <label for="sodienthoai">Số điện thoại<span class="required">*</span></label>
                <input [readonly]="disableInput" [pattern]="pattern.PHONE" required type="text" id="sodienthoai"
                  name="sodienthoai" formControlName="sodienthoai" />
                <div class="invalid">
                  <small
                    *ngIf="form.controls['sodienthoai'].touched && form.controls['sodienthoai'].hasError('required')">{{error.required}}</small>
                </div>
              </div>
  
              <div class="text-field">
                <label for="cccd">Căn cước công dân<span class="required">*</span></label>
                <input [readonly]="disableInput" required type="text" id="cccd" name="cccd" formControlName="cccd" />
                <div class="invalid">
                  <small
                    *ngIf="form.controls['cccd'].touched && form.controls['cccd'].hasError('required')">{{error.required}}</small>
                </div>
              </div>
  
              <div class="text-field">
                <label for="email">Email<span class="required">*</span></label>
                <input [readonly]="disableInput" [email]="pattern.EMAIL" required type="text" id="email" name="email"
                  formControlName="email" />
                <div class="invalid">
                  <small
                    *ngIf="form.controls['email'].touched && form.controls['email'].hasError('required')">{{error.required}}</small>
                  <small *ngIf="form.controls['email']?.errors?.['email']">{{error.email}}</small>
                </div>
              </div>
  
              <div class="text-field">
                <label for="ngaysinh">Ngày sinh<span class="required">*</span></label>
                <input [readonly]="disableInput" required type="date" id="ngaysinh" name="ngaysinh"
                  formControlName="ngaysinh" />
                <div class="invalid">
                  <small
                    *ngIf="form.controls['ngaysinh']?.invalid && form.controls['ngaysinh']?.touched">{{error.required}}</small>
                </div>
              </div>
  
              <div class="text-field">
                <label for="gioitinh">Giới tính<span class="required">*</span></label>
                <select required name="gioitinh" id="gioitinh" formControlName="gioitinh" *ngIf="!disableInput">
                  <option *ngFor="let opt of genders" [value]="opt.vie">{{opt.label|translate}}</option>
                </select>
                <input readonly type="text" id="gioitinh" name="gioitinh" formControlName="gioitinh"
                  *ngIf="disableInput" />
                <div class="invalid">
                  <small
                    *ngIf="form.controls['gioitinh'].touched && form.controls['gioitinh'].hasError('required')">{{error.select}}</small>
                </div>
              </div>
  
              <div class="text-field">
                <label for="diachi">Địa chỉ</label>
                <input [readonly]="disableInput" type="text" id="diachi" name="diachi" formControlName="diachi" />
                <div class="invalid"></div>
              </div>
  
              <div class="text-field">
                <label for="moiquanhe">Mối quan hệ</label>
                <input [readonly]="disableInput" type="text" id="moiquanhe" name="moiquanhe"
                  formControlName="moiquanhe" />
                <div class="invalid"></div>
              </div>
  
              <div class="text-field">
                <label for="loaibaohiem">Loại bảo hiểm<span class="required">*</span></label>
                <input [readonly]="disableInput" required type="text" id="loaibaohiem" name="loaibaohiem"
                  formControlName="loaibaohiem" />
                <div class="invalid">
                  <small
                    *ngIf="form.controls['loaibaohiem'].touched && form.controls['loaibaohiem'].hasError('required')">{{error.required}}</small>
                </div>
              </div>
  
              <div class="text-field">
                <label for="goibaohiem">Gói bảo hiểm<span class="required">*</span></label>
                <input [readonly]="disableInput" required type="text" id="goibaohiem" name="goibaohiem"
                  formControlName="goibaohiem" />
                <div class="invalid">
                  <small
                    *ngIf="form.controls['goibaohiem'].touched && form.controls['goibaohiem'].hasError('required')">{{error.required}}</small>
                </div>
              </div>
  
              <div class="text-field">
                <label for="tongphi">Tổng phí<span class="required">*</span></label>
                <input readonly required type="text" id="tongphi" name="tongphi" formControlName="tongphi" />
                <div class="invalid">
                  <small
                    *ngIf="form.controls['tongphi'].touched && form.controls['tongphi'].hasError('required')">{{error.required}}</small>
                </div>
              </div>
  
              <div class="text-field">
                <label for="trangthai">Trạng thái<span class="required">*</span></label>
                <select required name="trangthai" id="trangthai" formControlName="trangthai" *ngIf="!disableInput">
                  <option *ngFor="let opt of statusSeletor" [value]="opt.value">{{opt.label}}</option>
                </select>
                <input readonly type="text" id="trangthai" name="trangthai" formControlName="trangthai"
                  *ngIf="disableInput" />
                <div class="invalid">
                  <small
                    *ngIf="form.controls['trangthai'].touched && form.controls['trangthai'].hasError('required')">{{error.select}}</small>
                </div>
              </div>
  
              <div class="text-field">
                <label for="contractUrl">Link hợp đồng</label>
                <input [readonly]="disableInput" type="text" id="contractUrl" name="contractUrl"
                  formControlName="contractUrl" #link />
                <div class="invalid"></div>
                <div class="actions-input" *ngIf="contract.contractUrl">
                  <a [href]="contract.contractUrl" target="_blank">
                    <span class="material-icons" title="Mở">open_in_browser</span>
                  </a>
                  <span class="material-icons" title="Copy" (click)="copyInputMessage(link)">content_copy</span>
                </div>
              </div>
  
              <div *ngIf="!disableInput" class="text-field">
                <label for="contractUrl">File hợp đồng</label>
                <input [readonly]="disableInput" type="file" id="contractUrl" name="contractUrl"
                  formControlName="contractUrl" #link  (change)="upload($event)"/>
                <div class="invalid"></div>
                <!-- <div class="actions-input" *ngIf="contract.contractUrl">
                  <a [href]="contract.contractUrl" target="_blank">
                    <span class="material-icons" title="Mở">open_in_browser</span>
                  </a>
                  <span class="material-icons" title="Copy" (click)="copyInputMessage(link)">content_copy</span>
                </div> -->
              </div>
  
              <div class="text-field">
                <label for="soluongnguoi">Số lượng tham gia ghi nhận:</label>
                <input [readonly]="disableInput" type="text" id="soluongnguoi" name="soluongnguoi"
                  formControlName="soluongnguoi" />
              </div>
  
              <div class="text-field">
                <label for="voucher">Voucher đã sử dụng:</label>
                <input [readonly]="disableInput" type="text" id="voucher" name="voucher" formControlName="voucher" />
              </div>
  
            </form>
          </ng-template>
        </ngb-panel>
  
        <ngb-panel title="Thông tin thêm">
          <ng-template ngbPanelContent>
            <div class="dialog">
              <div class="text-field">
                <label for="hd_id">Mã hợp đồng</label>
                <input type="text" id="hd_id" name="hd_id" readonly [value]="contract.id" />
              </div>
  
              <div class="text-field">
                <label for="magiaodich">Mã giao dịch</label>
                <input type="text" id="magiaodich" name="magiaodich" readonly [value]="contract.magiaodich" />
              </div>
  
              <div class="text-field">
                <label for="mahoadon">Mã hóa đơn</label>
                <input type="text" id="mahoadon" name="mahoadon" readonly [value]="contract.mahoadon" />
              </div>
  
              <div class="text-field">
                <label for="ngaytao">Ngày mua</label>
                <input type="datetime-local" id="ngaytao" name="ngaytao" readonly [value]="contract.ngaytao" />
              </div>
  
              <div class="text-field">
                <label for="thoigianthanhtoan">Thời điểm thanh toán</label>
                <input type="datetime-local" id="thoigianthanhtoan" name="thoigianthanhtoan" readonly
                  [value]="contract.thoigianthanhtoan" />
              </div>
  
              <div class="text-field">
                <label for="trangthaithanhtoan">Trạng thái thanh toán</label>
                <input type="text" id="trangthaithanhtoan" name="trangthaithanhtoan" readonly
                  [value]="contract.trangthaithanhtoan" />
              </div>
  
            </div>
            <div *ngIf="personsList[1]">
              <h4>Danh sách người cùng tham gia:</h4>
              <div class="protector-list-container">
                <div class="protector-list">
                  <table>
                    <tr>
                      <th>{{'PRODUCT.POSITION'|translate}}</th>
                      <th>{{'PRODUCT.RELATIONSHIP'|translate}}</th>
                      <th>{{'COMMON.FULL_NAME'|translate}}</th>
                      <th>{{'COMMON.IDENTIFY_CARD'|translate}}</th>
                      <th>{{'COMMON.BIRTHDAY'|translate}}</th>
                      <th>{{'COMMON.GENDER'|translate}}</th>
                    </tr>
                    <tr *ngFor="let person of personsList; let i = index">
                      <td *ngIf="i>0">{{i}}</td>
                      <td *ngIf="i>0">{{person.dS_MOI_QUAN_HE}}</td>
                      <td *ngIf="i>0">{{person.dS_TEN}}</td>
                      <td *ngIf="i>0">{{person.dS_CMT}}</td>
                      <td *ngIf="i>0">{{person.dS_NG_SINH}}</td>
                      <td *ngIf="i>0">{{person.dS_GIOI}}</td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
            <div *ngIf="personP[0]!=undefined">
              <hr>
              <h4>Thông tin người được bảo hiểm:</h4>
              <div class="protector-list-container">
                <div class="protector-list">
                  <table>
                    <tr>
                      <th>{{'PRODUCT.RELATIONSHIP'|translate}}</th>
                      <th>{{'COMMON.FULL_NAME'|translate}}</th>
                      <th>{{'COMMON.IDENTIFY_CARD'|translate}}</th>
                      <th>{{'COMMON.BIRTHDAY'|translate}}</th>
                      <th>{{'COMMON.GENDER'|translate}}</th>
                      <th>Nằm viện</th>
                      <th>Ngoại trú</th>
                      <th>Thai sản</th>
                    </tr>
                    <tr>
                      <td >{{personP[0].moiquanheP|translate}}</td>
                      <td >{{personP[0].tenP}}</td>
                      <td >{{personP[0].cmtP}}</td>
                      <td >{{personP[0].nG_SINHP}}</td>
                      <td >{{personP[0].gioiP}}</td>
                      <td >{{personP[0].trocapnamviendotainan?'Có':'Không'}}</td>
                      <td >{{personP[0].ngoaitru?'Có':'Không'}}</td>
                      <td >{{personP[0].thaisan?'Có':'Không'}}</td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </ng-template>
        </ngb-panel>
  
        <ngb-panel title="Thông tin xe" *ngIf="isBSHCar">
          <ng-template ngbPanelContent>
            <div class="dialog">
              <div class="text-field">
                <label for="bien_xe">Biển xe</label>
                <input type="text" id="bien_xe" name="bien_xe" readonly [value]="BSHCar.bien_xe" />
              </div>
  
              <div class="text-field">
                <label for="so_may">Số máy</label>
                <input type="text" id="so_may" name="so_may" readonly [value]="BSHCar.so_may" />
              </div>
  
              <div class="text-field">
                <label for="so_khung">Số khung</label>
                <input type="text" id="so_khung" name="so_khung" readonly [value]="BSHCar.so_khung" />
              </div>
  
              <div class="text-field">
                <label for="nguon_goc">Nguồn gốc</label>
                <input type="text" id="nguon_goc" name="nguon_goc" readonly [value]="BSHCar.nguon_goc" />
              </div>
  
              <div class="text-field">
                <label for="hang_xe">Hãng xe</label>
                <input type="text" id="hang_xe" name="hang_xe" readonly [value]="BSHCar.hang_xe" />
              </div>
  
              <div class="text-field">
                <label for="hieu_xe">Hiệu xe</label>
                <input type="text" id="hieu_xe" name="hieu_xe" readonly [value]="BSHCar.hieu_xe" />
              </div>
  
              <div class="text-field">
                <label for="nam_sx">Năm sản xuất</label>
                <input type="text" id="nam_sx" name="nam_sx" readonly [value]="BSHCar.nam_sx" />
              </div>
  
              <div class="text-field">
                <label for="so_cn">Số chỗ ngồi</label>
                <input type="text" id="so_cn" name="so_cn" readonly [value]="BSHCar.so_cn" />
              </div>
  
              <div class="text-field">
                <label for="trong_tai">Trọng tải</label>
                <input type="text" id="trong_tai" name="trong_tai" readonly [value]="BSHCar.trong_tai" />
              </div>
  
              <div class="text-field">
                <label for="ngay_hl">Ngày hiệu lực</label>
                <input type="date" id="ngay_hl" name="ngay_hl" readonly [value]="BSHCar.ngay_hl" />
              </div>
  
              <div class="text-field">
                <label for="ngay_kt">Ngày kết thúc</label>
                <input type="date" id="ngay_kt" name="ngay_kt" readonly [value]="BSHCar.ngay_kt" />
              </div>
  
              <div class="text-field">
                <label for="muc_dich_kinh_doanh">Mục đích kinh doanh</label>
                <input type="text" id="muc_dich_kinh_doanh" name="muc_dich_kinh_doanh" readonly
                  [value]="BSHCar.muc_dich_kinh_doanh" />
              </div>
  
              <div class="text-field">
                <label for="muc_dich_su_dung">Mục đích sử dụng</label>
                <input type="text" id="muc_dich_su_dung" name="muc_dich_su_dung" readonly
                  [value]="BSHCar.muc_dich_su_dung" />
              </div>
  
              <div class="text-field">
                <label for="trach_nhiem_dan_su_bat_buoc">Trách nhiệm dân sự bắt buộc</label>
                <input type="text" id="trach_nhiem_dan_su_bat_buoc" name="trach_nhiem_dan_su_bat_buoc" readonly
                  [value]="BSHCar.trach_nhiem_dan_su_bat_buoc" />
              </div>
  
              <div class="text-field">
                <label for="linh_vuc_kinh_doanh">Lĩnh vực kinh doanh</label>
                <input type="text" id="linh_vuc_kinh_doanh" name="linh_vuc_kinh_doanh" readonly
                  [value]="BSHCar.linh_vuc_kinh_doanh" />
              </div>
            </div>
          </ng-template>
        </ngb-panel>
      </ngb-accordion>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary flex space" (click)="onSave(); modal.close('Close click')"
        [disabled]="form.invalid" *ngIf="this.dialogType === 'edit'">
        <span class="material-icons text-light">save</span>
        <p class="m-0 px-1">Lưu</p>
      </button>
      <button type="button" class="btn btn-danger flex space" (click)="modal.close('Close click')">
        <span class="material-icons text-light">close</span>
        <p class="m-0 px-1">Đóng</p>
      </button>
    </div>
  </ng-template>
