import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.css']
})
export class ProductCardComponent implements OnInit {
  @Input() id = ''
  @Input() image = ''
  @Input() name = ''
  @Input() meta = ''
  @Input() partner= ''
  user_Code:any
  constructor() { }

  ngOnInit(): void {
    if(this.partner=='otofun'){
      this.user_Code='A00039928'
    }
  }

}
