import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-promise-card',
  templateUrl: './promise-card.component.html',
  styleUrls: ['./promise-card.component.css']
})
export class PromiseCardComponent implements OnInit {
  @Input() label = ''
  @Input() childLabel = ''
  @Input() getStyle = ''

  constructor() { }

  ngOnInit(): void {
  }

}
