<div class="admin-body">
  <div class="container-fluid mt-3">
    <div class="row">

      <div class="col-lg-3 col-sm-6">
        <div class="card gradient-5">
          <div class="card-body">
            <h3 class="card-title text-white">Hợp đồng</h3>
            <div class="d-inline-block">
              <h2 class="text-white">{{ dashboard.dangkysanpham || 0 }}</h2>
              <p class="text-white mb-0">đang chờ xử lý</p>
            </div>
            <span class="float-right display-5 opacity-5"><i class="fa fa-money"></i></span>
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-sm-6">
        <div class="card gradient-6">
          <div class="card-body">
            <h3 class="card-title text-white">Hợp đồng</h3>
            <div class="d-inline-block">
              <h2 class="text-white">{{ dashboard.hopdongthanhcong || 0 }}</h2>
              <p class="text-white mb-0">thanh toán thành công</p>
            </div>
            <span class="float-right display-5 opacity-5"><i class="fa fa-money"></i></span>
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-sm-6">
        <div class="card gradient-7">
          <div class="card-body">
            <h3 class="card-title text-white">Hợp đồng</h3>
            <div class="d-inline-block">
              <h2 class="text-white">{{ dashboard.hopdongdahuy || 0 }}</h2>
              <p class="text-white mb-0">bị hủy đăng ký</p>
            </div>
            <span class="float-right display-5 opacity-5"><i class="fa fa-money"></i></span>
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-sm-6">
        <div class="card gradient-8">
          <div class="card-body">
            <h3 class="card-title text-white">Tỷ lệ chuyển đổi</h3>
            <div class="d-inline-block">
              <h2 class="text-white">{{ percent || 0 }}%</h2>
              <p class="text-white mb-0">mỗi hợp đồng</p>
            </div>
            <span class="float-right display-5 opacity-5"><i class="fa fa-money"></i></span>
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-sm-6">
        <div class="card gradient-1">
          <div class="card-body">
            <h3 class="card-title text-white">Sản phẩm</h3>
            <div class="d-inline-block">
              <h2 class="text-white">{{ dashboard.sanpham || 0 }}</h2>
              <p class="text-white mb-0">đang được hiển thị</p>
            </div>
            <span class="float-right display-5 opacity-5"><i class="fa fa-shopping-cart"></i></span>
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-sm-6">
        <div class="card gradient-3">
          <div class="card-body">
            <h3 class="card-title text-white">Danh mục</h3>
            <div class="d-inline-block">
              <h2 class="text-white">{{ dashboard.theloaisanpham || 0 }}</h2>
              <p class="text-white mb-0">đang được hiển thị</p>
            </div>
            <span class="float-right display-5 opacity-5"><i class="fa fa-users"></i></span>
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-sm-6">
        <div class="card gradient-2">
          <div class="card-body">
            <h3 class="card-title text-white">Tin tức</h3>
            <div class="d-inline-block">
              <h2 class="text-white">{{ dashboard.tintuc || 0 }}</h2>
              <p class="text-white mb-0">đang được hiển thị</p>
            </div>
            <span class="float-right display-5 opacity-5"><i class="fa fa-money"></i></span>
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-sm-6">
        <div class="card gradient-4">
          <div class="card-body">
            <h3 class="card-title text-white">Voucher</h3>
            <div class="d-inline-block">
              <h2 class="text-white">{{ dashboard.voucher || 0 }}</h2>
              <p class="text-white mb-0">sẵn sàng sử dụng</p>
            </div>
            <span class="float-right display-5 opacity-5"><i class="fa fa-heart"></i></span>
          </div>
        </div>
      </div>

      <div id="cartChart" class="rounded shadow-1 bg-light" #cartChart></div>

    </div>
  </div>
</div>
