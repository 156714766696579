<div class="login__page">
  <p class="login__head">{{ subLabel|translate }}</p>
  <div class="login__contain">
    <div class="login__form">
      <p class="login__form-label">{{ subLabel|translate }}</p>

      <!-- <div id="recaptcha-container"></div> -->

      <ng-container *ngIf="!isForget" [ngTemplateOutlet]="isRegister ? registerForm : loginForm"></ng-container>
      <ng-container [ngTemplateOutlet]="isForget ? getPassForm : null"></ng-container>
    </div>

    <div class="login__contain--left">
      <div class="login__banner mobile-hidden">
        <img class="login__thumb" src="../../../assets/frontend/images/bu/term-form-graphic2.png"
          alt="insurance for family">
        <div class="login__card">
          <div class="login__card--child" *ngFor="let usp of usps">
            <app-usp-card [icon]="usp.icon" [isBorder]="true" [color]="usp.color" [label]="usp.label|translate"
              [child]="usp.child|translate"></app-usp-card>
          </div>
        </div>
      </div>
      <div class="login__pointer">
        <div class="login__pointer-block">
          <app-pointat *ngFor="let point of pointers" [icon]="point.icon" [label]="point.label|translate"
            [childLabel]="point.childLabel|translate"></app-pointat>
        </div>
        <p class="login__pointer--addition">
          {{'LOGIN.ADDITION'|translate}}
        </p>
      </div>
    </div>
  </div>
</div>

<ng-template #validWarming let-modal>
  <div class="modal-header">
    <h4 class="modal-title">{{'LOGIN.NOTI'|translate}}</h4>
  </div>
  <div class="modal-body">
    <p>{{'LOGIN.NOTI_CONTENT1'|translate}}</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="modal.close('Close click')">{{'LOGIN.OK'|translate}}</button>
  </div>
</ng-template>

<ng-template #success let-modal>
  <div class="modal-header">
    <h4 class="modal-title">{{'LOGIN.NOTI'|translate}}</h4>
  </div>
  <div class="modal-body">
    <p>{{ message|translate }}</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="modal.close('Close click')">{{'LOGIN.OK'|translate}}</button>
  </div>
</ng-template>

<ng-template #loginForm>
  <form [formGroup]="login">
    <div class="text-field">
      <input type="text" id="username" name="username" [pattern]="valid.PHONE" formControlName="username"
        placeholder="{{'INFORMATIONS.PHONE_NUMBER'|translate}}" />
      <div class="invalid">
        <ng-container *ngIf="l['username']?.touched || submitted">
          <small *ngIf="l['username']?.hasError('required')">{{ error.required|translate }}</small>
          <small *ngIf="l['username']?.hasError('pattern')">{{ error.phone|translate }}</small>
        </ng-container>
      </div>
    </div>

    <div class="text-field password--show">
      <input [type]="showType" minlength="8" id="password" name="password" formControlName="password"
        placeholder="{{'INFORMATIONS.PASSWORD'|translate}}" />
      <span class="material-icons password--show" (click)="onShow()">{{ isShow ? 'visibility' : 'visibility_off'
        }}</span>
      <div class="invalid">
        <ng-container *ngIf="l['password']?.touched || submitted">
          <small *ngIf="l['password']?.hasError('required')">{{ error.required|translate }}</small>
          <small *ngIf="l['password']?.hasError('minlength')">{{ error.min_length|translate }}</small>
        </ng-container>
      </div>
    </div>

    <p>{{'LOGIN.NOTHAVE'|translate}} <strong class="sugest"
        (click)="formHandler(true)">{{'LOGIN.RES_NOW'|translate}}</strong></p>
    <p>{{'LOGIN.FORGET'|translate}} <strong class="sugest"
        (click)="formHandler1()">{{'LOGIN.RECOVER'|translate}}</strong></p>
    <app-button (click)="onLogin(login, validWarming, success)" [label]="subLabel|translate"
      [getStyle]="'button-template__button border fill'"></app-button>
  </form>
</ng-template>

<ng-template #getPassForm>
  <form [formGroup]="getPass">
    <div class="text-field">
      <label for="email">{{'COMMON.EMAIL_R'|translate}}</label> 
      <input type="email" id="email" name="email" [pattern]="valid.EMAIL1"  formControlName="email" [readonly]="isReadOnly"
        [placeholder]="'LOGIN.R_EMAIL'|translate" style="position: relative;"/>
        <span *ngIf="g['email']?.valid" class="sendOTP" (click)="sendMailOTP()">{{'COMMON.SEND_OTP'|translate}}</span>
        <span *ngIf="!g['email']?.valid" class="sendOTP" style="opacity: 0.5;">{{'COMMON.SEND_OTP'|translate}}</span>
      <div class="invalid">
        <ng-container *ngIf="g['email']?.touched || submitted">
          <small *ngIf="g['email']?.hasError('required')">{{ error.required|translate }}</small>
          <small *ngIf="g['email']?.hasError('pattern')">{{ error.email|translate }}</small>
        </ng-container>
      </div>
    </div>

    <div class="text-field">
      <label for="verify">{{'INFORMATIONS.VERIFICATION'|translate}} OTP*</label>
      <input type="text" id="verify" name="verify" formControlName="verify" (ngModelChange)="onVerify2()" />
      <div class="invalid">
        <ng-container *ngIf="g['verify']?.touched">
          <small *ngIf="g['verify']?.hasError('required')">{{ error.required|translate }}</small>
          <small *ngIf="g['verify']?.value && errorVerify2">{{ error.verify|translate }}</small>
        </ng-container>
      </div>
    </div>

    <div class="text-field password--show">
      <label for="mat_khau">{{ 'INFORMATIONS.PASSWORD' | translate }} *</label>
      <input [type]="showType" minlength="8" id="mat_khau" name="mat_khau" formControlName="mat_khau"
        placeholder="{{ 'INFORMATIONS.PASSWORD' | translate }}" (ngModelChange)="onCheckValid()"/>
      <span class="material-icons password--show" (click)="onShow()">{{ isShow ? 'visibility' : 'visibility_off'
        }}</span>
      <div class="invalid">
        <ng-container *ngIf="g['mat_khau']?.touched || submitted">
          <small *ngIf="g['mat_khau']?.hasError('required')">{{ error.required|translate }}</small>
          <small *ngIf="g['mat_khau']?.hasError('minlength')">{{ error.min_length|translate }}</small>
        </ng-container>
      </div>
    </div>

    <div class="text-field password--show">
      <label for="xac_nhan">{{ 'INFORMATIONS.RETYPE_PASSWORD' | translate }} *</label>
      <input [type]="showType" minlength="8" id="xac_nhan" name="xac_nhan" formControlName="xac_nhan"
        placeholder="{{ 'INFORMATIONS.RETYPE_PASSWORD' | translate }}" (ngModelChange)="onCheckValid()"/>
      <span class="material-icons password--show" (click)="onShow()">{{ isShow ? 'visibility' : 'visibility_off'
        }}</span>
      <div class="invalid">
        <ng-container *ngIf="g['xac_nhan']?.touched || submitted">
          <small *ngIf="g['xac_nhan']?.hasError('required')">{{ error.required|translate }}</small>
          <small *ngIf="g['xac_nhan']?.hasError('minlength')">{{ error.min_length|translate }}</small>
          <small *ngIf="g['xac_nhan']?.hasError('matching')">{{ error.retype|translate}}</small>
        </ng-container>
      </div>
    </div>
    <!-- <p>{{'COMMON.BACK'|translate}} <strong class="sugest"
      ><a href="/login">{{'LOGIN.LOGIN_NOW'|translate}}</a></strong></p> -->
    <button (click)="onChangePass()"
      class="btn btn-primary" [disabled]="isNotValid">{{'COMMON.CHANGE_PASS'|translate}}</button>
  </form>
</ng-template>

<ng-template #registerForm>
  <form [formGroup]="register">
    <ng-container *ngIf="stepValue === 1">
      <div class="text-field">
        <label for="hoten">{{ 'INFORMATIONS.FULL_NAME' | translate }} *</label>
        <input type="text" id="hoten" name="hoten" formControlName="hoten"
          placeholder="{{ 'INFORMATIONS.FULL_NAME' | translate }}" (ngModelChange)="onCapitalize()"/>
        <div class="invalid">
          <ng-container *ngIf="r['hoten']?.touched || submitted">
            <small *ngIf="r['hoten']?.hasError('required')">{{ error.required|translate }}</small>
          </ng-container>
        </div>
      </div>

      <div class="text-field">
        <label for="cmnd">{{ 'INFORMATIONS.IDENTIFY_CARD' | translate }} *</label>
        <input type="text" id="cmnd" name="cmnd" [pattern]="valid.CCCD" min="9" max="12" formControlName="cmnd"
          placeholder="{{ 'INFORMATIONS.IDENTIFY_CARD' | translate }}" />
        <div class="invalid">
          <ng-container *ngIf="r['cmnd']?.touched || submitted">
            <small *ngIf="r['cmnd']?.hasError('required')">{{ error.required|translate }}</small>
            <small *ngIf="r['cmnd']?.hasError('pattern')">{{ error.identify|translate }}</small>
          </ng-container>
        </div>
      </div>

      <div class="text-field">
        <label for="nguoi_gioi_thieu">{{'INFORMATIONS.REFERRAL_PERSON'|translate}}</label>
        <input type="text" id="nguoi_gioi_thieu" name="nguoi_gioi_thieu" [readonly]="disableInput"
          formControlName="nguoi_gioi_thieu" placeholder="{{'INFORMATIONS.REFERRAL_CODE'|translate}}"
          (change)="r['nguoi_gioi_thieu'].value.length > 0 ? (hasParent = true) : (hasParent = false)" />
        <div class="invalid">
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="stepValue === 2">
      <div class="text-field">
        <label for="so_dien_thoai">{{ 'INFORMATIONS.PHONE_NUMBER' | translate }} *</label>
        <input type="text" id="so_dien_thoai" name="so_dien_thoai" [pattern]="valid.PHONE"
          formControlName="so_dien_thoai" placeholder="{{ 'INFORMATIONS.PHONE_NUMBER' | translate }}" />
        <!-- <span class="material-icons password--show" *ngIf="r['so_dien_thoai']?.value" (click)="onSendCode()">send</span> -->
        <div class="invalid">
          <ng-container *ngIf="r['so_dien_thoai']?.touched || submitted">
            <small *ngIf="r['so_dien_thoai']?.hasError('required')">{{ error.required|translate }}</small>
            <small *ngIf="r['so_dien_thoai']?.hasError('pattern')">{{ error.phone|translate }}</small>
          </ng-container>
        </div>
      </div>

      <div class="text-field password--show">
        <label for="mat_khau">{{ 'INFORMATIONS.PASSWORD' | translate }} *</label>
        <input [type]="showType" minlength="8" id="mat_khau" name="mat_khau" formControlName="mat_khau"
          placeholder="{{ 'INFORMATIONS.PASSWORD' | translate }}" />
        <span class="material-icons password--show" (click)="onShow()">{{ isShow ? 'visibility' : 'visibility_off'
          }}</span>
        <div class="invalid">
          <ng-container *ngIf="r['mat_khau']?.touched || submitted">
            <small *ngIf="r['mat_khau']?.hasError('required')">{{ error.required|translate }}</small>
            <small *ngIf="r['mat_khau']?.hasError('minlength')">{{ error.min_length|translate }}</small>
          </ng-container>
        </div>
      </div>

      <div class="text-field password--show">
        <label for="xac_nhan">{{ 'INFORMATIONS.RETYPE_PASSWORD' | translate }} *</label>
        <input [type]="showType" minlength="8" id="xac_nhan" name="xac_nhan" formControlName="xac_nhan"
          placeholder="{{ 'INFORMATIONS.RETYPE_PASSWORD' | translate }}" />
        <span class="material-icons password--show" (click)="onShow()">{{ isShow ? 'visibility' : 'visibility_off'
          }}</span>
        <div class="invalid">
          <ng-container *ngIf="r['xac_nhan']?.touched || submitted">
            <small *ngIf="r['xac_nhan']?.hasError('required')">{{ error.required|translate }}</small>
            <small *ngIf="r['xac_nhan']?.hasError('minlength')">{{ error.min_length|translate }}</small>
            <small *ngIf="r['xac_nhan']?.hasError('matching')">{{ error.retype|translate}}</small>
          </ng-container>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="stepValue === 3">
      <!-- <div class="text-field">
        <label for="verify">{{'INFORMATIONS.VERIFICATION'|translate}} *</label>
        <input type="text" id="verify" name="verify" formControlName="verify" (ngModelChange)="onVerify()" />
        <div class="invalid">
          <ng-container *ngIf="r['verify']?.touched || submitted">
            <small *ngIf="r['verify']?.hasError('required')">{{ error.required|translate }}</small>
            <small *ngIf="r['verify']?.value && errorVerify">{{ error.verify|translate }}</small>
          </ng-container>
        </div>
      </div> -->
      <div class="text-field">
        <label for="email">Email *</label>
        <input type="text" id="email" name="email" [pattern]="valid.EMAIL1"  formControlName="email"
          placeholder="Email" />
        <div class="invalid">
          <ng-container *ngIf="r['email']?.touched || submitted">
            <small *ngIf="r['email']?.hasError('required')">{{ error.required|translate }}</small>
            <small *ngIf="r['email']?.hasError('pattern')">{{ error.email|translate }}</small>
          </ng-container>
        </div>
      </div>
    </ng-container>

    <p>{{'LOGIN.HAVE'|translate}} <strong class="sugest"
        (click)="formHandler(false)">{{'LOGIN.LOGIN_NOW'|translate}}</strong></p>

    <div class="d-flex justify-content-around">
      <button class="btn btn-outline-primary" (click)="stepValue > 1 ? onController(-1, register, validWarming) :  ''">
        {{ 'INFORMATIONS.BACK' | translate }}
      </button>
      <button class="btn btn-primary" (click)="stepValue < 3 ? onController(1, register, validWarming) : ''"
        *ngIf="stepValue < 3">
        {{ 'INFORMATIONS.NEXT' | translate }}
      </button>
      <button class="btn btn-primary" (click)="onRegister(register, validWarming, success)" [disabled]="!r['email'].valid"
         *ngIf="stepValue === 3">{{'COMMON.REGISTER'|translate}}</button>
         <!-- [disabled]="!r['verify'].value || errorVerify" -->
    </div>
    <br>
    <p>{{'LOGIN.NOTE'|translate}} <a href="/terms-of-use">{{'HEADER.POLICYCHILD2'|translate}}</a>
      {{'COMMON.AND'|translate}} <a href="/privacy-policy">{{'HEADER.POLICYCHILD3'|translate}}</a></p>
  </form>
</ng-template>