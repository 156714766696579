<ng-container *ngIf="product">
  <section class="product-template">
    <h1 class="product__title">{{ product.name }}</h1>

    <div [ngClass]="isPayment ? 'content__switcher--overflow' : 'content__switcher'">
      <div class="content__switcher--template">
        <div *ngFor="let content of contents" (click)="setContentStep(content.value)"
          [ngClass]="[switchValue === content.value ? 'content_active' : 'content_default', content.id === 0 ? 'content__switcher--overflow' : '']">
          <div class="content__switcher--nav">
            <span class="material-icons content-thumb">{{content.icon}}</span>
            <p class="content-label mobile">{{content.label | translate}}</p>
          </div>
          <div class="active-line"></div>
        </div>
      </div>
    </div>

    <div class="product__content">
      <div class="product__router">
        <div class="product__router-line">
          <span routerLink="/" class="product__router-line">{{'COMMON.HOME'|translate}} ></span>
          <p class="product__router-line"><span>{{product.name}}</span></p>
        </div>
      </div>

      <div [ngClass]="isPayment ? 'payment__switchcase' : 'payment-router--hide'" [ngSwitch]="product.metaTitle">
        <div *ngSwitchCase="'bao-hiem-suc-khoe'">
          <app-baohiemsuckhoe (setIsPayment)="setIsPayment($event)" [isShared]="isShared" [appVoucher]="appVoucher" [userCode]="userCode" [meetVoucher]="meetVoucher"></app-baohiemsuckhoe>
        </div>
        <div *ngSwitchCase="'vbi-care-insurance'">
          <app-baohiemsuckhoe (setIsPayment)="setIsPayment($event)" [isShared]="isShared" [appVoucher]="appVoucher" [userCode]="userCode" [meetVoucher]="meetVoucher"></app-baohiemsuckhoe>
        </div>
        <div *ngSwitchCase="'a-care-health-insurance'">
          <app-baohiemacare (setIsPayment)="setIsPayment($event)" [isShared]="isShared" [appVoucher]="appVoucher" [userCode]="userCode" [meetVoucher]="meetVoucher"></app-baohiemacare>
        </div>
        <div *ngSwitchCase="'bao-hiem-suc-khoe-a-care'">
          <app-baohiemacare (setIsPayment)="setIsPayment($event)" [isShared]="isShared" [appVoucher]="appVoucher" [userCode]="userCode" [meetVoucher]="meetVoucher"></app-baohiemacare>
        </div>
        <div *ngSwitchCase="'bao-hiem-mediplus'">
          <app-mediplus (setIsPayment)="setIsPayment($event)"  [isShared]="isShared" [appVoucher]="appVoucher" [userCode]="userCode" [meetVoucher]="meetVoucher"></app-mediplus>
        </div>
        <div *ngSwitchCase="'mediplus-insurance'">
          <app-mediplus (setIsPayment)="setIsPayment($event)"  [isShared]="isShared" [appVoucher]="appVoucher" [userCode]="userCode" [meetVoucher]="meetVoucher"></app-mediplus>
        </div>
        <div *ngSwitchCase="'bao-hiem-mic-care'">
          <app-miccare (setIsPayment)="setIsPayment($event)" [isShared]="isShared" [appVoucher]="appVoucher" [userCode]="userCode" [meetVoucher]="meetVoucher"></app-miccare>
        </div>
        <div *ngSwitchCase="'mic-care-insurance'">
          <app-miccare (setIsPayment)="setIsPayment($event)" [isShared]="isShared" [appVoucher]="appVoucher" [userCode]="userCode" [meetVoucher]="meetVoucher"></app-miccare>
        </div>
        <div *ngSwitchCase="'bao-hiem-tnds-xe-mo-to'">
          <app-motobike (setIsPayment)="setIsPayment($event)" [isShared]="isShared" [appVoucher]="appVoucher" [code]="product.code" [userCode]="userCode" [meetVoucher]="meetVoucher"></app-motobike>
        </div>
        <div *ngSwitchCase="'civil-liability-insurance-for-motorbikes'">
          <app-motobike (setIsPayment)="setIsPayment($event)" [isShared]="isShared" [appVoucher]="appVoucher" [code]="product.code" [userCode]="userCode" [meetVoucher]="meetVoucher"></app-motobike>
        </div>
        <div *ngSwitchCase="'bao-hiem-pti-tnds-xe-may'">
          <app-motobike (setIsPayment)="setIsPayment($event)" [isShared]="isShared" [appVoucher]="appVoucher" [code]="product.code" [userCode]="userCode" [meetVoucher]="meetVoucher"></app-motobike>
        </div>
        <div *ngSwitchCase="'bao-hiem-tnds-xe-o-to'">
          <app-car (setIsPayment)="setIsPayment($event)" [isShared]="isShared" [appVoucher]="appVoucher" [code]="product.code" [userCode]="userCode" [meetVoucher]="meetVoucher"></app-car>
        </div>
        <div *ngSwitchCase="'civil-liability-insurance-for-cars'">
          <app-car (setIsPayment)="setIsPayment($event)" [isShared]="isShared" [appVoucher]="appVoucher" [code]="product.code" [userCode]="userCode" [meetVoucher]="meetVoucher"></app-car>
        </div>
        <div *ngSwitchCase="'bao-hiem-tnds-xe-o-to-bsh'">
          <app-car (setIsPayment)="setIsPayment($event)" [isShared]="isShared" [appVoucher]="appVoucher" [code]="product.code" [userCode]="userCode" [meetVoucher]="meetVoucher"></app-car>
        </div>
        <div *ngSwitchCase="'bsh-civil-liability-insurance-for-cars'">
          <app-car (setIsPayment)="setIsPayment($event)" [isShared]="isShared" [appVoucher]="appVoucher" [code]="product.code" [userCode]="userCode" [meetVoucher]="meetVoucher"></app-car>
        </div>
        <div *ngSwitchCase="'bao-hiem-tro-cap-nam-vien'">
          <app-baohiemtrocapnamvien (setIsPayment)="setIsPayment($event)" [isShared]="isShared" [appVoucher]="appVoucher" [code]="product.code" [userCode]="userCode" [meetVoucher]="meetVoucher"></app-baohiemtrocapnamvien>
        </div>
        <div *ngSwitchCase="'bao-hiem-tro-cap-nam-vien-bsh'">
          <app-baohiemtrocapnamvien (setIsPayment)="setIsPayment($event)" [isShared]="isShared" [appVoucher]="appVoucher" [code]="product.code" [userCode]="userCode" [meetVoucher]="meetVoucher"></app-baohiemtrocapnamvien>
        </div>
        <div *ngSwitchCase="'bsh-hospital-allowance-insurance'">
          <app-baohiemtrocapnamvien (setIsPayment)="setIsPayment($event)" [isShared]="isShared" [appVoucher]="appVoucher" [code]="product.code" [userCode]="userCode" [meetVoucher]="meetVoucher"></app-baohiemtrocapnamvien>
        </div>
        <div *ngSwitchCase="'bao-hiem-y-te-nang-cao-bsh'">
          <app-baohiemytecaocap (setIsPayment)="setIsPayment($event)" [isShared]="isShared" [appVoucher]="appVoucher" [code]="product.code" [userCode]="userCode" [meetVoucher]="meetVoucher"></app-baohiemytecaocap>
        </div>
        <!-- tách bảo hiêm tai nạn cá nhân để thêm tùy chọn gia đình -->
        <div *ngSwitchCase="'bao-hiem-tai-nan-ca-nhan'">
          <app-baohiemtainancanhan (setIsPayment)="setIsPayment($event)" [isShared]="isShared" [appVoucher]="appVoucher" [code]="product.code" [userCode]="userCode" [meetVoucher]="meetVoucher"></app-baohiemtainancanhan>
        </div>
        <div *ngSwitchCase="'personal-accident-insurance-gic'">
          <app-baohiemtainancanhan (setIsPayment)="setIsPayment($event)" [isShared]="isShared" [appVoucher]="appVoucher" [code]="product.code" [userCode]="userCode" [meetVoucher]="meetVoucher"></app-baohiemtainancanhan>
        </div>
        <!-- Bảo hiểm tai nạn cá nhân BSH -->
        <div *ngSwitchCase="'bao-hiem-tai-nan-ca-nhan-bsh'">
          <app-baohiemtainancanhan-bsh (setIsPayment)="setIsPayment($event)" [isShared]="isShared" [appVoucher]="appVoucher" [code]="product.code" [userCode]="userCode" [meetVoucher]="meetVoucher"></app-baohiemtainancanhan-bsh>
        </div>
        <div *ngSwitchCase="'personal-accident-insurance-bsh'">
          <app-baohiemtainancanhan-bsh (setIsPayment)="setIsPayment($event)" [isShared]="isShared" [appVoucher]="appVoucher" [code]="product.code" [userCode]="userCode" [meetVoucher]="meetVoucher"></app-baohiemtainancanhan-bsh>
        </div>
        <div *ngSwitchCase="'nha-tu-nhan'">
          <app-baohiemtrocapnamvien (setIsPayment)="setIsPayment($event)" [isShared]="isShared" [appVoucher]="appVoucher" [code]="product.code" [userCode]="userCode" [meetVoucher]="meetVoucher"></app-baohiemtrocapnamvien>
        </div>
        <div *ngSwitchCase="'vbi-vi-cong-dong'">
          <app-baohiemtrocapnamvien (setIsPayment)="setIsPayment($event)" [isShared]="isShared" [appVoucher]="appVoucher" [code]="product.code" [userCode]="userCode" [meetVoucher]="meetVoucher"></app-baohiemtrocapnamvien>
        </div>
        <div *ngSwitchCase="'bao-hiem-ung-thu-vu'">
          <app-baohiemtrocapnamvien (setIsPayment)="setIsPayment($event)" [isShared]="isShared" [appVoucher]="appVoucher" [code]="product.code" [userCode]="userCode" [meetVoucher]="meetVoucher"></app-baohiemtrocapnamvien>
        </div>
        <div *ngSwitchCase="'tuong-lai-tuoi-sang'">
          <app-baohiemtrocapnamvien (setIsPayment)="setIsPayment($event)" [isShared]="isShared" [appVoucher]="appVoucher" [code]="product.code" [userCode]="userCode" [meetVoucher]="meetVoucher"></app-baohiemtrocapnamvien>
        </div>
        <div *ngSwitchCase="'bao-hiem-ung-thu'">
          <app-baohiemtrocapnamvien (setIsPayment)="setIsPayment($event)" [isShared]="isShared" [appVoucher]="appVoucher" [code]="product.code" [userCode]="userCode" [meetVoucher]="meetVoucher"></app-baohiemtrocapnamvien>
        </div>
        <div *ngSwitchCase="'bao-hiem-tru-cot-gia-dinh'">
          <app-baohiemtrocapnamvien (setIsPayment)="setIsPayment($event)" [isShared]="isShared" [appVoucher]="appVoucher" [code]="product.code" [userCode]="userCode" [meetVoucher]="meetVoucher"></app-baohiemtrocapnamvien>
        </div>
        <div *ngSwitchDefault>
          <app-loading></app-loading>
        </div>
      </div>
      <div #loiIch [ngClass]="isPayment ? 'content__switchcase--hidden' : 'content__switchcase'">
        <ngx-skeleton-loader *ngIf="product.loiIch.length === 0" count="1" [theme]="{
            margin: '8px 0',
            width: '240px',
            height: '32px',
            'border-radius': '3px'
          }"></ngx-skeleton-loader>
        <ngx-skeleton-loader *ngIf="product.loiIch.length === 0" count="12" appearance="line"></ngx-skeleton-loader>
        <div id="loiIch" [ngClass]="switchValue === 1 ? '' : 'content__switchcase--hidden'"
          [innerHTML]="product.loiIch"></div>
        <div #quyTrinh id="quyTrinh" [ngClass]="switchValue === 2 ? '' : 'content__switchcase--hidden'"
          [innerHTML]="product.quyTrinh"></div>
        <div #gioiThieu id="gioiThieu" [ngClass]="switchValue === 3 ? '' : 'content__switchcase--hidden'"
          [innerHTML]="product.gioiThieu"></div>
      </div>
    </div>

    <div [ngClass]="isPayment ? 'payment-router--hide' : 'payment-router'" (click)="isPayment = true">
      <span>Mua ngay</span>
    </div>

    <div id="content__controller" class="content__controller">
      <div class="content__controller--template">
        <div *ngFor="let content of contents" (click)="setContentStep(content.value)"
          [ngClass]="contentValue === content.value ? 'content_active' : ''" (click)="scroll(content.href)">
          <span class="material-icons content-thumb">{{content.icon}}</span>
          <p class="content-label">{{content.label|translate}}</p>
        </div>
      </div>
    </div>
  </section>
</ng-container>
