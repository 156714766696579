import { ITCompareFilter, ITCompareForm, ITCompareKhoangGia } from '@models/compare.model';
import { ApiService } from '@services/api.service';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { NgbModal, NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { COMPARE_FILTER, COMPARE_ORDER } from 'src/utils/compare';

@Component({
  selector: 'app-compare-result',
  templateUrl: './compare-result.component.html',
  styleUrls: ['./compare-result.component.css']
})
export class CompareResultComponent implements OnInit {
  public isToggle = false
  public formValue: ITCompareForm | any = localStorage.getItem('compare-result')
  policies: any[] = []
  packages: any[] = []
  nccs: any[] = []
  output: ITCompareKhoangGia[] = []
  results: ITCompareKhoangGia[] = []
  isOpenedAddBox: boolean = false
  filterModalID: number = 0
  public filter: ITCompareFilter = {
    orderbyphi: '',
    limit: 27,
    ncc: '',
    goi: '',
    pageNumber: 1,
    pageSize: 6
  }
  public packageForm: any = {
    pack: this.formValue.Goi
  }

  public isPolicyCollapsed = false
  public isPackageCollapsed = false
  public isFeeCollapsed = false
  dynamic: boolean = false

  constructor(
    private ApiService: ApiService,
    private offcanvasService: NgbOffcanvas,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
    this.formValue = JSON.parse(this.formValue)
    this.getComparePolicies()
    this.getCompareKhoangGia()
    this.getCompareGoi()
    this.getCompareNcc()
    window.addEventListener('scroll', this.scrollController, true);
  }

  ngOnDestroy() {
    window.removeEventListener('scroll', this.scrollController, true);
    this.offcanvasService.dismiss()
  }

  getComparePolicies() {
    this.ApiService.getComparePolicies(this.formValue.LoaiSanPham).subscribe(data => {
      const first = data[0]
      const last = data[4]

      this.policies = data

      this.policies[4] = first
      this.policies[0] = last
    })
  }

  priceHandler(data: any[]) {
    data.map(item => {
      item.gia = item.gia.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      item.quyenLoi.map((e: any) => {
        e.chiTra = e.chiTra.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      })
    })
    return data
  }

  getCompareKhoangGia() {
    this.ApiService.getCompareKhoangGia(
      this.formValue.LoaiSanPham,
      this.formValue.Tuoi,
      this.formValue.KhoangGia,
      this.formValue.GioiTinh,
      this.filter.orderbyphi,
      this.filter.limit,
      this.filter.ncc,
      this.filter.goi,
      this.filter.pageNumber,
      this.filter.pageSize
    ).subscribe(data => {
      this.priceHandler(data)
      this.output = data
    })
  }

  addItem(item: ITCompareKhoangGia) {
    if (this.results.length < 3) {
      this.results.includes(item) || this.results.push(item)
    } else return
  }

  toggle() {
    this.dynamic = !this.dynamic
    this.isToggle = true
    this.offcanvasService.dismiss('Cross click')
  }

  onPackChange(goi: string, e: any) {
    e.target.checked ? this.filter.goi = goi : this.filter.goi = ''
  }

  onNccChange(ncc: string, e: any) {
    e.target.checked ? this.filter.ncc = ncc : this.filter.ncc = ''
  }

  listHandler(content: TemplateRef<any>, index: number, item: ITCompareKhoangGia, e: any) {
    this.dynamic = e.currentTarget.checked

    this.dynamic ? this.addItem(item) : this.onRemove(item)
    this.offcanvasListBox(content)
  }

  offcanvasListBox(content: TemplateRef<any>) {
    this.offcanvasService.hasOpenOffcanvas() || this.offcanvasService.open(content, {
      panelClass: window.innerWidth > 500 ? 'modal--25' : 'modal--35',
      backdrop: false,
      scroll: true,
      position: 'bottom',
    })
    this.isOpenedAddBox && (this.isOpenedAddBox = false)
  }

  offcanvasAddBox(content: TemplateRef<any>) {
    this.offcanvasService.hasOpenOffcanvas() || (this.isOpenedAddBox = false)

    if (this.isOpenedAddBox) {
      return
    } else {
      this.offcanvasService.open(content, {
        panelClass: this.isToggle ? 'add-box modal--25' : 'add-box',
        scroll: true,
        position: 'bottom',
      })
      this.isOpenedAddBox = true
    }
  }

  closeAddBox() {
    this.offcanvasService.dismiss(confirm)
    this.isOpenedAddBox = false
  }

  onRemove(item: ITCompareKhoangGia) {
    const index = this.results.indexOf(item);
    index > -1 && this.results.splice(index, 1)
  }

  getCompareGoi() {
    this.ApiService.getCompareGoi().subscribe(data => {
      this.packages = data
    })
  }

  getCompareNcc() {
    this.ApiService.getCompareNcc(this.formValue.LoaiSanPham).subscribe(data => {
      this.nccs = data
    })
  }

  // Add the compare__filter--scrolled class to the navbar when you reach its scroll position. Remove "compare__filter--scrolled" when you leave the scroll position
  scrollController() {
    var controller: any = document.getElementById('compare__filter')
    window.pageYOffset >= 18
      ? controller?.classList?.add("compare__filter--scrolled")
      : controller?.classList?.remove("compare__filter--scrolled")
  }

  openFullscreen(content: TemplateRef<any>, id: number) {
    this.filterModalID = id
		this.modalService.open(content, { centered: true });
	}

  filters = COMPARE_FILTER
  order = COMPARE_ORDER
}
