<div class="content-title">
  <ng-container *ngIf="h1">
    <h1 class="content-title__label">{{upper}}</h1>
    <h1 class="content-title__label" ngClass="{{center ? '' : 'start'}}">{{label}}</h1>
  </ng-container>
  <ng-container *ngIf="!h1">
    <h2 class="content-title__label">{{upper}}</h2>
    <h2 class="content-title__label" ngClass="{{center ? '' : 'start'}}">{{label}}</h2>
  </ng-container>
  <div class="content-title__underline" ngClass="{{line ? '' : 'notline'}}">
    <div class="content-title__underline-more"></div>
  </div>
</div>
