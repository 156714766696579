import { eff, now } from "."

export const BHSKACARE_INSURANCE = [
  {
    id: 1,
    value: 'goi_1',
    label: 'PRODUCTS.ACARE.PACK1',
    label_api: 'FINLIFE_Gói Phổ Thông'
  },
  {
    id: 2,
    value: 'goi_2',
    label: 'PRODUCTS.ACARE.PACK2',
    label_api: 'FINLIFE_Gói Tiêu Chuẩn'

  },
  {
    id: 3,
    value: 'goi_3',
    label: 'PRODUCTS.ACARE.PACK3',
    label_api: 'FINLIFE_Gói Nâng Cao'

  },
  {
    id: 4,
    value: 'goi_4',
    label: 'PRODUCTS.ACARE.PACK4',
    label_api: 'FINLIFE_Gói Cao Cấp'

  },
]

export const ACARE_CUSTOMER_TEMPLATE = {
  moiquanheP: '',
  tenP: '',
  dchiP: '',
  nG_SINHP: '',
  startDate: '',
  endDate: '',
  idContract: '',
  gioiP: '',
  mobiP: '',
  cmtP: '',
  emailP: '',
  tenhuong: '',
  cmthuong: '',
  ttsk1: false,
  ttsk2: false,
  ttsk3: false,
  ttsk4: false,
  ttsk5: false,
}

export const ACARE_AGE = [
  { id: 1, value: 1, label: 'PRODUCTS.ACARE.AGE1' },
  { id: 2, value: 2, label: 'PRODUCTS.ACARE.AGE2' },
  { id: 3, value: 3, label: 'PRODUCTS.ACARE.AGE3' },
  { id: 4, value: 4, label: 'PRODUCTS.ACARE.AGE4' },
  { id: 5, value: 5, label: 'PRODUCTS.ACARE.AGE5' },
  { id: 6, value: 6, label: 'PRODUCTS.ACARE.AGE6' },
  { id: 7, value: 7, label: 'PRODUCTS.ACARE.AGE7' },
  { id: 8, value: 8, label: 'PRODUCTS.ACARE.AGE8' },
  { id: 9, value: 9, label: 'PRODUCTS.ACARE.AGE9' },
  { id: 10, value: 10, label: 'PRODUCTS.ACARE.AGE10' },

]

export const ACARE_BILL_TEMPLATE = {
  tuoi: 0,
  goi: '',
  trocapnamviendotainan: false,
  thaisan: false,
  ngoaitru: false
}

export const ACARE_FORM_TEMPLATE = {
  nv: 'ACARE',
  nG_SINH: '',
  files: {
    front: '',
    back: '',
  },
  daidien: '',
  chucvu: '',
  ttoan: '',
  nguoiGioiThieu: '',
  ten: '',
  lkh: '',
  gioi: '',
  cmt: '',
  mobi: '',
  email: '',
  dchi: '',
  tensanpham: 'Bảo hiểm sức khoẻ A-CARE',
  goisanpham: '',
  thoihan: '12',
  iddangky: '',
  magioithieu: '',
  moiquanhe: '',
  user_Code: '',
}

export const ACARE_PASSENGER_TEMPLATE = {
  moiquanheP: '',
  tenP: '',
  dchiP: '',
  nG_SINHP: '',
  startDate: now,
  endDate: eff,
  idContract: '',
  gioiP: '',
  mobiP: '',
  cmtP: '',
  emailP: '',
  tenhuong: '',
  cmthuong: '',
  ttsk1: false,
  ttsk2: false,
  ttsk3: false,
  ttsk4: false,
  ttsk5: false,
}
