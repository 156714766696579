import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-help-card',
  templateUrl: './help-card.component.html',
  styleUrls: ['./help-card.component.css']
})
export class HelpCardComponent implements OnInit {
  @Input() href = ''
  @Input() icon = ''
  @Input() head = ''
  @Input() info = ''

  constructor() { }

  ngOnInit(): void {
  }

}
