import { Component, OnInit, TemplateRef, Pipe, PipeTransform } from '@angular/core';
import { ExportService } from '@services/export.service';
import * as FileSaver from 'file-saver';
import { CartService } from '@services/cart/cart.service';
import { ITCart, ITCartBSHCar } from '@models/cart.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ERROR, VALID } from 'src/utils/validation';
import { GENDERS, STATUS_SELECTOR, STATUS_SELECTOR_A, STATUS_SELECTOR_M } from 'src/utils/constant';
import { ApiService } from '@services/api.service';
import { environment } from '@environments/environment';
import { ITCategory } from '@models/category.model';
import { ITProduct } from '@models/product.model';
import { ToastService } from '@services/toast.service';
import { ProtectService } from '@services/protect/protect.service';
import { BHSK_RELATIVE } from 'src/utils/product/vbicare';
import { CommonService } from '@services/common/common.service';

@Component({
  selector: 'app-material-manager',
  templateUrl: './material-manager.component.html',
  styleUrls: ['./material-manager.component.css']
})
export class MaterialManagerComponent implements OnInit {
  prodEnv: boolean = environment.production
  contracts: ITCart[] = []
  categories: ITCategory[] = []
  products: ITProduct[] = []
  contract = {
    id: 0,
    ngaytao: "",
    hoten: "",
    sodienthoai: "",
    cccd: "",
    xe_kinh_doanh: "",
    ngaysinh: "",
    gioitinh: "",
    diachi: "",
    moiquanhe: "",
    loaibaohiem: "",
    goibaohiem: "",
    thoihan: "",
    tongphi: "",
    hinhthucthanhtoan: "",
    thoigianthanhtoan: "",
    trangthai: "",
    contractUrl: "",
    trangthaithanhtoan: "",
    id_hoso: "",
    mahoadon: "",
    magiaodich: "",
    trangthaivnpay: "",
    maloi: "",
  }
  BSHCar: ITCartBSHCar = {
    id: 0,
    id_dangky: 0,
    bien_xe: '',
    so_may: '',
    so_khung: '',
    nguon_goc: '',
    hang_xe: '',
    hieu_xe: '',
    nam_sx: '',
    so_cn: '',
    trong_tai: '',
    ngay_hl: '',
    ngay_kt: '',
    muc_dich_kinh_doanh: '',
    muc_dich_su_dung: '',
    trach_nhiem_dan_su_bat_buoc: '',
    linh_vuc_kinh_doanh: '',
  }
  materialList:any=[]
  page: number = 1
  total: number = 10
  pageSize: number = 10
  trangthaithanhtoan: string = 'Đã thanh toán'
  trangthai: string = ''
  user: string = ''
  tempID: number = 0
  dialogType: string = ''
  disableInput: boolean = false
  filter: FormGroup = this._formBuilder.group({
    idProduct: [''],
    idCategory: [''],
    keyword: [''],
    fromDate: [''],
    toDate: [new Date()],
    status: ['Thành công'],
  })
  form: FormGroup = this._formBuilder.group({
    id: ['', Validators.required],
    ho_ten: ['', Validators.required],
    so_dien_thoai: ['', Validators.required],
    xe_kinh_doanh: ['', Validators.required],
    loai_xe: ['', Validators.required],
    so_cho_ngoi: ['', Validators.required],
    trang_thai: ['', Validators.required],
    gia_xe: ['', Validators.required],
    noi_san_xuat: ['', Validators.required],
    tu_ngay: ['', Validators.required],
  })
  isBSHCar: boolean = false
  materialDetail: any
  isUpload: any;

  constructor(
    private _service: CartService,
    private ApiService: ApiService,
    private _formBuilder: FormBuilder,
    public _toast: ToastService,
    private _common: CommonService,
    private _modal: NgbModal,
    private _protect: ProtectService,
  ) { }

  ngOnInit(): void {
    this.getContractPages()
    this.getContracts()
    this.getMaterialList(1,10,'','')
    this.getCategories()
    this.getProducts()
  }

  getContractPages() {
    this._service.getContractPages(this.pageSize).subscribe(res => {
      this.total = res.tongbanghi
    })
  }

  getContracts() {
    this.contracts && (this.contracts = [])

    this._service.getContracts(this.page, this.pageSize, this.trangthaithanhtoan).subscribe(res => {
      this.contracts = res
      this.onFormat(this.contracts)
      this.contracts = this.onFilterCategory(this.contracts);
    })
  }
  
  getMaterialList(page?:number,pageSize?:number,trangthai?:string,name?:string){
    this._service.getMaterialList(page, pageSize, trangthai, name).subscribe(res => {
      this.materialList = res
    })
  }

  getCategories() {
    this.ApiService.getCategories().subscribe(res => {
      this.categories = res;
    })
  }

  getProducts() {
    this.ApiService.getProducts().subscribe(res => {
      this.products = res;
    })
  }

  

  // getAccsByID(id: number) {
  //   this._service.getMaterialByID(id).subscribe(res => {
  //     console.log(res)
  //     this.onInitData(res)
  //   })
  // }

  getMaterialByID(id: number){
    this._service.getMaterialByID(id).subscribe(res => {
      console.log(res)
      this.onInitData(res)
      this.materialDetail = res
    })
  }

  upload(e: any) {
    let formData = new FormData()
    for (let file of e.target.files) {
      formData.append('files', file)
    }
    this._common.uploads(formData).subscribe((res) => {
      this._toast.show('Tải file thành công !', { icon: 'check_circle', classname: 'bg-success text-light' })
      // console.log(res[0])
      this.isUpload=res[0].name;
      this.form.controls['contractUrl'].setValue(res[0].name)
    })
  }

  getBSHCarContract(id: number) {
    this._service.getBSHCarContract(id).subscribe(res => {
      this.BSHCar = res[0]
      console.log(this.BSHCar)
    })
  }
  clearOldData(){
    this.form.controls['id'].setValue('')
    this.form.controls['ho_ten'].setValue('')
    this.form.controls['so_dien_thoai'].setValue('')
    this.form.controls['xe_kinh_doanh'].setValue('')
    this.form.controls['loai_xe'].setValue('')
    this.form.controls['so_cho_ngoi'].setValue('')
    this.form.controls['trang_thai'].setValue('')
    this.form.controls['gia_xe'].setValue('')
    this.form.controls['noi_san_xuat'].setValue('')
    this.form.controls['tu_ngay'].setValue('')
  }
  onInitData(data: any) {
    this.clearOldData()
    this.form.controls['id'].setValue(data.id)
    this.form.controls['ho_ten'].setValue(data.ten_khach_hang)
    this.form.controls['so_dien_thoai'].setValue(data.so_dien_thoai)
    this.form.controls['xe_kinh_doanh'].setValue(data.xe_kinh_doanh)
    this.form.controls['loai_xe'].setValue(data.loai_xe)
    this.form.controls['so_cho_ngoi'].setValue(data.so_cho_ngoi)
    this.form.controls['trang_thai'].setValue(data.trang_thai)
    this.form.controls['gia_xe'].setValue(data.gia_xe)
    this.form.controls['noi_san_xuat'].setValue(data.noi_san_xuat||'')
    this.form.controls['tu_ngay'].setValue(data.tu_ngay||'')
  }

  onSave() {
    let payload = {
      id: this.form.controls['id'].value,
      ten_khach_hang: this.form.controls['ho_ten'].value,
      so_dien_thoai: this.form.controls['so_dien_thoai'].value,
      xe_kinh_doanh: this.form.controls['xe_kinh_doanh'].value,
      loai_xe: this.form.controls['loai_xe'].value,
      so_cho_ngoi: this.form.controls['so_cho_ngoi'].value,
      trang_thai: this.form.controls['trang_thai'].value,
      gia_xe: this.form.controls['gia_xe'].value,
      tu_ngay: this.form.controls['tu_ngay'].value,
      noi_san_xuat: this.form.controls['noi_san_xuat'].value,
    }
    console.log(payload)
    this._service.putMaterial(this.tempID, payload).subscribe(res => {
      console.log(res)
      res && (
        this.getMaterialList(this.page,this.pageSize,this.trangthai,this.user),
        this._toast.show('Cập nhật thành công!', { icon: 'check_circle', classname: 'bg-success text-light' })
      )
    })
  }

  onFormat(data: any) {
    data.map((item: any) => {
      item.tongphi = item.tongphi.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    })
  }
  
  onFilterCategory(data:any){
    let filterData:any = [];
    for(let i=0; i<data.length;i++){
      if(data[i].loaibaohiem != "Kiến tạo giá trị"){
        filterData.push(data[i]);
      }
    }
    return filterData;
  }

  onSort() {
  }

  onDelete() {

  }

  dialogHandler(type: string, row: any, content: TemplateRef<any>) {
    this.dialogType = type
    type === 'view' ? (this.disableInput = true) : (this.disableInput = false)
    type === 'edit' && (this.tempID = row.id)
    this.getMaterialByID(row.id)
    this._modal.open(content, { size: 'xl', centered: true, scrollable: true });
  }

  copyInputMessage(inputElement: any) {
    inputElement.select();
    document.execCommand('copy');
    inputElement.setSelectionRange(0, 0);
  }

  removeVietnameseTones(str: string) {
    str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
    str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
    str = str.replace(/đ/g, "d");
    str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A");
    str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E");
    str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I");
    str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O");
    str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U");
    str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y");
    str = str.replace(/Đ/g, "D");
    // Some system encode vietnamese combining accent as individual utf-8 characters
    // Một vài bộ encode coi các dấu mũ, dấu chữ như một kí tự riêng biệt nên thêm hai dòng này
    str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ""); // ̀ ́ ̃ ̉ ̣  huyền, sắc, ngã, hỏi, nặng
    str = str.replace(/\u02C6|\u0306|\u031B/g, ""); // ˆ ̆ ̛  Â, Ê, Ă, Ơ, Ư
    // Remove extra spaces
    // Bỏ các khoảng trắng liền nhau
    str = str.replace(/ + /g, " ");
    str = str.trim();
    // Remove punctuations
    // Bỏ dấu câu, kí tự đặc biệt
    str = str.replace(/!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|,|\.|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|_|`|-|{|}|\||\\/g, " ");
    return str;
  }

  error = ERROR
  pattern = VALID
  genders = GENDERS
  statusSeletor = STATUS_SELECTOR_M
  mqhnguoimua = BHSK_RELATIVE
}
