import { Injectable, NgModule } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Resolve, RouterModule, RouterStateSnapshot, Routes, TitleStrategy } from '@angular/router';
import { CompareComponent } from '@modules/compare/compare.component';
import { NotfoundComponent } from '@components/notfound/notfound.component';
import { AuthComponent } from '@modules/auth/auth.component';
import { CartManagerComponent } from '@modules/cart-manager/cart-manager.component';
import { CategoriesManagerComponent } from '@modules/categories-manager/categories-manager.component';
import { CompareResultComponent } from '@modules/compare-result/compare-result.component';
import { ContactComponent } from '@modules/contact/contact.component';
import { ContentDetailComponent } from '@modules/content-detail/content-detail.component';
import { ContentManagerComponent } from '@modules/content-manager/content-manager.component';
import { ContentEditComponent } from '@modules/content-edit/content-edit.component';
import { FeedbacksManagerComponent } from '@modules/feedbacks-manager/feedbacks-manager.component';
import { HomeComponent } from '@modules/home/home.component';
import { IntroduceComponent } from '@modules/introduce/introduce.component';
import { InvestorsManagerComponent } from '@modules/investors-manager/investors-manager.component';
import { LoginComponent } from '@modules/login/login.component';
import { LoginGPAYComponent } from '@modules/login-gpay/login-gpay.component';
import { NewsComponent } from '@modules/news/news.component';
import { OverviewComponent } from '@modules/overview/overview.component';
import { PaymentConfirmComponent } from '@modules/payment-confirm/payment-confirm.component';
import { PaymentComponent } from '@modules/payment/payment.component';
import { ProductComponent } from '@modules/product/product.component';
import { ProductEditComponent } from '@modules/product-edit/product-edit.component';
import { ProductsManagerComponent } from '@modules/products-manager/products-manager.component';
import { SlidesManagerComponent } from '@modules/slides-manager/slides-manager.component';
import { VoucherManagerComponent } from '@modules/voucher-manager/voucher-manager.component';
import { AuthGuard } from '@services/auth/guard.service';
import { ActiveComponent } from '@components/active/active.component';
import { TermsComponent } from '@modules/terms/terms.component';
import { PolicyComponent } from '@modules/policy/policy.component';
import { SignContractComponent } from '@modules/sign-contract/sign-contract.component';
import { AcctiveAccountComponent } from '@modules/acctive-account/acctive-account.component';
import { SignatureComponent } from '@modules/signature/signature.component';
import { VoucherEditComponent } from '@modules/voucher-edit/voucher-edit.component';
import { WebviewAppComponent } from './webview-app/webview-app.component';
import { ProcessComponent } from '@modules/process/process.component';
import { AccManagerComponent } from './modules/acc-manager/acc-manager.component';
import { CollaboratorsLoginComponent } from '@modules/collaborators-login/collaborators-login.component';
import { CollaboratorsManagerComponent } from './modules/collaborators-manager/collaborators-manager.component';
import { CollaboratorsContractsComponent } from '@modules/collaborators-contracts/collaborators-contracts.component';
import { GuardCTVGuard } from '@services/auth/guard-ctv.guard';
import { MaterialManagerComponent } from '@modules/material-manager/material-manager.component';
import { ContractMeetvoucherComponent } from '@modules/contract-meetvoucher/contract-meetvoucher.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    data: {
      title: 'Finme',
      ogTitle: 'Finme',
      descrption: 'Bảo hiểm công nghệ trực tuyến',
      ogDescription: 'Bảo hiểm công nghệ trực tuyến',
      ogImage: 'https://finme.vn/assets/Upload/files/finme3.png',
      isHome: true,
    }
  },
  {
    path: 'partnership/:partner',
    component: HomeComponent,
    data: {
      title: 'Finme',
      ogTitle: 'Finme',
      descrption: 'Bảo hiểm công nghệ trực tuyến - Đối tác',
      ogDescription: 'Bảo hiểm công nghệ trực tuyến - Đối tác',
      ogImage: 'https://finme.vn/assets/Upload/files/finme3.png',
      isHome: true,
    }
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'Finme - Đăng nhập',
      ogTitle: 'Đăng nhập',
      descrption: 'Đăng nhập để sử dụng đặc quyền của cộng tác viên',
      ogDescription: 'Đăng nhập để sử dụng đặc quyền của cộng tác viên',
      ogImage: 'https://finme.vn/assets/Upload/files/finme3.png'
    }
  },
  // {
  //   path: 'login-gpay',
  //   component: LoginGPAYComponent,
  //   data: {
  //     title: 'Finme - Đăng ký GPAY',
  //     ogTitle: 'Đăng ký GPAY',
  //     descrption: 'Đăng ký GPAY để sử dụng đặc quyền',
  //     ogDescription: 'Đăng nhập GPAY để sử dụng đặc quyền',
  //     ogImage: 'https://finme.vn/assets/Upload/files/finme3.png'
  //   }
  // },
  {
    path: 'register/:user',
    component: LoginComponent,
    data: {
      title: 'Finme - Đăng ký cộng tác viên',
      ogTitle: 'Đăng ký cộng tác viên',
      descrption: 'Trở thành công tác viên của FinLife để nhận hoa hồng khủng',
      ogDescription: 'Trở thành công tác viên của FinLife để nhận hoa hồng khủng',
      ogImage: 'https://finme.vn/assets/Upload/images/register.jpg'
    }
  },
  {
    path: 'download',
    title: 'Finme - Tải ứng dụng Finme',
    component: ActiveComponent
  },
  {
    path: 'introduce',
    title: 'Finme - Giới thiệu',
    component: IntroduceComponent
  },
  {
    path: 'compare',
    component: CompareComponent,
    data: {
      title: 'Finme - So sánh',
      ogTitle: 'So sánh bảo hiểm',
      descrption: 'So sánh và lựa chọn gói bảo hiểm của bạn',
      ogDescription: 'So sánh và lựa chọn gói bảo hiểm của bạn',
      ogImage: 'https://finme.vn/assets/images/ssbh01.jpg'
    }
  },
  {
    path: 'compare-result',
    component: CompareResultComponent,
    data: {
      title: 'Finme - So sánh',
      ogTitle: 'So sánh bảo hiểm',
      descrption: 'So sánh và lựa chọn gói bảo hiểm của bạn',
      ogDescription: 'So sánh và lựa chọn gói bảo hiểm của bạn',
      ogImage: 'https://finme.vn/assets/images/ssbh01.jpg',
      hiddenFooter: true,
    }
  },
  {
    path: 'news',
    component: NewsComponent,
    data: {
      title: 'Finme - Tin tức',
      ogTitle: 'Trang tin tức Finme',
      descrption: 'Cập nhật thông tin, tin tức từ FinLife Việt Nam',
      ogDescription: 'Cập nhật thông tin, tin tức từ FinLife Việt Nam',
      ogImage: 'https://finme.vn/assets/Upload/images/register.jpg'
    }
  },
  {
    path: 'news/:meta', component: ContentDetailComponent
  },
  {
    path: 'contact',
    title: 'Finme - Liên hệ',
    component: ContactComponent
  },
  {
    path: 'contact-meetvoucher/:meetvoucher',
    title: 'Finme - Đăng ký voucher',
    component: ContractMeetvoucherComponent,
    data: {
      hiddenHeader: true,
      hiddenFooter: true,
    }
  },
  {
    path: 'terms-of-use',
    title: 'Finme - Terms of use',
    component: TermsComponent
  },
  {
    path: 'privacy-policy',
    title: 'Finme - Privacy policy',
    component: PolicyComponent
  },
  {
    path: 'san-pham/:meta',
    component: ProductComponent,
    data: {
      hiddenTopFooter: true,
      title: 'Finme - Sản phẩm',
      ogTitle: 'Sản phẩm bảo hiểm số',
      descrption: 'Mua ngay bảo hiểm trực tuyến',
      ogDescription: 'Mua ngay bảo hiểm trực tuyến',
      ogImage: 'https://finme.vn/assets/Upload/files/finme3.png'
    }
  },
  {
    path: 'san-pham/:meta/meetvoucher/:meetvoucher',
    component: ProductComponent,
    data: {
      hiddenTopFooter: true,
      title: 'Finme - Sản phẩm',
      ogTitle: 'Sản phẩm bảo hiểm số',
      descrption: 'Mua ngay bảo hiểm trực tuyến',
      ogDescription: 'Mua ngay bảo hiểm trực tuyến',
      ogImage: 'https://finme.vn/assets/Upload/files/finme3.png'
    }
  },
  {
    path: 'san-pham/:meta/:user',
    component: ProductComponent,
    data: {
      hiddenTopFooter: true
    }
  },
  {
    path: 'paymentconfirm',
    title: 'Finme - Xác nhận thanh toán',
    component: PaymentConfirmComponent,
    data: {
      hiddenHeader: true,
      hiddenFooter: true,
    }
  },
  {
    path: 'webview/sign-contract/:info',
    title: 'Finme - Ký hợp đồng',
    component: SignContractComponent,
    data: {
      hiddenHeader: true,
      hiddenFooter: true,
    }
  },
  {
    path: ':keyactive',
    title: 'Finme - Ký hợp đồng',
    component: AcctiveAccountComponent,
    data: {
      hiddenHeader: true,
      hiddenFooter: true,
    }
  },
  {
    path: 'webview/notification',
    title: 'Finme - Notification',
    component: SignatureComponent,
    data: {
      hiddenHeader: true,
      hiddenFooter: true,
    }
  },
  {
    path: 'webview/notification/app',
    title: 'Finme - Notification',
    component: ProcessComponent,
    data: {
      hiddenHeader: true,
      hiddenFooter: true,
    }
  },
  {
    path: 'webview/:meta&USER_CODE',
    component: PaymentComponent,
    data: {
      hiddenHeader: true,
      hiddenFooter: true,
      title: 'FinMe Bảo hiểm số',
      ogTitle: 'FinMe Bảo hiểm số',
      descrption: 'Sản phẩm bảo hiểm số từ FinLife Việt Nam',
      ogDescription: 'Sản phẩm bảo hiểm số từ FinLife Việt Nam',
      ogImage: 'https://finme.vn/assets/Upload/files/finme3.png'
    }
  },
  // webview-app
  {
    path: 'webview/app/:meta&USER_CODE',
    component: WebviewAppComponent,
    data: {
      hiddenHeader: true,
      hiddenFooter: true,
      title: 'FinMe Bảo hiểm số',
      ogTitle: 'FinMe Bảo hiểm số',
      descrption: 'Sản phẩm bảo hiểm số từ FinLife Việt Nam',
      ogDescription: 'Sản phẩm bảo hiểm số từ FinLife Việt Nam',
      ogImage: 'https://finme.vn/assets/Upload/files/finme3.png'
    }
  },
  {
    path: 'webview/app/:meta/:voucher',
    component: WebviewAppComponent,
    data: {
      hiddenHeader: true,
      hiddenFooter: true,
      title: 'FinMe Bảo hiểm số',
      ogTitle: 'FinMe Bảo hiểm số',
      descrption: 'Sản phẩm bảo hiểm số từ FinLife Việt Nam',
      ogDescription: 'Sản phẩm bảo hiểm số từ FinLife Việt Nam',
      ogImage: 'https://finme.vn/assets/Upload/files/finme3.png'
    }
  },

  // Admin routes
  {
    path: 'admin', redirectTo: 'admin/login', pathMatch: 'full'
  },
  {
    path: 'admin/login',
    title: 'Xác minh quản trị viên',
    component: AuthComponent,
    data: {
      hiddenHeader: true,
      hiddenFooter: true,
      isAdmin: true,
    }
  },
  {
    path: 'collaborators/login',
    title: 'Xác minh Cộng tác viên',
    component: CollaboratorsLoginComponent,
    data: {
      hiddenHeader: true,
      hiddenFooter: true,
      isAdmin: false,
    }
  },
  {
    path: 'collaborators/managerF1',
    title: 'Xác minh Cộng tác viên',
    canActivate: [GuardCTVGuard],
    component: CollaboratorsManagerComponent,
    data: {
      hiddenFooter: true,
      isAdmin: false,
      isCol:true
    }
  },
  {
    path: 'collaborators/contracts',
    title: 'Quản lý Hợp đồng',
    canActivate: [GuardCTVGuard],
    component: CollaboratorsContractsComponent,
    data: {
      hiddenFooter: true,
      isAdmin: false,
      isCol:true
    }
  },
  {
    path: 'admin/dashboard',
    title: 'Thống kê',
    canActivate: [AuthGuard],
    component: OverviewComponent,
    data: {
      hiddenFooter: true,
      isAdmin: true,
    }
  },
  {
    path: 'admin/cart-manager',
    title: 'Quản lý đăng ký sản phẩm',
    canActivate: [AuthGuard],
    component: CartManagerComponent,
    data: {
      hiddenFooter: true,
      isAdmin: true,
    }
  },
  {
    path: 'admin/acc-manager',
    title: 'Quản lý giao dịch tích lũy',
    canActivate: [AuthGuard],
    component: AccManagerComponent,
    data: {
      hiddenFooter: true,
      isAdmin: true,
    }
  },
  {
    path: 'admin/material-manager',
    title: 'Quản lý Đăng ký vật chất xe',
    canActivate: [AuthGuard],
    component: MaterialManagerComponent,
    data: {
      hiddenFooter: true,
      isAdmin: true,
    }
  },
  {
    path: 'admin/categories-manager',
    title: 'Quản lý danh mục',
    canActivate: [AuthGuard],
    component: CategoriesManagerComponent,
    data: {
      hiddenFooter: true,
      isAdmin: true,
    }
  },
  {
    path: 'admin/products-manager',
    title: 'Quản lý sản phẩm',
    canActivate: [AuthGuard],
    component: ProductsManagerComponent,
    data: {
      hiddenFooter: true,
      isAdmin: true,
    }
  },
  {
    path: 'admin/product-edit/:id',
    title: 'Chỉnh sửa sản phẩm',
    canActivate: [AuthGuard],
    component: ProductEditComponent,
    data: {
      hiddenFooter: true,
      isAdmin: true,
    }
  },
  {
    path: 'admin/content-manager',
    title: 'Quản lý bài viết',
    canActivate: [AuthGuard],
    component: ContentManagerComponent,
    data: {
      hiddenFooter: true,
      isAdmin: true,
    }
  },
  {
    path: 'admin/content-edit/:id',
    title: 'Chỉnh sửa bài viết',
    canActivate: [AuthGuard],
    component: ContentEditComponent,
    data: {
      hiddenFooter: true,
      isAdmin: true,
    }
  },
  {
    path: 'admin/feedback-manager',
    title: 'Quản lý phản hồi',
    canActivate: [AuthGuard],
    component: FeedbacksManagerComponent,
    data: {
      hiddenFooter: true,
      isAdmin: true,
    }
  },
  {
    path: 'admin/partners-manager',
    title: 'Quản lý đối tác',
    canActivate: [AuthGuard],
    component: OverviewComponent,
    data: {
      hiddenFooter: true,
      isAdmin: true,
    }
  },
  {
    path: 'admin/apartments-manager',
    title: 'Quản lý nhà đầu tư',
    canActivate: [AuthGuard],
    component: InvestorsManagerComponent,
    data: {
      hiddenFooter: true,
      isAdmin: true,
    }
  },
  {
    path: 'admin/brands-manager',
    title: 'Quản lý nhãn hàng',
    canActivate: [AuthGuard],
    component: OverviewComponent,
    data: {
      hiddenFooter: true,
      isAdmin: true,
    }
  },
  {
    path: 'admin/vouchers-manager',
    title: 'Quản lý phiếu giảm giá',
    canActivate: [AuthGuard],
    component: VoucherManagerComponent,
    data: {
      hiddenFooter: true,
      isAdmin: true,
    }
  },
  {
    path: 'admin/voucher-edit/:id',
    title: 'Chỉnh sửa sản phẩm',
    canActivate: [AuthGuard],
    component: VoucherEditComponent,
    data: {
      hiddenFooter: true,
      isAdmin: true,
    }
  },
  {
    path: 'admin/slider-manager',
    title: 'Quản lý trình chiếu',
    canActivate: [AuthGuard],
    component: SlidesManagerComponent,
    data: {
      hiddenFooter: true,
      isAdmin: true,
    }
  },
  {
    path: 'admin/users-manager',
    title: 'Quản lý người dùng',
    canActivate: [AuthGuard],
    component: OverviewComponent,
    data: {
      hiddenFooter: true,
      isAdmin: true,
    }
  },
  {
    path: 'admin/settings',
    title: 'Cài đặt hệ thống',
    canActivate: [AuthGuard],
    component: OverviewComponent,
    data: {
      hiddenFooter: true,
      isAdmin: true,
    }
  },

  { path: '**', pathMatch: 'full',title: 'Not Found', component: NotfoundComponent },
  { path: '404/notfound', title: 'Not Found', component: NotfoundComponent },
];

@Injectable({ providedIn: 'root' })
export class TemplatePageTitleStrategy extends TitleStrategy {
  constructor(private readonly title: Title) {
    super();
  }

  override updateTitle(routerState: RouterStateSnapshot) {
    const title = this.buildTitle(routerState);
    if (title !== undefined) {
      this.title.setTitle(title);
    }
  }
}

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
  exports: [RouterModule],
  providers: [
    { provide: TitleStrategy, useClass: TemplatePageTitleStrategy },
  ]
})
export class AppRoutingModule {
}
