import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-info-card',
  templateUrl: './info-card.component.html',
  styleUrls: ['./info-card.component.css']
})
export class InfoCardComponent implements OnInit {
  @Input() color = ''
  @Input() image = ''
  @Input() firstLetter:any
  @Input() innerContent = ''

  constructor() { }

  ngOnInit(): void {
  }

}
