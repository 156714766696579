<nav id="mainnav" class="mainnav">
  <ul class="menu">
    <li><a>
        <span>{{ 'HEADER.PRODUCT' | translate }}</span>
        <span class="material-icons">chevron_right</span>
      </a>
      <ul class="mega-menu--links submenu" id="mainnav__products" [ngClass]="prodEnv ? 'isProd' : ''">
        <ngx-skeleton-loader *ngIf="categories.length === 0" count="2" [theme]="{
            margin: '8px 8px 0 0',
            width: '1092px',
            height: '110px',
            'border-radius': '1rem'
          }"></ngx-skeleton-loader>
        <li *ngFor="let item of categories">
          <div class="mega-menu__cate--head">
            <img class="mega-menu__cate--thumb" src="../../../assets{{item.icon}}" [alt]="item.name">
            <span class="mega-menu__cate--name fs-6">{{item.name}}</span>
          </div>
          <div class="mega-menu--links-child px-5">
            <ul *ngFor="let product of products">
              <li routerLink="/san-pham/{{product.metaTitle}}" class="menu_item" *ngIf="item.id === product.categoryID">
                <a class="head__menu--link">{{product.name}}</a>
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </li>

    <li><a>
        <span>{{ 'HEADER.COMPARE' | translate }}</span>
        <span class="material-icons">chevron_right</span>
      </a>
      <ul class="submenu">
        <li><a routerLink="/compare">
            <span class="material-icons">compare</span>
            <span class="mega-menu__collapse--label">{{ 'HEADER.COMPARECHILD' | translate }}</span>
          </a></li>
      </ul>
    </li>

    <li><a>
        <span>{{ 'HEADER.NEWS' | translate }}</span>
        <span class="material-icons">chevron_right</span>
      </a>
      <ul class="submenu">
        <li><a routerLink="/news">
            <span class="material-icons">newspaper</span>
            <span class="mega-menu__collapse--label">{{ 'HEADER.NEWSCHILD' | translate }}</span>
          </a></li>
      </ul>
    </li>

    <li><a>
        <span>{{ 'HEADER.POLICY' | translate }}</span>
        <span class="material-icons">chevron_right</span>
      </a>
      <ul class="submenu">
        <li><a routerLink="/introduce">
            <span class="material-icons">info</span>
            <span class="mega-menu__collapse--label">{{ 'HEADER.POLICYCHILD1' | translate }}</span>
          </a></li>
        <li><a routerLink="/terms-of-use">
            <span class="material-icons">info</span>
            <span class="mega-menu__collapse--label">{{ 'HEADER.POLICYCHILD2' | translate }}</span>
          </a></li>
        <li><a routerLink="/privacy-policy">
            <span class="material-icons">info</span>
            <span class="mega-menu__collapse--label">{{ 'HEADER.POLICYCHILD3' | translate }}</span>
          </a></li>
      </ul>
    </li>

    <li><a>
        <span>{{ 'HEADER.CONTACT' | translate }}</span>
        <span class="material-icons">chevron_right</span>
      </a>
      <ul class="submenu">
        <li><a>
            <span class="material-icons">phone_in_talk</span>
            <span class="mega-menu__collapse--label">{{ 'HEADER.CONTACTCHILD1' | translate }}</span>
          </a></li>
        <li><a routerLink="/contact">
            <span class="material-icons ">widgets</span>
            <span class="mega-menu__collapse--label">{{ 'HEADER.CONTACTCHILD2' | translate }}</span>
          </a></li>
      </ul>
    </li>
  </ul>
</nav>