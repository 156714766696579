<br><br>
<app-content-title [label]="'HEADER.POLICYCHILD2'|translate" class="app-block__head"></app-content-title>
<div class="terms">
    <p>{{'TERMS.P1'|translate}}</p>
    <br>
    <p>{{'TERMS.P2'|translate}}</p>
    <br>
    <ul>
        <li>{{'TERMS.LI1'|translate}}</li>
        <li>{{'TERMS.LI2'|translate}}</li>
        <li>{{'TERMS.LI3'|translate}}</li>
    </ul>
    <br>
    <p>{{'TERMS.P3'|translate}}</p>
    <br>
    <b>{{'TERMS.B1'|translate}}</b>
    <br>
    <p>{{'TERMS.P4'|translate}}</p>
    <br>
    <p>{{'TERMS.P5'|translate}}</p>
    <p>{{'TERMS.P6'|translate}}</p>
    <p>{{'TERMS.P7'|translate}}</p>
    <br>
    <b>{{'TERMS.B2'|translate}}</b>
    <br>
    <p>{{'TERMS.P8'|translate}}</p>
    <ul>
        <li>{{'TERMS.LI4'|translate}}</li>
        <li>{{'TERMS.LI5'|translate}}</li>
    </ul>
    <br>
    <b>{{'TERMS.B3'|translate}}</b>
    <br><br>
    <b>{{'TERMS.B4'|translate}}</b>
    <br><br>
    <p>{{'TERMS.P9'|translate}}</p>
    <br>
    <b>{{'TERMS.B5'|translate}}</b>
    <br><br>
    <b>{{'TERMS.B6'|translate}}</b>
    <br><br>
    <p>{{'TERMS.P10'|translate}}</p>
    <br>
    <ul>
        <li>{{'TERMS.LI6'|translate}}</li>
        <li>{{'TERMS.LI7'|translate}}</li>
        <li>{{'TERMS.LI8'|translate}}</li>
    </ul>
    <br>
    <b>{{'TERMS.B7'|translate}}</b>
    <br><br>
    <p>{{'TERMS.P11'|translate}}</p>
    <br><br>
    <b>{{'TERMS.B8'|translate}}</b>
    <br><br>
    <p>{{'TERMS.P12'|translate}}</p>
    <br>
    <p>{{'TERMS.P13'|translate}}</p>
    <br>
    <b>{{'TERMS.B9'|translate}}</b>
    <br><br>
    <p>{{'TERMS.P14'|translate}}</p>
    <br>
    <b>{{'TERMS.B10'|translate}}</b>
    <br><br>
    <p>{{'TERMS.P15'|translate}}</p>
    <br>
    <b>{{'TERMS.B11'|translate}}</b>
    <br><br>
    <p>{{'TERMS.P16'|translate}}</p>
    <br>
    <b>{{'TERMS.B12'|translate}}</b>
    <br><br>
    <p>{{'TERMS.P17'|translate}}</p>
    <br><br>
    <b>{{'TERMS.B14'|translate}}</b>
    <br><br>
    <p>{{'TERMS.P18'|translate}}</p>
    <ul>
        <li>{{'TERMS.LI9'|translate}}</li>
        <li>{{'TERMS.LI10'|translate}}</li>
        <li>{{'TERMS.LI11'|translate}}</li>
        <li>{{'TERMS.LI12'|translate}}</li>
    </ul>
    <p>{{'TERMS.P19'|translate}}</p>
    <br>
    <b>{{'TERMS.B15'|translate}}</b>
    <br><br>
    <ul style="list-style: none;">
        <li>{{'TERMS.LI13'|translate}}</li>
        <br>
        <li>{{'TERMS.LI14'|translate}}</li>
        <br>
        <li>{{'TERMS.LI15'|translate}}</li>
        <br>
        <li>{{'TERMS.LI16'|translate}}</li>
    </ul>
    <br>
</div>