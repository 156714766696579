import { ApiService } from '@services/api.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ITProduct } from '@models/product.model';
import { PRODUCT_TEMPLATE } from 'src/utils/product';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css']
})
export class PaymentComponent implements OnInit {
  public isWebview:boolean = false
  public href:string = ''
  public meta:string = ''
  public product:ITProduct = {
    ...PRODUCT_TEMPLATE
  }

  constructor(
    private ApiService:ApiService,
    private router: Router,
    private _meta: Meta,
  ) { }

  ngOnInit(): void {
    this.getParams()
    this.getRoute(this.meta)
    localStorage.removeItem('form-data')
  }

  getParams() {
    this.href = this.router.url;
    this.href.includes('/webview/') ? this.isWebview = true : this.isWebview = false
    this.href.includes('&') ? this.meta = this.href.slice(9, -10) : this.meta = this.href.slice(9)
  }

  getRoute(meta:string){
    this.ApiService.pickProduct(meta, 'vi').subscribe((res:any)=>{
      this.product = res
      this._meta.updateTag({ property: 'og:image', content: res.icon })
    });
  }
}
