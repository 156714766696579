import { Component, Input, OnInit } from '@angular/core'
import { ITCategory } from '@models/category.model'
import { ITProduct } from '@models/product.model'
import { ProtectService } from '@services/protect/protect.service'

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css'],
})
export class FooterComponent implements OnInit {
  @Input() categories: ITCategory[] = []
  @Input() products: ITProduct[] = []
  @Input() hiddenFooter: boolean = false
  @Input() hiddenTopFooter: boolean = false
  isVie: boolean = true

  constructor(
    private _protect: ProtectService,
  ) {
    this.isVie = this._protect.getLanguage() === 'vietnamese'
  }

  ngOnInit() {}
}
