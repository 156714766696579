<div class="admin-body">
  <div class="row page-titles mx-0">
    <div class="col p-md-0">
      <ol class="breadcrumb">
        <li class="breadcrumb-item" routerLink="/collaborators/managerF1">Quản lý</li>
        <li class="breadcrumb-item active">Quản lý Cộng tác viên</li>
      </ol>
    </div>
  </div>

  <div class="admin__container">
    <div class="flex space">
      <span class="admin__container-head">Danh sách Cộng tác viên</span>
      <button type="button" class="btn btn-success btn-sm" (click)="openModal(addModal)">Thêm mới</button>
      <!-- <div class="flex space">
          <div>
            <span>Tìm theo usercode: </span>
            <input type="text" id="user" name="user" [(ngModel)]="user" #_user="ngModel"
              (blur)="getAccs(1,10,trangthai,user)">
          </div>
          <div>
            <span>Tìm theo trạng thái: </span>
            <input type="text" id="trangthai" name="trangthai" [(ngModel)]="trangthai" #_trangthai="ngModel"
              (blur)="getAccs(1,10,trangthai,user)">
          </div>
        </div> -->
    </div>

    <table class="table table-striped">
      <thead>
        <tr>
          <th scope="col">Mã CTV</th>
          <th scope="col">Họ tên</th>
          <th scope="col">CMND/CCCD</th>
          <th scope="col">Số điện thoại</th>
          <th scope="col">Email</th>
          <th scope="col">Trạng thái</th>
          <th scope="col">Chức năng</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let cont of cols; index as i">
          <th scope="row">{{ cont.ma_nv }}</th>
          <th scope="row" class="start">{{ cont.ho_ten}}</th>
          <td class="start">{{ cont.cmnd }}</td>
          <td class="start">{{ cont.so_dien_thoai }}</td>
          <td class="start">{{ cont.email }}</td>
          <td>{{ transfromStatus(cont.k_status) }}</td>
          <td>
            <span class="material-icons" title="Xem"
              (click)="dialogHandler('view', cont.ma_nv, dialog);">visibility</span>
            <!-- <span class="material-icons" title="Sửa" (click)="dialogHandler('edit', cont, dialog);"
              *ngIf="cont.trangthai !== 'Hủy hợp đồng'">edit</span> -->
          </td>
        </tr>
      </tbody>
    </table>

    <div class="flex space">
      <div class="">
        <!-- <span>Hiển thị</span>
            <select name="pageSize" id="pageSize" [value]="pageSize" [(ngModel)]="pageSize"
              (ngModelChange)="getContractPages(); getContracts()">
              <option value=10>10</option>
              <option value=15>15</option>
              <option value=20>20</option>
              <option value=25>25</option>
            </select>
            <label for="pageSize">hợp đồng.</label> -->
      </div>

      <ngb-pagination *ngIf="true" [collectionSize]="total" [(page)]="page" [maxSize]="pageSize" [rotate]="true"
        [boundaryLinks]="true" (pageChange)="getUserList(ma_nv_login,page,pageSize)"></ngb-pagination>
      <ngb-pagination *ngIf="false" [collectionSize]="total" [(page)]="page" [maxSize]="pageSize" [rotate]="true"
        [boundaryLinks]="true" (pageChange)="getUserList(ma_nv_login,page,pageSize)"></ngb-pagination>
    </div>

    <ngx-skeleton-loader *ngIf="cols.length < 1" count="9" [theme]="{
            margin: '4px 0',
            width: '100%',
            height: '54px',
            'border-radius': '8px'
          }"></ngx-skeleton-loader>
  </div>

</div>

<ng-template #dialog let-modal>
  <div class="modal-header px-4">
    <h4 class="modal-title m-0 fs-4">Thông tin Cộng tác viên</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <ngb-accordion #acc="ngbAccordion" activeIds="main-infomations">
      <ngb-panel title="Thông tin cơ bản" id="main-infomations">
        <ng-template ngbPanelContent>
          <form class="dialog" [formGroup]="form">
            <div class="text-field">
              <label for="ma_nv">Mã CTV<span class="required">*</span></label>
              <input readonly required type="text" id="ma_nv" name="ma_nv" formControlName="ma_nv" />
              <!-- <div class="invalid">
                  <small
                    *ngIf="form.controls['id'].touched && form.controls['id'].hasError('required')">{{error.required}}</small>
                </div> -->
            </div>

            <div class="text-field">
              <label for="ho_ten">Họ và tên<span class="required">*</span></label>
              <input readonly required type="text" id="ho_ten" name="ho_ten" formControlName="ho_ten" />
              <!-- <div class="invalid">
                  <small
                    *ngIf="form.controls['ho_ten'].touched && form.controls['ho_ten'].hasError('required')">{{error.required}}</small>
                </div> -->
            </div>

            <div class="text-field">
              <label for="so_dien_thoai">Số điện thoại<span class="required">*</span></label>
              <input readonly required type="text" id="so_dien_thoai" name="so_dien_thoai"
                formControlName="so_dien_thoai" />
              <!-- <div class="invalid">
                  <small
                    *ngIf="form.controls['so_dien_thoai'].touched && form.controls['so_dien_thoai'].hasError('required')">{{error.required}}</small>
                </div> -->
            </div>

            <div class="text-field">
              <label for="cmnd">Căn cước công dân<span class="required">*</span></label>
              <input readonly required type="text" id="cmnd" name="cmnd" formControlName="cmnd" />
              <!-- <div class="invalid">
                  <small
                    *ngIf="form.controls['cmnd'].touched && form.controls['cmnd'].hasError('required')">{{error.required}}</small>
                </div> -->
            </div>

            <div class="text-field">
              <label for="email">Email<span class="required">*</span></label>
              <input readonly required type="text" id="email" name="email" formControlName="email" />
              <!-- <div class="invalid">
                  <small
                    *ngIf="form.controls['email'].touched && form.controls['email'].hasError('required')">{{error.required}}</small>
                  <small *ngIf="form.controls['email']?.errors?.['email']">{{error.email}}</small>
                </div> -->
            </div>

            <div class="text-field">
              <label for="k_status">Trạng thái<span class="required">*</span></label>
              <select required name="k_status" id="k_status" formControlName="k_status" *ngIf="!disableInput">
                <option *ngFor="let opt of statusSeletor" [value]="opt.value">{{opt.label}}</option>
              </select>
              <input readonly type="text" id="k_status" name="k_status" formControlName="k_status"
                *ngIf="disableInput" />
              <div class="invalid">
                <small
                  *ngIf="form.controls['k_status'].touched && form.controls['k_status'].hasError('required')">{{error.select|translate}}</small>
              </div>
            </div>


          </form>
        </ng-template>
      </ngb-panel>

      <!-- <ngb-panel title="Thông tin thêm">
          <ng-template ngbPanelContent>
            <div class="dialog">
              <div class="text-field">
                <label for="hd_id">Mã hợp đồng</label>
                <input type="text" id="hd_id" name="hd_id" readonly [value]="contract.id" />
              </div>
  
              <div class="text-field">
                <label for="magiaodich">Mã giao dịch</label>
                <input type="text" id="magiaodich" name="magiaodich" readonly [value]="contract.magiaodich" />
              </div>
  
              <div class="text-field">
                <label for="mahoadon">Mã hóa đơn</label>
                <input type="text" id="mahoadon" name="mahoadon" readonly [value]="contract.mahoadon" />
              </div>
  
              <div class="text-field">
                <label for="ngaytao">Ngày mua</label>
                <input type="datetime-local" id="ngaytao" name="ngaytao" readonly [value]="contract.ngaytao" />
              </div>
  
              <div class="text-field">
                <label for="thoigianthanhtoan">Thời điểm thanh toán</label>
                <input type="datetime-local" id="thoigianthanhtoan" name="thoigianthanhtoan" readonly
                  [value]="contract.thoigianthanhtoan" />
              </div>
  
              <div class="text-field">
                <label for="trangthaithanhtoan">Trạng thái thanh toán</label>
                <input type="text" id="trangthaithanhtoan" name="trangthaithanhtoan" readonly
                  [value]="contract.trangthaithanhtoan" />
              </div>
  
            </div>
            <div *ngIf="personsList[1]">
              <h4>Danh sách người cùng tham gia:</h4>
              <div class="protector-list-container">
                <div class="protector-list">
                  <table>
                    <tr>
                      <th>{{'PRODUCT.POSITION'|translate}}</th>
                      <th>{{'PRODUCT.RELATIONSHIP'|translate}}</th>
                      <th>{{'COMMON.FULL_NAME'|translate}}</th>
                      <th>{{'COMMON.IDENTIFY_CARD'|translate}}</th>
                      <th>{{'COMMON.BIRTHDAY'|translate}}</th>
                      <th>{{'COMMON.GENDER'|translate}}</th>
                    </tr>
                    <tr *ngFor="let person of personsList; let i = index">
                      <td *ngIf="i>0">{{i}}</td>
                      <td *ngIf="i>0">{{person.dS_MOI_QUAN_HE}}</td>
                      <td *ngIf="i>0">{{person.dS_TEN}}</td>
                      <td *ngIf="i>0">{{person.dS_CMT}}</td>
                      <td *ngIf="i>0">{{person.dS_NG_SINH}}</td>
                      <td *ngIf="i>0">{{person.dS_GIOI}}</td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
            <div *ngIf="personP[0].moiquanheP">
              <hr>
              <h4>Thông tin người được bảo hiểm:</h4>
              <div class="protector-list-container">
                <div class="protector-list">
                  <table>
                    <tr>
                      <th>{{'PRODUCT.RELATIONSHIP'|translate}}</th>
                      <th>{{'COMMON.FULL_NAME'|translate}}</th>
                      <th>{{'COMMON.IDENTIFY_CARD'|translate}}</th>
                      <th>{{'COMMON.BIRTHDAY'|translate}}</th>
                      <th>{{'COMMON.GENDER'|translate}}</th>
                      <th>Nằm viện</th>
                      <th>Ngoại trú</th>
                      <th>Thai sản</th>
                    </tr>
                    <tr>
                      <td>{{personP[0].moiquanheP|translate}}</td>
                      <td>{{personP[0].tenP}}</td>
                      <td>{{personP[0].cmtP}}</td>
                      <td>{{personP[0].nG_SINHP}}</td>
                      <td>{{personP[0].gioiP}}</td>
                      <td>{{personP[0].trocapnamviendotainan?'Có':'Không'}}</td>
                      <td>{{personP[0].ngoaitru?'Có':'Không'}}</td>
                      <td>{{personP[0].thaisan?'Có':'Không'}}</td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </ng-template>
        </ngb-panel>
  
        <ngb-panel title="Thông tin xe" *ngIf="isBSHCar">
          <ng-template ngbPanelContent>
            <div class="dialog">
              <div class="text-field">
                <label for="bien_xe">Biển xe</label>
                <input type="text" id="bien_xe" name="bien_xe" readonly [value]="BSHCar.bien_xe" />
              </div>
  
              <div class="text-field">
                <label for="so_may">Số máy</label>
                <input type="text" id="so_may" name="so_may" readonly [value]="BSHCar.so_may" />
              </div>
  
              <div class="text-field">
                <label for="so_khung">Số khung</label>
                <input type="text" id="so_khung" name="so_khung" readonly [value]="BSHCar.so_khung" />
              </div>
  
              <div class="text-field">
                <label for="nguon_goc">Nguồn gốc</label>
                <input type="text" id="nguon_goc" name="nguon_goc" readonly [value]="BSHCar.nguon_goc" />
              </div>
  
              <div class="text-field">
                <label for="hang_xe">Hãng xe</label>
                <input type="text" id="hang_xe" name="hang_xe" readonly [value]="BSHCar.hang_xe" />
              </div>
  
              <div class="text-field">
                <label for="hieu_xe">Hiệu xe</label>
                <input type="text" id="hieu_xe" name="hieu_xe" readonly [value]="BSHCar.hieu_xe" />
              </div>
  
              <div class="text-field">
                <label for="nam_sx">Năm sản xuất</label>
                <input type="text" id="nam_sx" name="nam_sx" readonly [value]="BSHCar.nam_sx" />
              </div>
  
              <div class="text-field">
                <label for="so_cn">Số chỗ ngồi</label>
                <input type="text" id="so_cn" name="so_cn" readonly [value]="BSHCar.so_cn" />
              </div>
  
              <div class="text-field">
                <label for="trong_tai">Trọng tải</label>
                <input type="text" id="trong_tai" name="trong_tai" readonly [value]="BSHCar.trong_tai" />
              </div>
  
              <div class="text-field">
                <label for="ngay_hl">Ngày hiệu lực</label>
                <input type="date" id="ngay_hl" name="ngay_hl" readonly [value]="BSHCar.ngay_hl" />
              </div>
  
              <div class="text-field">
                <label for="ngay_kt">Ngày kết thúc</label>
                <input type="date" id="ngay_kt" name="ngay_kt" readonly [value]="BSHCar.ngay_kt" />
              </div>
  
              <div class="text-field">
                <label for="muc_dich_kinh_doanh">Mục đích kinh doanh</label>
                <input type="text" id="muc_dich_kinh_doanh" name="muc_dich_kinh_doanh" readonly
                  [value]="BSHCar.muc_dich_kinh_doanh" />
              </div>
  
              <div class="text-field">
                <label for="muc_dich_su_dung">Mục đích sử dụng</label>
                <input type="text" id="muc_dich_su_dung" name="muc_dich_su_dung" readonly
                  [value]="BSHCar.muc_dich_su_dung" />
              </div>
  
              <div class="text-field">
                <label for="trach_nhiem_dan_su_bat_buoc">Trách nhiệm dân sự bắt buộc</label>
                <input type="text" id="trach_nhiem_dan_su_bat_buoc" name="trach_nhiem_dan_su_bat_buoc" readonly
                  [value]="BSHCar.trach_nhiem_dan_su_bat_buoc" />
              </div>
  
              <div class="text-field">
                <label for="linh_vuc_kinh_doanh">Lĩnh vực kinh doanh</label>
                <input type="text" id="linh_vuc_kinh_doanh" name="linh_vuc_kinh_doanh" readonly
                  [value]="BSHCar.linh_vuc_kinh_doanh" />
              </div>
            </div>
          </ng-template>
        </ngb-panel> -->
    </ngb-accordion>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary flex space" (click)="onSave(); modal.close('Close click')"
      [disabled]="form.invalid" *ngIf="this.dialogType === 'edit'">
      <span class="material-icons text-light">save</span>
      <p class="m-0 px-1">Lưu</p>
    </button>
    <button type="button" class="btn btn-danger flex space" (click)="modal.close('Close click')">
      <span class="material-icons text-light">close</span>
      <p class="m-0 px-1">Đóng</p>
    </button>
  </div>
</ng-template>
<!-- Thêm mới CTV -->
<ng-template #addModal let-modal>
  <div class="modal-header px-4">
    <h4 class="modal-title m-0 fs-4">Thêm Cộng tác viên</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <form [formGroup]="addForm">
      <div class="text-field" style="width: 100%;">
        <label for="ma_nv">Mã CTV<span class="required">*</span></label>
        <input [readonly]="colInfo" required type="text" id="ma_nv" name="ma_nv" formControlName="ma_nv" />
        <div class="invalid">
          <small
            *ngIf="addForm.controls['ma_nv'].touched && addForm.controls['ma_nv'].hasError('required')">{{error.required|translate}}</small>
        </div>
      </div>

    </form>
    <div *ngIf="mess">
      <p>{{mess}}</p>
    </div>
    <div *ngIf="colInfo">
      <p>Họ và tên: <span class="required">{{colInfo.ho_ten}}</span></p>
      <p>CMND/CCCD: <span class="required">{{colInfo.cmnd}}</span></p>
    </div>
  </div>
  <div class="modal-footer">
    <div>
      <button type="button" class="btn btn-primary flex space" (click)="onCheck()" [disabled]="addForm.invalid||isTrue">
        <span class="material-icons text-light">search</span>
        <p class="m-0 px-1">Kiểm tra</p>
      </button>
    </div>
    <button type="button" class="btn btn-primary flex space" (click)="onAdd(); modal.close('Close click')"
      [disabled]="addForm.invalid||!isTrue">
      <span class="material-icons text-light">save</span>
      <p class="m-0 px-1">Thêm</p>
    </button>

  </div>
</ng-template>