<div class="notfound">
  <h1>Cảnh báo</h1>
  <p class="zoom-area"><b>Có gì đó </b> không ổn, vui lòng kiểm tra lại đường dẫn hoặc đường truyền mạng.</p>
  <section class="error-container">
    <span class="four"><span class="screen-reader-text">4</span></span>
    <span class="zero"><span class="screen-reader-text">0</span></span>
    <span class="four"><span class="screen-reader-text">4</span></span>
  </section>
  <div class="link-container">
    <button routerLink="/" class="btn btn-primary">{{ 'COMMON.RETURN_HOME' | translate }}</button>
  </div>
</div>
