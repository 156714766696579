<div>
  <section [ngClass]="hiddenTopFooter ? 'hidden' : 'footer__more-insurance'">
    <div class="container">
      <h3 class="footer__more-insurance--heading"> {{ isVie ? 'Sản phẩm' : 'Products' }} </h3>
      <div class="footer__more-insurance--block">
        <ngx-skeleton-loader *ngIf="categories.length === 0" count="6" [theme]="{
            margin: '8px 12px 0 0',
            width: '272px',
            height: '140px',
            'border-radius': '1rem',
            'background-color': '#2c9cdb'
          }"></ngx-skeleton-loader>
        <ul id="ul-1" class="footer__more-insurance--links" *ngFor="let item of categories">
          <li id="li-1">
            <div class="">
              <img src="../../../assets{{item.icon}}" alt="{{item.name}}">
            </div>
            <div class="">
              <p class="category">{{item.name}}</p>
              <ul id="ul-2" *ngFor="let product of products">
                <li routerLink="/san-pham/{{product.metaTitle}}" id="li-2" class="item"><a class="link"
                    *ngIf="item.id === product.categoryID">{{product.name}}</a></li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </section>

  <!-- <section class="footer__link">

  </section> -->

  <section class="footer__legal">
    <div class="container">
      <div>
        <div class="footer__introduce">
          <h2>
            Công ty Cổ phần Công nghệ Bảo hiểm FINME
          </h2>
          <p>Địa chỉ: Số 117 Trần Duy Hưng, Phường Trung Hòa, Quận Cầu Giấy, Hà Nội, Việt Nam</p>
          <p>Điện thoại: 02888815818</p>
          <p>Email: support@finlife.com.vn</p>
        </div>
        <div class="footer__legal--pay-options">
          <p> {{ 'FOOTER.PAYMENT'|translate }} </p>
          <div class="footer__legal--options">
            <img src="../../../assets/images/home/VnPay.png" alt="VnPay">
            <img src="../../../assets/images/home/MBBank.png" alt="MBBank">
            <img src="../../../assets/images/home/Techcombank.png" alt="Techcombank">
            <img src="../../../assets/images/home/TpBank.png" alt="TpBank">
            <img src="../../../assets/images/home/VPBank.png" alt="VPBank">
            <img src="../../../assets/images/home/VietinBank.png" alt="VietinBank">
          </div>
        </div>
  
  
        <br>
        <p class="footer__legal--info">© Copyright 2021 Finme. All Rights Reserved.</p>
      </div>
      <div>
        <!-- <p> {{ 'FOOTER.CONNECT'|translate }} </p> -->
        <div class="footer__link--item">
          <a href="https://www.facebook.com/finlife.vn" class="">
            <img src="../../../assets/images/facebook.svg" alt="facebook">
          </a>
          <a href="https://www.youtube.com/channel/UCjJHoUH6uBb5wwGP11Ng5JA" class="">
            <img src="../../../assets/images/youtube.svg" alt="youtube">
          </a>
        </div>
        <br>
        <div class="footer__link--item">
          <a routerLink="/terms-of-use" style="margin-right: 5px;color: white;">{{ 'HEADER.POLICYCHILD2' | translate
            }}</a>
          <a routerLink="/privacy-policy" style="margin-left: 5px;color: white;">{{ 'HEADER.POLICYCHILD3' | translate
            }}</a>
        </div>
      </div>
    </div>
    
  </section>
</div>