<div class="admin-body">
    <div class="row page-titles mx-0">
      <div class="col p-md-0">
        <ol class="breadcrumb">
          <li class="breadcrumb-item" routerLink="admin/dashboard">Quản lý</li>
          <li class="breadcrumb-item active">Hồ sơ Đăng kí vật chất xe</li>
        </ol>
      </div>
    </div>
  
    <div class="admin__container">
      <div class="flex space">
        <span class="admin__container-head">Danh sách Đăng kí vật chất xe</span>
        <div class="flex space">
          <!-- <div>
            <span>Tìm theo usercode: </span>
            <input type="text" id="user" name="user" [(ngModel)]="user" #_user="ngModel"
              (blur)="getMaterialList(1,10,trangthai,user)">
          </div> -->
          <div>
            <span>Tìm theo trạng thái: </span>
            <input type="text" id="trangthai" name="trangthai" [(ngModel)]="trangthai" #_trangthai="ngModel"
              (blur)="getMaterialList(1,10,trangthai,user)">
          </div>
        </div>
      </div>
  
      <!-- <form class="filter" [formGroup]="filter" *ngIf="!prodEnv">
          <div class="text-field">
            <label for="idProduct">Sản phẩm</label>
            <select name="idProduct" id="idProduct" formControlName="idProduct" formControlName="idProduct" (selectionchange)="getContracts()">
              <option value="" selected disabled>Chọn sản phẩm</option>
              <option *ngFor="let opt of products" [value]="opt.name">{{opt.name}}</option>
            </select>
          </div>
    
          <div class="text-field">
            <label for="idCategory">Danh mục</label>
            <select name="idCategory" id="idCategory" formControlName="idCategory" formControlName="idCategory" (selectionchange)="getContracts()">
              <option value="" selected disabled>Chọn danh mục</option>
              <option *ngFor="let opt of categories" [value]="opt.name">{{opt.name}}</option>
            </select>
          </div>
    
          <div class="text-field">
            <label for="fromDate">Từ ngày</label>
            <input type="date" id="fromDate" name="fromDate" (change)="getContracts()" formControlName="fromDate" formControlName="fromDate" />
          </div>
    
          <div class="text-field">
            <label for="toDate">Đến ngày</label>
            <input type="date" id="toDate" name="toDate" formControlName="toDate" formControlName="toDate" (change)="getContracts()" />
          </div>
    
          <div class="text-field">
            <label for="keyword">Từ khóa</label>
            <input type="text" id="keyword" name="keyword" formControlName="keyword" formControlName="keyword" placeholder="Nhập tên khách hàng" (blur)="getContracts()" />
          </div>
    
          <div class="text-field">
            <label for="status">Trạng thái</label>
            <select name="status" id="status" formControlName="status" formControlName="status" (selectionchange)="getContracts()">
              <option *ngFor="let opt of statusSeletor" [value]="opt.value">{{opt.label}}</option>
            </select>
          </div>
        </form> -->
  
      <table class="table table-striped">
        <thead>
          <tr>
            <th scope="col" sortable="id" (sort)="onSort()">ID</th>
            <th scope="col" class="start">Họ tên</th>
            <th scope="col">Số điện thoại</th>
            <th scope="col" sortable="loaibaohiem" (sort)="onSort()">Kinh doanh</th>
            <th scope="col" sortable="trangthai" (sort)="onSort()">Trạng thái</th>
            <th scope="col">Chức năng</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let cont of materialList; index as i">
            <th scope="row">{{ cont.id }}</th>
            <td class="start">{{ cont.ten_khach_hang }}</td>
            <td>{{ cont.so_dien_thoai }}</td>
            <td>{{ cont.xe_kinh_doanh }}</td>
            <td>{{ cont.trang_thai }}</td>
            <td>
              <span class="material-icons" title="Xem"
                (click)="dialogHandler('view', cont, dialog); getMaterialByID(cont.id)">visibility</span>
              <span class="material-icons" title="Sửa"
                (click)="dialogHandler('edit', cont, dialog); getMaterialByID(cont.id)"
                *ngIf="cont.trangthai !== 'Hủy hợp đồng'">edit</span>
            </td>
          </tr>
        </tbody>
      </table>
  
      <div class="flex space">
        <div class="">
          <!-- <span>Hiển thị</span>
            <select name="pageSize" id="pageSize" [value]="pageSize" [(ngModel)]="pageSize"
              (ngModelChange)="getContractPages(); getContracts()">
              <option value=10>10</option>
              <option value=15>15</option>
              <option value=20>20</option>
              <option value=25>25</option>
            </select>
            <label for="pageSize">hợp đồng.</label> -->
        </div>
  
        <ngb-pagination *ngIf="!user&&!trangthai" [collectionSize]="total" [(page)]="page" [maxSize]="pageSize"
          [rotate]="true" [boundaryLinks]="true" (pageChange)="getMaterialList(page,pageSize,'','')"></ngb-pagination>
        <ngb-pagination *ngIf="user||trangthai" [collectionSize]="total" [(page)]="page" [maxSize]="pageSize"
          [rotate]="true" [boundaryLinks]="true" (pageChange)="getMaterialList(page,pageSize,trangthai,user)"></ngb-pagination>
      </div>
  
      <ngx-skeleton-loader *ngIf="materialList.length < 1" count="9" [theme]="{
            margin: '4px 0',
            width: '100%',
            height: '54px',
            'border-radius': '8px'
          }"></ngx-skeleton-loader>
    </div>
  
  </div>
  
  <ng-template #dialog let-modal>
    <div class="modal-header px-4">
      <h4 class="modal-title m-0 fs-4">{{ dialogType === 'view' ? 'Chi tiết' : 'Cập nhật' }} thông tin hợp đồng</h4>
      <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
      <ngb-accordion #acc="ngbAccordion" activeIds="main-infomations">
        <ngb-panel title="Thông tin cơ bản" id="main-infomations">
          <ng-template ngbPanelContent>
            <form class="dialog" [formGroup]="form">
              <div class="text-field">
                <label for="id">ID<span class="required">*</span></label>
                <input readonly required type="text" id="id" name="id" formControlName="id" />
                <!-- <div class="invalid">
                  <small
                    *ngIf="form.controls['id'].touched && form.controls['id'].hasError('required')">{{error.required}}</small>
                </div> -->
              </div>
  
              <div class="text-field">
                <label for="ho_ten">Họ và tên<span class="required">*</span></label>
                <input readonly required type="text" id="ho_ten" name="ho_ten" formControlName="ho_ten" />
                <!-- <div class="invalid">
                  <small
                    *ngIf="form.controls['ho_ten'].touched && form.controls['ho_ten'].hasError('required')">{{error.required}}</small>
                </div> -->
              </div>
  
              <div class="text-field">
                <label for="so_dien_thoai">Số điện thoại<span class="required">*</span></label>
                <input readonly required type="text" id="so_dien_thoai"
                  name="so_dien_thoai" formControlName="so_dien_thoai" />
                <!-- <div class="invalid">
                  <small
                    *ngIf="form.controls['so_dien_thoai'].touched && form.controls['so_dien_thoai'].hasError('required')">{{error.required}}</small>
                </div> -->
              </div>
  
              <div class="text-field">
                <label for="loai_xe">Loại xe<span class="required">*</span></label>
                <input readonly required type="text" id="loai_xe" name="loai_xe"
                  formControlName="loai_xe" />
                <!-- <div class="invalid">
                  <small
                    *ngIf="form.controls['loai_xe'].touched && form.controls['loai_xe'].hasError('required')">{{error.required}}</small>
                </div> -->
              </div>

              <div class="text-field">
                <label for="xe_kinh_doanh">Kinh doanh<span class="required">*</span></label>
                <input readonly required type="text" id="xe_kinh_doanh" name="xe_kinh_doanh"
                  formControlName="xe_kinh_doanh" />
                <!-- <div class="invalid">
                  <small
                    *ngIf="form.controls['xe_kinh_doanh'].touched && form.controls['xe_kinh_doanh'].hasError('required')">{{error.required}}</small>
                </div> -->
              </div>
  
              <div class="text-field">
                <label for="so_cho_ngoi">Số chỗ ngồi<span class="required">*</span></label>
                <input readonly type="text" id="so_cho_ngoi" name="so_cho_ngoi"
                  formControlName="so_cho_ngoi" />
                <!-- <div class="invalid">
                  <small
                    *ngIf="form.controls['so_cho_ngoi'].touched && form.controls['so_cho_ngoi'].hasError('required')">{{error.required}}</small>
                </div> -->
              </div>
  
              <div class="text-field">
                <label for="trang_thai">Trạng thái<span class="required">*</span></label>
                <select required name="trang_thai" id="trang_thai" formControlName="trang_thai" *ngIf="!disableInput">
                  <option *ngFor="let opt of statusSeletor" [value]="opt.value">{{opt.label}}</option>
                </select>
                <input readonly type="text" id="trang_thai" name="trang_thai" formControlName="trang_thai"
                  *ngIf="disableInput" />
                <div class="invalid" *ngIf="dialogType !== 'view'">
                  <small
                    *ngIf="form.controls['trang_thai'].touched && form.controls['trang_thai'].hasError('required')">{{error.select|translate}}</small>
                </div>
              </div>
  
              <div class="text-field">
                <label for="gia_xe">Giá xe<span class="required">*</span></label>
                <input readonly required type="text" id="gia_xe" name="gia_xe" formControlName="gia_xe" />
                <!-- <div class="invalid">
                  <small
                    *ngIf="form.controls['gia_xe'].touched && form.controls['gia_xe'].hasError('required')">{{error.required}}</small>
                </div> -->
              </div>
  
              <div class="text-field">
                <label for="noi_san_xuat">Nơi sản xuất<span class="required">*</span></label>
                <input readonly type="text" id="noi_san_xuat" name="noi_san_xuat" formControlName="noi_san_xuat" />
                <!-- <div class="invalid">
                  <small
                    *ngIf="form.controls['noi_san_xuat'].touched && form.controls['noi_san_xuat'].hasError('required')">{{error.required}}</small>
                </div> -->
              </div>
  
              <div class="text-field">
                <label for="tu_ngay">Ngày sản xuất/ đăng kí xe<span class="required">*</span></label>
                <input readonly required type="text" id="tu_ngay" name="tu_ngay"
                  formControlName="tu_ngay" />
                <!-- <div class="invalid">
                  <small
                    *ngIf="form.controls['tu_ngay'].touched && form.controls['tu_ngay'].hasError('required')">{{error.required}}</small>
                </div> -->
              </div>
  
              
  
            </form>
          </ng-template>
        </ngb-panel>
  
      </ngb-accordion>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary flex space" (click)="onSave(); modal.close('Close click')"
        [disabled]="form.invalid" *ngIf="this.dialogType === 'edit'">
        <span class="material-icons text-light">save</span>
        <p class="m-0 px-1">Lưu</p>
      </button>
      <button type="button" class="btn btn-danger flex space" (click)="modal.close('Close click')">
        <span class="material-icons text-light">close</span>
        <p class="m-0 px-1">Đóng</p>
      </button>
    </div>
  </ng-template>