import { Component, OnInit } from '@angular/core';
import { ActivatedRoute} from '@angular/router';
import { ApiService } from '@services/api.service';

@Component({
  selector: 'app-acctive-account',
  templateUrl: './acctive-account.component.html',
  styleUrls: ['./acctive-account.component.css']
})
export class AcctiveAccountComponent implements OnInit {
  key:any='';
  isActive:boolean=false;
  constructor(
    private _route: ActivatedRoute,
    private _api: ApiService
  ) { }

  ngOnInit(): void {
      this._route.queryParams.subscribe((params) => {
        this.key = params['keyactive']
      })
      if(this.key){
        this._api.postActiveAccount(this.key).subscribe((data)=>{
          if(data.message=='Active tài khoản thành công!'){
            this.isActive=true;
            setTimeout(this.goToDownload, 5000)
          }else{
              window.location.href ='/404/notfound'
          }
        })
      }
      else{
        window.location.href ='/404/notfound'
      }
  }
  goToDownload(){
    window.location.href ='/download'
  }
}
