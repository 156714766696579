
export const COMPARE_DEMAND = [
  // { id: 1, value: 1, label: 'Nhu cầu tài chính'},
  { id: 1, value: 1, label: 'COMPARE.DEMAND1' },
  { id: 2, value: 2, label: 'COMPARE.DEMAND2' },
  { id: 3, value: 3, label: 'COMPARE.DEMAND3' },
  { id: 4, value: 4, label: 'COMPARE.DEMAND4' },
  { id: 5, value: 5, label: 'COMPARE.DEMAND5' },
]

export const COMPARE_AGE_RANGE = [
  // { id: 0, value: '', label: ''},
  { id: 1, value: 'below 0 year', label: 'COMPARE.AGE1' },
  { id: 2, value: 'from 0 to 18', label: 'COMPARE.AGE2' },
  { id: 3, value: 'from 18 to 65', label: 'COMPARE.AGE3' },
]

export const COMPARE_POLICY = [
  { item_id: 1, item_text: 'COMPARE.POLICY1' },
  { item_id: 2, item_text: 'COMPARE.POLICY2' },
  { item_id: 3, item_text: 'COMPARE.POLICY3' },
  { item_id: 4, item_text: 'COMPARE.POLICY4' },
  { item_id: 5, item_text: 'COMPARE.POLICY5' },
  { item_id: 6, item_text: 'COMPARE.POLICY6' },
]

export const COMPARE_COMPANY = [
  { item_id: 1, item_text: 'Bảo hiểm Quân đội MIC' },
  { item_id: 2, item_text: 'Bảo hiểm VietinBank' },
  { item_id: 3, item_text: 'Bảo hiểm Bưu điện PTI' },
]

export const COMPARE_PROMISE = [
  {
    id: 1,
    image: 'lowest-price',
    title: 'COMPARE.PROMISE1',
    description: 'COMPARE.DESC1',
  },
  {
    id: 2,
    position: 'unbiased-advice',
    title: 'COMPARE.PROMISE2',
    description: 'COMPARE.DESC2',
  },
  {
    id: 3,
    position: 'reliable',
    title: 'COMPARE.PROMISE3',
    description: 'COMPARE.DESC2',
  },
]

export const COMPARE_SLIDE = [
  { id: 1, image: '../../../assets/images/bao-hiem-HealthCare.jpeg' },
  { id: 2, image: '../../../assets/images/KTTD.jpeg' },
  { id: 3, image: '../../../assets/images/bao-hiem-HealthCare.jpeg' },
  { id: 4, image: '../../../assets/images/KTTD.jpeg' },
  { id: 5, image: '../../../assets/images/bao-hiem-HealthCare.jpeg' },
]

export const COMPARE_CONTENTS = [
  {
    id: 1,
    type: 'Sức khỏe',
    content: `<h1>Định nghĩa về Bảo hiểm Y tế</h1>
  <p>Định nghĩa về bảo hiểm sức khỏe là một sản phẩm bảo hiểm bao gồm các chi phí y tế và chăm sóc y tế khác nếu
    người được bảo hiểm bị ốm. Chi phí y tế hoặc quyền lợi bảo hiểm y tế nói chung là nội trú, ngoại trú và phẫu
    thuật. Hiện tại, hầu hết bảo hiểm y tế cung cấp quyền lợi Điều phối Y tế (CoB) của BPJS. Điều này có nghĩa là
    BPJS Kesehatan sẽ đảm bảo chi phí bệnh viện theo mức theo chương trình JKN, trong khi phần chênh lệch có thể
    được bảo hiểm y tế tư nhân chi trả.
    Mua bảo hiểm sức khỏe trực tuyến với CoB BPJS Quyền lợi sức khỏe tại Lifepal!
  </p>
  <h2>Quyền lợi Bảo hiểm Y tế</h2>
  <p>Sau khi biết bảo hiểm y tế hoạt động như thế nào và ý nghĩa của nó, điều quan trọng là bạn phải biết những
    lợi ích của nó. Quyền lợi bảo hiểm y tế là bảo hiểm cho các chi phí y tế hoặc chăm sóc sức khỏe tại bệnh viện
    hoặc các dịch vụ y tế khác. Quyền lợi được chia thành hai loại, đó là quyền lợi cơ bản và quyền lợi bổ sung
    (người cầm lái) . </p>
  <h2>1. Lợi ích cơ bản</h2>
  <p>Quyền lợi bảo hiểm y tế cơ bản là bảo hiểm chính. Điều này có nghĩa là khi đăng ký hợp đồng bảo hiểm, công ty
    bảo hiểm sẽ tự động chi trả các chi phí điều trị sau:</p>
  <ul>
    <li><strong>Nằm viện:</strong> Chi phí phòng bệnh, thăm khám bác sĩ, thuốc men và các chi phí khác liên quan
      đến
      việc nằm viện.</li>
    <li><strong>Phẫu thuật:</strong> Chi phí của các thủ tục phẫu thuật, chẳng hạn như phẫu thuật cắt bỏ, cắt cụt
      hoặc
      tái tạo. Chính
      sách bảo hiểm y tế cũng bao gồm chi phí phòng mổ, bác sĩ phẫu thuật và bác sĩ gây mê.</li>
    <li><strong>Ngoại trú:</strong>Chi phí cho các thủ thuật y tế không phải nằm viện. Có thể là tiền bệnh viện
      hoặc
      sau bệnh viện
      cũng như chẩn đoán và điều trị ngoại trú.</li>
  </ul>
  <h2>2. Lợi ích bổ sung</h2>
  <p>Các lợi ích bổ sung hoặc được gọi là người láilà một phần mở rộng phạm vi bảo hiểm y tế. Đó là, để có được
    một người láiKhách hàng này sẽ phải trả một khoản phí bổ sung.</p>
  <br>
  <p>Ví dụ: nếu bạn đã nhận được các quyền lợi bảo hiểm y tế cơ bản dưới hình thức nội trú, ngoại trú và phẫu
    thuật, thì khách hàng muốn nhận thêm các quyền lợi khác như khám sức khỏe (MCU), bảo hiểm thai sản , bảo hiểm
    sức khỏe bao gồm chăm sóc răng miệng, và chăm sóc mắt.</p>
  <ul>
    <li><strong>Kiểm tra sức khỏe :</strong> Thông thường, phí MCU chỉ được thanh toán khi bắt đầu đăng ký bảo
      hiểm nhằm đo lường rủi ro sức khỏe của khách hàng tương lai.
    </li>
    <li><strong>Bảo hiểm thai sản:</strong> Chi phí sinh con, bắt đầu từ khi khám , mổ lấy thai hoặc sinh thường
      và chăm sóc sau sinh.
    </li>
    <li><strong>Chăm sóc răng miệng:</strong> Chi phí bảo hiểm y tế chi trả cho việc chăm sóc răng miệng, bao gồm
      khám, làm sạch, trám răng hoặc nhổ răng.</li>
    <li><strong>Chăm sóc mắt:</strong> Chi phí chăm sóc sức khỏe mắt, bắt đầu từ việc khám và bao gồm cả kính.
    </li>
  </ul>
  <p>Một số công ty bảo hiểm cung cấp sự lựa chọn các chính sách với các quyền lợi từ cơ bản đến bổ sung (người
    lái) hoàn chỉnh với phí bảo hiểm thấp.</p>`,
    enContent: ``
  },
]

export const COMPARE_FILTER = [
  { id: 1, icon: 'home_work', label: 'COMPARE.FILTER1' },
  { id: 2, icon: 'sort', label: 'COMPARE.FILTER2' },
  { id: 3, icon: 'filter_alt', label: 'COMPARE.FILTER3' },
]

export const COMPARE_ORDER = [
  { id: 0, value: '', label: 'COMPARE.ORDER1' },
  { id: 1, value: 'asc', label: 'COMPARE.ORDER2' },
  { id: 2, value: 'desc', label: 'COMPARE.ORDER3' },
  // { id: 3, value: 'hot', label: 'COMPARE.ORDER4' },
]
