<div class="login-block">
  <p class="login-block__head">Đăng nhập</p>
  <p class="tiny-nav">
    <span class="material-icons expand_more">expand_more</span>
    Dịch vụ cá nhân
  </p>
  <p class="tiny-nav">
    <span class="material-icons expand_more">expand_more</span>
    Quản lý chính sách
  </p>
  <!-- (click)="offcanvas.dismiss('Cross click')" -->
  <button routerLink="/login" type="button" id="login-block__button" class="btn btn-light"
  >Đăng nhập / Đăng ký</button>
</div>
<div class="menu-block">
  <div class="menu-block__collapse" (click)="productCollapse.toggle()" [attr.aria-expanded]="!isProductCollapsed"
  aria-controls="collapseExample">
    <span>Sản phẩm bảo hiểm</span>
    <span class="material-icons" ngClass="{{isProductCollapsed ? 'back' : 'transform'}}">chevron_right</span>
  </div>
  <div #productCollapse="ngbCollapse" [(ngbCollapse)]="isProductCollapsed">
    <!-- <div routerLink="/introduce" class="menu-block__collapse--child">
      <span class="material-icons icon">info</span>
      <span class="menu__collapse--label">Giới thiệu</span>
    </div> -->
    <div routerLink="/introduce" class="menu-block__collapse--child">
      <span class="material-icons icon">info</span>
      <span class="menu__collapse--label">Giới thiệu</span>
    </div>
  </div>

  <div class="menu-block__collapse" (click)="policyCollapse.toggle()" [attr.aria-expanded]="!isPolicyCollapsed"
  aria-controls="collapseExample">
    <span>Chính sách công ty</span>
    <span class="material-icons" ngClass="{{isPolicyCollapsed ? 'back' : 'transform'}}">chevron_right</span>
  </div>
  <div #policyCollapse="ngbCollapse" [(ngbCollapse)]="isPolicyCollapsed">
    <div routerLink="/introduce" class="menu-block__collapse--child">
      <span class="material-icons icon">info</span>
      <span class="menu__collapse--label">Giới thiệu</span>
    </div>
  </div>

  <div class="menu-block__collapse" (click)="contactCollapse.toggle()" [attr.aria-expanded]="!isContactCollapsed"
  aria-controls="collapseExample">
    <span>Liên hệ</span>
    <span class="material-icons" ngClass="{{isContactCollapsed ? 'back' : 'transform'}}">chevron_right</span>
  </div>
  <div #contactCollapse="ngbCollapse" [(ngbCollapse)]="isContactCollapsed">
    <div class="menu-block__collapse--child">
      <span class="material-icons icon">phone_in_talk</span>
      <span class="menu__collapse--label">Gọi lại</span>
    </div>
    <div routerLink="/contact" class="menu-block__collapse--child">
      <span class="icon material-icons ">widgets</span>
      <span class="menu__collapse--label">Xem thêm</span>
    </div>
  </div>
</div>
