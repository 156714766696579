import { AuthService } from '@services/auth/auth.service';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { ITLogin, ITSession } from '@models/auth.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { TokenService } from '@services/token.service';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.css']
})
export class AuthComponent implements OnInit {
  public login: ITLogin = {
    username: '',
    password: ''
  }
  isLoggedIn = false;
  isLoginFailed = false;
  errorMessage = '';
  role: string = '';
  public session: any

  constructor(
    private _service: AuthService,
    private tokenStorage: TokenService,
    private modalService: NgbModal,
    private _router: Router,
  ) { }

  ngOnInit(): void {
    if (this.tokenStorage.getToken()) {
      this.isLoggedIn = true;
      this.role = this.tokenStorage.getUser().role;
      if(this.role!='ADMIN'){
       window.location.href='/collaborators/managerF1'
        return
      }
      this._router.navigateByUrl('/admin/dashboard')
    }
  }

  onSubmit(f: any, warming: TemplateRef<any>) {
    f.invalid ? this.modalService.open(warming, { centered: true }) : this.onAuth()
  }

  onAuth() {
    this._service.onLogin(this.login).subscribe(
      data => {
        if (data.token) {
          this.tokenStorage.saveToken(data.token);
        this.tokenStorage.saveUser(data);
        this._service.setLoginStatus(true);
        this.role = this.tokenStorage.getUser().role;
        this._service.setLoginRole(this.role);
        this.isLoginFailed = false;
        this.isLoggedIn = true;
        if(this.role!='ADMIN'&&this.isLoggedIn){
          window.location.href = '/collaborators/managerF1'
          return
        }
        window.location.href = '/admin/dashboard'
        }
        else {
          alert ('Thông tin đăng nhập chưa chính xác!');
        }
      }
    );
  }

}
