import { Component, OnInit } from '@angular/core';
import { ReplaySubject } from 'rxjs';

@Component({
  selector: 'app-my-upload-adapter',
  template: ``
})
export class MyUploadAdapterComponent {
  xhr: any;
  loader: any;
  constructor() {
    // The file loader instance to use during the upload.
    // this.loader = loader;
  }

  convertFileToBase64(file: File) {
    const result = new ReplaySubject<string>(1);
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = (event) => {
      const binaryString = reader.result;
      // const base64String = btoa(typeof binaryString === 'string' ? binaryString : String.fromCharCode(...new Uint8Array(binaryString)));
      // result.next(base64String);

    };
    return result;
  }

  // Starts the upload process.
  upload() {
    return this.loader.file
      .then((file: any) => new Promise((resolve, reject) => {
        // this._initRequest();
        // this._initListeners(resolve, reject, file);
        // this._sendRequest(file);
      }));
  }

  // Aborts the upload process.
  abort() {
    if (this.xhr) {
      this.xhr.abort();
    }
  }

  // Initializes the XMLHttpRequest object using the URL passed to the constructor.
  _initRequest() {
    const xhr = this.xhr = new XMLHttpRequest();
    // xhr.open('POST', `${API.PMAT_API}${apiFileCommon.uploadFile}`, true);
    xhr.responseType = 'json';
  }

  // Initializes XMLHttpRequest listeners.
  _initListeners(resolve: any, reject: any, file: any) {
    const xhr = this.xhr;
    const loader = this.loader;
    const genericErrorText = `Couldn't upload file: ${file.name}.`;

    xhr.addEventListener('error', () => reject(genericErrorText));
    xhr.addEventListener('abort', () => reject());
    xhr.addEventListener('load', () => {
      const response = xhr.response;


      if (!response || response.error) {
        return reject(response && response.error ? response.error.message : genericErrorText);
      }
      resolve({
        default: response.url
      });
    });

    if (xhr.upload) {
      xhr.upload.addEventListener('progress', (evt: any) => {
        if (evt.lengthComputable) {
          loader.uploadTotal = evt.total;
          loader.uploaded = evt.loaded;
        }
      });
    }
  }

  _sendRequest(file: any): void {
    // Prepare the form data.
    this.convertFileToBase64(file).subscribe(res => {
      // const data = new FormData();

      // data.append('upload', res);
      // console.log(res)

      // this.xhr.send(data);
    })
  }
}
