import { data } from 'jquery';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { ITArea, ITDistrict } from '@models/area.model';
import { ITFee } from '@models/fee.model';
import { catchError, Observable, of, switchMap } from 'rxjs';
import { API_PATH } from 'src/utils/constant';
import { ApiService } from '@services/api.service'
import { ITAppVoucher, ITVoucher } from '@models/voucher.model';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(
    private _http: HttpClient,
  ) { }

  getAreas(): Observable<ITArea[]> {
    return this._http.get<ITArea[]>(environment.apiURL + API_PATH.AREA)
  }

  getDistricts(id_tinh: number): Observable<ITDistrict[]> {
    return this._http.get<ITDistrict[]>(environment.apiURL + API_PATH.AREA + `/quanhuyen/${id_tinh}`)
  }

  upload(file: any): Observable<any> {
    const formData = new FormData();
    // Store form name as "file" with file data
    formData.append("file", file);

    return this._http.post(environment.apiURL + API_PATH.UPLOAD, formData)
  }

  uploads(files: any): Observable<any> {
    return this._http.post(environment.apiURL + API_PATH.UPLOADS, files)
  }

  getIPAddress(): Observable<any> {
    return this._http.get<any>("http://api.ipify.org/?format=json")
  }

  numberWithCommas(price: any) {
    return price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }

  getFee(url: string, body: any): Observable<any> {
    return this._http.post<ITFee>(`${environment.apiURL}${url}`, body)
      .pipe(catchError((error) => {
        return of(error);
      }), switchMap((response) => {
        return of({
          ...response,
          strTong: response.tongphi.toString(),
          thue: this.numberWithCommas(response.thue),
          phi: this.numberWithCommas(response.phi),
          tongphi: this.numberWithCommas(response.tongphi),
        });
      }));
  }

  getMeetVoucherInfo(voucher: string) {
    const headers = new HttpHeaders({ 'sign': '01c7457348163dde7416ae2595247516' });
    const body = new FormData();
    body.append("voucherID", voucher);
    return this._http.post<any>(environment.apiMeet + API_PATH.GETVCMEET, body, { headers: headers })
  }

  getDiscount(param: string, phi: any, code?: string, user_code?: any, webview?: boolean): Observable<any> {
    // const priceParsed1 = parseFloat(phi.replace(/,/g, ''))
    // if(param == 'AAAAAAAAAA') {
    //   return of({
    //     id: 999,
    //     status: true,
    //     loaiVoucher: '',
    //     maVoucher: 'AAAAAAAAAA',
    //     phanTram: 0.1,
    //     save: this.numberWithCommas((priceParsed1 * 0.1)),
    //     newTongPhi: this.numberWithCommas(priceParsed1 - ((priceParsed1 * 0.1))),
    //     ma_nv: 'A00069999'
    //   });
    // }

    if (param.includes('MGVOUCHER') && webview == false) {
      const headers = new HttpHeaders({ 'sign': '01c7457348163dde7416ae2595247516' });
      const body = new FormData();
      body.append("voucherID", param);
      return this._http.post<any>(environment.apiMeet + API_PATH.GETVCMEET, body, { headers: headers })
        .pipe(catchError((error) => {
          return of(error);
        }), switchMap((response) => {
          const priceParsed = parseFloat(phi.replace(/,/g, ''))
          const fee = Number(priceParsed)
          if (code == 'BSH01' && response.data) {
            if (response.data.type == 'BHPNT1' && response.data.status == 'READY' && fee >= 1000000) {
              return of({
                id: response.data.randomID,
                status: true,
                loaiVoucher: "Giá trị",
                maVoucher: param,
                phanTram: 0,
                save: "350,000",
                newTongPhi: this.numberWithCommas(fee - 350000)
              });
            }
            if (response.data.type == 'BHPN2' && response.data.status == 'READY' && fee >= 2000000) {
              return of({
                id: response.data.randomID,
                status: true,
                loaiVoucher: "Giá trị",
                maVoucher: param,
                phanTram: 0,
                save: "700,000",
                newTongPhi: this.numberWithCommas(fee - 700000)
              });
            }
          }
          if (code == 'VBI07' && response.data) {
            if (response.data.type == 'BHSK1' && response.data.status == 'READY' && fee >= 2000000) {
              return of({
                id: response.data.randomID,
                status: true,
                loaiVoucher: "Giá trị",
                maVoucher: param,
                phanTram: 0,
                save: "500,000",
                newTongPhi: this.numberWithCommas(fee - 500000)
              });
            }
            if (response.data.type == 'BHSK2' && response.data.status == 'READY' && fee >= 3000000) {
              return of({
                id: response.data.randomID,
                status: true,
                loaiVoucher: "Giá trị",
                maVoucher: param,
                phanTram: 0,
                save: "1,000,000",
                newTongPhi: this.numberWithCommas(fee - 1000000)
              });
            }
          }
          if (code == 'MIC01' && response.data) {
            if (response.data.type == 'BHPNT3' && response.data.status == 'READY') {
              return of({
                id: response.data.randomID,
                status: true,
                loaiVoucher: "Giá trị",
                maVoucher: param,
                phanTram: 0,
                save: "30,000",
                newTongPhi: this.numberWithCommas(fee - 30000)
              });
            }
          }
          if (code == 'BSH02' && response.data) {
            if (response.data.type == 'BHPNT4' && response.data.status == 'READY') {
              return of({
                id: response.data.randomID,
                status: true,
                loaiVoucher: "Giá trị",
                maVoucher: param,
                phanTram: 0,
                save: "100,000",
                newTongPhi: this.numberWithCommas(fee - 100000)
              });
            }
          }
          return of({
            id: 0,
            status: true,
            loaiVoucher: "Giá trị",
            maVoucher: param,
            phanTram: 0,
            save: "0",
            newTongPhi: this.numberWithCommas(fee)
          });
        }));
    }

    if (param.length != 10) {
      return this._http.get<ITVoucher>(environment.apiURL + API_PATH.DISCOUNT + `${param}`)
        .pipe(catchError((error) => {
          return of(error);
        }), switchMap((response) => {
          const priceParsed = parseFloat(phi.replace(/,/g, ''))
          if (response?.status == true && response?.maSp == code && phi != '0' && phi != '') {
            return of({
              ...response,
              save: response.loaiVoucher == "Phần trăm" ? this.numberWithCommas((priceParsed * response.phanTram) / 100) : this.numberWithCommas(response.phanTram * 1000),
              newTongPhi: response.loaiVoucher == "Phần trăm" ? this.numberWithCommas(priceParsed - ((priceParsed * response.phanTram) / 100)) : this.numberWithCommas(priceParsed - (response.phanTram * 1000)),
            });
          }
          else {
            return of(
              false);
          }
        }));
    } else {
      return this._http.get<ITVoucher>(environment.apiURL + API_PATH.APPDISCOUNT + `${param}`)
        .pipe(catchError((error) => {
          return of(error);
        }), switchMap((response) => {
          const priceParsed = parseFloat(phi.replace(/,/g, ''))
          if (response[0]?.trang_thai == true && response[0]?.ma_sp == code && phi != '0' && phi != '' && (user_code ? response[0].ma_nv == user_code : true)) {
            return of({
              id: response[0].id,
              status: response[0].trang_thai,
              loaiVoucher: '',
              maVoucher: response[0].ma_voucher,
              phanTram: response[0].ty_le,
              save: this.numberWithCommas((priceParsed * response[0].ty_le)),
              newTongPhi: this.numberWithCommas(priceParsed - ((priceParsed * response[0].ty_le))),
              ma_nv: response[0].ma_nv
            });
          }
          else {
            return of(
              false);
          }
        }));
    }
  }

  onChangeFromDate(date: Date | string): string {
    const fromDate = new Date(date).getDate()
    const fromMonth = new Date(date).getMonth()
    const fromYear = new Date(date).getFullYear()
    return new Date(fromYear + 1, fromMonth, fromDate + 1).toISOString().substring(0, 10)
  }
  changeDateString(date: string) {
    return date.slice(6) + '-' + date.slice(3, 5) + '-' + date.slice(0, 2);
  }

  validDateString(date: string, isWebview: boolean) {
    isWebview ? date = this.changeDateString(date) : date;
    let dateNow = new Date();
    dateNow.setHours(0);
    let dateDate = new Date(date);
    dateDate.setHours(1);
    return (dateDate >= dateNow);
  }

  validBirthdayString(date: string, isWebview: boolean) {
    isWebview ? date = this.changeDateString(date) : date;
    let dateDate = new Date(date);
    dateDate.setHours(0);
    let dateNow = new Date();
    dateNow.setHours(1);
    return (dateDate <= dateNow);
  }

  validBirthday(birthday: any, isWebview: boolean): boolean {
    isWebview ? birthday = this.changeDateString(birthday) : birthday;
    const maxBirth = new Date(new Date().getFullYear() - 18, new Date().getMonth(), new Date().getDate())
    let birth = new Date(birthday)
    birth.setHours(0);
    return birth <= maxBirth ? true : false
  }

  onCapitalize(value: string): string {
    if (!value) {
      return '';
    }
    return value.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
  }

  onNumberIn(value: string): boolean {
    // Cảnh báo chứa số.
    if (value.includes('0') || value.includes('1') || value.includes('2') || value.includes('3') || value.includes('4') || value.includes('5') || value.includes('6') || value.includes('7')
      || value.includes('8') || value.includes('9')) {
      return true;
    }
    else {
      return false;
    }
  }

}
