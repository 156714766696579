import { CONTENT_STEPS } from 'src/utils/constant';
import { PRODUCT_TEMPLATE } from 'src/utils/product';

import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { NavigationEnd, Router, ActivatedRoute } from '@angular/router';
import { ITProduct } from '@models/product.model';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from '@services/api.service';
import { ChangeProductService } from '@services/lang/change-product.service';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.css']
})
export class ProductComponent implements OnInit {
  url: any = ''
  public contentValue = 0
  public switchValue = 1
  isPayment: boolean = false
  isShared: boolean = false
  appVoucher: any = ''
  userCode: any = ''
  meetVoucher: any = ''
  public product: ITProduct = {
    ...PRODUCT_TEMPLATE
  }
  lang: string = ''

  constructor(
    private titleService: Title,
    private meta: Meta,
    private ApiService: ApiService,
    private ChangeService: ChangeProductService,
    private router: Router,
    public translate: TranslateService,
    private route: ActivatedRoute
  ) {
    this.lang = translate.getDefaultLang();
    translate.onLangChange.subscribe((e: any) => {
      this.lang = e.lang;
      if (this.lang == 'vi') {
        router.navigate([ChangeService.onChangeProductLangToVi(router.url)]);
      }
      else {
        router.navigate([ChangeService.onChangeProductLangToEn(router.url)]);
      }

    })
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.url = event.url
        const regex = /[/]([a-z]+[-]*){8,64}[/]/g
        if (this.url.includes('bao-hiem') && this.lang != 'vi') {
          this.lang = 'vi'
        }
        if (this.url.includes('insurance') && this.lang != 'en') {
          this.lang = 'en'
        }
        // if (this.url.includes('/A')) {
        //   this.getRoute(this.url.match(regex)?.toString().slice(1, -1))
        //   this.isPayment = true
        //   this.isShared = true
        // } else {
        let meta = this.route.snapshot.paramMap.get('meta')
        this.getRoute(meta || '')
        this.appVoucher = this.route.snapshot.paramMap.get('user')?.length == 10? this.route.snapshot.paramMap.get('user'):null
        this.userCode = this.route.snapshot.paramMap.get('user')?.length == 9? this.route.snapshot.paramMap.get('user'):null
        this.meetVoucher = this.route.snapshot.paramMap.get('meetvoucher')
      }
    })
  }

  ngOnInit(): void {
    window.addEventListener('scroll', this.scrollController, true);
    // window.innerWidth > 1280 ? this.isPayment = true : ''
  }

  ngOnDestroy() {
    window.removeEventListener('scroll', this.scrollController, true);
  }

  getRoute(meta: string) {
    this.ApiService.pickProduct(meta, this.lang).subscribe((res: any) => {
      this.product = res;
      this.titleService.setTitle(res.name)
      this.meta.updateTag({ property: 'og:image', content: res.icon })
    });
  }

  setContentStep(step: number) {
    this.contentValue = step
    this.switchValue = step
  }

  scroll(id: string) {
    const el: HTMLElement | any = document.getElementById(id);
    el.scrollIntoView();
  }

  // Add the sticky class to the navbar when you reach its scroll position. Remove "sticky" when you leave the scroll position
  scrollController() {
    var controller: any = document.getElementById('content__controller')
    window.pageYOffset >= 54 ? controller?.classList?.add("sticky") : controller?.classList?.remove("sticky")
  }

  setIsPayment(value: boolean) {
    this.isPayment = value
  }

  contents = CONTENT_STEPS
}
