
<div class="app-block bg-blue">
  <div class="container">
    <div class="app-block__description">
      <app-content-title [label]="'APP.LABEL'|translate" [center]="false" [line]="false" class="app-block__head">
      </app-content-title>
      <p class="app-block__child-head">
        {{'APP.HEAD'|translate}}
      </p>
      <div class="app-block__policies">
        <div class="app-block__policy" *ngFor="let policy of policies">
          <span class="material-icons">star_border</span>
          <p class="app-block__policy-label">{{ policy.label|translate }}</p>
        </div>
      </div>
      <div class="app-block__download">
        <span>{{'APP.DOWNLOAD'|translate}}</span>
        <div class="app-block__download--url">
          <a target="_blank" href="https://play.google.com/store/apps/details?id=com.abank.kitiapp&hl=vi">
            <img src="../../../assets/images/gg play.png" alt="on CH Play" />
          </a>
          <a target="_blank"
            href="https://apps.apple.com/vn/app/finme-pro-b%E1%BA%A3o-hi%E1%BB%83m-s%E1%BB%91/id6449207017?l=vi">
            <img src="../../../assets/images/App Store.png" alt="on App Store" />
          </a>
        </div>
      </div>
    </div>
    <img src="../../../assets/images/home-v1/finme-app.png" alt="finme-app" class="app-block__thumb" />
    <a class="btn btn-primary" id="app-block__install" (click)="openModalPromise(download_modal)" role="button">
      {{'APP.BUTTON'|translate}}
    </a>
  </div>
</div>

<ng-template #download_modal let-offcanvas>
  <div class="offcanvas-header line">
    <p class="offcanvas-title__modal">{{'APP.HEAD'|translate}}</p>
    <button type="button" class="btn-close" aria-label="Close" (click)="offcanvas.dismiss('Cross click')"></button>
  </div>
  <div class="offcanvas-body">
    <div class="app-block__download--url">
      <span>{{'APP.DOWNLOAD'|translate}}</span>
      <a target="_blank" href="https://play.google.com/store/apps/details?id=com.abank.kitiapp&hl=vi">
        <img src="../../../assets/images/gg play.png" alt="on CH Play" />
      </a>
      <span>{{'APP.OR'|translate}}</span>
      <a target="_blank"
        href="https://apps.apple.com/vn/app/finme-pro-b%E1%BA%A3o-hi%E1%BB%83m-s%E1%BB%91/id6449207017?l=vi">
        <img src="../../../assets/images/App Store.png" alt="on App Store" />
      </a>
    </div>
  </div>
</ng-template>
