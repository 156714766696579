<div class="category-carousel-card">
  <div class="category__upper">
    <div class="">
      <img src="../../../assets{{icon}}" alt="{{name}}" class="category__icon">
      <span class="category__name">{{name}}</span>
    </div>
    <img src="../../../assets{{logo}}" alt="" class="category__logo">
  </div>
  <p class="category__slogan">{{slogan}}</p>
</div>
