import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { ITCart } from '@models/cart.model';
import { catchError, Observable, of, switchMap } from 'rxjs';
import { API_PATH } from 'src/utils/constant';

@Injectable({
  providedIn: 'root'
})
export class CartService {

  constructor(
    private _http: HttpClient,
  ) { }

  getContracts(pageNumber?: number, pageSize?: number, trangthaithanhtoan?: string, manv?:string): Observable<ITCart[]> {
    let params =''
    if (pageNumber && pageSize) {
      params = `?pageNumber=${pageNumber}&pageSize=${pageSize}&trangthaithanhtoan=${trangthaithanhtoan}&manv=${manv}`
    }

    if (pageNumber && pageSize &&!manv) {
      params = `?pageNumber=${pageNumber}&pageSize=${pageSize}&trangthaithanhtoan=${trangthaithanhtoan}`
    }

    return this._http.get(`${environment.apiURL}${API_PATH.CONTRACT}${params}`)
      .pipe(catchError((error) => {
        return of(error);
      }), switchMap((response) => {
        return of(response);
      }));
  }

  getAccs(pageNumber?: number, pageSize?: number, trangthai?: string, user?:string){
    let params =''
    if (pageNumber && pageSize) {
      params = `?pageNumber=${pageNumber}&pageSize=${pageSize}${trangthai?'&trang_thai='+trangthai:''}${user?'&user_code='+user:''}`
    }
    // console.log(params)
    return this._http.get(`${environment.apiURL}${API_PATH.ACC}${params}`)
      .pipe(catchError((error) => {
        return of(error);
      }), switchMap((response) => {
        return of(response);
      }));
  }

  getMaterialList(pageNumber?: number, pageSize?: number, trangthai?: string, name?:string){
    let params =''
    if (pageNumber && pageSize) {
      params = `?pageNumber=${pageNumber}&pageSize=${pageSize}${trangthai?'&trang_thai='+trangthai:''}${name?'&ten_khach_hang='+name:''}`
    }
    // console.log(params)
    return this._http.get(`${environment.apiURL}${API_PATH.MATERIALLIST}${params}`)
      .pipe(catchError((error) => {
        return of(error);
      }), switchMap((response) => {
        return of(response);
      }));
  }

  getUserList(user:string, pageNumber?:number, pageSize?:number, status?:number, user_code?:string){
    let params = `?${pageNumber?'pageNumber='+pageNumber:''}${pageSize?'&pageSize='+pageSize:''}${status?'&trang_thai='+status:''}${user?'&ma_nv='+user:''}${user_code?'&user_code='+user_code:''}`
    // console.log(params)
    return this._http.get(`${environment.apiURL}${API_PATH.USER_LIST}${params}`)
      .pipe(catchError((error) => {
        return of(error);
      }), switchMap((response) => {
        return of(response);
      }));
  }

  postContract(data: ITCart): Observable<ITCart> {
    return this._http.post(`${environment.apiURL}${API_PATH.CONTRACT}`, data)
      .pipe(catchError((error) => {
        return of(error);
      }), switchMap((response) => {
        return of(response);
      }));
  }

  getContractbyID(id: number): Observable<ITCart> {
    return this._http.get(`${environment.apiURL}${API_PATH.CONTRACT}/${id}`)
      .pipe(catchError((error) => {
        return of(error);
      }), switchMap((response) => {
        return of(response);
      }));
  }

  getAccsByID(id: number) {
    return this._http.get(`${environment.apiURL}${API_PATH.ACC}/${id}`)
      .pipe(catchError((error) => {
        return of(error);
      }), switchMap((response) => {
        return of(response);
      }));
  }

  getUserByCode(code: number) {
    return this._http.get(`${environment.apiURL}${API_PATH.GET_CTV}/${code}`)
      .pipe(catchError((error) => {
        return of(error);
      }), switchMap((response) => {
        return of(response);
      }));
  }

  putContract(id: number, data: ITCart): Observable<ITCart> {
    return this._http.put(`${environment.apiURL}${API_PATH.CONTRACT}/${id}`, data)
      .pipe(catchError((error) => {
        return of(error);
      }), switchMap((response) => {
        return of(response);
      }));
  }

  putAcc(id: number, data: any){
    return this._http.put(`${environment.apiURL}${API_PATH.ACC}/${id}`, data)
      .pipe(catchError((error) => {
        return of(error);
      }), switchMap((response) => {
        return of(response);
      }));
  }

  putMaterial(id: number, data: any){
    return this._http.put(`${environment.apiURL}${API_PATH.PUTMATERIAL}/${id}`, data)
      .pipe(catchError((error) => {
        return of(error);
      }), switchMap((response) => {
        return of(response);
      }));
  }

  deleteContract(id: number): Observable<ITCart> {
    return this._http.delete(`${environment.apiURL}${API_PATH.CONTRACT}/${id}`)
      .pipe(catchError((error) => {
        return of(error);
      }), switchMap((response) => {
        return of(response);
      }));
  }

  getContractPersonsListbyID(id: number): Observable<any> {
    return this._http.get(`${environment.apiURL}${API_PATH.NGUOI_DS}/${id}`)
    .pipe(catchError((error) => {
      return of(error);
    }), switchMap((response) => {
      return of(response);
    }));
  }

  getContractPersonPbyID(id: number): Observable<any> {
    // console.log(environment.apiURL)
    return this._http.get(`${environment.apiURL}${API_PATH.PERSONP}/${id}`)
    .pipe(catchError((error) => {
      return of(error);
    }), switchMap((response) => {
      // console.log(`${environment.apiURL}${API_PATH.PERSONP}/${id}`)
      return of(response);
    }));
  }

  getMaterialByID(id: number): Observable<any> {
    // console.log(environment.apiURL)
    return this._http.get(`${environment.apiURL}${API_PATH.MATERIAL}/${id}`)
    .pipe(catchError((error) => {
      return of(error);
    }), switchMap((response) => {
      // console.log(`${environment.apiURL}${API_PATH.PERSONP}/${id}`)
      return of(response);
    }));
  }

  getContractPages(pageSize: number): Observable<any> {
    return this._http.get(`${environment.apiURL}${API_PATH.CONTRACT_PAGE_NUMBERS}?pageSize=${pageSize}`)
      .pipe(catchError((error) => {
        return of(error);
      }), switchMap((response) => {
        return of(response);
      }));
  }

  getBSHCarContract(id: number): Observable<any> {
    return this._http.get(`${environment.apiURL}${API_PATH.BSHCar_CONTRACT}/${id}`)
      .pipe(catchError((error) => {
        return of(error);
      }), switchMap((response) => {
        return of(response);
      }));
  }

}
