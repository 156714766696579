<div class="admin-body">
  <div class="row page-titles mx-0">
    <div class="col p-md-0">
      <ol class="breadcrumb">
        <li class="breadcrumb-item" routerLink="/admin/dashboard">Quản lý</li>
        <li class="breadcrumb-item" routerLink="/admin/vouchers-manager">Mã giảm giá</li>
        <li class="breadcrumb-item active">{{ voucherId!=0 ? 'Cập nhật' : 'Thêm mới' }}</li>
      </ol>
    </div>
  </div>

  <div class="admin__container">
    <div class="flex space">
      <span class="admin__container-head">{{ voucherId!=0 ? 'Cập nhật' : 'Thêm mới' }} mã giảm giá</span>
    </div>
    <div #dialogForm>
      <div class="modal-body">
        <form class="row" [formGroup]="form">
          <div style="display: flex;justify-content: space-between;">
            <div class="w-48 text-field">
              <label class="mx-1" for="tenSanPham">Tên sản phẩm<span class="required">*</span></label>
              <select required type="text" id="tenSanPham" name="tenSanPham" formControlName="tenSanPham"
                (ngModelChange)="calculation();onCheckValue()">
                <option *ngFor="let product of products" [value]="product.name">{{product.name|translate}}</option>
              </select>
              <div class="invalid">
                <small *ngIf="form.controls['tenSanPham'].touched && form.controls['tenSanPham'].hasError('required')">
                  {{ error.required | translate }}
                </small>
              </div>
            </div>
            <div *ngIf="form.controls['tenSanPham'].value" class="w-48 text-field">
              <label class="mx-1" for="loaiVoucher">Loại voucher<span class="required">*</span></label>
              <select required type="text" id="loaiVoucher" name="loaiVoucher" formControlName="loaiVoucher"
                (ngModelChange)="onCheckType();calculation()">
                <option *ngFor="let opt of voucherTypes" [value]="opt.name">{{opt.name|translate}}</option>
                <!-- <option value="Phần trăm">Phần trăm</option> -->
              </select>
              <div class="invalid">
                <small
                  *ngIf="form.controls['loaiVoucher'].touched && form.controls['loaiVoucher'].hasError('required')">
                  {{ error.required | translate }}
                </small>
              </div>
            </div>
          </div>

          <div *ngIf="form.controls['loaiVoucher'].valid&&form.controls['tenSanPham'].valid||voucherId!=0">
            <div style="display: flex;justify-content: space-between;">
              <div style="display: flex;width: 48%;justify-content: space-between;">
                <div class="w-48 text-field">
                  <label class="mx-1" for="maVoucher">Mã voucher</label>
                  <input readonly type="text" id="maVoucher" name="maVoucher" formControlName="maVoucher" />
                </div>
                <div class="w-48 text-field">
                  <label class="mx-1" for="voucher_conlai">Số lượng còn lại</label>
                  <input readonly type="number" id="voucher_conlai" name="voucher_conlai" formControlName="voucher_conlai" />
                </div>
              </div>
              <div style="display: flex;width: 48%;justify-content: space-between;">
                <div class="w-48 text-field">
                  <label class="mx-1" for="ngayHetHan">Ngày hết hạn<span class="required">*</span></label>
                  <input type="date" id="ngayHetHan" name="ngayHetHan" formControlName="ngayHetHan" />
                  <div class="invalid">
                    <small
                      *ngIf="form.controls['ngayHetHan'].touched && form.controls['ngayHetHan'].hasError('required')">
                      {{ error.required | translate }}
                    </small>
                  </div>
                </div>
                <div class="w-48 text-field">
                  <label class="mx-1" for="soLuong">Số lượng khởi tạo<span class="required">*</span></label>
                  <input [readonly]="voucherId!=0" type="number" id="soLuong" name="soLuong" formControlName="soLuong" />
                  <div class="invalid">
                    <small *ngIf="form.controls['soLuong'].touched && form.controls['soLuong'].hasError('required')">
                      {{ error.required | translate }}
                    </small>
                  </div>
                </div>
              </div>
            </div>

            <div *ngIf="!isPercent" style="display: flex;justify-content: space-between;">
              <div class="w-48 text-field">
                <label class="mx-1" for="minRevenue">Hoa hồng tối thiểu<span class="required">*</span></label>
                <input readonly required type="text" id="minRevenue" name="minRevenue" formControlName="minRevenue"
                  [(ngModel)]="revenueS" [value]="revenueS" />
                <div class="invalid">
                  <small
                    *ngIf="form.controls['minRevenue'].touched && form.controls['minRevenue'].hasError('required')">
                    {{ error.required | translate }}
                  </small>
                </div>
              </div>
              <div class="w-48 text-field">
                <label class="mx-1" for="minFee">Giá trị hợp hồng tối thiểu<span class="required">*</span></label>
                <input readonly required type="text" id="minFee" name="minFee" formControlName="minFee"
                  [(ngModel)]="minFee" [value]="minFee" />
                <div class="invalid">
                  <small *ngIf="form.controls['minFee'].touched && form.controls['minFee'].hasError('required')">
                    {{ error.required | translate }}
                  </small>
                </div>
              </div>
            </div>

            <div style="display: flex;justify-content: space-between;">
              <div *ngIf="isPercent" class="w-48 text-field">
                <label class="mx-1" for="phanTram">Phần trăm giảm giá<span class="required">*</span></label>
                <input [readonly]="disableInput" required type="number" id="phanTram" name="phanTram"
                  formControlName="phanTram" [(ngModel)]="percent" [value]="percent" (ngModelChange)="calculation()" />
                <span class="input--check"><span>%</span></span>
                <div class="invalid">
                  <small *ngIf="form.controls['phanTram'].touched && form.controls['phanTram'].hasError('required')">
                    {{ error.required | translate }}
                  </small>
                  <small *ngIf="form.controls['phanTram'].touched && isPercentWrong">
                    {{ 'Phần trăm vượt quá hoa hồng' }}
                  </small>
                </div>
              </div>
              <div *ngIf="!isPercent" class="w-48 text-field">
                <label class="mx-1" for="phanTram">Giá trị giảm giá<span class="required">*</span></label>
                <input [readonly]="disableInput" required type="number" id="phanTram" name="phanTram"
                  formControlName="phanTram" [(ngModel)]="percent" [value]="percent" (ngModelChange)="calculation()" />
                <span class="input--check"><span>.000 VNĐ</span></span>
                <div class="invalid">
                  <small *ngIf="form.controls['phanTram'].touched && form.controls['phanTram'].hasError('required')">
                    {{ error.required | translate }}
                  </small>
                  <small *ngIf="form.controls['phanTram'].touched && isDiscountWrong">
                    {{ 'Giá trị voucher vượt quá hoa hồng tối thiểu' }}
                  </small>
                </div>
              </div>
              <div *ngIf="isPercent" class="w-48 text-field">
                <label class="mx-1" for="hoaHong">Hoa hồng còn lại<span class="required">*</span></label>
                <input readonly required type="number" [(ngModel)]="totalDiscountP" [value]="totalDiscountP" />
                <span class="input--check"><span>%</span></span>
              </div>
              <div *ngIf="!isPercent" class="w-48 text-field">
                <label class="mx-1" for="hoaHong">Hoa hồng còn lại<span class="required">*</span></label>
                <input readonly required type="text" [(ngModel)]="totalDiscount" [value]="totalDiscount" />
                <!-- <span class="input--check"><span>.000 VNĐ</span></span> -->
              </div>
            </div>
          </div>
        </form>
      </div>

      <div class="modal-footer">
        <button type="button" class="mx-3 btn btn-primary flex space"
          [disabled]="this.form.controls['phanTram'].invalid || isDiscountWrong || isPercentWrong" (click)="onSave()">
          <span class="material-icons text-light">save</span>
          <p class="m-0 px-1">{{voucherId!=0 ? 'Cập nhật' : 'Tạo voucher'}}</p>
        </button>

        <button type="button" class="btn btn-danger flex space">
          <span class="material-icons text-light">close</span>
          <p class="m-0 px-1" routerLink="/admin/vouchers-manager">Quay lại</p>
        </button>
      </div>
    </div>
  </div>
</div>