import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { ITDashboard } from '@models/dashboard.model';
import { catchError, Observable, of, switchMap } from 'rxjs';
import { API_PATH } from 'src/utils/constant';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(
    private _http: HttpClient,
  ) { }

  getDashboard(): Observable<ITDashboard> {
    return this._http.get(`${environment.apiURL}${API_PATH.DASHBOARD}`)
      .pipe(catchError((error) => {
        return of(error);
      }), switchMap((response) => {
        return of(response);
      }));
  }

}
