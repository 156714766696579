import { Component, OnInit, TemplateRef } from '@angular/core';
import { ITContent } from '@models/content.model';
import { ContentService } from '@services/content/content.service';
import { BOOLEAN_SELECT, CONTENT_TEMPLATE, STATUS_SELECTOR } from 'src/utils/constant';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ERROR } from 'src/utils/validation';
import { Editor, Toolbar } from 'ngx-editor';
import { CommonService } from '@services/common/common.service';
import { Pipe, PipeTransform } from '@angular/core';
import { ProtectService } from '@services/protect/protect.service';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
// import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
// fix: npm i --save-dev @types/ckeditor__ckeditor5-build-classic

@Component({
  selector: 'app-content-edit',
  templateUrl: './content-edit.component.html',
  styleUrls: ['./content-edit.component.css']
})
export class ContentEditComponent implements OnInit {
  contents: ITContent[] = []
  content : ITContent = {
    ...CONTENT_TEMPLATE
  }

  page: number = 1
  total: number = 10
  pageSize: number = 10
  tempID: number = 0;
  ID: any;
  tempImage: any
  CurrentLang:any;
  dialogType: string = 'edit'
  dialogName: string = ''
  disableInput: boolean = false
  detail!: Editor;
  toolbar: Toolbar = [
    ['bold', 'italic'],
    ['underline', 'strike'],
    ['code', 'blockquote'],
    ['ordered_list', 'bullet_list'],
    [{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
    ['link', 'image'],
    ['text_color', 'background_color'],
    ['align_left', 'align_center', 'align_right', 'align_justify'],
  ];

  form: FormGroup = this._formBuilder.group({
    title: ['', Validators.required],
    image: ['', Validators.required],
    description: ['', Validators.required],
    detail: ['', Validators.required],
    status: [true],
  })

  constructor(
    private _service: ContentService,
    private _formBuilder: FormBuilder,
    private _common: CommonService,
    private _protect: ProtectService,
    private _route: ActivatedRoute,
    private _router: Router,
    public _translate: TranslateService
  ) { this.CurrentLang = _translate.getDefaultLang();
    _translate.onLangChange.subscribe((e:any)=>{
      this.CurrentLang= e.lang;
      this.getContents()
    })}

  ngOnInit(): void {
    this.getContents()
    this.detail = new Editor();
    this._route.paramMap.subscribe((params:ParamMap) => {
      this.ID = params.get('id');
    })
    this.getContentbyID(Number(this.ID));
    this.tempID = Number(this.ID);
  }

  getContents(){
    this._service.getContents(this.CurrentLang).subscribe(data => {
      this.contents = data
    })
  }

  getContentbyID(id: number) {
    this._service.getContentbyID(id,this.CurrentLang).subscribe(res => {
      this.content = res
      this.onInitData(this.content)
    })
  }

  onSave() {
    let status: boolean;
    if(this.form.controls['status'].value == 'false'){
      status = false;
    }
    else {
      status = true;
    }
    let payload = {
      // ...this.content,
      id: this.tempID || 0,
      name: this.form.controls['title'].value || '',
      metaTitle: this._protect.formatMetaTile(this.form.controls['title'].value),
      description: this.form.controls['description'].value || '',
      image: this.form.controls['image'].value || '',
      status: status,
      detail: this.form.controls['detail'].value || '',
      // createdDate: this.dialogType === 'add' ? new Date().toISOString() : '',
      // modifiedDate: this.dialogType === 'edit' ? new Date().toISOString() : '',
      "categoryID": 0,
      "warranty": 0,
      "createdDate": new Date().toISOString(),
      "createdBy": "admin",
      "modifiedDate": new Date().toISOString(),
      "modifiedBy": "admin",
      "metaKeywords": "string",
      "metaDescriptions": "string",
      topHot: new Date().toISOString(),
      "viewCount": 0,
      "tags": "string",
      "language": "vie",
    }
    this.dialogType === 'edit' && this._service.putContent(this.tempID, payload, this.CurrentLang).subscribe((res: any) => {
      res && this.getContents()
      this.onEmpty();
      if (res.ok == false){
        alert('Cập nhật thất bại');
      }
      else { alert('Cập nhật thành công')}
      this._router.navigate(['/admin/content-manager']);
    })


  }

  onInitData(data: any) {
    this.form.controls['title'].setValue(data.name)
    this.form.controls['image'].setValue(data.image)
    this.form.controls['status'].setValue(data.status)
    this.form.controls['description'].setValue(data.description)
    this.form.controls['detail'].setValue(data.detail)
  }

  onEmpty() {
    this.form.controls['title'].setValue('')
    this.form.controls['image'].setValue('')
    this.form.controls['status'].setValue(true)
    this.form.controls['description'].setValue('')
    this.form.controls['detail'].setValue('')
  }

  selectFile(e: any) {
    this.tempImage = e.target.files

    let formData = new FormData()
    for (let file of this.tempImage) {
      formData.append('files', file)
    }

    this._common.uploads(formData).subscribe((res: any) => {
      this.form.controls['image'].setValue(res[0].name)
    })
  }


  error = ERROR
  boolean = BOOLEAN_SELECT
}
