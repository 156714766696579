import { ApiService } from '@services/api.service'
import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core'
import { ITFee } from '@models/fee.model'
import { DATA_TO_DEFINE, GENDERS } from 'src/utils/constant'
import { BHTCNV_STEPS } from 'src/utils/steps'
import { ITVoucher } from '@models/voucher.model'
import { FEE_TEMPLATE, now, PAYMENT_TEMPLATE, VOUCHER_TEMPLATE } from 'src/utils/product'
import { TCNV_FORM_TEMPLATE } from 'src/utils/product/uic'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { ERROR, VALID } from 'src/utils/validation'
import { ITPayment } from '@models/payment.model'
import { ActivatedRoute, Router } from '@angular/router'
import { ITBHTCNVBill, ITBHTCNVFeeNew } from '@models/baohiemtrocapnamvien.model'
import * as moment from 'moment'
import { CommonService } from '@services/common/common.service'
import { ProtectService } from '@services/protect/protect.service'
import { ToastService } from '@services/toast.service'

@Component({
  selector: 'app-baohiemtainancanhan',
  templateUrl: './baohiemtainancanhan.component.html',
  styleUrls: ['./baohiemtainancanhan.component.css']
})
export class BaohiemtainancanhanComponent implements OnInit {
  @Input() code: string = ''
  @Input() isWebview: boolean = false
  @Input() isApp: boolean = false
  @Input() isShared: boolean = false
  @Output() setIsPayment = new EventEmitter()
  @Input() appVoucher: any = null
  @Input() userCode: any = null
  @Input() meetVoucher: any = null
  isPayment: boolean = true
  isTrueVoucher: boolean = true;
  public isBack = false
  public stepValue = 1
  public isPerson = true
  public isFamily = false
  public apiPath: string = ''
  public paymentPath: any = ''
  public save = 0
  public fee: ITFee = {
    ...FEE_TEMPLATE
  }
  now = now
  files: any
  public bill: ITBHTCNVFeeNew = {
    thoihan: '0',
    goi: 'goi_1',
    canhan: '',
  }
  public form: ITBHTCNVBill = {
    ...TCNV_FORM_TEMPLATE
  }
  public voucher: ITVoucher = {
    ...VOUCHER_TEMPLATE
  }
  public payment: ITPayment = {
    ...PAYMENT_TEMPLATE
  }
  dynamicVal: any
  currentDate: number = new Date().getDate()
  currentMonth: number = new Date().getMonth()
  currentYear: number = new Date().getFullYear()
  minBirth: any = new Date(this.currentYear - 65, this.currentMonth, this.currentDate + 1).toISOString().substring(0, 10)
  maxBirth: any = new Date(this.currentYear - 18, this.currentMonth, this.currentDate + 1).toISOString().substring(0, 10)
  wrongBirth: boolean = false
  durations: any
  respons: any
  dynamic: any
  submited: boolean = false
  isTrueLauncher: boolean = true;
  ma_nvAppVoucher:any=undefined
  tempFile: any = {
    back: '',
    front: '',
  }
  webviewNow: any;
  validDate: boolean = true;
  isNumberIn: boolean = false;
  ngaY_HL_Text: string = '';
  ngaY_KT_Text: string = '';

  constructor(
    private ApiService: ApiService,
    private _common: CommonService,
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private router: Router,
    private _protect: ProtectService,
    public _toast: ToastService,
  ) { }

  ngOnInit(): void {
    this.onFinmeInit(this.code)
    this.isWebview ? (this.getParams(), this.webviewNow = moment(this.now).format('DD-MM-YYYY'),
      this.form.ngaY_HL = moment(this.form.ngaY_HL).format('DD-MM-YYYY'),
      this.form.ngaY_KT = moment(this.form.ngaY_KT).format('DD-MM-YYYY')) : this.handleUserCode();
    this.setTextDate();
    this.onChangeDate(this.bill.thoihan, (this.isWebview ? this.ngaY_HL_Text : this.form.ngaY_HL));
    if (this.appVoucher?.length == 10) {
      this.voucher.maVoucher = this.appVoucher
    }
    if (this.meetVoucher){
      this.voucher.maVoucher = this.meetVoucher
    }
  }

  onFinmeInit(code: string) {
    const data = DATA_TO_DEFINE.filter(item => item.code === code)[0]
    this.form.nv = data.nv
    this.form.tensanpham = data.name
    this.durations = data.durations
    this.respons = data.respons
    this.dynamic = data.dynamic
    this.apiPath = data.path
    this.bill.thoihan = data.thoihan || '0'
    data.isChangeDate && this.onChangeDate(this.bill.thoihan, this.form.ngaY_HL)
    data.isDefaultFee && this.getFee()
  }

  handleUserCode() {
    const user = this.route.snapshot.params['user']
    if (user?.length == 9) {
      this.form.magioithieu = user
      this.getLauncher(user)
    }
  }

  getParams() {
    this.router.url.includes('&')
      ? (this.form.user_Code = this.payment.user_Code = this.router.url.slice(-9))
      : ''
  }

  upload(e: any, type: number) {
    let formData = new FormData()
    for (let file of e.target.files) {
      formData.append('files', file)
    }
    this._common.uploads(formData).subscribe((res) => {
      this._toast.show('Tải file thành công !', { icon: 'check_circle', classname: 'bg-success text-light' })
      type
        ? (this.tempFile.back = res[0].name)
        : (this.tempFile.front = res[0].name)
    })
  }

  confirmFile(): void {
    this._toast.show('Cập nhật ảnh CMT/CCCD thành công !', { icon: 'check_circle', classname: 'bg-success text-light' })
    this.form.files = this.tempFile
  }

  removeFile(type: number): void {
    this._toast.show('Xóa file thành công !', { icon: 'check_circle', classname: 'bg-success text-light' })
    type
      ? (this.tempFile.back = '')
      : (this.tempFile.front = '')
  }

  setTextDate() {
    this.ngaY_HL_Text = this._common.changeDateString(this.form.ngaY_HL);
    this.ngaY_KT_Text = this._common.changeDateString(this.form.ngaY_KT);
    this.validDate = this._common.validDateString(this.form.ngaY_HL, this.isWebview);
  }
  // Get date, month, year listen thoihan, ngaY_HL, then set ngaY_KT
  onChangeDate(range: any, date: any) {
    const fromDate = new Date(date).getDate()
    const fromMonth = new Date(date).getMonth()
    const fromYear = new Date(date).getFullYear()
    let tempMonth = fromMonth + parseInt(range)
    this.ngaY_KT_Text = JSON.parse(JSON.stringify(this.form.ngaY_KT));
    if (tempMonth > 12) {
      this.form.ngaY_KT = new Date(fromYear + 1, tempMonth - 12, fromDate + 1)
        .toISOString()
        .substring(0, 10)
    } else {
      this.form.ngaY_KT = new Date(fromYear, tempMonth, fromDate + 1)
        .toISOString()
        .substring(0, 10)
    }
    this.ngaY_KT_Text = JSON.parse(JSON.stringify(this.form.ngaY_KT));
    this.isWebview ? this.form.ngaY_KT = moment(this.form.ngaY_KT).format('DD-MM-YYYY') : this.form.ngaY_KT = this.form.ngaY_KT;
    this.getFee()
  }

  onChangeBirthDay(birthday: any) {
    this.isWebview ? birthday = this._common.changeDateString(birthday) : birthday;
    const minBirth = new Date(this.currentYear - 65, this.currentMonth, this.currentDate)
    const maxBirth = new Date()
    maxBirth.setDate(new Date().getDate() - 42)
    const birthdayDate = new Date(birthday);
    if (birthdayDate >= minBirth && birthdayDate <= maxBirth) {
      this.wrongBirth = false
    }
    else {
      this.wrongBirth = true
    }
  }

  policyHandler() {
    this.dynamicVal = this.dynamic.find((item: any) => item.value === this.bill.goi)
    this.form.goisanpham = this.respons.find((item: any) => item.value === this.bill.goi)?.label
  }

  onController(e: number, f: any) {
    // If any input in any step has invalid, open modal notice "Please fill the form", then warning
    // If all input in any step hasn't invalid, fee next step
    switch (this.stepValue) {
      case 1:
        if (f.controls['goi']?.invalid || f.controls['thoihan']?.invalid || !this.bill.thoihan || f.controls['ngaY_HL']?.invalid
          || !this.validDate || this.fee.phi == '') {
          this._toast.show('Hãy nhập đúng phiếu thông tin !', { icon: 'error', classname: 'bg-danger text-light' });

          f.controls['goi']!.touched = true
          f.controls['thoihan']!.touched = true
          f.controls['ngaY_HL']!.touched = true
        } else this.stepValue += e
        break
      case 2:
        if (f.invalid || this.wrongBirth || this.fee.phi == '') {
          this._toast.show('Hãy nhập đúng phiếu thông tin !', { icon: 'error', classname: 'bg-danger text-light' });

          f.controls['cmt']!.touched = true
          f.controls['mobi']!.touched = true
          f.controls['ten']!.touched = true
          f.controls['nG_SINH']!.touched = true
          f.controls['gioi']!.touched = true
          f.controls['email']!.touched = true
          f.controls['dchi']!.touched = true
        } else {
          this.stepValue += e

          if (this.isWebview && e === 1) {
            this.onSaveClient()
          }
        }
        break
      case 3:
        this.stepValue += e
        break
      default:
        this._toast.show('Hãy nhập đúng phiếu thông tin !', { icon: 'error', classname: 'bg-danger text-light' });
    }
  }

  numberWithCommas(price: any) {
    return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }

  // If magioithieu.length < 1, set nguoiGioiThieu = ''
  getEmptyLauncher() {
    this.form.magioithieu = ''
    this.form.nguoiGioiThieu = ''
  }

  getOldFee() {
    this.save = 0

    this.fee.tongphi = this.fee.phi
  }

  getFee() {
    this.isPerson ? this.bill.canhan = '0' : this.bill.canhan = '1';
    if (!this.bill.thoihan && this.bill.goi) {
      return
    }
    this.fee = { ...FEE_TEMPLATE }
    this._common.getFee(this.apiPath, this.bill).subscribe((data) => {
      this.fee = data
      this.payment.tongThanhToan = this.payment.tongphi = this.form.ttoan = data.strTong
      this.form.ttoan = this._common.numberWithCommas(this.form.ttoan)
      this.voucher.maVoucher ? this.getDiscount(this.voucher.maVoucher) : null
    });
  }

  // BAOHIEM20220824015137
  getDiscount(param: string) {
    this.voucher = {
      ...VOUCHER_TEMPLATE
    }
    this.appVoucher? (this.voucher.maVoucher = this.appVoucher, param=this.appVoucher) : null
    this.save = 0
    this.form.ttoan = this._common.numberWithCommas(this.fee.strTong)
    this.form.magioithieu = this.userCode ? this.userCode : this.form.magioithieu
    // this.form.nguoiGioiThieu = this.userCode? this.form.nguoiGioiThieu: ''
    if(param.includes('MGVOUCHER')){
      this.form.magioithieu = this.userCode = ''
      this.form.nguoiGioiThieu = ''
    }
    this.ma_nvAppVoucher = undefined
    if (!this.fee.phi) {
      this._toast.show('Chưa có phí để áp dụng voucher, hãy hoàn thành các thông tin!', { icon: 'error', classname: 'bg-danger text-light' });
    }
    this._common.getDiscount(param, this.fee.tongphi, this.code,this.form.user_Code, this.isWebview).subscribe((data) => {
      if (data.status) {
        this.voucher = data,
          this.save = data.save
        this.form.ttoan = data.newTongPhi
        data.ma_nv ? (this.form.magioithieu = data.ma_nv, this.getLauncher(this.form.magioithieu)) : null
        data.ma_nv ? this.ma_nvAppVoucher = data.ma_nv : null
        this.isTrueVoucher==true ? this.isTrueVoucher=true
        :(this.isTrueVoucher=true, this._toast.show('Dùng voucher thành công !', { icon: 'check_circle', classname: 'bg-success text-light' }))
      } else {
        this._toast.show('Dùng voucher thất bại! Hãy kiểm tra lại.', { icon: 'error', classname: 'bg-danger text-light' });
        this.isTrueVoucher = false
      }
    })
  }

  getLauncher(param: string) {
    param= param?.trim()
    this.form.nguoiGioiThieu=''
    this.isTrueLauncher=false;
    if(param&&param.includes('A')&&param.length==9){
      this.ApiService.getLauncher(param).subscribe((data) => {
        this.form.nguoiGioiThieu = data?.ho_ten
        data?.ho_ten?(this.form.user_Code= this.payment.user_Code = param,this.isTrueLauncher=true):null
      })
    }
    else if(param&&!param.includes('A')&&param.length==10){
      this.ApiService.getLauncherByPhone(param).subscribe((data) => {
        this.form.nguoiGioiThieu = data[0]?.ho_ten
        data[0]?.ho_ten?(this.form.user_Code= this.payment.user_Code = data[0]?.ma_nv,this.isTrueLauncher=true):null
      })
    } 
  }


  openCentered(content: TemplateRef<any>) {
    this.modalService.open(content, { centered: true })
  }

  openImageModal(content: TemplateRef<any>) {
    this.modalService.open(content, {
      size: window.innerWidth > 500 ? 'xl' : 'lg',
    })
  }

  onCapitalize(): void {
    this.form.ten = this._common.onCapitalize(this.form.ten)
  }

  onNumberIn() {
    this.isNumberIn = this._common.onNumberIn(this.form.ten)
  }

  getBack() {
    this.modalService.dismissAll()

    this.isPayment = false
    this.setIsPayment.emit(this.isPayment)
    // Hàm getBack sẽ ghi lại isPayment bằng false, đồng thời thông qua EventEmitter bắn value isPayment này ra component cha Product

    this.isBack = false
  }

  onSubmit(f: any) {
    if (f.invalid || this.wrongBirth || !this.validDate || this.fee.phi == '') {
      this._toast.show('Hãy nhập đúng phiếu thông tin !', { icon: 'error', classname: 'bg-danger text-light' });

      f.controls['cmt']!.touched = true
      f.controls['mobi']!.touched = true
      f.controls['ten']!.touched = true
      f.controls['nG_SINH']!.touched = true
      f.controls['gioi']!.touched = true
      f.controls['email']!.touched = true
      f.controls['dchi']!.touched = true
    } else {
      this.submited = true
      const totalFee = this.fee.tongphi as string
      const totalPay = this.form.ttoan as string
      this.payment.tongThanhToan = parseFloat(totalPay.replace(/,/g, '')).toString()
      this.payment.tongphi = parseFloat(totalFee.replace(/,/g, '')).toString()
      let payload = {
        ...this.form,
        ...this.payment,
        ...this.bill,
        email: this.form.email || '',
        gioitinh: this.genders.find((i: any) => i.value === this.form.gioi)?.label,
        nG_SINH: this.isWebview ? this._common.changeDateString(this.form.nG_SINH) : this.form.nG_SINH,
        ngaY_HL: this.isWebview ? this.ngaY_HL_Text : this.form.ngaY_HL,
        ngaY_KT: this.isWebview ? this.ngaY_KT_Text : this.form.ngaY_KT,
        goibaohiem: this.respons.find((i: any) => i.value === this.bill.goi)?.label_api,
        goisanpham: this.respons.find((i: any) => i.value === this.bill.goi)?.label_api,
        moiquanhe: (this.bill.canhan == '0') ? 'Cá nhân' : 'Gia đình 4 người',
        voucher: this.voucher.maVoucher
      }
      let formValue = Object.assign(payload)
      localStorage.setItem('form-data', JSON.stringify(formValue))


      this.ApiService.postPayment({ ...payload, moiquanhe: (this.bill.canhan == '0') ? 'Cá nhân' : 'Gia đình 4 người' }).subscribe((res) => {
        if (this.isApp) {
          // Gửi message vào app
          window.postMessage(JSON.stringify({ type: 'getLocalStorage' }));
          const submitButton = document.getElementById('submit');
          submitButton && submitButton.setAttribute('data-ttoan', this.payment.tongThanhToan || '0')
          window.location.href = 'webview/notification/app'
        } else {
          res.paymentUrl && (window.location.href = res.paymentUrl)
        }
        // res.paymentUrl && (window.location.href = res.paymentUrl)
        this.submited = false
      })

    }
  }

  onSaveClient() {
    const totalFee = this.fee.tongphi as string
    const totalPay = this.form.ttoan as string
    this.payment.tongThanhToan = parseFloat(totalPay.replace(/,/g, '')).toString()
    this.payment.tongphi = parseFloat(totalFee.replace(/,/g, '')).toString()
    let payload = {
      ...this.payment,
      user_Code: this.form.user_Code || this.payment.user_Code || '',
      hoten: this.form.ten.trim(),
      ngaysinh: this.isWebview ? this._common.changeDateString(this.form.nG_SINH) : this.form.nG_SINH,
      ngaytao: this.now,
      diachi: this.form.dchi.trim(),
      sodienthoai: this.form.mobi,
      cccd: this.form.cmt,
      email: this.form.email,
      sanPham: this.form.tensanpham,
      thietBi: 'app',
      loaibaohiem: this.form.tensanpham,
      goibaohiem: this.respons.find((i: any) => i.value === this.bill.goi)?.label_api,
      thoihan: this.durations.find((i: any) => i.value == this.bill.thoihan)?.value,
      thoigianthanhtoan: this.now,
      webview: this.isWebview.toString(),
      gioitinh: this.genders.find((i: any) => i.value === this.form.gioi)?.label,
      moiquanhe: (this.bill.canhan == '0') ? 'Cá nhân' : 'Gia đình 4 người'
    }

    this.ApiService.postPayment(payload).subscribe(res => {
      this.payment.idDangKy = this.form.iddangky = res.idDangKy
    })
  }

  pattern = VALID
  error = ERROR
  genders = GENDERS
  steps = BHTCNV_STEPS
}
