<div class="compare-list">
  <div class="container" *ngIf="!isToggle">
    <div class="">
      <app-content-title [label]="'COMPARE.SELECT_PROD'|translate" class="app-block__head"></app-content-title>
    </div>
    <div class="compare-list__main">
      <div id="compare__filter" class="compare__filter desktop-hidden">
        <ng-container *ngFor="let item of filters">
          <div class="" (click)="openFullscreen(filterModal, item.id)">
            <span class="material-icons">{{ item.icon }}</span>
            <span>{{ item.label | translate }}</span>
          </div>
        </ng-container>
      </div>

      <form action="" method="get" #filterForm="ngForm" class="compare__filter mobile-hidden">
        <div class="">
          <span class="filter__title">
            <label for="filteredOrder">{{ 'COMPARE.R1' | translate }}</label>
          </span>
          <div class="text-field">
            <select name="filteredOrder" id="filteredOrder" [(ngModel)]="filter.orderbyphi"
              (ngModelChange)="getCompareKhoangGia()">
              <option *ngFor="let opt of order" [value]="opt.value">{{opt.label|translate}}</option>
            </select>
          </div>
          <br>
          <div class="">
            <span>{{ 'COMPARE.R2' | translate }}</span>
            <select name="filteredNumber" id="filteredNumber" [(ngModel)]="filter.pageSize"
              (ngModelChange)="getCompareKhoangGia()">
              <option value=6 selected>6</option>
              <option value=9>9</option>
              <option value=12>12</option>
              <option value=15>15</option>
            </select>
            <label for="filteredNumber">{{ 'COMPARE.R3' | translate }}</label>
          </div>
        </div>
        <div class="">
          <span class="filter__title">{{ 'COMPARE.R4' | translate }}</span>
          <ng-container *ngFor="let ncc of nccs">
            <div class="check-field">
              <input type="checkbox" [name]="ncc.ncc" [id]="ncc.ncc" [checked]="filter.ncc === ncc.ncc" (click)="onNccChange(ncc.ncc, $event)" (change)="getCompareKhoangGia()">
              <label [for]="ncc.ncc">{{ ncc.ncc }}</label>
            </div>
          </ng-container>
          <div class="linedivider"></div>
          <span class="filter__title">{{ 'COMPARE.R5' | translate }}</span>
          <ng-container *ngFor="let pack of packages">
            <div class="check-field">
              <input type="checkbox" [name]="pack.goi" [id]="pack.goi" [checked]="filter.goi === pack.goi" (click)="onPackChange(pack.goi, $event)" (change)="getCompareKhoangGia()">
              <label [for]="pack.goi">{{ pack.ten }}</label>
            </div>
          </ng-container>
        </div>
      </form>

      <form action="" method="post" class="compare__card-body">
        <ngx-skeleton-loader *ngIf="output.length === 0" count="9" [theme]="{
          margin: '0 0 12px 24px',
          width: '100%',
          height: '272px',
          'border-radius': '5px',
          'background-color': '#f8f9fa',
          'box-shadow': '0 3px 13px -2px #0000003d'
        }"></ngx-skeleton-loader>
        <ng-container *ngFor="let item of output; index as index">
          <div class="compare__card-product" *ngIf="item.phi !== 0">
            <div class="compare__card-product-content">
              <div class="compare__card-product--left">
                <img src="../../../assets{{item.image}}" [alt]="item.name">
                <span>{{item.name}} - {{ 'COMPARE.R6' | translate }}: {{item.tenGoi}}</span>
                <br>
                <span>
                  {{item.gia}} VND/{{ 'COMPARE.R7' | translate }}
                  <!-- {{item.phi | currency:'VND':'symbol':'.2-2'}} -->
                </span>
              </div>
              <div class="compare__card-product--right">
                <div class="compare_accordition">
                  <p>{{ 'COMPARE.R8' | translate }}</p>
                  <ng-container *ngFor="let i of item.quyen; index as a">
                    <div class="accordition" *ngIf="a < 3">
                      <span class="material-icons">done</span>
                      <span>{{i}}</span>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
            <div class="divider"></div>
            <p>{{ 'COMPARE.R9' | translate }}: {{item.ncc}}</p>
            <div id="compare_accordition">
              <app-accordion [summary]="'COMPARE.ACCORDION'|translate" [content]="item.quyen"></app-accordion>
            </div>
            <div class="compare__card-product-btn">
              <div class="compare__add">
                <input type="checkbox" hidden [id]="index" name="compares"
                  (change)="listHandler(compare_list, index, item, $event)">
                <label [for]="index">{{ 'COMPARE.R10' | translate }}</label>
              </div>
              <span routerLink="/san-pham/{{item.metaTitle}}">
                {{ 'COMPARE.R11' | translate }}
              </span>
            </div>
          </div>
        </ng-container>
        <input type="checkbox" name="dynamic" id="dynamic" [value]="dynamic" [(ngModel)]="dynamic" hidden>
        <div class="compare__pagination">
          <ngb-pagination [collectionSize]="filter.limit" [(page)]="filter.pageNumber" aria-label="Default pagination" (pageChange)="getCompareKhoangGia()"></ngb-pagination>
        </div>
      </form>
      <br>
    </div>
  </div>

  <div class="container" *ngIf="isToggle">
    <app-content-title [label]="'COMPARE.RESULT'|translate" class="app-block__head"></app-content-title>
    <br>
    <table class="compare-result__header">
      <tr>
        <td>
          <div class="" (click)="isToggle = false">
            <span class="material-icons">arrow_back</span>
            <p>{{ 'COMPARE.R12' | translate }}</p>
          </div>
        </td>

        <ng-container *ngFor="let item of results">
          <td>
            <span class="material-icons" (click)="onRemove(item)">close</span>
            <p class="prod__name">{{item.name}}</p>
            <p class="prod__goi">Gói: {{item.tenGoi}}</p>
            <img src="../../../assets{{item.image}}" [alt]="item.name">
          </td>
        </ng-container>

        <td class="empty-warming" *ngIf="results.length < 1">
          <span class="material-icons">report_gmailerrorred</span>
          <br>
          <span>{{ 'COMPARE.R13' | translate }}</span>
        </td>
        <td class="add" *ngIf="results.length < 3" (click)="offcanvasAddBox(addbox)">
          <span class="material-icons">add_circle_outline</span>
          <br>
          <span>{{ 'COMPARE.R14' | translate }}</span>
        </td>
        <td *ngIf="results.length < 2"></td>
      </tr>
    </table>

    <div class="compare-result__body">
      <div class="compare-result__body--head" (click)="isPolicyCollapsed = ! isPolicyCollapsed"
        [attr.aria-expanded]="!isPolicyCollapsed" aria-controls="collapseExample">
        <span>{{ 'COMPARE.R15' | translate }}</span>
        <span class="material-icons">{{isPolicyCollapsed ? 'add' : 'remove'}}</span>
      </div>
      <div #policyCollapse="ngbCollapse" [(ngbCollapse)]="isPolicyCollapsed">
        <table>
          <tr *ngFor="let row of policies; index as i">
            <td>{{row.quyenLoi}}</td>
            <td><span *ngIf="results.length >= 1">
                {{ row.quyenLoi === results[0].quyen[i] ? ('COMMON.YES'|translate) : '-' }}
              </span></td>
            <td><span *ngIf="results.length >= 2">
                {{ row.quyenLoi === results[1].quyen[i] ? ('COMMON.YES'|translate) : '-' }}
              </span></td>
            <td><span *ngIf="results.length == 3">
                {{ row.quyenLoi === results[2].quyen[i] ? ('COMMON.YES'|translate) : '-' }}
              </span></td>
          </tr>
        </table>
      </div>
    </div>

    <div class="compare-result__body">
      <div class="compare-result__body--head" (click)="isPackageCollapsed = ! isPackageCollapsed"
        [attr.aria-expanded]="!isPackageCollapsed" aria-controls="collapseExample">
        <span>{{ 'COMPARE.R16' | translate }}</span>
        <span class="material-icons">{{isPackageCollapsed ? 'add' : 'remove'}}</span>
      </div>
      <div class="" #packageCollapse="ngbCollapse" [(ngbCollapse)]="isPackageCollapsed">
        <table>
          <tr *ngFor="let pack of packages">
            <td>{{pack.ten}}</td>
            <td><span *ngIf="results.length >= 1">
                {{ pack.goi === results[0].goi ? results[0].gia + ' VND' : '-' }}
              </span></td>
            <td><span *ngIf="results.length >= 2">
                {{ pack.goi === results[1].goi ? results[1].gia + ' VND' : '-' }}
              </span></td>
            <td><span *ngIf="results.length == 3">
                {{ pack.goi === results[2].goi ? results[2].gia + ' VND' : '-' }}
              </span></td>
          </tr>
        </table>
      </div>
    </div>

    <div class="compare-result__body">
      <div class="compare-result__body--head" (click)="isFeeCollapsed = ! isFeeCollapsed"
        [attr.aria-expanded]="!isFeeCollapsed" aria-controls="collapseExample">
        <span>{{ 'COMPARE.R17' | translate }}</span>
        <span class="material-icons">{{isFeeCollapsed ? 'add' : 'remove'}}</span>
      </div>
      <div class="" #feeCollapse="ngbCollapse" [(ngbCollapse)]="isFeeCollapsed">
        <table>
          <tr *ngFor="let row of policies; index as i">
            <td>{{row.quyenLoi}}</td>
            <td><span *ngIf="results.length >= 1">
                {{ row.quyenLoi === results[0].quyenLoi[i].quyenLoi ? results[0].quyenLoi[i].chiTra + ' VND' : '-' }}
              </span></td>
            <td><span *ngIf="results.length >= 2">
                {{ row.quyenLoi === results[1].quyenLoi[i].quyenLoi ? results[1].quyenLoi[i].chiTra + ' VND' : '-' }}
              </span></td>
            <td><span *ngIf="results.length == 3">
                {{ row.quyenLoi === results[2].quyenLoi[i].quyenLoi ? results[2].quyenLoi[i].chiTra + ' VND' : '-' }}
              </span></td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</div>

<div class="compare-result--fixed" *ngIf="isToggle">
  <div class="container">
    <table>
      <tr>
        <td></td>
        <ng-container *ngFor="let item of results">
          <td>
            <p routerLink="/san-pham/{{item.metaTitle}}">{{ 'COMPARE.R18' | translate }}</p>
          </td>
        </ng-container>

        <ng-container *ngIf="results.length < 2">
          <td></td>
        </ng-container>

        <ng-container *ngIf="results.length < 3">
          <td></td>
        </ng-container>
      </tr>
    </table>
  </div>
</div>

<ng-template #compare_list let-offcanvas>
  <div class="container">
    <div class="offcanvas-header">
      <h2 class="offcanvas-title">{{ 'COMPARE.R19' | translate }}</h2>
      <div class="btn-group">
        <span class="material-icons">
          remove_circle_outline
        </span>
        <span class="material-icons" (click)="offcanvas.dismiss('Cross click')">
          highlight_off
        </span>
      </div>
    </div>
    <div class="offcanvas-body compare-list__modal">
      <div class="compare-list__card">
        <div *ngIf="results.length === 0" class="compare-item__card empty-warming">
          <span class="material-icons">report_gmailerrorred</span>
          <div class="compare-item__card--right">
            <p>{{ 'COMPARE.R20' | translate }}</p>
          </div>
        </div>
        <ng-container *ngFor="let item of results">
          <div class="compare-item__card">
            <span class="material-icons" (click)="onRemove(item)">
              highlight_off
            </span>
            <img src="../../../assets{{item.image}}">
            <div class="compare-item__card--right">
              <p>{{item.name}}</p>
              <p>{{ 'COMPARE.R6' | translate }}: {{item.goi}}</p>
            </div>
          </div>
        </ng-container>
      </div>
      <div class="offcanvas--fixed">
        <button [disabled]="results.length < 1" class="btn-compare" (click)="toggle();offcanvas.close('Close click')">
          {{ 'COMPARE.R21' | translate }}
        </button>
        <button type="button" class="btn-add" [disabled]="results.length === 3" (click)="offcanvasAddBox(addbox)">
          <span>+</span>
        </button>
        <p>{{ 'COMPARE.R22' | translate }} <strong>{{ 'COMPARE.R22' | translate }}</strong> {{ 'COMPARE.R24' | translate }}</p>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #addbox let-offcanvas>
  <div class="container">
    <div class="offcanvas-header">
      <h2 class="offcanvas-title mobile-hidden">{{ 'COMPARE.R25' | translate }} {{formValue.LoaiSanPham}}</h2>
      <span class="material-icons" (click)="closeAddBox()">
        highlight_off
      </span>
    </div>
    <div class="offcanvas-body compare-list__box">
      <div class="compare-list__card">
        <ng-container *ngFor="let prod of output">
          <div class="compare-item__card" (click)="addItem(prod)" *ngIf="prod.phi !== 0">
            <img src="../../../assets{{prod.image}}">
            <div class="compare-item__card--right">
              <p>{{prod.name}}</p>
              <p>{{ 'COMPARE.R6' | translate }}: {{prod.goi}}</p>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #filterModal let-modal>
  <div class="modal-header filter__head">
    <span class="modal-title">{{ 'COMPARE.R26' | translate }}</span>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <form action="" method="get" #filterForm="ngForm">
      <ng-container *ngIf="filterModalID === 1">
        <span class="filter__title">{{ 'COMPARE.R27' | translate }}</span>
        <ng-container *ngFor="let ncc of nccs">
          <div class="check-field">
            <input type="checkbox" [name]="ncc.ncc" [id]="ncc.ncc" [checked]="filter.ncc === ncc.ncc" (click)="onNccChange(ncc.ncc, $event)" (change)="getCompareKhoangGia()">
            <label [for]="ncc.ncc">{{ ncc.ncc }}</label>
          </div>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="filterModalID === 2">
        <span class="filter__title">{{ 'COMPARE.R29' | translate }}</span>
        <ng-container *ngFor="let pack of packages">
          <div class="check-field">
            <input type="checkbox" [name]="pack.goi" [id]="pack.goi" [checked]="filter.goi === pack.goi" (click)="onPackChange(pack.goi, $event)" (change)="getCompareKhoangGia()">
            <label [for]="pack.goi">{{ pack.ten }}</label>
          </div>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="filterModalID === 3">
        <span class="filter__title">{{ 'COMPARE.R30' | translate }}</span>
        <ng-container *ngFor="let opt of order">
          <div class="check-field">
            <input type="radio" name="sorter" [id]="opt.value">
            <label [for]="opt.value">{{ opt.label }}</label>
          </div>
        </ng-container>
        <br>
        <div class="">
          <span>{{ 'COMPARE.R31' | translate }}</span>
          <select name="filteredNumber" id="filteredNumber" [(ngModel)]="filter.pageSize">
            <option value=6 selected>6</option>
            <option value=9>9</option>
            <option value=12>12</option>
            <option value=15>15</option>
          </select>
          <label for="filteredNumber"> {{ 'COMPARE.R25' | translate }}</label>
        </div>
      </ng-container>
      <div class="filter__footer">
        <button type="submit" class="btn btn-primary" (click)="modal.close('Close click')">
          {{ 'COMPARE.R32' | translate }}
        </button>
        <button type="reset" class="btn btn-outline-dark" (click)="modal.close('Close click')">
          {{ 'COMPARE.R33' | translate }}
        </button>
      </div>
    </form>
  </div>
</ng-template>
