import { COMPARE_CONTENTS, COMPARE_SLIDE } from 'src/utils/compare';
import { GENDERS, HOME_OURPARTNERS } from 'src/utils/constant';
import { ERROR } from 'src/utils/validation';

import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ITCategory } from '@models/category.model';
import {
  ITCompareForm, ITCompareRespon, ITCompareTuoi, ITKhoangGia
} from '@models/compare.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '@services/api.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-compare',
  templateUrl: './compare.component.html',
  styleUrls: ['./compare.component.css'],
})
export class CompareComponent implements OnInit {
  @ViewChild('compare_detail') compare_detail: any
  public isToggle = false
  categories: ITCategory[] = []
  tuois: ITCompareTuoi[] = []
  feeRanges: ITKhoangGia[] = []
  CurrentLang: any;
  public innerWidth: any
  public compare: ITCompareForm = {
    LoaiSanPham: '',
    Tuoi: '',
    KhoangGia: '',
    GioiTinh: '',
  }
  public respon: ITCompareRespon = {
    name: '',
    meta: '',
    logo: '',
    thumb: '',
    minFee: '',
    maxFee: '',
    duration: '',
    policy: '',
    company: '',
  }

  slideConfig = {
    slidesToShow: 3,
    autoplay: true,
    autoplaySpeed: 1000,
    speed: 1000,
    slidesToScroll: 1,
    // dots: true,
    prevArrow: false,
    nextArrow: false,
    responsive: [
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  carouselConfig = {
    slidesToShow: 4,
    autoplay: true,
    autoplaySpeed: 1000,
    speed: 1000,
    slidesToScroll: 1,
    dots: true,
    prevArrow: false,
    nextArrow: false,
    responsive: [
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  slickInit(e: any) { }
  breakpoint(e: any) { }
  afterChange(e: any) { }
  beforeChange(e: any) { }

  constructor(
    private ApiService: ApiService,
    private modalService: NgbModal,
    private _router: Router,
    public _translate: TranslateService,
  ) {this.CurrentLang = _translate.getDefaultLang();
    _translate.onLangChange.subscribe((e:any)=>{
      this.CurrentLang= e.lang;
      this.getCategories()
    }) }

  ngOnInit(): void {
    this.innerWidth = window.innerWidth
    this.getCategories()
    this.getCompareTuoi()
    this.getKhoangGia()
  }

  getCategories() {
    this.ApiService.getCategories(this.CurrentLang).subscribe((data) => {
      this.categories = data
    })
  }

  getKhoangGia() {
    this.ApiService.getKhoangGia().subscribe((data) => {
      this.feeRanges = data
    })
  }

  getCompareTuoi() {
    this.ApiService.getCompareTuoi().subscribe((data) => {
      this.tuois = data
    })
  }

  onToggle(loai: string) {
    this.isToggle = true
    this.compare.LoaiSanPham = loai
  }

  onSubmit(f: any, content: TemplateRef<any>) {
    if (f.invalid) {
      this.modalService.open(content, { centered: true })

      f.controls['gender']!.touched = true
      f.controls['range']!.touched = true
      f.controls['tuoi']!.touched = true
    } else {
      localStorage.setItem('compare-result', JSON.stringify(this.compare))
      this._router.navigateByUrl('/compare-result')
    }
  }

  openModal() {
    this.modalService.open(this.compare_detail, { scrollable: true })
  }

  onClear() {
    this.compare = {
      LoaiSanPham: '',
      Tuoi: 0,
      KhoangGia: 0,
      GioiTinh: '',
    }
  }

  ourPartners = HOME_OURPARTNERS
  genders = GENDERS
  slides = COMPARE_SLIDE
  content = COMPARE_CONTENTS
  error = ERROR
}
