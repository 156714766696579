import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ITFee } from '@models/fee.model';
import { ITVoucher } from '@models/voucher.model';
import { ApiService } from '@services/api.service';
import { FEE_TEMPLATE, VOUCHER_TEMPLATE } from 'src/utils/product';
import { ERROR } from 'src/utils/validation';

@Component({
  selector: 'app-last-step',
  templateUrl: './last-step.component.html',
  styleUrls: ['./last-step.component.css']
})
export class LastStepComponent implements OnInit {
  @Input() magioithieu: string = ''
  @Input() nguoiGioiThieu: string = ''
  @Input() ttoan: any
  @Input() fee: ITFee = {...FEE_TEMPLATE}
  @Input() voucher: ITVoucher = {...VOUCHER_TEMPLATE}

  @Output() onSubmit = new EventEmitter()
  save = 0

  constructor(
    private _service: ApiService,
  ) { }

  ngOnInit(): void {
  }

  // changeMessage(message: string) {
  //   this.data = message;
  //   this.dataChange.emit(this.data);
  // }

  getLauncher(param: string) {
    this._service.getLauncher(param).subscribe(res => {
      this.nguoiGioiThieu = res.ho_ten
    })
  }

  // If magioithieu.length < 1, set nguoiGioiThieu = ''
  getEmptyLauncher() {
    this.magioithieu = ''
    this.nguoiGioiThieu = ''
  }

  // If maVoucher.length < 1, set tongphi = phi
  getOldFee() {
    this.save = 0

    this.fee.tongphi = this.fee.phi
    this.ttoan = this.fee.tongphi = this.fee.phi
  }

  numberWithCommas(price: any) {
    return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }

  // BAOHIEM20220824015137
  getDiscount(param: string) {
    this._service.getDiscount(param).subscribe(res => {
      this.voucher.phanTram = res.phanTram
      this.voucher.status = res.status
      if (res.status) {
        if (this.fee.phi.length > 0) {
          const priceParsed = parseFloat(this.fee.phi.replace(/,/g, ''))

          this.save = (priceParsed * this.voucher.phanTram) / 100
          this.fee.tongphi = priceParsed - this.save

          this.save = this.numberWithCommas(this.save)
          this.fee.tongphi = this.numberWithCommas(this.fee.tongphi)
          this.ttoan = this.fee.tongphi
        }
      } else {
        console.log('Please insert correct voucher')
      }
    })
  }

  error = ERROR
}
