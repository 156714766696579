import * as Highcharts from 'highcharts';
import HC_exporting from 'highcharts/modules/exporting';

import { Component, OnInit } from '@angular/core';
import { ITDashboard } from '@models/dashboard.model';
import { DashboardService } from '@services/dashboard/dashboard.service';

HC_exporting(Highcharts);

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.css']
})
export class OverviewComponent implements OnInit {

  dashboard: ITDashboard = {
    dangkysanpham: 0,
    hopdongchoxuly: 0,
    hopdongthanhcong: 0,
    hopdongdahuy: 0,
    sanpham: 0,
    theloaisanpham: 0,
    voucher: 0,
    tintuc: 0,
    theloaitintuc: 0,
  }
  percent: any
  chartOptions: any

  constructor(
    private _service: DashboardService,
  ) { }

  ngOnInit(): void {
    this.getDashboard()
  }

  getDashboard() {
    this._service.getDashboard().subscribe(res => {
      this.dashboard = res

      setTimeout(() => {
        this.getPieChart();
      }, 500);

      this.percentage()
    })
  }

  percentage() {
    if (this.dashboard.hopdongthanhcong) {
      this.percent = Math.round((100 * this.dashboard.hopdongthanhcong) / this.dashboard.dangkysanpham)
    }
  }

  getPieChart() {
    this.chartOptions = {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie',
        style: {
          fontFamily: 'roboto'
        }
      },
      credits: {
        enabled: false
      },
      title: {
        text: 'Biểu đồ đơn hàng'
      },
      tooltip: {
        pointFormat: '<b>{point.percentage:.1f} %</b>'
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: false,
            format: '<b>{point.name}</b>: {point.percentage:.1f} %',
            style: {
              // color: (Highcharts.theme && Highcharts.theme.colors['contrastTextColor']) || 'black'
              color: 'black'
            }
          },
          showInLegend: true
        },
      },
      navigation: {
        buttonOptions: {
          enabled: true
        }
      },
      legend: {
        align: 'right',
        layout: 'vertical',
        verticalAlign: 'middle',
        x: -400,
        y: 0,
      },
      series: [{
        name: 'Cart',
        colorByPoint: true,
        data: [{
          name: 'Chờ xử lý',
          y: Math.round((100 * this.dashboard.hopdongchoxuly) / this.dashboard.dangkysanpham)
        }, {
          name: 'Bị hủy',
          y: Math.round((100 * this.dashboard.hopdongdahuy) / this.dashboard.dangkysanpham)
        }, {
          name: 'Thành Công',
          y: Math.round((100 * this.dashboard.hopdongthanhcong) / this.dashboard.dangkysanpham)
        }, ],
      },]
    };

    this.createChart(document.getElementById('cartChart'));
  }

  createChart(element: any) {
    // this.chartOptions['title'].text = title || this.chartOptions['title'].text;

    // let e = document.createElement("div");
    // element.nativeElement.appendChild(e);
    // Highcharts.chart(element.nativeElement, this.chartOptions);
    let e = document.createElement("div")
    element.appendChild(e)
    Highcharts.chart(element, this.chartOptions)
  }
}
