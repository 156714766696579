<div class="loading">
  <ngx-skeleton-loader
    *ngIf="innerWidth < 640"
    count="1"
    [theme]="{
      margin: '16px 32px',
      width: '86%',
      height: '72px',
      'border-radius': '2rem'
    }"
  ></ngx-skeleton-loader>
  <br>
  <ngx-skeleton-loader
    count="1"
    [theme]="{
      margin: innerWidth < 640 ? '16px 24px' : '16px',
      width: '240px',
      height: innerWidth < 640 ? '36px' : '32px',
      'border-radius': '5px'
    }"
  ></ngx-skeleton-loader>
  <br>
  <ngx-skeleton-loader
    count="{{innerWidth < 640 ? '6' : '11'}}"
    [theme]="{
      margin: innerWidth < 640 ? '12px 24px' : '12px 16px 6px 32px',
      width: innerWidth < 640 ? '88%' : '44%',
      height: '46px',
      'border-radius': '5px'
    }"
  ></ngx-skeleton-loader>
  <div class="">
    <ngx-skeleton-loader
    *ngIf="innerWidth < 640"
    count="1"
    [theme]="{
      width: '98.5%',
      height: '54px',
      'border-radius': '5px',
      'background-color': '#0462b9',
      'border-top-left-radius': '1rem',
      'border-top-right-radius': '1rem',
      position: 'fixed',
      bottom: '0px',
      'z-index': '3'
    }"
  ></ngx-skeleton-loader>
  </div>
</div>
