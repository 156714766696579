import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-category-carousel-card',
  templateUrl: './category-carousel-card.component.html',
  styleUrls: ['./category-carousel-card.component.css']
})
export class CategoryCarouselCardComponent implements OnInit {
  @Input() icon = ''
  @Input() name = ''
  @Input() logo = ''
  @Input() slogan = ''

  constructor() { }

  ngOnInit(): void {
  }

}
