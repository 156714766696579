
import { Component, OnInit, TemplateRef } from '@angular/core';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { HOME_PROMISES } from 'src/utils/constant';

@Component({
  selector: 'app-active',
  templateUrl: './active.component.html',
  styleUrls: ['./active.component.css']
})
export class ActiveComponent implements OnInit {

  policies = HOME_PROMISES

  constructor(
    private offcanvasService: NgbOffcanvas,
  ) { }

  ngOnInit(): void {
  }

  openModalPromise(promise_modal: TemplateRef<any>) {
    this.offcanvasService.open(promise_modal, {
      position: 'bottom',
      panelClass: 'promise_modal',
      backdropClass: 'backdrop_bg',
    })
  }
}
