<div class="admin-body">
  <div class="row page-titles mx-0">
    <div class="col p-md-0">
      <ol class="breadcrumb">
        <li class="breadcrumb-item" routerLink="admin/dashboard">Quản lý</li>
        <li class="breadcrumb-item">Sản phẩm</li>
        <li class="breadcrumb-item active">{{ prodId ? 'Cập nhật' : 'Thêm mới' }} sản phẩm</li>
      </ol>
    </div>
  </div>

  <div class="admin__container">
    <div class="flex space">
      <span class="admin__container-head">{{ prodId ? 'Cập nhật' : 'Thêm mới' }} sản phẩm</span>
    </div>
    <div #dialogForm>
      <div class="modal-body">
        <form class="row" [formGroup]="form">
          <div class="w-75 text-field">
            <label class="mx-1" for="title">Tên sản phẩm<span class="required">*</span></label>
            <input [readonly]="disableInput" required type="text" id="title" name="title" formControlName="title" />
            <div class="invalid">
              <small *ngIf="form.controls['title'].touched && form.controls['title'].hasError('required')">{{
                error.required | translate }}</small>
            </div>
          </div>

          <div class="w-25 text-field">
            <label class="mx-1" for="code">CODE<span class="required">*</span></label>
            <input required type="text" id="code" name="code" formControlName="code" />
            <div class="invalid">
              <small *ngIf="form.controls['code'].touched && form.controls['code'].hasError('required')">{{
                error.required | translate }}</small>
            </div>
          </div>

          <div class="w-75 text-field">
            <label class="mx-1" for="tenCongTy">Tên công ty<span class="required">*</span></label>
            <select name="tenCongTy" id="tenCongTy" formControlName="tenCongTy">
              <option [value]="''" disabled>Chọn công ty</option>
              <option *ngFor="let opt of companies" [value]="opt.tenCongty">{{opt.tenCongty}}</option>
            </select>
            <div class="invalid">
              <small *ngIf="form.controls['tenCongTy'].touched && form.controls['tenCongTy'].hasError('required')">{{
                error.select | translate }}</small>
            </div>
          </div>

          <div class="w-25 text-field">
            <label class="mx-1" for="categoryID">Loại sản phẩm<span class="required">*</span></label>
            <select name="categoryID" id="categoryID" formControlName="categoryID">
              <option [value]="''" disabled>Chọn loại sản phẩm</option>
              <option *ngFor="let opt of categories" [value]="opt.id">{{opt.name}}</option>
            </select>
            <div class="invalid">
              <small *ngIf="form.controls['categoryID'].touched && form.controls['categoryID'].hasError('required')">{{
                error.select | translate }}</small>
            </div>
          </div>

          <table class="w-50">
            <thead *ngIf="tempImage.logo || tempImage.icon">
              <tr>
                <th class="text-center">Logo</th>
                <th class="text-center">Icon</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="text-center w-50 px-3">
                  <div class="w-100 input-file m-auto" *ngIf="!tempImage.logo">
                    <input type="file" name="logo" id="logo" accept="image/png, image/gif, image/jpeg"
                      (change)="upload($event, 0)" />
                    <label for="logo" class="d-flex flex-column justify-content-center align-items-center input-label"
                      style="height: 95%;">
                      <span class="material-icons" style="font-size: 36px;">add_a_photo</span><br>
                      <span class="input-span">Upload Logo</span>
                    </label>
                  </div>

                  <div *ngIf="tempImage.logo" class="position-relative">
                    <img class="w-100 object-fit-contain" style="height: 120px;"
                    [src]="tempImage.logo" alt="Logo" title="Logo">
                    <button type="button" class="position-absolute btn--cancel" (click)="removeFile(0)" title="Xóa">
                      <span class="material-icons">cancel</span>
                    </button>
                  </div>
                </td>

                <td class="text-center w-50 px-3">
                  <div class="w-100 input-file m-auto" *ngIf="!tempImage.icon">
                    <input type="file" name="icon" id="icon" accept="image/png, image/gif, image/jpeg"
                      (change)="upload($event, 1)" />
                    <label for="icon" class="d-flex flex-column justify-content-center align-items-center input-label"
                      style="height: 95%;">
                      <span class="material-icons" style="font-size: 36px;">add_a_photo</span><br>
                      <span class="input-span">Upload Icon</span>
                    </label>
                  </div>

                  <div *ngIf="tempImage.icon" class="position-relative">
                    <img class="w-100 object-fit-contain" style="height: 120px;"
                    [src]="tempImage.icon" alt="Icon" title="Icon">
                    <button type="button" class="position-absolute btn--cancel" (click)="removeFile(1)" title="Xóa">
                      <span class="material-icons">cancel</span>
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

          <div class="w-50 flex flex-wrap justify-content-between">
            <div class="w-48 text-field">
              <label class="mx-1" for="heSo">Hệ số</label>
              <input required type="text" id="heSo" name="heSo" formControlName="heSo" />
              <div class="invalid">
                <small *ngIf="form.controls['heSo'].touched && form.controls['heSo'].hasError('required')">{{
                  error.required | translate }}</small>
              </div>
            </div>

            <div class="w-48 text-field">
              <label class="mx-1" for="status">Trạng thái</label>
              <select name="status" id="status" formControlName="status">
                <option *ngFor="let opt of boolean" [value]="opt.value">{{opt.vie}}</option>
              </select>
              <div class="invalid"></div>
            </div>

            <div class="w-48 text-field">
              <label class="mx-1" for="noibat">Nổi bật</label>
              <select name="noibat" id="noibat" formControlName="noibat">
                <option *ngFor="let opt of boolean" [value]="opt.value">{{opt.vie}}</option>
              </select>
              <div class="invalid"></div>
            </div>

            <div class="w-48 text-field">
              <label class="mx-1" for="muanhieu">Mua nhiều</label>
              <select name="muanhieu" id="muanhieu" formControlName="muanhieu">
                <option *ngFor="let opt of boolean" [value]="opt.value">{{opt.vie}}</option>
              </select>
              <div class="invalid"></div>
            </div>
          </div>

          <div class="my-3 w-100 text-field position-relative">
            <label class="mx-1">Nội dung</label>
            <div class="NgxEditor__Wrapper">
              <ngx-editor-menu [editor]="noiDung" [toolbar]="toolbar">
              </ngx-editor-menu>
              <ngx-editor [editor]="noiDung" formControlName="noiDung">
              </ngx-editor>
            </div>
            <div class="fake-icon position-absolute" (click)="fileNoiDung.click()">
              <i class="fa fa-file-image fa-lg"></i>
            </div>
            <input type="file" style="display: none;" onclick="this.value=null" accept="image/png, image/gif, image/jpeg"
            (change)="uploadInnerImage($event, 'noiDung')" #fileNoiDung>
          </div>

          <div class="my-3 w-100 text-field position-relative">
            <label class="mx-1">Giới thiệu</label>
            <div class="NgxEditor__Wrapper">
              <ngx-editor-menu [editor]="gioiThieu" [toolbar]="toolbar">
              </ngx-editor-menu>
              <ngx-editor [editor]="gioiThieu" formControlName="gioiThieu">
              </ngx-editor>
            </div>
            <div class="fake-icon position-absolute" (click)="fileGioiThieu.click()">
              <i class="fa fa-file-image fa-lg"></i>
            </div>
            <input type="file" style="display: none;" onclick="this.value=null" accept="image/png, image/gif, image/jpeg"
            (change)="uploadInnerImage($event, 'gioiThieu')" #fileGioiThieu>
          </div>

          <div class="my-3 w-100 text-field position-relative">
            <label class="mx-1">Quy trình</label>
            <div class="NgxEditor__Wrapper">
              <ngx-editor-menu [editor]="quyTrinh" [toolbar]="toolbar">
              </ngx-editor-menu>
              <ngx-editor [editor]="quyTrinh" formControlName="quyTrinh">
              </ngx-editor>
            </div>
            <div class="fake-icon position-absolute" (click)="fileQuyTrinh.click()">
              <i class="fa fa-file-image fa-lg"></i>
            </div>
            <input type="file" style="display: none;" onclick="this.value=null" accept="image/png, image/gif, image/jpeg"
            (change)="uploadInnerImage($event, 'quyTrinh')" #fileQuyTrinh>
          </div>

          <div class="my-3 w-100 text-field position-relative">
            <label class="mx-1">Lợi ích</label>
            <div class="NgxEditor__Wrapper">
              <ngx-editor-menu [editor]="loiIch" [toolbar]="toolbar">
              </ngx-editor-menu>
              <ngx-editor [editor]="loiIch" formControlName="loiIch">
              </ngx-editor>
            </div>
            <div class="fake-icon position-absolute" (click)="fileHoaHong.click()">
              <i class="fa fa-file-image fa-lg"></i>
            </div>
            <input type="file" style="display: none;" onclick="this.value=null" accept="image/png, image/gif, image/jpeg"
            (change)="uploadInnerImage($event, 'hoaHong')" #fileHoaHong>
          </div>

          <div id="hoaHong" class="my-3 w-100 text-field position-relative">
            <label class="mx-1">Hoa hồng</label>
            <div class="NgxEditor__Wrapper">
              <ngx-editor-menu [editor]="hoaHong" [toolbar]="toolbar">
              </ngx-editor-menu>
              <ngx-editor [editor]="hoaHong" formControlName="hoaHong">
              </ngx-editor>
            </div>
            <div class="fake-icon position-absolute" (click)="fileHoaHong.click()">
              <i class="fa fa-file-image fa-lg"></i>
            </div>
            <input type="file" style="display: none;" onclick="this.value=null" accept="image/png, image/gif, image/jpeg"
            (change)="uploadInnerImage($event, 'hoaHong')" #fileHoaHong>
          </div>
        </form>
      </div>

      <div class="modal-footer">
        <button type="button" class="mx-3 btn btn-primary flex space"
          [disabled]="invalidForm()" (click)="onSave()">
          <span class="material-icons text-light">save</span>
          <p class="m-0 px-1">Lưu</p>
        </button>

        <button type="button" class="btn btn-danger flex space">
          <span class="material-icons text-light">close</span>
          <p class="m-0 px-1">Hủy bỏ</p>
        </button>
      </div>
    </div>
  </div>
</div>
