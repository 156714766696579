<div class="admin-body">
  <div class="row page-titles mx-0">
    <div class="col p-md-0">
      <ol class="breadcrumb">
        <li class="breadcrumb-item" routerLink="admin/dashboard">Quản lý</li>
        <li class="breadcrumb-item active">Sản phẩm</li>
      </ol>
    </div>
  </div>

  <div class="admin__container">
    <div class="flex space">
      <span class="admin__container-head">Bảng sản phẩm</span>
      <button type="button" class="btn btn-success btn-sm" (click)="onRouting()">Thêm mới</button>
    </div>

    <table class="table table-striped">
      <thead>
        <tr>
          <th scope="col" sortable="id" (sort)="onSort()">#</th>
          <!-- <th scope="col" sortable="stt" (sort)="onSort()">SST</th> -->
          <th scope="col" sortable="name" (sort)="onSort()">Tên sản phẩm</th>
          <th scope="col" sortable="name" (sort)="onSort()">Mã danh mục</th>
          <th scope="col" class="start">Icon</th>
          <th scope="col" sortable="createdDate" (sort)="onSort()">Ngày tạo</th>
          <!-- <th scope="col">Người tạo</th> -->
          <th scope="col">Trạng thái</th>
          <th scope="col">Mua nhiều</th>
          <th scope="col">Nổi bật</th>
          <th scope="col">Chức năng</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let prod of products; index as i">
          <th scope="row">{{ i + 1 }}</th>
          <!-- <th scope="row">{{ prod.stt }}</th> -->
          <td>{{ prod.name }}</td>
          <td>{{ prod.categoryID }}</td>
          <td>
            <img src="../../../assets{{prod.icon}}" alt="{{prod.name}} logo" style="width: 32px">
          </td>
          <td>{{ prod.createdDate | date:'dd/MM/yyyy' }}</td>
          <!-- <td>{{ prod.createdBy }}</td> -->
          <td>
            <span [ngClass]="[prod.status ? 'material-icons true' : 'material-icons false']">{{ prod.status ? 'check_circle' : 'cancel' }} </span>
          </td>
          <td>
            <span [ngClass]="[prod.muanhieu ? 'material-icons true' : 'material-icons false']">{{ prod.muanhieu ? 'check_circle' : 'cancel' }} </span>
          </td>
          <td>
            <span [ngClass]="[prod.noibat ? 'material-icons true' : 'material-icons false']">{{ prod.noibat ? 'check_circle' : 'cancel' }} </span>
          </td>
          <td>
            <div class="flex around">
              <button type="button" class="btn btn-primary btn-sm" routerLink="/admin/product-edit/{{prod.id}}">Sửa</button>
              <button type="button" class="btn btn-danger btn-sm" (click)="dialogHandler('delete', prod, confirmDelete)">Xoá</button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <ngx-skeleton-loader
      *ngIf="products.length < 1"
      count="12"
      [theme]="{
        margin: '4px 0',
        width: '100%',
        height: '54px',
        'border-radius': '8px'
      }"
    ></ngx-skeleton-loader>
  </div>
</div>

<ng-template #confirmDelete let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-title">Xác nhận xoá bài viết</h4>
  </div>
  <div class="modal-body">
    <p>
      <strong>Bạn có chắc chắn muốn xoá bài viết
        <span class="text-primary">"{{ product!.name }}"</span>
        ?
      </strong>
    </p>
    <p>Tất cả thông tin liên quan đến bài viết này sẽ bị xóa vĩnh viễn.</p>
    <span class="text-danger">Và không thể hoàn tác thao tác này.</span>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('cancel click')">Hủy</button>
    <button type="button" class="btn btn-danger" (click)="onDelete(product.id)">Xóa</button>
  </div>
</ng-template>
