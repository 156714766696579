import { filter } from 'rxjs';

import { Component, Input } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Event as NavigationEvent, NavigationEnd, Router } from '@angular/router';
import { environment } from '@environments/environment';
import { ITCategory } from '@models/category.model';
import { ITProduct } from '@models/product.model';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from '@services/api.service';
import { ProtectService } from '@services/protect/protect.service';
import { TokenService } from '@services/token.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  prodEnv: boolean = environment.production
  title = environment.title;
  isLoggedIn = false;
  categories: ITCategory[] = []
  products: ITProduct[] = []
  @Input() toggle = false
  @Input() icon = 'menu'
  isHome: boolean = false
  isAdmin: boolean = false
  isCol: boolean = false
  hiddenHeader: boolean = false
  hiddenFooter: boolean = false
  hiddenTopFooter: boolean = false
  CurrentLang:any;

  event$

  constructor(
    private titleService: Title,
    private ApiService: ApiService,
    private tokenService: TokenService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private meta: Meta,
    public translate: TranslateService,
  ) {
    environment.production && console.log(environment.description)

    translate.addLangs(['vi', 'en', 'fr','zh-Hans']);
    translate.setDefaultLang('vi');

    const browserLang = translate.getBrowserLang();
    translate.use('vi');
    translate.onLangChange.subscribe((e:any)=>{
      this.CurrentLang= e.lang;
      this.getProducts()
      this.getCategories()
    });
    this.event$
      = this.router.events
        .subscribe(
          (event: NavigationEvent) => {
            // scrolltop khi chuyen trang tin tuc, home
            if (event instanceof NavigationEnd) {
             // if (event.url.includes("/news/") || event.url == '/') {
                setTimeout(() => {
                  document.documentElement.scrollTop = 0;
                }, 0);
             // }
            }
          });

    this.router.events.pipe(filter((event: any) => event instanceof NavigationEnd),)
      .subscribe(() => {

        var rt = this.getChild(this.activatedRoute)

        rt.data.subscribe((data: any) => {
          this.titleService.setTitle(data.title)

          if (data.descrption) {
            this.meta.updateTag({ name: 'description', content: data.descrption })
          } else {
            this.meta.removeTag("name='description'")
          }

          if (data.ogUrl) {
            this.meta.updateTag({ property: 'og:url', content: data.ogUrl })
          } else {
            this.meta.updateTag({ property: 'og:url', content: this.router.url })
          }

          if (data.ogTitle) {
            this.meta.updateTag({ property: 'og:title', content: data.ogTitle })
          } else {
            this.meta.removeTag("property='og:title'")
          }

          if (data.ogDescription) {
            this.meta.updateTag({ property: 'og:description', content: data.ogDescription })
          } else {
            this.meta.removeTag("property='og:description'")
          }

          if (data.ogImage) {
            this.meta.updateTag({ property: 'og:image', content: data.ogImage })
          } else {
            this.meta.removeTag("property='og:image'")
          }

          data.isHome ? (this.isHome = true) : (this.isHome = false)
          data.isAdmin ? (this.isAdmin = true) : (this.isAdmin = false)
          data.isCol ? (this.isCol = true) : (this.isCol = false)
          data.hiddenHeader ? (this.hiddenHeader = true) : (this.hiddenHeader = false)
          data.hiddenFooter ? (this.hiddenFooter = true) : (this.hiddenFooter = false)
          data.hiddenTopFooter ? (this.hiddenTopFooter = true) : (this.hiddenTopFooter = false)
        })
      })
  }

  ngOnInit(): void {
    this.titleService.setTitle(this.title);
    this.getCategories();
    this.getProducts();
    this.onFilterData();
    this.isLoggedIn = !!this.tokenService.getToken();
    if (this.isLoggedIn) {
      const user = this.tokenService.getUser();
      // console.log(this.isLoggedIn, this.tokenService.getToken())
      // this.username = user.username;
    }
  }

  getChild(activatedRoute: ActivatedRoute): any {
    if (activatedRoute.firstChild) {
      return this.getChild(activatedRoute.firstChild);
    } else {
      return activatedRoute;
    }

  }

  onToggle() {
    this.toggle === false ?
      this.toggle = true :
      this.toggle = false

    this.toggle ? this.icon = 'close' : this.icon = 'menu'
  }

  getCategories() {
    this.ApiService.getCategories(this.CurrentLang).subscribe(data => {
      this.categories = data;
    })
  }

  getProducts() {
    this.ApiService.getProducts(this.CurrentLang).subscribe(data => {
      this.products = data;
      this.onFilterData()
      // this.prodEnv && this.onFilterData()
    })
  }

  onFilterData() {
    let filteredProd = this.products?.filter(prod => prod.status)
    this.products = filteredProd

    let tempCateID: any[] = []
    filteredProd.map(item => {
      const exist = tempCateID.find(i => i === item.categoryID)
      exist || tempCateID.push(item.categoryID)
    })

    let filteredCate = this.categories.filter(cate =>
      cate.id === tempCateID[0] || cate.id === tempCateID[1] || cate.id === tempCateID[2] || cate.id === tempCateID[3]
    )
    this.categories = filteredCate
  }

  logout(): void {
    this.tokenService.signOut();
    window.location.reload();
  }
}
