import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-input-image',
  templateUrl: './input-image.component.html',
  styleUrls: ['./input-image.component.css']
})
export class InputImageComponent implements OnInit {
  @Input() label= ''

  constructor() { }

  ngOnInit(): void {
  }

}
