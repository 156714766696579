<div class="admin-body">
  <div class="row page-titles mx-0">
    <div class="col p-md-0">
      <ol class="breadcrumb">
        <li class="breadcrumb-item" routerLink="admin/dashboard">Quản lý</li>
        <li class="breadcrumb-item active">Bài viết</li>
      </ol>
    </div>
  </div>

  <div class="admin__container">
    <div class="flex space">
      <span class="admin__container-head">Danh sách bài viết</span>
      <button type="button" class="btn btn-success btn-sm" (click)="dialogHandler('add', null, dialogForm)">Thêm mới</button>
    </div>

    <table class="table table-striped">
      <thead>
        <tr>
          <th scope="col" sortable="id" (sort)="onSort()">#</th>
          <th scope="col" sortable="name" (sort)="onSort()">Ảnh</th>
          <th scope="col" sortable="name" (sort)="onSort()">Tên bài viết</th>
          <th scope="col" sortable="name" (sort)="onSort()">Mô tả</th>
          <th scope="col" sortable="name" (sort)="onSort()">Trạng thái</th>
          <th scope="col">Chức năng</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of contents; index as i">
          <th scope="row">{{ i + 1 }}</th>
          <td class="content__img">
            <img [src]="item.image" [alt]="item.name">
          </td>
          <td class="start content__name">
            <span>{{ item.name }}</span>
          </td>
          <td class="start content__desc">
            <span>{{ item.description }}</span>
          </td>
          <td>
            <span class="material-icons" [ngClass]="[item.status ? 'true' : 'false']">{{ item.status ? 'check_circle' : 'cancel' }}</span>
          </td>
          <td>
            <span class="material-icons" title="Xem" (click)="dialogHandler('view', item, dialogForm)">visibility</span>
            <span class="material-icons" title="Sửa" routerLink="/admin/content-edit/{{item.id}}">edit</span>
            <span class="material-icons" title="Xóa" (click)="dialogHandler('delete', item, confirmDelete)">delete</span>
          </td>
        </tr>
      </tbody>
    </table>

    <ngx-skeleton-loader
      *ngIf="contents.length < 1"
      count="9"
      [theme]="{
        margin: '4px 0',
        width: '100%',
        height: '54px',
        'border-radius': '8px'
      }"
    ></ngx-skeleton-loader>
  </div>
</div>

<ng-template #confirmDelete let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-title">Xác nhận xoá bài viết</h4>
  </div>
  <div class="modal-body">
    <p>
      <strong>Bạn có chắc chắn muốn xoá bài viết
        <span class="text-primary">"{{ content!.name }}"</span>
        ?
      </strong>
    </p>
    <p>Tất cả thông tin liên quan đến bài viết này sẽ bị xóa vĩnh viễn.</p>
    <span class="text-danger">Và không thể hoàn tác thao tác này.</span>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('cancel click')">Hủy</button>
    <button type="button" class="btn btn-danger" (click)="onDelete(content.id)">Xóa</button>
  </div>
</ng-template>

<ng-template #validWarming let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Thông báo</h4>
  </div>
  <div class="modal-body">
    <p>Vui lòng nhập đúng, đầy đủ phiếu thông tin trước khi lưu bài viết !</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="modal.close('Close click')">Đồng ý</button>
  </div>
</ng-template>

<ng-template #dialogForm let-modal>
	<div class="modal-header px-4">
		<h4 class="modal-title m-0 fs-4">{{ dialogName }} nội dung bài viết</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
	</div>
	<div class="modal-body">
    <form class="dialog" [formGroup]="form">
      <div class="text-field">
        <label for="title">Tên bài viết<span class="required">*</span></label>
        <input [readonly]="disableInput" required type="text" id="title" name="title" formControlName="title" />
        <div class="invalid">
          <small *ngIf="form.controls['title'].touched && form.controls['title'].hasError('required')">{{error.required}}</small>
        </div>
      </div>

      <ngb-accordion #acc="ngbAccordion" activeIds="ngb-panel-0" *ngIf="content.image">
        <ngb-panel title="Xem ảnh">
          <ng-template ngbPanelContent>
            <img [src]="content.image">
          </ng-template>
        </ngb-panel>
      </ngb-accordion>

      <div class="text-field upload mt-3" *ngIf="this.dialogType !== 'view'">
        <label for="image">Image<span class="required">*</span></label>
        <input [disabled]="disableInput" type="file" name="image" id="image" accept="image/png, image/gif, image/jpeg" aria-label="Tải lên ảnh danh mục"
          (change)="selectFile($event)" />
        <div class="invalid">
          <small *ngIf="form.controls['image'].touched && form.controls['image'].hasError('required')">{{error.required}}</small>
        </div>
      </div>

      <div class="text-field">
        <label for="status">Trạng thái</label>
        <select name="status" id="status" formControlName="status" *ngIf="!disableInput">
          <option *ngFor="let opt of boolean" [value]="opt.value">{{opt.vie}}</option>
        </select>
        <input [readonly]="disableInput" type="text" id="status" name="status" formControlName="status" *ngIf="disableInput" />
        <div class="invalid"></div>
      </div>

      <div class="text-field">
        <label for="description">Mô tả<span class="required">*</span></label>
        <input [readonly]="disableInput" required type="text" id="description" name="description" formControlName="description" />
        <div class="invalid">
          <small *ngIf="form.controls['description'].touched && form.controls['description'].hasError('required')">{{error.required}}</small>
        </div>
      </div>

      <div class="text-field">
        <label>Nội dung<span class="required">*</span></label>
        <div class="NgxEditor__Wrapper">
          <ngx-editor-menu [editor]="detail" [toolbar]="toolbar">
          </ngx-editor-menu>
          <ngx-editor [disabled]="disableInput" [editor]="detail" formControlName="detail">
          </ngx-editor>
        </div>
      </div>
    </form>

    <!-- <form [formGroup]="test">
      <div class="editor">
        <ngx-editor-menu [editor]="editor" [toolbar]="toolbar">
        </ngx-editor-menu>
        <ngx-editor [editor]="editor" formControlName="editorContent">
        </ngx-editor>
      </div>
    </form> -->
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-primary flex space" (click)="onSave(); modal.close('Close click')" [disabled]="form.invalid" *ngIf="this.dialogType === 'edit'">
      <span class="material-icons text-light">save</span>
      <p class="m-0 px-1">Lưu</p>
    </button>
		<button type="button" class="btn btn-danger flex space" (click)="modal.close('Close click')">
      <span class="material-icons text-light">close</span>
      <p class="m-0 px-1">Đóng</p>
    </button>
	</div>
</ng-template>
