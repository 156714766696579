<div class="text-field">
  <label [for]="name">{{ label }}
    <span [ngClass]="required ? 'required' : ''">*</span>
  </label>
  <input
    [type]="type"
    [id]="name"
    [name]="name"
    [required]="required"
    [placeholder]="placeholder"
    [class.is-invalid]="control.errors && control.touched"
    [pattern]="pattern"
    [(ngModel)]="value"
    />
    <!-- [formControlName]="controlName" -->
  <div class="invalid" *ngIf="control.errors && control.touched">
    <small>{{ errorMessage }}</small>
  </div>
</div>
