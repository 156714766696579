import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ERROR } from 'src/utils/validation';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.css']
})
export class InputComponent implements OnInit {
  public error = ERROR
  @Input() type: string = 'text'
  @Input() label: string = ''
  @Input() name: string = ''
  @Input() placeholder: string = ''
  @Input() pattern: any = ''
  @Input() value: string | Date = ''
  @Input() required: boolean = false
  @Input() controlName: string = ''
  @Input() formGroup!: FormGroup;
  @Input() validationMessages: { [key: string]: string } = {}

  constructor() { }

  ngOnInit(): void {
  }

  get control(): FormControl {
    return this.formGroup.get(this.controlName) as FormControl;
  }

  get errorMessage(): string | null {
    for (const errorKey in this.control.errors) {
      if (this.control.errors.hasOwnProperty(errorKey) && this.validationMessages[errorKey]) {
        return this.validationMessages[errorKey];
      }
    }

    return null;
  }

}
