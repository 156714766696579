<div class="miccare-payment">
  <div class="mobile-hidden">
    <div class="form__switcher">
      <span class="switcher__btn" [ngClass]="isToggle === false ? 'switcher__btn--actived' : ''"
        (click)="isToggle = false">{{ 'PRODUCT.INSURANCE_INFO' | translate }}</span>
      <span class="switcher__btn" [ngClass]="isToggle ? 'switcher__btn--actived' : ''" (click)="isToggle = true">
        {{ 'PRODUCT.HEALTH_INFO' | translate }}
      </span>
    </div>
  </div>

  <div class="step__controller">
    <app-step-controller [steps]="steps" [step]="25" [stepValue]="stepValue" [isWebview]="isWebview">
    </app-step-controller>
  </div>

  <form #f="ngForm">
    <div id="classic-form" class="form--left" *ngIf="isToggle === false">
      <div id="step_1" [ngClass]="stepValue == 1 ? 'step' : 'hidden'">
        <app-form-title [icon]="'loyalty'" [label]="'PRODUCT.PROD_VOLUME'|translate"></app-form-title>
        <div class="form-group">
          <div class="date-info">
            <div class="text-field">
              <label for="fromDate">{{ 'COMMON.FROM_DATE' | translate }}<span class="required">*</span></label>
              <input required autocomplete="off" [type]="isWebview ? 'text' : 'date'" id="fromDate" name="fromDate" [value]="fromDate"
                [(ngModel)]="fromDate" [min]="isWebview?null:now" [pattern]="isWebview ? pattern.DATE : pattern.ALL" (ngModelChange)="onChangeFromDate()" />
                <span *ngIf="f.controls['fromDate']?.valid&&isWebview&&validDate" class="input--check"><i class="fa-solid fa-circle-check"></i></span>
              <div class="invalid">
                <small *ngIf="f.controls['fromDate']?.invalid || fromDate.length < 1">{{error.required|translate}}</small>
                <small *ngIf="f.controls['fromDate']?.valid && fromDate.length > 0 && fromDate < (isWebview?null:now)">{{error.min_date|translate}}</small>
                <small *ngIf="f.controls['fromDate']?.valid &&!validDate&&isWebview">{{error.min_date|translate}}</small>
              </div>
            </div>
            <div class="text-field">
              <label for="toDate">{{ 'COMMON.TO_DATE' | translate }}</label>
              <input readonly [type]="isWebview ? 'text' : 'date'" id="toDate" name="toDate" [value]="toDate"
                [(ngModel)]="toDate" />
                <span *ngIf="f.controls['toDate']?.valid" class="input--check"><i class="fa-solid fa-circle-check"></i></span>
              <div class="invalid"></div>
            </div>
          </div>
          <div class="text-field">
            <label for="ng_sinh">{{ 'COMMON.BIRTHDAY' | translate }}<span class="required">*</span></label>
            <input required autocomplete="off" [type]="isWebview ? 'text' : 'date'" [max]="now" id="ng_sinh" name="ng_sinh"
              [value]="kh.ng_sinh" [(ngModel)]="kh.ng_sinh" placeholder="20-02-2002"  [pattern]="isWebview ? pattern.DATE : pattern.ALL" (ngModelChange)="getFee()" />
              <span *ngIf="f.controls['ng_sinh']?.valid&&isWebview" class="input--check"><i class="fa-solid fa-circle-check"></i></span>
            <div class="invalid">
              <small *ngIf="f.controls['ng_sinh']?.invalid && f.controls['ng_sinh']?.touched && kh.ng_sinh.length < 1">
                {{error.shortReq|translate}}
              </small>
              <small *ngIf="f.controls['ng_sinh']?.errors?.['pattern'] && isWebview">{{error.date|translate}}</small>
            </div>
          </div>
          <div class="text-field">
            <label for="nhom">{{ 'PRODUCT.PROGRAM' | translate }}</label>
            <select name="nhom" id="nhom" [(ngModel)]="bill.nhom" (ngModelChange)="getFee()">
              <option *ngFor="let opt of respons" [value]="opt.value">{{opt.label|translate}}</option>
            </select>
            <span *ngIf="f.controls['nhom']?.valid" class="input--check"><i class="fa-solid fa-circle-check"></i></span>
            <div class="invalid"></div>
          </div>
        </div>

        <span class="miccare__decs">{{ 'PRODUCT.MAIN_POLICY' | translate }}</span>
        <div class="miccare__dkbs">
          <div class="miccare__dkbs-item" *ngFor="let rule of main">
            <div class="check-field">
              <input type="checkbox" checked disabled />
              <label>{{ rule.label|translate }}</label>
            </div>
            <div class="text-field" *ngFor="let val of rule.values"
              [ngClass]="bill.nhom === val.groupID ? '' : 'other-hidden'">
              <span class="disabled check">{{ val.fee }}</span>
            </div>
          </div>
        </div>

        <span class="miccare__decs">{{ 'PRODUCT.ADDITION_POLICY' | translate }}</span>
        <div class="miccare__dkbs">
          <div class="miccare__dkbs-item">
            <div class="check-field">
              <input type="checkbox" id="bs1" name="bs1"
                (click)="bill.gcn_miccare_dkbs.bs1 === 'K' ? bill.gcn_miccare_dkbs.bs1 = 'C' : bill.gcn_miccare_dkbs.bs1 = 'K'"
                (change)="getFee()" />
              <label for="bs1">{{ extension[0].label|translate }}</label>
            </div>
            <div class="text-field" *ngFor="let val of extension[0].values"
              [ngClass]="bill.nhom === val.groupID ? '' : 'other-hidden'">
              <span class="disabled check">{{ val.fee }}</span>
            </div>
          </div>
          <div class="miccare__dkbs-item">
            <div class="check-field">
              <input type="checkbox" id="bs2" name="bs2"
                (click)="bill.gcn_miccare_dkbs.bs2 === 'K' ? bill.gcn_miccare_dkbs.bs2 = 'C' : bill.gcn_miccare_dkbs.bs2 = 'K'"
                (change)="getFee()" />
              <label for="bs2">{{ extension[1].label|translate }}</label>
            </div>
            <div class="text-field" *ngFor="let val of extension[1].values"
              [ngClass]="bill.nhom === val.groupID ? '' : 'other-hidden'">
              <span class="disabled check">{{ val.fee }}</span>
            </div>
          </div>
          <div class="miccare__dkbs-item" *ngIf="+bill.nhom > 2 && 2022 - khYear > 17">
            <div class="check-field">
              <input type="checkbox" id="bs3" name="bs3"
                (click)="bill.gcn_miccare_dkbs.bs3 === 'K' ? bill.gcn_miccare_dkbs.bs3 = 'C' : bill.gcn_miccare_dkbs.bs3 = 'K'"
                (change)="getFee()" />
              <label for="bs3">{{ extension[2].label|translate }}</label>
            </div>
            <div class="text-field" *ngFor="let val of extension[2].values"
              [ngClass]="bill.nhom === val.groupID ? '' : 'other-hidden'">
              <span class="disabled check">{{ val.fee }}</span>
            </div>
          </div>
          <div class="miccare__dkbs-item">
            <div class="check-field">
              <input type="checkbox" id="bs4" name="bs4"
                (click)="bill.gcn_miccare_dkbs.bs4 === 'K' ? bill.gcn_miccare_dkbs.bs4 = 'C' : bill.gcn_miccare_dkbs.bs4 = 'K'"
                (change)="getFee()" />
              <label for="bs4">{{ extension[3].label|translate }}</label>
            </div>
            <div class="text-field" *ngFor="let val of extension[3].values"
              [ngClass]="bill.nhom === val.groupID ? '' : 'other-hidden'">
              <span class="disabled check">{{ val.fee }}</span>
            </div>
          </div>
        </div>

        <div class="form-group">
          <div class="date-info">
            <div class="text-field">
              <label for="phi">{{ 'PAYMENT.FEE' | translate }}</label>
              <input autocomplete="off" type="text" id="phi" name="phi" readonly [value]="fee.phi" [(ngModel)]="fee.phi"/>
              <span *ngIf="f.controls['phi']?.valid&&f.controls['phi']?.dirty" class="input--check"><i class="fa-solid fa-circle-check"></i></span>
              <div class="invalid"></div>
            </div>
            <div class="text-field">
              <label for="thue">{{ 'PAYMENT.TAX' | translate }}</label>
              <input autocomplete="off" type="text" id="thue" name="thue" readonly [value]="fee.thue" [(ngModel)]="fee.thue"/>
              <span *ngIf="f.controls['thue']?.valid&&f.controls['thue']?.dirty" class="input--check"><i class="fa-solid fa-circle-check"></i></span>
              <div class="invalid"></div>
            </div>
          </div>
          <div class="text-field">
            <label for="tongphi">{{ 'PAYMENT.TOTAL' | translate }}</label>
            <input autocomplete="off" type="text" id="tongphi" name="tongphi" readonly [value]="fee.tongphi" [(ngModel)]="fee.tongphi">
            <span *ngIf="f.controls['tongphi']?.valid&&f.controls['tongphi']?.dirty" class="input--check"><i class="fa-solid fa-circle-check"></i></span>
            <div class="invalid"></div>
          </div>
        </div>

        <ng-container [ngTemplateOutlet]="prodStep"></ng-container>
      </div>

      <div id="step_2" [ngClass]="stepValue == 2 ? 'step' : 'hidden'">
        <app-form-title [icon]="'badge'" [label]="'PRODUCT.PROTECTOR'|translate"></app-form-title>

        <div class="form-group">
          <div class="check-field baby">
            <input type="checkbox" id="is_baby" name="is_baby" (click)="isBaby()"/>
            <label for="is_baby">{{ 'PRODUCT.CHILD_BUY' | translate }}</label>
          </div>
          <div class="text-field" [ngClass]="is_baby ? '' : 'parent-contract--hidden'">
            <label for="so_hd_bm">{{ 'PRODUCT.PARENTS_CONTRACT_ID' | translate }}<span class="required">*</span></label>
            <input [required]="is_baby" autocomplete="off" type="text" id="so_hd_bm" name="so_hd_bm"
              [value]="bill.so_hd_bm" [(ngModel)]="bill.so_hd_bm" />
              <span *ngIf="f.controls['so_hd_bm']?.valid" class="input--check"><i class="fa-solid fa-circle-check"></i></span>
            <div class="invalid">
              <small
                *ngIf="f.controls['so_hd_bm']?.invalid && f.controls['so_hd_bm']?.touched">{{error.required|translate}}</small>
            </div>
          </div>
          <div class="text-field">
            <label for="cmt">{{ 'COMMON.IDENTIFY_CARD' | translate }}<span class="required">*</span></label>
            <input required min="9" max="12" [pattern]="pattern.CCCD" autocomplete="off" type="text" id="cmt" name="cmt"
              [value]="kh.cmt" [(ngModel)]="kh.cmt" />
              <span *ngIf="f.controls['cmt']?.valid" class="input--check"><i class="fa-solid fa-circle-check"></i></span>
            <div class="invalid">
              <small *ngIf="f.controls['cmt']?.invalid && f.controls['cmt']?.touched">{{error.required|translate}}</small>
              <small *ngIf="f.controls['cmt']?.errors?.['pattern']">{{error.identify|translate}}</small>
            </div>
          </div>
          <div class="text-field">
            <label for="mobi">{{ 'COMMON.PHONE_NUMBER' | translate }}<span class="required">*</span></label>
            <input required maxlength="10" [pattern]="pattern.PHONE" autocomplete="off" type="tel" id="mobi" name="mobi"
              [value]="kh.mobi" [(ngModel)]="kh.mobi" />
              <span *ngIf="f.controls['mobi']?.valid" class="input--check"><i class="fa-solid fa-circle-check"></i></span>
            <div class="invalid">
              <small
                *ngIf="f.controls['mobi']?.invalid && f.controls['mobi']?.touched && kh.mobi.length < 1">{{error.required|translate}}</small>
              <small *ngIf="f.controls['mobi']?.errors?.['pattern']">{{error.phone|translate}}</small>
            </div>
          </div>
          <div class="text-field">
            <label for="ten">{{ 'COMMON.FULL_NAME' | translate }}<span class="required">*</span></label>
            <input required autocomplete="off" type="text" id="ten" name="ten" [value]="kh.ten"
              [(ngModel)]="kh.ten" (blur)="onCapitalize(1);onNumberIn(1)" />
              <span *ngIf="f.controls['ten']?.valid" class="input--check"><i class="fa-solid fa-circle-check"></i></span>
            <div [class]="isNumberIn?'alertMessage':'invalid'">
              <small
                *ngIf="f.controls['ten']?.invalid && f.controls['ten']?.touched && kh.ten.length < 1">{{error.required|translate}}</small>
                <small *ngIf="isNumberIn">{{error.number_in|translate}}</small>
            </div>
          </div>
          <div class="text-field">
            <label for="gioi">{{ 'COMMON.GENDER' | translate }}</label>
            <select name="gioi" id="gioi" [(ngModel)]="kh.gioi">
              <option value="" selected disabled>{{ 'PRODUCT.SELECT_GENDER' | translate }}...</option>
              <option *ngFor="let opt of genders" [value]="opt.value">{{opt.label|translate}}</option>
            </select>
            <span *ngIf="f.controls['gioi']?.valid&&f.controls['gioi']?.dirty" class="input--check"><i class="fa-solid fa-circle-check"></i></span>
            <div class="invalid"></div>
          </div>
          <div class="text-field">
            <label for="email">Email<span class="required">*</span></label>
            <input required [email]="pattern.EMAIL" autocomplete="off" type="email" id="email" name="email"
              [value]="kh.email" [(ngModel)]="kh.email" />
              <span *ngIf="f.controls['email']?.valid" class="input--check"><i class="fa-solid fa-circle-check"></i></span>
            <div class="invalid">
              <small
                *ngIf="f.controls['email']?.invalid && f.controls['email']?.touched && kh.email.length < 1">{{error.required|translate}}</small>
              <small *ngIf="f.controls['email']?.errors?.['email']">{{error.email|translate}}</small>
            </div>
          </div>
          <div class="text-field">
            <label for="dchi">{{ 'COMMON.ADDRESS' | translate }}<span class="required">*</span></label>
            <input required autocomplete="off" type="text" id="dchi" name="dchi" [value]="kh.dchi"
              [(ngModel)]="kh.dchi" />
              <span *ngIf="f.controls['dchi']?.valid" class="input--check"><i class="fa-solid fa-circle-check"></i></span>
            <div class="invalid">
              <small *ngIf="f.controls['dchi']?.invalid && f.controls['dchi']?.touched">{{error.required|translate}}</small>
            </div>
          </div>
        </div>
        <div class="full">
          <div class="text-field">
            <label for="ng_huong">{{ 'PRODUCT.BENEFICIARY' | translate }}</label>
            <textarea id="ng_huong" name="ng_huong" rows="3" cols="20"
              [placeholder]="'PRODUCT.INFO_PLACEHOLDER'|translate" [(ngModel)]="bill.ng_huong"></textarea>
          </div>
        </div>

        <div class="check-field">
          <label for="dbhm">{{ 'PRODUCT.DIFFERENT_CLIENT' | translate }}</label>
          <input type="checkbox" id="dbhm" name="dbhm"
            (click)="form.dbhm === 'K' ? form.dbhm = 'C' : form.dbhm = 'K'" />
        </div>
        <app-form-title [icon]="'badge'" [label]="'PRODUCT.CLIENT_INFO'|translate" *ngIf="form.dbhm === 'C'"></app-form-title>
        <div class="form-group" *ngIf="form.dbhm === 'C'">
          <div class="text-field">
            <label for="cmtm">{{ 'COMMON.IDENTIFY_CARD' | translate }}<span class="required">*</span></label>
            <input required min="9" max="12" autocomplete="off" type="text" [pattern]="pattern.CCCD" id="cmtm" name="cmtm" [value]="form.cmtm"
              [(ngModel)]="form.cmtm" />
              <span *ngIf="f.controls['cmtm']?.valid" class="input--check"><i class="fa-solid fa-circle-check"></i></span>
            <div class="invalid">
              <small *ngIf="f.controls['cmtm']?.invalid && f.controls['cmtm']?.touched">{{error.required|translate}}</small>
              <small *ngIf="f.controls['cmtm']?.errors?.['pattern']">{{error.identify|translate}}</small>
            </div>
          </div>
          <div class="text-field">
            <label for="mobim">{{ 'COMMON.PHONE_NUMBER' | translate }}<span class="required">*</span></label>
            <input required maxlength="10" [pattern]="pattern.PHONE" autocomplete="off" type="tel" id="mobim"
              name="mobim" [value]="form.mobim" [(ngModel)]="form.mobim" />
              <span *ngIf="f.controls['mobim']?.valid" class="input--check"><i class="fa-solid fa-circle-check"></i></span>
            <div class="invalid">
              <small
                *ngIf="f.controls['mobim']?.invalid && f.controls['mobim']?.touched && form.mobim.length < 1">{{error.required|translate}}</small>
              <small *ngIf="f.controls['mobim']?.errors?.['pattern']">{{error.phone|translate}}</small>
            </div>
          </div>
          <div class="text-field">
            <label for="tenm">{{ 'COMMON.FULL_NAME' | translate }}<span class="required">*</span></label>
            <input required autocomplete="off" type="text" id="tenm" name="tenm" [value]="form.tenm"
              [(ngModel)]="form.tenm" (blur)="onCapitalize(0);onNumberIn(0)" />
              <span *ngIf="f.controls['tenm']?.valid" class="input--check"><i class="fa-solid fa-circle-check"></i></span>
            <div [class]="isNumberInP?'alertMessage':'invalid'">
              <small *ngIf="f.controls['tenm']?.invalid && f.controls['tenm']?.touched">{{error.required|translate}}</small>
              <small *ngIf="isNumberInP">{{error.number_in|translate}}</small>
            </div>
          </div>
          <div class="text-field">
            <label for="emailm">Email<span class="required">*</span></label>
            <input required [email]="pattern.EMAIL" autocomplete="off" type="email" id="emailm" name="emailm"
              [value]="form.emailm" [(ngModel)]="form.emailm" />
              <span *ngIf="f.controls['emailm']?.valid" class="input--check"><i class="fa-solid fa-circle-check"></i></span>
            <div class="invalid">
              <small
                *ngIf="f.controls['emailm']?.invalid && f.controls['emailm']?.touched && form.emailm.length < 1">{{error.required|translate}}</small>
              <small *ngIf="f.controls['emailm']?.errors?.['email']">{{error.email|translate}}</small>
            </div>
          </div>
          <div class="text-field">
            <label for="dchim">{{ 'COMMON.ADDRESS' | translate }}<span class="required">*</span></label>
            <input required autocomplete="off" type="text" id="dchim" name="dchim" [value]="form.dchim"
              [(ngModel)]="form.dchim" />
              <span *ngIf="f.controls['dchim']?.valid" class="input--check"><i class="fa-solid fa-circle-check"></i></span>
            <div class="invalid">
              <small *ngIf="f.controls['dchim']?.invalid && f.controls['dchim']?.touched">{{error.required|translate}}</small>
            </div>
          </div>
          <div class="text-field">
            <label for="ng_sinhm">{{ 'COMMON.BIRTHDAY' | translate }}<span class="required">*</span></label>
            <input required autocomplete="off" [type]="isWebview ? 'text' : 'date'" [max]="maxBirth" id="ng_sinhm" name="ng_sinhm"
              [value]="form.ng_sinhm" [(ngModel)]="form.ng_sinhm" placeholder="20-02-2002"  [pattern]="isWebview ? pattern.DATE : pattern.ALL" />
              <span *ngIf="f.controls['ng_sinhm']?.valid&&isWebview" class="input--check"><i class="fa-solid fa-circle-check"></i></span>
            <div class="invalid">
              <small *ngIf="f.controls['ng_sinhm']?.invalid && f.controls['ng_sinhm']?.touched && form.ng_sinhm.length < 1">
                {{error.shortReq|translate}}
              </small>
              <small *ngIf="f.controls['ng_sinhm']?.errors?.['pattern'] && isWebview">{{error.date|translate}}</small>
            </div>
          </div>
        </div>
        <br>

        <ng-container [ngTemplateOutlet]="prodStep"></ng-container>
      </div>

      <div id="step_3" ngClass="{{stepValue == 3 ? 'step' : 'hidden'}}">
        <app-form-title [icon]="'badge'" [label]="'Thông tin tình trạng sức khỏe'"></app-form-title>
        <ol>
          <li>
            <span>{{ 'MICCARE.1' | translate }}</span><br>
            <div class="check-field">
              <div class="form-check">
                <input type="radio" id="ckco" name="forck" value="ckco" [(ngModel)]="forck" />
                <label for="ckco">{{ 'COMMON.YES' | translate }}</label>
              </div>
              <div class="form-check mx-md-3">
                <input type="radio" id="ckko" name="forck" value="ckko" [(ngModel)]="forck" checked />
                <label for="ckko">{{ 'COMMON.NO' | translate }}</label>
              </div>
            </div>
          </li>
          <li>
            <span>{{ 'MICCARE.2' | translate }}</span><br>
            <div class="check-field">
              <div class="form-check">
                <input type="radio" id="ccco" name="forcc" value="ccco" [(ngModel)]="forcc" />
                <label for="ccco">{{ 'COMMON.YES' | translate }}</label>
              </div>
              <div class="form-check mx-md-3">
                <input type="radio" id="ccko" name="forcc" value="ccko" [(ngModel)]="forcc" checked />
                <label for="ccko">{{ 'COMMON.NO' | translate }}</label>
              </div>
            </div>
          </li>
          <li>
            <span>{{ 'MICCARE.3' | translate }}</span><br>
            <div class="check-field">
              <div class="form-check">
                <input type="radio" id="cuco" name="forcu" value="cuco" [(ngModel)]="forcu" />
                <label for="cuco">{{ 'COMMON.YES' | translate }}</label>
              </div>
              <div class="form-check mx-md-3">
                <input type="radio" id="cuko" name="forcu" value="cuko" [(ngModel)]="forcu" checked />
                <label for="cuko">{{ 'COMMON.NO' | translate }}</label>
              </div>
            </div>
          </li>
        </ol>

        <ng-container [ngTemplateOutlet]="prodStep"></ng-container>

        <div class="mobile-hidden">
          <button class="form__payment" (click)="isToggle ? isToggle = false : isToggle = true">{{ 'PRODUCT.DECLARE_HEALTH' | translate }}</button>
        </div>
      </div>

    </div>

    <div id="table-form" class="form--left" *ngIf="isToggle">
      <app-form-title [label]="'PRODUCT.HEALTHY_INFO'|translate"></app-form-title>

      <table>
        <tr>
          <th>{{ 'PRODUCT.POSITION' | translate }}</th>
          <th>{{ 'PRODUCT.QUESTION' | translate }}</th>
          <th class="input-col">{{ 'COMMON.YES' | translate }}</th>
        </tr>
        <tr>
          <td class="table__cate" colspan="3">{{ 'MICCARE.4' | translate }}</td>
        </tr>
        <tr>
          <td>1</td>
          <td class="tablerow"><label for="tt1C">{{ 'MICCARE.5' | translate }}</label></td>
          <td>
            <input type="checkbox" id="tt1C" name="tt1C"
              (click)="ttsk.tt1C === 'K' ? ttsk.tt1C = 'C' : ttsk.tt1C = 'K'" />
          </td>
        </tr>
        <tr>
          <td>2</td>
          <td class="tablerow"><label for="tt2C">Parkinson, Alzheimer</label></td>
          <td>
            <input type="checkbox" id="tt2C" name="tt2C"
              (click)="ttsk.tt2C === 'K' ? ttsk.tt2C = 'C' : ttsk.tt2C = 'K'" />
          </td>
        </tr>
        <tr>
          <td>3</td>
          <td class="tablerow"><label for="tt3C">{{ 'MICCARE.6' | translate }}</label></td>
          <td>
            <input type="checkbox" id="tt3C" name="tt3C"
              (click)="ttsk.tt3C === 'K' ? ttsk.tt3C = 'C' : ttsk.tt3C = 'K'" />
          </td>
        </tr>
        <tr>
          <td>4</td>
          <td class="tablerow"><label for="tt4C">{{ 'MICCARE.7' | translate }}</label></td>
          <td>
            <input type="checkbox" id="tt4C" name="tt4C"
              (click)="ttsk.tt4C === 'K' ? ttsk.tt4C = 'C' : ttsk.tt4C = 'K'" />
          </td>
        </tr>
        <tr>
          <td>5</td>
          <td class="tablerow"><label for="tt5C">{{ 'MICCARE.7' | translate }}</label></td>
          <td>
            <input type="checkbox" id="tt5C" name="tt5C"
              (click)="ttsk.tt5C === 'K' ? ttsk.tt5C = 'C' : ttsk.tt5C = 'K'" />
          </td>
        </tr>
        <tr>
          <td>6</td>
          <td class="tablerow"><label for="tt6C">{{ 'MICCARE.9' | translate }}</label></td>
          <td>
            <input type="checkbox" id="tt6C" name="tt6C"
              (click)="ttsk.tt6C === 'K' ? ttsk.tt6C = 'C' : ttsk.tt6C = 'K'" />
          </td>
        </tr>
        <tr>
          <td>7</td>
          <td class="tablerow"><label for="tt7C">{{ 'MICCARE.10' | translate }}</label></td>
          <td>
            <input type="checkbox" id="tt7C" name="tt7C"
              (click)="ttsk.tt7C === 'K' ? ttsk.tt7C = 'C' : ttsk.tt7C = 'K'" />
          </td>
        </tr>
        <tr>
          <td>8</td>
          <td class="tablerow"><label for="tt8C">{{ 'MICCARE.11' | translate }}</label></td>
          <td>
            <input type="checkbox" id="tt8C" name="tt8C"
              (click)="ttsk.tt8C === 'K' ? ttsk.tt8C = 'C' : ttsk.tt8C = 'K'" />
          </td>
        </tr>
        <tr>
          <td>9</td>
          <td class="tablerow"><label for="tt9C">{{ 'MICCARE.12' | translate }}</label></td>
          <td>
            <input type="checkbox" id="tt9C" name="tt9C"
              (click)="ttsk.tt9C === 'K' ? ttsk.tt9C = 'C' : ttsk.tt9C = 'K'" />
          </td>
        </tr>
        <tr>
          <td>10</td>
          <td class="tablerow"><label for="tt10C">{{ 'MICCARE.13' | translate }}</label></td>
          <td>
            <input type="checkbox" id="tt10C" name="tt10C"
              (click)="ttsk.tt10C === 'K' ? ttsk.tt10C = 'C' : ttsk.tt10C = 'K'" />
          </td>
        </tr>
        <tr>
          <td>11</td>
          <td class="tablerow"><label for="tt11C">{{ 'MICCARE.14' | translate }}</label></td>
          <td>
            <input type="checkbox" id="tt11C" name="tt11C"
              (click)="ttsk.tt11C === 'K' ? ttsk.tt11C = 'C' : ttsk.tt11C = 'K'" />
          </td>
        </tr>
        <tr>
          <td>12</td>
          <td class="tablerow"><label for="tt12C">{{ 'MICCARE.15' | translate }}</label></td>
          <td>
            <input type="checkbox" id="tt12C" name="tt12C"
              (click)="ttsk.tt12C === 'K' ? ttsk.tt12C = 'C' : ttsk.tt12C = 'K'" />
          </td>
        </tr>
        <tr>
          <td>13</td>
          <td class="tablerow"><label for="tt13C">{{ 'MICCARE.16' | translate }}</label></td>
          <td>
            <input type="checkbox" id="tt13C" name="tt13C"
              (click)="ttsk.tt13C === 'K' ? ttsk.tt13C = 'C' : ttsk.tt13C = 'K'" />
          </td>
        </tr>

        <tr>
          <td class="table__cate" colspan="3">{{ 'MICCARE.17' | translate }}</td>
        </tr>
        <tr>
          <td>14</td>
          <td class="tablerow"><label for="tt14C">{{ 'MICCARE.18' | translate }}</label></td>
          <td>
            <input type="checkbox" id="tt14C" name="tt14C"
              (click)="ttsk.tt14C === 'K' ? ttsk.tt14C = 'C' : ttsk.tt14C = 'K'" />
          </td>
        </tr>
        <tr>
          <td>15</td>
          <td class="tablerow"><label for="tt15C">{{ 'MICCARE.19' | translate }}</label></td>
          <td>
            <input type="checkbox" id="tt15C" name="tt15C"
              (click)="ttsk.tt15C === 'K' ? ttsk.tt15C = 'C' : ttsk.tt15C = 'K'" />
          </td>
        </tr>
        <tr>
          <td>16</td>
          <td class="tablerow"><label for="tt16C">{{ 'MICCARE.20' | translate }}</label></td>
          <td>
            <input type="checkbox" id="tt16C" name="tt16C"
              (click)="ttsk.tt16C === 'K' ? ttsk.tt16C = 'C' : ttsk.tt16C = 'K'" />
          </td>
        </tr>
        <tr>
          <td>17</td>
          <td class="tablerow"><label for="tt17C">{{ 'MICCARE.21' | translate }}</label></td>
          <td>
            <input type="checkbox" id="tt17C" name="tt17C"
              (click)="ttsk.tt17C === 'K' ? ttsk.tt17C = 'C' : ttsk.tt17C = 'K'" />
          </td>
        </tr>

        <tr>
          <td class="table__cate" colspan="3">{{ 'MICCARE.22' | translate }}</td>
        </tr>
        <tr>
          <td>18</td>
          <td class="tablerow"><label for="tt18C">{{ 'MICCARE.23' | translate }}</label></td>
          <td>
            <input type="checkbox" id="tt18C" name="tt18C"
              (click)="ttsk.tt18C === 'K' ? ttsk.tt18C = 'C' : ttsk.tt18C = 'K'" />
          </td>
        </tr>
        <tr>
          <td>19</td>
          <td class="tablerow"><label for="tt19C">{{ 'MICCARE.24' | translate }}</label></td>
          <td>
            <input type="checkbox" id="tt19C" name="tt19C"
              (click)="ttsk.tt19C === 'K' ? ttsk.tt19C = 'C' : ttsk.tt19C = 'K'" />
          </td>
        </tr>
        <tr>
          <td>20</td>
          <td class="tablerow"><label for="tt20C">{{ 'MICCARE.25' | translate }}</label></td>
          <td>
            <input type="checkbox" id="tt20C" name="tt20C"
              (click)="ttsk.tt20C === 'K' ? ttsk.tt20C = 'C' : ttsk.tt20C = 'K'" />
          </td>
        </tr>
        <tr>
          <td>21</td>
          <td class="tablerow"><label for="tt21C">{{ 'MICCARE.26' | translate }}</label></td>
          <td>
            <input type="checkbox" id="tt21C" name="tt21C"
              (click)="ttsk.tt21C === 'K' ? ttsk.tt21C = 'C' : ttsk.tt21C = 'K'" />
          </td>
        </tr>
        <tr>
          <td>22</td>
          <td class="tablerow"><label for="tt22C">{{ 'MICCARE.27' | translate }}</label></td>
          <td>
            <input type="checkbox" id="tt22C" name="tt22C"
              (click)="ttsk.tt22C === 'K' ? ttsk.tt22C = 'C' : ttsk.tt22C = 'K'" />
          </td>
        </tr>
        <tr>
          <td>23</td>
          <td class="tablerow"><label for="tt23C">{{ 'MICCARE.28' | translate }}</label></td>
          <td>
            <input type="checkbox" id="tt23C" name="tt23C"
              (click)="ttsk.tt23C === 'K' ? ttsk.tt23C = 'C' : ttsk.tt23C = 'K'" />
          </td>
        </tr>

        <tr>
          <td class="table__cate" colspan="3">{{ 'MICCARE.29' | translate }}</td>
        </tr>
        <tr>
          <td>24</td>
          <td class="tablerow"><label for="tt24C">{{ 'MICCARE.30' | translate }}</label></td>
          <td>
            <input type="checkbox" id="tt24C" name="tt24C"
              (click)="ttsk.tt24C === 'K' ? ttsk.tt24C = 'C' : ttsk.tt24C = 'K'" />
          </td>
        </tr>
        <tr>
          <td>25</td>
          <td class="tablerow"><label for="tt25C">{{ 'MICCARE.31' | translate }}</label></td>
          <td>
            <input type="checkbox" id="tt25C" name="tt25C"
              (click)="ttsk.tt25C === 'K' ? ttsk.tt25C = 'C' : ttsk.tt25C = 'K'" />
          </td>
        </tr>
        <tr>
          <td>26</td>
          <td class="tablerow"><label for="tt26C">{{ 'MICCARE.32' | translate }}</label></td>
          <td>
            <input type="checkbox" id="tt26C" name="tt26C"
              (click)="ttsk.tt26C === 'K' ? ttsk.tt26C = 'C' : ttsk.tt26C = 'K'" />
          </td>
        </tr>
        <tr>
          <td>27</td>
          <td class="tablerow"><label for="tt27C">{{ 'MICCARE.33' | translate }}</label></td>
          <td>
            <input type="checkbox" id="tt27C" name="tt27C"
              (click)="ttsk.tt27C === 'K' ? ttsk.tt27C = 'C' : ttsk.tt27C = 'K'" />
          </td>
        </tr>
        <tr>
          <td>28</td>
          <td class="tablerow"><label for="tt28C">{{ 'MICCARE.34' | translate }}</label></td>
          <td>
            <input type="checkbox" id="tt28C" name="tt28C"
              (click)="ttsk.tt28C === 'K' ? ttsk.tt28C = 'C' : ttsk.tt28C = 'K'" />
          </td>
        </tr>
        <tr>
          <td>29</td>
          <td class="tablerow"><label for="tt29C">{{ 'MICCARE.35' | translate }}</label></td>
          <td>
            <input type="checkbox" id="tt29C" name="tt29C"
              (click)="ttsk.tt29C === 'K' ? ttsk.tt29C = 'C' : ttsk.tt29C = 'K'" />
          </td>
        </tr>
        <tr>
          <td>30</td>
          <td class="tablerow"><label for="tt30C">{{ 'MICCARE.36' | translate }}</label></td>
          <td>
            <input type="checkbox" id="tt30C" name="tt30C"
              (click)="ttsk.tt30C === 'K' ? ttsk.tt30C = 'C' : ttsk.tt30C = 'K'" />
          </td>
        </tr>
        <tr>
          <td>31</td>
          <td class="tablerow"><label for="tt31C">{{ 'MICCARE.37' | translate }}</label></td>
          <td>
            <input type="checkbox" id="tt31C" name="tt31C"
              (click)="ttsk.tt31C === 'K' ? ttsk.tt31C = 'C' : ttsk.tt31C = 'K'" />
          </td>
        </tr>

        <tr>
          <td class="table__cate" colspan="3">{{ 'MICCARE.38' | translate }}</td>
        </tr>
        <tr>
          <td>32</td>
          <td class="tablerow"><label for="tt32C">{{ 'MICCARE.39' | translate }}</label></td>
          <td>
            <input type="checkbox" id="tt32C" name="tt32C"
              (click)="ttsk.tt32C === 'K' ? ttsk.tt32C = 'C' : ttsk.tt32C = 'K'" />
          </td>
        </tr>
        <tr>
          <td>33</td>
          <td class="tablerow"><label for="tt33C">{{ 'MICCARE.40' | translate }}</label></td>
          <td>
            <input type="checkbox" id="tt33C" name="tt33C"
              (click)="ttsk.tt33C === 'K' ? ttsk.tt33C = 'C' : ttsk.tt33C = 'K'" />
          </td>
        </tr>
        <tr>
          <td>34</td>
          <td class="tablerow"><label for="tt34C">{{ 'MICCARE.41' | translate }}</label></td>
          <td>
            <input type="checkbox" id="tt34C" name="tt34C"
              (click)="ttsk.tt34C === 'K' ? ttsk.tt34C = 'C' : ttsk.tt34C = 'K'" />
          </td>
        </tr>

        <tr>
          <td class="table__cate" colspan="3">{{ 'MICCARE.42' | translate }}</td>
        </tr>
        <tr>
          <td>35</td>
          <td class="tablerow"><label for="tt35C">{{ 'MICCARE.43' | translate }}</label></td>
          <td>
            <input type="checkbox" id="tt35C" name="tt35C"
              (click)="ttsk.tt35C === 'K' ? ttsk.tt35C = 'C' : ttsk.tt35C = 'K'" />
          </td>
        </tr>
        <tr>
          <td>36</td>
          <td class="tablerow"><label for="tt36C">{{ 'MICCARE.44' | translate }}</label></td>
          <td>
            <input type="checkbox" id="tt36C" name="tt36C"
              (click)="ttsk.tt36C === 'K' ? ttsk.tt36C = 'C' : ttsk.tt36C = 'K'" />
          </td>
        </tr>
        <tr>
          <td>37</td>
          <td class="tablerow"><label for="tt37C">{{ 'MICCARE.45' | translate }}</label></td>
          <td>
            <input type="checkbox" id="tt37C" name="tt37C"
              (click)="ttsk.tt37C === 'K' ? ttsk.tt37C = 'C' : ttsk.tt37C = 'K'" />
          </td>
        </tr>
        <tr>
          <td>38</td>
          <td class="tablerow"><label for="tt38C">{{ 'MICCARE.46' | translate }}</label></td>
          <td>
            <input type="checkbox" id="tt38C" name="tt38C"
              (click)="ttsk.tt38C === 'K' ? ttsk.tt38C = 'C' : ttsk.tt38C = 'K'" />
          </td>
        </tr>
        <tr>
          <td>39</td>
          <td class="tablerow"><label for="tt39C">{{ 'MICCARE.47' | translate }}<br>{{ 'MICCARE.48' | translate }}</label></td>
          <td>
            <input type="checkbox" id="tt39C" name="tt39C"
              (click)="ttsk.tt39C === 'K' ? ttsk.tt39C = 'C' : ttsk.tt39C = 'K'" />
          </td>
        </tr>
        <tr>
          <td>40</td>
          <td class="tablerow"><label for="tt40C">{{ 'MICCARE.49' | translate }}</label></td>
          <td>
            <input type="checkbox" id="tt40C" name="tt40C"
              (click)="ttsk.tt40C === 'K' ? ttsk.tt40C = 'C' : ttsk.tt40C = 'K'" />
          </td>
        </tr>

        <tr>
          <td class="table__cate" colspan="3">{{ 'MICCARE.50' | translate }}</td>
        </tr>
        <tr>
          <td>41</td>
          <td class="tablerow"><label for="tt41C">{{ 'MICCARE.51' | translate }}</label></td>
          <td>
            <input type="checkbox" id="tt41C" name="tt41C"
              (click)="ttsk.tt41C === 'K' ? ttsk.tt41C = 'C' : ttsk.tt41C = 'K'" />
          </td>
        </tr>
        <tr>
          <td>42</td>
          <td class="tablerow"><label for="tt42C">{{ 'MICCARE.52' | translate }}</label></td>
          <td>
            <input type="checkbox" id="tt42C" name="tt42C"
              (click)="ttsk.tt42C === 'K' ? ttsk.tt42C = 'C' : ttsk.tt42C = 'K'" />
          </td>
        </tr>
        <tr>
          <td>43</td>
          <td class="tablerow"><label for="tt43C">{{ 'MICCARE.53' | translate }}</label></td>
          <td>
            <input type="checkbox" id="tt43C" name="tt43C"
              (click)="ttsk.tt43C === 'K' ? ttsk.tt43C = 'C' : ttsk.tt43C = 'K'" />
          </td>
        </tr>
        <tr>
          <td>44</td>
          <td class="tablerow"><label for="tt44C">{{ 'MICCARE.54' | translate }}</label></td>
          <td>
            <input type="checkbox" id="tt44C" name="tt44C"
              (click)="ttsk.tt44C === 'K' ? ttsk.tt44C = 'C' : ttsk.tt44C = 'K'" />
          </td>
        </tr>
        <tr>
          <td>45</td>
          <td class="tablerow"><label for="tt45C">{{ 'MICCARE.55' | translate }}</label></td>
          <td>
            <input type="checkbox" id="tt45C" name="tt45C"
              (click)="ttsk.tt45C === 'K' ? ttsk.tt45C = 'C' : ttsk.tt45C = 'K'" />
          </td>
        </tr>

        <tr>
          <td class="table__cate" colspan="3">{{ 'MICCARE.56' | translate }}</td>
        </tr>
        <tr>
          <td>46</td>
          <td class="tablerow"><label for="tt46C">{{ 'MICCARE.57' | translate }}</label>
          </td>
          <td>
            <input type="checkbox" id="tt46C" name="tt46C"
              (click)="ttsk.tt46C === 'K' ? ttsk.tt46C = 'C' : ttsk.tt46C = 'K'" />
          </td>
        </tr>
        <tr>
          <td>47</td>
          <td class="tablerow"><label for="tt47C">{{ 'MICCARE.58' | translate }}</label></td>
          <td>
            <input type="checkbox" id="tt47C" name="tt47C"
              (click)="ttsk.tt47C === 'K' ? ttsk.tt47C = 'C' : ttsk.tt47C = 'K'" />
          </td>
        </tr>
        <tr>
          <td>48</td>
          <td class="tablerow"><label for="tt48C">{{ 'MICCARE.59' | translate }}</label></td>
          <td>
            <input type="checkbox" id="tt48C" name="tt48C"
              (click)="ttsk.tt48C === 'K' ? ttsk.tt48C = 'C' : ttsk.tt48C = 'K'" />
          </td>
        </tr>
        <tr>
          <td>49</td>
          <td class="tablerow"><label for="tt49C">{{ 'MICCARE.60' | translate }}</label></td>
          <td>
            <input type="checkbox" id="tt49C" name="tt49C"
              (click)="ttsk.tt49C === 'K' ? ttsk.tt49C = 'C' : ttsk.tt49C = 'K'" />
          </td>
        </tr>

        <tr>
          <td class="table__cate" colspan="3">{{ 'MICCARE.61' | translate }}</td>
        </tr>
        <tr>
          <td>50</td>
          <td class="tablerow"><label for="tt50C">{{ 'MICCARE.62' | translate }}</label></td>
          <td>
            <input type="checkbox" id="tt50C" name="tt50C"
              (click)="ttsk.tt50C === 'K' ? ttsk.tt50C = 'C' : ttsk.tt50C = 'K'" />
          </td>
        </tr>
        <tr>
          <td>51</td>
          <td class="tablerow"><label for="tt51C">{{ 'MICCARE.63' | translate }}</label></td>
          <td>
            <input type="checkbox" id="tt51C" name="tt51C"
              (click)="ttsk.tt51C === 'K' ? ttsk.tt51C = 'C' : ttsk.tt51C = 'K'" />
          </td>
        </tr>
        <tr>
          <td>52</td>
          <td class="tablerow"><label for="tt52C">{{ 'MICCARE.64' | translate }}</label></td>
          <td>
            <input type="checkbox" id="tt52C" name="tt52C"
              (click)="ttsk.tt52C === 'K' ? ttsk.tt52C = 'C' : ttsk.tt52C = 'K'" />
          </td>
        </tr>

        <tr>
          <td class="table__cate" colspan="3">{{ 'MICCARE.65' | translate }}</td>
        </tr>
        <tr>
          <td>53</td>
          <td class="tablerow"><label for="tt53C">{{ 'MICCARE.66' | translate }}</label></td>
          <td>
            <input type="checkbox" id="tt53C" name="tt53C"
              (click)="ttsk.tt53C === 'K' ? ttsk.tt53C = 'C' : ttsk.tt53C = 'K'" />
          </td>
        </tr>
        <tr>
          <td>54</td>
          <td class="tablerow"><label for="tt54C">{{ 'MICCARE.67' | translate }}</label></td>
          <td>
            <input type="checkbox" id="tt54C" name="tt54C"
              (click)="ttsk.tt54C === 'K' ? ttsk.tt54C = 'C' : ttsk.tt54C = 'K'" />
          </td>
        </tr>
        <tr>
          <td>55</td>
          <td class="tablerow"><label for="tt55C">{{ 'MICCARE.68' | translate }}</label></td>
          <td>
            <input type="checkbox" id="tt55C" name="tt55C"
              (click)="ttsk.tt55C === 'K' ? ttsk.tt55C = 'C' : ttsk.tt55C = 'K'" />
          </td>
        </tr>
        <tr>
          <td>56</td>
          <td class="tablerow"><label for="tt56C">{{ 'MICCARE.69' | translate }}</label></td>
          <td>
            <input type="checkbox" id="tt56C" name="tt56C"
              (click)="ttsk.tt56C === 'K' ? ttsk.tt56C = 'C' : ttsk.tt56C = 'K'" />
          </td>
        </tr>

        <tr>
          <td class="table__cate" colspan="3">{{ 'MICCARE.70' | translate }}</td>
        </tr>
        <tr>
          <td>57</td>
          <td class="tablerow"><label for="tt57C">{{ 'MICCARE.71' | translate }}</label></td>
          <td>
            <input type="checkbox" id="tt57C" name="tt57C"
              (click)="ttsk.tt57C === 'K' ? ttsk.tt57C = 'C' : ttsk.tt57C = 'K'" />
          </td>
        </tr>

        <tr>
          <td class="table__cate" colspan="3">{{ 'MICCARE.72' | translate }}</td>
        </tr>
        <tr>
          <td>58</td>
          <td class="tablerow"><label for="tt58C">{{ 'MICCARE.73' | translate }}</label></td>
          <td>
            <input type="checkbox" id="tt58C" name="tt58C"
              (click)="ttsk.tt58C === 'K' ? ttsk.tt58C = 'C' : ttsk.tt58C = 'K'" />
          </td>
        </tr>
        <tr>
          <td>59</td>
          <td class="tablerow"><label for="tt59C">{{ 'MICCARE.74' | translate }}</label></td>
          <td>
            <input type="checkbox" id="tt59C" name="tt59C"
              (click)="ttsk.tt59C === 'K' ? ttsk.tt59C = 'C' : ttsk.tt59C = 'K'" />
          </td>
        </tr>
        <tr>
          <td>60</td>
          <td class="tablerow"><label for="tt60C">{{ 'MICCARE.75' | translate }}</label></td>
          <td>
            <input type="checkbox" id="tt60C" name="tt60C"
              (click)="ttsk.tt60C === 'K' ? ttsk.tt60C = 'C' : ttsk.tt60C = 'K'" />
          </td>
        </tr>
        <tr>
          <td>61</td>
          <td class="tablerow"><label for="tt61C">{{ 'MICCARE.76' | translate }}</label></td>
          <td>
            <input type="checkbox" id="tt61C" name="tt61C"
              (click)="ttsk.tt61C === 'K' ? ttsk.tt61C = 'C' : ttsk.tt61C = 'K'" />
          </td>
        </tr>
        <tr>
          <td>62</td>
          <td class="tablerow"><label for="tt62C">{{ 'MICCARE.77' | translate }}</label></td>
          <td>
            <input type="checkbox" id="tt62C" name="tt62C"
              (click)="ttsk.tt62C === 'K' ? ttsk.tt62C = 'C' : ttsk.tt62C = 'K'" />
          </td>
        </tr>
        <tr>
          <td>63</td>
          <td class="tablerow"><label for="tt63C">{{ 'MICCARE.78' | translate }}</label></td>
          <td>
            <input type="checkbox" id="tt63C" name="tt63C"
              (click)="ttsk.tt63C === 'K' ? ttsk.tt63C = 'C' : ttsk.tt63C = 'K'" />
          </td>
        </tr>
        <tr>
          <td>64</td>
          <td class="tablerow"><label for="tt64C">{{ 'MICCARE.79' | translate }}</label></td>
          <td>
            <input type="checkbox" id="tt64C" name="tt64C"
              (click)="ttsk.tt64C === 'K' ? ttsk.tt64C = 'C' : ttsk.tt64C = 'K'" />
          </td>
        </tr>
        <tr>
          <td>65</td>
          <td class="tablerow"><label for="tt65C">{{ 'MICCARE.80' | translate }}</label></td>
          <td>
            <input type="checkbox" id="tt65C" name="tt65C"
              (click)="ttsk.tt65C === 'K' ? ttsk.tt65C = 'C' : ttsk.tt65C = 'K'" />
          </td>
        </tr>
        <tr>
          <td>66</td>
          <td class="tablerow"><label for="tt66C">{{ 'MICCARE.81' | translate }}</label></td>
          <td>
            <input type="checkbox" id="tt66C" name="tt66C"
              (click)="ttsk.tt66C === 'K' ? ttsk.tt66C = 'C' : ttsk.tt66C = 'K'" />
          </td>
        </tr>
        <tr>
          <td>67</td>
          <td class="tablerow"><label for="tt67C">{{ 'MICCARE.82' | translate }}</label></td>
          <td>
            <input type="checkbox" id="tt67C" name="tt67C"
              (click)="ttsk.tt67C === 'K' ? ttsk.tt67C = 'C' : ttsk.tt67C = 'K'" />
          </td>
        </tr>
        <tr>
          <td>68</td>
          <td class="tablerow"><label for="tt68C">{{ 'MICCARE.83' | translate }}</label></td>
          <td>
            <input type="checkbox" id="tt68C" name="tt68C"
              (click)="ttsk.tt68C === 'K' ? ttsk.tt68C = 'C' : ttsk.tt68C = 'K'" />
          </td>
        </tr>
        <tr>
          <td>69</td>
          <td class="tablerow"><label for="tt69C">{{ 'MICCARE.84' | translate }}</label></td>
          <td>
            <input type="checkbox" id="tt69C" name="tt69C"
              (click)="ttsk.tt69C === 'K' ? ttsk.tt69C = 'C' : ttsk.tt69C = 'K'" />
          </td>
        </tr>
        <tr>
          <td>70</td>
          <td class="tablerow"><label for="tt70C">{{ 'MICCARE.85' | translate }}</label></td>
          <td>
            <input type="checkbox" id="tt70C" name="tt70C"
              (click)="ttsk.tt70C === 'K' ? ttsk.tt70C = 'C' : ttsk.tt70C = 'K'" />
          </td>
        </tr>
        <tr>
          <td>II</td>
          <td class="table__cate"><label for="tt71C">{{ 'MICCARE.86' | translate }}</label></td>
          <td>
            <input type="checkbox" id="tt71C" name="tt71C"
              (click)="ttsk.tt71C === 'K' ? ttsk.tt71C = 'C' : ttsk.tt71C = 'K'" />
          </td>
        </tr>
      </table>
      <br>

      <ng-container [ngTemplateOutlet]="prodStep"></ng-container>

      <div class="mobile-hidden">
        <button class="form__payment" (click)="isToggle ? isToggle = false : isToggle = true">
          {{ 'PRODUCT.DECLARE_INSURANCE' | translate }}
        </button>
      </div>
    </div>

    <div id="last-step" [ngClass]="stepValue == 5 ? 'step' : 'hidden'" *ngIf="isWebview === false">
      <app-form-title [label]="'PRODUCT.FEE_INFO'|translate"></app-form-title>
      <hr>
      <div class="last-step--label">
        <span>{{ 'PAYMENT.FEE' | translate }}:</span>
        <span>{{fee.phi}} VND</span>
      </div>
      <div class="last-step--label">
        <span>{{ 'PAYMENT.TAX' | translate }}:</span>
        <span>{{fee.thue}} VND</span>
      </div>
      <hr>
      <div class="flex">
        <img class="form__payment--thumb" src="../../../assets/frontend/images/coupon.png" alt="voucher">
        <span class="form__payment--voucher">Voucher:</span>
      </div>
      <div class="flex space" *ngIf="!meetVoucher">
        <div class="text-field launcher">
          <label for="launcher">{{ 'COMMON.REFERRAL_CODE' | translate }}</label>
          <input autocomplete="off" type="text" id="launcher" name="launcher" [placeholder]="'PRODUCT.A00000001'|translate"
            [value]="form.magioithieu" [(ngModel)]="form.magioithieu" [disabled]="isShared"
            (ngModelChange)="getLauncher(form.magioithieu)" />
        </div>
        <div class="text-field maVoucher">
          <label for="maVoucher">{{ 'PAYMENT.VOUCHER_ID' | translate }}</label>
          <input autocomplete="off" type="text" id="maVoucher" name="maVoucher" [placeholder]="'PRODUCT.INSERT_VOUCHER'|translate"
            [value]="voucher.maVoucher" [(ngModel)]="voucher.maVoucher"
            (ngModelChange)="voucher.maVoucher.length === 21 ? getDiscount(voucher.maVoucher) : getOldFee()" />
          <div class="invalid">
            <small *ngIf="voucher.maVoucher.length === 21 && voucher.status === false">{{error.voucher|translate}}</small>
          </div>
        </div>
      </div>
      <div *ngIf="form.nguoiGioiThieu">
        <span>{{ 'PAYMENT.PRESENTER' | translate }}:</span>
        <span>{{form.nguoiGioiThieu}}</span>
      </div>
      <div class="last-step--label">
        <span>{{ 'PAYMENT.SALE' | translate }}:</span>
        <span>- {{save}} VND</span>
      </div>
      <div class="last-step--label" style="font-weight: 500;">
        <span>{{ 'PAYMENT.TOTAL' | translate }}:</span>
        <span>{{fee.tongphi}} VND</span>
      </div>
      <hr>
      <button class="form__payment" (click)="onSubmit(f)"
      [disabled]="submited" [ngClass]="submited ? 'opacity-50' : 'opacity-100'">{{ 'COMMON.PAY' | translate }}</button>
    </div>

    <div id="webview-step" [ngClass]="stepValue === 5 ? 'step' : 'hidden'" *ngIf="isWebview">
      <app-form-title [label]="'PRODUCT.FEE_INFO'|translate"></app-form-title>
      <hr>
      <span class="label">{{ 'PAYMENT.CLIENT_NAME' | translate }}</span>
      <span class="info">{{kh.ten}}</span>
      <span class="label">{{ 'PAYMENT.CLIENT_EMAIL' | translate }}</span>
      <span class="info">{{kh.email}}</span>
      <span class="label">{{ 'PRODUCT.CLIENT_PHONE_NUMBER' | translate }}</span>
      <span class="info">{{kh.mobi}}</span>
      <hr>
      <span class="label">{{ 'PAYMENT.FEE' | translate }}</span>
      <span class="info">{{fee.phi}}</span>
      <span class="label">{{ 'PAYMENT.TAX' | translate }}</span>
      <span class="info">{{fee.thue}} VND</span>
      <span class="label">{{ 'PAYMENT.TOTAL' | translate }}</span>
      <span class="fee webview-cl">{{fee.tongphi}} VND</span>
      <br>

      <ng-container [ngTemplateOutlet]="prodStep"></ng-container>
      <span *ngIf="!isApp" class="notice-payment">{{ 'PAYMENT.NOTICE' | translate }}</span>
    </div>
  </form>
</div>

<ng-template #getBackConfirm let-modal>
  <div class="modal-header">
    <h4 class="modal-title">{{ 'COMMON.PAY' | translate }}</h4>
  </div>
  <div class="modal-body">
    <p>{{ 'COMMON.BEFORE_BACK' | translate }} ?</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="getBack()">{{ 'COMMON.YES' | translate }}</button>
    <button type="button" class="btn btn-light" (click)="modal.close('Close click')">{{ 'COMMON.NO' | translate }}</button>
  </div>
</ng-template>

<ng-template #prodStep>
  <div class="step-and-total" [ngClass]="isWebview ? 'webview' : ''">
    <button class="back" (click)="stepValue > 1 ? onController(-1, f) : openCentered(getBackConfirm) ">
      {{ 'COMMON.BACK' | translate }}
    </button>
    <button class="next" [disabled]="stepValue === 4 && !fee.tongphi" [ngClass]="(stepValue === 4 && !fee.tongphi) ? 'disableBtn' : ''"
      (click)="stepValue < 5 ? onController(1, f) : ''" *ngIf="stepValue < 5">
      {{ 'COMMON.NEXT' | translate }}
    </button>
    <button class="next" (click)="onSubmit(f)" *ngIf="stepValue === 5"
    [disabled]="submited" [ngClass]="submited ? 'opacity-50' : 'opacity-100'">{{ 'COMMON.PAY' | translate }}</button>
  </div>
</ng-template>

