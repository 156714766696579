import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GPAYRegister } from '@models/gpay.model';
import { catchError, Observable, of, switchMap } from 'rxjs';
import { environment } from '@environments/environment';
import { API_PATH } from 'src/utils/constant';


@Injectable({
  providedIn: 'root'
})
export class GpayService {

  constructor(private _http: HttpClient) { }

  onRegister(data: GPAYRegister): Observable<any> {
    return this._http.post(`${environment.apiURL}${API_PATH.GPAYREGISTER}`, data)
      .pipe(catchError((error) => {
        return of(error);
      }), switchMap((response) => {
        return of(response);
      }));
  }
}
