import { Component, OnInit, TemplateRef } from '@angular/core';
import { ExportService } from '@services/export.service';
import * as FileSaver from 'file-saver';
import { CartService } from '@services/cart/cart.service';
import { ITCart, ITCartBSHCar } from '@models/cart.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ERROR, VALID } from 'src/utils/validation';
import { GENDERS, STATUS_SELECTOR } from 'src/utils/constant';
import { ApiService } from '@services/api.service';
import { environment } from '@environments/environment';
import { ITCategory } from '@models/category.model';
import { ITProduct } from '@models/product.model';
import { ToastService } from '@services/toast.service';
import { ProtectService } from '@services/protect/protect.service';
import { BHSK_RELATIVE } from 'src/utils/product/vbicare';
import { CommonService } from '@services/common/common.service';
import { TokenService } from '@services/token.service';

@Component({
  selector: 'app-collaborators-contracts',
  templateUrl: './collaborators-contracts.component.html',
  styleUrls: ['./collaborators-contracts.component.css']
})
export class CollaboratorsContractsComponent implements OnInit {
  prodEnv: boolean = environment.production
  ma_nv_login: string ='';
  contracts: ITCart[] = []
  categories: ITCategory[] = []
  products: ITProduct[] = []
  contract: ITCart = {
    id: 0,
    ngaytao: "",
    hoten: "",
    sodienthoai: "",
    cccd: "",
    email: "",
    ngaysinh: "",
    gioitinh: "",
    diachi: "",
    moiquanhe: "",
    loaibaohiem: "",
    goibaohiem: "",
    thoihan: "",
    tongphi: "",
    hinhthucthanhtoan: "",
    thoigianthanhtoan: "",
    trangthai: "",
    contractUrl: "",
    trangthaithanhtoan: "",
    id_hoso: "",
    mahoadon: "",
    magiaodich: "",
    trangthaivnpay: "",
    maloi: "",
  }
  BSHCar: ITCartBSHCar = {
    id: 0,
    id_dangky: 0,
    bien_xe: '',
    so_may: '',
    so_khung: '',
    nguon_goc: '',
    hang_xe: '',
    hieu_xe: '',
    nam_sx: '',
    so_cn: '',
    trong_tai: '',
    ngay_hl: '',
    ngay_kt: '',
    muc_dich_kinh_doanh: '',
    muc_dich_su_dung: '',
    trach_nhiem_dan_su_bat_buoc: '',
    linh_vuc_kinh_doanh: '',
  }

  page: number = 1
  total: number = 10
  pageSize: number = 10
  trangthaithanhtoan: string = 'Đã thanh toán'
  tempID: number = 0
  dialogType: string = ''
  disableInput: boolean = false
  filter: FormGroup = this._formBuilder.group({
    idProduct: [''],
    idCategory: [''],
    keyword: [''],
    fromDate: [''],
    toDate: [new Date()],
    status: ['Thành công'],
  })
  form: FormGroup = this._formBuilder.group({
    hddtId: ['', Validators.required],
    hoten: ['', Validators.required],
    sodienthoai: ['', Validators.required],
    cccd: ['', Validators.required],
    email: ['', Validators.required],
    ngaysinh: ['', Validators.required],
    gioitinh: ['', Validators.required],
    diachi: [''],
    moiquanhe: [''],
    loaibaohiem: ['', Validators.required],
    goibaohiem: ['', Validators.required],
    tongphi: ['', Validators.required],
    trangthai: ['', Validators.required],
    contractUrl: [''],
    soluongnguoi: [''],
    voucher: ['']
  })
  isBSHCar: boolean = false
  personsList:any = []
  personP: any
  isUpload: any;
  

  constructor(
    private _service: CartService,
    private ApiService: ApiService,
    private _formBuilder: FormBuilder,
    public _toast: ToastService,
    private _common: CommonService,
    private _modal: NgbModal,
    private _protect: ProtectService,
    private tokenStorage: TokenService,
  ) { }

  ngOnInit(): void {
    this.ma_nv_login = this.tokenStorage.getUser().ma_nv;
    this.getContractPages()
    this.getContracts()
    this.getCategories()
    this.getProducts()
  }

  getContractPages() {
    this._service.getContractPages(this.pageSize).subscribe(res => {
      this.total = res.tongbanghi
    })
  }

  getContracts() {
    this.contracts && (this.contracts = [])

    this._service.getContracts(this.page, this.pageSize, this.trangthaithanhtoan, this.ma_nv_login).subscribe(res => {
      this.contracts = res
      this.onFormat(this.contracts)
      this.contracts = this.onFilterCategory(this.contracts);
    })
  }

  getCategories() {
    this.ApiService.getCategories().subscribe(res => {
      this.categories = res;
    })
  }

  getProducts() {
    this.ApiService.getProducts().subscribe(res => {
      this.products = res;
    })
  }

  getContractbyID(id: number) {
    this._service.getContractbyID(id).subscribe(res => {
      this.contract = res
      this.contract.tongphi = this.contract.tongphi.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      this.contract.ngaysinh = this._protect.formatDate(res.ngaysinh)
      res.loaibaohiem === 'Bảo hiểm TNDS ôtô BSH'
        ? (this.isBSHCar = true)
        : (this.isBSHCar = false)
      this.isBSHCar && this.getBSHCarContract(id)
      // console.log(this.contract)
      this.onInitData(this.contract)
    })
  }

  getContractPersonsListbyID(id: number){
    this.personsList = [];
    this._service.getContractPersonsListbyID(id).subscribe(res => {
      // console.log(res)
      this.personsList = res
    })
    this.personP = null;
    this._service.getContractPersonPbyID(id).subscribe(res => {
      // console.log(res)
      this.personP = res
      if(this.personP[0].moiquanheP){
        this.personP[0].moiquanheP = this.mqhnguoimua.find((item: any) => item.value === this.personP[0].moiquanheP)?.label
      }
    })
  }

  upload(e: any) {
    let formData = new FormData()
    for (let file of e.target.files) {
      formData.append('files', file)
    }
    this._common.uploads(formData).subscribe((res) => {
      this._toast.show('Tải file thành công !', { icon: 'check_circle', classname: 'bg-success text-light' })
      // console.log(res[0])
      this.isUpload=res[0].name;
      this.form.controls['contractUrl'].setValue(res[0].name)
    })
  }

  getBSHCarContract(id: number) {
    this._service.getBSHCarContract(id).subscribe(res => {
      this.BSHCar = res[0]
      // console.log(this.BSHCar)
    })
  }
  clearOldData(){
    this.form.controls['hddtId'].setValue('')
    this.form.controls['hoten'].setValue('')
    this.form.controls['sodienthoai'].setValue('')
    this.form.controls['cccd'].setValue('')
    this.form.controls['email'].setValue('')
    this.form.controls['ngaysinh'].setValue('')
    this.form.controls['gioitinh'].setValue('')
    this.form.controls['diachi'].setValue('')
    this.form.controls['moiquanhe'].setValue('')
    this.form.controls['loaibaohiem'].setValue('')
    this.form.controls['goibaohiem'].setValue('')
    this.form.controls['tongphi'].setValue('')
    this.form.controls['trangthai'].setValue('')
    this.form.controls['contractUrl'].setValue('');
    this.form.controls['soluongnguoi'].setValue('')
    this.form.controls['voucher'].setValue('')
  }
  onInitData(data: any) {
    this.clearOldData()
    this.form.controls['hddtId'].setValue(data.id_hoso)
    this.form.controls['hoten'].setValue(data.hoten)
    this.form.controls['sodienthoai'].setValue(data.sodienthoai)
    this.form.controls['cccd'].setValue(data.cccd)
    this.form.controls['email'].setValue(data.email)
    this.form.controls['ngaysinh'].setValue(data.ngaysinh)
    this.form.controls['gioitinh'].setValue(data.gioitinh?this.removeVietnameseTones(data.gioitinh.toUpperCase()):null)
    this.form.controls['diachi'].setValue(data.diachi)
    this.form.controls['moiquanhe'].setValue(data.moiquanhe)
    this.form.controls['loaibaohiem'].setValue(data.loaibaohiem)
    this.form.controls['goibaohiem'].setValue(data.goibaohiem)
    this.form.controls['tongphi'].setValue(data.tongphi)
    this.form.controls['trangthai'].setValue(data.trangthai)
    this.form.controls['contractUrl'].setValue(data.contractUrl);
    this.form.controls['soluongnguoi'].setValue(data.soluongnguoi)
    this.form.controls['voucher'].setValue(data.voucher)
  }

  onSave() {
    this.isUpload?this.contract.contractUrl=this.isUpload:null
    let payload = {
      ...this.contract,
      ...this.form.value,
      contractUrl:this.isUpload?this.contract.contractUrl:this.form.controls['contractUrl'].value
    }
    this._service.putContract(this.tempID, payload).subscribe(res => {
      // console.log(res)
      res && (
        this.getContracts(),
        this._toast.show('Cập nhật hợp đồng thành công !', { icon: 'check_circle', classname: 'bg-success text-light' }),
        this.isUpload = ''
      )
    })
  }

  onFormat(data: any) {
    data.map((item: any) => {
      item.tongphi = item.tongphi.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    })
  }
  
  onFilterCategory(data:any){
    let filterData:any = [];
    for(let i=0; i<data.length;i++){
      if(data[i].loaibaohiem != "Kiến tạo giá trị"){
        filterData.push(data[i]);
      }
    }
    return filterData;
  }

  onSort() {
  }

  onDelete() {

  }

  dialogHandler(type: string, row: any, content: TemplateRef<any>) {
    this.dialogType = type
    type === 'view' ? (this.disableInput = true) : (this.disableInput = false)
    type === 'edit' && (this.tempID = row.id)
    this.getContractbyID(row.id)
    this._modal.open(content, { size: 'xl', centered: true, scrollable: true });
  }

  copyInputMessage(inputElement: any) {
    inputElement.select();
    document.execCommand('copy');
    inputElement.setSelectionRange(0, 0);
  }

  removeVietnameseTones(str: string) {
    str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
    str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
    str = str.replace(/đ/g, "d");
    str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A");
    str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E");
    str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I");
    str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O");
    str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U");
    str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y");
    str = str.replace(/Đ/g, "D");
    // Some system encode vietnamese combining accent as individual utf-8 characters
    // Một vài bộ encode coi các dấu mũ, dấu chữ như một kí tự riêng biệt nên thêm hai dòng này
    str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ""); // ̀ ́ ̃ ̉ ̣  huyền, sắc, ngã, hỏi, nặng
    str = str.replace(/\u02C6|\u0306|\u031B/g, ""); // ˆ ̆ ̛  Â, Ê, Ă, Ơ, Ư
    // Remove extra spaces
    // Bỏ các khoảng trắng liền nhau
    str = str.replace(/ + /g, " ");
    str = str.trim();
    // Remove punctuations
    // Bỏ dấu câu, kí tự đặc biệt
    str = str.replace(/!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|,|\.|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|_|`|-|{|}|\||\\/g, " ");
    return str;
  }

  error = ERROR
  pattern = VALID
  genders = GENDERS
  statusSeletor = STATUS_SELECTOR
  mqhnguoimua = BHSK_RELATIVE
}
