<div class="car-payment">
  <div class="step__controller">
    <app-step-controller [steps]="steps" [step]="33" [stepValue]="stepValue" [isWebview]="isWebview">
    </app-step-controller>
  </div>

  <form #f="ngForm">
    <div class="form--left">
      <div id="step_1" [ngClass]="stepValue == 1 ? 'step' : 'hidden'">
        <app-form-title [icon]="'time_to_leave'" [label]="'VIHICLE.VIHICLE_INFO'|translate"></app-form-title>
        <div class="form-group">
          <div class="text-field">
            <label for="bieN_XE">{{ 'PRODUCT.LICENSE_PLATE' | translate }}<span class="required">*</span></label>
            <input required min="10" autocomplete="off" type="text" placeholder="99A 999.99" id="bieN_XE" name="bieN_XE"
              [value]="bill.bieN_XE" [(ngModel)]="bill.bieN_XE" />
              <span *ngIf="f.controls['bieN_XE']?.valid" class="input--check"><i class="fa-solid fa-circle-check"></i></span>
            <div class="invalid">
              <small
                *ngIf="f.controls['bieN_XE']?.invalid && bill.bieN_XE.length === 0 && f.controls['bieN_XE']?.touched">{{error.required|translate}}</small>
            </div>
          </div>
          <div class="text-field">
            <label for="sO_MAY">{{ 'VIHICLE.ENGINE_NUMBER' | translate }}<span class="required">*</span></label>
            <input required autocomplete="off" type="text" id="sO_MAY" name="sO_MAY" [value]="bill.sO_MAY"
              [(ngModel)]="bill.sO_MAY" />
              <span *ngIf="f.controls['sO_MAY']?.valid" class="input--check"><i class="fa-solid fa-circle-check"></i></span>
            <div class="invalid">
              <small *ngIf="f.controls['sO_MAY']?.invalid && f.controls['sO_MAY']?.touched">{{error.required|translate}}</small>
            </div>
          </div>
          <div class="text-field">
            <label for="sO_KHUNG">{{ 'VIHICLE.FRAME_NUMBER' | translate }}<span class="required">*</span></label>
            <input required autocomplete="off" type="text" id="sO_KHUNG" name="sO_KHUNG" [value]="bill.sO_KHUNG"
              [(ngModel)]="bill.sO_KHUNG" />
              <span *ngIf="f.controls['sO_KHUNG']?.valid" class="input--check"><i class="fa-solid fa-circle-check"></i></span>
            <div class="invalid">
              <small
                *ngIf="f.controls['sO_KHUNG']?.invalid && f.controls['sO_KHUNG']?.touched">{{error.required|translate}}</small>
            </div>
          </div>
          <div class="text-field" *ngIf="isBSHCar">
            <label for="nguoN_GOC">{{ 'VIHICLE.ORIGINAL' | translate }}<span class="required">*</span></label>
            <select [required]="isBSHCar" name="nguoN_GOC" id="nguoN_GOC" [(ngModel)]="bill.nguoN_GOC">
              <option value="" selected disabled>{{ 'VIHICLE.SELECT_ORIGINAL' | translate }}...</option>
              <option *ngFor="let opt of original" [value]="opt.value">{{opt.label|translate}}</option>
            </select>
            <span *ngIf="f.controls['nguoN_GOC']?.valid" class="input--check"><i class="fa-solid fa-circle-check"></i></span>
            <div class="invalid">
              <small
                *ngIf="f.controls['nguoN_GOC']?.invalid && f.controls['nguoN_GOC']?.touched">{{error.select|translate}}</small>
            </div>
          </div>
          <div class="text-field">
            <label for="hanG_XE">{{ 'VIHICLE.CAR_BRAND' | translate }}<span class="required">*</span></label>
            <select required name="hanG_XE" id="hanG_XE" [(ngModel)]="bill.hanG_XE" (ngModelChange)="getCarSeri(bill.hanG_XE)">
              <option value="" selected disabled>{{ 'VIHICLE.SELECT_CAR_BRAND' | translate }}...</option>
              <option *ngFor="let opt of brands" [value]="opt.hang">{{opt.hang}}</option>
            </select>
            <span *ngIf="f.controls['hanG_XE']?.valid" class="input--check"><i class="fa-solid fa-circle-check"></i></span>
            <div class="invalid">
              <small
                *ngIf="f.controls['hanG_XE']?.invalid && f.controls['hanG_XE']?.touched">{{error.select|translate}}</small>
            </div>
          </div>
          <div class="text-field">
            <label for="hieU_XE">{{ 'VIHICLE.CAR_SERIES' | translate }}<span class="required">*</span></label>
            <select required name="hieU_XE" id="hieU_XE" [(ngModel)]="bill.hieU_XE">
              <option value="" selected disabled>{{bill.hanG_XE.length < 1 ? ('VIHICLE.LSCS'|translate) : ('VIHICLE.SELECT_CAR_SERIES'|translate)
                  }}</option>
              <option *ngFor="let opt of series" [value]="opt.hieu">{{opt.hieu}}</option>
            </select>
            <span *ngIf="f.controls['hieU_XE']?.valid" class="input--check"><i class="fa-solid fa-circle-check"></i></span>
            <div class="invalid">
              <!-- <small *ngIf="bill.hanG_XE.length < 1 && f.controls['hieU_XE']?.touched">{{error.series|translate}}</small> -->
              <small
                *ngIf="f.controls['hieU_XE']?.invalid && f.controls['hieU_XE']?.touched">{{error.select|translate}}</small>
            </div>
          </div>
          <div class="form-child">
            <div class="text-field">
              <label for="naM_SX">{{ 'VIHICLE.PUBLISH_YEAR' | translate }}<span class="required">*</span></label>
              <input required [pattern]="pattern.YEAR" autocomplete="off" type="text" id="naM_SX" name="naM_SX"
                [value]="bill.naM_SX" [(ngModel)]="bill.naM_SX"
                (ngModelChange)="f.controls['naM_SX']!.valid ? getFee() : ''" />
                <span *ngIf="f.controls['naM_SX']?.valid" class="input--check"><i class="fa-solid fa-circle-check"></i></span>
              <div class="invalid">
                <small
                  *ngIf="f.controls['naM_SX']?.invalid && f.controls['naM_SX']?.touched && bill.naM_SX.length < 1">{{error.required|translate}}</small>
                <small *ngIf="f.controls['naM_SX']?.errors?.['pattern']">{{error.year|translate}}</small>
              </div>
            </div>
            <div class="text-field">
              <label for="sO_CN">{{ 'VIHICLE.SEATS' | translate }}<span class="required">*</span></label>
              <input required autocomplete="off" min="1" max="2" [pattern]="pattern.SEAT" type="text" id="sO_CN"
                name="sO_CN" [value]="bill.sO_CN" [(ngModel)]="bill.sO_CN" (ngModelChange)="getFee()" />
                <span *ngIf="f.controls['sO_CN']?.valid" class="input--check"><i class="fa-solid fa-circle-check"></i></span>
              <div class="invalid">
                <small
                  *ngIf="f.controls['sO_CN']?.invalid && f.controls['sO_CN']?.touched && bill.sO_CN.length < 1">{{error.required|translate}}</small>
                <small *ngIf="f.controls['sO_CN']?.errors?.['pattern']">{{error.bien|translate}}</small>
              </div>
            </div>
          </div>
          <div class="form-child">
            <div class="text-field">
              <label for="sO_LP">{{ 'VIHICLE.CO_DRIVER' | translate }}</label>
              <input autocomplete="off" type="text" id="sO_LP" name="sO_LP" value="0" disabled ngModel/>
              <span *ngIf="f.controls['sO_LP']?.valid" class="input--check"><i class="fa-solid fa-circle-check"></i></span>
              <div class="invalid"></div>
            </div>
            <div class="text-field">
              <label for="ttai">{{ 'VIHICLE.TONNAGE' | translate }}
                <span class="required" *ngIf="formdsd === 'mdsdh'">*</span>
              </label>
              <input autocomplete="off" type="text" id="ttai" name="ttai" [value]="bill.ttai" [(ngModel)]="bill.ttai"
                (ngModelChange)="getFee()" [autofocus]="formdsd === 'mdsdh'" />
              <span *ngIf="f.controls['ttai']?.valid" class="input--check"><i class="fa-solid fa-circle-check"></i></span>
              <div class="invalid">
                <small *ngIf="formdsd === 'mdsdh' && bill.ttai === '0' || bill.ttai.length === 0">{{error.ttai|translate}}</small>
              </div>
            </div>
          </div>
          <div class="form-child">
            <div class="text-field">
              <label for="ngaY_HL">{{ 'COMMON.FROM_DATE' | translate }}<span class="required">*</span></label>
              <input required autocomplete="off" [type]="isWebview ? 'text' : 'date'" id="ngaY_HL" name="ngaY_HL" [value]="bill.ngaY_HL"
                [(ngModel)]="bill.ngaY_HL" [min]="isWebview?null:now" [pattern]="isWebview ? pattern.DATE : pattern.ALL" (ngModelChange)="onChangeFromDate()" />
              <span *ngIf="f.controls['ngaY_HL']?.valid&&isWebview&&validDate" class="input--check"><i class="fa-solid fa-circle-check"></i></span>
              <div class="invalid">
                <small *ngIf="f.controls['ngaY_HL']?.invalid">{{error.required|translate}}</small>
                <small *ngIf="f.controls['ngaY_HL']?.valid && bill.ngaY_HL.length > 0 && bill.ngaY_HL < (isWebview?null:now)">{{error.min_date|translate}}</small>
                <small *ngIf="f.controls['ngaY_HL']?.valid &&!validDate&&isWebview">{{error.min_date|translate}}</small>
              </div>
            </div>
            <div class="text-field">
              <label for="ngaY_KT">{{ 'COMMON.TO_DATE' | translate }}</label>
              <input readonly [type]="isWebview ? 'text' : 'date'" id="ngaY_KT" name="ngaY_KT" [value]="bill.ngaY_KT"
                [(ngModel)]="bill.ngaY_KT" />
                <span *ngIf="f.controls['ngaY_KT']?.valid" class="input--check"><i class="fa-solid fa-circle-check"></i></span>
              <div class="invalid"></div>
            </div>
          </div>
        </div>
        <app-form-title [icon]="'recommend'" [label]="'VIHICLE.USES'|translate"></app-form-title>
        <div class="check_purpose">
          <span>{{ 'VIHICLE.PURPOSE' | translate }}:</span>
          <input type="checkbox" id="mdsd" name="mdsd" [checked]="bill.mdsd === 'C'"
            (click)="bill.mdsd === 'K' ? bill.mdsd = 'C' : bill.mdsd = 'K'" (change)="getFee()" />
          <span *ngIf="f.controls['mdsd']?.valid" class="input--check"><i class="fa-solid fa-circle-check"></i></span>
          <label for="mdsd">{{ 'VIHICLE.BUSINESS' | translate }}</label>
        </div>
        <span>{{ 'VIHICLE.TRANSPORT' | translate }}:</span>
        <div class="form-group">
          <div class="form-check">
            <input type="radio" id="mdsdn" name="formdsd" value="mdsdn" [(ngModel)]="formdsd" (change)="getFee()"
              checked />
            <label for="mdsdn" (click)="bill.ttai === '0' ? '' : bill.ttai = '0'">{{ 'VIHICLE.PERSON' | translate }}</label>
          </div>
          <div class="form-check">
            <input type="radio" id="mdsdh" name="formdsd" value="mdsdh" [(ngModel)]="formdsd" (change)="getFee()" />
            <label for="mdsdh">{{ 'VIHICLE.GOODS' | translate }}</label>
          </div>
          <div class="form-check">
            <input type="radio" id="mdsdt" name="formdsd" value="mdsdt" [(ngModel)]="formdsd" (change)="getFee()" />
            <label for="mdsdt">{{ 'VIHICLE.PERSON' | translate }} &amp; {{ 'VIHICLE.GOODS' | translate }}</label>
          </div>
        </div>
        <div class="form-group invalid">
          <small
            *ngIf="formdsd === 'mdsdh' && bill.ttai === '0' || formdsd === 'mdsdh' && f.controls['sO_CN'].value > 3 || formdsd === 'mdsdh' && bill.ttai.length === 0">{{error.truck|translate}}</small>
        </div>

        <app-form-title [icon]="'business_center'" [label]="'Lĩnh vực kinh doanh'|translate"></app-form-title>
        <div class="checkbox-grid" *ngIf="formdsd === 'mdsdn'">
          <div class="flex">
            <input type="radio" name="formdsdn" id="xeN1" value="xeN1" [(ngModel)]="formdsdn"
              (ngModelChange)="onGetFeeWithRadio()">
            <label for="xeN1">{{ 'VIHICLE.NFCU' | translate }}</label>
          </div>
          <div class="flex" *ngIf="!isBSHCar">
            <input type="radio" name="formdsdn" id="xeN2" value="xeN2" [(ngModel)]="formdsdn"
              (ngModelChange)="onGetFeeWithRadio()">
            <label for="xeN2">{{ 'VIHICLE.VHITIA' | translate }}</label>
          </div>
          <div class="flex" *ngIf="!isBSHCar">
            <input type="radio" name="formdsdn" id="xeN3" value="xeN3" [(ngModel)]="formdsdn"
              (ngModelChange)="onGetFeeWithRadio()">
            <label for="xeN3">{{ 'VIHICLE.INTERCITY_BUS' | translate }}</label>
          </div>
          <div class="flex" *ngIf="!isBSHCar">
            <input type="radio" name="formdsdn" id="xeN4" value="xeN4" [(ngModel)]="formdsdn"
              (ngModelChange)="onGetFeeWithRadio()">
            <label for="xeN4">{{ 'VIHICLE.BUS' | translate }}</label>
          </div>
          <div class="flex" *ngIf="!isBSHCar">
            <input type="radio" name="formdsdn" id="xeN5" value="xeN5" [(ngModel)]="formdsdn"
              (ngModelChange)="onGetFeeWithRadio()">
            <label for="xeN5">{{ 'VIHICLE.GRAB_CAR' | translate }}</label>
          </div>
          <div class="flex">
            <input type="radio" name="formdsdn" id="xeN6" value="xeN6" [(ngModel)]="formdsdn"
              (ngModelChange)="onGetFeeWithRadio()">
            <label for="xeN6">{{ 'VIHICLE.TAXI' | translate }}</label>
          </div>
          <div class="flex" *ngIf="!isBSHCar">
            <input type="radio" name="formdsdn" id="xeN7" value="xeN7" [(ngModel)]="formdsdn"
              (ngModelChange)="onGetFeeWithRadio()">
            <label for="xeN7">{{ 'VIHICLE.PRACTICE_CAR' | translate }}</label>
          </div>
          <div class="flex">
            <input type="radio" name="formdsdn" id="xeN8" value="xeN8" [(ngModel)]="formdsdn"
              (ngModelChange)="onGetFeeWithRadio()">
            <label for="xeN8">{{ 'VIHICLE.AMBULANCE' | translate }}</label>
          </div>
          <div class="flex" *ngIf="!isBSHCar">
            <input type="radio" name="formdsdn" id="xeN10" value="xeN10" [(ngModel)]="formdsdn"
              (ngModelChange)="onGetFeeWithRadio()">
            <label for="xeN10">{{ 'VIHICLE.BUSINESS_CAR' | translate }} {{ isBSHCar ? '' : ('VIHICLE.OTHER'|translate) }}</label>
          </div>
        </div>

        <div class="checkbox-grid" *ngIf="formdsd === 'mdsdh'">
          <div class="flex">
            <input type="radio" name="formdsdh" id="xeH1" value="xeH1" [(ngModel)]="formdsdh"
              (ngModelChange)="onGetFeeWithRadio()">
            <label for="xeH1">{{ 'VIHICLE.TRAILERS' | translate }}</label>
          </div>
          <div class="flex" *ngIf="!isBSHCar">
            <input type="radio" name="formdsdh" id="xeH2" value="xeH2" [(ngModel)]="formdsdh"
              (ngModelChange)="onGetFeeWithRadio()">
            <label for="xeH2">{{ 'VIHICLE.OVER_10_TONS' | translate }}</label>
          </div>
          <div class="flex" *ngIf="!isBSHCar">
            <input type="radio" name="formdsdh" id="xeH3" value="xeH3" [(ngModel)]="formdsdh"
              (ngModelChange)="onGetFeeWithRadio()">
            <label for="xeH3">{{ 'VIHICLE.1' | translate }}</label>
          </div>
          <div class="flex">
            <input type="radio" name="formdsdh" id="xeH4" value="xeH4" [(ngModel)]="formdsdh"
              (ngModelChange)="onGetFeeWithRadio()">
            <label for="xeH4">{{ isBSHCar ? ('VIHICLE.BUSINESS_TRUCK'|translate) : ('VIHICLE.TRUCK'|translate) }}</label>
          </div>
          <div class="flex" *ngIf="!isBSHCar">
            <input type="radio" name="formdsdh" id="xeH5" value="xeH5" [(ngModel)]="formdsdh"
              (ngModelChange)="onGetFeeWithRadio()">
            <label for="xeH5">{{ 'VIHICLE.2' | translate }}</label>
          </div>
          <div class="flex" *ngIf="!isBSHCar">
            <input type="radio" name="formdsdh" id="xeH6" value="xeH6" [(ngModel)]="formdsdh"
              (ngModelChange)="onGetFeeWithRadio()">
            <label for="xeH6">{{ 'VIHICLE.3' | translate }}</label>
          </div>
          <div class="flex" *ngIf="!isBSHCar">
            <input type="radio" name="formdsdh" id="xeH7" value="xeH7" [(ngModel)]="formdsdh"
              (ngModelChange)="onGetFeeWithRadio()">
            <label for="xeH7">{{ 'VIHICLE.4' | translate }}</label>
          </div>
          <div class="flex">
            <input type="radio" name="formdsdh" id="xeH8" value="xeH8" [(ngModel)]="formdsdh"
              (ngModelChange)="onGetFeeWithRadio()">
            <label for="xeH8" (click)="bill.mdsd = 'K'">{{ 'VIHICLE.5' | translate }}</label>
          </div>
          <div class="flex" *ngIf="isBSHCar">
            <input type="radio" name="formdsdh" id="xeH9" value="xeH9" [(ngModel)]="formdsdh"
              (ngModelChange)="onGetFeeWithRadio()">
            <label for="xeH9">{{ 'VIHICLE.6' | translate }}</label>
          </div>
          <div class="flex" *ngIf="isBSHCar">
            <input type="radio" name="formdsdh" id="xeH10" value="xeH10" [(ngModel)]="formdsdh"
              (ngModelChange)="onGetFeeWithRadio()">
            <label for="xeH10">{{ 'VIHICLE.7' | translate }}</label>
          </div>
        </div>

        <div class="checkbox-grid" *ngIf="formdsd === 'mdsdt'">
          <div class="flex" (click)="bill.mdsd = 'C'">
            <input type="radio" name="formdsdt" id="xeT1" value="xeT1" [(ngModel)]="formdsdt"
              (ngModelChange)="onGetFeeWithRadio()">
            <label for="xeT1">{{ 'VIHICLE.8' | translate }} {{ isBSHCar ? ('VIHICLE.COMMERCE'|translate) : '' }} (Pick-up)</label>
          </div>
          <div class="flex" (click)="bill.mdsd = 'K'">
            <input type="radio" name="formdsdt" id="xeT2" value="xeT2" [(ngModel)]="formdsdt"
              (ngModelChange)="onGetFeeWithRadio()">
            <label for="xeT2">{{ isBSHCar ? ('VIHICLE.BUSINESS_PICK_UP'|translate) : ('VIHICLE.OTHER_USE_CAR'|translate) }} </label>
          </div>
        </div>
        <br>

        <ng-container [ngTemplateOutlet]="prodStep"></ng-container>
      </div>

      <div id="step_2" [ngClass]="stepValue == 2 ? 'step' : 'hidden'">
        <app-form-title [icon]="'verified_user'" [label]="'PRODUCT.PROD_VOLUME'|translate"></app-form-title>
        <div class="car-payment-range">
          <div class="flex">
            <h3>1. {{ 'VIHICLE.CTP_INSURANCE' | translate }}</h3>
            <input type="checkbox" id="bbtn" name="bbtn" [checked]="bill.bbtn === 'C'"
            (click)="bill.bbtn === 'K' ? bill.bbtn = 'C' : bill.bbtn = 'K'" (change)="getFee()" />
            <span *ngIf="f.controls['bbtn']?.valid" class="input--check"><i class="fa-solid fa-circle-check"></i></span>
          </div>
          <div class="form-group">
            <div class="text-field">
              <label for="for_people">{{ 'VIHICLE.FOR_PERSON' | translate }} {{ 'VIHICLE.ACC' | translate }}</label>
              <span id="for_people" class="disabled">150,000,000</span>
            </div>
            <div class="text-field">
              <label for="for_assets">{{ 'VIHICLE.FOR_GOODS' | translate }} {{ 'VIHICLE.ACC' | translate }}</label>
              <span id="for_assets" class="disabled">100,000,000</span>
            </div>
            <div class="text-field">
              <label for="for_clients">{{ 'VIHICLE.FOR_CLIENT' | translate }} {{ 'VIHICLE.ACC' | translate }}</label>
              <span id="for_clients" class="disabled">150,000,000</span>
            </div>
          </div>
          <h3>2. {{ 'VIHICLE.TN' | translate }}</h3>
          <div class="form-group">
            <div class="text-field">
              <label for="respons_people">{{ 'VIHICLE.LOR' | translate }} {{ 'VIHICLE.FOR_PERSON' | translate }}</label>
              <select name="respons_people" id="respons_people" [(ngModel)]="bill.tk" (change)="getFee()">
                <option *ngFor="let opt of respons_people" [value]="opt.value">{{opt.label|translate}}</option>
              </select>
              <span *ngIf="f.controls['respons_people']?.valid&&f.controls['respons_people']?.dirty" class="input--check"><i class="fa-solid fa-circle-check"></i></span>
              <div class="invalid"></div>
            </div>
            <div class="text-field">
              <label for="respons_goods">{{ 'VIHICLE.LOR' | translate }} {{ 'VIHICLE.FOR_GOODS' | translate }}</label>
              <select name="respons_goods" id="respons_goods" [(ngModel)]="bill.tb" (change)="getFee()">
                <option *ngFor="let opt of respons_assets" [value]="opt.value">{{opt.label|translate}}</option>
              </select>
              <span *ngIf="f.controls['respons_goods']?.valid&&f.controls['respons_goods']?.dirty" class="input--check"><i class="fa-solid fa-circle-check"></i></span>
              <div class="invalid"></div>
            </div>
            <div class="text-field">
              <label for="respons_client">{{ 'VIHICLE.LOR' | translate }} {{ 'VIHICLE.FOR_CLIENT' | translate }}</label>
              <span id="respons_client" class="disabled">0</span>
              <div class="invalid"></div>
            </div>
          </div>
          <h3>3. {{ 'VIHICLE.CLIENT_INSIDE' | translate }}</h3>
          <div class="form-group">
            <div class="text-field">
              <label for="sO_CN">{{ 'VIHICLE.NUMBER_OF_PEOPLE' | translate }} {{ 'VIHICLE.SITTING' | translate }}</label>
              <input autocomplete="off" type="text" id="sO_CN" name="sO_CN" [value]="bill.sO_CN"
                [(ngModel)]="bill.sO_CN" (ngModelChange)="getFee()"/>
                <span *ngIf="f.controls['sO_CN']?.valid" class="input--check"><i class="fa-solid fa-circle-check"></i></span>
              <div class="invalid"></div>
            </div>
            <div class="text-field">
              <label for="responsibility">{{ 'VIHICLE.LOR' | translate }} {{ 'VIHICLE.ACC' | translate }}</label>
              <select name="responsibility" id="responsibility" [(ngModel)]="bill.tl" (change)="getFee()">
                <option *ngFor="let opt of respons_drivers" [value]="opt.value">{{opt.label|translate}}</option>
              </select>
              <span *ngIf="f.controls['responsibility']?.valid&&f.controls['responsibility']?.dirty" class="input--check"><i class="fa-solid fa-circle-check"></i></span>
              <div class="invalid"></div>
            </div>
            <div class="text-field">
              <label for="sO_LP">{{ 'VIHICLE.CO_DRIVER' | translate }}</label>
              <input autocomplete="off" type="text" id="sO_LP" name="sO_LP" value="0" disabled />
              <div class="invalid"></div>
            </div>
            <div class="text-field">
              <label for="responsibility">{{ 'VIHICLE.LOR' | translate }} {{ 'VIHICLE.ACC' | translate }}</label>
              <select name="responsibility" id="responsibility" [(ngModel)]="bill.tl" (change)="getFee()">
                <option *ngFor="let opt of respons_drivers" [value]="opt.value">{{opt.label|translate}}</option>
              </select>
              <span *ngIf="f.controls['responsibility']?.valid&&f.controls['responsibility']?.dirty" class="input--check"><i class="fa-solid fa-circle-check"></i></span>
            </div>
          </div>
          <app-form-title [icon]="'paid'" [label]="'PRODUCT.FEE_INFO'|translate"></app-form-title>
          <div class="form-group">
            <div class="form-child">
              <div class="text-field">
                <label for="phi">{{ 'PAYMENT.FEE' | translate }}</label>
                <input autocomplete="off" type="text" id="phi" name="phi" readonly [value]="fee.phi" [(ngModel)]="fee.phi"/>
                <span *ngIf="f.controls['phi']?.valid&&f.controls['phi']?.dirty" class="input--check"><i class="fa-solid fa-circle-check"></i></span>
                <div class="invalid"></div>
              </div>
              <div class="text-field">
                <label for="thue">{{ 'PAYMENT.TAX' | translate }}</label>
                <input autocomplete="off" type="text" id="thue" name="thue" readonly [value]="fee.thue" [(ngModel)]="fee.thue"/>
                <span *ngIf="f.controls['thue']?.valid" class="input--check"><i class="fa-solid fa-circle-check"></i></span>
                <div class="invalid"></div>
              </div>
            </div>
            <!-- <div class="text-field">
              <label for="maVoucher">{{ 'PAYMENT.VOUCHER_ID' | translate }}</label>
              <input autocomplete="off" type="text" id="maVoucher" name="maVoucher"
                [placeholder]="'PRODUCT.INSERT_VOUCHER'|translate" [value]="voucher.maVoucher"
                [(ngModel)]="voucher.maVoucher"
                (blur)="getDiscount(voucher.maVoucher)"/>
                <span *ngIf="f.controls['phi']?.valid&&isTrueVoucher==true" class="input--check"><i
                  class="fa-solid fa-circle-check"></i></span>
                <span *ngIf="f.controls['phi']?.valid&&isTrueVoucher==false" class="input--check-false"><i class="fa-solid fa-circle-xmark"></i></span>
              <div class="invalid">
                <small
                  *ngIf="voucher.maVoucher&& voucher.status === false">{{error.voucher|translate}}</small>
              </div>
            </div> -->
          </div>
        </div>

        <ng-container [ngTemplateOutlet]="prodStep"></ng-container>
      </div>

      <div id="step_3" [ngClass]="stepValue == 3 ? 'step' : 'hidden'">
        <app-form-title [icon]="'badge'" [label]="'VIHICLE.OWNER_INFO'|translate" [isLonger]="true" [note]="'VIHICLE.NOTE'|translate">
        </app-form-title>
        <div class="typeofcustomer">
          <span>{{ 'COMMON.TYPE' | translate }}:</span>
          <input type="checkbox" id="pers" name="isPerson" [value]="isPerson" [(ngModel)]="isPerson"
          (click)="isCorp = false ; isPerson=true;onValidBirthday(form.nG_SINH)" />
          <label for="pers">{{ 'COMMON.PERSONAL' | translate }}</label>
          <input type="checkbox" id="corp" name="isCorp" [value]="isCorp" [(ngModel)]="isCorp"
          (click)="isPerson = false ; isCorp=true;onValidBirthday(form.nG_SINH)" />
          <label for="corp">{{ 'COMMON.COMPANY' | translate }}</label>
        </div>

        <div class="form-group">
          <div class="form-grid">
            <div class="text-field">
              <label for="cmt">{{isCorp ? ('PRODUCT.TAX_ID'|translate) : ('COMMON.IDENTIFY_CARD'|translate)}}<span class="required">*</span></label>
              <input required autocomplete="off" type="text" [pattern]="isCorp ?pattern.ALL :pattern.CCCD" id="cmt" name="cmt" [value]="form.cmt" [(ngModel)]="form.cmt" />
              <span *ngIf="f.controls['cmt']?.valid" class="input--check"><i class="fa-solid fa-circle-check"></i></span>
              <div class="invalid">
                <small *ngIf="f.controls['cmt']?.invalid && f.controls['cmt']?.touched && form.cmt.length < 1">
                  {{error.required|translate}}
                </small>
                <small *ngIf="f.controls['cmt']?.errors?.['pattern']">{{error.identify|translate}}</small>
              </div>
            </div>
            <div class="text-field">
              <label for="mobi">{{ 'COMMON.PHONE_NUMBER' | translate }}<span class="required">*</span></label>
              <input required maxlength="10" [pattern]="pattern.PHONE" autocomplete="off" type="tel" id="mobi"
                name="mobi" [value]="form.mobi" [(ngModel)]="form.mobi" />
              <span *ngIf="f.controls['mobi']?.valid" class="input--check"><i class="fa-solid fa-circle-check"></i></span>
              <div class="invalid">
                <small
                  *ngIf="f.controls['mobi']?.invalid && f.controls['mobi']?.touched && form.mobi.length < 1">{{error.required|translate}}</small>
                <small *ngIf="f.controls['mobi']?.errors?.['pattern']">{{error.phone|translate}}</small>
              </div>
            </div>
            <div class="text-field">
              <label for="ten">{{isCorp ? ('COMMON.COMPANY_NAME'|translate) : ('COMMON.FULL_NAME'|translate)}}<span class="required">*</span></label>
              <input required autocomplete="off" type="text" id="ten" name="ten"
                [value]="form.ten" [(ngModel)]="form.ten" (blur)="onCapitalize(1);onNumberIn(1)" />
                <span *ngIf="f.controls['ten']?.valid" class="input--check"><i class="fa-solid fa-circle-check"></i></span>
              <div [class]="isNumberIn?'alertMessage':'invalid'">
                <small
                  *ngIf="f.controls['ten']?.invalid && f.controls['ten']?.touched && form.ten.length < 1">{{error.required|translate}}</small>
                  <small *ngIf="isNumberIn">{{error.number_in|translate}}</small>
              </div>
            </div>
            <div class="text-field">
              <label for="nG_SINH">{{isCorp ? ('PRODUCT.FOUNDING'|translate) : ('PRODUCT.BIRTHDAY'|translate)}}<span class="required">*</span></label>
              <input required minlength="10" maxlength="10" autocomplete="off" [type]="isWebview ? 'text' : 'date'" [max]="now" id="nG_SINH" name="nG_SINH"
                [value]="form.nG_SINH" [(ngModel)]="form.nG_SINH" placeholder="20-02-2002"  [pattern]="isWebview ? pattern.DATE : pattern.ALL"
                (ngModelChange)="onValidBirthday(form.nG_SINH)" />
                <span *ngIf="f.controls['nG_SINH']?.valid&&isWebview&&validBirth" class="input--check"><i class="fa-solid fa-circle-check"></i></span>
              <div class="invalid">
                <small *ngIf="f.controls['nG_SINH']?.touched && !f.controls['nG_SINH'].valid">{{error.short|translate}}</small>
                <small *ngIf="f.controls['nG_SINH']?.touched && form.nG_SINH.length > 1 && validBirth == false">
                  {{error.max_birth|translate}}
                </small>
                <small *ngIf="f.controls['nG_SINH']?.invalid && f.controls['nG_SINH']?.touched && form.nG_SINH.length < 1">
                  {{error.shortReq|translate}}
                </small>
                <small *ngIf="f.controls['nG_SINH']?.errors?.['pattern'] && isWebview">{{error.date|translate}}</small>
              </div>
            </div>
            <div class="input-file" (click)="openImageModal(viewImage, false)" *ngIf="!tempFile.front || !tempFile.back">
              <label class="input-label">
                <span class="material-icons">collections</span><br>
                <span class="input-span">{{ 'COMMON.UPLOAD_IMAGE' | translate }}<br>{{ 'COMMON.IDENTIFY_CARD' | translate }}</span>
              </label>
            </div>
            <div class="uploaded-file" (click)="openImageModal(viewImage, false)" [title]="'COMMON.IDENTIFY_IMAGE'|translate"
              *ngIf="form.files.front && form.files.back && tempFile.front && tempFile.back">
              <img [src]="form.files.front" [alt]="'COMMON.IDENTIFY_FRONT'|translate">
              <img [src]="form.files.back" [alt]="'COMMON.IDENTIFY_BACK'|translate">
            </div>
          </div>
        </div>
        <div class="full">
          <div class="text-field">
            <label for="email">Email<span class="required">*</span></label>
            <input required [email]="pattern.EMAIL" autocomplete="off" type="email" id="email" name="email"
              [value]="form.email" [(ngModel)]="form.email" />
              <span *ngIf="f.controls['email']?.valid" class="input--check"><i class="fa-solid fa-circle-check"></i></span>
            <div class="invalid">
              <small
                *ngIf="f.controls['email']?.invalid && f.controls['email']?.touched && form.email.length < 1">{{error.required|translate}}</small>
              <small *ngIf="f.controls['email']?.errors?.['email']">{{error.email|translate}}</small>
            </div>
          </div>
          <div class="text-field">
            <label for="dchi">{{ 'COMMON.ADDRESS' | translate }}<span class="required">*</span></label>
            <input required autocomplete="off" type="text" id="dchi" name="dchi" [value]="form.dchi"
              [(ngModel)]="form.dchi" />
              <span *ngIf="f.controls['dchi']?.valid" class="input--check"><i class="fa-solid fa-circle-check"></i></span>
            <div class="invalid">
              <small *ngIf="f.controls['dchi']?.invalid && f.controls['dchi']?.touched">{{error.required|translate}}</small>
            </div>
          </div>
        </div>
        <!-- other -->

        <!-- <div class="step-flex">
          <input type="checkbox" id="other" name="isOther" [value]="isOther" [(ngModel)]="isOther" />
          <label for="other">{{ 'VIHICLE.OTHER_OWNER' | translate }}</label>
        </div>
        <div *ngIf="isOther">
          <div class="typeofcustomer">
            <span>{{ 'COMMON.TYPE' | translate }}:</span>
            <input type="checkbox" id="oPers" name="oIsPerson" [value]="isOtherPerson" [(ngModel)]="isOtherPerson"
            (click)="isOtherCorp = false ; isOtherPerson=true;onValidOtherBirthday(form.nG_SINHM)" />
            <label for="oPers">{{ 'COMMON.PERSONAL' | translate }}</label>
            <input type="checkbox" id="oCorp" name="oIsCorp" [value]="isOtherCorp" [(ngModel)]="isOtherCorp"
            (click)="isOtherPerson = false ; isOtherCorp=true;onValidOtherBirthday(form.nG_SINHM)" />
            <label for="oCorp">{{ 'COMMON.COMPANY' | translate }}</label>
          </div>

          <div class="form-group">
            <div class="form-grid">
              <div class="text-field">
                <label for="cmtm">{{isOtherCorp ? ('PRODUCT.TAX_ID'|translate) : ('COMMON.IDENTIFY_CARD'|translate)}}<span class="required">*</span></label>
                <input required autocomplete="off" type="text" [pattern]="pattern.CCCD" id="cmtm" name="cmtm" [value]="form.cmtm" [(ngModel)]="form.cmtm" />
                <span *ngIf="f.controls['cmtm']?.valid" class="input--check"><i class="fa-solid fa-circle-check"></i></span>
                <div class="invalid">
                  <small *ngIf="f.controls['cmtm']?.invalid && f.controls['cmtm']?.touched && form.cmtm.length < 1">
                    {{error.required|translate}}
                  </small>
                  <small *ngIf="f.controls['cmtm']?.errors?.['pattern']">{{error.identify|translate}}</small>
                </div>
              </div>
              <div class="text-field">
                <label for="mobim">{{ 'COMMON.PHONE_NUMBER' | translate }}<span class="required">*</span></label>
                <input required="{{isOther}}" maxlength="10" [pattern]="pattern.PHONE" autocomplete="off" type="text"
                  id="mobim" name="mobim" [value]="form.mobim" [(ngModel)]="form.mobim" />
                  <span *ngIf="f.controls['mobim']?.valid" class="input--check"><i class="fa-solid fa-circle-check"></i></span>
                <div class="invalid">
                  <small
                    *ngIf="f.controls['mobim']?.invalid && f.controls['mobim']?.touched && form.mobim.length < 1">{{error.required|translate}}</small>
                  <small *ngIf="f.controls['mobim']?.errors?.['pattern']">{{error.phone|translate}}</small>
                </div>
              </div>
              <div class="text-field">
                <label for="tenm">{{isOtherCorp ? ('COMMON.COMPANY_NAME'|translate) : ('COMMON.FULL_NAME'|translate)}}<span class="required">*</span></label>
                <input required autocomplete="off" type="text" id="tenm" name="tenm"
                  [value]="form.tenm" [(ngModel)]="form.tenm" (blur)="onCapitalize(0);onNumberIn(0)" />
                  <span *ngIf="f.controls['tenm']?.valid" class="input--check"><i class="fa-solid fa-circle-check"></i></span>
                <div [class]="isNumberInP?'alertMessage':'invalid'">
                  <small
                    *ngIf="f.controls['tenm']?.invalid && f.controls['tenm']?.touched && form.tenm.length < 1">{{error.required|translate}}</small>
                    <small *ngIf="isNumberInP">{{error.number_in|translate}}</small>
                </div>
              </div>
              <div class="text-field">
                <label for="nG_SINHM">{{isOtherCorp ? ('PRODUCT.FOUNDING'|translate) : ('PRODUCT.BIRTHDAY'|translate)}}<span class="required">*</span></label>
                <input required minlength="10" maxlength="10" autocomplete="off" [type]="isWebview ? 'text' : 'date'" [max]="now" id="nG_SINHM" name="nG_SINHM"
                  [value]="form.nG_SINHM" [(ngModel)]="form.nG_SINHM" placeholder="20-02-2002"  [pattern]="isWebview ? pattern.DATE : pattern.ALL"
                  (ngModelChange)="onValidOtherBirthday(form.nG_SINHM)" />
                  <span *ngIf="f.controls['nG_SINHM']?.valid&&isWebview&&validOtherBirth" class="input--check"><i class="fa-solid fa-circle-check"></i></span>
                <div class="invalid">
                  <small *ngIf="f.controls['nG_SINHM']?.touched && !f.controls['nG_SINHM'].valid">{{error.short|translate}}</small>
                  <small *ngIf="f.controls['nG_SINHM']?.touched && form.nG_SINHM.length > 1 && validOtherBirth == false&& isOther">
                    {{error.max_birth|translate}}
                  </small>
                  <small *ngIf="f.controls['nG_SINHM']?.invalid && f.controls['nG_SINHM']?.touched && form.nG_SINHM.length < 1">
                    {{error.shortReq|translate}}
                  </small>
                  <small *ngIf="f.controls['nG_SINHM']?.errors?.['pattern'] && isWebview">{{error.date|translate}}</small>
                </div>
              </div>
              <div class="input-file" (click)="openImageModal(viewImage, true)" *ngIf="!tempFileM.front || !tempFileM.back">
                <label class="input-label">
                  <span class="material-icons">collections</span><br>
                  <span class="input-span">{{ 'COMMON.UPLOAD_IMAGE' | translate }}<br>{{ 'COMMON.IDENTIFY_CARD' | translate }}</span>
                </label>
              </div>
              <div class="uploaded-file" (click)="openImageModal(viewImage, true)" [title]="'COMMON.IDENTIFY_IMAGE'|translate"
                *ngIf="form.fileMs.front && form.fileMs.back && tempFileM.front && tempFileM.back">
                <img [src]="form.fileMs.front" [alt]="'COMMON.IDENTIFY_FRONT'|translate">
                <img [src]="form.fileMs.back" [alt]="'COMMON.IDENTIFY_BACK'|translate">
              </div>
            </div>
          </div>
          <div class="full">
            <div class="text-field">
              <label for="emailm">Email<span class="required">*</span></label>
              <input [required]="isOther" [email]="pattern.EMAIL" autocomplete="off" type="text" id="emailm"
                name="emailm" [value]="form.emailm" [(ngModel)]="form.emailm" />
                <span *ngIf="f.controls['emailm']?.valid" class="input--check"><i class="fa-solid fa-circle-check"></i></span>
              <div class="invalid">
                <small
                  *ngIf="f.controls['emailm']?.invalid && f.controls['emailm']?.touched && form.email.length < 1">{{error.required|translate}}</small>
                <small *ngIf="f.controls['emailm']?.errors?.['email']">{{error.email|translate}}</small>
              </div>
            </div>
            <div class="text-field">
              <label for="dchim">{{ 'COMMON.ADDRESS' | translate }}<span class="required">*</span></label>
              <input [required]="isOther" autocomplete="off" type="text" id="dchim" name="dchim" [value]="form.dchim"
                [(ngModel)]="form.dchim" />
                <span *ngIf="f.controls['dchim']?.valid" class="input--check"><i class="fa-solid fa-circle-check"></i></span>
              <div class="invalid">
                <small *ngIf="f.controls['dchim']?.invalid && f.controls['dchim']?.touched">{{error.required|translate}}</small>
              </div>
            </div>
          </div>
        </div> -->
        <br>

        <ng-container [ngTemplateOutlet]="prodStep"></ng-container>
      </div>
    </div>

    <div id="last-step" [ngClass]="stepValue === 4 ? 'step' : 'hidden'" *ngIf="isWebview === false">
      <app-form-title [label]="'PRODUCT.FEE_INFO'|translate"></app-form-title>
      <hr>
      <div class="last-step--label">
        <span>{{ 'PAYMENT.FEE' | translate }}:</span>
        <span>{{fee.phi}} VND</span>
      </div>
      <div class="last-step--label">
        <span>{{ 'PAYMENT.TAX' | translate }}:</span>
        <span>{{fee.thue}} VND</span>
      </div>
      <hr>
      <div class="flex">
        <img class="form__payment--thumb" src="../../../assets/frontend/images/coupon.png" alt="voucher">
        <span class="form__payment--voucher">Voucher:</span>
      </div>
      <div class="flex space" *ngIf="!meetVoucher">
        <div class="text-field launcher">
          <label for="launcher">{{ 'COMMON.REFERRAL_CODE' | translate }}</label>
          <input autocomplete="off" type="text" id="launcher" name="launcher" [placeholder]="'PRODUCT.A00000001'|translate"
            [value]="form.magioithieu" [(ngModel)]="form.magioithieu" [disabled]="isShared||ma_nvAppVoucher||userCode"
            (ngModelChange)="getLauncher(form.magioithieu)" />
            <span *ngIf="f.controls['launcher']?.value!=''&&f.controls['launcher']?.value!=undefined&&isTrueLauncher==true" class="input--check"><i
              class="fa-solid fa-circle-check"></i></span>
            <span *ngIf="f.controls['launcher']?.value!=''&&f.controls['launcher']?.value!=undefined&&isTrueLauncher==false" class="input--check-false"><i class="fa-solid fa-circle-xmark"></i></span>
        </div>
        <div class="text-field maVoucher">
          <label for="maVoucher">{{ 'PAYMENT.VOUCHER_ID' | translate }}</label>
          <input autocomplete="off" type="text" id="maVoucher" name="maVoucher"
            [placeholder]="'PRODUCT.INSERT_VOUCHER'|translate" [value]="voucher.maVoucher" 
            [(ngModel)]="voucher.maVoucher"
            (blur)="getDiscount(voucher.maVoucher)"/>
            <span *ngIf="f.controls['maVoucher']?.value!=''&&isTrueVoucher==true" class="input--check"><i
              class="fa-solid fa-circle-check"></i></span>
            <span *ngIf="f.controls['maVoucher']?.value!=''&&isTrueVoucher==false" class="input--check-false"><i class="fa-solid fa-circle-xmark"></i></span>
          <div class="invalid">
            <small
              *ngIf="voucher.maVoucher&& voucher.status === false">{{error.voucher|translate}}</small>
          </div>
        </div> 
      </div>
      <div class="flex space" *ngIf="meetVoucher">
        <div class="text-field maVoucher" style="width: 100%;">
          <label for="maVoucher">{{ 'PAYMENT.VOUCHER_ID' | translate }}</label>
          <input [disabled]="meetVoucher" autocomplete="off" type="text" id="maVoucher" name="maVoucher"
            [placeholder]="'PRODUCT.INSERT_VOUCHER'|translate" [value]="voucher.maVoucher"
            [(ngModel)]="voucher.maVoucher" (blur)="getDiscount(voucher.maVoucher)" />
          <span *ngIf="f.controls['maVoucher']?.value!=''&&isTrueVoucher==true" class="input--check"><i
              class="fa-solid fa-circle-check"></i></span>
          <span *ngIf="f.controls['maVoucher']?.value!=''&&isTrueVoucher==false" class="input--check-false"><i
              class="fa-solid fa-circle-xmark"></i></span>
          <div class="invalid">
            <small *ngIf="voucher.maVoucher&& voucher.status === false">{{error.voucher|translate}}</small>
          </div>
        </div>
      </div>
      <div *ngIf="form.nguoiGioiThieu" style="color: rgb(28, 124, 192) !important;">
        <span>{{ 'PAYMENT.PRESENTER' | translate }}:</span>
        <span>{{form.nguoiGioiThieu}}</span>
      </div>
      <div *ngIf="f.controls['launcher']?.value!=''&&f.controls['launcher']?.value!=undefined&&!isTrueLauncher">
        <span>{{'PRODUCT.WRONG_CODE'|translate}}</span>
      </div>
      <div class="last-step--label voucher--save">
        <span>{{ 'PAYMENT.SALE' | translate }}:</span>
        <span>- {{save}} VND</span>
      </div>
      <div class="last-step--label" style="font-weight: 500;">
        <span>{{ 'PAYMENT.TOTAL' | translate }}:</span>
        <span>{{form.ttoan}} VND</span>
      </div>
      <hr>
      <button class="form__payment" (click)="onSubmit(f)"
      [disabled]="submited" [ngClass]="submited ? 'opacity-50' : 'opacity-100'">{{ 'COMMON.PAY' | translate }}</button>
    </div>

    <div id="webview-step" [ngClass]="stepValue === 4 ? 'step' : 'hidden'" *ngIf="isWebview">
      <app-form-title [label]="'PRODUCT.CLIENT_INFO'|translate"></app-form-title>
      <hr>
      <div class="step4-flex">
        <span class="label">{{ 'PAYMENT.CLIENT_NAME' | translate }}</span>
        <span class="info">{{form.ten}}</span>
      </div>
      <div class="step4-flex">
        <span class="label">{{ 'PAYMENT.CLIENT_EMAIL' | translate }}</span>
      <span class="info">{{form.email}}</span>
      </div>
      <div class="step4-flex">
        <span class="label">{{ 'PRODUCT.CLIENT_PHONE_NUMBER' | translate }}</span>
      <span class="info">{{form.mobi}}</span>
      </div>
      <hr  *ngIf="!isApp">
      <div *ngIf="!isApp" class="flex space">
        <div class="text-field launcher">
          <label for="launcher">{{ 'COMMON.REFERRAL_CODE' | translate }}</label>
          <input autocomplete="off" type="text" id="launcher" name="launcher" [placeholder]="'PRODUCT.A00000001'|translate"
            [value]="form.magioithieu" [(ngModel)]="form.magioithieu" [disabled]="isShared||ma_nvAppVoucher||userCode"
            (ngModelChange)="getLauncher(form.magioithieu)" />
            <span *ngIf="f.controls['launcher']?.value!=''&&f.controls['launcher']?.value!=undefined&&isTrueLauncher==true" class="input--check"><i
              class="fa-solid fa-circle-check"></i></span>
            <span *ngIf="f.controls['launcher']?.value!=''&&f.controls['launcher']?.value!=undefined&&isTrueLauncher==false" class="input--check-false"><i class="fa-solid fa-circle-xmark"></i></span>
        </div>
        <div class="text-field maVoucher">
          <label for="maVoucher">{{ 'PAYMENT.VOUCHER_ID' | translate }}</label>
          <input autocomplete="off" type="text" id="maVoucher" name="maVoucher"
            [placeholder]="'PRODUCT.INSERT_VOUCHER'|translate" [value]="voucher.maVoucher" 
            [(ngModel)]="voucher.maVoucher"
            (blur)="getDiscount(voucher.maVoucher)"/>
            <span *ngIf="f.controls['maVoucher']?.value!=''&&isTrueVoucher==true" class="input--check"><i
              class="fa-solid fa-circle-check"></i></span>
            <span *ngIf="f.controls['maVoucher']?.value!=''&&isTrueVoucher==false" class="input--check-false"><i class="fa-solid fa-circle-xmark"></i></span>
          <div class="invalid">
            <small
              *ngIf="voucher.maVoucher&& voucher.status === false">{{error.voucher|translate}}</small>
          </div>
        </div> 
      </div>
      <div *ngIf="form.nguoiGioiThieu" style="color: rgb(28, 124, 192) !important; font-size: 12px;">
        <span>{{ 'PAYMENT.PRESENTER' | translate }}: {{form.nguoiGioiThieu}}</span>
      </div>
      <app-form-title [label]="'PRODUCT.FEE_INFO'|translate"></app-form-title>
      <hr>
      <div *ngIf="f.controls['launcher']?.value!=''&&f.controls['launcher']?.value!=undefined&&!isTrueLauncher">
        <span>{{'PRODUCT.WRONG_CODE'|translate}}</span>
      </div>
      <div class="step4-flex">
        <span class="label">{{ 'PAYMENT.FEE' | translate }}</span>
      <span class="info">{{fee.phi}}</span>
      </div>
      <div class="step4-flex">
        <span class="label">{{ 'PAYMENT.TAX' | translate }}</span>
      <span class="info">{{fee.thue}} VND</span>
      </div>
      <!-- <div class="text-field">
        <label for="maVoucher">{{ 'PAYMENT.VOUCHER_ID' | translate }}</label>
        <input autocomplete="off" type="text" id="maVoucher" name="maVoucher"
          [placeholder]="'PRODUCT.INSERT_VOUCHER'|translate" [value]="voucher.maVoucher"
          [(ngModel)]="voucher.maVoucher" (blur)="getDiscount(voucher.maVoucher)" />
        <span *ngIf="f.controls['phi']?.valid&&isTrueVoucher==true" class="input--check"><i
            class="fa-solid fa-circle-check"></i></span>
        <span *ngIf="f.controls['phi']?.valid&&isTrueVoucher==false" class="input--check-false"><i
            class="fa-solid fa-circle-xmark"></i></span>
        <div class="invalid">
          <small *ngIf="voucher.maVoucher&& voucher.status === false">{{error.voucher|translate}}</small>
        </div>
      </div> -->
      <div *ngIf="isApp" class="text-field maVoucher" style="width: 100%;">
        <label for="maVoucher">{{ 'PAYMENT.VOUCHER_ID' | translate }}</label>
        <input autocomplete="off" type="text" id="maVoucher" name="maVoucher"
          [placeholder]="'PRODUCT.INSERT_VOUCHER'|translate" [value]="voucher.maVoucher" 
          [(ngModel)]="voucher.maVoucher"
          (blur)="getDiscount(voucher.maVoucher)"/>
          <span *ngIf="f.controls['maVoucher']?.value!=''&&isTrueVoucher==true" class="input--check"><i
            class="fa-solid fa-circle-check"></i></span>
          <span *ngIf="f.controls['maVoucher']?.value!=''&&isTrueVoucher==false" class="input--check-false"><i class="fa-solid fa-circle-xmark"></i></span>
        <div class="invalid">
          <small
            *ngIf="voucher.maVoucher&& voucher.status === false">{{error.voucher|translate}}</small>
        </div>
      </div> 
      <div class="step4-flex">
        <span class="label" style="color: #dc3545;">{{ 'PAYMENT.SALE' | translate }}:</span>
        <span class="info" style="color: #dc3545;">- {{save}} VND</span>
      </div>
      <br>
      <div class="step4-flex">
        <span class="label">{{ 'PAYMENT.TOTAL' | translate }}</span>
      <span class="fee webview-cl">{{form.ttoan}} VND</span>
      </div>
      <br>

      <ng-container [ngTemplateOutlet]="prodStep"></ng-container>
      <!-- <span *ngIf="!isApp" class="notice-payment">{{ 'PAYMENT.NOTICE' | translate }}</span> -->
    </div>
  </form>
</div>

<ng-template #getBackConfirm let-modal>
  <div class="modal-header">
    <h4 class="modal-title">{{ 'COMMON.PAY' | translate }}</h4>
  </div>
  <div class="modal-body">
    <p>{{ 'COMMON.BEFORE_BACK' | translate }} ?</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="getBack()">{{ 'COMMON.YES' | translate }}</button>
    <button type="button" class="btn btn-light" (click)="modal.close('Close click')">{{ 'COMMON.NO' | translate }}</button>
  </div>
</ng-template>

<ng-template #viewImage let-modal>
  <div class="modal-header">
    <h4 class="modal-title m-0 fs-4">{{ 'COMMON.UPLOAD_IMAGE' | translate }} {{ isOtherView ? 'người mua khác chủ xe' : '' }}</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <table class="w-100" *ngIf="!isOtherView">
      <thead *ngIf="tempFile.front || tempFile.back">
        <tr>
          <th class="text-center">{{ 'COMMON.IDENTIFY_FRONT' | translate }}</th>
          <th class="text-center">{{ 'COMMON.IDENTIFY_BACK' | translate }}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td class="text-center w-50 p-2">
            <div class="input-file m-auto" *ngIf="!tempFile.front">
              <input type="file" name="frontIden" id="frontIden" accept="image/png, image/gif, image/jpeg"
                (change)="upload($event, 0, false)" />
              <label for="frontIden" class="input-label">
                <span class="material-icons">add_a_photo</span><br>
                <span class="input-span">{{ 'COMMON.IDENTIFY_FRONT' | translate }}<br>{{ 'COMMON.IDENTIFY_CARD' | translate }}</span>
              </label>
            </div>

            <div *ngIf="tempFile.front" class="position-relative">
              <img class="w-100 h-auto object-fit-contain" [src]="tempFile.front" [alt]="'COMMON.IDENTIFY_FRONT'|translate" [title]="'COMMON.IDENTIFY_FRONT'|translate">
              <button type="button" class="position-absolute btn--cancel" (click)="removeFile(0, false)" [title]="'COMMON.DELETE'|translate">
                <span class="material-icons">cancel</span>
              </button>
            </div>
          </td>

          <td class="text-center w-50 p-2">
            <div class="input-file m-auto" *ngIf="!tempFile.back">
              <input type="file" name="backIden" id="backIden" accept="image/png, image/gif, image/jpeg"
                (change)="upload($event, 1, false)" />
              <label for="backIden" class="input-label">
                <span class="material-icons">add_a_photo</span><br>
                <span class="input-span">{{ 'COMMON.IDENTIFY_BACK' | translate }}<br>{{ 'COMMON.IDENTIFY_CARD' | translate }}</span>
              </label>
            </div>

            <div *ngIf="tempFile.back" class="position-relative">
              <img class="w-100 h-auto object-fit-contain" [src]="tempFile.back" [alt]="'COMMON.IDENTIFY_BACK'|translate" [title]="'COMMON.IDENTIFY_BACK'|translate">
              <button type="button" class="position-absolute btn--cancel" (click)="removeFile(1, false)" [title]="'COMMON.DELETE'|translate">
                <span class="material-icons">cancel</span>
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <table class="w-100" *ngIf="isOtherView">
      <thead *ngIf="tempFileM.front || tempFileM.back">
        <tr>
          <th class="text-center">{{ 'COMMON.IDENTIFY_FRONT' | translate }}</th>
          <th class="text-center">{{ 'COMMON.IDENTIFY_BACK' | translate }}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td class="text-center w-50 p-2">
            <div class="input-file m-auto" *ngIf="!tempFileM.front">
              <input type="file" name="frontIdenM" id="frontIdenM" accept="image/png, image/gif, image/jpeg"
                (change)="upload($event, 0, true)" />
              <label for="frontIdenM" class="input-label">
                <span class="material-icons">add_a_photo</span><br>
                <span class="input-span">{{ 'COMMON.IDENTIFY_FRONT' | translate }}<br>{{ 'COMMON.IDENTIFY_CARD' | translate }}</span>
              </label>
            </div>

            <div *ngIf="tempFileM.front" class="position-relative">
              <img class="w-100 h-auto object-fit-contain" [src]="tempFileM.front" [alt]="'COMMON.IDENTIFY_FRONT'|translate" [title]="'COMMON.IDENTIFY_FRONT'|translate">
              <button type="button" class="position-absolute btn--cancel" (click)="removeFile(0, true)" [title]="'COMMON.DELETE'|translate">
                <span class="material-icons">cancel</span>
              </button>
            </div>
          </td>

          <td class="text-center w-50 p-2">
            <div class="input-file m-auto" *ngIf="!tempFileM.back">
              <input type="file" name="backIdenM" id="backIdenM" accept="image/png, image/gif, image/jpeg"
                (change)="upload($event, 1, true)" />
              <label for="backIdenM" class="input-label">
                <span class="material-icons">add_a_photo</span><br>
                <span class="input-span">{{ 'COMMON.IDENTIFY_BACK' | translate }}<br>{{ 'COMMON.IDENTIFY_CARD' | translate }}</span>
              </label>
            </div>

            <div *ngIf="tempFileM.back" class="position-relative">
              <img class="w-100 h-auto object-fit-contain" [src]="tempFileM.back" [alt]="'COMMON.IDENTIFY_BACK'|translate" [title]="'COMMON.IDENTIFY_BACK'|translate">
              <button type="button" class="position-absolute btn--cancel" (click)="removeFile(1, true)" [title]="'COMMON.DELETE'|translate">
                <span class="material-icons">cancel</span>
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="modal-footer" *ngIf="isOtherView">
    <button type="button" class="btn btn-primary" (click)="confirmFile(true); modal.close('Close click')" *ngIf="tempFileM.front && tempFileM.back">{{ 'COMMON.PAY' | translate }}</button>
    <button type="button" class="btn btn-success" (click)="removeFile(0, true); removeFile(1, true)" *ngIf="tempFileM.front || tempFileM.back">{{ 'COMMON.UPLOAD_NEW' | translate }}</button>
    <button type="button" class="btn btn-light" (click)="modal.close('Close click')">{{ 'COMMON.EXIT' | translate }}</button>
  </div>
  <div class="modal-footer" *ngIf="!isOtherView">
    <button type="button" class="btn btn-primary" (click)="confirmFile(false); modal.close('Close click')" *ngIf="tempFile.front && tempFile.back">{{ 'COMMON.PAY' | translate }}</button>
    <button type="button" class="btn btn-success" (click)="removeFile(0, false); removeFile(1, false)" *ngIf="tempFile.front || tempFile.back">{{ 'COMMON.UPLOAD_NEW' | translate }}</button>
    <button type="button" class="btn btn-light" (click)="modal.close('Close click')">{{ 'COMMON.EXIT' | translate }}</button>
  </div>
</ng-template>

<ng-template #prodStep>
  <div *ngIf="stepValue !== 4" class="totalFee">
    <span>{{ 'PAYMENT.TOTAL' | translate }}:</span> {{form.ttoan || 0}} VND
  </div>
  <div class="step-and-total" [ngClass]="isWebview ? 'webview' : ''">
    <button class="back" (click)="stepValue > 1 ? onController(-1, f) : openCentered(getBackConfirm) ">
      {{ 'COMMON.BACK' | translate }}
    </button>
    <button class="next" [disabled]="stepValue === 3 && !form.ttoan" [ngClass]="(stepValue === 3 && !form.ttoan) ? 'disableBtn' : ''"
      (click)="stepValue < 4 ? onController(1, f) : ''" *ngIf="stepValue < 4">
      {{ 'COMMON.NEXT' | translate }}
    </button>
    <button class="next" (click)="onSubmit(f)" *ngIf="stepValue === 4"
    [disabled]="submited" [ngClass]="submited ? 'opacity-50' : 'opacity-100'">{{ 'COMMON.PAY' | translate }}</button>
  </div>
</ng-template>

