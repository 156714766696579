import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TokenService } from '@services/token.service';

@Component({
  selector: 'app-slides-manager',
  templateUrl: './slides-manager.component.html',
  styleUrls: ['./slides-manager.component.css']
})
export class SlidesManagerComponent implements OnInit {

  constructor(
    private tokenStorage: TokenService,
    private _router: Router,
  ) { }

  ngOnInit(): void {
     
  }

}
