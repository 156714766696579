import { eff, now } from "."

export const MOTO_VEHICLE_TYPES = [
  { id: 1, value: 'L', label: 'PRODUCTS.MOTOR.L' },
  { id: 2, value: 'N', label: 'PRODUCTS.MOTOR.N' },
  { id: 3, value: 'D', label: 'PRODUCTS.MOTOR.D' },
  { id: 4, value: 'B', label: 'PRODUCTS.MOTOR.B' },
]

export const MOTO_RESPONS = [
  { id: 1, value: '10000000', label: 'PRODUCTS.10M' },
  { id: 2, value: '15000000', label: 'PRODUCTS.15M' },
  { id: 3, value: '20000000', label: 'PRODUCTS.20M' },
  { id: 4, value: '25000000', label: 'PRODUCTS.25M' },
  { id: 5, value: '30000000', label: 'PRODUCTS.30M' },
]

export const MOTO_BILL_TEMPLATE = {
  loaI_XE: '',
  ngaY_HL: now,
  ngaY_KT: eff,
  tl: '0',
  sO_CN: '2',
}

export const MOTO_FORM_TEMPLATE = {
  nv: '2BL',
  kieU_HD: 'G',
  ttoan: '',
  bieN_XE: '',
  sO_KHUNG: '',
  sO_MAY: '',
  lkh: '',
  ten: '',
  cmt: '',
  files: {
    front: '',
    back: '',
  },
  nG_SINH: '',
  gioi: '',
  mobi: '',
  email: '',
  dchi: '',
  dbhm: '',
  lkhm: '',
  tenm: '',
  cmtm: '',
  fileMs: {
    front: '',
    back: '',
  },
  nG_SINHM: '',
  mobim: '',
  emailm: '',
  dchim: '',
  nguoiGioiThieu: '',
  tensanpham: 'Bảo hiểm TNDS Mô tô',
  goisanpham: 'Bảo hiểm xe máy tiêu chuẩn',
  thoihan: '12',
  iddangky: '',
  magioithieu: '',
  moiquanhe: '',
  user_Code: '',
}
