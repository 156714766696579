import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { ITGCNS, ITVBICareForm } from '@models/baohiemsuckhoe.model';
import { ITFee } from '@models/fee.model';
import { API_PATH, GENDERS } from 'src/utils/constant'
import { ApiService } from '@services/api.service';
import { ProductsService } from '@services/products/products.service';
import { BHSK_STEPS } from 'src/utils/steps';
import { ITVoucher } from '@models/voucher.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ERROR, VALID } from 'src/utils/validation';
import { ITPayment } from '@models/payment.model';
import { ActivatedRoute, Router } from '@angular/router';
import { eff, FEE_TEMPLATE, now, PAYMENT_TEMPLATE, VOUCHER_TEMPLATE, year } from 'src/utils/product';
import { BHSK_EMPTY_CUSTOMER_TEMPLATE, BHSK_INSURANCE, BHSK_ITEM_CUSTOMER_TEMPLATE, BHSK_RELATIVE, VBICARE_DKBS, VBICARE_FORM_TEMPLATE, VBICARE_PACKAGES } from 'src/utils/product/vbicare';
import { environment } from '@environments/environment';
import { ProtectService } from '@services/protect/protect.service';
import * as moment from 'moment';
import { ToastService } from '@services/toast.service';
import { CommonService } from '@services/common/common.service';

@Component({
  selector: 'app-baohiemsuckhoe',
  templateUrl: './baohiemsuckhoe.component.html',
  styleUrls: ['./baohiemsuckhoe.component.css']
})
export class BaohiemsuckhoeComponent implements OnInit {
  prodEnv: boolean = environment.production
  @Output() setIsPayment = new EventEmitter()
  @Input() isWebview: boolean = false
  @Input() isApp: boolean = false
  @Input() isShared: boolean = false
  @Input() appVoucher: any = null
  @Input() userCode: any = null
  @Input() meetVoucher: any = null
  isPayment: boolean = true
  public innerWidth: any
  public isBack = false
  public stepValue = 1
  public launcher_id = ''
  public save = 0
  public fee: ITFee = {
    ...FEE_TEMPLATE
  }
  vbiPackages: any[] = []
  year = year
  now = now
  eff = eff
  listDkbs: string[] = []
  dkbs1: boolean = false
  dkbs2: boolean = false
  dkbs3: boolean = false
  dkbs4: boolean = false
  public gcns: ITGCNS = {
    ...BHSK_ITEM_CUSTOMER_TEMPLATE
  }
  public form: ITVBICareForm = {
    ...VBICARE_FORM_TEMPLATE
  }
  public voucher: ITVoucher = {
    ...VOUCHER_TEMPLATE
  }
  public payment: ITPayment = {
    ...PAYMENT_TEMPLATE
  }
  submited: boolean = false
  so_id_vbi: string | undefined = ''
  so_id_dt_vbi: string | undefined = ''
  webviewNow: any;
  isNumberIn: boolean = false;
  isNumberInP: boolean = false;
  validDate: boolean = true;
  validBirthday: boolean = true;
  validBirthdayP: boolean = true;
  isTrueLauncher: boolean = true;

  constructor(
    private ApiService: ApiService,
    private _productsService: ProductsService,
    private _protect: ProtectService,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private router: Router,
    public _toast: ToastService,
    public _common: CommonService,
  ) { }

  ngOnInit(): void {
    this.innerWidth = window.innerWidth
    this.isWebview ? (this.getParams(), this.webviewNow = moment(this.now).format('DD-MM-YYYY'),
      this.gcns.ngay_hl = moment(this.gcns.ngay_hl).format('DD-MM-YYYY'),
      this.gcns.ngay_kt = moment(this.gcns.ngay_kt).format('DD-MM-YYYY')) : this.handleUserCode()
  }

  handleUserCode() {
    const user = this.route.snapshot.params['user']
    this.form.magioithieu = user
    this.getLauncher(user)
  }

  getParams() {
    this.router.url.includes('&')
      ? (this.form.user_Code = this.payment.user_Code = this.router.url.slice(-9))
      : ''
  }

  onChangeFromDate() {
    this.validDate = this._common.validDateString(this.gcns.ngay_hl, this.isWebview);
    this.isWebview ? this.gcns.ngay_kt = moment(this._common.onChangeFromDate(this._common.changeDateString(this.gcns.ngay_hl))).format('DD-MM-YYYY') : this.gcns.ngay_kt = this._common.onChangeFromDate(this.gcns.ngay_hl);
  }

  // If magioithieu.length < 1, set nguoiGioiThieu = ''
  getEmptyLauncher() {
    this.form.magioithieu = ''
    this.form.nguoiGioiThieu = ''
  }

  // If maVoucher.length < 1, set tongphi = phi
  getOldFee() {
    this.save = 0

    this.fee.tongphi = this.fee.phi
    this.form.ttoan = this.fee.tongphi
      = this.fee.phi
  }

  openCentered(content: TemplateRef<any>) {
    this.modalService.open(content, { centered: true });
  }

  getBack() {
    this.modalService.dismissAll();

    this.isPayment = false
    this.setIsPayment.emit(this.isPayment)
    // Hàm getBack sẽ ghi lại isPayment bằng false, đồng thời thông qua EventEmitter bắn value isPayment này ra component cha Product

    this.isBack = false
  }

  onForSelf() {
    if (this.gcns.moi_qh === 'QH00000') {
      this.gcns.ten = this.form.ten
      this.gcns.dchi = this.form.dchi
      this.gcns.ngay_sinh = this.form.nG_SINH
      this.gcns.gioi_tinh = this.form.gioi
      this.gcns.cmt = this.form.cmt
      this.gcns.d_thoai = this.form.mobi
      this.gcns.email = this.form.email
    }
  }

  onFormatDate(value: any) {
    return new Date(value).toISOString().slice(0, 10).replace('-', '').replace('-', '')
  }

  checkBirthday(date: string) {
    this.validBirthday = this._common.validBirthdayString(date, this.isWebview);
  }

  checkBirthdayP(date: string) {
    this.validBirthdayP = this._common.validBirthdayString(date, this.isWebview);
  }

  getFee() {
    let payload = {
      ...this.form,
      nG_SINH: this.isWebview ? this._common.changeDateString(this.form.nG_SINH) : this.form.nG_SINH,
      ngaysinh: this.isWebview ? this._common.changeDateString(this.form.nG_SINH) : this.form.nG_SINH,
      gioi_tinh: this.form.gioi,
      d_thoai: this.form.mobi,
      magioithieu: '',
      nguoiGioiThieu: '',
      GCNS: [
        {
          ...this.gcns,
          ngay_sinh: this.isWebview ? this._common.changeDateString(this.gcns.ngay_sinh) : this.gcns.ngay_sinh,
          ngay_hl: this.isWebview ? this._common.changeDateString(this.gcns.ngay_hl) : this.gcns.ngay_hl,
          ngay_kt: this.isWebview ? this._common.changeDateString(this.gcns.ngay_kt) : this.gcns.ngay_kt,
        }
      ]
    }
    this.fee = {...FEE_TEMPLATE}
    this._common.getFee(API_PATH.VBICARE_FEE, payload).subscribe((data) => {
      this.fee = data
      this.payment.tongThanhToan = this.payment.tongphi = this.form.ttoan = data.strTong
      this.so_id_vbi = data.so_id_vbi
      this.so_id_dt_vbi = data.so_id_dt_vbi
    });
  }

  onSubmit(f: any) {
    if (f.invalid || !this.validDate || !this.validBirthday || !this.validBirthdayP) {
      this._toast.show('Hãy nhập đúng phiếu thông tin !', { icon: 'error', classname: 'bg-danger text-light' });

      f.controls['ten']!.touched = true
      f.controls['cmt']!.touched = true
      f.controls['d_thoai']!.touched = true
      f.controls['email']!.touched = true
      f.controls['ngaysinh']!.touched = true
      f.controls['gioi_tinh']!.touched = true
      f.controls['dchi']!.touched = true

      f.controls['moi_qh']!.touched = true
      f.controls['goi_bh']!.touched = true
      f.controls['gcns_ten']!.touched = true
      f.controls['gcns_cmt']!.touched = true
      f.controls['gcns_d_thoai']!.touched = true
      f.controls['gcns_email']!.touched = true
      f.controls['gcns_ngay_sinh']!.touched = true
      f.controls['gcns_gioi_tinh']!.touched = true
      f.controls['ngay_hl']!.touched = true
      f.controls['ngay_kt']!.touched = true
    } else {
        if(this.fee.phi == ''){
          this._toast.show('Kiểm tra lại thông tin và phí!', { icon: 'error', classname: 'bg-danger text-light' });
        }
        else {
          this.submited = true
        let payload = {
          ...this.form,
          ...this.payment,
          email: this.form.email || '',
          gioitinh: this.genders.find((i: any) => i.vie === this.form.gioi)?.label,
          nG_SINH: this.isWebview ? this._common.changeDateString(this.form.nG_SINH) : this.form.nG_SINH,
          goibaohiem: this.goibaohiem.find((i: any) => i.value === this.gcns.goi_bh)?.label_api,
          goisanpham: this.goibaohiem.find((i: any) => i.value === this.gcns.goi_bh)?.label_api,
          so_id_vbi: this.so_id_vbi,
          so_id_dt_vbi: this.so_id_dt_vbi,
        }
        let formValue = Object.assign(payload)
        localStorage.setItem('form-data', JSON.stringify(formValue))

        const totalFee = this.fee.tongphi as string
        this.payment.tongThanhToan = this.payment.tongphi = parseFloat(totalFee.replace(/,/g, '')).toString()

        this.ApiService.postPayment(payload).subscribe(res => {
          if (this.isApp) {
            // Gửi message vào app
            window.postMessage(JSON.stringify({ type: 'getLocalStorage' }));
            const submitButton = document.getElementById('submit');
            submitButton&&submitButton.setAttribute('data-ttoan', this.payment.tongThanhToan || '0')
            window.location.href ='webview/notification/app'
          } else {
            res.paymentUrl && (window.location.href = res.paymentUrl)
          }
          // res.paymentUrl && (window.location.href = res.paymentUrl)
          this.submited = false
        })
        }
      }
  }

  onController(e: number, f: any) {
    // If any input in any step has invalid, open modal notice "Please fill the form", then warning
    // If all input in any step hasn't invalid, fee next step
    switch (this.stepValue) {
      case 1:
        if (f.controls['ten']?.invalid | f.controls['cmt']?.invalid | f.controls['d_thoai']?.invalid | f.controls['email']?.invalid | f.controls['ngaysinh']?.invalid | f.controls['gioi_tinh']?.invalid | f.controls['dchi']?.invalid
        || !this.validBirthday) {
          this._toast.show('Hãy nhập đúng phiếu thông tin !', { icon: 'error', classname: 'bg-danger text-light' });

          f.controls['ten']!.touched = true
          f.controls['cmt']!.touched = true
          f.controls['d_thoai']!.touched = true
          f.controls['email']!.touched = true
          f.controls['ngaysinh']!.touched = true
          f.controls['gioi_tinh']!.touched = true
          f.controls['dchi']!.touched = true
        } else this.stepValue += e
        break
      case 2:
        if (f.controls['moi_qh']?.invalid | f.controls['gcns_ten']?.invalid | f.controls['gcns_cmt']?.invalid | f.controls['gcns_d_thoai']?.invalid | f.controls['gcns_email']?.invalid | f.controls['gcns_ngay_sinh']?.invalid | f.controls['gcns_gioi_tinh']?.invalid
        || !this.validBirthdayP) {
          this._toast.show('Hãy nhập đúng phiếu thông tin !', { icon: 'error', classname: 'bg-danger text-light' });

          f.controls['moi_qh']!.touched = true
          f.controls['gcns_ten']!.touched = true
          f.controls['gcns_cmt']!.touched = true
          f.controls['gcns_d_thoai']!.touched = true
          f.controls['gcns_email']!.touched = true
          f.controls['gcns_ngay_sinh']!.touched = true
          f.controls['gcns_gioi_tinh']!.touched = true
        } else this.stepValue += e
        break
      case 3:
        if (f.invalid || this.fee.phi == '') {
          this._toast.show('Hãy nhập đúng phiếu thông tin !', { icon: 'error', classname: 'bg-danger text-light' });

          f.controls['goi_bh']!.touched = true
          f.controls['ngay_hl']!.touched = true
          f.controls['ngay_kt']!.touched = true
        } else {
          this.stepValue += e

          if (this.isWebview && e === 1) {
            this.onSaveClient()
          }
        }
        break
      case 4:
        this.stepValue += e
        break
      default:
        this._toast.show('Hãy nhập đúng phiếu thông tin !', { icon: 'error', classname: 'bg-danger text-light' });

    }
  }

  onSaveClient() {
    let payload = {
      ...this.payment,
      user_Code: this.form.user_Code || this.payment.user_Code || '',
      hoten: this.form.ten.trim(),
      ngaysinh: this.isWebview ? this._common.changeDateString(this.form.nG_SINH) : this.form.nG_SINH,
      ngaytao: this.now,
      diachi: this.form.dchi.trim(),
      sodienthoai: this.form.mobi,
      cccd: this.form.cmt,
      email: this.form.email,
      sanPham: 'Bảo hiểm sức khỏe VBICare',
      thietBi: 'app',
      loaibaohiem: 'Bảo hiểm sức khỏe VBICare',
      goibaohiem: this.form.goisanpham || '',
      moiquanhe: this.mqhnguoimua.find(i => i.code === this.gcns.moi_qh)?.label_api || '',
      thoihan: '12',
      thoigianthanhtoan: this.now,
      webview: this.isWebview.toString(),
      gioitinh: this.genders.find((i: any) => i.vie === this.form.gioi)?.label
    }

    this.ApiService.postPayment(payload).subscribe(res => {
      this.payment.idDangKy = this.form.iddangky = res.idDangKy
    })
  }

  // BAOHIEM20220824015137
  getDiscount(param: string) {
    this._common.getDiscount(param, this.fee.tongphi).subscribe((data) => {
      if (data.status) {
        this.voucher = data,
          this.save = data.save
        this.form.ttoan = data.newTongPhi
      } else {
        this._toast.show('Hãy nhập đúng voucher !', { icon: 'error', classname: 'bg-danger text-light' });
      }
    })
  }

  getLauncher(param: string) {
    param= param.trim()
    this.isTrueLauncher=false;
    this.form.nguoiGioiThieu=''
    if(param.includes('A')&&param.length==9){
      this.ApiService.getLauncher(param).subscribe((data) => {
        this.form.nguoiGioiThieu = data?.ho_ten
        data?.ho_ten?(this.form.user_Code= this.payment.user_Code = param,this.isTrueLauncher=true):null
      })
    }
    else if(!param.includes('A')&&param.length==10){
      this.ApiService.getLauncherByPhone(param).subscribe((data) => {
        this.form.nguoiGioiThieu = data[0]?.ho_ten
        data[0]?.ho_ten?(this.form.user_Code= this.payment.user_Code = data[0]?.ma_nv,this.isTrueLauncher=true):null
      })
    } 
  }

  policyHandler() {
    // this.form.goisanpham = this.vbiPackages.find(item => item.ma === this.gcns.goi_bh)?.ten
    this.form.goisanpham = this.goibaohiem.find(item => item.value === this.gcns.goi_bh)?.label
  }

  onChangeCheckbox(type: string, value: any) {
    const found = this.listDkbs.find(i => i === type)

    if (value && !found) {
      this.listDkbs.push(type)
    } else if (!value && found) {
      this.listDkbs = this.listDkbs.filter(i => i !== found)
    }

    if (this.listDkbs.length > 1) {
      this.gcns.dkbs = this.listDkbs.join(', ')
    } else {
      this.gcns.dkbs = this.listDkbs.join('')
    }

    this.getFee()
  }

  onCapitalize(type: number): void {
    type
      ? this.form.ten = this._common.onCapitalize(this.form.ten)
      : this.gcns.ten = this._common.onCapitalize(this.gcns.ten)
  }

  onNumberIn(type:number) {
    type
    ? this.isNumberIn = this._common.onNumberIn(this.form.ten)
    : this.isNumberInP = this._common.onNumberIn(this.gcns.ten)
  }

  pattern = VALID
  error = ERROR
  steps = BHSK_STEPS
  genders = GENDERS
  mqhnguoimua = BHSK_RELATIVE
  goibaohiem = this.prodEnv ? VBICARE_PACKAGES : BHSK_INSURANCE
  dkbs = VBICARE_DKBS
}
