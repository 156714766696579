<div class="acare-payment">
  <div class="step__controller">
    <app-step-controller [steps]="steps" [step]="33" [stepValue]="stepValue" [isWebview]="false"></app-step-controller>
  </div>

  <form #f="ngForm" id="form">
    <div class="form--left">
      <div id="step_1" [ngClass]="stepValue === 1 ? 'step' : 'hidden'">
        <app-form-title [icon]="'loyalty'" [label]="'PRODUCT.CLIENT_INFO'|translate"></app-form-title>
        <div class="typeofclient">
          <span>{{ 'COMMON.TYPE' | translate }}:</span>
          <input type="checkbox" id="pers" name="isPerson" [value]="isPerson" [(ngModel)]="isPerson"
            (click)="isCorp = false ; isPerson=true;checkBirthday(form.nG_SINH) " />
          <label for="pers">{{ 'COMMON.PERSONAL' | translate }}</label>
          <input type="checkbox" id="corp" name="isCorp" [value]="isCorp" [(ngModel)]="isCorp"
            (click)="isPerson = false ; isCorp=true;checkBirthday(form.nG_SINH)" />
          <label for="corp">{{ 'COMMON.COMPANY' | translate }}</label>
        </div>

        <div class="form-group">
          <div class="form-grid">
            <div class="text-field">
              <label for="cmt">{{isCorp ? ('PRODUCT.TAX_ID'|translate) : ('COMMON.IDENTIFY_CARD'|translate)}}<span
                  class="required">*</span>
              </label>
              <input required [pattern]="isCorp ?pattern.ALL :pattern.CCCD" autocomplete="off" type="text" id="cmt"
                name="cmt" [value]="form.cmt" [(ngModel)]="form.cmt" />
              <span *ngIf="f.controls['cmt']?.valid" class="input--check"><i
                  class="fa-solid fa-circle-check"></i></span>
              <div class="invalid">
                <small
                  *ngIf="f.controls['cmt']?.invalid && f.controls['cmt']?.touched && form.cmt.length < 1">{{error.required|translate}}</small>
                <small *ngIf="f.controls['cmt']?.errors?.['pattern'] && isPerson">{{error.identify|translate}}</small>
              </div>
            </div>
            <div class="text-field">
              <label for="mobi">{{ 'COMMON.PHONE_NUMBER' | translate }}<span class="required">*</span></label>
              <input required maxlength="10" [pattern]="pattern.PHONE" autocomplete="off" type="tel" id="mobi"
                name="mobi" [value]="form.mobi" [(ngModel)]="form.mobi" />
              <span *ngIf="f.controls['mobi']?.valid" class="input--check"><i
                  class="fa-solid fa-circle-check"></i></span>
              <div class="invalid">
                <small
                  *ngIf="f.controls['mobi']?.invalid && f.controls['mobi']?.touched && form.mobi.length < 1">{{error.required|translate}}</small>
                <small *ngIf="f.controls['mobi']?.errors?.['pattern']">{{error.phone|translate}}</small>
              </div>
            </div>
            <div class="text-field">
              <label for="ten">{{isCorp ? ('COMMON.COMPANY_NAME'|translate) : ('COMMON.FULL_NAME'|translate)}}<span
                  class="required">*</span></label>
              <input required autocomplete="off" type="text" id="ten" name="ten" [value]="form.ten"
                [(ngModel)]="form.ten" (blur)="onCapitalize(1);onNumberIn(1)" />
              <span *ngIf="f.controls['ten']?.valid" class="input--check"><i
                  class="fa-solid fa-circle-check"></i></span>
              <div [class]="isNumberIn?'alertMessage':'invalid'">
                <small
                  *ngIf="f.controls['ten']?.invalid && f.controls['ten']?.touched && form.ten.length < 1">{{error.required|translate}}</small>
                <small *ngIf="isNumberIn">{{error.number_in|translate}}</small>
              </div>
            </div>
            <div class="text-field">
              <label for="nG_SINH">{{isCorp ? ('PRODUCT.FOUNDING'|translate) : ('PRODUCT.BIRTHDAY'|translate)}}<span
                  class="required">*</span></label>
              <input required minlength="10" maxlength="10" autocomplete="off" [type]="isWebview ? 'text' : 'date'" [max]="now" id="nG_SINH"
                name="nG_SINH" [value]="form.nG_SINH" [(ngModel)]="form.nG_SINH" placeholder="20-02-2002"
                [pattern]="isWebview ? pattern.DATE : pattern.ALL" (ngModelChange)="checkBirthday(form.nG_SINH)" />
              <span *ngIf="f.controls['nG_SINH']?.valid&&isWebview&&validBirthday" class="input--check"><i
                  class="fa-solid fa-circle-check"></i></span>
              <div class="invalid">
                <small *ngIf="f.controls['nG_SINH']?.touched && !f.controls['nG_SINH'].valid">{{error.short|translate}}</small>
                <small
                  *ngIf="f.controls['nG_SINH']?.invalid && f.controls['nG_SINH']?.touched && form.nG_SINH.length < 1">{{error.shortReq|translate}}</small>
                <small
                  *ngIf="f.controls['nG_SINH']?.valid && form.nG_SINH.length > 0 && form.nG_SINH > (isWebview?null:now)">{{error.max_date|translate}}</small>
                <small *ngIf="f.controls['nG_SINH']?.errors?.['pattern'] && isWebview">{{error.date|translate}}</small>
                <small
                  *ngIf="f.controls['nG_SINH']?.valid &&!validBirthday&& isWebview">{{error.max_date|translate}}</small>
              </div>
            </div>
            <div class="input-file" (click)="openImageModal(viewImage)" *ngIf="!tempFile.front || !tempFile.back">
              <label class="input-label">
                <span class="material-icons">collections</span><br>
                <span class="input-span">{{ 'COMMON.UPLOAD_IMAGE' | translate }}<br>{{ 'COMMON.IDENTIFY_CARD' |
                  translate }}</span>
              </label>
            </div>
            <div class="uploaded-file" (click)="openImageModal(viewImage)" [title]="'COMMON.IDENTIFY_IMAGE'|translate"
              *ngIf="form.files.front && form.files.back && tempFile.front && tempFile.back">
              <img [src]="form.files.front" [alt]="'COMMON.IDENTIFY_FRONT'|translate">
              <img [src]="form.files.back" [alt]="'COMMON.IDENTIFY_BACK'|translate">
            </div>
          </div>
          <div [ngClass]="isCorp ? 'form-child--full' : 'form-child'">
            <div [ngClass]="isCorp ? 'gender-hidden' : 'text-field'">
              <label for="gioi">{{ 'COMMON.GENDER' | translate }}<span class="required">*</span></label>
              <select name="gioi" id="gioi" [(ngModel)]="form.gioi" [required]="isPerson" (ngModelChange)="getFee()">
                <option *ngFor="let opt of genders" [value]="opt.value">{{opt.label|translate}}</option>
              </select>
              <span *ngIf="f.controls['gioi']?.valid" class="input--check"><i
                  class="fa-solid fa-circle-check"></i></span>
              <div class="invalid">
                <small
                  *ngIf="f.controls['gioi']?.invalid && f.controls['gioi']?.touched">{{error.shortSel|translate}}</small>
              </div>
            </div>
            <div [ngClass]="isCorp ? 'text-field full-child' : 'text-field'">
              <label for="email">Email<span class="required">*</span></label>
              <input required [email]="pattern.EMAIL" autocomplete="off" type="email" id="email" name="email"
                [value]="form.email" [(ngModel)]="form.email" />
              <span *ngIf="f.controls['email']?.valid" class="input--check"><i
                  class="fa-solid fa-circle-check"></i></span>
              <div class="invalid">
                <small
                  *ngIf="f.controls['email']?.invalid && f.controls['email']?.touched && form.email.length < 1">{{error.required|translate}}</small>
                <small *ngIf="f.controls['email']?.errors?.['email']">{{error.email|translate}}</small>
              </div>
            </div>
          </div>
          <div class="text-field last">
            <label for="dchi">{{ 'COMMON.ADDRESS' | translate }}<span class="required">*</span></label>
            <input required="" autocomplete="off" type="text" id="dchi" name="dchi" [value]="form.dchi"
              [(ngModel)]="form.dchi" />
            <span *ngIf="f.controls['dchi']?.valid&&f.controls['dchi']?.touched" class="input--check"><i
                class="fa-solid fa-circle-check"></i></span>
            <div class="invalid"></div>
          </div>
        </div>
        <div class="form-last">
          <div class="text-field form-child--full">
            <ng-container *ngIf="!isPerson ">
              <label for="daidien">{{ 'PRODUCT.REPRESENTATIVE' | translate }}<span class="required">*</span></label>
              <input required autocomplete="off" type="text" id="daidien" name="daidien" [value]="form.daidien"
                [(ngModel)]="form.daidien" />
              <span *ngIf="f.controls['daidien']?.valid" class="input--check"><i
                  class="fa-solid fa-circle-check"></i></span>
              <div class="invalid">
                <small
                  *ngIf="f.controls['daidien']?.invalid && f.controls['daidien']?.touched">{{error.select|translate}}</small>
              </div>
            </ng-container>
          </div>
          <div class="text-field last">
            <ng-container *ngIf="!isPerson">
              <label for="chucvu">{{ 'COMMON.POSITION' | translate }}<span class="required">*</span></label>
              <input required autocomplete="off" type="text" id="chucvu" name="chucvu" [value]="form.chucvu"
                [(ngModel)]="form.chucvu" />
              <span *ngIf="f.controls['chucvu']?.valid" class="input--check"><i
                  class="fa-solid fa-circle-check"></i></span>
              <div class="invalid">
                <small
                  *ngIf="f.controls['chucvu']?.invalid && f.controls['chucvu']?.touched">{{error.select|translate}}</small>
              </div>
            </ng-container>
          </div>
        </div>
        <ng-container [ngTemplateOutlet]="prodStep"></ng-container>

      </div>


      <div id="step_2" [ngClass]="stepValue === 2 ? 'step' : 'hidden'">
        <app-form-title [icon]="'badge'" [label]="'PRODUCT.PROTECTOR'|translate"></app-form-title>
        <div class="form-group">
          <div class="text-field">
            <label for="moiquanhe">{{ 'PRODUCT.RELATIONSHIP' | translate }}<span class="required">*</span></label>
            <select required name="moiquanhe" id="moiquanhe" [(ngModel)]="passenger.moiquanheP"
              (ngModelChange)="onForSelf()">
              <option *ngFor="let opt of mqhnguoimua" [value]="opt.value">{{opt.label|translate}}</option>
            </select>
            <span *ngIf="f.controls['moiquanhe']?.valid" class="input--check"><i
                class="fa-solid fa-circle-check"></i></span>
            <div class="invalid">
              <small
                *ngIf="f.controls['moiquanhe']?.invalid && f.controls['moiquanhe']?.touched">{{error.select|translate}}</small>
            </div>
          </div>
          <div class="text-field">
            <label for="tenP">{{ 'PRODUCT.BENEFICIARY_NAME' | translate }}<span class="required">*</span></label>
            <input required autocomplete="off" type="text" id="tenP" name="tenP" [value]="passenger.tenP"
              [(ngModel)]="passenger.tenP" (blur)="onCapitalize(0);onNumberIn(0)" />
            <span *ngIf="f.controls['tenP']?.valid" class="input--check"><i class="fa-solid fa-circle-check"></i></span>
            <div [class]="isNumberInP?'alertMessage':'invalid'">
              <small
                *ngIf="f.controls['tenP']?.invalid && f.controls['tenP']?.touched && passenger.tenP.length < 1">{{error.required|translate}}</small>
              <small *ngIf="f.controls['tenP']?.errors?.['pattern']">{{error.name|translate}}</small>
              <small *ngIf="isNumberInP">{{error.number_in|translate}}</small>
            </div>
          </div>
          <div class="text-field">
            <label for="cmtP">{{ 'COMMON.IDENTIFY_CARD' | translate }} {{ 'PRODUCT.BENEFICIARY' | translate }}<span
                class="required">*</span></label>
            <input required min="9" max="12" [pattern]="pattern.CCCD" autocomplete="off" type="text" id="cmtP"
              name="cmtP" [value]="passenger.cmtP" [(ngModel)]="passenger.cmtP" />
            <span *ngIf="f.controls['cmtP']?.valid" class="input--check"><i class="fa-solid fa-circle-check"></i></span>
            <div class="invalid">
              <small
                *ngIf="f.controls['cmtP']?.invalid && f.controls['cmtP']?.touched && passenger.cmtP.length < 1">{{error.required|translate}}</small>
              <small *ngIf="f.controls['cmtP']?.errors?.['pattern']">{{error.identify|translate}}</small>
            </div>
          </div>
          <div class="text-field">
            <label for="mobiP">{{ 'COMMON.PHONE_NUMBER' | translate }} {{ 'PRODUCT.BENEFICIARY' | translate }}<span
                class="required">*</span></label>
            <input required maxlength="10" [pattern]="pattern.PHONE" autocomplete="off" type="tel" id="mobiP"
              name="mobiP" [value]="passenger.mobiP" [(ngModel)]="passenger.mobiP" />
            <span *ngIf="f.controls['mobiP']?.valid" class="input--check"><i
                class="fa-solid fa-circle-check"></i></span>
            <div class="invalid">
              <small
                *ngIf="f.controls['mobiP']?.invalid && f.controls['mobiP']?.touched && passenger.mobiP.length < 1">{{error.required|translate}}</small>
              <small *ngIf="f.controls['mobiP']?.errors?.['pattern']">{{error.phone|translate}}</small>
            </div>
          </div>
          <div class="text-field">
            <label for="emailP">Email {{ 'PRODUCT.BENEFICIARY' | translate }}<span class="required">*</span></label>
            <input required [email]="pattern.EMAIL" autocomplete="off" type="email" id="emailP" name="emailP"
              [value]="passenger.emailP" [(ngModel)]="passenger.emailP" />
            <span *ngIf="f.controls['emailP']?.valid" class="input--check"><i
                class="fa-solid fa-circle-check"></i></span>
            <div class="invalid">
              <small
                *ngIf="f.controls['emailP']?.invalid && f.controls['emailP']?.touched && passenger.emailP.length < 1">{{error.required|translate}}</small>
              <small *ngIf="f.controls['emailP']?.errors?.['email']">{{error.email|translate}}</small>
            </div>
          </div>
          <div class="text-field">
            <label for="nG_SINHP">{{ 'COMMON.BIRTHDAY' | translate }}<span class="required">*</span></label>
            <input minlength="10" maxlength="10" required autocomplete="off" [type]="isWebview ? 'text' : 'date'" placeholder="20-02-2002" [max]="now"
              id="nG_SINHP" name="nG_SINHP" [value]="passenger.nG_SINHP" [(ngModel)]="passenger.nG_SINHP"
              (ngModelChange)="birthdayHandler(passenger.nG_SINHP); getFee();checkBirthdayP(passenger.nG_SINHP);"
              placeholder="20-02-2022" [pattern]="isWebview ? pattern.DATE : pattern.ALL" />
            <span *ngIf="f.controls['nG_SINHP']?.valid&&isWebview&&validBirthdayP&&!wrongBirth" class="input--check"><i
                class="fa-solid fa-circle-check"></i></span>
            <div class="invalid">
              <small *ngIf="f.controls['nG_SINHP']?.touched && !f.controls['nG_SINHP'].valid">{{error.short|translate}}</small>
              <small
                *ngIf="f.controls['nG_SINHP']?.invalid && f.controls['nG_SINHP']?.touched && passenger.nG_SINHP.length < 1">{{error.shortReq|translate}}</small>
              <small *ngIf="f.controls['nG_SINHP']?.valid&& wrongBirth && f.controls['nG_SINHP'].value">{{
                'PRODUCT.60D_TO_65Y' | translate }}</small>
              <small
                *ngIf="f.controls['nG_SINHP']?.valid && passenger.nG_SINHP.length > 0 && passenger.nG_SINHP > (isWebview?null:now)">{{error.max_date|translate}}</small>
              <small *ngIf="f.controls['nG_SINHP']?.errors?.['pattern'] && isWebview">{{error.date|translate}}</small>
              <small
                *ngIf="f.controls['nG_SINHP']?.valid &&!validBirthdayP&& isWebview">{{error.max_date|translate}}</small>
            </div>
          </div>
          <div class="text-field">
            <label for="gioiP">{{ 'COMMON.GENDER' | translate }}<span class="required">*</span></label>
            <select required name="gioiP" id="gioiP" [(ngModel)]="passenger.gioiP" (ngModelChange)="getFee()">
              <option value="" selected disabled>{{ 'PRODUCT.SELECT_GENDER' | translate }}...</option>
              <option *ngFor="let opt of genders" [value]="opt.value">{{opt.label|translate}}</option>
            </select>
            <span *ngIf="f.controls['gioiP']?.valid" class="input--check"><i
                class="fa-solid fa-circle-check"></i></span>
            <div class="invalid">
              <small
                *ngIf="f.controls['gioiP']?.invalid && f.controls['gioiP']?.touched">{{error.shortSel|translate}}</small>
            </div>
          </div>

          <ng-container [ngTemplateOutlet]="prodStep"></ng-container>
        </div>
      </div>

      <div id="step_3" [ngClass]="stepValue === 3 ? 'step' : 'hidden'">
        <app-form-title [icon]="'health_and_safety'" [label]="'PRODUCT.PROD_INFO'|translate"></app-form-title>

        <div class="special-flex space">
          <div class="dynamic-box">
            <div class="text-field">
              <label for="startDate">{{ 'COMMON.FROM_DATE' | translate }}<span class="required">*</span></label>
              <input required autocomplete="off" [type]="isWebview ? 'text' : 'date'" id="startDate" name="startDate"
                [value]="passenger.startDate" [(ngModel)]="passenger.startDate" [min]="isWebview?null:now"
                [pattern]="isWebview ? pattern.DATE : pattern.ALL" (ngModelChange)="onChangeFromDate()" lang="vi-VN" />
              <span *ngIf="f.controls['startDate']?.valid&&isWebview&&validDate" class="input--check"><i
                  class="fa-solid fa-circle-check"></i></span>
              <div class="invalid">
                <small
                  *ngIf="f.controls['startDate']?.touched && !f.controls['startDate']!.value">{{error.required|translate}}</small>
                <small
                  *ngIf="f.controls['startDate']?.valid && passenger.startDate.length > 0 && passenger.startDate < (isWebview?null:now)">{{error.min_date|translate}}</small>
                <small
                  *ngIf="f.controls['startDate']?.valid &&!validDate&&isWebview">{{error.min_date|translate}}</small>
              </div>
            </div>
            <div class="text-field">
              <label for="endDate">{{ 'COMMON.TO_DATE' | translate }}</label>
              <input [type]="isWebview ? 'text' : 'date'" id="endDate" name="endDate" readonly
                [value]="passenger.endDate" [(ngModel)]="passenger.endDate" />
              <span *ngIf="f.controls['endDate']?.valid" class="input--check"><i
                  class="fa-solid fa-circle-check"></i></span>
              <div class="invalid"></div>
            </div>
            <div class="text-field">
              <label for="goi">{{ 'PRODUCT.PACKAGES' | translate }}<span class="required">*</span></label>
              <select required id="goi" name="goi" [(ngModel)]="bill.goi" (ngModelChange)="policyHandler(); getFee()">
                <option value="" selected disabled>{{ 'PRODUCT.SELECT_PACKAGE' | translate }}...</option>
                <option *ngFor="let opt of goibaohiem" [value]="opt.value">{{opt.label|translate}}</option>
              </select>
              <span *ngIf="f.controls['goi']?.valid" class="input--check"><i
                  class="fa-solid fa-circle-check"></i></span>
              <div class="invalid">
                <small
                  *ngIf="f.controls['goi']?.invalid && f.controls['goi']?.touched">{{error.select|translate}}</small>
              </div>
            </div>
            <!-- <div class="text-field">
              <label for="maVoucher">{{ 'PAYMENT.VOUCHER_ID' | translate }}</label>
              <input autocomplete="off" type="text" id="maVoucher" name="maVoucher"
                [placeholder]="'PRODUCT.INSERT_VOUCHER'|translate" [value]="voucher.maVoucher"
                [(ngModel)]="voucher.maVoucher" (blur)="getDiscount(voucher.maVoucher)" />
              <span *ngIf="f.controls['phi']?.valid&&isTrueVoucher==true" class="input--check"><i
                  class="fa-solid fa-circle-check"></i></span>
              <span *ngIf="f.controls['phi']?.valid&&isTrueVoucher==false" class="input--check-false"><i
                  class="fa-solid fa-circle-xmark"></i></span>
              <div class="invalid">
                <small *ngIf="voucher.maVoucher&& voucher.status === false">{{error.voucher|translate}}</small>
              </div>
            </div> -->
            <div class="text-field">
              <label for="phi">{{ 'PAYMENT.FEE' | translate }}</label>
              <input required autocomplete="off" type="text" id="phi" name="phi" readonly [value]="fee.phi"
                [(ngModel)]="fee.phi" (ngModelChange)="getDiscount(voucher.maVoucher)"/>
              <span *ngIf="f.controls['phi']?.valid" class="input--check"><i
                  class="fa-solid fa-circle-check"></i></span>
              <div class="invalid"></div>
            </div>
          </div>
          <div class="acare__dkbs">
            <span class="acare__decs">{{ 'PRODUCT.ADDITION_POLICY' | translate }}</span>
            <div class="acare__dkbs-item">
              <div class="check-field">
                <input type="checkbox" id="trocapnamviendotainan" name="trocapnamviendotainan"
                  [value]="bill.trocapnamviendotainan" [(ngModel)]="bill.trocapnamviendotainan"
                  (ngModelChange)="getFee()" />
                <label for="trocapnamviendotainan">{{ 'PRODUCT.ACCIDENT' | translate }}</label>
              </div>
            </div>
            <div class="acare__dkbs-item">
              <div class="check-field">
                <input type="checkbox" id="ngoaitru" name="ngoaitru" [value]="bill.ngoaitru" [(ngModel)]="bill.ngoaitru"
                  (ngModelChange)="getFee()" />
                <label for="ngoaitru">{{ 'PRODUCT.TREATMENT' | translate }}</label>
              </div>
            </div>
            <div class="acare__dkbs-item" *ngIf="passenger.gioiP == 'female'">
              <div class="check-field">
                <input type="checkbox" id="thaisan" name="thaisan" [value]="bill.thaisan" [(ngModel)]="bill.thaisan"
                  (ngModelChange)="getFee()" />
                <label for="thaisan">{{ 'PRODUCT.TAKE_CARE' | translate }}</label>
              </div>
            </div>
          </div>
        </div>

        <ng-container [ngTemplateOutlet]="prodStep"></ng-container>
      </div>
    </div>

    <div id="last-step" [ngClass]="stepValue === 4 ? 'step' : 'hidden'" *ngIf="!isWebview">
      <app-form-title [label]="'PRODUCT.FEE_INFO'|translate"></app-form-title>
      <hr>
      <div class="last-step--label">
        <span>{{ 'PAYMENT.FEE' | translate }}:</span>
        <span>{{fee.phi}} VND</span>
      </div>
      <div class="last-step--label">
        <span>{{ 'PAYMENT.TAX' | translate }}:</span>
        <span>{{fee.thue}} VND</span>
      </div>
      <hr>
      <div class="flex">
        <img class="form__payment--thumb" src="../../../assets/frontend/images/coupon.png" alt="voucher">
        <span class="form__payment--voucher">Voucher:</span>
      </div>
      <div class="flex space" *ngIf="!meetVoucher">
        <div class="text-field launcher">
          <label for="launcher">{{ 'COMMON.REFERRAL_CODE' | translate }}</label>
          <input autocomplete="off" type="text" id="launcher" name="launcher" [placeholder]="'PRODUCT.A00000001'|translate"
            [value]="form.magioithieu" [(ngModel)]="form.magioithieu" [disabled]="isShared||ma_nvAppVoucher||userCode"
            (ngModelChange)="getLauncher(form.magioithieu)" />
            <span *ngIf="f.controls['launcher']?.value!=''&&f.controls['launcher']?.value!=undefined&&isTrueLauncher==true" class="input--check"><i
              class="fa-solid fa-circle-check"></i></span>
            <span *ngIf="f.controls['launcher']?.value!=''&&f.controls['launcher']?.value!=undefined&&isTrueLauncher==false" class="input--check-false"><i class="fa-solid fa-circle-xmark"></i></span>
        </div>
        <div class="text-field maVoucher">
          <label for="maVoucher">{{ 'PAYMENT.VOUCHER_ID' | translate }}</label>
          <input autocomplete="off" type="text" id="maVoucher" name="maVoucher"
            [placeholder]="'PRODUCT.INSERT_VOUCHER'|translate" [value]="voucher.maVoucher" 
            [(ngModel)]="voucher.maVoucher"
            (blur)="getDiscount(voucher.maVoucher)"/>
            <span *ngIf="f.controls['maVoucher']?.value!=''&&isTrueVoucher==true" class="input--check"><i
              class="fa-solid fa-circle-check"></i></span>
            <span *ngIf="f.controls['maVoucher']?.value!=''&&isTrueVoucher==false" class="input--check-false"><i class="fa-solid fa-circle-xmark"></i></span>
          <div class="invalid">
            <small
              *ngIf="voucher.maVoucher&& voucher.status === false">{{error.voucher|translate}}</small>
          </div>
        </div> 
      </div>
      <div class="flex space" *ngIf="meetVoucher">
        <div class="text-field maVoucher" style="width: 100%;">
          <label for="maVoucher">{{ 'PAYMENT.VOUCHER_ID' | translate }}</label>
          <input [disabled]="meetVoucher" autocomplete="off" type="text" id="maVoucher" name="maVoucher"
            [placeholder]="'PRODUCT.INSERT_VOUCHER'|translate" [value]="voucher.maVoucher" 
            [(ngModel)]="voucher.maVoucher"
            (blur)="getDiscount(voucher.maVoucher)"/>
            <span *ngIf="f.controls['maVoucher']?.value!=''&&isTrueVoucher==true" class="input--check"><i
              class="fa-solid fa-circle-check"></i></span>
            <span *ngIf="f.controls['maVoucher']?.value!=''&&isTrueVoucher==false" class="input--check-false"><i class="fa-solid fa-circle-xmark"></i></span>
          <div class="invalid">
            <small
              *ngIf="voucher.maVoucher&& voucher.status === false">{{error.voucher|translate}}</small>
          </div>
      </div>
      </div>
      <div *ngIf="form.nguoiGioiThieu" style="color: rgb(28, 124, 192) !important;">
        <span>{{ 'PAYMENT.PRESENTER' | translate }}:</span>
        <span>{{form.nguoiGioiThieu}}</span>
      </div>
      <div *ngIf="f.controls['launcher']?.value!=''&&f.controls['launcher']?.value!=undefined&&!isTrueLauncher">
        <span>{{'PRODUCT.WRONG_CODE'|translate}}</span>
      </div>
      <div class="last-step--label voucher--save">
        <span>{{ 'PAYMENT.SALE' | translate }}:</span>
        <span>- {{save}} VND</span>
      </div>
      <div class="last-step--label" style="font-weight: 500;">
        <span>{{ 'PAYMENT.TOTAL' | translate }}:</span>
        <span>{{form.ttoan}} VND</span>
      </div>
      <hr>
      <button class="form__payment" (click)="onSubmit(f)" [disabled]="submited"
        [ngClass]="submited ? 'opacity-50' : 'opacity-100'">
        {{ 'COMMON.PAY' | translate }}
      </button>

    </div>
    <div id="webview-step" [ngClass]="stepValue === 4 ? 'step' : 'hidden'" *ngIf="isWebview">
      <app-form-title [label]="'PRODUCT.CLIENT_INFO'|translate"></app-form-title>
      <hr>
      <div class="step4-flex">
        <span class="label">{{ 'PAYMENT.CLIENT_NAME' | translate }}</span>
        <span class="info">{{form.ten}}</span>
      </div>
      <div class="step4-flex">
        <span class="label">{{ 'PAYMENT.CLIENT_EMAIL' | translate }}</span>
      <span class="info">{{form.email}}</span>
      </div>
      <div class="step4-flex">
        <span class="label">{{ 'PRODUCT.CLIENT_PHONE_NUMBER' | translate }}</span>
      <span class="info">{{form.mobi}}</span>
      </div>
      <hr *ngIf="!isApp">
      <div *ngIf="!isApp" class="flex space">
        <div class="text-field launcher">
          <label for="launcher">{{ 'COMMON.REFERRAL_CODE' | translate }}</label>
          <input autocomplete="off" type="text" id="launcher" name="launcher" [placeholder]="'PRODUCT.A00000001'|translate"
            [value]="form.magioithieu" [(ngModel)]="form.magioithieu" [disabled]="isShared||ma_nvAppVoucher||userCode"
            (ngModelChange)="getLauncher(form.magioithieu)" />
            <span *ngIf="f.controls['launcher']?.value!=''&&f.controls['launcher']?.value!=undefined&&isTrueLauncher==true" class="input--check"><i
              class="fa-solid fa-circle-check"></i></span>
            <span *ngIf="f.controls['launcher']?.value!=''&&f.controls['launcher']?.value!=undefined&&isTrueLauncher==false" class="input--check-false"><i class="fa-solid fa-circle-xmark"></i></span>
        </div>
        <div class="text-field maVoucher">
          <label for="maVoucher">{{ 'PAYMENT.VOUCHER_ID' | translate }}</label>
          <input autocomplete="off" type="text" id="maVoucher" name="maVoucher"
            [placeholder]="'PRODUCT.INSERT_VOUCHER'|translate" [value]="voucher.maVoucher" 
            [(ngModel)]="voucher.maVoucher"
            (blur)="getDiscount(voucher.maVoucher)"/>
            <span *ngIf="f.controls['maVoucher']?.value!=''&&isTrueVoucher==true" class="input--check"><i
              class="fa-solid fa-circle-check"></i></span>
            <span *ngIf="f.controls['maVoucher']?.value!=''&&isTrueVoucher==false" class="input--check-false"><i class="fa-solid fa-circle-xmark"></i></span>
          <div class="invalid">
            <small
              *ngIf="voucher.maVoucher&& voucher.status === false">{{error.voucher|translate}}</small>
          </div>
        </div> 
      </div>
      <div *ngIf="form.nguoiGioiThieu" style="color: rgb(28, 124, 192) !important; font-size: 12px;">
        <span>{{ 'PAYMENT.PRESENTER' | translate }}: {{form.nguoiGioiThieu}}</span>
      </div>
      <app-form-title [label]="'PRODUCT.FEE_INFO'|translate"></app-form-title>
      <hr>
      <div *ngIf="f.controls['launcher']?.value!=''&&f.controls['launcher']?.value!=undefined&&!isTrueLauncher">
        <span>{{'PRODUCT.WRONG_CODE'|translate}}</span>
      </div>
      <div class="step4-flex">
        <span class="label">{{ 'PAYMENT.FEE' | translate }}</span>
      <span class="info">{{fee.phi}}</span>
      </div>
      <div class="step4-flex">
        <span class="label">{{ 'PAYMENT.TAX' | translate }}</span>
      <span class="info">{{fee.thue}} VND</span>
      </div>
      <!-- <div class="text-field">
        <label for="maVoucher">{{ 'PAYMENT.VOUCHER_ID' | translate }}</label>
        <input autocomplete="off" type="text" id="maVoucher" name="maVoucher"
          [placeholder]="'PRODUCT.INSERT_VOUCHER'|translate" [value]="voucher.maVoucher"
          [(ngModel)]="voucher.maVoucher" (blur)="getDiscount(voucher.maVoucher)" />
        <span *ngIf="f.controls['phi']?.valid&&isTrueVoucher==true" class="input--check"><i
            class="fa-solid fa-circle-check"></i></span>
        <span *ngIf="f.controls['phi']?.valid&&isTrueVoucher==false" class="input--check-false"><i
            class="fa-solid fa-circle-xmark"></i></span>
        <div class="invalid">
          <small *ngIf="voucher.maVoucher&& voucher.status === false">{{error.voucher|translate}}</small>
        </div>
      </div> -->
      <div *ngIf="isApp" class="text-field maVoucher" style="width: 100%;">
        <label for="maVoucher">{{ 'PAYMENT.VOUCHER_ID' | translate }}</label>
        <input autocomplete="off" type="text" id="maVoucher" name="maVoucher"
          [placeholder]="'PRODUCT.INSERT_VOUCHER'|translate" [value]="voucher.maVoucher" 
          [(ngModel)]="voucher.maVoucher"
          (blur)="getDiscount(voucher.maVoucher)"/>
          <span *ngIf="f.controls['maVoucher']?.value!=''&&isTrueVoucher==true" class="input--check"><i
            class="fa-solid fa-circle-check"></i></span>
          <span *ngIf="f.controls['maVoucher']?.value!=''&&isTrueVoucher==false" class="input--check-false"><i class="fa-solid fa-circle-xmark"></i></span>
        <div class="invalid">
          <small
            *ngIf="voucher.maVoucher&& voucher.status === false">{{error.voucher|translate}}</small>
        </div>
      </div> 
      <div class="step4-flex">
        <span class="label" style="color: #dc3545;">{{ 'PAYMENT.SALE' | translate }}:</span>
        <span class="info" style="color: #dc3545;">- {{save}} VND</span>
      </div>
      <br>
      <div class="step4-flex">
        <span class="label">{{ 'PAYMENT.TOTAL' | translate }}</span>
      <span class="fee webview-cl">{{form.ttoan}} VND</span>
      </div>
      <br>

      <ng-container [ngTemplateOutlet]="prodStep"></ng-container>
      <!-- <span *ngIf="!isApp" class="notice-payment">{{ 'PAYMENT.NOTICE' | translate }}</span> -->
    </div>
  </form>
</div>

<ng-template #getBackConfirm let-modal>
  <div class="modal-header">
    <h4 class="modal-title">{{ 'COMMON.PAY' | translate }}</h4>
  </div>
  <div class="modal-body">
    <p>{{ 'COMMON.BEFORE_BACK' | translate }} ?</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="getBack()">{{ 'COMMON.YES' | translate }}</button>
    <button type="button" class="btn btn-light" (click)="modal.close('Close click')">{{ 'COMMON.NO' | translate
      }}</button>
  </div>
</ng-template>

<ng-template #viewImage let-modal>
  <div class="modal-header px-4">
    <h4 class="modal-title m-0 fs-4">{{ 'COMMON.UPLOAD_IMAGE' | translate }}</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <table class="w-100">
      <thead *ngIf="tempFile.front || tempFile.back">
        <tr>
          <th class="text-center">{{ 'COMMON.IDENTIFY_FRONT' | translate }}</th>
          <th class="text-center">{{ 'COMMON.IDENTIFY_BACK' | translate }}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td class="text-center w-50 p-2">
            <div class="input-file m-auto" *ngIf="!tempFile.front">
              <input type="file" name="frontIden" id="frontIden" accept="image/png, image/gif, image/jpeg"
                (change)="upload($event, 0)" />
              <label for="frontIden" class="input-label">
                <span class="material-icons">add_a_photo</span><br>
                <span class="input-span">{{ 'COMMON.IDENTIFY_FRONT' | translate }}<br>{{ 'COMMON.IDENTIFY_CARD' |
                  translate }}</span>
              </label>
            </div>

            <div *ngIf="tempFile.front" class="position-relative">
              <img class="w-100 h-auto object-fit-contain" [src]="tempFile.front"
                [alt]="'COMMON.IDENTIFY_FRONT'|translate" [title]="'COMMON.IDENTIFY_FRONT'|translate">
              <button type="button" class="position-absolute btn--cancel" (click)="removeFile(0)"
                [title]="'COMMON.DELETE'|translate">
                <span class="material-icons">cancel</span>
              </button>
            </div>
          </td>

          <td class="text-center w-50 p-2">
            <div class="input-file m-auto" *ngIf="!tempFile.back">
              <input type="file" name="backIden" id="backIden" accept="image/png, image/gif, image/jpeg"
                (change)="upload($event, 1)" />
              <label for="backIden" class="input-label">
                <span class="material-icons">add_a_photo</span><br>
                <span class="input-span">{{ 'COMMON.IDENTIFY_BACK' | translate }}<br>{{ 'COMMON.IDENTIFY_CARD' |
                  translate }}</span>
              </label>
            </div>

            <div *ngIf="tempFile.back" class="position-relative">
              <img class="w-100 h-auto object-fit-contain" [src]="tempFile.back"
                [alt]="'COMMON.IDENTIFY_BACK'|translate" [title]="'COMMON.IDENTIFY_BACK'|translate">
              <button type="button" class="position-absolute btn--cancel" (click)="removeFile(1)"
                [title]="'COMMON.DELETE'|translate">
                <span class="material-icons">cancel</span>
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="confirmFile(); modal.close('Close click')"
      *ngIf="tempFile.front && tempFile.back">{{ 'COMMON.PAY' | translate }}</button>
    <button type="button" class="btn btn-success" (click)="removeFile(0); removeFile(1)"
      *ngIf="tempFile.front || tempFile.back">{{ 'COMMON.UPLOAD_NEW' | translate }}</button>
    <button type="button" class="btn btn-light" (click)="modal.close('Close click')">{{ 'COMMON.EXIT' | translate
      }}</button>
  </div>
</ng-template>

<ng-template #prodStep>
  <div>
    <div *ngIf="stepValue !== 4" class="totalFee">
      <span>{{ 'PAYMENT.TOTAL' | translate }}:</span> {{form.ttoan || 0}} VND
    </div>
    <div class="step-and-total" [ngClass]="isWebview ? 'webview' : ''">
      <button class="back" (click)="stepValue > 1 ? onController(-1, f) : openCentered(getBackConfirm) ">
        {{ 'COMMON.BACK' | translate }}
      </button>
      <button class="next" [disabled]="stepValue === 3 && !fee.tongphi"
        [ngClass]="(stepValue === 3 && !fee.tongphi) ? 'disableBtn' : ''"
        (click)="stepValue < 4 ? onController(1, f) : ''" *ngIf="stepValue < 4">
        {{ 'COMMON.NEXT' | translate }}
      </button>
      <button id="submit" type="submit" class="next" (click)="onSubmit(f)"
        [ngClass]="submited ? 'opacity-50' : 'opacity-100'" [disabled]="submited" *ngIf="stepValue === 4">{{
        'COMMON.PAY'
        | translate }}</button>
    </div>
  </div>
</ng-template>