<div class="uic-payment">
    <div class="step__controller">
      <app-step-controller [steps]="steps" [step]="50" [stepValue]="stepValue" [isWebview]="isWebview">
      </app-step-controller>
    </div>
  
    <form #f="ngForm">
      <div class="form--left">
        <div id="step_1" [ngClass]="stepValue === 1 ? 'step' : 'hidden'">
          <app-form-title [icon]="'loyalty'" [label]="'PRODUCT.PROD_VOLUME'|translate"></app-form-title>
          <div class="typeofclient">
            <span>{{ 'COMMON.TYPE' | translate }}:</span>
            <input type="checkbox" id="additional" name="additional" [value]="additional" [(ngModel)]="additional"
              (ngModelChange)=" getFee()" checked />
            <label for="pers">{{ 'COMMON.ADDITIONAL' | translate }}</label>
            <span class="sub-info" (click)="openModal(subInfo)"><i class="fa-solid fa-circle-question"></i></span>
            <!-- <input type="checkbox" id="additional" name="additional" [value]="additional" [(ngModel)]="additional"
              (ngModelChange)="(additional ? notAdditional = false : ''); getFee()" />
            <label for="additional">{{ 'PRODUCT.FAMILY_4P' | translate }}</label> -->
          </div>
          <br>
          <div class="flex space">
            <div class="dynamic-box">
              <div class="text-field">
                <label for="goi">{{ 'PRODUCT.PACKAGES' | translate }}<span class="required">*</span></label>
                <select required name="goi" id="goi" [(ngModel)]="bill.goi" (ngModelChange)="policyHandler(); getFee()">
                  <option *ngFor="let opt of respons" [value]="opt.value">{{opt.label|translate}}</option>
                </select>
                <span *ngIf="f.controls['goi']?.valid" class="input--check"><i class="fa-solid fa-circle-check"></i></span>
                <div class="invalid">
                  <small *ngIf="f.controls['goi']?.invalid && f.controls['goi']?.touched">{{error.select|translate}}</small>
                </div>
              </div>
  
              <div class="text-field">
                <label for="thoihan">{{ 'PRODUCT.DURATION' | translate }}<span class="required">*</span></label>
                <select required name="thoihan" id="thoihan" [(ngModel)]="bill.thoihan"
                  (ngModelChange)="setTextDate();onChangeDate(bill.thoihan, (isWebview?ngaY_HL_Text:form.ngaY_HL))">
                  <option *ngFor="let opt of durations" [value]="opt.value">{{opt.label|translate}}</option>
                </select>
                <span *ngIf="f.controls['thoihan']?.valid" class="input--check"><i class="fa-solid fa-circle-check"></i></span>
                <div class="invalid">
                  <small
                    *ngIf="f.controls['thoihan']?.invalid || f.controls['thoihan']?.touched && bill.thoihan === '0'">{{error.select|translate}}</small>
                </div>
              </div>
  
              <div class="text-field">
                <label for="ngaY_HL">{{ 'COMMON.FROM_DATE' | translate }}<span class="required">*</span></label>
                <input required autocomplete="off" [type]="isWebview ? 'text' : 'date'" id="ngaY_HL" name="ngaY_HL" [value]="form.ngaY_HL"
                  [(ngModel)]="form.ngaY_HL" [min]="isWebview?null:now" [pattern]="isWebview ? pattern.DATE : pattern.ALL" (ngModelChange)="setTextDate();onChangeDate(bill.thoihan, (isWebview?ngaY_HL_Text:form.ngaY_HL))" />
                  <span *ngIf="f.controls['moiquanhe']?.valid&&validDate&&isWebview" class="input--check"><i class="fa-solid fa-circle-check"></i></span>
                <div class="invalid">
                  <small *ngIf="f.controls['ngaY_HL']?.invalid || form.ngaY_HL.length < 1">{{error.required|translate}}</small>
                  <small *ngIf="f.controls['ngaY_HL']?.valid && form.ngaY_HL.length > 0 && form.ngaY_HL < (isWebview?null:now)">{{error.min_date|translate}}</small>
                  <small *ngIf="f.controls['ngaY_HL']?.valid &&!validDate&&isWebview">{{error.min_date|translate}}</small>
                </div>
              </div>
              <div class="text-field">
                <label for="ngaY_KT">{{ 'COMMON.TO_DATE' | translate }}</label>
                <input autocomplete="off" [type]="isWebview ? 'text' : 'date'" id="ngaY_KT" name="ngaY_KT" readonly [value]="form.ngaY_KT"
                  [(ngModel)]="form.ngaY_KT" />
                  <span *ngIf="f.controls['ngaY_KT']?.valid" class="input--check"><i class="fa-solid fa-circle-check"></i></span>
                <div class="invalid"></div>
              </div>
            </div>
  
            <ng-container [ngTemplateOutlet]="dynamic"></ng-container>
          </div>
  
  
          <div class="form-group">
            <div class="fee-info">
              <div class="text-field">
                <label for="phi">{{ 'PAYMENT.FEE' | translate }}</label>
                <input autocomplete="off" type="text" id="phi" name="phi" readonly [value]="fee.phi" [(ngModel)]="fee.phi"/>
                <span *ngIf="f.controls['phi']?.valid" class="input--check"><i class="fa-solid fa-circle-check"></i></span>
                <div class="invalid"></div>
              </div>
              <div class="text-field">
                <label for="thue">{{ 'PAYMENT.TAX' | translate }}</label>
                <input autocomplete="off" type="text" id="thue" name="thue" readonly [value]="fee.thue" [(ngModel)]="fee.thue"/>
                <span *ngIf="f.controls['thue']?.valid" class="input--check"><i class="fa-solid fa-circle-check"></i></span>
                <div class="invalid"></div>
              </div>
            </div>
            <!-- <div class="text-field">
              <label for="maVoucher">{{ 'PAYMENT.VOUCHER_ID' | translate }}</label>
              <input autocomplete="off" type="text" id="maVoucher" name="maVoucher"
                [placeholder]="'PRODUCT.INSERT_VOUCHER'|translate" [value]="voucher.maVoucher"
                [(ngModel)]="voucher.maVoucher"
                (blur)="getDiscount(voucher.maVoucher)"/>
                <span *ngIf="f.controls['phi']?.valid&&isTrueVoucher==true" class="input--check"><i
                  class="fa-solid fa-circle-check"></i></span>
                <span *ngIf="f.controls['phi']?.valid&&isTrueVoucher==false" class="input--check-false"><i class="fa-solid fa-circle-xmark"></i></span>
              <div class="invalid">
                <small
                  *ngIf="voucher.maVoucher&& voucher.status === false">{{error.voucher|translate}}</small>
              </div>
            </div> -->
          </div>
  
          <ng-container [ngTemplateOutlet]="prodStep"></ng-container>
        </div>
  
        <div id="step_2" [ngClass]="stepValue == 2 ? 'step' : 'hidden'">
          <app-form-title [label]="'PRODUCT.PRIVATE_INFO'|translate" [icon]="'badge'"></app-form-title>
          <div class="form-group">
            <div class="form-grid">
              <div class="text-field">
                <label for="cmt">{{ 'COMMON.IDENTIFY_CARD' | translate }}<span class="required">*</span></label>
                <input required autocomplete="off" type="text" id="cmt" name="cmt" min="9" max="12"
                  [pattern]="pattern.CCCD" [value]="form.cmt" [(ngModel)]="form.cmt" (ngModelChange)="addSelf();checkPersonMain()"/>
                  <span *ngIf="f.controls['cmt']?.valid" class="input--check"><i class="fa-solid fa-circle-check"></i></span>
                <div class="invalid">
                  <small *ngIf="f.controls['cmt']?.invalid && f.controls['cmt']?.touched && !f.controls['cmt'].value">{{error.required|translate}}</small>
                  <small *ngIf="f.controls['cmt']?.errors?.['pattern']">{{error.identify|translate}}</small>
                  <small *ngIf="!isValidCmtMain">{{error.double_ID|translate}}</small>
                </div>
              </div>
              <div class="text-field">
                <label for="mobi">{{ 'COMMON.PHONE_NUMBER' | translate }}<span class="required">*</span></label>
                <input required maxlength="10" [pattern]="pattern.PHONE" autocomplete="off" type="tel" id="mobi"
                  name="mobi" [value]="form.mobi" [(ngModel)]="form.mobi" />
                  <span *ngIf="f.controls['mobi']?.valid" class="input--check"><i class="fa-solid fa-circle-check"></i></span>
                <div class="invalid">
                  <small
                    *ngIf="f.controls['mobi']?.invalid && f.controls['mobi']?.touched && form.mobi.length < 1">{{error.required|translate}}</small>
                  <small *ngIf="f.controls['mobi']?.errors?.['pattern']">{{error.phone|translate}}</small>
                </div>
              </div>
              <div class="text-field">
                <label for="ten">{{ 'COMMON.FULL_NAME' | translate }}<span class="required">*</span></label>
                <input required autocomplete="off" type="text" id="ten" name="ten" [value]="form.ten"
                  [(ngModel)]="form.ten" (blur)="onCapitalize(0);" (ngModelChange)="addSelf();onNumberIn(0)"/>
                  <span *ngIf="f.controls['ten']?.valid" class="input--check"><i class="fa-solid fa-circle-check"></i></span>
                <div [class]="isNumberIn?'alertMessage':'invalid'">
                  <small
                    *ngIf="f.controls['ten']?.invalid && f.controls['ten']?.touched && form.ten.length < 1">{{error.required|translate}}</small>
                    <small *ngIf="isNumberIn">{{error.number_in|translate}}</small>
                </div>
              </div>
              <div class="text-field">
                <label for="nG_SINH">{{ 'COMMON.BIRTHDAY' | translate }}<span class="required">*</span></label>
                <input required minlength="10" maxlength="10" autocomplete="off" [type]="isWebview ? 'text' : 'date'" id="nG_SINH" name="nG_SINH"
                  [value]="form.nG_SINH" [(ngModel)]="form.nG_SINH" placeholder="20-02-2002"  [pattern]="isWebview ? pattern.DATE : pattern.ALL"
                  (ngModelChange)="onChangeBirthDay(form.nG_SINH); addSelf()" />
                  <span *ngIf="f.controls['nG_SINH']?.valid&&isWebview&&!wrongBirth" class="input--check"><i class="fa-solid fa-circle-check"></i></span>
                <div class="invalid">
                  <small *ngIf="f.controls['nG_SINH']?.touched && !f.controls['nG_SINH'].valid">{{error.short|translate}}</small>
                  <small *ngIf="f.controls['nG_SINH']?.touched && !f.controls['nG_SINH'].value">{{error.shortReq|translate}}</small>
                  <small *ngIf="f.controls['nG_SINH']?.touched && wrongBirth && f.controls['nG_SINH'].value">{{ 'PRODUCT.18Y_TO_65Y' | translate }}</small>
                  <small *ngIf="f.controls['nG_SINH']?.errors?.['pattern'] && isWebview">{{error.date|translate}}</small>
                </div>
              </div>
              <div class="input-file" (click)="openImageModal(viewImage)" *ngIf="!tempFile.front || !tempFile.back">
                <label class="input-label">
                  <span class="material-icons">collections</span><br>
                  <span class="input-span">{{ 'COMMON.UPLOAD_IMAGE' | translate }}<br>{{ 'COMMON.IDENTIFY_CARD' | translate }}</span>
                </label>
              </div>
              <div class="uploaded-file" (click)="openImageModal(viewImage)" [title]="'COMMON.IDENTIFY_IMAGE'|translate"
                *ngIf="form.files.front && form.files.back && tempFile.front && tempFile.back">
                <img [src]="form.files.front" [alt]="'COMMON.IDENTIFY_FRONT'|translate">
                <img [src]="form.files.back" [alt]="'COMMON.IDENTIFY_BACK'|translate">
              </div>
            </div>
            <div class="form-child">
              <div class="text-field">
                <label for="gioi">{{ 'COMMON.GENDER' | translate }}<span class="required">*</span></label>
                <select required name="gioi" id="gioi" [(ngModel)]="form.gioi" (ngModelChange)="addSelf()">
                  <option *ngFor="let opt of genders" [value]="opt.value">{{opt.label|translate}}</option>
                </select>
                <span *ngIf="f.controls['gioi']?.valid" class="input--check"><i class="fa-solid fa-circle-check"></i></span>
                <div class="invalid">
                  <small *ngIf="f.controls['gioi']?.invalid && f.controls['gioi']?.touched">{{error.shortSel|translate}}</small>
                </div>
              </div>
              <div class="text-field">
                <label for="email">Email<span class="required">*</span></label>
                <input required [email]="pattern.EMAIL" autocomplete="off" type="email" id="email" name="email"
                  [value]="form.email" [(ngModel)]="form.email" />
                  <span *ngIf="f.controls['email']?.valid" class="input--check"><i class="fa-solid fa-circle-check"></i></span>
                <div class="invalid">
                  <small
                    *ngIf="f.controls['email']?.invalid && f.controls['email']?.touched && form.email.length < 1">{{error.required|translate}}</small>
                  <small *ngIf="f.controls['email']?.errors?.['email']">{{error.email|translate}}</small>
                </div>
              </div>
            </div>
            <div class="text-field">
              <label for="dchi">{{ 'COMMON.ADDRESS' | translate }}<span class="required">*</span></label>
              <input required autocomplete="off" type="text" id="dchi" name="dchi" [value]="form.dchi"
                [(ngModel)]="form.dchi" />
              <span *ngIf="f.controls['dchi']?.valid" class="input--check"><i class="fa-solid fa-circle-check"></i></span>
              <div class="invalid">
                <small *ngIf="f.controls['dchi']?.invalid && f.controls['dchi']?.touched">{{error.required|translate}}</small>
              </div>
            </div>
          </div>
          <div class="subPersons">
            <h3>{{'PRODUCT.SUBTITTLE'|translate}}</h3>
            <div class="d-flex">
              <h4 *ngIf="dsduocbaohiem.length > 0">{{'COMMON.QUANTITY'|translate}} {{dsduocbaohiem.length}}
                {{'COMMON.PERSON'|translate}}</h4>
            </div>
            <div class="protector-list" *ngIf="dsduocbaohiem.length > 0">
              <!-- <table>
                <tr>
                  <th>{{'PRODUCT.POSITION'|translate}}</th>
                  <th>{{'COMMON.FULL_NAME'|translate}}</th>
                  <th>{{'COMMON.SUB'|translate}}</th>
                </tr>
                <tr *ngFor="let person of dsduocbaohiem; let i = index">
                  <td>{{i+1}}</td>
                  <td>{{person.name}}</td>
                  <td><button class="btn btn-success" (click)="editHandler(i,editPerson)"><span
                        class="material-icons">edit</span></button>
                    <button class="btn btn-danger" (click)="deletePerson(i)"><span
                        class="material-icons">delete</span></button>
                  </td>
                </tr>
              </table> -->
              <div *ngFor="let person of dsduocbaohiem; let i = index" class="d-flex">
  
                <div *ngIf="dsduocbaohiem.length>1" style="display: flex;flex-direction: column; width: 100;">
                  <div style="display: flex;">
                    <button *ngIf="i>0" class="btn btn-danger"
                      style="max-width: 20px; max-height: 20px;border-radius: 50%; position: relative; top: 12px;"
                      (click)="deletePerson(i); getFee()"><i class="fa-solid fa-minus"
                        style="position: relative;top:-8px; left: 0px;"></i></button>
                    <button *ngIf="i==0" class="btn"
                      style="max-width: 20px; max-height: 20px;color: white;border-radius: 50%; position: relative; top: 12px;cursor: default;"><i
                        class="fa-solid fa-minus" style="position: relative;top:-8px; left: 0px;"></i></button>
                    <div
                      style="min-width: 100px;width: 260px;min-height: 38px;background: #e3e3fa;margin: 5px 0;padding: 0 0 0 5px;position: relative;">
                      <span style="position: relative; top: 10px; left: 5px;">{{person.name}}</span><button *ngIf="i>0" class="btn"
                        style="color: var(--finlife-color);font-size: 15px;align-items: end;position: absolute;right: 5px;"
                        (click)="editHandler(i,editPerson)">{{'COMMON.EDIT'|translate}}</button></div>
                  </div>
                </div>
              </div>
              <div style="display: flex;justify-content: center;">
                <button class="btn btn-success newBtn"
                  style="background: none; color: var(--finlife-color);margin: 5px 0 10px 0;"
                  (click)="openModal(addPerson)" ><i
                    class="fa-solid fa-plus"></i> {{'COMMON.ADD1'|translate}}</button>
              </div>
            </div>
          </div>
          <ng-container [ngTemplateOutlet]="prodStep"></ng-container>
        </div>
      </div>
  
      <div id="last-step" [ngClass]="stepValue == 3 ? 'step' : 'hidden'" *ngIf="isWebview === false">
        <app-form-title [label]="'PRODUCT.FEE_INFO'|translate"></app-form-title>
        <hr>
        <div class="last-step--label">
          <span>{{ 'PAYMENT.FEE' | translate }}:</span>
          <span>{{fee.phi}} VND</span>
        </div>
        <div class="last-step--label">
          <span>{{ 'PAYMENT.TAX' | translate }}:</span>
          <span>{{fee.thue}} VND</span>
        </div>
        <hr>
        <div class="flex">
          <img class="form__payment--thumb" src="../../../assets/frontend/images/coupon.png" alt="voucher">
          <span class="form__payment--voucher">Voucher:</span>
        </div>
        <div class="flex space" *ngIf="!meetVoucher">
          <div class="text-field launcher">
            <label for="launcher">{{ 'COMMON.REFERRAL_CODE' | translate }}</label>
            <input autocomplete="off" type="text" id="launcher" name="launcher" [placeholder]="'PRODUCT.A00000001'|translate"
              [value]="form.magioithieu" [(ngModel)]="form.magioithieu" [disabled]="isShared||ma_nvAppVoucher||userCode"
              (ngModelChange)="getLauncher(form.magioithieu)" />
              <span *ngIf="f.controls['launcher']?.value!=''&&f.controls['launcher']?.value!=undefined&&isTrueLauncher==true" class="input--check"><i
                class="fa-solid fa-circle-check"></i></span>
              <span *ngIf="f.controls['launcher']?.value!=''&&f.controls['launcher']?.value!=undefined&&isTrueLauncher==false" class="input--check-false"><i class="fa-solid fa-circle-xmark"></i></span>
          </div>
          <div class="text-field maVoucher">
            <label for="maVoucher">{{ 'PAYMENT.VOUCHER_ID' | translate }}</label>
            <input autocomplete="off" type="text" id="maVoucher" name="maVoucher"
              [placeholder]="'PRODUCT.INSERT_VOUCHER'|translate" [value]="voucher.maVoucher" 
              [(ngModel)]="voucher.maVoucher"
              (blur)="getDiscount(voucher.maVoucher)"/>
              <span *ngIf="f.controls['maVoucher']?.value!=''&&isTrueVoucher==true" class="input--check"><i
                class="fa-solid fa-circle-check"></i></span>
              <span *ngIf="f.controls['maVoucher']?.value!=''&&isTrueVoucher==false" class="input--check-false"><i class="fa-solid fa-circle-xmark"></i></span>
            <div class="invalid">
              <small
                *ngIf="voucher.maVoucher&& voucher.status === false">{{error.voucher|translate}}</small>
            </div>
          </div> 
        </div>
        <div class="flex space" *ngIf="meetVoucher">
          <div class="text-field maVoucher" style="width: 100%;">
            <label for="maVoucher">{{ 'PAYMENT.VOUCHER_ID' | translate }}</label>
            <input [disabled]="meetVoucher" autocomplete="off" type="text" id="maVoucher" name="maVoucher"
              [placeholder]="'PRODUCT.INSERT_VOUCHER'|translate" [value]="voucher.maVoucher"
              [(ngModel)]="voucher.maVoucher" (blur)="getDiscount(voucher.maVoucher)" />
            <span *ngIf="f.controls['maVoucher']?.value!=''&&isTrueVoucher==true" class="input--check"><i
                class="fa-solid fa-circle-check"></i></span>
            <span *ngIf="f.controls['maVoucher']?.value!=''&&isTrueVoucher==false" class="input--check-false"><i
                class="fa-solid fa-circle-xmark"></i></span>
            <div class="invalid">
              <small *ngIf="voucher.maVoucher&& voucher.status === false">{{error.voucher|translate}}</small>
            </div>
          </div>
        </div>
        <div *ngIf="form.nguoiGioiThieu" style="color: rgb(28, 124, 192) !important;">
          <span>{{ 'PAYMENT.PRESENTER' | translate }}:</span>
          <span>{{form.nguoiGioiThieu}}</span>
        </div>
        <div *ngIf="f.controls['launcher']?.value!=''&&f.controls['launcher']?.value!=undefined&&!isTrueLauncher">
          <span>{{'PRODUCT.WRONG_CODE'|translate}}</span>
        </div>
        <div class="last-step--label voucher--save">
          <span>{{ 'PAYMENT.SALE' | translate }}:</span>
          <span>- {{save}} VND</span>
        </div>
        <div class="last-step--label" style="font-weight: 500;">
          <span>{{ 'PAYMENT.TOTAL' | translate }}:</span>
          <span>{{form.ttoan}} VND</span>
        </div>
        <hr>
        <button class="form__payment" (click)="onSubmit(f)"
        [disabled]="submited" [ngClass]="submited ? 'opacity-50' : 'opacity-100'">{{ 'COMMON.PAY' | translate }}</button>
      </div>
  
      <div id="webview-step" [ngClass]="stepValue === 3 ? 'step' : 'hidden'" *ngIf="isWebview">
        <app-form-title [label]="'PRODUCT.CLIENT_INFO'|translate"></app-form-title>
        <hr>
        <div class="step4-flex">
          <span class="label">{{ 'PAYMENT.CLIENT_NAME' | translate }}</span>
          <span class="info">{{form.ten}}</span>
        </div>
        <div class="step4-flex">
          <span class="label">{{ 'PAYMENT.CLIENT_EMAIL' | translate }}</span>
        <span class="info">{{form.email}}</span>
        </div>
        <div class="step4-flex">
          <span class="label">{{ 'PRODUCT.CLIENT_PHONE_NUMBER' | translate }}</span>
        <span class="info">{{form.mobi}}</span>
        </div>
        <hr *ngIf="!isApp">
        <div *ngIf="!isApp" class="flex space">
          <div class="text-field launcher">
            <label for="launcher">{{ 'COMMON.REFERRAL_CODE' | translate }}</label>
            <input autocomplete="off" type="text" id="launcher" name="launcher" [placeholder]="'PRODUCT.A00000001'|translate"
              [value]="form.magioithieu" [(ngModel)]="form.magioithieu" [disabled]="isShared||ma_nvAppVoucher||userCode"
              (ngModelChange)="getLauncher(form.magioithieu)" />
              <span *ngIf="f.controls['launcher']?.value!=''&&f.controls['launcher']?.value!=undefined&&isTrueLauncher==true" class="input--check"><i
                class="fa-solid fa-circle-check"></i></span>
              <span *ngIf="f.controls['launcher']?.value!=''&&f.controls['launcher']?.value!=undefined&&isTrueLauncher==false" class="input--check-false"><i class="fa-solid fa-circle-xmark"></i></span>
          </div>
          <div class="text-field maVoucher">
            <label for="maVoucher">{{ 'PAYMENT.VOUCHER_ID' | translate }}</label>
            <input autocomplete="off" type="text" id="maVoucher" name="maVoucher"
              [placeholder]="'PRODUCT.INSERT_VOUCHER'|translate" [value]="voucher.maVoucher" 
              [(ngModel)]="voucher.maVoucher"
              (blur)="getDiscount(voucher.maVoucher)"/>
              <span *ngIf="f.controls['maVoucher']?.value!=''&&isTrueVoucher==true" class="input--check"><i
                class="fa-solid fa-circle-check"></i></span>
              <span *ngIf="f.controls['maVoucher']?.value!=''&&isTrueVoucher==false" class="input--check-false"><i class="fa-solid fa-circle-xmark"></i></span>
            <div class="invalid">
              <small
                *ngIf="voucher.maVoucher&& voucher.status === false">{{error.voucher|translate}}</small>
            </div>
          </div> 
        </div>
        <div *ngIf="form.nguoiGioiThieu" style="color: rgb(28, 124, 192) !important; font-size: 12px;">
          <span>{{ 'PAYMENT.PRESENTER' | translate }}: {{form.nguoiGioiThieu}}</span>
        </div>
        <app-form-title [label]="'PRODUCT.FEE_INFO'|translate"></app-form-title>
        <hr>
        <div *ngIf="f.controls['launcher']?.value!=''&&f.controls['launcher']?.value!=undefined&&!isTrueLauncher">
          <span>{{'PRODUCT.WRONG_CODE'|translate}}</span>
        </div>
        <div class="step4-flex">
          <span class="label">{{ 'PAYMENT.FEE' | translate }}</span>
        <span class="info">{{fee.phi}}</span>
        </div>
        <div class="step4-flex">
          <span class="label">{{ 'PAYMENT.TAX' | translate }}</span>
        <span class="info">{{fee.thue}} VND</span>
        </div>
        <!-- <div class="text-field">
          <label for="maVoucher">{{ 'PAYMENT.VOUCHER_ID' | translate }}</label>
          <input autocomplete="off" type="text" id="maVoucher" name="maVoucher"
            [placeholder]="'PRODUCT.INSERT_VOUCHER'|translate" [value]="voucher.maVoucher"
            [(ngModel)]="voucher.maVoucher" (blur)="getDiscount(voucher.maVoucher)" />
          <span *ngIf="f.controls['phi']?.valid&&isTrueVoucher==true" class="input--check"><i
              class="fa-solid fa-circle-check"></i></span>
          <span *ngIf="f.controls['phi']?.valid&&isTrueVoucher==false" class="input--check-false"><i
              class="fa-solid fa-circle-xmark"></i></span>
          <div class="invalid">
            <small *ngIf="voucher.maVoucher&& voucher.status === false">{{error.voucher|translate}}</small>
          </div>
        </div> -->
        <div *ngIf="isApp" class="text-field maVoucher" style="width: 100%;">
          <label for="maVoucher">{{ 'PAYMENT.VOUCHER_ID' | translate }}</label>
          <input autocomplete="off" type="text" id="maVoucher" name="maVoucher"
            [placeholder]="'PRODUCT.INSERT_VOUCHER'|translate" [value]="voucher.maVoucher" 
            [(ngModel)]="voucher.maVoucher"
            (blur)="getDiscount(voucher.maVoucher)"/>
            <span *ngIf="f.controls['maVoucher']?.value!=''&&isTrueVoucher==true" class="input--check"><i
              class="fa-solid fa-circle-check"></i></span>
            <span *ngIf="f.controls['maVoucher']?.value!=''&&isTrueVoucher==false" class="input--check-false"><i class="fa-solid fa-circle-xmark"></i></span>
          <div class="invalid">
            <small
              *ngIf="voucher.maVoucher&& voucher.status === false">{{error.voucher|translate}}</small>
          </div>
        </div> 
        <div class="step4-flex">
          <span class="label" style="color: #dc3545;">{{ 'PAYMENT.SALE' | translate }}:</span>
          <span class="info" style="color: #dc3545;">- {{save}} VND</span>
        </div>
        <br>
        <div class="step4-flex">
          <span class="label">{{ 'PAYMENT.TOTAL' | translate }}</span>
        <span class="fee webview-cl">{{form.ttoan}} VND</span>
        </div>
        <br>
  
        <ng-container [ngTemplateOutlet]="prodStep"></ng-container>
        <!-- <span *ngIf="!isApp" class="notice-payment">{{ 'PAYMENT.NOTICE' | translate }}</span> -->
      </div>
    </form>
  </div>
  
  <ng-template #getBackConfirm let-modal>
    <div class="modal-header">
      <h4 class="modal-title">{{ 'COMMON.PAY' | translate }}</h4>
    </div>
    <div class="modal-body">
      <p>{{ 'COMMON.BEFORE_BACK' | translate }} ?</p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary" (click)="getBack()">{{ 'COMMON.YES' | translate }}</button>
      <button type="button" class="btn btn-light" (click)="modal.close('Close click')">{{ 'COMMON.NO' | translate }}</button>
    </div>
  </ng-template>
  
  <ng-template #viewImage let-modal>
    <div class="modal-header px-4">
      <h4 class="modal-title m-0 fs-4">{{ 'COMMON.UPLOAD_IMAGE' | translate }}</h4>
          <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
      <table class="w-100">
        <thead *ngIf="tempFile.front || tempFile.back">
          <tr>
            <th class="text-center">{{ 'COMMON.IDENTIFY_FRONT' | translate }}</th>
            <th class="text-center">{{ 'COMMON.IDENTIFY_BACK' | translate }}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="text-center w-50 p-2">
              <div class="input-file m-auto" *ngIf="!tempFile.front">
                <input type="file" name="frontIden" id="frontIden" accept="image/png, image/gif, image/jpeg"
                  (change)="upload($event, 0)" />
                <label for="frontIden" class="input-label">
                  <span class="material-icons">add_a_photo</span><br>
                  <span class="input-span">{{ 'COMMON.IDENTIFY_FRONT' | translate }}<br>{{ 'COMMON.IDENTIFY_CARD' | translate }}</span>
                </label>
              </div>
  
              <div *ngIf="tempFile.front" class="position-relative">
                <img class="w-100 h-auto object-fit-contain" [src]="tempFile.front"
                  [alt]="'COMMON.IDENTIFY_FRONT'|translate" [title]="'COMMON.IDENTIFY_FRONT'|translate">
                <button type="button" class="position-absolute btn--cancel" (click)="removeFile(0)" [title]="'COMMON.DELETE'|translate">
                  <span class="material-icons">cancel</span>
                </button>
              </div>
            </td>
  
            <td class="text-center w-50 p-2">
              <div class="input-file m-auto" *ngIf="!tempFile.back">
                <input type="file" name="backIden" id="backIden" accept="image/png, image/gif, image/jpeg"
                  (change)="upload($event, 1)" />
                <label for="backIden" class="input-label">
                  <span class="material-icons">add_a_photo</span><br>
                  <span class="input-span">{{ 'COMMON.IDENTIFY_BACK' | translate }}<br>{{ 'COMMON.IDENTIFY_CARD' | translate }}</span>
                </label>
              </div>
  
              <div *ngIf="tempFile.back" class="position-relative">
                <img class="w-100 h-auto object-fit-contain" [src]="tempFile.back"
                  [alt]="'COMMON.IDENTIFY_BACK'|translate" [title]="'COMMON.IDENTIFY_BACK'|translate">
                <button type="button" class="position-absolute btn--cancel" (click)="removeFile(1)" [title]="'COMMON.DELETE'|translate">
                  <span class="material-icons">cancel</span>
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary" (click)="confirmFile(); modal.close('Close click')" *ngIf="tempFile.front && tempFile.back">{{ 'COMMON.PAY' | translate }}</button>
      <button type="button" class="btn btn-success" (click)="removeFile(0); removeFile(1)" *ngIf="tempFile.front || tempFile.back">{{ 'COMMON.UPLOAD_NEW' | translate }}</button>
      <button type="button" class="btn btn-light" (click)="modal.close('Close click')">{{ 'COMMON.EXIT' | translate }}</button>
    </div>
  </ng-template>
  <!-- modal thêm người -->
  <ng-template #addPerson let-modal>
    <div class="modal-header px-4">
      <h4 class="modal-title m-0 fs-4">{{'PRODUCT.SUBTITTLE2'|translate}}</h4>
      <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
      <form #p="ngForm" class="addPerson">
        <div class="text-field">
          <label for="moiquanhe">{{'PRODUCT.RELATIONSHIP'|translate}}<span class="required">*</span></label>
          <select required name="moiquanhe" id="moiquanhe" [(ngModel)]="person.moiquanhe">
            <option *ngFor="let opt of moiquanhe" [value]="opt.value">{{opt.label|translate}}</option>
          </select>
          <span *ngIf="p.controls['moiquanhe']?.valid" class="input--check"><i class="fa-solid fa-circle-check"></i></span>
          <div class="invalid">
            <small
              *ngIf="p.controls['moiquanhe']?.invalid && p.controls['moiquanhe']?.touched">{{error.shortSel|translate}}</small>
          </div>
        </div>
        <div class="text-field">
          <label for="name">{{ 'COMMON.FULL_NAME' | translate }}<span class="required">*</span></label>
          <input required autocomplete="off" type="text" id="name" name="name" [value]="person.name"
            [(ngModel)]="person.name" (blur)="onCapitalize(1);" (ngModelChange)="onNumberIn(1)" />
            <span *ngIf="p.controls['name']?.valid" class="input--check"><i class="fa-solid fa-circle-check"></i></span>
          <div [class]="isNumberIn1?'alertMessage':'invalid'">
            <small
              *ngIf="p.controls['name']?.invalid && p.controls['name']?.touched && person.name.length < 1">{{error.required|translate}}</small>
            <small *ngIf="isNumberIn1">{{error.number_in|translate}}</small>
          </div>
        </div>
        <div class="text-field">
          <label for="cmt">{{ 'COMMON.IDENTIFY_CARD' | translate }}<span class="required">*</span></label>
          <input required autocomplete="off" type="text" id="cmt" name="cmt" min="9" max="12" [pattern]="pattern.CCCD"
            [value]="person.cmt" [(ngModel)]="person.cmt" (ngModelChange)="checkPerson()" />
            <span *ngIf="p.controls['cmt']?.valid" class="input--check"><i class="fa-solid fa-circle-check"></i></span>
          <div class="invalid">
            <small
              *ngIf="p.controls['cmt']?.invalid && p.controls['cmt']?.touched && !p.controls['cmt'].value">{{error.required|translate}}</small>
            <small *ngIf="p.controls['cmt']?.errors?.['pattern']">{{error.identify|translate}}</small>
            <small *ngIf="!isValidCmt">{{error.double_ID|translate}}</small>
          </div>
        </div>
        <div class="text-field">
          <label for="gioitinh">{{ 'COMMON.GENDER' | translate }}<span class="required">*</span></label>
          <select required name="gioitinh" id="gioitinh" [(ngModel)]="person.gioitinh">
            <option *ngFor="let opt of genders" [value]="opt.value">{{opt.label|translate}}</option>
          </select>
          <span *ngIf="p.controls['gioitinh']?.valid" class="input--check"><i class="fa-solid fa-circle-check"></i></span>
          <div class="invalid">
            <small
              *ngIf="p.controls['gioitinh']?.invalid && p.controls['gioitinh']?.touched">{{error.shortSel|translate}}</small>
          </div>
        </div>
        <div class="text-field">
          <label for="ngaysinh">{{ 'COMMON.BIRTHDAY' | translate }}<span class="required">*</span></label>
          <input required minlength="10" maxlength="10" autocomplete="off" [type]="isWebview ? 'text' : 'date'" id="ngaysinh" name="ngaysinh"
            [value]="person.ngaysinh" [(ngModel)]="person.ngaysinh" placeholder="20-02-2002"
            [pattern]="isWebview ? pattern.DATE : pattern.ALL" (ngModelChange)="onChangeBirthDayP(person.ngaysinh)" />
            <span *ngIf="p.controls['ngaysinh']?.valid&&isWebview&&!wrongBirthP" class="input--check"><i class="fa-solid fa-circle-check"></i></span>
          <div class="invalid">
            <small *ngIf="f.controls['ngaysinh']?.touched && !f.controls['ngaysinh'].valid">{{error.short|translate}}</small>
            <small
              *ngIf="p.controls['ngaysinh']?.touched && !p.controls['ngaysinh'].value">{{error.shortReq|translate}}</small>
            <small
              *ngIf="p.controls['ngaysinh']?.touched && wrongBirthP && p.controls['ngaysinh'].value">{{'PRODUCT.6Y_TO_65Y'|translate}}</small>
            <small *ngIf="p.controls['ngaysinh']?.errors?.['pattern'] && isWebview">{{error.date|translate}}</small>
          </div>
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <button type="button" [disabled]="p.invalid||wrongBirthP||!isValidCmt"
        class="btn btn-success" (click)="addP(); getFee();modal.close('Close click')">OK</button>
    </div>
  </ng-template>
  <!-- modal chỉnh sửa người -->
  <ng-template #editPerson let-modal>
    <div class="modal-header px-4">
      <h4 class="modal-title m-0 fs-4">{{'PRODUCT.SUBTITTLE2'|translate}}</h4>
      <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
      <form #p="ngForm" class="addPerson">
        <div class="text-field">
          <label for="moiquanhe">{{'PRODUCT.RELATIONSHIP'|translate}}<span class="required">*</span></label>
          <select required name="moiquanhe" id="moiquanhe" [(ngModel)]="person.moiquanhe">
            <option *ngFor="let opt of moiquanhe" [value]="opt.value">{{opt.label|translate}}</option>
          </select>
          <span *ngIf="p.controls['moiquanhe']?.valid" class="input--check"><i class="fa-solid fa-circle-check"></i></span>
          <div class="invalid">
            <small
              *ngIf="p.controls['moiquanhe']?.invalid && p.controls['moiquanhe']?.touched">{{error.shortSel|translate}}</small>
          </div>
        </div>
        <div class="text-field">
          <label for="name">{{ 'COMMON.FULL_NAME' | translate }}<span class="required">*</span></label>
          <input required autocomplete="off" type="text" id="name" name="name" [value]="person.name"
            [(ngModel)]="person.name" (blur)="onCapitalize(1);" (ngModelChange)="onNumberIn(1)" />
            <span *ngIf="p.controls['moiquanhe']?.valid" class="input--check"><i class="fa-solid fa-circle-check"></i></span>
          <div [class]="isNumberIn1?'alertMessage':'invalid'">
            <small
              *ngIf="p.controls['name']?.invalid && p.controls['name']?.touched && person.name.length < 1">{{error.required|translate}}</small>
            <small *ngIf="isNumberIn1">{{error.number_in|translate}}</small>
          </div>
        </div>
        <div class="text-field">
          <label for="cmt">{{ 'COMMON.IDENTIFY_CARD' | translate }}<span class="required">*</span></label>
          <input required autocomplete="off" type="text" id="cmt" name="cmt" min="9" max="12" [pattern]="pattern.CCCD"
            [value]="person.cmt" [(ngModel)]="person.cmt" (ngModelChange)="checkPerson()" />
            <span *ngIf="p.controls['cmt']?.valid" class="input--check"><i class="fa-solid fa-circle-check"></i></span>
          <div class="invalid">
            <small
              *ngIf="p.controls['cmt']?.invalid && p.controls['cmt']?.touched && !p.controls['cmt'].value">{{error.required|translate}}</small>
            <small *ngIf="p.controls['cmt']?.errors?.['pattern']">{{error.identify|translate}}</small>
            <small *ngIf="!isValidCmt">{{error.double_ID|translate}}</small>
          </div>
        </div>
        <div class="text-field">
          <label for="gioitinh">{{ 'COMMON.GENDER' | translate }}<span class="required">*</span></label>
          <select required name="gioitinh" id="gioitinh" [(ngModel)]="person.gioitinh">
            <option *ngFor="let opt of genders" [value]="opt.value">{{opt.label|translate}}</option>
          </select>
          <span *ngIf="p.controls['gioitinh']?.valid" class="input--check"><i class="fa-solid fa-circle-check"></i></span>
          <div class="invalid">
            <small
              *ngIf="p.controls['gioitinh']?.invalid && p.controls['gioitinh']?.touched">{{error.shortSel|translate}}</small>
          </div>
        </div>
        <div class="text-field">
          <label for="ngaysinh">{{ 'COMMON.BIRTHDAY' | translate }}<span class="required">*</span></label>
          <input required minlength="10" maxlength="10" autocomplete="off" [type]="isWebview ? 'text' : 'date'" id="ngaysinh" name="ngaysinh"
            [value]="person.ngaysinh" [(ngModel)]="person.ngaysinh" placeholder="20-02-2002"
            [pattern]="isWebview ? pattern.DATE : pattern.ALL" (ngModelChange)="onChangeBirthDayP(person.ngaysinh)" />
            <span *ngIf="p.controls['ngaysinh']?.valid&&isWebview&&!wrongBirthP" class="input--check"><i class="fa-solid fa-circle-check"></i></span>
          <div class="invalid">
            <small *ngIf="f.controls['ngaysinh']?.touched && !f.controls['ngaysinh'].valid">{{error.short|translate}}</small>
            <small
              *ngIf="p.controls['ngaysinh']?.touched && !p.controls['ngaysinh'].value">{{error.shortReq|translate}}</small>
            <small
              *ngIf="p.controls['ngaysinh']?.touched && wrongBirthP && p.controls['ngaysinh'].value">{{error.expec_date|translate}}</small>
            <small *ngIf="p.controls['ngaysinh']?.errors?.['pattern'] && isWebview">{{error.date|translate}}</small>
          </div>
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <button type="button" [disabled]="p.invalid||wrongBirthP||!isValidCmt" class="btn btn-success"
        (click)="editP();modal.close('Close click')">OK</button>
    </div>
  </ng-template>
  <ng-template #prodStep>
    <div *ngIf="stepValue !== 3" class="totalFee">
      <span>{{ 'PAYMENT.TOTAL' | translate }}:</span> {{form.ttoan || 0}} VND
    </div>
    <div class="step-and-total" [ngClass]="isWebview ? 'webview' : ''">
      <button class="back" (click)="stepValue > 1 ? onController(-1, f) : openCentered(getBackConfirm) ">
        {{ 'COMMON.BACK' | translate }}
      </button>
      <button class="next" [disabled]="stepValue === 2 && !form.ttoan" [ngClass]="(stepValue === 2 && !form.ttoan) ? 'disableBtn' : ''"
        (click)="stepValue < 3 ? onController(1, f) : ''" *ngIf="stepValue < 3">
        {{ 'COMMON.NEXT' | translate }}
      </button>
      <button class="next" (click)="onSubmit(f)" *ngIf="stepValue === 3"
      [disabled]="submited" [ngClass]="submited ? 'opacity-50' : 'opacity-100'">{{ 'COMMON.PAY' | translate }}</button>
    </div>
  </ng-template>
  <!-- infor -->
  <ng-template #subInfo let-modal>
    <div class="modal-header px-4">
      <h4 class="modal-title m-0 fs-4"><b>{{'PRODUCT.SUBINFO'|translate}}</b></h4>
    </div>
    <div class="modal-body">
      <p>{{'PRODUCT.SUBINFO_1'|translate}} {{ dynamicVal?.val_3}} {{'COMMON.MILION'|translate}}.</p>
      <p>{{'PRODUCT.SUBINFO_2'|translate}}</p>
    </div>
    <div class="modal-footer">
      <button type="button"
        class="btn btn-success" (click)="modal.close('Close click')">{{'PRODUCT.SUBINFO_OK'|translate}}</button>
    </div>
  </ng-template>
  <ng-template #dynamic>
    <div class="dynamic-template">
      <h5>{{ 'PRODUCT.RIGHTS' | translate }}</h5>
      <ng-container *ngIf="code === 'BSH04'">
        <div class="flex">
          <span class="material-icons">done</span>
          <p>{{ 'PRODUCT.BSH04_1' | translate }}: {{ dynamicVal?.val_1 }} {{'COMMON.M'|translate}} VND/{{ 'COMMON.DAY' | translate }}</p>
        </div>
        <div class="flex">
          <span class="material-icons">done</span>
          <p>{{ 'PRODUCT.BSH04_2' | translate }}: {{ dynamicVal?.val_2}} VND</p>
        </div>
        <div class="flex">
          <span class="material-icons">done</span>
          <p>{{ 'PRODUCT.BSH04_3' | translate }}: {{ dynamicVal?.val_3}} {{'COMMON.M'|translate}} VND</p>
        </div>
        <!-- <div class="flex">
          <span class="material-icons">done</span>
          <p>{{ 'PRODUCT.BSH04_4' | translate }}: {{ dynamicVal?.val_4}} đồng</p>
        </div> -->
        <!-- <div class="flex">
          <span class="material-icons">done</span>
          <p>{{ 'PRODUCT.BSH04_3' | translate }}: {{ dynamicVal?.val_4 || '10.000.000' }}đ/{{ 'PRODUCT.DURATION' | translate }}</p>
        </div> -->
      </ng-container>
      <ng-container *ngIf="code !== 'GIC01'&&code!=='BSH04'">
        <div class="flex">
          <span class="material-icons">done</span>
          <p *ngIf="code === 'UIC01'">{{ 'PRODUCT.UIC_1' | translate }}: {{ dynamicVal?.val_1 || '100.000' }}/{{ 'COMMON.DAY' | translate }}</p>
          <p *ngIf="code === 'BSH01'">{{ 'PRODUCT.UIC_2' | translate }} {{ dynamicVal?.val_1 || '500.000' }}/{{ 'COMMON.DAY' | translate }}</p>
        </div>
        <div class="flex">
          <span class="material-icons">done</span>
          <p *ngIf="code === 'UIC01'">{{ 'PRODUCT.UIC_3' | translate }}: {{ dynamicVal?.val_2 || '1.000.000' }}đ</p>
          <p *ngIf="code === 'BSH01'">{{ 'PRODUCT.UIC_4' | translate }} {{ dynamicVal?.val_2 || '5.000.000' }}đ</p>
        </div>
        <div class="flex">
          <span class="material-icons">done</span>
          <p *ngIf="code === 'UIC01'">{{ 'PRODUCT.UIC_5' | translate }}: {{ dynamicVal?.val_3 || '10.000.000' }}đ</p>
          <p *ngIf="code === 'BSH01'">{{ 'PRODUCT.UIC_6' | translate }} {{ dynamicVal?.val_3 || '50.000.000' }}đ</p>
        </div>
        <div class="flex">
          <span class="material-icons">done</span>
          <p *ngIf="code === 'UIC01'">{{ 'PRODUCT.UIC_7' | translate }}: {{ dynamicVal?.val_4 || '10.000.000' }}đ/{{ 'PRODUCT.DURATION' | translate }}</p>
          <p *ngIf="code === 'BSH01'">{{ 'PRODUCT.UIC_8' | translate }} {{ dynamicVal?.val_4 || '50.000.000' }}đ/{{ 'PRODUCT.DURATION' | translate }}</p>
        </div>
      </ng-container>
  
      <ng-container *ngIf="code === 'GIC01'">
        <div class="flex">
          <span class="material-icons">done</span>
          <p>{{ 'PRODUCT.UIC_9' | translate }} {{ dynamicVal?.val_1 || '50' }} {{ 'COMMON.MILION' | translate }}</p>
        </div>
        <div class="flex">
          <span class="material-icons">done</span>
          <p>{{ 'PRODUCT.UIC_10' | translate }} {{ dynamicVal?.val_2 || '5' }} {{ 'COMMON.MILION' | translate }}</p>
        </div>
        <div class="flex">
          <span class="material-icons">done</span>
          <p>{{ 'PRODUCT.UIC_11' | translate }} {{ dynamicVal?.val_3 || '4' }} {{ 'COMMON.MILION' | translate }} <br>
            ({{ 'PRODUCT.UIC_12' | translate }} {{ dynamicVal?.val_4 || '200.000' }}/{{ 'COMMON.DAY' | translate }})</p>
        </div>
      </ng-container>
    </div>
  </ng-template>
  
  