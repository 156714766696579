<div class="introduce">
  <div class="container">
    <div class="introduce_tabs_image">
      <img *ngFor="let item of upImage" src="../../..{{item.img}}" alt="introduce {{item.id}}">
    </div>
  </div>
  <div class="tabs_menu">
    <div class="line"></div>
    <div class="tabs_menu--block">
      <span class="tabs_menu--router">
        {{'INTRO.TITTLE'|translate}}
      </span>
    </div>
    <div class="line"></div>
  </div>
  <div class="container">
  <div class="introduce_firt_text">
    <h1>
      {{'INTRO.CONTENT1'|translate}}
    </h1>
    <p>
      {{'INTRO.CONTENT2'|translate}}
    </p>
    <p>
      {{'INTRO.CONTENT3'|translate}}
    </p>
  </div>
  <div>
    <div class="introduce_second_text" *ngFor="let item of upContentFirt"
      ngClass="{{item.id%2 === 0 ? 'row_reverse' : ''}}">
      <img src="../../..{{item.img}}" alt="{{item.head|translate}}">
      <div>
        <h4 class="introduce_text_title">{{item.head|translate}}</h4>
        <p>{{item.label|translate}}</p>
      </div>
    </div>
    <div class="introduce_third_text" *ngFor="let item of upContentSecond">
      <div>
        <h2 class="introduce_text_second_title">{{item.head|translate}}</h2>
        <img src="../../..{{item.img}}" alt="{{item.head|translate}}">
        <p>
          {{item.label|translate}}
        </p>
      </div>
    </div>
  </div>
</div>
</div>

