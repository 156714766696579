import { now } from ".";

export const GIC_RESPONS = [
  { id: 1, value: 'goi_1', label: 'PRODUCTS.BRONZE', label_api: 'GIC Gói Đồng' },
  { id: 2, value: 'goi_2', label: 'PRODUCTS.SILVER', label_api: 'GIC Gói Bạc' },
  { id: 3, value: 'goi_3', label: 'PRODUCTS.GOLD', label_api: 'GIC Gói Vàng' },
  { id: 4, value: 'goi_4', label: 'PRODUCTS.DIAMOND', label_api: 'GIC Gói Kim Cương' },
]

export const BSH03_RESPONS = [
  { id: 1, value: 'goi_1', label: 'PRODUCTS.GOLD', label_api: 'BSH Tai nạn Gói Vàng' },
  { id: 2, value: 'goi_2', label: 'PRODUCTS.PLATIUM', label_api: 'BSH Tai nạn Gói Bạch Kim' },
]

export const BSH04_RESPONS = [
  { id: 1, value: 'goi_1', label: 'PRODUCTS.PACK1', label_api: 'BSH Y tế nâng cao Gói 1' },
  { id: 2, value: 'goi_2', label: 'PRODUCTS.PACK2', label_api: 'BSH Y tế nâng cao Gói 2' },
  { id: 3, value: 'goi_3', label: 'PRODUCTS.PACK3', label_api: 'BSH Y tế nâng cao Gói 3' },
]

export const GIC_DURATIONS = [
  { id: 1, value: '12', label: 'PRODUCTS.12_MONTH' },
]

export const BSH03_DURATIONS = [
  { id: 1, value: '12', label: 'PRODUCTS.12_MONTH' },
]

export const BSH04_DURATIONS = [
  { id: 1, value: '12', label: 'PRODUCTS.12_MONTH' },
]

export const GIC_DYNAMIC_POLICY = [
  { id: 1, value: 'goi_1', val_1: '50', val_2: '5', val_3: '4', val_4: '200.000' },
  { id: 2, value: 'goi_2', val_1: '100', val_2: '5', val_3: '5', val_4: '300.000' },
  { id: 3, value: 'goi_3', val_1: '150', val_2: '10', val_3: '10', val_4: '400.000' },
  { id: 4, value: 'goi_4', val_1: '200', val_2: '10', val_3: '15', val_4: '500.000' },
]

export const BSH03_DYNAMIC_POLICY = [
  { id: 1, value: 'goi_1', val_1: '450', val_2: '10', val_3: '10', val_4: '400.000' },
  { id: 2, value: 'goi_2', val_1: '600', val_2: '10', val_3: '15', val_4: '500.000' },
]

export const BSH04_DYNAMIC_POLICY = [
  { id: 1, value: 'goi_1', val_1: '20', val_2: '60.000', val_3: '2'},
  { id: 2, value: 'goi_2', val_1: '40', val_2: '120.000', val_3: '4'},
  { id: 3, value: 'goi_3', val_1: '60', val_2: '160.000', val_3: '6'},
]

export const GIC_FORM_TEMPLATE = {
  nv: 'GIC01',
  kieU_HD: 'G',
  ttoan: '',
  ten: '',
  cmt: '',
  files: {
    front: '',
    back: '',
  },
  nG_SINH: '',
  ngaY_HL: now,
  ngaY_KT: '',
  gioi: '',
  mobi: '',
  email: '',
  dchi: '',
  nguoiGioiThieu: '',
  tensanpham: 'Bảo hiểm tai nạn cá nhân',
  goisanpham: '',
  thoihan: '',
  iddangky: '',
  magioithieu: '',
  moiquanhe: '',
  user_Code: '',
}
