export const VALID = {
  MIN: '8',
  MAX: '64',
  EMAIL: `/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/`,
  EMAIL1: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  PHONE: `\\d{10}|(?:\\d{3}-){2}\\d{4}|\\(\\d{3}\\)\\d{3}-?\\d{4}`,
  NAME: `(?:[A-ZÀÁẠẢÃÂẦẤẬẨẪĂẰẮẶẲẴÈÉẸẺẼÊỀẾỆỂỄÌÍỊỈĨÒÓỌỎÕÔỒỐỘỔỖƠỜỚỢỞỠÙÚỤỦŨƯỪỨỰỬỮỲÝỴỶỸĐ]|[a-zàáạảãâầấậẩẫăằắặẳẵèéẹẻẽêềếệểễìíịỉĩòóọỏõôồốộổỗơờớợởỡùúụủũưừứựửữỳýỵỷỹđ])[a-zàáạảãâầấậẩẫăằắặẳẵèéẹẻẽêềếệểễìíịỉĩòóọỏõôồốộổỗơờớợởỡùúụủũưừứựửữỳýỵỷỹđ]*(?:[ ](?:[A-ZÀÁẠẢÃÂẦẤẬẨẪĂẰẮẶẲẴÈÉẸẺẼÊỀẾỆỂỄÌÍỊỈĨÒÓỌỎÕÔỒỐỘỔỖƠỜỚỢỞỠÙÚỤỦŨƯỪỨỰỬỮỲÝỴỶỸĐ]|[a-zàáạảãâầấậẩẫăằắặẳẵèéẹẻẽêềếệểễìíịỉĩòóọỏõôồốộổỗơờớợởỡùúụủũưừứựửữỳýỵỷỹđ])[a-zàáạảãâầấậẩẫăằắặẳẵèéẹẻẽêềếệểễìíịỉĩòóọỏõôồốộổỗơờớợởỡùúụủũưừứựửữỳýỵỷỹđ]*)*$`,
  LICENSE_PLATES: `[0-9]{1,2}(?:[-]{0,1}|[ ]{0,1})(?:[A-Z]{1,2}|MĐ|TĐ)[0-9]{0,1}[ ]{0,1}(?:[0-9]{4}|[0-9]{3}[.][0-9]{2})`,
  CAR_LICENSE_PLATES: `[0-9]{1,2}[A-Z]{1,2}(?:[-]{0,1}|[ ]{0,1})[0-9]{4,5}`,
  YEAR: `(?:(?:18|19|20|21)[0-9]{2})`,
  CCCD: /(^[0-9]{9,9}$)|(^[0-9]{12,12}$)/,
  SEAT: `[1-9]{1}[0-9]{0,1}`,
  DATE: /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/,
  ALL: /.*/,
}

export const ERROR = {
  required: 'ERROR.REQUIRED',
  shortReq: 'ERROR.REQUIRED_SHORT',
  short: 'ERROR.REQUIRED_LENGTH10',
  select: 'ERROR.SELECT',
  shortSel: 'ERROR.SELECT_SHORT',
  retype: 'ERROR.RETYPE',
  verify: 'ERROR.VERIFY',
  min_length: 'ERROR.MIN',
  min_date: 'ERROR.MIN_DATE',
  max_birth: 'ERROR.MAX_BIRTH',
  expec_date: 'ERROR.EXPEC_DATE',
  eff_date: 'ERROR.EFF_DATE',
  year: 'ERROR.YEAR',
  so_khung: 'ERROR.SO_KHUNG',
  so_may: 'ERROR.SO_MAY',
  phone: 'ERROR.PHONE',
  name: 'ERROR.NAME',
  identify: 'ERROR.IDENTIFY',
  double_ID: 'ERROR.DOUBLE_ID',
  email: 'ERROR.EMAIL',
  type: 'ERROR.TYPE',
  series: 'ERROR.SERIES',
  district: 'ERROR.DICSTRICT',
  images: 'ERROR.IMAGES',
  voucher: 'ERROR.VOUCHER',
  bien: 'ERROR.BIEN',
  may: 'ERROR.MAY',
  khung: 'ERROR.KHUNG',
  ttai: 'ERROR.TTAI',
  seat: 'ERROR.SEAT',
  truck: 'ERROR.TRUCK',
  date: 'ERROR.DATE',
  max_date: 'ERROR.MAX_DATE',
  number_in: 'ERROR.NUMBER_IN',
}
