import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NgxEditorModule } from 'ngx-editor';
import * as ngxSkeletonLoader from 'ngx-skeleton-loader';
import { SlickCarouselModule } from 'ngx-slick-carousel';

import { DatePipe } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getMessaging, provideMessaging } from '@angular/fire/messaging';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { BrowserModule, Meta, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { NewsComponent } from '@modules/news/news.component';
import { NgbAccordionModule, NgbModule, NgbToastModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AccordionComponent } from './components/accordion/accordion.component';
import { ActiveComponent } from './components/active/active.component';
import { ButtonComponent } from './components/button/button.component';
import {
  CategoryCarouselCardComponent
} from './components/category-carousel-card/category-carousel-card.component';
import { ContentTitleComponent } from './components/content-title/content-title.component';
import { CustomersComponent } from './components/customers/customers.component';
import { FormTitleComponent } from './components/form-title/form-title.component';
import { HelpCardComponent } from './components/help-card/help-card.component';
import { InfoCardComponent } from './components/info-card/info-card.component';
import { InputImageComponent } from './components/input-image/input-image.component';
import { InputComponent } from './components/input/input.component';
import { LastStepComponent } from './components/last-step/last-step.component';
import { LoadingComponent } from './components/loading/loading.component';
import { MegaMenuComponent } from './components/mega-menu/mega-menu.component';
import {
  MyUploadAdapterComponent
} from './components/my-upload-adapter/my-upload-adapter.component';
import { NotfoundComponent } from './components/notfound/notfound.component';
import { PointatComponent } from './components/pointat/pointat.component';
import { ProductCardComponent } from './components/product-card/product-card.component';
import { PromiseCardComponent } from './components/promise-card/promise-card.component';
import { StepcontrollerComponent } from './components/stepcontroller/stepcontroller.component';
import { ToastComponent } from './components/toast/toast.component';
import { UspCardComponent } from './components/usp-card/usp-card.component';
import { ExportDirective } from './directive/export.directive';
import { authInterceptorProviders } from './helpers/auth.interceptor';
import { FooterComponent } from './layouts/footer/footer.component';
import { HeaderComponent } from './layouts/header/header.component';
import { SidebarComponent } from './layouts/sidebar/sidebar.component';
import { AuthComponent } from './modules/auth/auth.component';
import { CartManagerComponent } from './modules/cart-manager/cart-manager.component';
import {
  CategoriesManagerComponent
} from './modules/categories-manager/categories-manager.component';
import { CompareResultComponent } from './modules/compare-result/compare-result.component';
import { CompareComponent } from './modules/compare/compare.component';
import { ContactComponent } from './modules/contact/contact.component';
import { ContentDetailComponent } from './modules/content-detail/content-detail.component';
import { ContentEditComponent } from './modules/content-edit/content-edit.component';
import { ContentManagerComponent } from './modules/content-manager/content-manager.component';
import { FeedbacksManagerComponent } from './modules/feedbacks-manager/feedbacks-manager.component';
import { HomeComponent } from './modules/home/home.component';
import { IntroduceComponent } from './modules/introduce/introduce.component';
import { InvestorsManagerComponent } from './modules/investors-manager/investors-manager.component';
import { LoginGPAYComponent } from './modules/login-gpay/login-gpay.component';
import { LoginComponent } from './modules/login/login.component';
import { OverviewComponent } from './modules/overview/overview.component';
import { PaymentConfirmComponent } from './modules/payment-confirm/payment-confirm.component';
import { PaymentComponent } from './modules/payment/payment.component';
import { ProductEditComponent } from './modules/product-edit/product-edit.component';
import { ProductComponent } from './modules/product/product.component';
import { ProductsManagerComponent } from './modules/products-manager/products-manager.component';
import { SlidesManagerComponent } from './modules/slides-manager/slides-manager.component';
import { VoucherManagerComponent } from './modules/voucher-manager/voucher-manager.component';
import { BaohiemacareComponent } from './payments/baohiemacare/baohiemacare.component';
import { BaohiemsuckhoeComponent } from './payments/baohiemsuckhoe/baohiemsuckhoe.component';
import {
  BaohiemtainancanhanComponent
} from './payments/baohiemtainancanhan/baohiemtainancanhan.component';
import {
  BaohiemtrocapnamvienComponent
} from './payments/baohiemtrocapnamvien/baohiemtrocapnamvien.component';
import { CarComponent } from './payments/car/car.component';
import { MediplusComponent } from './payments/mediplus/mediplus.component';
import { MiccareComponent } from './payments/miccare/miccare.component';
import { MotobikeComponent } from './payments/motobike/motobike.component';
import { TermsComponent } from './modules/terms/terms.component';
import { PolicyComponent } from './modules/policy/policy.component';
import { BaohiemtainancanhanBSHComponent } from './payments/baohiemtainancanhan-bsh/baohiemtainancanhan-bsh.component';
import { SignContractComponent } from './modules/sign-contract/sign-contract.component';
import { SignatureComponent } from './modules/signature/signature.component';
import { VoucherEditComponent } from './modules/voucher-edit/voucher-edit.component';
import { WebviewAppComponent } from './webview-app/webview-app.component';
import { ProcessComponent } from './modules/process/process.component';
import { AcctiveAccountComponent } from './modules/acctive-account/acctive-account.component';
import { AccManagerComponent } from './modules/acc-manager/acc-manager.component';
import { CollaboratorsManagerComponent } from './modules/collaborators-manager/collaborators-manager.component';
import { CollaboratorsLoginComponent } from './modules/collaborators-login/collaborators-login.component';
import { CollaboratorsContractsComponent } from './modules/collaborators-contracts/collaborators-contracts.component';
import { BaohiemytecaocapComponent } from './payments/baohiemytecaocap/baohiemytecaocap.component';
import { MaterialManagerComponent } from './modules/material-manager/material-manager.component';
import { ContractMeetvoucherComponent } from './modules/contract-meetvoucher/contract-meetvoucher.component';


export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}


@NgModule({
  declarations: [
    AppComponent,
    AuthComponent,
    ContactComponent,
    HomeComponent,
    IntroduceComponent,
    LoginComponent,
    ProductComponent,
    OverviewComponent,
    CartManagerComponent,
    CategoriesManagerComponent,
    ProductsManagerComponent,
    SlidesManagerComponent,
    InvestorsManagerComponent,
    FeedbacksManagerComponent,
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    ButtonComponent,
    ContentTitleComponent,
    CustomersComponent,
    ProductCardComponent,
    InfoCardComponent,
    HelpCardComponent,
    PromiseCardComponent,
    PointatComponent,
    UspCardComponent,
    CategoryCarouselCardComponent,
    PaymentComponent,
    MotobikeComponent,
    CarComponent,
    MediplusComponent,
    InputComponent,
    InputImageComponent,
    FormTitleComponent,
    MegaMenuComponent,
    MiccareComponent,
    StepcontrollerComponent,
    BaohiemsuckhoeComponent,
    PaymentConfirmComponent,
    LoadingComponent,
    NotfoundComponent,
    VoucherManagerComponent,
    CompareComponent,
    ExportDirective,
    NewsComponent,
    ContentManagerComponent,
    ContentDetailComponent,
    CompareResultComponent,
    AccordionComponent,
    BaohiemacareComponent,
    BaohiemtrocapnamvienComponent,
    LastStepComponent,
    ToastComponent,
    ActiveComponent,
    ContentEditComponent,
    ProductEditComponent,
    MyUploadAdapterComponent,
    BaohiemtainancanhanComponent,
    LoginGPAYComponent,
    TermsComponent,
    PolicyComponent,
    BaohiemtainancanhanBSHComponent,
    SignContractComponent,
    SignatureComponent,
    VoucherEditComponent,
    WebviewAppComponent,
    ProcessComponent,
    AcctiveAccountComponent,
    AccManagerComponent,
    CollaboratorsManagerComponent,
    CollaboratorsLoginComponent,
    CollaboratorsContractsComponent,
    BaohiemytecaocapComponent,
    MaterialManagerComponent,
    ContractMeetvoucherComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatSidenavModule,
    NgbToastModule,
    NgbModule,
    NgxEditorModule,
    SlickCarouselModule,
    ngxSkeletonLoader.NgxSkeletonLoaderModule,
    RouterModule.forRoot([]),
    NgMultiSelectDropDownModule.forRoot(),
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideMessaging(() => getMessaging()),
    NgbAccordionModule,
    CKEditorModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    Title,
    DatePipe,
    authInterceptorProviders,
    Meta
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
