<div class="mediplus-payment">
  <div class="step__controller">
    <app-step-controller [steps]="steps" [step]="50" [stepValue]="stepValue" [isWebview]="isWebview">
    </app-step-controller>
  </div>

  <form #f="ngForm">
    <div class="form--left">
      <div id="step_1" [ngClass]="stepValue == 1 ? 'step' : 'hidden'">
        <app-form-title [icon]="'loyalty'" [label]="'PRODUCT.PROD_VOLUME'|translate"></app-form-title>
        <div class="form-group">
          <div class="text-field">
            <label for="ngaY_HL">{{ 'COMMON.FROM_DATE' | translate }}<span class="required">*</span></label>
            <input required autocomplete="off" [type]="isWebview ? 'text' : 'date'" id="ngaY_HL" name="ngaY_HL"
              [value]="bill.ngaY_HL" [(ngModel)]="bill.ngaY_HL" [min]="isWebview?null:now"
              [pattern]="isWebview ? pattern.DATE : pattern.ALL" (ngModelChange)="onChangeFromDate()" />
              <span *ngIf="f.controls['ngaY_HL']?.valid&&validDate&&isWebview" class="input--check"><i class="fa-solid fa-circle-check"></i></span>
            <div class="invalid">
              <small *ngIf="f.controls['ngaY_HL']?.invalid || bill.ngaY_HL.length < 1">{{error.required|translate}}</small>
              <small
                *ngIf="f.controls['ngaY_HL']?.valid && bill.ngaY_HL.length > 0 && bill.ngaY_HL < (isWebview?null:now)">{{error.min_date|translate}}</small>
              <small *ngIf="f.controls['ngaY_HL']?.valid &&!validDate&&isWebview">{{error.min_date|translate}}</small>
            </div>
          </div>
          <div class="text-field">
            <label for="han">{{ 'PRODUCT.DURATION' | translate }}</label>
            <select name="han" id="han" [(ngModel)]="bill.han">
              <option *ngFor="let opt of durations" [value]="opt.value">{{opt.label|translate}}</option>
            </select>
            <span *ngIf="f.controls['han']?.valid" class="input--check"><i class="fa-solid fa-circle-check"></i></span>
            <div class="invalid"></div>
          </div>
          <div class="text-field">
            <label for="muc">{{ 'VIHICLE.LOR' | translate }}</label>
            <select name="muc" id="muc" [(ngModel)]="bill.muc" (ngModelChange)="policyHandler(); getFee()">
              <option *ngFor="let opt of respons" [value]="opt.value">{{opt.label|translate}}</option>
            </select>
            <span *ngIf="f.controls['muc']?.valid" class="input--check"><i class="fa-solid fa-circle-check"></i></span>
            <div class="invalid"></div>
          </div>
          <div class="text-field">
            <label for="sO_DT">{{ 'VIHICLE.NUMBER_OF_PEOPLE' | translate }}</label>
            <span id="sO_DT" class="disabled">{{fee.phi}}</span>
          </div>

          <div class="fee-info">
            <div class="text-field">
              <label for="phi">{{ 'PAYMENT.FEE' | translate }}</label>
              <input autocomplete="off" type="text" id="phi" name="phi" readonly [value]="fee.phi" [(ngModel)]="fee.phi"/>
              <span *ngIf="f.controls['phi']?.valid&&f.controls['phi']?.dirty" class="input--check"><i class="fa-solid fa-circle-check"></i></span>
              <div class="invalid"></div>
            </div>
            <div class="text-field">
              <label for="thue">{{ 'PAYMENT.TAX' | translate }}</label>
              <input autocomplete="off" type="text" id="thue" name="thue" readonly [value]="fee.thue" [(ngModel)]="fee.thue"/>
              <span *ngIf="f.controls['thue']?.valid&&f.controls['thue']?.dirty" class="input--check"><i class="fa-solid fa-circle-check"></i></span>
              <div class="invalid"></div>
            </div>
          </div>
          <div class="text-field">
            <label for="tongphi">{{ 'PAYMENT.TOTAL' | translate }}</label>
            <input autocomplete="off" type="text" id="tongphi" name="tongphi" readonly [value]="fee.tongphi" [(ngModel)]="fee.tongphi"/>
            <span *ngIf="f.controls['tongphi']?.valid&&f.controls['tongphi']?.dirty" class="input--check"><i class="fa-solid fa-circle-check"></i></span>
            <div class="invalid"></div>
          </div>
        </div>

        <ng-container [ngTemplateOutlet]="prodStep"></ng-container>
      </div>
      <div id="step_2" [ngClass]="stepValue == 2 ? 'step' : 'hidden'">
        <app-form-title [icon]="'badge'" [label]="'PRODUCT.PROTECTOR'|translate"></app-form-title>
        <div class="typeofprotector">
          <span>{{ 'COMMON.TYPE' | translate }}:</span>
          <input type="checkbox" id="prot" name="isProtector" [value]="isProtector" [(ngModel)]="isProtector"
            (ngModelChange)="isProtector ? isInner = false : ''" checked />
          <label for="prot">{{ 'PRODUCT.MEDIPLUS_1' | translate }}</label>
          <input type="checkbox" id="inner" name="isInner" [value]="isInner" [(ngModel)]="isInner"
            (ngModelChange)="isInner ? isProtector = false : ''" />
          <label for="inner">{{ 'PRODUCT.MEDIPLUS_2' | translate }}</label>
        </div>
        <div [ngClass]="isProtector ? 'protector-list' : 'hide-list'">
          <div class="text-field">
            <label for="nG_HUONG">{{ 'PRODUCT.BENEFICIARY' | translate }}<span class="required">{{ 'PRODUCT.MEDIPLUS_3' | translate }}</span></label>
            <textarea id="nG_HUONG" name="nG_HUONG" rows="3" cols="20"
              [placeholder]="'PRODUCT.INFO_PLACEHOLDER'|translate" [(ngModel)]="form.nG_HUONG"></textarea>
          </div>
        </div>

        <div [ngClass]="isInner ? 'protector-list' : 'hide-list'">
          <table>
            <tr>
              <th>STT<span class="required"></span></th>
              <th>{{ 'COMMON.FULL_NAME' | translate }}<span class="required">*</span></th>
              <th>{{ 'COMMON.IDENTIFY_CARD' | translate }}<span class="required">*</span></th>
              <th>{{ 'COMMON.BIRTHDAY' | translate }}<span class="required">*</span></th>
              <th>{{ 'COMMON.GENDER' | translate }}</th>
              <th>Mobi</th>
              <th>Email</th>
            </tr>
            <tr>
              <td>
                <span>1</span>
              </td>
              <td>
                <input autocomplete="off" type="text" id="dS_TEN" name="dS_TEN" [value]="docs[0].dS_TEN"
                  [(ngModel)]="docs[0].dS_TEN" />
              </td>
              <td>
                <input autocomplete="off" type="text" id="dS_CMT" name="dS_CMT" [value]="docs[0].dS_CMT"
                  [(ngModel)]="docs[0].dS_CMT" />
              </td>
              <td>
                <input autocomplete="off" type="date" id="dS_NG_SINH" name="dS_NG_SINH" [value]="docs[0].dS_NG_SINH"
                  (change)="onGetFeeTable(0)" [(ngModel)]="docs[0].dS_NG_SINH" />
              </td>
              <td>
                <select name="dS_GIOI" id="dS_GIOI" [(ngModel)]="docs[0].dS_GIOI">
                  <option *ngFor="let opt of genders" [value]="opt.value">{{opt.label|translate}}</option>
                </select>
              </td>
              <td>
                <input autocomplete="off" type="text" id="dS_MOBI" name="dS_MOBI" [value]="docs[0].dS_MOBI"
                  [(ngModel)]="docs[0].dS_MOBI" />
              </td>
              <td>
                <input autocomplete="off" type="text" id="dS_EMAIL" name="dS_EMAIL" [value]="docs[0].dS_EMAIL"
                  [(ngModel)]="docs[0].dS_EMAIL" />
              </td>
            </tr>
            <tr>
              <td>
                <span>2</span>
              </td>
              <td>
                <input autocomplete="off" type="text" id="dS_TEN" name="dS_TEN" [value]="docs[1].dS_TEN"
                  [(ngModel)]="docs[1].dS_TEN" />
              </td>
              <td>
                <input autocomplete="off" type="text" id="dS_CMT" name="dS_CMT" [value]="docs[1].dS_CMT"
                  [(ngModel)]="docs[1].dS_CMT" />
              </td>
              <td>
                <input autocomplete="off" type="date" id="dS_NG_SINH" name="dS_NG_SINH" [value]="docs[1].dS_NG_SINH"
                  (change)="onGetFeeTable(1)" [(ngModel)]="docs[1].dS_NG_SINH" />
              </td>
              <td>
                <select name="dS_GIOI" id="dS_GIOI" [(ngModel)]="docs[1].dS_GIOI">
                  <option *ngFor="let opt of genders" [value]="opt.value">{{opt.label|translate}}</option>
                </select>
              </td>
              <td>
                <input autocomplete="off" type="text" id="dS_MOBI" name="dS_MOBI" [value]="docs[1].dS_MOBI"
                  [(ngModel)]="docs[1].dS_MOBI" />
              </td>
              <td>
                <input autocomplete="off" type="text" id="dS_EMAIL" name="dS_EMAIL" [value]="docs[1].dS_EMAIL"
                  [(ngModel)]="docs[1].dS_EMAIL" />
              </td>
            </tr>
            <tr>
              <td>
                <span>3</span>
              </td>
              <td>
                <input autocomplete="off" type="text" id="dS_TEN" name="dS_TEN" [value]="docs[2].dS_TEN"
                  [(ngModel)]="docs[2].dS_TEN" />
              </td>
              <td>
                <input autocomplete="off" type="text" id="dS_CMT" name="dS_CMT" [value]="docs[2].dS_CMT"
                  [(ngModel)]="docs[2].dS_CMT" />
              </td>
              <td>
                <input autocomplete="off" type="date" id="dS_NG_SINH" name="dS_NG_SINH" [value]="docs[2].dS_NG_SINH"
                  (change)="onGetFeeTable(2)" [(ngModel)]="docs[2].dS_NG_SINH" />
              </td>
              <td>
                <select name="dS_GIOI" id="dS_GIOI" [(ngModel)]="docs[2].dS_GIOI">
                  <option *ngFor="let opt of genders" [value]="opt.value">{{opt.label|translate}}</option>
                </select>
              </td>
              <td>
                <input autocomplete="off" type="text" id="dS_MOBI" name="dS_MOBI" [value]="docs[2].dS_MOBI"
                  [(ngModel)]="docs[2].dS_MOBI" />
              </td>
              <td>
                <input autocomplete="off" type="text" id="dS_EMAIL" name="dS_EMAIL" [value]="docs[2].dS_EMAIL"
                  [(ngModel)]="docs[2].dS_EMAIL" />
              </td>
            </tr>
            <tr>
              <td>
                <span>4</span>
              </td>
              <td>
                <input autocomplete="off" type="text" id="dS_TEN" name="dS_TEN" [value]="docs[3].dS_TEN"
                  [(ngModel)]="docs[3].dS_TEN" />
              </td>
              <td>
                <input autocomplete="off" type="text" id="dS_CMT" name="dS_CMT" [value]="docs[3].dS_CMT"
                  [(ngModel)]="docs[3].dS_CMT" />
              </td>
              <td>
                <input autocomplete="off" type="date" id="dS_NG_SINH" name="dS_NG_SINH" [value]="docs[3].dS_NG_SINH"
                  (change)="onGetFeeTable(3)" [(ngModel)]="docs[3].dS_NG_SINH" />
              </td>
              <td>
                <select name="dS_GIOI" id="dS_GIOI" [(ngModel)]="docs[3].dS_GIOI">
                  <option *ngFor="let opt of genders" [value]="opt.value">{{opt.label|translate}}</option>
                </select>
              </td>
              <td>
                <input autocomplete="off" type="text" id="dS_MOBI" name="dS_MOBI" [value]="docs[3].dS_MOBI"
                  [(ngModel)]="docs[3].dS_MOBI" />
              </td>
              <td>
                <input autocomplete="off" type="text" id="dS_EMAIL" name="dS_EMAIL" [value]="docs[3].dS_EMAIL"
                  [(ngModel)]="docs[3].dS_EMAIL" />
              </td>
            </tr>
            <tr>
              <td>
                <span>5</span>
              </td>
              <td>
                <input autocomplete="off" type="text" id="dS_TEN" name="dS_TEN" [value]="docs[4].dS_TEN"
                  [(ngModel)]="docs[4].dS_TEN" />
              </td>
              <td>
                <input autocomplete="off" type="text" id="dS_CMT" name="dS_CMT" [value]="docs[4].dS_CMT"
                  [(ngModel)]="docs[4].dS_CMT" />
              </td>
              <td>
                <input autocomplete="off" type="date" id="dS_NG_SINH" name="dS_NG_SINH" [value]="docs[4].dS_NG_SINH"
                  (change)="onGetFeeTable(4)" [(ngModel)]="docs[4].dS_NG_SINH" />
              </td>
              <td>
                <select name="dS_GIOI" id="dS_GIOI" [(ngModel)]="docs[4].dS_GIOI">
                  <option *ngFor="let opt of genders" [value]="opt.value">{{opt.label|translate}}</option>
                </select>
              </td>
              <td>
                <input autocomplete="off" type="text" id="dS_MOBI" name="dS_MOBI" [value]="docs[4].dS_MOBI"
                  [(ngModel)]="docs[4].dS_MOBI" />
              </td>
              <td>
                <input autocomplete="off" type="text" id="dS_EMAIL" name="dS_EMAIL" [value]="docs[4].dS_EMAIL"
                  [(ngModel)]="docs[4].dS_EMAIL" />
              </td>
            </tr>
          </table>
          <div class="flex space">
            <span class="notice">{{ 'PRODUCT.MEDIPLUS_4' | translate }}</span>
            <a class="table-btn" href="../../../assets/Upload/files/FileMau_DS.xls" download="FileMau_DS.xls">
              <span class="material-icons">download</span>
              <span>{{ 'PRODUCT.MEDIPLUS_5' | translate }}</span>
            </a>
            <!-- <button class="table-btn">
              <span class="material-icons">upload</span>
              <span>Upload file</span>
            </button>
            <button class="table-btn" (click)="onGetDocs()">
              <span class="material-icons">content_cut</span>
              <span>Cắt dòng</span>
            </button> -->
          </div>
        </div>

        <app-form-title [label]="'PRODUCT.CLIENT_INFO'|translate"></app-form-title>
        <div class="typeofclient">
          <span>{{ 'COMMON.TYPE' | translate }}:</span>
          <input type="checkbox" id="pers" name="isPerson" [value]="isPerson" [(ngModel)]="isPerson"
          (click)="isCorp = false ; isPerson=true;onValidBirthday(form.nG_SINH) "/>
          <label for="pers">{{ 'COMMON.PERSONAL' | translate }}</label>
          <input type="checkbox" id="corp" name="isCorp" [value]="isCorp" [(ngModel)]="isCorp"
          (click)="isPerson = false ; isCorp=true;onValidBirthday(form.nG_SINH)"/>
          <label for="corp">{{ 'COMMON.COMPANY' | translate }}</label>
        </div>

        <div class="form-group">
          <div class="form-grid">
            <div class="text-field">
              <label for="cmt">{{isCorp ? ('PRODUCT.TAX_ID'|translate) : ('COMMON.IDENTIFY_CARD'|translate)}}<span class="required">*</span></label>
              <input required autocomplete="off" type="text" [pattern]="pattern.CCCD" id="cmt" name="cmt"
                [value]="form.cmt" [(ngModel)]="form.cmt" />
                <span *ngIf="f.controls['cmt']?.valid" class="input--check"><i class="fa-solid fa-circle-check"></i></span>
              <div class="invalid">
                <small *ngIf="f.controls['cmt']?.invalid && f.controls['cmt']?.touched && form.cmt.length < 1">
                  {{error.required|translate}}
                </small>
                <small *ngIf="f.controls['cmt']?.errors?.['pattern']">{{error.identify|translate}}</small>
              </div>
            </div>
            <div class="text-field">
              <label for="mobi">{{ 'COMMON.PHONE_NUMBER' | translate }}<span class="required">*</span></label>
              <input required maxlength="10" [pattern]="pattern.PHONE" autocomplete="off" type="tel" id="mobi"
                name="mobi" [value]="form.mobi" [(ngModel)]="form.mobi" />
                <span *ngIf="f.controls['mobi']?.valid" class="input--check"><i class="fa-solid fa-circle-check"></i></span>
              <div class="invalid">
                <small
                  *ngIf="f.controls['mobi']?.invalid && f.controls['mobi']?.touched && form.mobi.length < 1">{{error.required|translate}}</small>
                <small *ngIf="f.controls['mobi']?.errors?.['pattern']">{{error.phone|translate}}</small>
              </div>
            </div>
            <div class="text-field">
              <label for="ten">{{isCorp ? ('COMMON.COMPANY_NAME'|translate) : ('COMMON.FULL_NAME'|translate)}}<span class="required">*</span></label>
              <input required autocomplete="off" type="text" id="ten" name="ten" [value]="form.ten"
                [(ngModel)]="form.ten" (blur)="onCapitalize();onNumberIn()" />
                <span *ngIf="f.controls['ten']?.valid" class="input--check"><i class="fa-solid fa-circle-check"></i></span>
              <div [class]="isNumberIn?'alertMessage':'invalid'">
                <small
                  *ngIf="f.controls['ten']?.invalid && f.controls['ten']?.touched && form.ten.length < 1">{{error.required|translate}}</small>
                  <small *ngIf="isNumberIn">{{error.number_in|translate}}</small>
              </div>
            </div>
            <div class="text-field">
              <label for="nG_SINH">{{isCorp ? ('PRODUCT.FOUNDING'|translate) : ('PRODUCT.BIRTHDAY'|translate)}}<span class="required">*</span></label>
              <input required autocomplete="off" [type]="isWebview ? 'text' : 'date'" placeholder="20-02-2022"
                [pattern]="isWebview ? pattern.DATE : pattern.ALL" id="nG_SINH" name="nG_SINH"
                [value]="form.nG_SINH" [(ngModel)]="form.nG_SINH" (ngModelChange)="onValidBirthday(form.nG_SINH)" />
                <span *ngIf="f.controls['nG_SINH']?.valid&&isWebview&&validBirth" class="input--check"><i class="fa-solid fa-circle-check"></i></span>
              <div class="invalid">
                <small
                  *ngIf="f.controls['nG_SINH']?.invalid && f.controls['nG_SINH']?.touched && form.nG_SINH.length < 1">
                  {{error.shortReq|translate}}
                </small>
                <small *ngIf="f.controls['nG_SINH']?.touched && form.nG_SINH.length > 1 && validBirth == false && isPerson">
                  {{error.max_date|translate}}
                </small>
                <small *ngIf="f.controls['nG_SINH']?.errors?.['pattern'] && isWebview">{{error.date|translate}}</small>
              </div>
            </div>
            <div class="input-file" (click)="openImageModal(viewImage)" *ngIf="!tempFile.front || !tempFile.back">
              <label class="input-label">
                <span class="material-icons">collections</span><br>
                <span class="input-span">{{ 'COMMON.UPLOAD_IMAGE' | translate }}<br>{{ 'COMMON.IDENTIFY_CARD' | translate }}</span>
              </label>
            </div>
            <div class="uploaded-file" (click)="openImageModal(viewImage)" [title]="'COMMON.IDENTIFY_IMAGE'|translate"
              *ngIf="form.files.front && form.files.back && tempFile.front && tempFile.back">
              <img [src]="form.files.front" [alt]="'COMMON.IDENTIFY_FRONT'|translate">
              <img [src]="form.files.back" [alt]="'COMMON.IDENTIFY_BACK'|translate">
            </div>
          </div>
          <div [ngClass]="isCorp ? 'form-child--full' : 'form-child'">
            <div [ngClass]="isCorp ? 'gender-hidden' : 'text-field'">
              <label for="gioi">{{ 'COMMON.GENDER' | translate }}<span class="required">*</span></label>
              <select required name="gioi" id="gioi" [(ngModel)]="form.gioi">
                <option *ngFor="let opt of genders" [value]="opt.value">{{opt.label|translate}}</option>
              </select>
              <span *ngIf="f.controls['gioi']?.valid" class="input--check"><i class="fa-solid fa-circle-check"></i></span>
              <div class="invalid">
                <small *ngIf="f.controls['gioi']?.invalid && f.controls['gioi']?.touched">{{error.shortSel|translate}}</small>
              </div>
            </div>
            <div [ngClass]="isCorp ? 'text-field full-child' : 'text-field'">
              <label for="email">Email<span class="required">*</span></label>
              <input required [email]="pattern.EMAIL" autocomplete="off" type="email" id="email" name="email"
                [value]="form.email" [(ngModel)]="form.email" />
                <span *ngIf="f.controls['email']?.valid" class="input--check"><i class="fa-solid fa-circle-check"></i></span>
              <div class="invalid">
                <small
                  *ngIf="f.controls['email']?.invalid && f.controls['email']?.touched && form.email.length < 1">{{error.required|translate}}</small>
                <small *ngIf="f.controls['email']?.errors?.['email']">{{error.email|translate}}</small>
              </div>
            </div>
          </div>
          <div class="text-field" style="width: 50%;">
            <label for="dchi">{{ 'COMMON.ADDRESS' | translate }}<span class="required">*</span></label>
            <input required autocomplete="off" type="text" id="dchi" name="dchi" [value]="form.dchi"
              [(ngModel)]="form.dchi" />
              <span *ngIf="f.controls['dchi']?.valid" class="input--check"><i class="fa-solid fa-circle-check"></i></span>
            <div class="invalid">
              <small *ngIf="f.controls['dchi']?.invalid && f.controls['dchi']?.touched">{{error.required|translate}}</small>
            </div>
          </div>
        </div>

        <ng-container [ngTemplateOutlet]="prodStep"></ng-container>
      </div>
    </div>

    <div id="last-step" [ngClass]="stepValue == 3 ? 'step' : 'hidden'" *ngIf="isWebview === false">
      <app-form-title [label]="'PRODUCT.FEE_INFO'|translate"></app-form-title>
      <hr>
      <div class="last-step--label">
        <span>{{ 'PAYMENT.FEE' | translate }}:</span>
        <span>{{fee.phi}} VND</span>
      </div>
      <div class="last-step--label">
        <span>{{ 'PAYMENT.TAX' | translate }}:</span>
        <span>{{fee.thue}} VND</span>
      </div>
      <hr>
      <div class="flex">
        <img class="form__payment--thumb" src="../../../assets/frontend/images/coupon.png" alt="voucher">
        <span class="form__payment--voucher">Voucher:</span>
      </div>
      <div class="flex space" *ngIf="!meetVoucher">
        <div class="text-field launcher">
          <label for="launcher">{{ 'COMMON.REFERRAL_CODE' | translate }}</label>
          <input autocomplete="off" type="text" id="launcher" name="launcher" placeholder="A0000001"
            [value]="form.magioithieu" [(ngModel)]="form.magioithieu" [disabled]="isShared"
            (ngModelChange)="getLauncher(form.magioithieu)" />
        </div>
        <div class="text-field maVoucher">
          <label for="maVoucher">{{ 'PAYMENT.VOUCHER_ID' | translate }}</label>
          <input autocomplete="off" type="text" id="maVoucher" name="maVoucher" [placeholder]="'PRODUCT.INSERT_VOUCHER'|translate"
            [value]="voucher.maVoucher" [(ngModel)]="voucher.maVoucher"
            (ngModelChange)="voucher.maVoucher.length === 21 ? getDiscount(voucher.maVoucher) : getOldFee()" />
          <div class="invalid">
            <small *ngIf="voucher.maVoucher.length === 21 && voucher.status === false">{{error.voucher|translate}}</small>
          </div>
        </div>
      </div>
      <div *ngIf="form.nguoiGioiThieu !== ''">
        <span>{{ 'PAYMENT.PRESENTER' | translate }}:</span>
        <span>{{form.nguoiGioiThieu}}</span>
      </div>
      <div class="last-step--label">
        <span>{{ 'PAYMENT.SALE' | translate }}:</span>
        <span>- {{save}} VND</span>
      </div>
      <div class="last-step--label" style="font-weight: 500;">
        <span>{{ 'PAYMENT.TOTAL' | translate }}:</span>
        <span>{{fee.tongphi}} VND</span>
      </div>
      <hr>
      <button class="form__payment" (click)="onSubmit(f)" [disabled]="submited"
        [ngClass]="submited ? 'opacity-50' : 'opacity-100'">{{ 'COMMON.PAY' | translate }}</button>
    </div>

    <div id="webview-step" [ngClass]="stepValue === 3 ? 'step' : 'hidden'" *ngIf="isWebview">
      <app-form-title [label]="'PRODUCT.FEE_INFO'|translate"></app-form-title>
      <hr>
      <span class="label">{{ 'PAYMENT.CLIENT_NAME' | translate }}</span>
      <span class="info">{{form.ten}}</span>
      <span class="label">{{ 'PAYMENT.CLIENT_EMAIL' | translate }}</span>
      <span class="info">{{form.email}}</span>
      <span class="label">{{ 'PRODUCT.CLIENT_PHONE_NUMBER' | translate }}</span>
      <span class="info">{{form.mobi}}</span>
      <hr>
      <span class="label">{{ 'PAYMENT.FEE' | translate }}</span>
      <span class="info">{{fee.phi}}</span>
      <span class="label">{{ 'PAYMENT.TAX' | translate }}</span>
      <span class="info">{{fee.thue}} VND</span>
      <span class="label">{{ 'PAYMENT.TOTAL' | translate }}</span>
      <span class="fee webview-cl">{{fee.tongphi}} VND</span>
      <br>

      <ng-container [ngTemplateOutlet]="prodStep"></ng-container>
      <span *ngIf="!isApp" class="notice-payment">{{ 'PAYMENT.NOTICE' | translate }}</span>
    </div>
  </form>
</div>

<ng-template #getBackConfirm let-modal>
  <div class="modal-header">
    <h4 class="modal-title">{{ 'COMMON.PAY' | translate }}</h4>
  </div>
  <div class="modal-body">
    <p>{{ 'COMMON.BEFORE_BACK' | translate }} ?</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="getBack()">{{ 'COMMON.YES' | translate }}</button>
    <button type="button" class="btn btn-light" (click)="modal.close('Close click')">{{ 'COMMON.NO' | translate }}</button>
  </div>
</ng-template>

<ng-template #viewImage let-modal>
  <div class="modal-header px-4">
    <h4 class="modal-title m-0 fs-4">{{ 'COMMON.UPLOAD_IMAGE' | translate }}</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <table class="w-100">
      <thead *ngIf="tempFile.front || tempFile.back">
        <tr>
          <th class="text-center">{{ 'COMMON.IDENTIFY_FRONT' | translate }}</th>
          <th class="text-center">{{ 'COMMON.IDENTIFY_BACK' | translate }}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td class="text-center w-50 p-2">
            <div class="input-file m-auto" *ngIf="!tempFile.front">
              <input type="file" name="frontIden" id="frontIden" accept="image/png, image/gif, image/jpeg"
                (change)="upload($event, 0)" />
              <label for="frontIden" class="input-label">
                <span class="material-icons">add_a_photo</span><br>
                <span class="input-span">{{ 'COMMON.IDENTIFY_FRONT' | translate }}<br>{{ 'COMMON.IDENTIFY_CARD' | translate }}</span>
              </label>
            </div>

            <div *ngIf="tempFile.front" class="position-relative">
              <img class="w-100 h-auto object-fit-contain" [src]="tempFile.front" [alt]="'COMMON.IDENTIFY_FRONT'|translate"
                [title]="'COMMON.IDENTIFY_FRONT'|translate">
              <button type="button" class="position-absolute btn--cancel" (click)="removeFile(0)" [title]="'COMMON.DELETE'|translate">
                <span class="material-icons">cancel</span>
              </button>
            </div>
          </td>

          <td class="text-center w-50 p-2">
            <div class="input-file m-auto" *ngIf="!tempFile.back">
              <input type="file" name="backIden" id="backIden" accept="image/png, image/gif, image/jpeg"
                (change)="upload($event, 1)" />
              <label for="backIden" class="input-label">
                <span class="material-icons">add_a_photo</span><br>
                <span class="input-span">{{ 'COMMON.IDENTIFY_BACK' | translate }}<br>{{ 'COMMON.IDENTIFY_CARD' | translate }}</span>
              </label>
            </div>

            <div *ngIf="tempFile.back" class="position-relative">
              <img class="w-100 h-auto object-fit-contain" [src]="tempFile.back" [alt]="'COMMON.IDENTIFY_BACK'|translate"
                [title]="'COMMON.IDENTIFY_BACK'|translate">
              <button type="button" class="position-absolute btn--cancel" (click)="removeFile(1)" [title]="'COMMON.DELETE'|translate">
                <span class="material-icons">cancel</span>
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="confirmFile(); modal.close('Close click')"
      *ngIf="tempFile.front && tempFile.back">{{ 'COMMON.PAY' | translate }}</button>
    <button type="button" class="btn btn-success" (click)="removeFile(0); removeFile(1)"
      *ngIf="tempFile.front || tempFile.back">{{ 'COMMON.UPLOAD_NEW' | translate }}</button>
    <button type="button" class="btn btn-light" (click)="modal.close('Close click')">{{ 'COMMON.EXIT' | translate }}</button>
  </div>
</ng-template>

<ng-template #prodStep>
  <div class="step-and-total" [ngClass]="isWebview ? 'webview' : ''">
    <button class="back" (click)="stepValue > 1 ? onController(-1, f) : openCentered(getBackConfirm) ">
      {{ 'COMMON.BACK' | translate }}
    </button>
    <button class="next" [disabled]="stepValue === 2 && !fee.tongphi"
      [ngClass]="(stepValue === 2 && !fee.tongphi) ? 'disableBtn' : ''"
      (click)="stepValue < 3 ? onController(1, f) : ''" *ngIf="stepValue < 3">
      {{ 'COMMON.NEXT' | translate }}
    </button>
    <button class="next" (click)="onSubmit(f)" *ngIf="stepValue === 3" [disabled]="submited"
      [ngClass]="submited ? 'opacity-50' : 'opacity-100'">{{ 'COMMON.PAY' | translate }}</button>
  </div>
</ng-template>
