import { ApiService } from '@services/api.service';
import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { ITFee } from '@models/fee.model';
import { ITMicCareBill, ITMicCareDKBS, ITMicCareFee, ITMicCareKH, ITMicCareTTSK } from '@models/miccare.model';
import { ITPayment } from '@models/payment.model';
import { ITVoucher } from '@models/voucher.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { API_PATH, GENDERS } from 'src/utils/constant';
import { MICCARE_STEPS } from 'src/utils/steps';
import { ERROR, VALID } from 'src/utils/validation';
import { eff, FEE_TEMPLATE, now, PAYMENT_TEMPLATE, VOUCHER_TEMPLATE, year } from 'src/utils/product';
import { DatePipe } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { MICCARE_CUSTOMER_TEMPLATE, MICCARE_DKBS_TEMPLATE, MICCARE_EXTENSION_RULES, MICCARE_MAIN_RULES, MICCARE_RESPONS, MICCARE_TTSK_TEMPLATE } from 'src/utils/product/miccare';
import { ProtectService } from '@services/protect/protect.service';
import * as moment from 'moment';
import { ToastService } from '@services/toast.service';
import { CommonService } from '@services/common/common.service';

@Component({
  selector: 'app-miccare',
  templateUrl: './miccare.component.html',
  styleUrls: ['./miccare.component.css']
})
export class MiccareComponent implements OnInit {
  @Input() isWebview:boolean = false
  @Input() isApp: boolean = false
  @Input() isShared: boolean = false
  @Output() setIsPayment = new EventEmitter()
  @Input() appVoucher: any = null
  @Input() userCode: any = null
  @Input() meetVoucher: any = null
  isPayment: boolean = true
  public isToggle:boolean = false
  public isBack = false
  public stepValue = 1
  public isOther = false
  public save = 0
  public forck = 'ckko'
  public forcc = 'ccko'
  public forcu = 'cuko'
  public fee:ITFee = {
    ...FEE_TEMPLATE
  }
  year = year
  now = now
  eff = eff
  birthYear:any = new Date().setFullYear(new Date().getFullYear() - 18)
  maxBirth:any = new Date(this.birthYear).toISOString().substring(0,10)
  public validBirth = true
  files: any
  public fromDate = this.now
  public toDate = this.eff
  public dkbs:ITMicCareDKBS = {
    ...MICCARE_DKBS_TEMPLATE
  }
  public kh:ITMicCareKH = {
    ...MICCARE_CUSTOMER_TEMPLATE
  }
  public is_baby:boolean = false
  isTrueLauncher: boolean = true;
  public khYear:number = 0
  public ttsk: ITMicCareTTSK = {
    ...MICCARE_TTSK_TEMPLATE
  }
  public bill:ITMicCareFee = {
    ma_dvi: "",
    nsd: "",
    pas: "",
    nv: "NG_SKC",
    id_tras: "",
    kieu_hd: "G",
    checksum: "",
    ngay_hl: "",
    ngay_kt: "",
    nhom: '1',
    so_hd_bm: '',
    ng_sinh: "",
    ng_huong: '',
    gcn_miccare_dkbs: this.dkbs
  }
  public form:ITMicCareBill = {
    ttoan: '',
    ngay_hl: this.now,
    ngay_kt: this.eff,
    gcn_miccare_ttin_hd: {
      ngay_hl: this.fromDate,
      nhom: this.bill.nhom,
      so_hd_bm: this.bill.so_hd_bm,
      ng_huong: this.bill.ng_huong,
      gcn_miccare_dkbs: this.dkbs,
    },
    gcn_miccare_ttin_kh: this.kh,
    gcn_miccare_ttsk: this.ttsk,
    dbhm: 'K',
    lkhm: 'C',
    tenm: '',
    cmtm: '',
    gioim: '',
    ng_sinhm: '',
    dchim: '',
    mobim: '',
    emailm: '',
    nguoiGioiThieu: '',
    tensanpham: 'Bảo hiểm MIC Care',
    goisanpham: '',
    thoihan: '',
    iddangky: '',
    magioithieu: '',
    moiquanhe: '',
    user_Code: '',
  }
  public voucher: ITVoucher = {
    ...VOUCHER_TEMPLATE
  }
  public payment: ITPayment = {
    ...PAYMENT_TEMPLATE
  }
  submited: boolean = false
  webviewNow: any;
  validDate: boolean = true;
  isNumberIn: boolean = false;
  isNumberInP: boolean = false;

  constructor(
    private ApiService:ApiService,
    private _protect: ProtectService,
    private modalService: NgbModal,
    private datePipe: DatePipe,
    private route: ActivatedRoute,
    private router: Router,
    public _toast: ToastService,
    public _common: CommonService,
  ) { }

  ngOnInit(): void {
    this.isWebview ? (this.getParams(), this.webviewNow = moment(this.now).format('DD-MM-YYYY'),
    this.fromDate = moment(this.fromDate).format('DD-MM-YYYY'),
    this.toDate = moment(this.toDate).format('DD-MM-YYYY')) : this.handleUserCode()
  }

  handleUserCode() {
    const user = this.route.snapshot.params['user']
    this.form.magioithieu = user
    this.getLauncher(user)
  }

  getParams() {
    this.router.url.includes('&') ? this.form.user_Code = this.payment.user_Code = this.router.url.slice(-9) : ''
  }

  onSetRadio() {
    this.forck === 'ckco' ? this.ttsk.ck = 'C' : this.ttsk.ck = 'K'
    this.forcc === 'ccco' ? this.ttsk.cc = 'C' : this.ttsk.cc = 'K'
    this.forcu === 'cuco' ? this.ttsk.cu = 'C' : this.ttsk.cu = 'K'
  }

  onChangeFromDate() {
    this.validDate = this._common.validDateString(this.fromDate, this.isWebview);
    this.isWebview ? this.toDate = moment(this._common.onChangeFromDate(this._common.changeDateString(this.fromDate))).format('DD-MM-YYYY') : this.toDate = this._common.onChangeFromDate(this.fromDate);
    this.getFee()
  }

  // If magioithieu.length < 1, set nguoiGioiThieu = ''
  getEmptyLauncher() {
    this.form.magioithieu = ''
    this.form.nguoiGioiThieu = ''
  }

  getOldFee() {
    this.save = 0

    this.fee.tongphi = this.fee.phi
  }

  onReturnDate() {
    this.khYear = new Date((this.isWebview?this._common.changeDateString(this.kh.ng_sinh):this.kh.ng_sinh)).getFullYear()
    this.bill.ngay_hl = this.datePipe.transform((this.isWebview?this._common.changeDateString(this.fromDate):this.fromDate), "dd/MM/yyyy") as string
    this.bill.ngay_kt = this.datePipe.transform((this.isWebview?this._common.changeDateString(this.toDate):this.toDate), "dd/MM/yyyy") as string
    this.bill.ng_sinh = this.datePipe.transform((this.isWebview?this._common.changeDateString(this.kh.ng_sinh):this.kh.ng_sinh), "dd/MM/yyyy") as string
  }

  isBaby(){
    this.is_baby ? this.is_baby = false : this.is_baby = true;
  }

  getFee() {
    if (this.kh.ng_sinh.length < 10) return
    this.onReturnDate()
    this.fee = {...FEE_TEMPLATE}
    console.log(this.bill)
    this._common.getFee( API_PATH.MICCARE_FEE, this.bill ).subscribe((data) => {
      this.fee = data
      this.payment.tongThanhToan = this.payment.tongphi = this.form.ttoan = data.strTong
    });
  }

  // BAOHIEM20220824015137
  getDiscount(param: string) {
    this._common.getDiscount(param, this.fee.tongphi).subscribe((data) => {
      if (data.status) {
        this.voucher = data,
        this.save = data.save
        this.form.ttoan = data.newTongPhi
      } else {
        this._toast.show('Hãy nhập đúng voucher !', { icon: 'error', classname: 'bg-danger text-light' });
      }
    })
  }

  getLauncher(param: string) {
    param= param?.trim()
    this.form.nguoiGioiThieu=''
    this.isTrueLauncher=false;
    if(param&&param.includes('A')&&param.length==9){
      this.ApiService.getLauncher(param).subscribe((data) => {
        this.form.nguoiGioiThieu = data?.ho_ten
        data?.ho_ten?(this.form.user_Code= this.payment.user_Code = param,this.isTrueLauncher=true):null
      })
    }
    else if(param&&!param.includes('A')&&param.length==10){
      this.ApiService.getLauncherByPhone(param).subscribe((data) => {
        this.form.nguoiGioiThieu = data[0]?.ho_ten
        data[0]?.ho_ten?(this.form.user_Code= this.payment.user_Code = data[0]?.ma_nv,this.isTrueLauncher=true):null
      })
    } 
  }


  openCentered(content: TemplateRef<any>) {
    this.modalService.open(content, { centered: true });
  }

  openImageModal(content: TemplateRef<any>) {
    this.modalService.open(content, { size: window.innerWidth > 500 ? 'xl' : 'lg' });
  }

  policyHandler() {
    this.form.goisanpham = this.respons.find(item => item.label === this.bill.nhom)?.label
  }

  getBack() {
    this.modalService.dismissAll();

    this.isPayment = false
    this.setIsPayment.emit(this.isPayment)
    // Hàm getBack sẽ ghi lại isPayment bằng false, đồng thời thông qua EventEmitter bắn value isPayment này ra component cha Product

    this.isBack = false
  }

  onSubmit(f: any) {
    this.onSetRadio()
    if (f.invalid || this.fee.phi == '') {
      this._toast.show('Hãy nhập đúng phiếu thông tin !', { icon: 'error', classname: 'bg-danger text-light' });

      f.controls['fromDate']!.touched = true
      f.controls['toDate']!.touched = true
      f.controls['ng_sinh']!.touched = true
      f.controls['cmt']!.touched = true
      f.controls['mobi']!.touched = true
      f.controls['ten']!.touched = true
      f.controls['email']!.touched = true
      f.controls['dchi']!.touched = true

      if (this.form.dbhm === 'C') {
        f.controls['cmtm']!.touched = true
        f.controls['mobim']!.touched = true
        f.controls['tenm']!.touched = true
        f.controls['emailm']!.touched = true
        f.controls['dchim']!.touched = true
        f.controls['ng_sinhm']!.touched = true
      }
    } else {
      this.submited = true
      let payload = {
        ...this.form,
        ...this.payment,
        ...this.bill,
        email: this.form.emailm || '',
        gioitinh: this.genders.find((i: any) => i.value === this.form.gioim)?.label,
        nG_SINH: this.isWebview ? this._common.changeDateString(this.form.ng_sinhm) : this.form.ng_sinhm,
        goibaohiem: this.respons.find((i: any) => i.value === this.bill.nhom)?.label_api,
        goisanpham: this.respons.find((i: any) => i.value === this.bill.nhom)?.label_api,
      }
      let formValue = Object.assign(payload)
      localStorage.setItem('form-data', JSON.stringify(formValue))

      const totalFee = this.fee.tongphi as string
      this.payment.tongThanhToan = this.payment.tongphi = parseFloat(totalFee.replace(/,/g, '')).toString()

      this.ApiService.postPayment(payload).subscribe(res => {
        if (this.isApp) {
          // Gửi message vào app
          window.postMessage(JSON.stringify({ type: 'getLocalStorage' }));
          const submitButton = document.getElementById('submit');
          submitButton&&submitButton.setAttribute('data-ttoan', this.payment.tongThanhToan || '0')
          window.location.href ='webview/notification/app'
        } else {
          res.paymentUrl && (window.location.href = res.paymentUrl)
        }
        // res.paymentUrl && (window.location.href = res.paymentUrl)
        this.submited = false
      })
    }
  }

  onController(e: number, f: any) {
    // If any input in any step has invalid, open modal notice "Please fill the form", then warning
    // If all input in any step hasn't invalid, fee next step
    switch (this.stepValue) {
      case 1:
        if (f.controls['fromDate']?.invalid | f.controls['toDate']?.invalid | f.controls['ng_sinh']?.invalid || this.fee.phi == '') {
          this._toast.show('Hãy nhập đúng phiếu thông tin !', { icon: 'error', classname: 'bg-danger text-light' });

          f.controls['fromDate']!.touched = true
          f.controls['toDate']!.touched = true
          f.controls['ng_sinh']!.touched = true
        } else this.stepValue += e
        break
      case 2:
        if (f.controls['cmt']?.invalid | f.controls['mobi']?.invalid | f.controls['ten']?.invalid | f.controls['email']?.invalid | f.controls['dchi']?.invalid) {
          if (this.form.dbhm == 'C' && f.controls['cmtm']?.invalid | f.controls['mobim']?.invalid | f.controls['tenm']?.invalid | f.controls['emailm']?.invalid | f.controls['dchim']?.invalid | f.controls['ng_sinhm']?.invalid) {
            f.controls['cmtm']!.touched = true
            f.controls['mobim']!.touched = true
            f.controls['tenm']!.touched = true
            f.controls['emailm']!.touched = true
            f.controls['dchim']!.touched = true
            f.controls['ng_sinhm']!.touched = true
          }
          this._toast.show('Hãy nhập đúng phiếu thông tin !', { icon: 'error', classname: 'bg-danger text-light' });

          f.controls['cmt']!.touched = true
          f.controls['mobi']!.touched = true
          f.controls['ten']!.touched = true
          f.controls['email']!.touched = true
          f.controls['dchi']!.touched = true
        } else this.stepValue += e
        break
      case 3:
        e === 1 ? this.isToggle = true : ''
        this.stepValue += e
        break
      case 4:
        this.isToggle = false
        this.stepValue += e

        if (this.isWebview && e === 1) {
          this.onSaveClient()
        }
        break
      case 5:
        e === -1 ? this.isToggle = true : ''
        this.stepValue += e
        break
      default:
        this._toast.show('Hãy nhập đúng phiếu thông tin !', { icon: 'error', classname: 'bg-danger text-light' });

    }
  }

  onSaveClient() {
    let payload = {
      ...this.payment,
      user_Code: this.form.user_Code || this.payment.user_Code || '',
      hoten: this.form.tenm.trim(),
      ngaysinh: this.isWebview ? this._common.changeDateString(this.form.ng_sinhm) : this.form.ng_sinhm,
      ngaytao: this.now,
      diachi: this.form.dchim.trim(),
      sodienthoai: this.form.mobim,
      cccd: this.form.cmtm,
      email: this.form.emailm,
      loaibaohiem: 'Bảo hiểm trợ cấp viện phí',
      goibaohiem: this.respons.find(i => i.value === +this.bill.nhom)?.label_api,
      thoihan: '12',
      thoigianthanhtoan: this.now,
      webview: this.isWebview.toString(),
      gioitinh: this.genders.find((i: any) => i.value === this.kh.gioi)?.label
    }

    this.ApiService.postPayment(payload).subscribe(res => {
      this.payment.idDangKy = this.form.iddangky = res.idDangKy
    })
  }

  onCapitalize(type: number): void {
    type
      ? this.kh.ten = this._common.onCapitalize(this.kh.ten)
      : this.form.tenm = this._common.onCapitalize(this.form.tenm)
  }

  onNumberIn(type:number) {
    type
    ? this.isNumberIn = this._common.onNumberIn(this.kh.ten)
    : this.isNumberInP = this._common.onNumberIn(this.form.tenm)
  }

  pattern = VALID
  error = ERROR
  genders = GENDERS
  steps = MICCARE_STEPS
  respons = MICCARE_RESPONS
  main = MICCARE_MAIN_RULES
  extension = MICCARE_EXTENSION_RULES
}
