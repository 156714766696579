<div [ngClass]="[isHome ? 'header blue_bg' : 'header']" *ngIf="!isAdmin&&!isCol">
  <div class="container">
    <div class="header__toolbar--toggle">
      <button class="header__toolbar-icon" (click)="openSidebar(siderbar)">
        <span class="material-icons" id="toolbar__button">{{ icon }}</span>
      </button>
    </div>

    <div class="login">
      <img routerLink="/" class="header__img--logo" src="../../../assets/Upload/files/finme2.png" alt="finme" />
      <div class="flex space">
        <div class="mega-menu">
          <app-mega-menu [categories]="categories" [products]="products"></app-mega-menu>
        </div>

        <div>
          <!-- <select #langSelect (change)="translate.use(langSelect.value)">
            <option *ngFor="let lang of translate.getLangs()" [value]="lang"
              [selected]="lang === translate.currentLang">{{ lang }}</option>
          </select> -->
          <img class="m-2" [src]="activedLang.path" [alt]="activedLang.name" width="28"
          [ngbPopover]="langContent" [popoverTitle]="langTitle">

          <ng-template #langContent>
            <div class="flex switchLang" *ngFor="let lang of languages" (click)="switchLang(lang.value)">
              <img [src]="lang.path" [alt]="lang.name" width="28">
              <span>{{ lang.name }}</span>
            </div>
          </ng-template>
          <ng-template #langTitle>{{ 'HEADER.SELECT' | translate }}</ng-template>
        </div>

        <button type="button" id="header__button--login" class="btn btn-outline-primary" routerLink="/login"
          *ngIf="!isLoggedIn">
          {{ 'HEADER.LOGIN' | translate }}
        </button>

        <div id="header__button--profile" *ngIf="isLoggedIn">
          <button type="button" class="btn btn-primary btn-floating" [ngbPopover]="popContent"
            [popoverTitle]="popTitle">
            <span class="material-icons">account_circle</span>
          </button>
        </div>
      </div>
    </div>
    <!-- <div class="language" >
      <i [class]="languageIcon"></i>
      <div class="language--menu" (click)="onChangeLanguage()">
        <span>{{language}}</span>
      </div>
    </div> -->
  </div>
</div>

  <ng-template #popTitle>
    Xin chào, <b>{{ userInfo.ho_ten }}</b>!
  </ng-template>
  
  <ng-template #popContent>
    <div class="d-block w-100" *ngIf="!isCol">
      <div class="menu-block__collapse--child">
        <span class="material-icons icon">smartphone</span>
        <span class="mx-2">{{ userInfo.username }}</span>
      </div>
  
      <div class="menu-block__collapse--child">
        <span class="material-icons icon">payment</span>
        <span class="mx-2">{{ userInfo.ma_nv }}</span>
      </div>
  
      <!-- <div class="menu-block__collapse--child">
        <span class="material-icons icon">supervised_user_circle</span>
        <span class="mx-2">{{ userInfo.role }}</span>
      </div> -->
  
      <div class="menu-block__collapse--child space" (click)="openAdmin()" *ngIf="userInfo.role !== 'CTV'">
        <span class="">Quản trị hệ thống</span>
        <span class="material-icons icon">manage_accounts</span>
      </div>
  
      <div class="menu-block__collapse--child space">
        <span class="">Cập nhật thông tin</span>
        <span class="material-icons icon">account_box</span>
      </div>
  
      <div class="menu-block__collapse--child space" (click)="onLogOut()">
        <span class="">Đăng xuất</span>
        <span class="icon material-icons">logout</span>
      </div>
    </div>
  </ng-template>
  
  <ng-template #noticeTitle>
    <div *ngIf="!isCol">
      Thông báo
    </div>
  </ng-template>
  
  <ng-template #noticeContent>
    <div *ngIf="!isCol">
      <div class="d-block w-100" *ngFor="let notice of notifications">
        <div class="p-2 border-bottom border-secondary notice--item" [ngClass]="notice.read ? 'bg-white' : 'bg-light'">
          Bạn có hợp đồng mới <strong>{{ notice.name }}</strong> mã <strong>{{ notice.id }}</strong>
        </div>
      </div>
    
      <div class="d-block w-100 d-flex justify-content-end">
        <span class="px-2 py-1 text-primary" routerLink="/admin/cart-manager">Xem tất cả</span>
      </div>
    </div>
  </ng-template>
  
  <ng-template #siderbar let-offcanvas>
    <div class="offcanvas-body">
      <!-- <app-sidebar></app-sidebar> -->
  
      <div class="login-block">
        <p class="login-block__head">Đăng nhập</p>
        <p class="tiny-nav">
          <span class="material-icons expand_more">expand_more</span>
          Dịch vụ cá nhân
        </p>
        <p class="tiny-nav">
          <span class="material-icons expand_more">expand_more</span>
          Quản lý chính sách
        </p>
        <button routerLink="/login" type="button" id="login-block__button" class="btn btn-light"
          (click)="offcanvas.dismiss('Cross click')">
          Đăng nhập / Đăng ký
        </button>
      </div>
  
      <div class="menu-block">
        <div class="menu-block__collapse" (click)="categoryCollapse.toggle()" [attr.aria-expanded]="!isCategoryCollapsed"
          aria-controls="collapseExample">
          <span>Sản phẩm bảo hiểm</span>
          <span class="material-icons" [ngClass]="isCategoryCollapsed ? 'back' : 'transform'">chevron_right</span>
        </div>
        <ngx-skeleton-loader *ngIf="categories.length === 0" count="6" [theme]="{
            margin: '8px 16px 0px 16px',
            width: '92%',
            height: '60px',
            'border-radius': '0.5rem'
          }"></ngx-skeleton-loader>
        <div #categoryCollapse="ngbCollapse" [(ngbCollapse)]="isCategoryCollapsed">
          <ul class="navbar" *ngFor="let cate of categories">
            <li>
              <div class="navbar__category" (click)="productCollapse.toggle()" [attr.aria-expanded]="!cate.status"
                aria-controls="collapseExample">
                <div class="navbar__category--head">
                  <img src="../../../assets{{ cate.icon }}" [alt]="cate.name" />
                  <span>{{ cate.name }}</span>
                </div>
                <span class="material-icons" [ngClass]="cate.status ? 'back' : 'transform'">chevron_right</span>
              </div>
              <div #productCollapse="ngbCollapse" [(ngbCollapse)]="cate.status">
                <ul *ngFor="let product of products">
                  <li routerLink="/san-pham/{{ product.metaTitle }}" (click)="offcanvas.dismiss('Cross click')">
                    <span *ngIf="cate.id === product.categoryID" class="navbar__product display">{{ product.name }}</span>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
  
        <div class="menu-block__collapse" (click)="compareCollapse.toggle()" [attr.aria-expanded]="!isCompareCollapsed"
          aria-controls="collapseExample">
          <span>So sánh</span>
          <span class="material-icons" [ngClass]="isCompareCollapsed ? 'back' : 'transform'">chevron_right</span>
        </div>
        <div #compareCollapse="ngbCollapse" [(ngbCollapse)]="isCompareCollapsed">
          <div routerLink="/compare" class="menu-block__collapse--child" (click)="offcanvas.dismiss('Cross click')">
            <span class="material-icons icon">compare</span>
            <span class="menu__collapse--label">So sánh bảo hiểm</span>
          </div>
        </div>
  
        <div class="menu-block__collapse" (click)="newsCollapse.toggle()" [attr.aria-expanded]="!isNewsCollapsed"
          aria-controls="collapseExample">
          <span>Tin tức</span>
          <span class="material-icons" [ngClass]="isNewsCollapsed ? 'back' : 'transform'">chevron_right</span>
        </div>
        <div #newsCollapse="ngbCollapse" [(ngbCollapse)]="isNewsCollapsed">
          <div routerLink="/news" class="menu-block__collapse--child" (click)="offcanvas.dismiss('Cross click')">
            <span class="material-icons icon">newspaper</span>
            <span class="menu__collapse--label">Tin tức bảo hiểm</span>
          </div>
        </div>
  
        <div class="menu-block__collapse" (click)="policyCollapse.toggle()" [attr.aria-expanded]="!isPolicyCollapsed"
          aria-controls="collapseExample">
          <span>Chính sách công ty</span>
          <span class="material-icons" [ngClass]="isPolicyCollapsed ? 'back' : 'transform'">chevron_right</span>
        </div>
        <div #policyCollapse="ngbCollapse" [(ngbCollapse)]="isPolicyCollapsed">
          <div routerLink="/introduce" class="menu-block__collapse--child" (click)="offcanvas.dismiss('Cross click')">
            <span class="material-icons icon">info</span>
            <span class="menu__collapse--label">Giới thiệu</span>
          </div>
        </div>
  
        <div class="menu-block__collapse" (click)="contactCollapse.toggle()" [attr.aria-expanded]="!isContactCollapsed"
          aria-controls="collapseExample">
          <span>Liên hệ</span>
          <span class="material-icons" [ngClass]="isContactCollapsed ? 'back' : 'transform'">chevron_right</span>
        </div>
        <div #contactCollapse="ngbCollapse" [(ngbCollapse)]="isContactCollapsed">
          <div class="menu-block__collapse--child" (click)="offcanvas.dismiss('Cross click')">
            <span class="material-icons icon">phone_in_talk</span>
            <span class="menu__collapse--label">Gọi lại</span>
          </div>
          <div routerLink="/contact" class="menu-block__collapse--child" (click)="offcanvas.dismiss('Cross click')">
            <span class="icon material-icons">widgets</span>
            <span class="menu__collapse--label">Xem thêm</span>
          </div>
        </div>
  
        <div class="menu-block__collapse" (click)="contactCollapse.toggle()" [attr.aria-expanded]="!isContactCollapsed"
          aria-controls="collapseExample" *ngIf="isLoggedIn">
          <span>Tài khoản</span>
          <span class="material-icons" [ngClass]="isContactCollapsed ? 'back' : 'transform'">chevron_right</span>
        </div>
        <div #contactCollapse="ngbCollapse" [(ngbCollapse)]="isContactCollapsed">
          <div class="menu-block__collapse--child" (click)="offcanvas.dismiss('Cross click')">
            <span class="material-icons icon">account_box</span>
            <span class="menu__collapse--label">Cập nhật thông tin</span>
          </div>
          <div class="menu-block__collapse--child" (click)="offcanvas.dismiss('Cross click'); onLogOut()">
            <span class="icon material-icons">logout</span>
            <span class="menu__collapse--label">Đăng xuất</span>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
  
  <ng-container *ngIf="isAdmin && isLoggedIn &&!isCol">
  
    <div class="nav-header">
      <div class="brand-logo">
        <a [href]="baseUrl" target="_blank">
          <img src="../../../assets/frontend/images/finadmin.png" alt="Finme" />
        </a>
      </div>
    </div>
  
    <div class="adminheader">
      <div class="header-content d-flex justify-content-end" style="width: calc(100vw - 245px);">
        <!-- <div class="nav-control">
          <div class="hamburger">
            <span class="material-icons toggle-icon">menu</span>
          </div>
        </div> -->
  
        <div class="user-img d-flex flex-row c-pointer position-relative" data-toggle="dropdown">
          <div [ngbPopover]="noticeContent" [popoverTitle]="noticeTitle">
            <span class="material-icons">notifications</span>
          </div>
  
          <div id="header__button--profile" [ngbPopover]="popContent" [popoverTitle]="popTitle">
            <span class="material-icons">account_circle</span>
          </div>
        </div>
      </div>
    </div>
  
    <div class="nk-sidebar">
      <div class="nk-nav-scroll">
        <ul class="metismenu" id="menu">
          <li class="nav-label" routerLink="admin/dashboard">Quản lý</li>
          <div style="margin: 0 auto;">
            <!-- <select #langSelect (change)="translate.use(langSelect.value)">
              <option *ngFor="let lang of translate.getLangs()" [value]="lang"
                [selected]="lang === translate.currentLang">{{ lang }}</option>
            </select> -->
            <img class="m-2" [src]="activedLang.path" [alt]="activedLang.name" width="28"
            [ngbPopover]="langContent" [popoverTitle]="langTitle">
  
            <ng-template #langContent>
              <div class="flex switchLang" *ngFor="let lang of languages" (click)="switchLang(lang.value)">
                <img [src]="lang.path" [alt]="lang.name" width="28">
                <span>{{ lang.name }}</span>
              </div>
            </ng-template>
            <ng-template #langTitle>{{ 'HEADER.SELECT' | translate }}</ng-template>
          </div>
          <li>
            <ul>
              <li routerLink="admin/dashboard">
                <span class="material-icons">space_dashboard</span>
                <span>Báo cáo tổng quan</span>
              </li>
              <li routerLink="admin/cart-manager">
                <span class="material-icons">inventory</span>
                <span>Quản lý hợp đồng</span>
              </li>
              <li routerLink="admin/acc-manager">
                <span class="material-icons">inventory</span>
                <span>Quản lý tích lũy</span>
              </li>
              <li routerLink="admin/material-manager">
                <span class="material-icons">inventory</span>
                <span>Đăng kí vật chất xe</span>
              </li>
            </ul>
          </li>
          <li class="nav-label">Quản lý sản phẩm</li>
          <li>
            <ul>
              <li routerLink="admin/categories-manager">
                <span class="material-icons">loyalty</span>
                <span>Quản lý danh mục</span>
              </li>
              <li routerLink="admin/products-manager">
                <span class="material-icons">health_and_safety</span>
                <span>Quản lý sản phẩm</span>
              </li>
            </ul>
          </li>
          <li class="nav-label">khác</li>
          <li>
            <ul>
              <li routerLink="admin/content-manager">
                <span class="material-icons">feed</span>
                <span>Quản lý bài viết</span>
              </li>
              <li routerLink="admin/partners-manager">
                <span class="material-icons">handshake</span>
                <span>Quản lý cộng sự</span>
              </li>
              <li routerLink="admin/apartments-manager">
                <span class="material-icons">apartment</span>
                <span>Quản lý nhà đầu tư</span>
              </li>
              <li routerLink="admin/brands-manager">
                <span class="material-icons">branding_watermark</span>
                <span>Quản lý thương hiệu</span>
              </li>
              <li routerLink="admin/vouchers-manager">
                <span class="material-icons">confirmation_number</span>
                <span>Quản lý Voucher</span>
              </li>
              <li routerLink="admin/slider-manager">
                <span class="material-icons">slideshow</span>
                <span>Quản lý Slide</span>
              </li>
              <li routerLink="admin/feedback-manager">
                <span class="material-icons">chat</span>
                <span>Bình luận khách hàng</span>
              </li>
            </ul>
          </li>
          <li class="nav-label">cấu hình</li>
          <li>
            <ul>
              <li routerLink="admin/users-manager">
                <span class="material-icons">manage_accounts</span>
                <span>Quản lý user</span>
              </li>
              <li routerLink="admin/settings">
                <span class="material-icons">settings</span>
                <span>Cấu hình Website</span>
              </li>
              <li (click)="onLogOut()">
                <span class="material-icons">logout</span>
                <span>Thoát</span>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  
  </ng-container>

  <ng-container *ngIf="!isAdmin && isLoggedIn &&isCol">
  
    <div class="nav-header">
      <div class="brand-logo">
        <a [href]="baseUrl" target="_blank">
          <img src="../../../assets/frontend/images/finadmin.png" alt="Finme" />
        </a>
      </div>
    </div>
  
    <div class="adminheader">
      <div class="header-content d-flex justify-content-end" style="width: calc(100vw - 245px);">
        <!-- <div class="nav-control">
          <div class="hamburger">
            <span class="material-icons toggle-icon">menu</span>
          </div>
        </div> -->
  
        <div class="user-img d-flex flex-row c-pointer position-relative" data-toggle="dropdown">
  
          <div id="header__button--profile" [ngbPopover]="popContent" [popoverTitle]="popTitle">
            <span class="material-icons">account_circle</span>
          </div>
        </div>
      </div>
    </div>
  
    <div class="nk-sidebar">
      <div class="nk-nav-scroll">
        <ul class="metismenu" id="menu">
          <li class="nav-label" >Quản lý</li>
          
          <li>
            <ul>
              <!-- <li routerLink="admin/dashboard">
                <span class="material-icons">space_dashboard</span>
                <span>Báo cáo tổng quan</span>
              </li>
              <li routerLink="admin/cart-manager">
                <span class="material-icons">inventory</span>
                <span>Quản lý hợp đồng</span>
              </li> -->
              <li routerLink="collaborators/managerF1" routerLinkActive="activeR">
                <span class="material-icons">inventory</span>
                <span>Quản lý Công tác viên</span>
              </li>
              <li routerLink="collaborators/contracts" routerLinkActive="activeR">
                <span class="material-icons">inventory</span>
                <span>Quản lý Hợp đồng</span>
              </li>
              <li (click)="onLogOut()">
                <span class="material-icons">logout</span>
                <span>Đăng xuất</span>
              </li>
            </ul>
          </li>
          <!-- <li class="nav-label">Quản lý sản phẩm</li>
          <li>
            <ul>
              <li routerLink="admin/categories-manager">
                <span class="material-icons">loyalty</span>
                <span>Quản lý danh mục</span>
              </li>
              <li routerLink="admin/products-manager">
                <span class="material-icons">health_and_safety</span>
                <span>Quản lý sản phẩm</span>
              </li>
            </ul>
          </li>
          <li class="nav-label">khác</li>
          <li>
            <ul>
              <li routerLink="admin/content-manager">
                <span class="material-icons">feed</span>
                <span>Quản lý bài viết</span>
              </li>
              <li routerLink="admin/partners-manager">
                <span class="material-icons">handshake</span>
                <span>Quản lý cộng sự</span>
              </li>
              <li routerLink="admin/apartments-manager">
                <span class="material-icons">apartment</span>
                <span>Quản lý nhà đầu tư</span>
              </li>
              <li routerLink="admin/brands-manager">
                <span class="material-icons">branding_watermark</span>
                <span>Quản lý thương hiệu</span>
              </li>
              <li routerLink="admin/vouchers-manager">
                <span class="material-icons">confirmation_number</span>
                <span>Quản lý Voucher</span>
              </li>
              <li routerLink="admin/slider-manager">
                <span class="material-icons">slideshow</span>
                <span>Quản lý Slide</span>
              </li>
              <li routerLink="admin/feedback-manager">
                <span class="material-icons">chat</span>
                <span>Bình luận khách hàng</span>
              </li>
            </ul>
          </li>
          <li class="nav-label">cấu hình</li>
          <li>
            <ul>
              <li routerLink="admin/users-manager">
                <span class="material-icons">manage_accounts</span>
                <span>Quản lý user</span>
              </li>
              <li routerLink="admin/settings">
                <span class="material-icons">settings</span>
                <span>Cấu hình Website</span>
              </li>
              <li (click)="onLogOut()">
                <span class="material-icons">logout</span>
                <span>Thoát</span>
              </li>
            </ul>
          </li> -->
        </ul>
      </div>
    </div>
  
  </ng-container>
