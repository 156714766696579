<ng-container *ngIf="blog">
  <div class="blog">
    <div class="container">
      <div class="blog__main">
        <app-content-title [upper]="blog.name" [h1]="true" class="app-block__head"></app-content-title>
        <div class="blog__breadcrumb">
          <p><span routerLink="/">{{'COMMON.HOME'|translate}}</span> > <span routerLink="/news">{{'HEADER.NEWS'|translate}}</span> >
            <span>{{blog.name | uppercase}}</span></p>
        </div>

        <div class="blog__detail">
          <img [src]="blog.image" [alt]="blog.name" class="blog__image">
          <div class="blog__detail--content" [innerHTML]="blog.detail"></div>
        </div>

        <div class="news-carousel desktop-hidden">
          <h2>{{'NEWS_RECENTLY'|translate}}</h2>
          <!-- <ngb-carousel *ngIf="ableNews" [showNavigationIndicators]="false" [showNavigationArrows]="false">
            <ng-template ngbSlide *ngFor="let slide of ableNews">
              <div class="news-carousel-card">
                <img class="news__img" [src]="slide.image" />
                <p class="news__title">{{slide.name}}</p>
              </div>
            </ng-template>
          </ngb-carousel> -->
          <div class="container">
            <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="latestblogsConfig"
              (init)="slickInit($event)" (breakpoint)="breakpoint($event)" (afterChange)="afterChange($event)"
              (beforeChange)="beforeChange($event)">
              <div ngxSlickItem *ngFor="let slide of ableNews">
                <div class="news-carousel-card" routerLink="/news/{{slide.metaTitle}}">
                  <div class="news__upper">
                    <img class="news__img" [src]="slide.image" />
                  </div>
                  <p class="news__title">{{slide.name}}</p>
                </div>
              </div>
            </ngx-slick-carousel>
          </div>
        </div>
      </div>

      <div class="lastest-blog mobile-hidden">
        <h2>{{'NEWS_RECENTLY'|translate}}</h2>
        <div class="lastest-blog__item" *ngFor="let item of ableNews">
          <div routerLink="/news/{{item.metaTitle}}"><img [src]="item.image"></div>
          <div><span routerLink="/news/{{item.metaTitle}}">{{item.name}}</span></div>
        </div>
      </div>
    </div>
  </div>
</ng-container>