<div class="health-payment">
  <div class="step__controller">
    <app-step-controller [steps]="steps" [step]="33" [stepValue]="stepValue" [isWebview]="false"></app-step-controller>
  </div>

  <form #f="ngForm">
    <div class="form--left">
      <div id="step_1" [ngClass]="stepValue == 1 ? 'step' : 'hidden'">
        <app-form-title [icon]="'loyalty'" [label]="'PRODUCT.PROD_INFO'|translate"></app-form-title>
        <div class="form-group">
          <div class="text-field">
            <label for="ten">{{ 'COMMON.FULL_NAME' | translate }}<span class="required">*</span></label>
            <input required autocomplete="off" type="text" id="ten" name="ten"
              [value]="form.ten" [(ngModel)]="form.ten" (blur)="onCapitalize(1);onNumberIn(1)" />
              <span *ngIf="f.controls['ten']?.valid" class="input--check"><i class="fa-solid fa-circle-check"></i></span>
            <div [class]="isNumberIn?'alertMessage':'invalid'">
              <small
                *ngIf="f.controls['ten']?.invalid && f.controls['ten']?.touched && form.ten.length < 1">{{error.required|translate}}</small>
                <small *ngIf="isNumberIn">{{error.number_in|translate}}</small>
            </div>
          </div>
          <div class="text-field">
            <label for="cmt">{{ 'COMMON.IDENTIFY_CARD' | translate }}<span class="required">*</span></label>
            <input required min="9" max="12" [pattern]="pattern.CCCD" autocomplete="off" type="text" id="cmt" name="cmt"
              [value]="form.cmt" [(ngModel)]="form.cmt" />
              <span *ngIf="f.controls['cmt']?.valid" class="input--check"><i class="fa-solid fa-circle-check"></i></span>
            <div class="invalid">
              <small
                *ngIf="f.controls['cmt']?.invalid && f.controls['cmt']?.touched && form.cmt.length < 1">{{error.required|translate}}</small>
              <small *ngIf="f.controls['cmt']?.errors?.['pattern']">{{error.identify|translate}}</small>
            </div>
          </div>
          <div class="text-field">
            <label for="mobi">{{ 'COMMON.PHONE_NUMBER' | translate }}<span class="required">*</span></label>
            <input required maxlength="10" [pattern]="pattern.PHONE" autocomplete="off" type="tel" id="mobi"
              name="mobi" [value]="form.mobi" [(ngModel)]="form.mobi" />
              <span *ngIf="f.controls['mobi']?.valid" class="input--check"><i class="fa-solid fa-circle-check"></i></span>
            <div class="invalid">
              <small
                *ngIf="f.controls['mobi']?.invalid && f.controls['mobi']?.touched && form.mobi.length < 1">{{error.required|translate}}</small>
              <small *ngIf="f.controls['mobi']?.errors?.['pattern']">{{error.phone|translate}}</small>
            </div>
          </div>
          <div class="text-field">
            <label for="email">Email<span class="required">*</span></label>
            <input required [email]="pattern.EMAIL" autocomplete="off" type="email" id="email" name="email"
              [value]="form.email" [(ngModel)]="form.email" />
              <span *ngIf="f.controls['email']?.valid" class="input--check"><i class="fa-solid fa-circle-check"></i></span>
            <div class="invalid">
              <small
                *ngIf="f.controls['email']?.invalid && f.controls['email']?.touched && form.email.length < 1">{{error.required|translate}}</small>
              <small *ngIf="f.controls['email']?.errors?.['email']">{{error.email|translate}}</small>
            </div>
          </div>
          <div class="text-field">
            <label for="nG_SINH">{{ 'COMMON.BIRTHDAY' | translate }}<span class="required">*</span></label>
            <input required autocomplete="off" [type]="isWebview ? 'text' : 'date'" [max]="now" id="nG_SINH" name="nG_SINH"
              [value]="form.nG_SINH" [(ngModel)]="form.nG_SINH" placeholder="20-02-2002"  [pattern]="isWebview ? pattern.DATE : pattern.ALL"
              (ngModelChange)="checkBirthday(form.nG_SINH)" />
              <span *ngIf="f.controls['nG_SINH']?.valid&&isWebview&&validBirthday" class="input--check"><i class="fa-solid fa-circle-check"></i></span>
            <div class="invalid">
              <small *ngIf="f.controls['nG_SINH']?.invalid && f.controls['nG_SINH']?.touched && form.nG_SINH.length < 1">
                {{error.shortReq|translate}}
              </small>
              <small *ngIf="f.controls['nG_SINH']?.errors?.['pattern'] && isWebview">{{error.date|translate}}</small>
              <small *ngIf="f.controls['nG_SINH']?.valid &&!validBirthday">{{error.max_date|translate}}</small>
            </div>
          </div>
          <div class="text-field">
            <label for="gioi">{{ 'COMMON.GENDER' | translate }}<span class="required">*</span></label>
            <select required name="gioi" id="gioi" [(ngModel)]="form.gioi">
              <option value="" selected disabled>{{ 'PRODUCT.SELECT_GENDER' | translate }}...</option>
              <option *ngFor="let opt of genders" [value]="opt.vie">{{opt.label|translate}}</option>
            </select>
            <span *ngIf="f.controls['gioi']?.valid" class="input--check"><i class="fa-solid fa-circle-check"></i></span>
            <div class="invalid">
              <small
                *ngIf="f.controls['gioi']?.invalid && f.controls['gioi']?.touched">{{error.shortSel|translate}}</small>
            </div>
          </div>
          <div class="text-field">
            <label for="dchi">{{ 'COMMON.ADDRESS' | translate }}<span class="required">*</span></label>
            <input required autocomplete="off" type="text" id="dchi" name="dchi" [value]="form.dchi"
              [(ngModel)]="form.dchi" />
              <span *ngIf="f.controls['dchi']?.valid" class="input--check"><i class="fa-solid fa-circle-check"></i></span>
            <div class="invalid">
              <small *ngIf="f.controls['dchi']?.invalid && f.controls['dchi']?.touched">{{error.required|translate}}</small>
            </div>
          </div>
        </div>

        <ng-container [ngTemplateOutlet]="prodStep"></ng-container>
      </div>

      <div id="step_2" [ngClass]="stepValue == 2 ? 'step' : 'hidden'">
        <app-form-title [icon]="'badge'" [label]="'PRODUCT.PROTECTOR'|translate"></app-form-title>
        <div class="form-group">
          <div class="text-field">
            <label for="moi_qh">{{ 'PRODUCT.RELATIONSHIP' | translate }}<span class="required">*</span></label>
            <select required name="moi_qh" id="moi_qh" [(ngModel)]="gcns.moi_qh" (ngModelChange)="onForSelf()">
              <option *ngFor="let opt of mqhnguoimua" [value]="opt.code">{{opt.label|translate}}</option>
            </select>
            <span *ngIf="f.controls['moi_qh']?.valid" class="input--check"><i class="fa-solid fa-circle-check"></i></span>
            <div class="invalid">
              <small *ngIf="f.controls['moi_qh']?.invalid && f.controls['moi_qh']?.touched">{{error.select|translate}}</small>
            </div>
          </div>
          <div class="text-field">
            <label for="gcns_ten">{{ 'COMMON.FULL_NAME' | translate }} {{ 'PRODUCT.BENEFICIARY' | translate }}<span class="required">*</span></label>
            <input required autocomplete="off" type="text" id="gcns_ten" name="gcns_ten"
              [value]="gcns.ten" [(ngModel)]="gcns.ten" (blur)="onCapitalize(0); onNumberIn(0)" />
              <span *ngIf="f.controls['gcns_ten']?.valid" class="input--check"><i class="fa-solid fa-circle-check"></i></span>
            <div [class]="isNumberInP?'alertMessage':'invalid'">
              <small
                *ngIf="f.controls['gcns_ten']?.invalid && f.controls['gcns_ten']?.touched && gcns.ten.length < 1">{{error.required|translate}}</small>
              <small *ngIf="f.controls['gcns_ten']?.errors?.['pattern']">{{error.name|translate}}</small>
              <small *ngIf="isNumberInP">{{error.number_in|translate}}</small>
            </div>
          </div>
          <div class="text-field">
            <label for="gcns_cmt">{{ 'COMMON.IDENTIFY_CARD' | translate }} {{ 'PRODUCT.BENEFICIARY' | translate }}<span class="required">*</span></label>
            <input required min="9" max="12" [pattern]="pattern.CCCD" autocomplete="off" type="text" id="gcns_cmt"
              name="gcns_cmt" [value]="gcns.cmt" [(ngModel)]="gcns.cmt" />
              <span *ngIf="f.controls['gcns_cmt']?.valid" class="input--check"><i class="fa-solid fa-circle-check"></i></span>
            <div class="invalid">
              <small
                *ngIf="f.controls['gcns_cmt']?.invalid && f.controls['gcns_cmt']?.touched && gcns.cmt.length < 1">{{error.required|translate}}</small>
              <small *ngIf="f.controls['gcns_cmt']?.errors?.['pattern']">{{error.identify|translate}}</small>
            </div>
          </div>
          <div class="text-field">
            <label for="gcns_d_thoai">{{ 'COMMON.PHONE_NUMBER' | translate }} {{ 'PRODUCT.BENEFICIARY' | translate }}<span class="required">*</span></label>
            <input required maxlength="10" [pattern]="pattern.PHONE" autocomplete="off" type="tel" id="gcns_d_thoai"
              name="gcns_d_thoai" [value]="gcns.d_thoai" [(ngModel)]="gcns.d_thoai" />
              <span *ngIf="f.controls['gcns_d_thoai']?.valid" class="input--check"><i class="fa-solid fa-circle-check"></i></span>
            <div class="invalid">
              <small
                *ngIf="f.controls['gcns_d_thoai']?.invalid && f.controls['gcns_d_thoai']?.touched && gcns.d_thoai.length < 1">{{error.required|translate}}</small>
              <small *ngIf="f.controls['gcns_d_thoai']?.errors?.['pattern']">{{error.phone|translate}}</small>
            </div>
          </div>
          <div class="text-field">
            <label for="gcns_email">Email {{ 'PRODUCT.BENEFICIARY' | translate }}<span class="required">*</span></label>
            <input required [email]="pattern.EMAIL" autocomplete="off" type="email" id="gcns_email" name="gcns_email"
              [value]="gcns.email" [(ngModel)]="gcns.email" />
              <span *ngIf="f.controls['gcns_email']?.valid" class="input--check"><i class="fa-solid fa-circle-check"></i></span>
            <div class="invalid">
              <small
                *ngIf="f.controls['gcns_email']?.invalid && f.controls['gcns_email']?.touched && gcns.email.length < 1">{{error.required|translate}}</small>
              <small *ngIf="f.controls['gcns_email']?.errors?.['email']">{{error.email|translate}}</small>
            </div>
          </div>
          <div class="text-field">
            <label for="gcns_ngay_sinh">{{ 'COMMON.BIRTHDAY' | translate }}<span class="required">*</span></label>
            <input required autocomplete="off" [type]="isWebview ? 'text' : 'date'" [max]="now" id="gcns_ngay_sinh" name="gcns_ngay_sinh"
              [value]="gcns.ngay_sinh" [(ngModel)]="gcns.ngay_sinh" placeholder="20-02-2002"  [pattern]="isWebview ? pattern.DATE : pattern.ALL"
              (ngModelChange)="checkBirthdayP(gcns.ngay_sinh); getFee()" />
              <span *ngIf="f.controls['gcns_ngay_sinh']?.valid&&isWebview&&validBirthdayP" class="input--check"><i class="fa-solid fa-circle-check"></i></span>
            <div class="invalid">
              <small *ngIf="f.controls['gcns_ngay_sinh']?.invalid && f.controls['gcns_ngay_sinh']?.touched && gcns.ngay_sinh.length < 1">
                {{error.shortReq|translate}}
              </small>
              <small *ngIf="f.controls['gcns_ngay_sinh']?.errors?.['pattern'] && isWebview">{{error.date|translate}}</small>
              <small *ngIf="f.controls['nG_SINH']?.valid &&!validBirthdayP">{{error.max_date|translate}}</small>
            </div>
          </div>
          <div class="text-field">
            <label for="gcns_gioi_tinh">{{ 'COMMON.GENDER' | translate }}<span class="required">*</span></label>
            <select required name="gcns_gioi_tinh" id="gcns_gioi_tinh" [(ngModel)]="gcns.gioi_tinh">
              <option value="" selected disabled>{{ 'PRODUCT.SELECT_GENDER' | translate }}...</option>
              <option *ngFor="let opt of genders" [value]="opt.vie">{{opt.label|translate}}</option>
            </select>
            <span *ngIf="f.controls['gcns_gioi_tinh']?.valid" class="input--check"><i class="fa-solid fa-circle-check"></i></span>
            <div class="invalid">
              <small
                *ngIf="f.controls['gcns_gioi_tinh']?.invalid && f.controls['gcns_gioi_tinh']?.touched">{{error.shortSel|translate}}</small>
            </div>
          </div>

        </div>

        <ng-container [ngTemplateOutlet]="prodStep"></ng-container>
      </div>

      <div id="step_3" [ngClass]="stepValue == 3 ? 'step' : 'hidden'">
        <app-form-title [icon]="'health_and_safety'" [label]="'PRODUCT.PROD_INFO'|translate"></app-form-title>

        <div class="special-flex space">
          <div class="dynamic-box">
            <div class="text-field">
              <label for="ngay_hl">{{ 'COMMON.FROM_DATE' | translate }}<span class="required">*</span></label>
              <input required autocomplete="off" [type]="isWebview ? 'text' : 'date'" id="ngay_hl" name="ngay_hl" [value]="gcns.ngay_hl"
                [(ngModel)]="gcns.ngay_hl" [min]="isWebview?null:now" [pattern]="isWebview ? pattern.DATE : pattern.ALL" (ngModelChange)="onChangeFromDate()" />
                <span *ngIf="f.controls['ngay_hl']?.valid&&isWebview&&validDate" class="input--check"><i class="fa-solid fa-circle-check"></i></span>
              <div class="invalid">
                <small *ngIf="f.controls['ngay_hl']?.touched && !f.controls['ngay_hl']!.value">{{error.required|translate}}</small>
                <small *ngIf="f.controls['ngay_hl']?.valid && gcns.ngay_hl.length > 0 && gcns.ngay_hl < (isWebview?null:now)">{{error.min_date|translate}}</small>
                <small *ngIf="f.controls['ngay_hl']?.valid &&!validDate&&isWebview">{{error.min_date|translate}}</small>
              </div>
            </div>
            <div class="text-field">
              <label for="ngay_kt">{{ 'COMMON.TO_DATE' | translate }}</label>
              <input [type]="isWebview ? 'text' : 'date'" id="ngay_kt" name="ngay_kt" [value]="gcns.ngay_kt"
                [(ngModel)]="gcns.ngay_kt" readonly />
                <span *ngIf="f.controls['ngay_kt']?.valid" class="input--check"><i class="fa-solid fa-circle-check"></i></span>
              <div class="invalid"></div>
            </div>
            <div class="text-field">
              <label for="goi_bh">{{ 'PRODUCT.PACKAGES' | translate }}<span class="required">*</span></label>
              <select required id="goi_bh" name="goi_bh" [(ngModel)]="gcns.goi_bh" (ngModelChange)="policyHandler(); getFee()">
                <option value="" selected disabled>{{ 'PRODUCT.SELECT_PACKAGE' | translate }}...</option>
                <!-- <option *ngFor="let opt of vbiPackages" [value]="opt.ma">{{opt.ten}}</option> -->
                <option *ngFor="let opt of goibaohiem" [value]="opt.value">{{opt.label|translate}}</option>
              </select>
              <span *ngIf="f.controls['goi_bh']?.valid" class="input--check"><i class="fa-solid fa-circle-check"></i></span>
              <div class="invalid">
                <small *ngIf="f.controls['goi_bh']?.invalid && f.controls['goi_bh']?.touched">{{error.select|translate}}</small>
              </div>
            </div>
            <div class="text-field">
              <label for="phi">{{ 'PAYMENT.FEE' | translate }}</label>
              <input required autocomplete="off" type="text" id="phi" name="phi" readonly [value]="fee.phi" [(ngModel)]="fee.phi"/>
              <span *ngIf="f.controls['phi']?.valid" class="input--check"><i class="fa-solid fa-circle-check"></i></span>
              <div class="invalid"></div>
            </div>
            <div class="text-field">
              <label for="tongphi">{{ 'PAYMENT.TOTAL' | translate }}</label>
              <input autocomplete="off" type="text" id="tongphi" name="tongphi" readonly [value]="fee.tongphi"/>
              <div class="invalid"></div>
            </div>
          </div>
          <div class="vbicare__dkbs">
            <span class="acare__decs">{{ 'PRODUCT.ADDITION_POLICY' | translate }}</span>
            <div class="acare__dkbs-item">
              <div class="check-field">
                <input type="checkbox" id="dkbs1" name="dkbs1" [value]="dkbs1"
                [(ngModel)]="dkbs1" (ngModelChange)="onChangeCheckbox(dkbs[1].value, dkbs1)" />
                <label for="dkbs1">{{ dkbs[1].label|translate }}</label>
              </div>
            </div>
            <div class="acare__dkbs-item">
              <div class="check-field">
                <input type="checkbox" id="dkbs2" name="dkbs2" [value]="dkbs2"
                [(ngModel)]="dkbs2" (ngModelChange)="onChangeCheckbox(dkbs[2].value, dkbs2)" />
                <label for="dkbs2">{{ dkbs[2].label|translate }}</label>
              </div>
            </div>
            <div class="acare__dkbs-item">
              <div class="check-field">
                <input type="checkbox" id="dkbs3" name="dkbs3" [value]="dkbs3"
                [(ngModel)]="dkbs3" (ngModelChange)="onChangeCheckbox(dkbs[3].value, dkbs3)" />
                <label for="dkbs3">{{ dkbs[3].label|translate }}</label>
              </div>
            </div>
            <div class="acare__dkbs-item">
              <div class="check-field">
                <input type="checkbox" id="dkbs4" name="dkbs4" [value]="dkbs4"  [disabled]="gcns.gioi_tinh === 'NAM'"
                [(ngModel)]="dkbs4" (ngModelChange)="onChangeCheckbox(dkbs[0].value, dkbs4)" />
                <label for="dkbs4">{{ dkbs[0].label|translate }}</label>
              </div>
            </div>
          </div>
        </div>

        <ng-container [ngTemplateOutlet]="prodStep"></ng-container>
      </div>
    </div>

    <div id="last-step" [ngClass]="stepValue === 4 ? 'step' : 'hidden'" *ngIf="isWebview === false">
      <app-form-title [label]="'PRODUCT.FEE_INFO'|translate"></app-form-title>
      <hr>
      <div class="last-step--label">
        <span>{{ 'PAYMENT.FEE' | translate }}:</span>
        <span>{{fee.phi}} VND</span>
      </div>
      <div class="last-step--label">
        <span>{{ 'PAYMENT.TAX' | translate }}:</span>
        <span>{{fee.thue}} VND</span>
      </div>
      <hr>
      <div class="flex">
        <img class="form__payment--thumb" src="../../../assets/frontend/images/coupon.png" alt="voucher">
        <span class="form__payment--voucher">Voucher:</span>
      </div>
      <div class="flex space" *ngIf="!meetVoucher">
        <div class="text-field launcher">
          <label for="launcher">{{ 'COMMON.REFERRAL_CODE' | translate }}</label>
          <input autocomplete="off" type="text" id="launcher" name="launcher" [placeholder]="'PRODUCT.A00000001'|translate"
            [value]="form.magioithieu" [(ngModel)]="form.magioithieu" [disabled]="isShared"
            (ngModelChange)="getLauncher(form.magioithieu)" />
        </div>
        <div class="text-field maVoucher">
          <label for="maVoucher">{{ 'PAYMENT.VOUCHER_ID' | translate }}</label>
          <input autocomplete="off" type="text" id="maVoucher" name="maVoucher" [placeholder]="'PRODUCT.INSERT_VOUCHER'|translate"
            [value]="voucher.maVoucher" [(ngModel)]="voucher.maVoucher"
            (ngModelChange)="voucher.maVoucher.length === 21 ? getDiscount(voucher.maVoucher) : getOldFee()" />
          <div class="invalid">
            <small *ngIf="voucher.maVoucher.length === 21 && voucher.status === false">{{error.voucher|translate}}</small>
          </div>
        </div>
      </div>
      <div *ngIf="form.nguoiGioiThieu !== ''">
        <span>{{ 'PAYMENT.PRESENTER' | translate }}:</span>
        <span>{{form.nguoiGioiThieu}}</span>
      </div>
      <div class="last-step--label">
        <span>{{ 'PAYMENT.SALE' | translate }}:</span>
        <span>- {{save}} VND</span>
      </div>
      <div class="last-step--label" style="font-weight: 500;">
        <span>{{ 'PAYMENT.TOTAL' | translate }}:</span>
        <span>{{fee.tongphi}} VND</span>
      </div>
      <hr>
      <button class="form__payment" (click)="onSubmit(f)"
        [disabled]="submited" [ngClass]="submited ? 'opacity-50' : 'opacity-100'">{{ 'COMMON.PAY' | translate }}</button>
    </div>

    <div id="webview-step" [ngClass]="stepValue === 4 ? 'step' : 'hidden'" *ngIf="isWebview">
      <app-form-title [label]="'PRODUCT.FEE_INFO'|translate"></app-form-title>
      <hr>
      <span class="label">{{ 'PAYMENT.CLIENT_NAME' | translate }}</span>
      <span class="info">{{form.ten}}</span>
      <span class="label">{{ 'PAYMENT.CLIENT_EMAIL' | translate }}</span>
      <span class="info">{{form.email}}</span>
      <span class="label">{{ 'PRODUCT.CLIENT_PHONE_NUMBER' | translate }}</span>
      <span class="info">{{form.mobi}}</span>
      <hr>
      <span class="label">{{ 'PAYMENT.FEE' | translate }}</span>
      <span class="info">{{fee.phi}}</span>
      <span class="label">{{ 'PAYMENT.TAX' | translate }}</span>
      <span class="info">{{fee.thue}} VND</span>
      <span class="label">{{ 'PAYMENT.TOTAL' | translate }}</span>
      <span class="fee webview-cl">{{fee.tongphi}} VND</span>
      <br>
      <ng-container [ngTemplateOutlet]="prodStep"></ng-container>
      <span *ngIf="!isApp" class="notice-payment">{{ 'PAYMENT.NOTICE' | translate }}</span>
    </div>
  </form>
</div>

<ng-template #getBackConfirm let-modal>
  <div class="modal-header">
    <h4 class="modal-title">{{ 'COMMON.PAY' | translate }}</h4>
  </div>
  <div class="modal-body">
    <p>{{ 'COMMON.BEFORE_BACK' | translate }} ?</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="getBack()">{{ 'COMMON.YES' | translate }}</button>
    <button type="button" class="btn btn-light" (click)="modal.close('Close click')">{{ 'COMMON.NO' | translate }}</button>
  </div>
</ng-template>

<ng-template #prodStep>
  <div class="step-and-total">
    <button class="back" (click)="stepValue > 1 ? onController(-1, f) : openCentered(getBackConfirm) ">
      {{ 'COMMON.BACK' | translate }}
    </button>
    <button class="next"  [disabled]="stepValue === 3 && !fee.tongphi" [ngClass]="(stepValue === 3 && !fee.tongphi) ? 'disableBtn' : ''"
      (click)="stepValue < 4 ? onController(1, f) : ''" *ngIf="stepValue < 4">
      {{ 'COMMON.NEXT' | translate }}
    </button>
    <button class="next" (click)="onSubmit(f)" *ngIf="stepValue === 4"
    [disabled]="submited" [ngClass]="submited ? 'opacity-50' : 'opacity-100'">{{ 'COMMON.PAY' | translate }}</button>
  </div>
</ng-template>

