<div class="admin-body">
  <div class="row page-titles mx-0">
    <div class="col p-md-0">
      <ol class="breadcrumb">
        <li class="breadcrumb-item" routerLink="/admin/dashboard">Quản lý</li>
        <li class="breadcrumb-item active">Mã giảm giá</li>
      </ol>
    </div>
  </div>

  <div class="admin__container">
    <div class="flex space">
      <span class="admin__container-head">Bảng mã giảm giá</span>
      <button type="button" class="btn btn-success btn-sm" routerLink="/admin/voucher-edit/0">Thêm mới</button>
    </div>

    <table class="table table-striped">
      <thead>
        <tr>
          <th scope="col" sortable="id" (sort)="onSort()">#</th>
          <th scope="col" sortable="name" (sort)="onSort()">Tên sản phẩm</th>
          <th scope="col" sortable="name" (sort)="onSort()">Loại Voucher</th>
          <th scope="col" sortable="name" (sort)="onSort()">Mã Voucher</th>
          <th scope="col" sortable="name" (sort)="onSort()">Phần trăm/ Giá trị</th>
          <th scope="col" sortable="name" (sort)="onSort()">Ngày hết hạn</th>
          <th scope="col" sortable="name" (sort)="onSort()">Số lượng còn lại</th>
          <th scope="col">Chức năng</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of vouchers; index as i">
          <th scope="row">{{ i + 1 }}</th>
          <td>{{ item.tenSanPham }}</td>
          <td>{{ item.loaiVoucher }}</td>
          <td>{{ item.maVoucher }}</td>
          <td>{{ item.phanTram }}<span *ngIf="item.loaiVoucher == 'Phần trăm'">%</span><span *ngIf="item.loaiVoucher == 'Giá trị'">.000 VNĐ</span></td>
          <td>{{ item.ngayHetHan | date: 'dd/MM/yyyy' }}</td>
          <td>{{ item.voucher_conlai }}</td>
          <td>
            <div class="d-flex justify-content-center">
              <button type="button" class="mx-1 btn btn-primary btn-sm" routerLink="/admin/voucher-edit/{{item.id}}">Sửa</button>
              <button type="button" class="mx-1 btn btn-danger btn-sm" (click)="onDelete(item.id)">Xoá</button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <ngx-skeleton-loader
      *ngIf="vouchers.length < 1"
      count="9"
      [theme]="{
        margin: '4px 0',
        width: '100%',
        height: '54px',
        'border-radius': '8px'
      }"
    ></ngx-skeleton-loader>
  </div>
</div>
