import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { ITContent } from '@models/content.model';
import { catchError, Observable, of, switchMap } from 'rxjs';
import { API_PATH } from 'src/utils/constant';

@Injectable({
  providedIn: 'root'
})
export class ContentService {
  CurrentLang:any;

  constructor(
    private _http: HttpClient,
  ) {}

  getContents(lang: string): Observable<ITContent[]> {
    const url = lang === 'en' ? environment.enURL : environment.apiURL
    return this._http.get(`${url}${API_PATH.CONTENT}`)
      .pipe(catchError((error) => {
        return of(error);
      }), switchMap((response) => {
        return of(response);
      }));
  }

  postContent(data: ITContent, lang: string): Observable<ITContent> {
    const url = lang === 'en' ? environment.enURL : environment.apiURL
    return this._http.post(`${url}${API_PATH.CONTENT}`, data)
      .pipe(catchError((error) => {
        return of(error);
      }), switchMap((response) => {
        return of(response);
      }));
  }

  getContentbyMeta(meta: string, lang: string): Observable<ITContent> {
    const url = lang === 'en' ? environment.enURL : environment.apiURL
    return this._http.get(`${url}${API_PATH.CONTENT}/${meta}`)
      .pipe(catchError((error) => {
        return of(error);
      }), switchMap((response) => {
        return of(response);
      }));
  }

  getContentbyID(id: number, lang: string): Observable<ITContent> {
    const url = lang === 'en' ? environment.enURL : environment.apiURL
    return this._http.get(`${url}${API_PATH.CONTENT}/id/${id}`)
      .pipe(catchError((error) => {
        return of(error);
      }), switchMap((response) => {
        return of(response);
      }));
  }

  putContent(id: number, data: ITContent, lang: string): Observable<ITContent> {
    const url = lang === 'en' ? environment.enURL : environment.apiURL
    return this._http.put(`${url}${API_PATH.CONTENT}/${id}`, data)
      .pipe(catchError((error) => {
        return of(error);
      }), switchMap((response) => {
        return of(response);
      }));
  }

  deleteContent(id: number, lang: string): Observable<ITContent> {
    const url = lang === 'en' ? environment.enURL : environment.apiURL
    return this._http.delete(`${url}${API_PATH.CONTENT}/${id}`)
      .pipe(catchError((error) => {
        return of(error);
      }), switchMap((response) => {
        return of(response);
      }));
  }

}
