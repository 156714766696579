<section class="container">
    <div class="signContract">
        <div class="card">
            <h3>ĐANG XÁC THỰC THANH TOÁN</h3>
            <div class="img">
                <img src="../../../assets/frontend/images/work-in-progress.png" alt="process">
            </div>
            <h2>Vui lòng quay lại trang chủ</h2>
            <p>Thông tin sẽ được sớm gửi đến quý khách nếu đã thanh toán thành công!</p>
        </div>
    </div>
</section>
