import { eff, now } from "."

export const BHSK_RELATIVE = [
  {
    id: 1,
    value: '1',
    code: 'QH00000',
    label: 'PRODUCTS.REL1',
    label_api: 'Bản thân'
  },
  {
    id: 2,
    value: '2',
    code: 'QH00001',
    label: 'PRODUCTS.REL2',
    label_api: 'Bố - Mẹ'
  },
  {
    id: 3,
    value: '3',
    code: 'QH00003',
    label: 'PRODUCTS.REL3',
    label_api: 'Anh - Chị - Em (Ruột)'
  },
  {
    id: 4,
    value: '4',
    code: 'QH00002',
    label: 'PRODUCTS.REL4',
    label_api: 'Vợ - Chồng'
  },
  {
    id: 5,
    value: '5',
    code: 'QH00007',
    label: 'PRODUCTS.REL5',
    label_api: 'Con Cái'
  },
  {
    id: 6,
    value: '6',
    code: 'QH00006',
    label: 'PRODUCTS.REL6',
    label_api: 'Khác'
  },
]

export const BHSK_INSURANCE = [
  {
    id: 1,
    value: 'BAN_LE_DONG_NEW',
    label: 'PRODUCTS.BRONZE',
    label_api: 'Gói Đồng'
  },
  {
    id: 2,
    value: 'BAN_LE_BAC_NEW',
    label: 'PRODUCTS.SILVER',
    label_api: 'Gói Bạc'
  },
  {
    id: 3,
    value: 'BAN_LE_VANG_NEW',
    label: 'PRODUCTS.GOLD',
    label_api: 'Gói Vàng'
  },
  {
    id: 4,
    value: 'BAN_LE_TITAN_NEW',
    label: 'PRODUCTS.TITAN',
    label_api: 'Gói Titan'
  },
  {
    id: 5,
    value: 'BAN_LE_BACH_KIM_NEW',
    label: 'PRODUCTS.PLATIUM',
    label_api: 'Gói Bạch Kim'
  },
  {
    id: 6,
    value: 'BAN_LE_KIM_CUONG_NEW',
    label: 'PRODUCTS.DIAMOND',
    label_api: 'Gói Kim Cương'
  },
]

export const VBICARE_PACKAGES = [
  {
    id: 1,
    value: 'NEW_BAN_LE_DONG',
    label: 'PRODUCTS.BRONZE',
    label_api: 'Gói Đồng'
  },
  {
    id: 2,
    value: 'NEW_BAN_LE_BAC',
    label: 'PRODUCTS.SILVER',
    label_api: 'Gói Bạc'
  },
  {
    id: 3,
    value: 'NEW_BAN_LE_VANG',
    label: 'PRODUCTS.GOLD',
    label_api: 'Gói Vàng'
  },
  {
    id: 4,
    value: 'NEW_BAN_LE_TITAN',
    label: 'PRODUCTS.TITAN',
    label_api: 'Gói Titan'
  },
  {
    id: 5,
    value: 'NEW_BAN_LE_KIM_CUONG',
    label: 'PRODUCTS.DIAMOND',
    label_api: 'Gói Kim Cương'
  },
]

export const VBICARE_DKBS = [
  {
    id: 1,
    value: 'C',
    label: 'PRODUCTS.VBI.C',
  },
  {
    id: 2,
    value: 'B3',
    label: 'PRODUCTS.VBI.B3',
  },
  {
    id: 3,
    value: 'D7',
    label: 'PRODUCTS.VBI.D7',
  },
  {
    id: 4,
    value: 'A15',
    label: 'PRODUCTS.VBI.A15',
  },
]

export const BHSK_ITEM_CUSTOMER_TEMPLATE = {
  so_id_dt_dtac: '',
  goi_bh: '',
  ten: '',
  dchi: '',
  ngay_sinh: '',
  gioi_tinh: '',
  cmt: '',
  cmt_ngay_cap: '',
  cmt_noi_cap: '',
  d_thoai: '',
  email: '',
  ngay_hl: now,
  ngay_kt: eff,
  moi_qh: '',
  dkbs: '',
}

export const BHSK_EMPTY_CUSTOMER_TEMPLATE = {
  so_id_dt_dtac: '',
  goi_bh: '',
  ten: '',
  dchi: '',
  ngay_sinh: '',
  gioi_tinh: '',
  cmt: '',
  cmt_ngay_cap: '',
  cmt_noi_cap: '',
  d_thoai: '',
  email: '',
  ngay_hl: now,
  ngay_kt: eff,
  moi_qh: '',
  dkbs: '',
}

export const VBICARE_FORM_TEMPLATE = {
  dtac_key: '',
  nsd: '',
  so_id_vbi: '',
  so_id_dtac: '',
  nv: 'CN.6',
  ten: '',
  dchi: '',
  ngaysinh: '',
  nG_SINH: '',
  gioi_tinh: '',
  gioi: '',
  cmt: '',
  cmt_ngay_cap: '',
  cmt_noi_cap: '',
  mobi: '',
  d_thoai: '',
  email: '',
  dai_dien: '',
  cvu_dai_dien: '',
  ctrinh: '',
  trang_thai: 'C',
  phi_bh: '',
  signature: '',
  GCNS: [],
  ttoan: '',
  nguoiGioiThieu: '',
  tensanpham: 'Bảo hiểm sức khỏe VBI',
  goisanpham: '',
  thoihan: '',
  iddangky: '',
  magioithieu: '',
  moiquanhe: '',
  user_Code: '',
}
