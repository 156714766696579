import { ApiService } from '@services/api.service';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ITCategory } from '@models/category.model';
import { ERROR } from 'src/utils/validation';
import { Editor, Validators } from 'ngx-editor';
import { BOOLEAN_SELECT, CATEGORY_TEMPLATE } from 'src/utils/constant';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from '@services/toast.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CommonService } from '@services/common/common.service';

@Component({
  selector: 'app-categories-manager',
  templateUrl: './categories-manager.component.html',
  styleUrls: ['./categories-manager.component.css']
})
export class CategoriesManagerComponent implements OnInit {
  title = 'Quản lý danh mục'
  editor!: Editor;
  dialogType: string = ''
  disableInput: boolean = false
  categories: ITCategory[] = []
  category: ITCategory = {
    ...CATEGORY_TEMPLATE
  }
  imageTemp: any
  iconTemp: any
  logoTemp: any
  public cateID: number = 0

  form: FormGroup = this._formBuilder.group({
    name: ['', Validators.required],
    slogan: ['', Validators.required],
    image: ['', Validators.required],
    icon: ['', Validators.required],
    logo: ['', Validators.required],
    detail: ['', Validators.required],
    status: [true],
  })

  constructor(
    private titleService: Title,
    private ApiService: ApiService,
    private _common: CommonService,
    private _formBuilder: FormBuilder,
    private modalService: NgbModal,
    public toastService: ToastService,
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle(this.title);
    this.getCategories()
    this.editor = new Editor()
  }

  showSuccess() {
    this.toastService.show('I am a success toast', { classname: 'bg-success text-light', delay: 10000 });
  }

  getCategories() {
    this.ApiService.getCategories().subscribe(data => {
      this.categories = data;
    })
  }

  // getCategory(id: number, isEdit: boolean) {
  //   this.ApiService.getCategory(id).subscribe(data => {
  //     isEdit ? this.form = data : this.category = data
  //   })
  // }

  onDelete(id: number) {
    this.ApiService.deleteServices(id, 'ProductCategory').subscribe()
    this.categories = []
    this.getCategories()
    this.modalService.dismissAll();
  }

  selectFile(e: any, control: string) {
    switch (control) {
      case 'image':
        this.imageTemp = e.target.files
        break
      case 'icon':
        this.iconTemp = e.target.files
        break
      case 'logo':
        this.logoTemp = e.target.files
        break
    }

    let formData = new FormData()
    for (let file of this.imageTemp) {
      formData.append('files', file)
    }

    this._common.uploads(formData).subscribe((res: any) => {
      console.log("🚀 ~ file: categories-manager.component.ts:110 ~ CategoriesManagerComponent ~ this._common.upload ~ res", res)
      switch (control) {
        case 'image':
          this.form.controls['image'].setValue(res[0].name)
          break
        case 'icon':
          this.form.controls['icon'].setValue(res[0].name)
          break
        case 'logo':
          this.form.controls['logo'].setValue(res[0].name)
          break
      }
    })
  }

  openModal(content: TemplateRef<any>, id: number) {
    this.cateID = id
    this.modalService.open(content, { centered: true });
  }

  onSort() {

  }

  onSubmit(f: any, warming: TemplateRef<any>) {
    if (f.invalid) {
      this.modalService.open(warming, { centered: true })

      f.controls['name']!.touched = true
      f.controls['slogan']!.touched = true
    } else {
      this.categories = []
      this.getCategories()
    }
  }

  onSave() {
    let payload = {
      ...this.category,
      ...this.form.value
    }

    this.dialogType === 'add' && this.ApiService.postCategory(payload).subscribe((res: any) => {
      res && this.getCategories()
    })

    this.dialogType === 'edit' && this.ApiService.putCategory(this.cateID, payload).subscribe((res: any) => {
      res && this.getCategories()
    })
  }

  dialogHandler(type: string, content: TemplateRef<any>, row?: any) {
    this.dialogType = type
    type === 'view' ? (this.disableInput = true) : (this.disableInput = false)
    type === 'edit' && (this.cateID = row.id)
    // type !== 'add' && this.getCategory(row.id)

    switch(type) {
      case 'add':
        this.modalService.open(content, { centered: true });
        break
      case 'delete':
        this.modalService.open(content, { centered: true });
        break
      case 'delete':
        this.modalService.open(content, { centered: true });
        break
    }
  }

  error = ERROR
  boolean = BOOLEAN_SELECT
}
