<div class="login__page">
    <p class="login__head">{{ subLabel }} GPAY</p>
    <div class="login__contain">
      <div class="login__form">
        <p class="login__form-label">{{ subLabel }} GPAY</p>

        <ng-container [ngTemplateOutlet]="registerForm"></ng-container>
      </div>

      <div class="login__contain--left">
        <div class="login__banner mobile-hidden">
          <img class="login__thumb" src="../../../assets/frontend/images/bu/term-form-graphic2.png" alt="insurance for family">
          <div class="login__card">
            <div class="login__card--child" *ngFor="let usp of usps">
              <app-usp-card [icon]="usp.icon" [isBorder]="true" [color]="usp.color" [label]="usp.label" [child]="usp.child"></app-usp-card>
            </div>
          </div>
        </div>
        <div class="login__pointer">
          <div class="login__pointer-block">
            <app-pointat *ngFor="let point of pointers" [icon]="point.icon" [label]="point.label"
              [childLabel]="point.childLabel"></app-pointat>
          </div>
          <p class="login__pointer--addition">
            *Lợi ích về thuế có thể thay đổi trong luật thuế
          </p>
        </div>
      </div>
    </div>
  </div>

  <ng-template #validWarming let-modal>
    <div class="modal-header">
      <h4 class="modal-title">Thông báo</h4>
    </div>
    <div class="modal-body">
      <p>Vui lòng nhập đúng thông tin trước khi đăng nhập !</p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary" (click)="modal.close('Close click')">Đồng ý</button>
    </div>
  </ng-template>

  <ng-template #success let-modal>
    <div class="modal-header">
      <h4 class="modal-title">Thông báo</h4>
    </div>
    <div class="modal-body">
      <p>{{ message }}</p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary" (click)="modal.close('Close click')">Đồng ý</button>
    </div>
  </ng-template>

  <!-- <ng-template #loginForm>
    <form [formGroup]="login">
      <div class="text-field">
        <input type="text" id="username" name="username" [pattern]="valid.PHONE" formControlName="username" placeholder="Số điện thoại" />
        <div class="invalid">
          <ng-container *ngIf="l['username']?.touched || submitted">
            <small *ngIf="l['username']?.hasError('required')">{{ error.required }}</small>
            <small *ngIf="l['username']?.hasError('pattern')">{{ error.phone }}</small>
          </ng-container>
        </div>
      </div>

      <div class="text-field password--show">
        <input [type]="showType" minlength="8" id="password" name="password" formControlName="password" placeholder="Mật khẩu" />
        <span class="material-icons password--show" (click)="onShow()">{{ isShow ? 'visibility' : 'visibility_off' }}</span>
        <div class="invalid">
          <ng-container *ngIf="l['password']?.touched || submitted">
            <small *ngIf="l['password']?.hasError('required')">{{ error.required }}</small>
            <small *ngIf="l['password']?.hasError('minlength')">{{ error.min_length }}</small>
          </ng-container>
        </div>
      </div>

      <p>Bạn chưa có tài khoản? <strong class="sugest" (click)="formHandler(true)">Đăng ký ngay</strong></p>
      <app-button (click)="onLogin(login, validWarming, success)" [label]="subLabel"
        [getStyle]="'button-template__button border fill'"></app-button>
    </form>
  </ng-template> -->

  <ng-template #registerForm>
    <form [formGroup]="register">
        <div class="text-field">
          <label for="hoten">{{ 'COMMON.FULL_NAME' | translate }} *</label>
          <input type="text" id="hoten" placeholder="Họ và tên" name="hoten" formControlName="hoten" />
          <div class="invalid">
            <ng-container *ngIf="r['hoten']?.touched || submitted">
              <small *ngIf="r['hoten']?.hasError('required')">{{ error.required }}</small>
            </ng-container>
          </div>
        </div>

        <div class="text-field">
          <label for="so_dien_thoai">{{ 'COMMON.PHONE_NUMBER' | translate }} *</label>
          <input type="text" id="so_dien_thoai" name="so_dien_thoai" placeholder="Số điện thoại" [pattern]="valid.PHONE" formControlName="so_dien_thoai" />
          <div class="invalid">
            <ng-container *ngIf="r['so_dien_thoai']?.touched || submitted">
              <small *ngIf="r['so_dien_thoai']?.hasError('required')">{{ error.required }}</small>
              <small *ngIf="r['so_dien_thoai']?.hasError('pattern')">{{ error.phone }}</small>
            </ng-container>
          </div>
        </div>

        <div class="text-field">
          <label for="cmnd">CMND/CCCD *</label>
          <input type="text" id="cmnd" name="cmnd"
          [pattern]="valid.CCCD" min="9" max="12"
          formControlName="cmnd" placeholder="CMND/CCCD" />
          <div class="invalid">
            <ng-container *ngIf="r['cmnd']?.touched || submitted">
              <small *ngIf="r['cmnd']?.hasError('required')">{{ error.required }}</small>
              <small *ngIf="r['cmnd']?.hasError('pattern')">{{ error.identify }}</small>
            </ng-container>
          </div>
        </div>

        <div class="text-field">
          <label for="email">Email *</label>
          <input type="text" id="email" name="email" placeholder="Email" formControlName="email" />
          <div class="invalid">
            <ng-container *ngIf="r['email']?.touched || submitted">
              <small *ngIf="r['email']?.hasError('required')">{{ error.required }}</small>
              <small *ngIf="r['email']?.hasError('pattern')">{{ error.email }}</small>
            </ng-container>
          </div>
        </div>

        <!-- <div class="text-field">
          <label for="company">Tên công ty/Cửa hàng</label>
          <input type="text" id="company" name="company" formControlName="company" />
        </div>

        <div class="text-field">
          <label for="website">Website/Fanpage</label>
          <input type="text" id="website" name="website" formControlName="website" />
        </div> -->

      <!-- <p>Bạn đã có tài khoản? <strong class="sugest" (click)="formHandler(false)">Đăng nhập ngay</strong></p> -->
      <br>
      <div class="d-flex justify-content-around">
        <button class="btn btn-primary" (click)="onRegister(register, validWarming, success)"
          [disabled]="!register.valid">Đăng ký</button>
      </div>
      <br>
      <p>Bằng việc đăng ký, bạn đã đồng ý với chúng tôi về <a href="#">Điều khoản & Điều kiện</a> và <a href="#">Chính sách bảo mật</a></p>
    </form>
  </ng-template>

