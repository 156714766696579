<div class="admin-body">
  <div class="row page-titles mx-0">
    <div class="col p-md-0">
      <ol class="breadcrumb">
        <li class="breadcrumb-item" routerLink="admin/dashboard">Quản lý</li>
        <li class="breadcrumb-item active">Danh mục</li>
      </ol>
    </div>
  </div>

  <div class="admin__container">
    <div class="flex space">
      <span class="admin__container-head">Bảng danh mục</span>
      <button type="button" class="btn btn-success btn-sm" (click)="dialogHandler('add', dialogForm)">Thêm mới</button>
    </div>

    <table class="table table-striped">
      <thead>
        <tr>
          <th scope="col" sortable="id" (sort)="onSort()">#</th>
          <!-- <th scope="col" sortable="stt" (sort)="onSort()">SST</th> -->
          <th scope="col" sortable="name" (sort)="onSort()">Tên danh mục</th>
          <th scope="col" class="start">Icon</th>
          <th scope="col" class="start">Logo</th>
          <th scope="col" class="start">Slogan</th>
          <th scope="col" class="start" sortable="createdDate" (sort)="onSort()">Ngày tạo</th>
          <!-- <th scope="col">Người tạo</th> -->
          <th scope="col">Trạng thái</th>
          <th scope="col">Chức năng</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let cate of categories; index as i">
          <th scope="row">{{ i + 1 }}</th>
          <!-- <th scope="row">{{ cate.stt }}</th> -->
          <td>{{ cate.name }}</td>
          <td>
            <img src="../../../assets{{cate.icon}}" alt="" style="width: 24px">
          </td>
          <td>
            <img src="../../../assets{{cate.logo}}" alt="" style="width: 24px">
          </td>
          <td class="start">{{ cate.slogan }}</td>
          <td class="start">{{ cate.createdDate | date:'dd/MM/yyyy' }}</td>
          <!-- <td>{{ cate.createdBy }}</td> -->
          <td>
            <span [ngClass]="[cate.status ? 'material-icons true' : 'material-icons false']">{{ cate.status ?
              'check_circle' : 'cancel' }} </span>
          </td>
          <td>
            <!-- <span class="material-icons" title="Xem" (click)="dialogHandler('view', dialogForm, cate)">visibility</span> -->
            <span class="material-icons" title="Sửa" (click)="dialogHandler('edit', dialogForm, cate)">edit</span>
            <span class="material-icons" title="Xóa" (click)="dialogHandler('delete', confirmDelete, cate)">delete</span>
          </td>
        </tr>
      </tbody>
    </table>

    <ngx-skeleton-loader *ngIf="categories.length < 1" count="9" [theme]="{
        margin: '4px 0',
        width: '100%',
        height: '54px',
        'border-radius': '8px'
      }"></ngx-skeleton-loader>
  </div>
</div>

<ng-template #confirmDelete let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-title">Xác nhận xoá danh mục</h4>
  </div>
  <div class="modal-body">
    <p>
      <strong>Bạn có chắc chắn muốn xoá danh mục
        <!-- <span class="text-primary">"{{cate.ten}}"</span> -->
        ?
      </strong>
    </p>
    <p>Tất cả thông tin liên quan đến danh mục này sẽ bị xóa vĩnh viễn.</p>
    <span class="text-danger">Và không thể hoàn tác thao tác này.</span>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('cancel click')">Hủy</button>
    <button type="button" class="btn btn-danger" (click)="onDelete(cateID)">Xóa</button>
  </div>
</ng-template>

<ng-template #validWarming let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Thông báo</h4>
  </div>
  <div class="modal-body">
    <p>Vui lòng nhập đúng, đầy đủ phiếu thông tin trước khi lưu danh mục !</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="modal.close('Close click')">Đồng ý</button>
  </div>
</ng-template>

<ng-template #dialogForm let-modal>
	<div class="modal-header">
		<h4 class="modal-title">{{ dialogType === 'add' ? 'Thêm mới' : 'Chỉnh sửa' }} danh mục</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
	</div>
	<div class="modal-body">
    <form class="dialog" [formGroup]="form">
      <div class="text-field">
        <label for="name">Tên danh mục<span class="required">*</span></label>
        <input [readonly]="disableInput" required type="text" id="name" name="name" formControlName="name" />
        <div class="invalid">
          <small *ngIf="form.controls['name'].touched && form.controls['name'].hasError('required')">{{error.required}}</small>
        </div>
      </div>

      <div class="text-field">
        <label for="slogan">Slogan<span class="required">*</span></label>
        <input [readonly]="disableInput" required type="text" id="slogan" name="slogan" formControlName="slogan" />
        <div class="invalid">
          <small *ngIf="form.controls['slogan'].touched && form.controls['slogan'].hasError('required')">{{error.required}}</small>
        </div>
      </div>

      <div class="text-field upload">
        <label for="image">Image<span class="required">*</span></label>
        <input type="file" name="image" id="image" accept="image/png, image/gif, image/jpeg" aria-label="Tải lên ảnh danh mục"
          (change)="selectFile($event, 'image')" />
        <div class="invalid">
          <small *ngIf="form.controls['image'].touched && form.controls['image'].hasError('required')">{{error.required}}</small>
        </div>
      </div>

      <div class="text-field upload">
        <label for="icon">Icon<span class="required">*</span></label>
        <input type="file" name="icon" id="icon" accept="image/png, image/gif, image/jpeg" aria-label="Tải lên icon danh mục"
          (change)="selectFile($event, 'icon')" />
        <div class="invalid">
          <small *ngIf="form.controls['icon'].touched && form.controls['icon'].hasError('required')">{{error.required}}</small>
        </div>
      </div>

      <div class="text-field upload">
        <label for="logo">Logo<span class="required">*</span></label>
        <input type="file" name="logo" id="logo" accept="image/png, image/gif, image/jpeg" aria-label="Tải lên logo danh mục"
          (change)="selectFile($event, 'logo')" />
        <div class="invalid">
          <small *ngIf="form.controls['logo'].touched && form.controls['logo'].hasError('required')">{{error.required}}</small>
        </div>
      </div>

      <div class="text-field">
        <label for="detail">Nội dung<span class="required">*</span></label>
        <div class="NgxEditor__Wrapper">
          <ngx-editor-menu [editor]="editor"> </ngx-editor-menu>
          <ngx-editor [editor]="editor" formControlName="detail" [disabled]="false" [placeholder]="'Type here...'"></ngx-editor>
        </div>
        <div class="invalid">
          <small *ngIf="form.controls['detail'].touched && form.controls['detail'].hasError('required')">{{error.required}}</small>
        </div>
      </div>

      <div class="text-field">
        <label for="status">Trạng thái</label>
        <select name="status" id="status" formControlName="status" *ngIf="!disableInput">
          <option *ngFor="let opt of boolean" [value]="opt.value">{{opt.vie}}</option>
        </select>
        <input readonly type="text" id="status" name="status" formControlName="status" *ngIf="disableInput" />
        <div class="invalid">
          <small *ngIf="form.controls['status'].touched && form.controls['status'].hasError('required')">{{error.select}}</small>
        </div>
      </div>

    </form>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-primary flex space" (click)="onSave(); modal.close('Close click')" [disabled]="form.invalid" *ngIf="this.dialogType === 'edit'">
      <span class="material-icons text-light">save</span>
      <p class="m-0 px-1">Lưu</p>
    </button>
		<button type="button" class="btn btn-danger flex space" (click)="modal.close('Close click')">
      <span class="material-icons text-light">close</span>
      <p class="m-0 px-1">Đóng</p>
    </button>
	</div>
</ng-template>
