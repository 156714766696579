import { BrowserModule } from '@angular/platform-browser';
import { ApiService } from '@services/api.service'
import { Component, Input, OnInit, DoCheck, TemplateRef, ViewEncapsulation, ViewChild, AfterViewInit, SimpleChanges } from '@angular/core'
import { ITCategory } from '@models/category.model'
import { ITProduct } from '@models/product.model'
import { NgbCarouselConfig, NgbModal, NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap'
import { HOME_BANNERS, HOME_BRANDS, HOME_FEEDBACKS, HOME_HELPINFO, HOME_INFORMATIONS, HOME_OURPARTNERS, HOME_PROMISES, HOME_PROMISE_INNER, HOME_USPS } from 'src/utils/constant'
import { ITContent } from '@models/content.model'
import { environment } from '@environments/environment'
import { ContentService } from '@services/content/content.service'
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  providers: [NgbCarouselConfig],
  encapsulation: ViewEncapsulation.None,
})
export class HomeComponent implements OnInit, AfterViewInit {
  prodEnv: boolean = environment.production
  categories: ITCategory[] = []
  products: ITProduct[] = []
  news: ITContent[] = []
  ableNews: any = []
  public innerWidth: any
  partnerID:any
  @ViewChild('content') content: any
  @Input() isHidden = false
  CurrentLang: any;
  closeResult = ''


  slideConfig = {
    slidesToShow: 3,
    autoplay: true,
    autoplaySpeed: 1000,
    speed: 1000,
    slidesToScroll: 1,
    dots: true,
    prevArrow: false,
    nextArrow: false,
    responsive: [
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  slidenewConfig = {
    slidesToShow: 4,
    autoplay: true,
    autoplaySpeed: 2000,
    speed: 1500,
    slidesToScroll: 1,
    dots: true,
    prevArrow: false,
    nextArrow: false,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  slickInit(e: any) { }
  breakpoint(e: any) { }
  afterChange(e: any) { }
  beforeChange(e: any) { }

  constructor(
    private modalService: NgbModal,
    private ApiService: ApiService,
    private _contentsService: ContentService,
    private offcanvasService: NgbOffcanvas,
    public _translate: TranslateService,
    private route: ActivatedRoute,
  ) {
    this.CurrentLang = _translate.getDefaultLang();
    this.partnerID = this.route.snapshot.params['partner']
    _translate.onLangChange.subscribe((e: any) => {
      this.CurrentLang = e.lang;
      this.getProducts()
      this.getCategories()
      this.getContents()
    })
  }

  ngAfterViewInit(): void {
    this.openModal()
  }

  ngOnInit() {
    this.innerWidth = window.innerWidth
    this.getCategories()
    this.getProducts()
    this.onSortProducts()
    this.getContents()
  }

  getCategories() {
    this.ApiService.getCategories(this.CurrentLang).subscribe((data) => {
      this.categories = data
    })
  }

  getProducts() {
    this.products = []
    this.ApiService.getProducts(this.CurrentLang).subscribe((data) => {
      this.products = data
      this.onSortProducts()
      // this.prodEnv && this.onFilterData()
      this.onFilterData()
    })
  }

  onSortProducts() {
    this.products.sort((a, b) => {
      return a.stt - b.stt
    })
  }

  onFilterData() {
    let filteredProd = this.products?.filter(prod => prod.status)
    this.products = filteredProd

    let tempCateID: any[] = []
    filteredProd.map(item => {
      const exist = tempCateID.find(i => i === item.categoryID)
      exist || tempCateID.push(item.categoryID)
    })

    let filteredCate = this.categories.filter(cate =>
      cate.id === tempCateID[0] || cate.id === tempCateID[1] || cate.id === tempCateID[2] || cate.id === tempCateID[3]
    )
    this.categories = filteredCate
  }

  getContents() {
    this._contentsService.getContents(this.CurrentLang).subscribe((data) => {
      this.news = data;
      this.ableNews = [];
      for (let i = 0; i < this.news.length; i++) {
        if (this.news[i].status == true) {
          this.ableNews.push(this.news[i]);
        }
      }
    })
  }

  onSetHidden() {
    this.isHidden = true
  }

  openModal() {
    if (this.modalService.hasOpenModals() == false) {
      this.modalService.open(this.content, {
        modalDialogClass: 'unset-modal',
        centered: true,
        size: 'lg',
      })
    }
  }

  openModalProduct(product_modal: TemplateRef<any>) {
    this.offcanvasService.open(product_modal, {
      position: 'bottom',
      panelClass: 'product_modal',
      backdropClass: 'backdrop_bg',
    })
  }

  openModalFold(fold_modal: TemplateRef<any>) {
    this.offcanvasService.open(fold_modal, {
      position: 'bottom',
      panelClass: 'fold_modal',
      backdropClass: 'backdrop_bg',
    })
  }

  openModalPromise(promise_modal: TemplateRef<any>) {
    this.offcanvasService.open(promise_modal, {
      position: 'bottom',
      panelClass: 'promise_modal',
      backdropClass: 'backdrop_bg',
    })
  }

  promise_inner = HOME_PROMISE_INNER
  usps = HOME_USPS
  banners = HOME_BANNERS
  informations = HOME_INFORMATIONS
  promises = HOME_PROMISES
  policies = HOME_PROMISES
  feedbacks = HOME_FEEDBACKS
  ourPartners = HOME_OURPARTNERS
  helpInfo = HOME_HELPINFO
  brands = HOME_BRANDS
}
