import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractControl, ValidatorFn } from '@angular/forms';
import { environment } from '@environments/environment';
import { ITLogin, ITRegister, ITSession } from '@models/auth.model';
import { catchError, Observable, of, switchMap } from 'rxjs';
import { API_PATH } from 'src/utils/constant';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private loggedInStatus = JSON.parse(localStorage.getItem('loggedIn') || ('false'));
  private loggedInRole = (localStorage.getItem('loggedInRole') || (''));

  constructor(
    private _http: HttpClient
  ) { }

  onLogin(data: ITLogin): Observable<ITSession> {
    return this._http.post(`${environment.apiURL}${API_PATH.AUTH}`, data)
      .pipe(catchError((error) => {
        return of(error);
      }), switchMap((response) => {
        return of(response);
      }));
  }

  setLoginStatus(value: boolean) {
    this.loggedInStatus = value;
    localStorage.setItem('loggedIn', `${value}`);
  }

  get LoginStatus() {
    return JSON.parse(localStorage.getItem('loggedIn') || this.loggedInStatus.toString());
  }

  setLoginRole(value: string) {
    this.loggedInRole = value;
    localStorage.setItem('loggedInRole', value);
  }

  get LoginRole() {
    return (localStorage.getItem('loggedInRole') || this.loggedInRole);
  }

  onRegister(data: ITRegister): Observable<any> {
    return this._http.post(`${environment.apiURL}${API_PATH.REGISTER}`, data)
      .pipe(catchError((error) => {
        return of(error);
      }), switchMap((response) => {
        return of(response);
      }));
  }

  onChangePass(data: any): Observable<any> {
    return this._http.post(`${environment.apiURL}${API_PATH.CHANGE_PASS}`, data)
      .pipe(catchError((error) => {
        return of(error);
      }), switchMap((response) => {
        return of(response);
      }));
  }

  getEmailOTP(param: string): Observable<any> {
    return this._http.get<any>(environment.apiURL + API_PATH.GET_MAIL_OTP + `?email=${param}`)
  }

  onActive(key: string): Observable<any> {
    return this._http.post(`${environment.apiURL}${API_PATH.ACTIVE}?keyactive=${key}`, null)
      .pipe(catchError((error) => {
        return of(error);
      }), switchMap((response) => {
        return of(response);
      }));
  }

  match(controlName: string, checkControlName: string): ValidatorFn {
    return (controls: AbstractControl) => {
      const control = controls.get(controlName);
      const checkControl = controls.get(checkControlName);

      if (checkControl?.errors && !checkControl.errors['matching']) {
        return null;
      }

      if (control?.value !== checkControl?.value) {
        controls.get(checkControlName)?.setErrors({ matching: true });
        return { matching: true };
      } else {
        return null;
      }
    };
  }

  get windowRef() {
    return window
  }

}
