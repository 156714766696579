import { PRODUCT_TEMPLATE } from 'src/utils/constant';

import { Component, OnInit, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { ITProduct } from '@models/product.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from '@services/api.service';
import { ProductsService } from '@services/products/products.service';

@Component({
  selector: 'app-products-manager',
  templateUrl: './products-manager.component.html',
  styleUrls: ['./products-manager.component.css']
})
export class ProductsManagerComponent implements OnInit {
  products: ITProduct[] = []
  product: ITProduct = {
    ...PRODUCT_TEMPLATE
  }
  dialogType: string = ''
  dialogName: string = ''
  CurrentLang: any;
  disableInput: boolean = false
  constructor(
    private ApiService: ApiService,
    private _service: ProductsService,
    private _modal: NgbModal,
    public _translate: TranslateService,
    private router: Router,
  ) {
    this.CurrentLang = _translate.getDefaultLang();
    _translate.onLangChange.subscribe((e: any) => {
      this.CurrentLang = e.lang;
      this.getProducts()
    })
  }

  ngOnInit(): void {
    this.getProducts()
  }

  getProducts() {
    this.ApiService.getProducts(this.CurrentLang).subscribe(data => {
      this.products = data;
    })
  }

  getProductbyID(id: number) {
    this._service.getProductbyID(id, this.CurrentLang).subscribe(res => {
      this.product = res
      // this.onInitData(this.product)
    })
  }

  onSort() {
  }

  dialogHandler(type: string, row: any, content: TemplateRef<any>) {
    this.dialogType = type
    type === 'view' ? (this.disableInput = true) : (this.disableInput = false)
    // type === 'edit' && (this.tempID = row.id)
    type !== 'add' && this.getProductbyID(row.id)

    switch (type) {
      case 'add':
        this.dialogName = 'Thêm mới'
        this._modal.open(content, { size: 'lg', centered: true, scrollable: true });
        break
      case 'delete':
        this._modal.open(content, { centered: true });
        break
      default:
        console.log('Something wrong!!!')
    }
  }

  onDelete(id: number) {
    this._service.deleteProduct(id, this.CurrentLang).subscribe()
    this.products = []
    this.getProducts()
    this._modal.dismissAll();
  }

  onRouting(): void {
    this.router.navigate(['/admin/product-edit/0'])
  }

}
