import { catchError, Observable, of, switchMap } from 'rxjs';
import { API_PATH } from 'src/utils/constant';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { ITVouchers, ITVoucher } from '@models/voucher.model';

@Injectable({
  providedIn: 'root'
})
export class VoucherService {

  constructor(
    private _http: HttpClient,
  ) { }


  getVouchers(lang?: string): Observable<ITVouchers[]> {
    const url = lang === 'en' ? environment.enURL : environment.apiURL
    return this._http.get(`${url}${API_PATH.VOUCHER}`).pipe(catchError((error) => {
      return of(error);
    }), switchMap((response) => {
      return of(response);
    }));
  }

  postVoucher(data: ITVoucher, lang?: string): Observable<ITVoucher> {
    const url = lang === 'en' ? environment.enURL : environment.apiURL
    return this._http.post(`${url}${API_PATH.VOUCHER}`, data).pipe(catchError((error) => {
      return of(error);
    }), switchMap((response) => {
      return of(response);
    }));
  }

  getVoucherByID(id: number, lang?: string): Observable<ITVoucher[]> {
    const url = lang === 'en' ? environment.enURL : environment.apiURL
    const path = API_PATH.VOUCHER_BY_ID.replace('{id}', id.toString())

    return this._http.get(`${url}${path}`).pipe(catchError((error) => {
      return of(error);
    }), switchMap((response) => {
      return of(response);
    }));
  }

  putVoucher(data: ITVoucher, lang?: string): Observable<ITVoucher[]> {
    const url = lang === 'en' ? environment.enURL : environment.apiURL
    const path = API_PATH.VOUCHER_BY_ID.replace('{id}', data.id.toString())

    return this._http.put(`${url}${path}`, data).pipe(catchError((error) => {
      return of(error);
    }), switchMap((response) => {
      return of(response);
    }));
  }

  deleteVoucherByID(id: number, lang?: string): Observable<ITVoucher[]> {
    const url = lang === 'en' ? environment.enURL : environment.apiURL
    const path = API_PATH.VOUCHER_BY_ID.replace('{id}', id.toString())

    return this._http.delete(`${url}${path}`).pipe(catchError((error) => {
      return of(error);
    }), switchMap((response) => {
      return of(response);
    }));
  }

}
