import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { ITArea, ITDistrict } from '@models/area.model';
import { ITPayment, ITPaymentRespon, ITPaymentUrl } from '@models/payment.model';
import { ITCategory } from '@models/category.model';
import { ITFee } from '@models/fee.model';
import { ITProduct } from '@models/product.model';
import { ITVoucher, ITVouchers } from '@models/voucher.model';
import { Observable } from 'rxjs';
import { API_PATH } from 'src/utils/constant';
import { ITContract } from '@models/contract.model';
import { ITCompareGoi, ITKhoangGia, ITCompareNcc, ITComparePackPolicy, ITComparePolicies, ITCompareProd, ITCompareTuoi, ITCompareKhoangGia, ITCol } from '@models/compare.model';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(
    private http: HttpClient,
  ) { }

  getServices(url: string): Observable<any> {
    return this.http.get<any>(`${environment.apiURL}${url}`)
  }

  postServices(url: string, body: any): Observable<any> {
    return this.http.post<any>(`${environment.apiURL}${url}`, body)
  }

  postActiveAccount(key: any): Observable<any> {
    return this.http.post<any>(`${environment.apiURL}api/Authentication/ActiveAccount?keyactive=${key}`, '')
  }

  deleteServices(id: number, url: string): Observable<any> {
    return this.http.delete<any>(`${environment.apiURL}api/${url}/${id}`)
  }

  getFee(url: string, body: any): Observable<ITFee> {
    return this.http.post<ITFee>(`${environment.apiURL}${url}`, body)
  }

  postPayment(body: ITPayment | any): Observable<ITPaymentUrl> {
    return this.http.post<ITPaymentUrl>(environment.apiURL + API_PATH.PAYMENT, body)
  }

  postConfirm(url: string, body: any): Observable<ITPaymentRespon> {
    return this.http.post<ITPaymentRespon>(`${environment.apiURL}${url}`, body)
  }

  activeMeetVoucher(voucher: string): Observable<any> {
    const headers = new HttpHeaders({ 'sign': '01c7457348163dde7416ae2595247516' });
    const body = new FormData();
    body.append("voucherID", voucher);
    return this.http.post(environment.apiMeet + API_PATH.ACTIVEVCMEET, body, { headers: headers })
  }

  postMeetVoucher(body: any): Observable<any> {
    return this.http.post(environment.apiURL + API_PATH.POSTVCMEET, body)
  }

  postMeetVoucherInfo(body: any): Observable<any> {
    return this.http.post(environment.apiURL + API_PATH.POSTVCMEETINFO, body)
  }

  getCategories(lang?: string): Observable<ITCategory[]> {
    const url = lang === 'en' ? environment.enURL : environment.apiURL
    return this.http.get<ITCategory[]>(url + API_PATH.CATEGORIES)
  }

  getCategory(id: number): Observable<ITCategory> {
    return this.http.get<ITCategory>(environment.apiURL + API_PATH.CATEGORIES + `/${id}`)
  }

  postCategory(body: ITCategory): Observable<ITCategory> {
    return this.http.post<ITCategory>(environment.apiURL + API_PATH.CATEGORIES, body)
  }

  putCategory(id: number, body: ITCategory): Observable<ITCategory> {
    return this.http.put<ITCategory>(environment.apiURL + API_PATH.CATEGORIES + `/${id}`, body)
  }

  getProducts(lang?: string): Observable<ITProduct[]> {
    const url = lang === 'en' ? environment.enURL : environment.apiURL

    return this.http.get<ITProduct[]>(url + API_PATH.PRODUCT_LIST)
  }

  getProductByName(name: string, lang?: string,): Observable<ITProduct[]> {
    const url = lang === 'en' ? environment.enURL : environment.apiURL
    return this.http.get<any>(url + API_PATH.PRODUCTBYNAME + '/' + name)
  }

  findProduct(id: number, lang?: string): Observable<ITProduct> {
    const url = lang === 'en' ? environment.enURL : environment.apiURL

    return this.http.get<ITProduct>(`${url}${API_PATH.PRODUCT_LIST}/${id}`)
  }

  pickProduct(name: string, lang?: string): Observable<ITProduct> {
    const url = lang === 'en' ? environment.enURL : environment.apiURL

    return this.http.get<ITProduct>(`${url}${API_PATH.PRODUCT_LIST}/${name}`)
  }

  postProduct(body: ITProduct, lang?: string): Observable<ITProduct> {
    const url = lang === 'en' ? environment.enURL : environment.apiURL
    return this.http.post<ITProduct>(url + API_PATH.PRODUCT_LIST, body)
  }

  putProduct(id: number, body: ITProduct, lang?: string): Observable<ITProduct> {
    const url = lang === 'en' ? environment.enURL : environment.apiURL
    return this.http.put<ITProduct>(url + API_PATH.PRODUCT_LIST + `/${id}`, body)
  }

  getVoucher(): Observable<ITVouchers[]> {
    return this.http.get<ITVouchers[]>(environment.apiURL + API_PATH.VOUCHER)
  }

  getDiscount(param: string): Observable<ITVoucher> {
    return this.http.get<ITVoucher>(environment.apiURL + API_PATH.DISCOUNT + `${param}`)
  }

  getLauncher(param: string): Observable<any> {
    return this.http.get<any>(environment.apiURL + API_PATH.LAUNCHER + `${param}`)
  }

  getLauncherByPhone(param: string): Observable<any> {
    return this.http.get<any>(environment.apiURL + API_PATH.LAUNCHER_PHONE + `${param}`)
  }

  getCarBrand(): Observable<any> {
    return this.http.get<any>(environment.apiURL + API_PATH.CAR_BRANDS)
  }

  getCarSeri(brand: string): Observable<any> {
    return this.http.get<any>(environment.apiURL + API_PATH.CAR_SERIES + `/${brand}`)
  }

  getContract(): Observable<ITContract[]> {
    return this.http.get<ITContract[]>(environment.apiURL + API_PATH.CONTRACT)
  }

  // API So Sanh
  getCompareGoi(): Observable<ITCompareGoi[]> {
    return this.http.get<ITCompareGoi[]>(environment.apiURL + API_PATH.COMPARE_PACKAGES)
  }

  getKhoangGia(): Observable<ITKhoangGia[]> {
    return this.http.get<ITKhoangGia[]>(environment.apiURL + API_PATH.COMPARE_FEE_RANGE)
  }

  getCompareNcc(type: string): Observable<ITCompareNcc[]> {
    return this.http.get<ITCompareNcc[]>(environment.apiURL + API_PATH.COMPARE_PARTNERS, {
      params: {
        LoaiSanPham: type
      }
    })
  }

  getCompareProds(pack: string): Observable<ITCompareProd[]> {
    return this.http.get<ITCompareProd[]>(environment.apiURL + API_PATH.COMPARE_PRODUCTIONS, {
      params: {
        Goi: pack
      }
    })
  }

  getCompareProd(meta: string, pack: string): Observable<ITCompareProd> {
    return this.http.get<ITCompareProd>(environment.apiURL + API_PATH.COMPARE_PRODUCTIONS, {
      params: {
        MetaTitle: meta,
        Goi: pack
      }
    })
  }

  getComparePolicies(loai: string): Observable<ITComparePolicies[]> {
    return this.http.get<ITComparePolicies[]>(environment.apiURL + API_PATH.COMPARE_POLICIES, {
      params: {
        LoaiSanPham: loai
      }
    })
  }

  getComparePackPolicy(loai: string, ncc: string, goi: string): Observable<ITComparePackPolicy[]> {
    return this.http.get<ITComparePackPolicy[]>(environment.apiURL + API_PATH.COMPARE_QLGOI, {
      params: {
        LoaiSanPham: loai,
        NCC: ncc,
        Goi: goi
      }
    })
  }

  getCompareKhoangGia(
    loai: string,
    tuoi: number,
    gia: number,
    gioi: string,
    orderbyphi: string,
    limit: number,
    ncc: string,
    goi: string,
    pageNumber: number,
    pageSize: number
  ): Observable<ITCompareKhoangGia[]> {
    return this.http.get<ITCompareKhoangGia[]>(environment.apiURL + API_PATH.COMPARE_BY_FEE_RANGE, {
      params: {
        LoaiSanPham: loai,
        Tuoi: tuoi,
        KhoangGia: gia,
        GioiTinh: gioi,
        orderbyphi: orderbyphi,
        limit: limit,
        ncc: ncc,
        goi: goi,
        pageNumber: pageNumber,
        pageSize: pageSize
      }
    })
  }

  getCompareTuoi(): Observable<ITCompareTuoi[]> {
    return this.http.get<ITCompareTuoi[]>(environment.apiURL + API_PATH.COMPARE_AGES)
  }

  getColInfo(ma_nv: string): Observable<ITCol[]> {
    return this.http.get<ITCol[]>(environment.apiURL + API_PATH.GET_CTV + '/' + ma_nv)
  }
  postCol(ma_nv_login: string, ma_nv: string) {
    let data = {
      ma_nv: ma_nv,
      k_manager: ma_nv_login,
    }
    return this.http.post(environment.apiURL + API_PATH.UPDATE_K_MANAGER, data)
  }
}
