import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ChangeProductService {

  constructor() { }
  onChangeProductLangToEn(url:string){
    switch(url){
      case '/san-pham/bao-hiem-tnds-xe-o-to-bsh':{
        url = 'san-pham/bsh-civil-liability-insurance-for-cars'
        break;
      }
      case '/san-pham/bao-hiem-suc-khoe-a-care':{
        url = 'san-pham/a-care-health-insurance'
        break;
      }
      case '/san-pham/bao-hiem-tro-cap-nam-vien-bsh':{
        url = 'san-pham/bsh-hospital-allowance-insurance'
        break;
      }
      case '/san-pham/bao-hiem-tnds-xe-mo-to':{
        url = 'san-pham/civil-liability-insurance-for-motorbikes'
        break;
      }
      case '/san-pham/bao-hiem-tnds-xe-o-to':{
        url = 'san-pham/civil-liability-insurance-for-cars'
        break;
      }
      case '/san-pham/bao-hiem-mediplus':{
        url = 'san-pham/mediplus-insurance'
        break;
      }
      case '/san-pham/bao-hiem-mic-care':{
        url = 'san-pham/mic-care-insurance'
        break;
      }
      case '/san-pham/bao-hiem-suc-khoe':{
        url = 'san-pham/vbi-care-insurance'
        break;
      }
      case '/san-pham/bao-hiem-tai-nan-ca-nhan':{
        url = 'san-pham/personal-accident-insurance-gic'
        break;
      }
      case '/san-pham/bao-hiem-tai-nan-ca-nhan-bsh':{
        url = 'san-pham/personal-accident-insurance-bsh'
        break;
      }
    }
    return url
  }
  onChangeProductLangToVi(url:string){
    switch(url){
      case '/san-pham/bsh-civil-liability-insurance-for-cars':{
        url = 'san-pham/bao-hiem-tnds-xe-o-to-bsh'
        break;
      }
      case '/san-pham/a-care-health-insurance':{
        url = 'san-pham/bao-hiem-suc-khoe-a-care'
        break;
      }
      case '/san-pham/bsh-hospital-allowance-insurance':{
        url = 'san-pham/bao-hiem-tro-cap-nam-vien-bsh'
        break;
      }
      case '/san-pham/civil-liability-insurance-for-motorbikes':{
        url = 'san-pham/bao-hiem-tnds-xe-mo-to'
        break;
      }
      case '/san-pham/civil-liability-insurance-for-cars':{
        url = 'san-pham/bao-hiem-tnds-xe-o-to'
        break;
      }
      case '/san-pham/mediplus-insurance':{
        url = 'san-pham/bao-hiem-mediplus'
        break;
      }
      case '/san-pham/mic-care-insurance':{
        url = 'san-pham/bao-hiem-mic-care'
        break;
      }
      case '/san-pham/vbi-care-insurance':{
        url = 'san-pham/bao-hiem-suc-khoe'
        break;
      }
      case '/san-pham/personal-accident-insurance-gic':{
        url = 'san-pham/bao-hiem-tai-nan-ca-nhan'
        break;
      }
      case '/san-pham/personal-accident-insurance-bsh':{
        url = 'san-pham/bao-hiem-tai-nan-ca-nhan-bsh'
        break;
      }
    }
    return url
  }
}
