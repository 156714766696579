<section class="container">
    <div class="signContract">
        <h3>HỢP ĐỒNG DỊCH VỤ</h3>
        <div class="card" id="card1">
            <p style="text-align: center; font-weight: bold;">Độc lập - Tự do - Hạnh phúc</p>
            <p style="text-align: center; font-weight: bold;">-----****-----</p>
            <p style="text-align: center; font-weight: bold;">HỢP ĐỒNG HỢP TÁC BÁN HÀNG</p>
            <p style="text-align: center; font-weight: bold;">(Số: {{contractID}}/HĐDV)</p>
            <p>Hôm nay, ngày {{day}} <span class="Apple-converted-space"></span> tháng {{month}} năm {{year}} , chúng
                tôi
                gồm:
            </p>
            <p style="font-weight: bold;">Bên A: CÔNG TY CỔ PHẦN CÔNG NGHỆ BẢO HIỂM FINME</p>
            <ul style="list-style-position: outside; list-style-type: square;">
                <li>
                    <p>14-15A. Tầng 7 Toà nhà Charmvit, Số 17 Trần Duy Hưng, Phường Trung Hoà, Quận Cầu Giấy, Thành phố
                        Hà
                        Nội
                    </p>
                </li>
                <li>
                    <p>Điện thoại: (024) 7300 9669</p>
                </li>
                <li>
                    <p>Mã số thuế: 0107766291</p>
                </li>
                <li>
                    <p>Đại diện: Ông ĐỖ TRƯỜNG AN </p>
                </li>
                <li>
                    <p>Chức vụ: Tổng giám đốc</p>
                </li>
            </ul>
            <!-- <p *ngIf="isCollapsed0">
                <button type="button" class="btn btn-outline-primary" (click)="collapse0.toggle()"
                    [attr.aria-expanded]="!isCollapsed0" aria-controls="collapseExample">
                    Xem thêm
                </button>
            </p> -->
            <!-- <div #collapse0="ngbCollapse" [(ngbCollapse)]="isCollapsed0"> -->
                <p style="font-weight: bold;">Bên B: </p>
                <ul style="list-style-position: outside; list-style-type: square;">
                    <li>
                        <p>Ông (Bà): <span style="color: #ff0000;">{{name}}</span></p>
                    </li>
                    <li>
                        <p>Ngày sinh: <span style="color: #ff0000;">{{birthday}}</span></p>
                    </li>
                    <li>
                        <p>Số CMND / CCCD: <span style="color: #ff0000;">{{cardID}}</span></p>
                    </li>
                    <li>
                        <p>Mã số thuế: <span style="color: #ff0000;">{{tax}}</span></p>
                    </li>
                    <li>
                        <p>Địa chỉ thường trú: <span style="color: #ff0000;">{{address}}</span></p>
                    </li>
                    <!-- <li>
                    <p>Địa chỉ tạm trú: <span style="color: #ff0000;">{{temp_address}}</span></p>
                </li> -->
                    <li>
                        <p>Email:  <span style="color: #ff0000;">{{email}}</span></p>
                    </li>
                    <li>
                        <p>Số điện thoại: <span style="color: #ff0000;">{{phone}}</span></p>
                    </li>
                </ul>
                <p>
                    <span style="color: #ff0000;">
                        <em>(*) Những thông tin phải cung cấp</em>
                    </span>
                </p>
                <ul style="list-style-position: outside; list-style-type: square;">
                    <li>
                        <p>Trong Hợp đồng và các phụ lục kèm theo (nếu có), “FinMe”, hay còn gọi là “APP FinMe” <span
                                class="Apple-converted-space"></span>hoặc “Ứng dụng FinMe" được hiểu là phần mềm ứng
                            dụng tên
                            FinMe
                            chạy
                            trên điện thoại di động hệ điều hành Android và iOS do Bên A và/ hoặc đối tác của Bên A xây
                            dựng,
                            phát
                            triển; thông qua đó cung cấp phương tiện, công cụ và các hướng dẫn quy định bán hàng của Bên
                            A đối
                            với
                            Bên B
                            trong việc hợp tác bán hàng giữa hai bên. </p>
                    </li>
                    <li>
                        <p>Bên A và Bên B (sau đây còn được gọi là “Hai Bên” hoặc “Các Bên”) thỏa thuận trên cơ sở hoàn
                            toàn tự
                            nguyện
                            thống nhất ký kết Hợp đồng hợp tác bán hàng (gọi tắt là “Hợp đồng”) cùng các phụ lục hợp
                            đồng (nếu
                            có)
                            qua
                            hình thức online (trực tuyến) với những điều khoản như sau:</p>
                    </li>
                </ul>
                <p style="font-weight: bold;">Điều 1: Nội dung Hợp Đồng</p>
                <p>Bên A thuê Bên B và trả Phí bán hàng cho Bên B trên cơ sở kết quả các công việc mà Bên B thực hiện
                    như quy
                    định
                    tại
                    Điều 2 cùng các điều khoản và điều kiện khác quy định trong Hợp đồng cùng các văn bản kèm theo.</p>
                <p style="font-weight: bold;">Điều 2: Nội dung công việc của Bên B</p>
                <p>- Bên B thực hiện công việc bán các sản phẩm, dịch vụ của Bên A, hoặc của đối tác hợp tác với Bên A,
                    theo các
                    quy
                    trình, quy định và phương tiện bán hàng do Bên A cung cấp.</p>
                <p>- Các công việc khác theo yêu cầu của Bên A.</p>
                <p style="font-weight: bold;">Điều 3: Thời hiệu của Hợp Đồng:</p>
                <p>Hợp đồng này có hiệu lực từ ngày ký. Việc ký lại/gia hạn hợp đồng này tùy vào nhu cầu của Hai bên tại
                    từng
                    thời
                    điểm.
                </p>
                <p style="font-weight: bold;">Điều 4: Chế độ làm việc:</p>
                <p>4.1. Địa điểm: Tự do hoặc Bên B đồng ý theo địa điểm do Bên A phân công.</p>
                <p>4.2. Thời giờ làm việc: Tự do hoặc Bên B đồng ý sẽ làm việc theo thời gian do Bên A yêu cầu.</p>
                <p>4.3. Công cụ làm việc: Cấp phát theo từng chương trình/dự án của Bên A (nếu có)</p>
                <p>4.4. Phương tiện đi lại: Tự túc</p>
                <p style="font-weight: bold;">Điều 5: Phí bán hàng và quyền lợi của Bên B</p>
                <p>5.1. Phí bán hàng: Bên B được tính Phí bán hàng theo quy định/chính sách của Bên A theo từng thời kỳ.
                </p>
                <p>5.2. Bên B đồng ý đối với trường hợp Bên A không thanh toán, khấu trừ, tạm giữ, xử lý Phí bán hàng
                    của Bên B,
                    dựa
                    trên các quy định/ chính sách bán hàng được Bên A thông báo cho Bên B theo từng thời kỳ, thông qua
                    các
                    phương
                    tiện
                    bao gồm nhưng không giới hạn như: thư điện tử, ứng dụng FinMe</p>
                <p>5.3. Bên B đồng ý rằng ngoài khoản phí trên, Bên A sẽ không thanh toán cho Bên B bất kỳ khoản tiền
                    nào khác;
                    Đồng
                    thời Bên A thực hiện giữ lại các khoản thuế, phí theo quy định của pháp luật trên phần Phí bán hàng
                    Bên A
                    trả
                    cho
                    Bên B và thực hiện thủ tục liên quan đến việc nộp thuế cho Cơ quan Nhà nước có thẩm quyền thay cho
                    Bên B.
                    Bên A
                    sẽ
                    cung cấp các chứng từ liên quan đến việc khấu trừ các khoản thuế, phí đã nộp trên cho Cơ quan Nhà
                    nước khi
                    Bên B
                    có
                    yêu cầu.</p>
                <p>5.4. Hình thức trả Phí bán hàng: Chuyển khoản (qua FinMe)</p>
                <p>5.5. Bên A có toàn quyền thay đổi các quy định về Phí bán hàng bao gồm nhưng không giới hạn mức Phí
                    bán hàng,
                    điều
                    kiện hưởng Phí bán hàng, Bên A sẽ thông báo cho Bên B bằng văn bản hoặc thư điện tử.</p>
                <p>5.6. Bên B có trách nhiệm phải gửi cho Bên A bản chính của Hợp đồng này, hồ sơ cá nhân bao gồm chứng
                    minh
                    nhân
                    dân,
                    Hộ khẩu, Cam kết thuế, Sơ yếu lý lịch theo địa chỉ bên dưới.</p>
                <p style="font-weight: bold; font-style: italic;">Thông tin gửi hồ sơ:</p>
                <ul style="list-style: square;">
                    <li>
                        <p>Phòng nhân sự - Công ty Cổ phần Công Nghệ Bảo Hiểm Finme</p>
                    </li>
                    <li>
                        <p>Địa chỉ: 14-15A. Tầng 7 Toà nhà Charmvit, Số 17 Trần Duy Hưng, Phường Trung Hoà, Quận Cầu
                            Giấy, Thành
                            phố
                            Hà
                            Nội</p>
                    </li>
                </ul>
                <p style="font-weight: bold;">Điều 6: Quyền và nghĩa vụ của Bên A</p>
                <p>6.1. Quyền của Bên A</p>
                <ul style="list-style: square;">
                    <li>
                        <p>Yêu cầu Bên B thực hiện nội dung công việc quy định tại Điều 2 Hợp đồng này có hiệu quả.</p>
                    </li>
                    <li>
                        <p>Trong quá trình thực hiện hợp đồng nếu Bên B vi phạm bất kỳ các điều khoản nào của Hợp đồng
                            này hoặc
                            vi
                            phạm
                            quy định về Quy tắc ứng xử/ Quy tắc bảo mật thông tin hoặc không đạt chỉ tiêu của Bên A giao
                            thì Bên
                            A
                            có
                            quyền chấm dứt hợp đồng với Bên B mà không cần phải thông báo trước cho Bên B và cũng đồng
                            thời Bên
                            A sẽ
                            không thanh toán bất kỳ các khoản bồi thường hoặc chi phí nào cho Bên B.</p>
                    </li>
                    <li>
                        <p>Có quyền yêu cầu Bên B bồi thường các thiệt hại phát sinh từ việc Bên B không tuân thủ quy
                            định, quy
                            chế,
                            hướng dẫn làm việc của Bên A.</p>
                    </li>
                </ul>
                <p>6.2. Nghĩa vụ của Bên A:</p>
                <ul style="list-style: square;">
                    <li>
                        <p>Thanh toán Phí bán hàng cho Bên B theo nội dung của hợp đồng hoặc theo từng phụ lục hợp đồng.
                        </p>
                    </li>
                    <li>
                        <p>Tạo điều kiện để Bên B thực hiện công việc được thuận lợi nhất.</p>
                    </li>
                    <li>
                        <p>Khấu trừ và nộp thuế thu nhập cá nhân (nếu có) đối với khoản Phí bán hàng thanh toán cho Bên
                            B theo
                            đúng
                            quy
                            định pháp luật.</p>
                    </li>
                </ul>
                <p style="font-weight: bold;">Điều 7: Quyền và nghĩa vụ của Bên B</p>
                <p>7.1. Quyền của Bên B</p>
                <ul style="list-style: square;">
                    <li>
                        <p>Được trả Phí bán hàng theo thỏa thuận tại Hợp đồng này.</p>
                    </li>
                    <li>
                        <p>Được hưởng các chế độ đãi ngộ khác theo quy định của Bên A.</p>
                    </li>
                </ul>
                <p>7.2. Nghĩa vụ của Bên B</p>
                <ul style="list-style: square;">
                    <li>
                        <p>Thực hiện công việc được thỏa thuận trong Hợp đồng này hoặc các phụ lục Hợp đồng.</p>
                    </li>
                    <li>
                        <p>Tuân thủ mọi quy định và quy tắc ứng xử của Bên A trong quá trình triển khai công việc hợp
                            tác giữa
                            Hai
                            bên,
                            bao gồm nhưng không giới hạn các quy định, quy tắc ứng xử được Bên A quy định trong Hợp đồng
                            này và
                            phụ
                            lục
                            đi kèm (nếu có), những thay đổi, bổ sung được thông báo bằng qua thư điện tử (email), thông
                            báo qua
                            FinMe,
                            bằng website: <a href="https://finme.vn">https://finme.vn</a> , hoặc các kênh liên lạc khác.
                        </p>
                    </li>
                    <li>
                        <p>Tuân thủ triệt để các quy định về bảo mật thông tin dữ liệu nội bộ, không được tiết lộ ra
                            ngoài dưới
                            mọi
                            hình
                            thức. Không được tiết lộ cung cấp thông tin thiệt hại đến lợi ích của Bên A.</p>
                    </li>
                    <li>
                        <p>Không được sử dụng hình ảnh, tài liệu liên quan của Bên A vào các mục đích khác nếu không
                            được sự
                            đồng ý
                            của
                            Bên A.</p>
                    </li>
                    <li>
                        <p>Bồi thường cho Bên A mọi thiệt hại phát sinh do vi phạm các nghĩa vụ, trách nhiệm theo quy
                            định của
                            Hợp
                            đồng
                            này.</p>
                    </li>
                    <li>
                        <p>Có nghĩa vụ nộp thuế TNCN theo quy định thuế hiện hành.</p>
                    </li>
                </ul>
                <p style="font-weight: bold;">Điều 8: Chấm dứt hợp đồng</p>
                <p>8.1. Chấm dứt Hợp đồng</p>
                <p>Hợp đồng này chấm dứt trong các trường hợp sau đây:</p>
                <ul style="list-style: square;">
                    <li>
                        <p>Hợp đồng hết hạn theo quy định của Điều 3 Hợp đồng này, nhưng các bên không có nhu cầu gia
                            hạn Hợp
                            đồng.
                        </p>
                    </li>
                    <li>
                        <p>
                            Trong bất kỳ thời điểm nào, nếu theo đánh giá của Bên A, Bên B không đáp ứng được nhu cầu
                            công việc
                            được
                            giao hoặc thực hiện công việc trái với quy định của Bên A.
                        </p>
                    </li>
                    <li>Theo sự thỏa thuận giữa 2 bên.</li>
                </ul>
                <p>8.2 Giải quyết khi chấm dứt hợp đồng</p>
                <ul style="list-style: square;">
                    <li>
                        <p>Bên B có trách nhiệm bàn giao toàn bộ các tài sản, công cụ, phương tiện, vật dụng, hồ sơ, tài
                            liệu,
                            giấy
                            tờ
                            khác của Bên A trong quá trình thực hiện công việc theo Hợp đồng này trước khi chấm dứt Hợp
                            đồng này
                            (nếu
                            có).</p>
                    </li>
                    <li>
                        <p>Hai bên có trách nhiệm hoàn tất đúng và đủ các nghĩa vụ tài chính phát sinh giữa Hai Bên
                            trong quá
                            trình
                            thực
                            hiện công việc theo Hợp đồng này trước khi chấm dứt Hợp đồng.</p>
                    </li>
                    <li>
                        <p>Bên A có quyền không thanh toán một phần hoặc toàn bộ Phí bán hàng Bên B trong những trường
                            hợp vi
                            phạm
                            Hợp
                            đồng này, và Bên B cam kết không có khiếu nại, khiếu kiện nào liên quan đến quy định này.
                        </p>
                    </li>
                </ul>
                <p style="font-weight: bold;">Điều 9: Điều khoản chung</p>
                <p>9.1. Hợp đồng này được chuẩn bị theo mẫu chung. Các điều khoản và điều kiện giao dịch điện tử được
                    đăng tại
                    website http://finme.vn, ứng dụng di động finme, các phụ lục hợp đồng (nếu có), các thông báo/hướng
                    dẫn/quy
                    trình/quy định/quy tắc Bên A cung cấp cho Bên B (bao gồm cung cấp trực tiếp hoặc qua các phương tiện
                    thông
                    tin
                    phù
                    hợp có ký nhận hoặc không ký nhận) là bộ phận không tách rời Hợp đồng này.</p>
                <p>9.2. Mọi tranh chấp giữa 02 bên có liên quan đến Hợp đồng này sẽ được giải quyết bởi Cơ quan Nhà nước
                    có thẩm
                    quyền
                    của Việt Nam.</p>
                <p>9.3 Hợp đồng này gồm 02 bản có giá trị pháp lý như nhau mỗi bên giữ 01 bản. Hai bên thống nhất Hợp
                    đồng này
                    được
                    ký
                    với phương thức giao dịch điện tử/chữ ký số, không đóng dấu của DGP.</p>
                <div style="display: flex; flex-direction: row; width: 100%;">
                    <div style="flex: 1; display: flex; align-items: center; flex-direction: column;">
                        <p style="text-align: center; font-weight: bold;">BÊN A</p>
                    </div>
                    <div style="flex: 1; display: flex; align-items: center; flex-direction: column;">
                        <p style="text-align: center; font-weight: bold;">BÊN B</p>
                        <br><br><br><br>
                        <!-- <img style="width: 100px; height: 50px;" src="" /> -->
                    </div>
                </div>
            <!-- </div> -->
            <!-- <p *ngIf="!isCollapsed0">
                <button type="button" class="btn btn-outline-primary" (click)="collapse0.toggle()"
                    [attr.aria-expanded]="!isCollapsed0" aria-controls="collapseExample">
                    Thu gọn
                </button>
            </p> -->
        </div>

        <!-- Policy -->
        <h3>QUY TẮC BẢO MẬT THÔNG TIN</h3>
        <div class="card" id="card2">
            <!-- <p style="font-weight: bold;">QUY TẮC BẢO MẬT THÔNG TIN</p> -->
            <!-- <p *ngIf="isCollapsed">
                <button type="button" class="btn btn-outline-primary" (click)="collapse.toggle()"
                    [attr.aria-expanded]="!isCollapsed" aria-controls="collapseExample">
                    Xem thêm
                </button>
            </p> -->
            <!-- <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed"> -->
                <p style="font-weight: 500; font-size: 16px; font-style: italic; text-decoration: underline;">Định
                    nghĩa
                </p>
                <ul style="list-style-type: square; list-style-position: outside;">
                    <li>
                        <p>Mục Đích Kinh Doanh” có ý nghĩa như được quy định tại phần căn cứ</p>
                    </li>
                    <li>
                        <p>
                            Thông Tin Bảo Mật” có nghĩa là tất cả thông tin dù là về thương mại, tài chính, kỹ thuật hay
                            thông tin khác, bao gồm nhưng không giới hạn mọi bí mật hay thông tin bảo mật dưới mọi hình
                            thức
                            của Bên Tiết Lộ Thông Tin, cùng với mọi bí quyết, chương trình tin học, kỹ công nghệ, bí mật
                            kinh doanh và kế hoạch kinh doanh, chi tiết khách hàng, các bản phân tích, tài liệu sưu tập,
                            dữ
                            liệu, đề tài nghiên cứu hoặc các tài liệu khác do Bên Tiếp Nhận Thông Tin tạo ra từ hoặc
                            liên
                            quan đến các thông tin trên; hoặc chứa đựng; hoặc về cơ bản toàn bộ hoặc một phần dựa trên
                            các
                            thông tin trên mà thực tế là các thông tin đó đã được cung cấp, cũng như các cuộc thảo luận
                            liên
                            quan đến Mục Đích Kinh Doanh đang được thực hiện và Thỏa thuận này đã được thông qua.
                        </p>
                    </li>
                    <li>
                        <p>Bên Tiết Lộ Thông Tin” có nghĩa là bên tiết lộ Thông Tin Bảo Mật</p>
                    </li>
                    <li>
                        <p>Bên Tiếp Nhận Thông Tin” có nghĩa là bên nhận được Thông Tin Bảo Mật.</p>
                    </li>
                </ul>
                <p style="font-weight: 500; font-size: 16px; font-style: italic; text-decoration: underline;">Tiết lộ
                    thông tin bảo mật</p>
                <p>2.1. Bên Tiếp Nhận Thông Tin cam kết đối với các Thông Tin Bảo Mật như sau:</p>
                <ul style="list-style-type: lower-alpha; list-style-position: inherit;">
                    <li>
                        <p>Giữ bí mật đối với những Thông Tin Bảo Mật do Bên Tiết Lộ Thông Tin cung cấp;</p>
                    </li>
                    <li>
                        <p>Không tiết lộ bất kỳ phần nào của Thông Tin Bảo Mật cho bất kỳ người nào mà không có sự đồng
                            ý
                            trước bằng văn bản cho mỗi trường hợp tiết lộ, ngoại trừ:</p>
                        <ul
                            style="list-style-type: square; list-style-position: inherit; padding-left: 15px !important;">
                            <li>
                                <p>Chỉ những nhân viên của Bên Tiếp Nhận Thông Tin hoặc của nhóm công ty của Bên Tiếp
                                    Nhận
                                    Thông Tin được tuyển dụng cho các bộ phận quản lý rủi ro, tín dụng, kiểm toán, pháp
                                    chế
                                    và / hoặc bộ phận tuân thủ pháp luật có liên quan đến Mục Đích Kinh Doanh được tiếp
                                    nhận
                                    Thông Tin Bảo Mật trên cơ sở cần phải biết;</p>
                            </li>
                            <li>
                                <p>Kiểm toán viên, nhà tư vấn nghiệp vụ và bất kỳ người hoặc bộ phận nào của Bên Tiếp
                                    Nhận
                                    Thông Tin có quyền hoặc nghĩa vụ hợp pháp được truy cập hoặc được biết về các Thông
                                    Tin
                                    Bảo Mật liên quan đến công việc kinh doanh của Bên Tiếp Nhận Thông Tin;</p>
                            </li>
                            <li>
                                <p>Bên Tiếp Nhận Thông Tin nhận được lệnh của một tòa án có thẩm quyền hoặc theo nghĩa
                                    vụ
                                    pháp luật phải tiết lộ Thông Tin Bảo Mật, với điều kiện rằng Bên Tiếp Nhận Thông Tin
                                    sẽ
                                    sử dụng mọi cố gắng hợp lý để thông báo bằng văn bản cho Bên Tiết Lộ Thông Tin trước
                                    khi
                                    tiết lộ thông tin theo lệnh hoặc nghĩa vụ nói trên;</p>
                            </li>
                            <li>
                                <p>Các bên thứ ba giao kết hợp đồng với Bên Tiếp Nhận Thông Tin có liên quan đến Mục
                                    Đích
                                    Kinh Doanh và đã được ủy quyền rõ ràng bằng văn bản bởi Bên Tiết Lộ Thông Tin để
                                    nhận
                                    Thông Tin Bảo Mật trước khi tiết lộ;</p>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <p>Phải đảm bảo rằng mọi cá nhân, bộ phận được đề cập tại khoản b, trước khi tiết lộ các Thông
                            Tin
                            Bảo Mật, phải biết về tính bảo mật của thông tin mà họ có trách nhiệm bảo mật đối với Bên
                            Tiết
                            Lộ Thông Tin và đồng ý giữ bí mật các Thông Tin Bảo Mật theo các điều khoản của Thỏa Thuận
                            này,
                            và (b) sử dụng mọi cố gắng hợp lý để đảm bảo rằng những đối tượng này sẽ tuân thủ các nghĩa
                            vụ
                            nói trên;</p>
                    </li>
                    <li>
                        <p>Phải sử dụng mọi cố gắng hợp lý để thực hiện và duy trì đầy đủ các biện pháp an ninh để bảo
                            vệ
                            Thông Tin Bảo Mật khỏi sự truy cập, sử dụng trái phép và chiếm dụng các Thông Tin Bảo Mật.
                        </p>
                    </li>
                </ul>
                <p>2.2. Bên Tiếp Nhận Thông Tin sẽ không sử dụng, tận dụng, khai thác hoặc dưới bất kỳ cách thức nào
                    khác sử
                    dụng các Thông Tin Bảo Mật ngoài Mục Đích Kinh Doanh mà không có sự chấp thuận bằng văn bản của Bên
                    Tiết
                    Lộ Thông Tin.</p>
                <p style="font-weight: 500; font-size: 16px; font-style: italic; text-decoration: underline;">Nguyên
                    tắc
                    cẩn trọng</p>
                <p>3.1. Tất cả các tài liệu có chứa Thông Tin Bảo Mật cung cấp bởi hoặc thu được từ Bên Tiết Lộ, bao gồm
                    nhưng không giới hạn, các băng từ, tài liệu, hướng dẫn, chi tiết kỹ thuật, biểu đồ tiến trình, danh
                    sách
                    các chương trình và dữ liệu tập tin in sẵn ("Tài Liệu"), là và sẽ được duy trì là tài sản của Bên
                    Tiết
                    Lộ và không được sao chép toàn bộ hoặc một phần mà không có sự chấp thuận rõ ràng bằng văn bản của
                    Bên
                    Tiết Lộ Thông Tin. Tất cả các bản sao của Tài Liệu sẽ trở thành tài Sản của Bên Tiết Lộ Thông Tin và
                    có
                    quyền tác giả cũng như các quyền tài sản như đối với bản chính.</p>
                <p>3.2. Bên Tiết Lộ Thông Tin có thể gửi thông báo bằng văn bản vào bất kỳ lúc nào yêu cầu hoàn trả lại
                    hoặc
                    tiêu hủy bất kỳ phần nào của Thông Tin Bảo Mật hoặc bản sao của nó được tiết lộ theo Thoả Thuận này,
                    và
                    việc hoàn trả hoặc tiêu huỷ sẽ được kết hợp với một thông báo gửi đến Bên Tiết Lộ Thông Tin với ý
                    nghĩa
                    rằng với việc hoàn trả hoặc tiêu huỷ như vậy, Bên Tiếp Nhận Thông Tin đã không cố tình sở hữu hoặc
                    kiểm
                    soát, trực tiếp hay gián tiếp, bất kỳ Thông Tin Bảo Mật hoặc các bản sao nào của nó, và Bên Tiếp
                    Nhận
                    Thông Tin phải tuân thủ yêu cầu trên trong vòng 03 (ba) ngày kể từ ngày nhận được yêu cầu.</p>
                <p>3.3. Bên Tiếp Nhận Thông Tin phải bảo vệ Thông Tin Bảo Mật của Bên Tiết Lộ Thông Tin và, trong khi
                    thực
                    hiện điều này, Bên Tiếp Nhận Thông Tin phải áp dụng mức độ cẩn trọng cao nhất như là áp dụng đối với
                    các
                    thông tin tài sản và thông tín bí mật của chính mình.</p>
                <p>3.4. Bên Tiếp Nhận Thông Tin sẽ sử dụng mọi biện pháp bảo vệ hợp lý để ngăn ngừa việc tiết lộ trái
                    phép
                    các Thông Tin Bảo Mật và sẽ bảo đảm rằng tất cả các nhân viên và nhà tư vấn nghiệp vụ, là người có
                    quyền
                    truy cập vào các Thông Tin Bảo Mật, cũng tuân thủ các điều khoản cam kết này như thể họ là một bên
                    trong
                    Thỏa Thuận.</p>
                <p>3.5. Nếu Bên Tiếp Nhận Thông Tin không tuân thủ các điều khoản đặt ra trong Thỏa Thuận này, Bên Tiết
                    Lộ
                    Thông Tin có quyền yêu cầu Bên Tiếp Nhận Thông Tin bồi thường cho Bên Tiết Lộ Thông Tin mọi chi phí,
                    phí
                    tổn và thiệt hại phát sinh.</p>
                <p style="font-weight: 500; font-size: 16px; font-style: italic; text-decoration: underline;">Thông tin
                    được loại trừ</p>
                <p style="color: red; font-style: italic;">Các nghĩa vụ theo Quy tắc này không áp dụng đối với bất kỳ
                    Thông
                    Tin Bảo Mật nào mà:</p>
                <p>4.1. Thuộc quyền sở hữu hợp pháp của Bên Tiếp Nhận Thông Tin trước khi nhận được từ Bên Tiết Lộ Thông
                    Tin;</p>
                <p>4.2. Đã được công khai hoặc trở thành công khai, trừ trường hợp là kết quả của việc vi phạm Thỏa
                    Thuận;
                </p>
                <p>4.3. Được khai thác một cách độc lập bởi Bên Tiếp Nhận Thông Tin</p>
                <p>4.4. Được tiết lộ bởi Bên Tiếp Nhận Thông Tin để đáp ứng đòi hỏi, hoặc theo yêu cầu của tòa án có
                    thẩm
                    quyền, của cơ quan chính phủ hoặc cơ quan điều hành, với điều kiện rằng trong trường hợp này, Bên
                    Tiếp
                    Nhận Thông Tin sẽ thông báo bằng văn bản cho Bên Tiết Lộ sau đó ngay khi có thể và sử dụng nỗ lực
                    cao
                    nhất của mình để bảo đảm xử lý bí mật các Thông Tin Bảo Mật buộc phải tiết lộ;</p>
                <p>4.5. Được tiết lộ cho một bên thứ ba theo văn bản uỷ quyền từ Bên Tiết Lộ Thông Tin; hoặc</p>
                <p>4.6. Nhận được từ một bên thứ ba mà không có vi phạm về các nghĩa vụ giữ bí mật khác có liên quan.
                </p>
                <p style="font-weight: 500; font-size: 16px; font-style: italic; text-decoration: underline;">Mối liên
                    hệ
                    giữa các bên</p>
                <ul style="list-style-type: square; list-style-position: outside;">
                    <li>
                        <p>Không quy định nào trong Quy tắc này là nhằm, hoặc được xem là sẽ tạo thành bất kỳ hợp danh
                            hoặc
                            liên doanh nào giữa các bên, hay bên này làm đại lý của bên kia, hoặc ủy quyền cho bất kỳ
                            bên
                            nào thay mặt và đại diện cho bên kia thực hiện bất cứ cam kết nào.</p>
                    </li>
                </ul>
                <p style="font-weight: 500; font-size: 16px; font-style: italic; text-decoration: underline;">Không
                    chuyển
                    giao quyền, giấy phép hoặc bảo đảm</p>
                <ul style="list-style-type: square; list-style-position: outside;">
                    <li>
                        <p>Không một giấy phép nào liên quan đến thương hiệu, nhãn hiệu dịch vụ, quyền sang chế, bản
                            quyền
                            hay quyền sở hữu trí tuệ được cấp hoặc gợi ý cấp bởi Bên Tiết Lộ Thông Tin. Không bên nào
                            được
                            quyền lợi dụng mối quan hệ kinh doanh song phương giữa các bên cho mục đích quảng cáo của
                            mình.
                        </p>
                    </li>
                    <li>
                        <p>Các bên trong Quy tắc này sẽ không hoặc cho phép bên kia đề cập đến Thông Tin Mật hay sử dụng
                            tên
                            của một bên trong các thông báo công khai hay các tài liệu khuyến mại, tiếp thị hoặc bán
                            hàng mà
                            không có sự đồng ý trước bằng văn bản của bên kia.</p>
                    </li>
                </ul>
                <p style="font-weight: 500; font-size: 16px; font-style: italic; text-decoration: underline;">Chấm dứt
                    hiệu lực quy tắc</p>
                <ul style="list-style-type: square; list-style-position: outside;">
                    <li>
                        <p>Không phụ thuộc vào thời hiệu của Hợp đồng bên dưới, Quy tắc này sẽ có hiệu lực đầy đủ trong
                            vòng
                            05 (năm) năm kể từ ngày ký (theo ngày ký của Hợp đồng Hợp tác bán hàng) và chấm dứt ngay khi
                            hết
                            hạn, hoặc sớm hơn, phụ thuộc vào việc một bên thông báo bằng văn bản cho bên kia trước một
                            tháng
                            về việc chấm dứt, với điều kiện là việc chấm dứt này hoặc việc Mục Tiêu Kinh Doanh đã hoàn
                            thành
                            sẽ không ảnh hưởng đến nghĩa vụ giữ bí mật thông tin trong thời gian 05 (năm) năm kể từ ngày
                            chấm dứt.</p>
                    </li>
                </ul>
                <p style="font-weight: 500; font-size: 16px; font-style: italic; text-decoration: underline;">Luật
                    điều
                    chỉnh</p>
                <ul style="list-style-type: square; list-style-position: outside;">
                    <li>
                        <p>Quy tắc này được điều chỉnh và diễn giải theo pháp luật của Việt Nam. Các bên đồng ý rằng bất
                            kỳ
                            hành động pháp lý hay vụ kiện nào phát sinh từ hoặc liên quan đến Quy tắc này sẽ được giải
                            quyết
                            tại tòa án có thẩm quyền tại Việt Nam.</p>
                    </li>
                </ul>
            <!-- </div> -->
            <!-- <p *ngIf="!isCollapsed">
                <button type="button" class="btn btn-outline-primary" (click)="collapse.toggle()"
                    [attr.aria-expanded]="!isCollapsed" aria-controls="collapseExample">
                    Thu gọn
                </button>
            </p> -->
        </div>

        <div style="margin-top: 10px;">
            <div class="typeofclient">
                <input type="checkbox" id="pers" name="isPerson" [value]="isChecked" [(ngModel)]="isChecked" />Tôi đồng
                ý với Các điều khoản và Điều kiện.
            </div>
            <div style="display: flex;justify-content: center; margin-top: 15px;">
                <button class="btn btn-primary" [disabled]="!isChecked" (click)="onClickToSign()">Ký hợp đồng</button>
            </div>
        </div>
    </div>
</section>