import { Component, OnInit } from '@angular/core';
import { ITVouchers } from '@models/voucher.model';
import { ToastService } from '@services/toast.service';
import { VoucherService } from '@services/voucher/voucher.service';

@Component({
  selector: 'app-voucher-manager',
  templateUrl: './voucher-manager.component.html',
  styleUrls: ['./voucher-manager.component.css']
})
export class VoucherManagerComponent implements OnInit {
  vouchers: ITVouchers[] = []
  constructor(
    private _service: VoucherService,
    public _toast: ToastService,
  ) { }

  ngOnInit(): void {
    this.getVouchers()
  }

  getVouchers(): void {
    this._service.getVouchers().subscribe(data => {
      this.vouchers = data;
    })
  }

  onSort() {
  }

  onDelete(id: number): void {
    this._service.deleteVoucherByID(id).subscribe(data => {
      if (data) {
        this.getVouchers()
        this._toast.show('Xóa voucher thành công !', { icon: 'check_circle', classname: 'bg-success text-light' })
      } else {
        this._toast.show('Xóa voucher thất bại !', { icon: 'error', classname: 'bg-danger text-light' });
      }
    })
  }

}
