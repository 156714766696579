import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-step-controller',
  templateUrl: './stepcontroller.component.html',
  styleUrls: ['./stepcontroller.component.css']
})
export class StepcontrollerComponent implements OnInit {
  @Input() steps:any[] = []
  @Input() step:number = 25
  @Input() stepValue:number = 1
  @Input() isWebview:boolean = false

  constructor() { }

  ngOnInit(): void {
  }

}
