<app-form-title [label]="'Thông tin phí bảo hiểm'"></app-form-title>
<hr>
<div class="last-step--label">
  <span>Phí:</span>
  <span>{{fee.phi}} VND</span>
</div>
<div class="last-step--label">
  <span>Thuế:</span>
  <span>{{fee.thue}} VND</span>
</div>
<hr>
<div class="flex">
  <img class="form__payment--thumb" src="../../../assets/frontend/images/coupon.png" alt="voucher">
  <span class="form__payment--voucher">Voucher:</span>
</div>
<div class="flex space">
  <div class="text-field launcher">
    <label for="launcher">Mã giới thiệu</label>
    <input autocomplete="off" type="text" id="launcher" name="launcher" placeholder="A0000001"
      [value]="magioithieu" [(ngModel)]="magioithieu"
      (ngModelChange)="magioithieu.length === 9 ? getLauncher(magioithieu) : getEmptyLauncher()" />
  </div>
  <div class="text-field maVoucher">
    <label for="maVoucher">Mã Voucher</label>
    <input autocomplete="off" type="text" id="maVoucher" name="maVoucher" placeholder="Nhập mã voucher"
      [value]="voucher.maVoucher" [(ngModel)]="voucher.maVoucher"
      (ngModelChange)="voucher.maVoucher.length === 21 ? getDiscount(voucher.maVoucher) : getOldFee()" />
    <div class="invalid">
      <small *ngIf="voucher.maVoucher.length === 21 && voucher.status === false">{{error.voucher}}</small>
    </div>
  </div>
</div>
<div *ngIf="nguoiGioiThieu !== ''">
  <span>Người giới thiệu:</span>
  <span>{{nguoiGioiThieu}}</span>
</div>
<div class="last-step--label">
  <span>Giảm giá:</span>
  <span>- {{save}} VND</span>
</div>
<div class="last-step--label" style="font-weight: 500;">
  <span>Tổng phí:</span>
  <span>{{fee.tongphi}} VND</span>
</div>
<hr>
<!-- <span class="form__payment" (click)="onSubmit(f, validWarming)">Thanh toán</span> -->
