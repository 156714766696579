<div class="compare">
  <div class="container">

    <!-- <div class="desciption">
      <p>Bảo hiểm phi nhân thọ là bảo hiểm tự nguyện giúp khách hàng yên tâm hơn về sức khỏe, tai nạn, tài sản, hàng
        hóa (kho vận, tàu xe, tàu hàng,…), nhằm đảm bảo cho các rủi ro đó. Mỗi hợp đồng bảo hiểm có thời hạn trung
        bình từ 1 đến 2 năm hoặc ngắn hơn. <br> Việc so sánh các bảo hiểm phi nhân thọ giúp khách hàng có thể lựa chọn
        sản phẩm nhanh nhất, sát với mong muốn của khách hàng nhất. Khách hàng khi mua hàng trên finme.vn sẽ có cái
        nhìn tổng quan về các sản phẩm. Dễ dàng tìm thấy sản phẩm phù hợp hơn thông qua tính năng so sánh sản phẩm.
      </p>
    </div> -->

    <div class="slide-carousel" *ngIf="isToggle">
      <ngx-skeleton-loader *ngIf="slides.length === 0" count="10" [theme]="{
        margin: '0 10px 16px 0',
        width: '150px',
        height: '54px',
        'border-radius': '0.5rem'
      }"></ngx-skeleton-loader>
      <div class="mobile-hidden">
        <ngb-carousel [showNavigationIndicators]="false" [showNavigationArrows]="false">
          <ng-template ngbSlide *ngFor="let slide of slides">
            <img class="compare__thumb" src="{{slide.image}}">
          </ng-template>
        </ngb-carousel>
      </div>
      <div class="desktop-hidden">
        <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig"
          (init)="slickInit($event)" (afterChange)="afterChange($event)" (beforeChange)="beforeChange($event)">
          <div ngxSlickItem *ngFor="let slide of slides" class="slide">
            <div class="slide-carousel-card">
              <img class="slide__img" src="{{slide.image}}">
            </div>
          </div>
        </ngx-slick-carousel>
      </div>
    </div>

    <div class="compare__top" ngClass="isToggle ? 'radius' : 'unset-radius'">
      <div class="">
        <h1 class="compare__head">{{ 'COMPARE.HEAD' | translate }}</h1>
        <p class="compare__description">
          {{ 'COMPARE.SUB_HEAD' | translate }}
        </p>
      </div>

      <ng-container *ngIf="!isToggle">
        <div class="">
          <ngx-skeleton-loader *ngIf="categories.length === 0" count="3" [theme]="{
            margin: '0 10px 0 20px',
            width: innerWidth < 640 ? '50px' : '210px',
            height: '75px',
            'border-radius': '0.5rem',
            'border': '0'
          }"></ngx-skeleton-loader>
          <ng-container *ngFor="let cate of categories">
            <button class="compare__btn" (click)="onToggle(cate.name)" *ngIf="cate.id < 4">
              <img src="../../../assets{{ cate.icon }}" [alt]="cate.name">
              <span>{{ cate.name }}</span>
            </button>
          </ng-container>
        </div>
        <ngx-skeleton-loader *ngIf="categories.length === 0" count="1" [theme]="{
          'margin': '10px auto 0',
          'width': '70%',
          'height': '55px',
          'display': 'grid',
          'grid-template-columns': '1fr 1fr 1fr 1fr 1fr 1fr',
          'border-radius': '0.5rem'
          }"></ngx-skeleton-loader>
        <div class="compare-btn__list">
          <ng-container *ngFor="let cate of categories">
            <button class="compare__btn-item" (click)="onToggle(cate.name)">
              <img src="../../../assets{{ cate.icon }}" [alt]="cate.name">
              <span>{{ 'COMPARE.INSURANCE' | translate }} {{ cate.name }}</span>
            </button>
          </ng-container>
        </div>
      </ng-container>

      <form #f="ngForm" *ngIf="isToggle">
        <div class="compare-form__input">
          <div class="compare-form__input--left">
            <div class="text-field compare-field">
              <label for="LoaiSanPham">{{ 'COMPARE.TYPE_PROD' | translate }}</label>
              <input name="LoaiSanPham" id="LoaiSanPham" readonly [(ngModel)]="compare.LoaiSanPham">
              <div class="invalid"></div>
            </div>
            <div class="text-field compare-field">
              <label for="gender">{{ 'COMMON.GENDER' | translate }}<span class="required">*</span></label>
              <select required name="gender" id="gender" [(ngModel)]="compare.GioiTinh">
                <option value="" selected disabled>{{ 'PRODUCT.SELECT_GENDER' | translate }}...</option>
                <option *ngFor="let opt of genders" [value]="opt.value">{{opt.label|translate}}</option>
              </select>
              <div class="invalid">
                <small *ngIf="f.controls['gender']?.invalid && f.controls['gender']?.touched">{{error.select|translate}}</small>
              </div>
            </div>
          </div>
          <div class="compare-form__input--right">
            <div class="text-field compare-field">
              <label for="range">{{ 'COMPARE.FEE_RANGE' | translate }}<span class="required">*</span></label>
              <select required name="range" id="range" [(ngModel)]="compare.KhoangGia">
                <option value="" selected disabled>{{ 'COMPARE.SELECT_FEE_RANGE' | translate }}...</option>
                <option *ngFor="let opt of feeRanges" [value]="opt.id">{{opt.ten}}</option>
              </select>
              <div class="invalid">
                <small *ngIf="f.controls['range']?.invalid && f.controls['range']?.touched">{{error.select|translate}}</small>
              </div>
            </div>
            <div class="text-field compare-field">
              <label for="tuoi">{{ 'COMPARE.AGE' | translate }}<span class="required">*</span></label>
              <select required name="tuoi" id="tuoi" [(ngModel)]="compare.Tuoi">
                <option value="" selected disabled>{{ 'COMPARE.SELECT_AGE' | translate }}...</option>
                <option *ngFor="let opt of tuois" [value]="opt.id">{{opt.label|translate}}</option>
              </select>
              <div class="invalid">
                <small *ngIf="f.controls['tuoi']?.invalid && f.controls['tuoi']?.touched">{{error.select|translate}}</small>
              </div>
            </div>
          </div>
        </div>
        <div class="compare-detail__btn-group">
          <button type="submit" (click)="onSubmit(f, checkValid)">
            {{ 'COMMON.NEXT' | translate }}
          </button>
          <!-- <button class="btn btn-outline-dark" type="reset" (click)="onClear()">
            Nhập lại
          </button> -->
        </div>
      </form>
    </div>

    <div class="slide-carousel" *ngIf="!isToggle">
      <ngx-skeleton-loader *ngIf="slides.length === 0" count="10" [theme]="{
        margin: '0 10px 16px 0',
        width: '150px',
        height: '54px',
        'border-radius': '0.5rem'
      }"></ngx-skeleton-loader>
      <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="carouselConfig"
        (init)="slickInit($event)" (breakpoint)="breakpoint($event)" (afterChange)="afterChange($event)"
        (beforeChange)="beforeChange($event)">
        <div ngxSlickItem *ngFor="let slide of slides" class="slide">
          <div class="slide-carousel-card">
            <img class="slide__img" src="{{slide.image}}">
          </div>
        </div>
      </ngx-slick-carousel>
    </div>

    <div class="ourpartner" *ngIf="!isToggle">
      <p class="ourpartner__head">{{ 'COMPARE.DEPARTMENT_HEAD' | translate }}</p>
      <p class="ourpartner__child-head">
        {{ 'COMPARE.DEPARTMENT_DESC' | translate }}
      </p>
      <div class="compare__partners">
        <img *ngFor="let partner of ourPartners" src="{{ partner.image }}" alt="{{ partner.name }}"
          class="compare__partner" />
      </div>
    </div>

    <div class="compare__review" *ngIf="!isToggle">
      <p class="compare__review__head">{{ 'COMPARE.DEPARTMENT_QUESTION' | translate }}</p>
      <div class="compare__review__body">
        <div class="compare__review__content">
          <img class="compare__review__img" src="../../../assets/Upload/images/icon/a (8).png">
          <div class="compare__review__text">
            <p>{{ 'COMPARE.1' | translate }}</p>
            <span>{{ 'COMPARE.2' | translate }}</span>
          </div>
        </div>
        <div class="compare__review__content">
          <img class="compare__review__img" src="../../../assets/Upload/images/icon/a (3).png">
          <div class="compare__review__text">
            <p>{{ 'COMPARE.3' | translate }}</p>
            <span>{{ 'COMPARE.4' | translate }}</span>
          </div>
        </div>
        <div class="compare__review__content">
          <img class="compare__review__img" src="../../../assets/Upload/images/icon/a (7).png">
          <div class="compare__review__text">
            <p>{{ 'COMPARE.5' | translate }}</p>
            <span>{{ 'COMPARE.6' | translate }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="compare__info" *ngIf="!isToggle">
      <div class="compare__info__body">
        <div class="compare__info__count">
          <div class="count">
            <font style="vertical-align: inherit;">
              <font style="vertical-align: inherit;">01</font>
            </font>
          </div>
        </div>
        <div class="compare__info__text">
          <p>{{ 'COMPARE.7' | translate }}</p>
          <span>{{ 'COMPARE.8' | translate }}</span>
        </div>
      </div>
      <div class="compare__info__body">
        <div class="compare__info__count">
          <div class="count">
            <font style="vertical-align: inherit;">
              <font style="vertical-align: inherit;">02</font>
            </font>
          </div>
        </div>
        <div class="compare__info__text">
          <p>{{ 'COMPARE.9' | translate }}</p>
          <span>{{ 'COMPARE.10' | translate }}</span>
        </div>
      </div>
      <div class="compare__info__body">
        <div class="compare__info__count">
          <div class="count">
            <font style="vertical-align: inherit;">
              <font style="vertical-align: inherit;">03</font>
            </font>
          </div>
        </div>
        <div class="compare__info__text">
          <p>{{ 'COMPARE.11' | translate }}</p>
          <span>{{ 'COMPARE.12' | translate }}</span>
        </div>
      </div>
      <div class="compare__info__body">
        <div class="compare__info__count">
          <div class="count">
            <font style="vertical-align: inherit;">
              <font style="vertical-align: inherit;">04</font>
            </font>
          </div>
        </div>
        <div class="compare__info__text">
          <p>{{ 'COMPARE.13' | translate }}</p>
          <span>{{ 'COMPARE.14' | translate }}</span>
        </div>
      </div>
    </div>

    <div class="compare-detail__content" *ngIf="isToggle">
      <h1>{{ 'COMPARE.CONTENT.1' | translate }}</h1>
      <p>
        {{ 'COMPARE.CONTENT.2' | translate }}
      </p>
      <h2>{{ 'COMPARE.CONTENT.3' | translate }}</h2>
      <p>
        {{ 'COMPARE.CONTENT.4' | translate }}
      </p>
      <h2>{{ 'COMPARE.CONTENT.5' | translate }}</h2>
      <p>
        {{ 'COMPARE.CONTENT.6' | translate }}
      </p>
      <ul>
        <li>
          <strong>{{ 'COMPARE.CONTENT.7' | translate }}:</strong>
          {{ 'COMPARE.CONTENT.8' | translate }}
        </li>
        <li>
          <strong>{{ 'COMPARE.CONTENT.9' | translate }}:</strong>
          {{ 'COMPARE.CONTENT.10' | translate }}
        </li>
        <li>
          <strong>{{ 'COMPARE.CONTENT.11' | translate }}:</strong>
          {{ 'COMPARE.CONTENT.12' | translate }}
        </li>
      </ul>
      <h2>{{ 'COMPARE.CONTENT.13' | translate }}</h2>
      <p>
        {{ 'COMPARE.CONTENT.14' | translate }}
      </p>
      <br>
      <p>
        {{ 'COMPARE.CONTENT.15' | translate }}
      </p>
      <ul>
        <li>
          <strong>{{ 'COMPARE.CONTENT.16' | translate }}:</strong>
          {{ 'COMPARE.CONTENT.17' | translate }}
        </li>
        <li>
          <strong>{{ 'COMPARE.CONTENT.18' | translate }}:</strong>
          {{ 'COMPARE.CONTENT.19' | translate }}
        </li>
        <li><strong>{{ 'COMPARE.CONTENT.20' | translate }}:</strong>
          {{ 'COMPARE.CONTENT.21' | translate }}
        </li>
        <li><strong>{{ 'COMPARE.CONTENT.22' | translate }}:</strong>
          {{ 'COMPARE.CONTENT.23' | translate }}
        </li>
      </ul>
      <p>
        {{ 'COMPARE.CONTENT.24' | translate }}
      </p>
    </div>
  </div>
</div>

<ng-template #checkValid let-modal>
  <div class="modal-header">
    <h4 class="modal-title">{{ 'COMPARE.15' | translate }}</h4>
  </div>
  <div class="modal-body">
    <p>{{ 'COMPARE.16' | translate }}</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="modal.close('Close click')">
      {{ 'COMPARE.17' | translate }}
    </button>
  </div>
</ng-template>
