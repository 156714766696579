import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-pointat',
  templateUrl: './pointat.component.html',
  styleUrls: ['./pointat.component.css']
})
export class PointatComponent implements OnInit {
  @Input() icon = ''
  @Input() label = ''
  @Input() childLabel = ''

  constructor() { }

  ngOnInit(): void {
  }

}
