import { Editor, Toolbar } from 'ngx-editor';
import { BOOLEAN_SELECT, PRODUCT_TEMPLATE } from 'src/utils/constant';
import { ERROR } from 'src/utils/validation';

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ITCategory } from '@models/category.model';
import { ITProduct } from '@models/product.model';
import { ApiService } from '@services/api.service';
import { CommonService } from '@services/common/common.service';
import { ProductsService } from '@services/products/products.service';
import { ProtectService } from '@services/protect/protect.service';
import { ToastService } from '@services/toast.service';
import { TokenService } from '@services/token.service';

@Component({
  selector: 'app-product-edit',
  templateUrl: './product-edit.component.html',
  styleUrls: ['./product-edit.component.css']
})
export class ProductEditComponent implements OnInit {

  error = ERROR
  boolean = BOOLEAN_SELECT
  products: ITProduct[] = []
  categories: ITCategory[] = []
  companies: any[] = []
  product: ITProduct = {
    ...PRODUCT_TEMPLATE
  }
  prodId: number = 0
  page: number = 1
  total: number = 10
  pageSize: number = 10
  disableInput: boolean = false
  detail!: Editor;
  noiDung!: Editor;
  quyTrinh!: Editor;
  gioiThieu!: Editor;
  loiIch!: Editor;
  hoaHong!: Editor;
  toolbar: Toolbar = [
    ['bold', 'italic'],
    ['underline', 'strike'],
    ['code', 'blockquote'],
    ['ordered_list', 'bullet_list'],
    [{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
    ['link', 'image'],
    ['text_color', 'background_color'],
    ['align_left', 'align_center', 'align_right', 'align_justify'],
  ];

  form: FormGroup = this.formBuilder.group({
    title: ['', Validators.required],
    code: ['', Validators.required],
    description: [''],
    categoryID: ['', Validators.required],
    tenCongTy: ['', Validators.required],
    heSo: [0],
    noiDung: [''],
    quyTrinh: [''],
    gioiThieu: [''],
    loiIch: [''],
    hoaHong: [''],
    image: [''],
    icon: [''],
    stt: [''],
    status: [true],
    noibat: [true],
    muanhieu: [true],
  })
  tempImage: any = {
    logo: '',
    icon: '',
  }

  constructor(
    private _service: ProductsService,
    private formBuilder: FormBuilder,
    private _common: CommonService,
    private _protect: ProtectService,
    private route: ActivatedRoute,
    private router: Router,
    public _toast: ToastService,
    private ApiService: ApiService,
    private _token: TokenService,
  ) {
    this.prodId = +this.route.snapshot.params['id']
  }

  ngOnInit(): void {
    this.detail = new Editor();
    this.noiDung = new Editor();
    this.quyTrinh = new Editor();
    this.gioiThieu = new Editor();
    this.loiIch = new Editor();
    this.hoaHong = new Editor();
    this.prodId !== 0 && this.getProductbyID(this.prodId);
    this.getCategories()
    this.getCompanies()
  }

  getProductbyID(id: number) {
    this._service.getProductbyID(id).subscribe(res => {
      this.product = res
      this.onInitData(this.product)
    })
  }

  getCategories() {
    this.ApiService.getCategories().subscribe(data => {
      this.categories = data;
    })
  }

  getCompanies() {
    this._service.getCompanies().subscribe(data => {
      this.companies = data
    })
  }

  onSave() {
    let payload = {
      id: this.prodId || 0,
      name: this.form.controls['title'].value,
      code: this.form.controls['code'].value,
      metaTitle: this._protect.formatMetaTile(this.form.controls['title'].value),
      description: this.form.controls['description'].value || null,
      image: this.tempImage.logo.split('finme.vn')[1] || null,
      moreImages: null,
      price: 0,
      promotionPrice: 0,
      includedVAT: true,
      quantity: 0,
      status: this.form.controls['status'].value,
      detail: null,
      categoryID: this.form.controls['categoryID'].value,
      warranty: 0,
      createdDate: this.prodId ? this.product.createdDate : new Date().toISOString(),
      "createdBy": "admin",
      modifiedDate: new Date().toISOString(),
      "modifiedBy": "admin",
      metaKeywords: null,
      metaDescriptions: null,
      topHot: new Date().toISOString(),
      viewCount: 0,
      noibat: this.form.controls['noibat'].value,
      muanhieu: this.form.controls['muanhieu'].value,
      icon: this.tempImage.icon.split('finme.vn')[1] || null,
      tenCongTy: this.form.controls['tenCongTy'].value || null,
      heSo: this.form.controls['heSo'].value || 0,
      noiDung: this.form.controls['noiDung'].value || null,
      quyTrinh: this.form.controls['quyTrinh'].value || null,
      gioiThieu: this.form.controls['gioiThieu'].value || null,
      loiIch: this.form.controls['loiIch'].value || null,
      hoaHong: this.form.controls['hoaHong'].value || null,
      stt: this.form.controls['stt'].value || 0,
    }

    this.prodId ? this.onUpdate(payload) : this.onCreate(payload)
  }

  onCreate(payload: any): void {
    this._service.postProduct(payload).subscribe((res: any) => {
      if (res.status === 401) {
        alert('Phiên đăng nhập đã hết hạn !')
        this._token.signOut()
        window.location.href = 'admin/login'
      }
      else if (res.status === 400 || res.status === 500) {
        this._toast.show('Thêm mới sản phẩm thất bại !', { icon: 'error', classname: 'bg-danger text-light' })
      } else {
        this._toast.show('Thêm mới sản phẩm thành công !', { icon: 'check_circle', classname: 'bg-success text-light' })
        this.router.navigate(['/admin/products-manager']);
      }
    })
  }

  onUpdate(payload: any): void {
    this._service.putProduct(this.prodId, payload).subscribe((res: any) => {
      if (res.status === 401) {
        alert('Phiên đăng nhập đã hết hạn !')
        this._token.signOut()
        window.location.href = 'admin/login'
      }
      else if (res.status === 400 || res.status === 500) {
        this._toast.show('Cập nhật sản phẩm thất bại !', { icon: 'error', classname: 'bg-danger text-light' })
      } else {
        this._toast.show('Cập nhật sản phẩm thành công !', { icon: 'check_circle', classname: 'bg-success text-light' })
        this.router.navigate(['/admin/products-manager']);
      }
    })
  }

  onInitData(data: any) {
    this.form.controls['title'].setValue(data.name)
    this.form.controls['status'].setValue(data.status)
    this.form.controls['description'].setValue(data.description)
    this.form.controls['code'].setValue(data.code)
    this.form.controls['categoryID'].setValue(data.categoryID)
    this.form.controls['tenCongTy'].setValue(data.tenCongTy)
    this.form.controls['heSo'].setValue(data.heSo)
    this.form.controls['noiDung'].setValue(data.noiDung)
    this.form.controls['quyTrinh'].setValue(data.quyTrinh)
    this.form.controls['gioiThieu'].setValue(data.gioiThieu)
    this.form.controls['loiIch'].setValue(data.loiIch)
    this.form.controls['hoaHong'].setValue(data.hoaHong)
    this.form.controls['image'].setValue(data.image)
    this.form.controls['icon'].setValue(data.icon)
    this.form.controls['stt'].setValue(data.stt)
  }

  upload(e: any, type: number) {
    let formData = new FormData()
    for (let file of e.target.files) {
      formData.append('files', file)
    }
    this._common.uploads(formData).subscribe((res) => {
      this._toast.show('Tải file thành công !', { icon: 'check_circle', classname: 'bg-success text-light' })
      type
        ? (this.tempImage.icon = res[0].name)
        : (this.tempImage.logo = res[0].name)
    })
  }

  removeFile(type: number): void {
    this._toast.show('Xóa file thành công !', { icon: 'check_circle', classname: 'bg-success text-light' })
    type
      ? (this.tempImage.icon = '')
      : (this.tempImage.logo = '')
  }

  uploadInnerImage(e: any, type: string): void {
    let formData = new FormData()
    for (let file of e.target.files) {
      formData.append('files', file)
    }
    this._common.uploads(formData).subscribe((res) => {
      this._toast.show('Tải file thành công !', { icon: 'check_circle', classname: 'bg-success text-light' })

      switch (type) {
        case 'noiDung':
          this.form.controls['noiDung'].setValue(
            this.form.controls['noiDung'].value + `<img src="${res[0].name}" alt="">`)
          break
        case 'quyTrinh':
          this.form.controls['quyTrinh'].setValue(
            this.form.controls['quyTrinh'].value + `<img src="${res[0].name}" alt="">`)
          break
        case 'gioiThieu':
          this.form.controls['gioiThieu'].setValue(
            this.form.controls['gioiThieu'].value + `<img src="${res[0].name}" alt="">`)
          break
        case 'loiIch':
          this.form.controls['loiIch'].setValue(
            this.form.controls['loiIch'].value + `<img src="${res[0].name}" alt="">`)
          break
        case 'hoaHong':
          this.form.controls['hoaHong'].setValue(
            this.form.controls['hoaHong'].value + `<img src="${res[0].name}" alt="">`)
          break
      }
    })

  }

  invalidForm(): boolean {
    let result
    (this.form.controls['title'].invalid || this.form.controls['code'].invalid || this.form.controls['categoryID'].invalid || this.form.controls['tenCongTy'].invalid)
      ? (result = true)
      : (result = false)
    return result
  }

}
