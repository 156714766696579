import { Component, Input, OnInit } from '@angular/core'

@Component({
  selector: 'app-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.css'],
})
export class AccordionComponent implements OnInit {
  @Input() summary: string = ''
  @Input() content: string[] = ['Giá cả 200.000']

  constructor() {}

  ngOnInit(): void {}

  collapse() {}
}
