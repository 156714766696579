import { Component, OnInit, TemplateRef } from '@angular/core';
import { ToastService } from '@services/toast.service';

@Component({
  selector: 'app-toast',
	template: `
		<ngb-toast
			*ngFor="let toast of toastService.toasts"
			[class]="toast.classname"
			[autohide]="true"
			[delay]="toast.delay || 5000"
			(hidden)="toastService.remove(toast)"
		>
			<ng-template [ngIf]="isTemplate(toast)" [ngIfElse]="text">
				<ng-template [ngTemplateOutlet]="toast.textOrTpl|translate"></ng-template>
			</ng-template>

			<ng-template #text>
        <div class="flex">
          <span class="material-icons fs-5">{{ toast.icon }}</span>
          <span class="fs-6">&nbsp;&nbsp;{{ toast.textOrTpl|translate }}</span>
        </div>
      </ng-template>
		</ngb-toast>
	`,
	host: { class: 'toast-container position-fixed top-0 end-0 p-3', style: 'z-index: 1200' },
})
export class ToastComponent implements OnInit {

  constructor(public toastService: ToastService) {}

  ngOnInit(): void {
  }

  isTemplate(toast: any) {
		return toast.textOrTpl instanceof TemplateRef;
	}

}
