import { GpayService } from '@services/gpay/gpay.service';
import { Component, OnInit, TemplateRef } from '@angular/core';
// import { Title } from '@angular/platform-browser';
import { ERROR, VALID } from 'src/utils/validation';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import { LOGIN_POINTERS, LOGIN_USPS } from 'src/utils/constant';
// import { TokenService } from '@services/token.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastService } from '@services/toast.service';
import { ProtectService } from '@services/protect/protect.service';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

@Component({
  selector: 'app-login-gpay',
  templateUrl: './login-gpay.component.html',
  styleUrls: ['./login-gpay.component.css']
})
export class LoginGPAYComponent implements OnInit {
  isShow: boolean = false
  showType: string = 'password'
  // isRegister: boolean = true;
  isLoggedIn: boolean = false;
  isLoginFailed: boolean = false;
  submitted: boolean = false;
  message: string = '';
  subLabel: string = 'Đăng ký'
  // session: any
  // hasParent: boolean = true
  // disableInput: boolean = false

  // login: FormGroup = this._formBuilder.group({
  //   username: ['', Validators.required],
  //   password: ['', Validators.required],
  // })

  register: FormGroup = this._formBuilder.group(
    {
      hoten: ['', Validators.required],
      so_dien_thoai: ['', [Validators.required, Validators.pattern(VALID.PHONE)]],
      cmnd: ['', [Validators.required, Validators.pattern(VALID.CCCD)]],
      email: ['', [Validators.required, Validators.pattern(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)]],
      company: [''],
      website: ['']
    },
    // {
    //   validators: [this._service.match('mat_khau', 'xac_nhan')]
    // }
  )
  windowRef: any;
  // phone: any;
  // user: any;
  // errorVerify: boolean = true
  // public stepValue = 1

  constructor(
    // private titleService: Title,
    private _service: GpayService,
    // private tokenStorage: TokenService,
    private _formBuilder: FormBuilder,
    private modalService: NgbModal,
    // private route: ActivatedRoute,
    private _router: Router,
    public _toast: ToastService,
    private win: ProtectService
  ) {

  }

  ngOnInit(): void {
    // if (this.tokenStorage.getToken()) {
    //   this.isLoggedIn = true;
    //   this._router.navigateByUrl('/')
    // } else {
    //   this.titleService.setTitle('Finme - Đăng nhập');
    // }

    this.windowRef = this.win.windowRef

    this.windowRef.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
      size: 'invisible',
      callback: (response: any) => {
        // Recaptcha resolved
      },
      'expired-callback': () => {
        // Recaptcha expired
      }
    })

    this.windowRef.recaptchaVerifier.render()
  }

  // convenience getter for easy access to form fields
  // get l() { return this.login.controls; }
  get r() { return this.register.controls; }

  // onLogin(f: any, warming: TemplateRef<any>, notice: TemplateRef<any>) {
  //   this.submitted = true

  //   if (f.valid) {
  //     const payload = {
  //       username: this.login.controls['username'].value,
  //       password: this.login.controls['password'].value
  //     }

  //     this._service.onLogin(payload).subscribe(data => {
  //       if (data.token) {
  //         this._toast.show('Đăng nhập thành công!', { icon: 'check_circle', classname: 'bg-success text-light' });

  //         this.tokenStorage.saveToken(data.token);
  //         this.tokenStorage.saveUser({
  //           role: data.role,
  //           username: data.username,
  //           ma_nv: data.ma_nv,
  //           ho_ten: data.ho_ten,
  //         });

  //         this.isLoginFailed = false;
  //         this.isLoggedIn = true;
  //         window.location.href = '/'
  //       } else {
  //         this._toast.show('Đăng nhập thất bại!', { icon: 'error', classname: 'bg-danger text-light' });
  //         this.message = 'Vui lòng kiểm tra lại thông tin đăng nhập hoặc kích hoạt tài khoản qua email !'
  //         this.modalService.open(notice, { centered: true })
  //       }
  //     })
  //   } else this.modalService.open(warming, { centered: true })
  // }

  onRegister(f: any, warming: TemplateRef<any>, notice: TemplateRef<any>) {
    this.submitted = true

    if (f.valid) {
      const payload = {
        merchant_code: '',
        account_name: this.removeVietnameseTones(this.register.controls['hoten'].value),
        map_id: String(this.register.controls['cmnd'].value),
        map_type: (this.register.controls['cmnd'].value.length == 9 )?'CMND':'CCCD',
        account_type: 'M',
        bank_code: 'BIDV',
        max_amount: 0,
        min_amount: 0,
        equal_amount: 0,
        customer_address: '',
        signature: '',
      }

      this._service.onRegister(payload).subscribe(
        data => {
          if (data.res) {
            this.message = `Tài khoản của bạn là: ${data.account_number} \n\r;
                            Tên tài khoản: ${data.account_name} \n\r;
                            Ngân hàng: BIDV`;
            this.modalService.open(notice, { centered: true })
            this._toast.show('Đăng ký thành công !', { icon: 'check_circle', classname: 'bg-success text-light' });
            // this.formHandler(false)
            // this._router.navigateByUrl('/download')
          } else {
            this._toast.show('Đăng ký thất bại !', { icon: 'error', classname: 'bg-danger text-light' });
            this._toast.show('Trùng lặp số CMND/CCCD !', { icon: 'error', classname: 'bg-danger text-light' });
            this.message = 'Số CMND/CCCD bị trùng lặp, vui lòng kiểm tra lại thông tin !'
            this.modalService.open(notice, { centered: true })
          }
        })
    } else this.modalService.open(warming, { centered: true })
  }


  onShow() {
    this.isShow = !this.isShow
    this.showType = this.isShow ? 'text' : 'password'
  }

  removeVietnameseTones(str: string) {
    str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
    str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
    str = str.replace(/đ/g, "d");
    str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A");
    str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E");
    str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I");
    str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O");
    str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U");
    str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y");
    str = str.replace(/Đ/g, "D");
    // Some system encode vietnamese combining accent as individual utf-8 characters
    // Một vài bộ encode coi các dấu mũ, dấu chữ như một kí tự riêng biệt nên thêm hai dòng này
    str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ""); // ̀ ́ ̃ ̉ ̣  huyền, sắc, ngã, hỏi, nặng
    str = str.replace(/\u02C6|\u0306|\u031B/g, ""); // ˆ ̆ ̛  Â, Ê, Ă, Ơ, Ư
    // Remove extra spaces
    // Bỏ các khoảng trắng liền nhau
    str = str.replace(/ + /g, " ");
    str = str.trim();
    // Remove punctuations
    // Bỏ dấu câu, kí tự đặc biệt
    str = str.replace(/!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|,|\.|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|_|`|-|{|}|\||\\/g, " ");
    return str;
  }
  // formHandler(isRegister: boolean) {
  //   this.isRegister = isRegister
  //   this.subLabel = isRegister ? 'Đăng ký' : 'Đăng nhập'
  //   this.titleService.setTitle(`Finme - ${this.subLabel}`)
  // }

  valid = VALID
  error = ERROR
  pointers = LOGIN_POINTERS
  usps = LOGIN_USPS
}
