<div class="admin-body">
    <div class="row page-titles mx-0">
        <div class="col p-md-0">
            <ol class="breadcrumb">
                <li class="breadcrumb-item" routerLink="admin/dashboard">Quản lý</li>
                <li class="breadcrumb-item">Bài viết</li>
                <li class="breadcrumb-item active">Chỉnh sửa bài viết</li>
            </ol>
        </div>
    </div>

    <div class="admin__container">
        <div class="flex space">
            <span class="admin__container-head">Cập nhật nội dung bài viết</span>
        </div>
        <div #dialogForm>
            <div class="modal-body">
            <form class="dialog" [formGroup]="form">
              <div class="text-field">
                <label for="title">Tên bài viết<span class="required">*</span></label>
                <input [readonly]="disableInput" required type="text" id="title" name="title" formControlName="title" />
                <div class="invalid">
                  <small *ngIf="form.controls['title'].touched && form.controls['title'].hasError('required')">{{error.required}}</small>
                </div>
              </div>
        
              <ngb-accordion #acc="ngbAccordion" activeIds="ngb-panel-0" *ngIf="content.image">
                <ngb-panel title="Xem ảnh">
                  <ng-template ngbPanelContent>
                    <img [src]="content.image">
                  </ng-template>
                </ngb-panel>
              </ngb-accordion>
        
              <div class="text-field upload mt-3" *ngIf="this.dialogType !== 'view'">
                <label for="image">Image<span class="required">*</span></label>
                <input [disabled]="disableInput" type="file" name="image" id="image" accept="image/png, image/gif, image/jpeg" aria-label="Tải lên ảnh danh mục"
                  (change)="selectFile($event)" />
                <div class="invalid">
                  <small *ngIf="form.controls['image'].touched && form.controls['image'].hasError('required')">{{error.required}}</small>
                </div>
              </div>
        
              <div class="text-field">
                <label for="status">Trạng thái</label>
                <select name="status" id="status" formControlName="status" *ngIf="!disableInput">
                  <option *ngFor="let opt of boolean" [value]="opt.value">{{opt.vie}}</option>
                </select>
                <input [readonly]="disableInput" type="text" id="status" name="status" formControlName="status" *ngIf="disableInput" />
                <div class="invalid"></div>
              </div>
        
              <div class="text-field">
                <label for="description">Mô tả<span class="required">*</span></label>
                <input [readonly]="disableInput" required type="text" id="description" name="description" formControlName="description" />
                <div class="invalid">
                  <small *ngIf="form.controls['description'].touched && form.controls['description'].hasError('required')">{{error.required}}</small>
                </div>
              </div>
        
              <div class="text-field">
                <label>Nội dung<span class="required">*</span></label>
                <div class="NgxEditor__Wrapper">
                  <ngx-editor-menu [editor]="detail" [toolbar]="toolbar">
                  </ngx-editor-menu>
                  <ngx-editor [disabled]="disableInput" [editor]="detail" formControlName="detail">
                  </ngx-editor>
                </div>
              </div>
            </form>
        
            <!-- <form [formGroup]="test">
              <div class="editor">
                <ngx-editor-menu [editor]="editor" [toolbar]="toolbar">
                </ngx-editor-menu>
                <ngx-editor [editor]="editor" formControlName="editorContent">
                </ngx-editor>
              </div>
            </form> -->
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-primary flex space" (click)="onSave();" [disabled]="form.invalid">
              <span class="material-icons text-light">save</span>
              <p class="m-0 px-1">Lưu</p>
            </button>
                <button type="button" class="btn btn-danger flex space">
              <span class="material-icons text-light">close</span>
              <p class="m-0 px-1">Đóng</p>
            </button>
            </div>
        </div>  
    </div>
</div>