import { eff, now } from "."

export const CAR_RESPONS_PEOLPE = [
  // Mức trách nghiệm về người
  // { id: 1, value: 10000000, label: 'PRODUCTS.10M' },
  { id: 1, value: 0, label: ' ' },
  { id: 2, value: 20000000, label: 'PRODUCTS.20M' },
  // { id: 3, value: 30000000, label: 'PRODUCTS.30M' },
  // { id: 4, value: 40000000, label: 'PRODUCTS.40M' },
  { id: 5, value: 50000000, label: 'PRODUCTS.50M' },
  { id: 6, value: 70000000, label: 'PRODUCTS.70M' },
  { id: 7, value: 100000000, label: 'PRODUCTS.100M' },
  { id: 8, value: 150000000, label: 'PRODUCTS.150M' },
]

export const CAR_RESPONS_ASSETS = [
  // Mức trách nghiệm về tài sản
  { id: 1, value: 0, label: ' ' },
  // { id: 1, value: 10000000, label: 'PRODUCTS.10M' },
  // { id: 2, value: 15000000, label: 'PRODUCTS.15M' },
  { id: 3, value: 20000000, label: 'PRODUCTS.20M' },
  // { id: 4, value: 25000000, label: 'PRODUCTS.25M' },
  // { id: 5, value: 30000000, label: 'PRODUCTS.30M' },
  { id: 6, value: 50000000, label: 'PRODUCTS.50M' },
  { id: 7, value: 70000000, label: 'PRODUCTS.70M' },
  { id: 8, value: 100000000, label: 'PRODUCTS.100M' },
  { id: 9, value: 150000000, label: 'PRODUCTS.150M' },
]

export const CAR_RESPONS_DRIVERS = [
  // Mức trách nghiệm về lái xe, phụ xe
  { id: 1, value: 0, label: ' ' },
  { id: 2, value: 10000000, label: 'PRODUCTS.10M' },
  { id: 3, value: 20000000, label: 'PRODUCTS.20M' },
  { id: 4, value: 30000000, label: 'PRODUCTS.30M' },
  { id: 5, value: 40000000, label: 'PRODUCTS.40M' },
  { id: 6, value: 50000000, label: 'PRODUCTS.50M' },
]

export const CAR_ORIGINAL = [
  // Nguồn gốc xuất xứ
  // { id: 1, value: '', label: 'Chọn nguồn gốc...' },
  { id: 2, value: 'Sản xuất tại Việt Nam', label: 'PRODUCTS.VN' },
  { id: 3, value: 'Nhập khẩu', label: 'PRODUCTS.IMPORT' },
]

export const CAR_FORM_TEMPLATE = {
  nv: '',
  kieU_HD: 'G',
  ttoan: '',
  lkh: 'C',
  ten: '',
  cmt: '',
  files: {
    front: '',
    back: '',
  },
  nG_SINH: '',
  gioi: '',
  mobi: '',
  email: '',
  dchi: '',
  dbhm: '',
  lkhm: '',
  tenm: '',
  cmtm: '',
  fileMs: {
    front: '',
    back: '',
  },
  nG_SINHM: '',
  mobim: '',
  emailm: '',
  dchim: '',
  nguoiGioiThieu: '',
  tensanpham: '',
  goisanpham: '',
  thoihan: '',
  iddangky: '',
  magioithieu: '',
  moiquanhe: '',
  user_Code: '',
}

export const CAR_BILL_TEMPLATE = {
  bieN_XE: '',
  sO_MAY: '',
  sO_KHUNG: '',
  hanG_XE: '',
  hieU_XE: '',
  naM_SX: '',
  sO_CN: '',
  ttai: '0',
  ngaY_HL: now,
  ngaY_KT: eff,
  tn: '',
  tt: '',
  tb: '',
  tl: '0',
  tk: '0',
  bbtn: 'C',
  mdsd: 'K',
  mdsdn: 'C',
  xeN1: 'C',
  xeN2: 'K',
  xeN3: 'K',
  xeN4: 'K',
  xeN5: 'K',
  xeN6: 'K',
  xeN7: 'K',
  xeN8: 'K',
  xeN10: 'K',
  mdsdh: 'K',
  xeH1: 'K',
  xeH2: 'K',
  xeH3: 'K',
  xeH4: 'K',
  xeH5: 'K',
  xeH6: 'K',
  xeH7: 'K',
  xeH8: 'K',
  xeH9: 'K',
  xeH10: 'K',
  mdsdt: 'K',
  xeT1: 'K',
  xeT2: 'K',
}
