<div class="confirm">
  <div id="recaptcha-container"></div>

  <div class="confirm__grid">
    <div class="confirm__body" *ngIf="respon">
      <span class="material-icons confirm__header--failed" *ngIf="errorMessage">
        error
      </span>
      <span class="material-icons" *ngIf="!errorMessage"
      [ngClass]="respon.status ? 'confirm__header--success' : 'confirm__header--pending'">
        {{ respon.status ? 'check_circle' : 'pending' }}
      </span>
      <!-- <ngx-skeleton-loader
          *ngIf="respon.message.length === 0"
          count="1"
          [theme]="{
            width: '180px',
            height: '32px',
            'border-radius': '0.5rem'
          }"
        ></ngx-skeleton-loader> -->
      <h2 class="confirm__header confirm__header--failed" *ngIf="errorMessage">
        {{ errorMessage }}
      </h2>
      <h2 class="confirm__header" *ngIf="!errorMessage"
        [ngClass]="respon.status ? 'confirm__header--success' : 'confirm__header--pending'">
        {{ respon.message  || 'ĐANG XỬ LÝ' }}
      </h2>
      <hr>
      <div class="confirm__info">
        <span class="confirm__label">{{ 'PAYMENT_CONFIRM.TYPE' | translate }}</span>
        <ngx-skeleton-loader
          *ngIf="respon.message.length === 0"
          count="1"
          [theme]="{
            width: '180px',
            height: '24px',
            'border-radius': '0.5rem'
          }"
        ></ngx-skeleton-loader>
        <span class="confirm__data">{{confirmed.vnp_BankCode}}</span>
      </div>
      <div class="confirm__info">
        <span class="confirm__label">{{ 'PAYMENT_CONFIRM.METHOD' | translate }}</span>
        <ngx-skeleton-loader
          *ngIf="respon.message.length === 0"
          count="1"
          [theme]="{
            width: '180px',
            height: '24px',
            'border-radius': '0.5rem'
          }"
        ></ngx-skeleton-loader>
        <span class="confirm__data">{{confirmed.vnp_CardType}}</span>
      </div>
      <div class="confirm__info">
        <span class="confirm__label">{{ 'PAYMENT_CONFIRM.AMOUNT' | translate }}</span>
        <ngx-skeleton-loader
          *ngIf="respon.message.length === 0"
          count="1"
          [theme]="{
            width: '210px',
            height: '36px',
            'border-radius': '0.5rem'
          }"
        ></ngx-skeleton-loader>
        <span class="confirm__price">{{respon.tongTien}} VND</span>
      </div>
      <div class="confirm__info">
        <span class="confirm__label">Email</span>
        <ngx-skeleton-loader
          *ngIf="respon.message.length === 0"
          count="1"
          [theme]="{
            width: '250px',
            height: '24px',
            'border-radius': '0.5rem'
          }"
        ></ngx-skeleton-loader>
        <span class="confirm__data">{{respon.email}}</span>
      </div>
      <div class="confirm__info">
        <span class="confirm__label">{{ 'COMMON.PHONE_NUMBER' | translate }}</span>
        <ngx-skeleton-loader
          *ngIf="respon.message.length === 0"
          count="1"
          [theme]="{
            width: '180px',
            height: '24px',
            'border-radius': '0.5rem'
          }"
        ></ngx-skeleton-loader>
        <span class="confirm__data">{{respon.soDienThoai}}</span>
      </div>
      <div class="confirm__info">
        <span class="confirm__label">{{ 'PAYMENT_CONFIRM.BILL_ID' | translate }}</span>
        <ngx-skeleton-loader
          *ngIf="respon.message.length === 0"
          count="1"
          [theme]="{
            width: '270px',
            height: '24px',
            'border-radius': '0.5rem'
          }"
        ></ngx-skeleton-loader>
        <span class="confirm__data">{{respon.maHoaDon}}</span>
      </div>
      <div class="confirm__info">
        <span class="confirm__label">{{ 'PAYMENT_CONFIRM.PAYMENT_ID' | translate }}</span>
        <ngx-skeleton-loader
          *ngIf="respon.message.length === 0"
          count="1"
          [theme]="{
            width: '150px',
            height: '24px',
            'border-radius': '0.5rem'
          }"
        ></ngx-skeleton-loader>
        <span class="confirm__data">{{respon.maGiaoDich}}</span>
      </div>
      <br>
      <button routerLink="/" class="btn btn-primary" *ngIf="confirmed.webview !== 'true'">{{ 'COMMON.RETURN_HOME' | translate }}</button>

      <p class="confirm__help">
        {{ 'PAYMENT_CONFIRM.HELP' | translate }} <br>
        <a href="tell:02888815818" class="hotline">02888815818</a>
      </p>
    </div>
    <div class="confirm__extend">
      <!-- <h2 class="confirm__header">{{ 'PAYMENT_CONFIRM.RETURN_HOME' | translate }}</h2> -->
      <ngx-skeleton-loader
        *ngIf="products.length === 0"
        count="11"
        [theme]="{
          margin: '15px',
          width: '160px',
          height: '160px',
          'border-radius': '1rem'
        }"
      ></ngx-skeleton-loader>
      <div class="confirm__products">
        <div class="product" *ngFor="let item of products">
          <app-product-card [meta]="item.metaTitle" [image]="item.image" [name]="item.name">
          </app-product-card>
        </div>
      </div>
    </div>
  </div>
</div>
