import { now } from ".";

export const UIC_RESPONS = [
  { id: 1, value: 'goi_1', label: 'PRODUCTS.UIC.1', label_api: 'Lựa chọn 1' },
  { id: 2, value: 'goi_2', label: 'PRODUCTS.UIC.2', label_api: 'Lựa chọn 2' },
  { id: 3, value: 'goi_3', label: 'PRODUCTS.UIC.3', label_api: 'Lựa chọn 3' },
  { id: 4, value: 'goi_4', label: 'PRODUCTS.UIC.4', label_api: 'Lựa chọn 4' },
  { id: 5, value: 'goi_5', label: 'PRODUCTS.UIC.5', label_api: 'Lựa chọn 5' },
]

export const UIC_DURATIONS = [
  { id: 1, value: '3', label: 'PRODUCTS.3_MONTH' },
  { id: 2, value: '6', label: 'PRODUCTS.6_MONTH' },
  { id: 3, value: '12', label: 'PRODUCTS.12_MONTH' },
]

export const UIC_DYNAMIC_POLICY = [
  { id: 1, value: 'goi_1', val_1: '100.000', val_2: '1.000.000', val_3: '10.000.000', val_4: '10.000.000' },
  { id: 2, value: 'goi_2', val_1: '200.000', val_2: '2.000.000', val_3: '20.000.000', val_4: '20.000.000' },
  { id: 3, value: 'goi_3', val_1: '300.000', val_2: '3.000.000', val_3: '30.000.000', val_4: '30.000.000' },
  { id: 4, value: 'goi_4', val_1: '500.000', val_2: '5.000.000', val_3: '50.000.000', val_4: '50.000.000' },
  { id: 5, value: 'goi_5', val_1: '1.000.000', val_2: '10.000.000', val_3: '100.000.000', val_4: '100.000.000' },
]

export const UIC_FORM_TEMPLATE = {
  nv: 'UIC',
  kieU_HD: 'G',
  ttoan: '',
  ten: '',
  cmt: '',
  files: {
    front: '',
    back: '',
  },
  nG_SINH: '',
  ngaY_HL: now,
  ngaY_KT: '',
  gioi: '',
  mobi: '',
  email: '',
  dchi: '',
  nguoiGioiThieu: '',
  tensanpham: 'Bảo hiểm trợ cấp nằm viện UIC',
  goisanpham: '',
  thoihan: '',
  iddangky: '',
  magioithieu: '',
  moiquanhe: '',
  user_Code: '',
}

export const TCNV_FORM_TEMPLATE = {
  nv: '',
  kieU_HD: 'G',
  ttoan: '',
  ten: '',
  cmt: '',
  files: {
    front: '',
    back: '',
  },
  nG_SINH: '',
  ngaY_HL: now,
  ngaY_KT: '',
  gioi: '',
  mobi: '',
  email: '',
  dchi: '',
  nguoiGioiThieu: '',
  tensanpham: '',
  goisanpham: '',
  thoihan: '',
  iddangky: '',
  magioithieu: '',
  moiquanhe: '',
  user_Code: '',
}
