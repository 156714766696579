import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.css']
})
export class CustomersComponent implements OnInit {
  @Input() image = ''
  @Input() name = ''
  @Input() feedback = ''

  constructor() { }

  ngOnInit(): void {
  }

  customers = [
    {
      id: 1,
      name: 'Nguyễn Bình Minh',
      image: '../../../assets/Upload/customer/em minh it.jpeg',
      feedback: 'Ứng dụng Finme khiến tôi rất vừa ý với những áp dụng công nghệ làm đổi mới lĩnh vực bảo hiểm.'
    },
    {
      id: 2,
      name: 'Nguyễn Kim Hồng',
      image: '../../../assets/Upload/customer/z354598b9d8.jpg',
      feedback: 'Là nơi khởi đầu lĩnh vực bảo hiểm số và giúp khách hàng yên tâm hơn.'
    },
    {
      id: 3,
      name: 'Tào Nhật Ninh',
      image: '../../../assets/Upload/customer/Ninh.png',
      feedback: 'Ứng dụng Finme tiên phong về công nghệ lĩnh vực bảo hiểm.'
    },
  ]
}
