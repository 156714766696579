import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {
  title = 'Finme - Liên hệ'

  constructor(private titleService:Title) { }

  ngOnInit(): void {
    this.titleService.setTitle(this.title);
  }

  special = [
    { id: 1, label: 'CONTACT.LABEL1' },
    { id: 2, label: 'CONTACT.LABEL2' },
    { id: 3, label: 'CONTACT.LABEL3' },
    { id: 4, label: 'CONTACT.LABEL4' },
    { id: 5, label: 'CONTACT.LABEL5' },
    { id: 6, label: 'CONTACT.LABEL6' },
  ]
}
