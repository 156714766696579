import * as moment from 'moment';
import { API_PATH, GENDERS } from 'src/utils/constant';
import {
  eff, FEE_TEMPLATE, now, PAYMENT_TEMPLATE, VOUCHER_TEMPLATE, year
} from 'src/utils/product';
import {
  ACARE_AGE, ACARE_BILL_TEMPLATE, ACARE_CUSTOMER_TEMPLATE, ACARE_FORM_TEMPLATE,
  ACARE_PASSENGER_TEMPLATE, BHSKACARE_INSURANCE
} from 'src/utils/product/acare';
import { BHSK_RELATIVE } from 'src/utils/product/vbicare';
import { BHSK_STEPS } from 'src/utils/steps';
import { ERROR, VALID } from 'src/utils/validation';

import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ITACareBill, ITACareFee, ITACarePassenger } from '@models/baohiemsuckhoeacare';
import { ITFee } from '@models/fee.model';
import { ITPayment } from '@models/payment.model';
import { ITVoucher } from '@models/voucher.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '@services/api.service';
import { CommonService } from '@services/common/common.service';
import { ProtectService } from '@services/protect/protect.service';
import { ToastService } from '@services/toast.service';

@Component({
  selector: 'app-baohiemacare',
  templateUrl: './baohiemacare.component.html',
  styleUrls: ['./baohiemacare.component.css'],
})
export class BaohiemacareComponent implements OnInit {
  @Output() setIsPayment = new EventEmitter()
  isPayment: boolean = true
  files: any
  @Input() isWebview: boolean = false
  @Input() isApp: boolean = false
  @Input() isShared: boolean = false
  @Input() appVoucher: any = null
  @Input() userCode: any = null
  @Input() meetVoucher: any = null
  public isBack = false
  public stepValue = 1
  public isPerson = true
  public isPaymentWindow: boolean = true;
  public isCorp = false
  public launcher_id = ''
  public save = 0
  public fee: ITFee = {
    ...FEE_TEMPLATE
  }
  year = year
  now = now
  eff = eff
  ma_nvAppVoucher: any = undefined
  currentDate: number = new Date().getDate()
  currentMonth: number = new Date().getMonth()
  currentYear: number = new Date().getFullYear()
  minBirth: any = new Date(this.currentYear - 65, this.currentMonth, this.currentDate + 1).toISOString().substring(0, 10)
  wrongBirth: boolean = false
  webviewBirthday = {
    day: 0,
    month: 0,
    year: 0,
  }
  public form: ITACareBill = {
    ...ACARE_FORM_TEMPLATE
  }
  public passenger: ITACarePassenger = {
    ...ACARE_PASSENGER_TEMPLATE
  }
  public bill: ITACareFee = {
    ...ACARE_BILL_TEMPLATE
  }
  public voucher: ITVoucher = {
    ...VOUCHER_TEMPLATE
  }
  public payment: ITPayment = {
    ...PAYMENT_TEMPLATE
  }
  public moi_qh_selected: boolean = false
  public moi_qh_arr: string[] = []
  submited: boolean = false
  tempFile: any = {
    front: '',
    back: '',
  }
  webviewNow: any;
  isNumberIn: boolean = false;
  isNumberInP: boolean = false;
  validDate: boolean = true;
  validBirthday: boolean = true;
  validBirthdayP: boolean = true;
  isTrueVoucher: boolean = true;
  isTrueLauncher: boolean = true;

  constructor(
    private ApiService: ApiService,
    private _common: CommonService,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private router: Router,
    private _protect: ProtectService,
    public _toast: ToastService,
  ) { }

  ngOnInit(): void {
    this.isWebview ? (
      this.getParams(), this.webviewNow = moment(this.now).format('DD-MM-YYYY'),
      this.passenger.startDate = moment(this.passenger.startDate).format('DD-MM-YYYY'),
      this.passenger.endDate = moment(this.passenger.endDate).format('DD-MM-YYYY')
    ) : this.handleUserCode()
    if (this.appVoucher?.length == 10) {
      this.voucher.maVoucher = this.appVoucher
    }
    if (this.meetVoucher){
      this.voucher.maVoucher = this.meetVoucher
    }
  }

  handleUserCode() {
    const user = this.route.snapshot.params['user']
    if (user?.length == 9) {
      this.form.magioithieu = user
      this.getLauncher(user)
    }
  }

  checkBirthday(date: string) {
    if (this.isPerson) {
      this.validBirthday = this._common.validBirthdayString(date, this.isWebview);
    }
    else {
      this.validBirthday = true;
    }
  }

  checkBirthdayP(date: string) {
    if (this.isPerson) {
      this.validBirthdayP = this._common.validBirthdayString(date, this.isWebview);
    }
    else {
      this.validBirthdayP = true;
    }
  }

  onChangeFromDate() {
    this.validDate = this._common.validDateString(this.passenger.startDate, this.isWebview);
    this.isWebview ? this.passenger.endDate = moment(this._common.onChangeFromDate(this._common.changeDateString(this.passenger.startDate))).format('DD-MM-YYYY') : this.passenger.endDate = this._common.onChangeFromDate(this.passenger.startDate);
  }

  getParams() {
    this.router.url.includes('&')
      ? (this.form.user_Code = this.payment.user_Code = this.router.url.slice(
        -9,
      ))
      : ''
  }

  // If magioithieu.length < 1, set nguoiGioiThieu = ''
  getEmptyLauncher() {
    this.form.magioithieu = ''
    this.form.nguoiGioiThieu = ''
  }

  // If maVoucher.length < 1, set tongphi = phi
  getOldFee() {
    this.save = 0

    this.fee.tongphi = this.fee.phi
    this.form.ttoan = this.fee.tongphi = this.fee.phi
  }

  openCentered(content: TemplateRef<any>) {
    this.modalService.open(content, { centered: true })
  }

  getBack() {
    this.modalService.dismissAll()

    this.isPayment = false
    this.setIsPayment.emit(this.isPayment)
    // Hàm getBack sẽ ghi lại isPayment bằng false, đồng thời thông qua EventEmitter bắn value isPayment này ra component cha Product

    this.isBack = false
  }

  openImageModal(content: TemplateRef<any>) {
    this.modalService.open(content, {
      size: window.innerWidth > 500 ? 'xl' : 'lg',
    })
  }

  onForSelf() {
    if (this.passenger.moiquanheP === '1') {
      this.passenger.tenP = this.form.ten
      this.passenger.dchiP = this.form.dchi
      this.passenger.nG_SINHP = this.form.nG_SINH
      this.passenger.gioiP = this.form.gioi
      this.passenger.cmtP = this.form.cmt
      this.passenger.mobiP = this.form.mobi
      this.passenger.emailP = this.form.email

      this.birthdayHandler(this.passenger.nG_SINHP)
    }
  }

  onSubmit(f: any) {
    if (f.invalid || this.fee.phi == '') {
      this._toast.show('Hãy nhập đúng phiếu thông tin !', { icon: 'error', classname: 'bg-danger text-light' });

      f.controls['cmt']!.touched = true
      f.controls['ten']!.touched = true
      f.controls['mobi']!.touched = true
      f.controls['email']!.touched = true
      if (this.isWebview) {

      } else {
        f.controls['nG_SINH']!.touched = true
      }
      if (this.isPerson && f.controls['gioi']?.invalid) {
        f.controls['gioi']!.touched = true
      }
      if (this.isCorp && (f.controls['daidien']?.invalid | f.controls['chucvu']?.invalid)) {
        f.controls['daidien']!.touched = true
        f.controls['chucvu']!.touched = true
      }

      f.controls['moiquanhe']!.touched = true
      f.controls['tenP']!.touched = true
      f.controls['cmtP']!.touched = true
      f.controls['mobiP']!.touched = true
      f.controls['emailP']!.touched = true
      f.controls['nG_SINH']!.touched = true
      f.controls['gioiP']!.touched = true

      f.controls['startDate']!.touched = true
      f.controls['goi']!.touched = true
    } else {
      if (!this.validDate || !this.validBirthday || this.wrongBirth || !this.validBirthdayP) {
        this._toast.show('Hãy nhập đúng phiếu thông tin !', { icon: 'error', classname: 'bg-danger text-light' });
      }
      else {
        this.submited = true
        const totalFee = this.fee.tongphi as string
        const totalPay = this.form.ttoan as string
        this.payment.tongThanhToan = parseFloat(totalPay.replace(/,/g, '')).toString()
        this.payment.tongphi = parseFloat(totalFee.replace(/,/g, '')).toString()
        let payload = {
          ...this.form,
          ...this.passenger,
          ...this.payment,
          ...this.bill,
          email: this.form.email || '',
          gioitinh: this.genders.find((i: any) => i.value === this.form.gioi)?.label,
          nG_SINH: this.isWebview ? this._common.changeDateString(this.form.nG_SINH) : this.form.nG_SINH,
          nG_SINHP: this.isWebview ? this._common.changeDateString(this.passenger.nG_SINHP) : this.passenger.nG_SINHP,
          goibaohiem: this.goibaohiem.find((i: any) => i.value === this.bill.goi)?.label_api,
          goisanpham: this.goibaohiem.find((i: any) => i.value === this.bill.goi)?.label_api,
          startDate: this.isWebview ? this._common.changeDateString(this.passenger.startDate) : this.passenger.startDate,
          endDate: this.isWebview ? this._common.changeDateString(this.passenger.endDate) : this.passenger.endDate,
          voucher: this.voucher.maVoucher
        }

        let formValue = Object.assign(payload)
        localStorage.setItem('form-data', JSON.stringify(formValue))
        this.ApiService.postPayment(payload).subscribe((res) => {
          if (this.isApp) {
            // Gửi message vào app
            window.postMessage(JSON.stringify({ type: 'getLocalStorage' }));
            const submitButton = document.getElementById('submit');
            submitButton && submitButton.setAttribute('data-ttoan', this.payment.tongThanhToan || '0')
            window.location.href = 'webview/notification/app'
          } else {
            res.paymentUrl && (window.location.href = res.paymentUrl)
          }
          // res.paymentUrl && (window.location.href = res.paymentUrl)
          this.submited = false
        })
      }
    }
  }

  onController(e: number, f: any) {
    // If any input in any step has invalid, open modal notice "Please fill the form", then warning
    // If all input in any step hasn't invalid, fee next step
    switch (this.stepValue) {
      case 1:
        if (
          f.controls['cmt']?.invalid ||
          f.controls['nG_SINH']?.invalid ||
          f.controls['ten']?.invalid ||
          f.controls['mobi']?.invalid ||
          f.controls['email']?.invalid ||
          f.controls['daidien']?.invalid ||
          f.controls['chucvu']?.invalid ||
          !this.validBirthday
        ) {
          this._toast.show('Hãy nhập đúng phiếu thông tin !', { icon: 'error', classname: 'bg-danger text-light' });

          f.controls['cmt']!.touched = true
          f.controls['ten']!.touched = true
          f.controls['mobi']!.touched = true
          f.controls['email']!.touched = true
          if (this.isWebview) {

          } else {
            f.controls['nG_SINH']!.touched = true
          }
          if (this.isPerson && f.controls['gioi']?.invalid) {
            f.controls['gioi']!.touched = true
          }
          if (this.isCorp && (f.controls['daidien']?.invalid | f.controls['chucvu']?.invalid)) {
            f.controls['daidien']!.touched = true
            f.controls['chucvu']!.touched = true
          }
        } else this.stepValue += e
        break
      case 2:
        if (
          f.controls['moiquanhe']?.invalid ||
          f.controls['tenP']?.invalid ||
          f.controls['cmtP']?.invalid ||
          f.controls['mobiP']?.invalid ||
          f.controls['emailP']?.invalid ||
          f.controls['nG_SINH']?.invalid ||
          f.controls['gioiP']?.invalid ||
          !this.validBirthdayP || this.wrongBirth
        ) {
          this._toast.show('Hãy nhập đúng phiếu thông tin !', { icon: 'error', classname: 'bg-danger text-light' });

          f.controls['moiquanhe']!.touched = true
          f.controls['tenP']!.touched = true
          f.controls['cmtP']!.touched = true
          f.controls['mobiP']!.touched = true
          f.controls['emailP']!.touched = true
          f.controls['nG_SINH']!.touched = true
          f.controls['gioiP']!.touched = true
        } else this.stepValue += e
        break
      case 3:
        if (
          f.controls['startDate']?.invalid ||
          f.controls['goi']?.invalid || (this.fee.phi == '') || !this.validDate
        ) {
          this._toast.show('Hãy nhập đúng phiếu thông tin !', { icon: 'error', classname: 'bg-danger text-light' });

          f.controls['startDate']!.touched = true
          f.controls['goi']!.touched = true
        } else {
          this.stepValue += e

          if (this.isWebview && e === 1) {
            this.onSaveClient()
          }
        }
        break
      case 4:
        this.stepValue += e
        break
      default:
        this._toast.show('Hãy nhập đúng phiếu thông tin !', { icon: 'error', classname: 'bg-danger text-light' });

    }
  }

  onSaveClient() {
    const totalFee = this.fee.tongphi as string
    const totalPay = this.form.ttoan as string
    this.payment.tongThanhToan = parseFloat(totalPay.replace(/,/g, '')).toString()
    this.payment.tongphi = parseFloat(totalFee.replace(/,/g, '')).toString()
    let payload = {
      ...this.payment,
      user_Code: this.form.user_Code || this.payment.user_Code || '',
      hoten: this.form.ten.trim(),
      ngaysinh: this.isWebview ? this._common.changeDateString(this.form.nG_SINH) : this.form.nG_SINH,
      ngaytao: this.now,
      diachi: this.form.dchi.trim(),
      sodienthoai: this.form.mobi,
      cccd: this.form.cmt,
      email: this.form.email,
      sanPham: 'Bảo hiểm sức khỏe ACare',
      thietBi: 'app',
      loaibaohiem: 'Bảo hiểm sức khỏe ACare',
      goibaohiem: this.goibaohiem.find((i: any) => i.value === this.bill.goi)?.label_api,
      thoihan: '12',
      thoigianthanhtoan: this.now,
      webview: this.isWebview.toString(),
      gioitinh: this.genders.find((i: any) => i.value === this.form.gioi)?.label,
      voucher: this.voucher.maVoucher
    }
    this.ApiService.postPayment(payload).subscribe(res => {
      this.payment.idDangKy = this.form.iddangky = res.idDangKy
    })
  }

  // BAOHIEM20220824015137
  getDiscount(param: string) {
    this.voucher = {
      ...VOUCHER_TEMPLATE
    }
    this.appVoucher? (this.voucher.maVoucher = this.appVoucher, param=this.appVoucher) : null
    this.save = 0
    this.form.ttoan = this._common.numberWithCommas(this.fee.strTong)
    this.form.magioithieu = this.userCode ? this.userCode : this.form.magioithieu
    // this.form.nguoiGioiThieu = this.userCode? this.form.nguoiGioiThieu: ''
    if(param.includes('MGVOUCHER')){
      this.form.magioithieu = this.userCode = ''
      this.form.nguoiGioiThieu = ''
    }
    this.ma_nvAppVoucher = undefined
    if (!this.fee.phi) {
      this._toast.show('Chưa có phí để áp dụng voucher, hãy hoàn thành các thông tin!', { icon: 'error', classname: 'bg-danger text-light' });
    }
    this._common.getDiscount(param, this.fee.tongphi, 'VBI07',this.form.user_Code, this.isWebview).subscribe((data) => {
      if (data.status) {
        this.voucher = data
        this.save = data.save
        this.form.ttoan = data.newTongPhi
        data.ma_nv ? (this.form.magioithieu = data.ma_nv, this.getLauncher(this.form.magioithieu)) : null
        data.ma_nv ? this.ma_nvAppVoucher = data.ma_nv : null
        this.isTrueVoucher == true ? this.isTrueVoucher = true
          : (this.isTrueVoucher = true, this._toast.show('Dùng voucher thành công !', { icon: 'check_circle', classname: 'bg-success text-light' }))
      } else {
        this._toast.show('Dùng voucher thất bại! Hãy kiểm tra lại.', { icon: 'error', classname: 'bg-danger text-light' });
        this.isTrueVoucher = false
      }
    })
  }

  onChangeCheckbox() {
    // this.isInner ? this.form.dbh = 'D' : this.form.dbh = 'M'
    this.isPerson && this.isCorp === false
      ? (this.form.lkh = 'C')
      : (this.form.lkh = 'T')
  }

  upload(e: any, type: number) {
    let formData = new FormData()
    for (let file of e.target.files) {
      formData.append('files', file)
    }
    this._common.uploads(formData).subscribe((res) => {
      this._toast.show('Tải file thành công !', { icon: 'check_circle', classname: 'bg-success text-light' })
      type
        ? (this.tempFile.back = res[0].name)
        : (this.tempFile.front = res[0].name)
    })
  }

  confirmFile(): void {
    this._toast.show('Cập nhật ảnh CMT/CCCD thành công !', { icon: 'check_circle', classname: 'bg-success text-light' })
    this.form.files = this.tempFile
  }

  removeFile(type: number): void {
    this._toast.show('Xóa file thành công !', { icon: 'check_circle', classname: 'bg-success text-light' })
    type
      ? (this.tempFile.back = '')
      : (this.tempFile.front = '')
  }

  onCapitalize(type: number): void {
    type
      ? this.form.ten = this._common.onCapitalize(this.form.ten)
      : this.passenger.tenP = this._common.onCapitalize(this.passenger.tenP)
  }

  onNumberIn(type: number) {
    type
      ? this.isNumberIn = this._common.onNumberIn(this.form.ten)
      : this.isNumberInP = this._common.onNumberIn(this.passenger.tenP)
  }

  getLauncher(param: string) {
    param = param?.trim()
    this.form.nguoiGioiThieu = ''
    this.isTrueLauncher = false;
    if (param && param.includes('A') && param.length == 9) {
      this.ApiService.getLauncher(param).subscribe((data) => {
        this.form.nguoiGioiThieu = data?.ho_ten
        data?.ho_ten ? (this.form.user_Code = this.payment.user_Code = param, this.isTrueLauncher = true) : null
      })
    }
    else if (param && !param.includes('A') && param.length == 10) {
      this.ApiService.getLauncherByPhone(param).subscribe((data) => {
        this.form.nguoiGioiThieu = data[0]?.ho_ten
        data[0]?.ho_ten ? (this.form.user_Code = this.payment.user_Code = data[0]?.ma_nv, this.isTrueLauncher = true) : null
      })
    }
  }

  policyHandler() {
    this.form.goisanpham = this.goibaohiem.find((item: any) => item.value === this.bill.goi)?.label
  }

  birthdayHandler(birthday: any) {
    this.isWebview ? birthday = this._common.changeDateString(birthday) : birthday;

    // const birth = moment(birthday, 'YYYY-MM-DD').startOf('day')
    // const current = moment().startOf('day')
    // const range = moment.duration(current.diff(birth)).asYears()

    const minBirth = new Date(this.currentYear - 65, this.currentMonth, this.currentDate)
    const maxBirth = new Date(this.currentYear, this.currentMonth, this.currentDate - 60)
    let birthdayDate = new Date(birthday);
    birthdayDate.setHours(0);
    let today = new Date();
    today.setHours(12);
    const age = (Number(new Date()) - Number(birthdayDate)) / 31557600000;
    if (birthdayDate >= minBirth && birthdayDate <= maxBirth) {
      this.wrongBirth = false
    }
    else {
      this.wrongBirth = true
    }

    if (age < 1) {
      this.bill.tuoi = 1
    } else if (age >= 1 && age < 4) {
      this.bill.tuoi = 2
    } else if (age >= 4 && age < 7) {
      this.bill.tuoi = 3
    } else if (age >= 7 && age < 10) {
      this.bill.tuoi = 4
    } else if (age >= 10 && age < 19) {
      this.bill.tuoi = 5
    } else if (age >= 19 && age < 31) {
      this.bill.tuoi = 6
    } else if (age >= 31 && age < 41) {
      this.bill.tuoi = 7
    } else if (age >= 41 && age < 51) {
      this.bill.tuoi = 8
    } else if (age >= 51 && age < 61) {
      this.bill.tuoi = 9
    } else if (age >= 61 && age <= 65) {
      this.bill.tuoi = 10
    }
  }

  getFee() {
    if (this.wrongBirth || !this.bill.goi) {
      return
    }
    if (this.passenger.gioiP == 'male') {
      this.bill.thaisan = false;
    }
    this.fee = { ...FEE_TEMPLATE }
    this._common.getFee(API_PATH.ACARE_FEE, this.bill).subscribe((data) => {
      this.fee = data
      this.payment.tongThanhToan = this.payment.tongphi = this.form.ttoan = data.strTong
      this.form.ttoan = this._common.numberWithCommas(this.form.ttoan)
      this.voucher.maVoucher ? this.getDiscount(this.voucher.maVoucher) : null
    });
  }

  pattern = VALID
  error = ERROR
  steps = BHSK_STEPS
  genders = GENDERS
  mqhnguoimua = BHSK_RELATIVE
  goibaohiem = BHSKACARE_INSURANCE
  agerange = ACARE_AGE
}
