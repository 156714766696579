<div class="contact">
  <div class="container">
  <div class="contact__content">
    <p class="contact__router-line"><a routerLink="/" class="contact__router-line">{{'COMMON.HOME'|translate}}</a> > {{'HEADER.CONTACT'|translate}} </p>
    <p class="contact__description">{{'CONTACT.P1'|translate}}</p>
  </div>

  <div class="contact__login">
    <div class="bg">
      <div class="contact__text">
        <p class="contact__login-head">{{'CONTACT.P2'|translate}}</p>
        <p class="contact__login-label">{{'CONTACT.P3'|translate}}</p>
        <ul class="contact__finme-special">
        <li class="finme-special__label" *ngFor="let item of special">
          <span class="material-icons">star</span>
          <span class="finme-special__child">{{item.label|translate}}</span>
        </li>
        </ul>
      </div>
      <img src="/assets/images/contact-us-no-bg.svg" alt="family" class="contact__image">
    </div>
    <app-button routerLink="/login" [label]="'COMMON.LOGIN'|translate" [getStyle]="'fill'"></app-button>
  </div>

  <div class="contact__needhelp">
    <p class="contact-needhelp__head">{{'CONTACT.HELP'|translate}}</p>
    <div class="contact-help__thanks">
      {{'CONTACT.THANKS'|translate}}
      <br>
      {{'CONTACT.CHOOSE'|translate}}
    </div>
    <div class="contact-help__low">
      <div class="contact__needhelp__image">
        <img src="/assets/images/connectwhatapp.svg" alt="">
      </div>
      <div class="contact__needhelp__text">
        <span class="text">{{'CONTACT.CONTACT'|translate}}</span>
        <br>
        <a href="tell:02888815818"><span class="number">02888815818</span></a>
      </div>
    </div>
  </div>
</div>
</div>
