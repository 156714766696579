import { BOOLEAN_SELECT } from 'src/utils/constant';
import { ERROR } from 'src/utils/validation';
import { ApiService } from '@services/api.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService } from '@services/toast.service';
import { TokenService } from '@services/token.service';
import { VoucherService } from '@services/voucher/voucher.service';
import { ITProduct } from '@models/product.model';

@Component({
  selector: 'app-voucher-edit',
  templateUrl: './voucher-edit.component.html',
  styleUrls: ['./voucher-edit.component.css']
})
export class VoucherEditComponent implements OnInit {

  error = ERROR
  boolean = BOOLEAN_SELECT
  voucher: any = {
    id: 0,
    tenSanPham: '',
    loaiVoucher: '',
    maVoucher: '',
    ngayHetHan: '',
    soLuong: 0,
    phanTram: 0,
    hoaHong: 0
  }
  isPercent: boolean = true;
  isPercentWrong: boolean = false;
  isDiscountWrong: boolean = false;
  voucherTypes = [
    { id: 1, name: 'Phần trăm' },
    // { id: 2, name: 'Giá trị' },
  ]
  products: ITProduct[] = []
  minFee: any;
  revenue: any;
  revenueS: string='';
  revenuePercent: any;
  voucherId: number = 0
  page: number = 1
  percent: number = 0;
  totalDiscountP: number = 0;
  totalDiscount: any = '';
  total: number = 10
  pageSize: number = 10
  disableInput: boolean = false
  form: FormGroup = this.formBuilder.group({
    tenSanPham: ['', Validators.required],
    goiSanPham: ['', Validators.required],
    loaiVoucher: ['', Validators.required],
    maVoucher: [''],
    ngayHetHan: ['', Validators.required],
    soLuong: ['', Validators.required],
    voucher_conlai: ['', Validators.required],
    phanTram: [0, Validators.required],
    minRevenue: [0, Validators.required],
    minFee: [0, Validators.required],
  })

  constructor(
    private _service: VoucherService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    public _toast: ToastService,
    private _token: TokenService,
    private _apiService: ApiService,
  ) {
    this.voucherId = this.route.snapshot.params['id']
  }

  ngOnInit(): void {
    this.voucherId !== 0 && this.getVoucherByID(this.voucherId)
    this.getProducts();
    this.form.controls['loaiVoucher'].setValue('Phần trăm')
  }

  getVoucherByID(id: number) {
    this._service.getVoucherByID(id).subscribe(res => {
      console.log(res)
      this.voucher = res
      this.onInitData(this.voucher)
    })
  }

  getProducts() {
    this._apiService.getProducts('vi').subscribe((data) => {
      this.products = data
      this.onSortProducts()
      this.onFilterData()
    })
  }

  calculation() {
    // this.onCheckValue()
    let revenuePercent = (this.products.find((item: any) => item.name === this.form.controls['tenSanPham'].value)?.heSo || 0)*100
    if (this.isPercent) {
        if(this.form.controls['phanTram'].value<= revenuePercent){
          this.isPercentWrong = false;
          this.totalDiscountP = Number(( revenuePercent - this.form.controls['phanTram'].value).toFixed(2))
        }
        else {
          this.isPercentWrong = true;
          this.totalDiscountP = 0}
    }
    else {
      this.isPercentWrong = false;
      if(this.form.controls['phanTram'].value <= this.revenue){
      this.isDiscountWrong = false;
      this.totalDiscount = 'Hoa hồng sẽ giảm đi '+ this.form.controls['phanTram'].value+'.000 VNĐ'
      }
      else {
        this.isDiscountWrong = true;
        this.totalDiscount = 'Giá trị voucher vượt quá hoa hồng tối thiểu'
      }
    }
  }
  

  onCheckValue(){
    switch(this.form.controls['tenSanPham'].value){
      case 'Bảo hiểm TNDS xe mô tô':
      this.revenue = 21
      this.revenueS = '21.000 VNĐ'
      this.revenuePercent = 40
      this.minFee = '60.500 VNĐ'
      break
      case 'Bảo hiểm TNDS xe ô tô':
      this.revenue = 86
      this.revenueS = '86.000 VNĐ'
      this.revenuePercent = 20
      this.minFee = '480.700 VNĐ'
      break
      case "Bảo hiểm MIC Care":
      this.revenue = 0
      this.revenuePercent = 15
      this.minFee = 0
      break
      case "Bảo hiểm tai nạn cá nhân GIC":
      this.revenue = 45
      this.revenueS = '45.000 VNĐ'
      this.revenuePercent = 30
      this.minFee = '150.000 VNĐ'
      break
      case "Bảo hiểm sức khoẻ A-CARE":
      this.revenue = 346
      this.revenueS = '346.000 VNĐ'
      this.revenuePercent = 26
      this.minFee = '1.481.200 VNĐ'
      break
      case "Bảo hiểm trợ cấp nằm viện BSH":
      this.revenue = 315
      this.revenueS = '315.000 VNĐ'
      this.revenuePercent = 35
      this.minFee = '1.000.000 VNĐ'
      break
      case "Bảo hiểm TNDS xe ô tô  BSH":
      this.revenue = 129
      this.revenueS = '129.000 VNĐ'
      this.revenuePercent = 30
      this.minFee = '480.700 VNĐ'
      break
      case "Bảo hiểm tai nạn cá nhân BSH":
      this.revenue = 162
      this.revenue = '162.000 VNĐ'
      this.revenuePercent = 36
      this.minFee = '500.000 VNĐ'
      break
      default:
      this.revenue = 0
      this.revenuePercent = 0
      this.minFee = 0
    }
  }

  onSortProducts() {
    this.products.sort((a, b) => {
      return a.stt - b.stt
    })
  }

  onFilterData() {
    let filteredProd = this.products?.filter(prod => prod.status)
    this.products = filteredProd
    // console.log(filteredProd)
  }

  onInitData(data: any) {
    this.form.controls['loaiVoucher'].setValue(data.loaiVoucher)
    this.form.controls['maVoucher'].setValue(data.maVoucher)
    this.form.controls['tenSanPham'].setValue(data.tenSanPham)
    this.form.controls['ngayHetHan'].setValue(data.ngayHetHan)
    this.form.controls['soLuong'].setValue(data.soLuong)
    this.form.controls['voucher_conlai'].setValue(data.voucher_conlai)
    this.form.controls['phanTram'].setValue(data.phanTram)
    this.form.controls['hoaHong'].setValue(data.soTienHoaHong)
    this.onCheckType()
  }
  onCheckType() {
    this.form.controls['loaiVoucher'].value == 'Phần trăm' ? this.isPercent = true : this.isPercent = false;
  }
  onSave() {
    let payload = {
      id: this.voucherId || 0,
      loaiVoucher: this.form.controls['loaiVoucher']?.value,
      maVoucher: this.form.controls['maVoucher']?.value || '',
      tenSanPham: this.form.controls['tenSanPham']?.value,
      ngayHetHan: this.form.controls['ngayHetHan']?.value,
      soLuong: this.form.controls['soLuong']?.value,
      voucher_conlai: this.form.controls['voucher_conlai']?.value || '',
      phanTram: this.form.controls['phanTram']?.value,
      soTienHoaHong: this.totalDiscountP,
      hoaHong: this.totalDiscountP,
      maSp: this.products.find((item: any) => item.name === this.form.controls['tenSanPham'].value)?.code ||''
    }
    console.log(payload)
    this.voucherId!=0 ? this.onUpdate(payload) : this.onCreate(payload)
  }

  onCreate(payload: any): void {
    this._service.postVoucher(payload).subscribe((res: any) => {
      if (res.status === 401) {
        alert('Phiên đăng nhập đã hết hạn !')
        this._token.signOut()
        window.location.href = 'admin/login'
      }
      else if (res.status === 400 || res.status === 500) {
        this._toast.show('Thêm mới voucher thất bại !', { icon: 'error', classname: 'bg-danger text-light' })
      } else {
        this._toast.show('Thêm mới voucher thành công !', { icon: 'check_circle', classname: 'bg-success text-light' })
        this.router.navigate(['/admin/vouchers-manager']);
      }
    })
  }

  onUpdate(payload: any): void {
    this._service.putVoucher(payload).subscribe((res: any) => {
      if (res.status === 401) {
        alert('Phiên đăng nhập đã hết hạn !')
        this._token.signOut()
        window.location.href = 'admin/login'
      }
      else if (res.status === 400 || res.status === 500) {
        this._toast.show('Cập nhật voucher thất bại !', { icon: 'error', classname: 'bg-danger text-light' })
      } else {
        this._toast.show('Cập nhật voucher thành công !', { icon: 'check_circle', classname: 'bg-success text-light' })
        this.router.navigate(['/admin/vouchers-manager']);
      }
    })
  }

}
