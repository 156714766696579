import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { ITProduct } from '@models/product.model';
import { catchError, Observable, of, switchMap } from 'rxjs';
import { API_PATH } from 'src/utils/constant';

@Injectable({
  providedIn: 'root'
})
export class ProductsService {

  constructor(
    private _http: HttpClient,
  ) { }

  getVbiCarePackages(): Observable<any> {
    return this._http.get(`${environment.vbiURL}${API_PATH.VBICARE_PACKAGES}`)
      .pipe(catchError((error) => {
        return of(error);
      }), switchMap((response) => {
        return of(response);
      }));
  }

  getProducts(lang?: string): Observable<ITProduct[]> {
    const url = lang === 'en' ? environment.enURL : environment.apiURL
    return this._http.get(`${url}${API_PATH.PRODUCT_LIST}`)
      .pipe(catchError((error) => {
        return of(error);
      }), switchMap((response) => {
        return of(response);
      }));
  }

  postProduct(data: ITProduct, lang?: string): Observable<ITProduct> {
    const url = lang === 'en' ? environment.enURL : environment.apiURL
    return this._http.post(`${url}${API_PATH.PRODUCT_LIST}`, data)
      .pipe(catchError((error) => {
        return of(error);
      }), switchMap((response) => {
        return of(response);
      }));
  }

  getProductbyMeta(meta: string, lang?: string): Observable<ITProduct> {
    const url = lang === 'en' ? environment.enURL : environment.apiURL
    return this._http.get(`${url}${API_PATH.PRODUCT_LIST}/${meta}`)
      .pipe(catchError((error) => {
        return of(error);
      }), switchMap((response) => {
        return of(response);
      }));
  }

  getProductbyID(id: number, lang?: string): Observable<ITProduct> {
    const url = lang === 'en' ? environment.enURL : environment.apiURL
    return this._http.get(`${url}${API_PATH.PRODUCT_LIST}/id/${id}`)
      .pipe(catchError((error) => {
        return of(error);
      }), switchMap((response) => {
        return of(response);
      }));
  }

  putProduct(id: number, data: ITProduct, lang?: string): Observable<ITProduct> {
    const url = lang === 'en' ? environment.enURL : environment.apiURL
    return this._http.put(`${url}${API_PATH.PRODUCT_LIST}/${id}`, data)
      .pipe(catchError((error) => {
        return of(error);
      }), switchMap((response) => {
        return of(response);
      }));
  }

  deleteProduct(id: number, lang?: string): Observable<ITProduct> {
    const url = lang === 'en' ? environment.enURL : environment.apiURL
    return this._http.delete(`${url}${API_PATH.PRODUCT_LIST}/${id}`)
      .pipe(catchError((error) => {
        return of(error);
      }), switchMap((response) => {
        return of(response);
      }));
  }

  getCompanies(): Observable<any[]> {
    return this._http.get(`${environment.apiURL}${API_PATH.COMPANIES}`)
      .pipe(catchError((error) => {
        return of(error);
      }), switchMap((response) => {
        return of(response);
      }));
  }

}
