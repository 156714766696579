import { ITPayment } from '@models/payment.model'
import { ApiService } from '@services/api.service'
import { ITMotoForm } from '@models/motofee.model'
import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core'
import { ITFee } from '@models/fee.model'
import { ITMotoFee } from '@models/motofee.model'
import { MOTO_STEPS } from 'src/utils/steps'
import { ITVoucher } from '@models/voucher.model'
import { API_PATH } from 'src/utils/constant'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { ERROR, VALID } from 'src/utils/validation'
import { ActivatedRoute, Router } from '@angular/router'
import { eff, FEE_TEMPLATE, now, PAYMENT_TEMPLATE, VOUCHER_TEMPLATE, voucherInfor  } from 'src/utils/product'
import { MOTO_BILL_TEMPLATE, MOTO_FORM_TEMPLATE, MOTO_RESPONS, MOTO_VEHICLE_TYPES } from 'src/utils/product/moto'
import { CommonService } from '@services/common/common.service'
import { ProtectService } from '@services/protect/protect.service'
import * as moment from 'moment'
import { ToastService } from '@services/toast.service'

@Component({
  selector: 'app-motobike',
  templateUrl: './motobike.component.html',
  styleUrls: ['./motobike.component.css'],
})
export class MotobikeComponent implements OnInit {
  @Output() setIsPayment = new EventEmitter()
  isPayment: boolean = true
  @Input() code: string = ''
  @Input() isWebview: boolean = false
  @Input() isApp: boolean = false
  @Input() isShared: boolean = false
  @Input() appVoucher: any = null
  @Input() userCode: any = null
  @Input() meetVoucher: any = null
  public isBack = false
  public stepValue = 1
  public isPerson = true
  public isCorp = false
  public isOther = false
  public isOtherView = false
  public isOtherPerson = true
  public isOtherCorp = false
  isTrueVoucher: boolean = true;
  isTrueLauncher: boolean = true;
  public save = 0
  public fee: ITFee = {
    ...FEE_TEMPLATE
  }
  public apiPath: string = ''
  birthYear: any = new Date().setFullYear(new Date().getFullYear() - 18)
  maxBirth: any = new Date(this.birthYear).toISOString().substring(0, 10)
  public validBirth = true
  public validOtherBirth = true
  files: any
  fileMs: any
  public bill: ITMotoFee = {
    ...MOTO_BILL_TEMPLATE
  }
  public form: ITMotoForm = {
    ...MOTO_FORM_TEMPLATE
  }
  public voucher: ITVoucher = {
    ...VOUCHER_TEMPLATE
  }
  public payment: ITPayment = {
    ...PAYMENT_TEMPLATE
  }
  submited: boolean = false
  tempFile: any = {
    back: '',
    front: '',
  }
  tempFileM: any = {
    back: '',
    front: '',
  }
  webviewNow: any;
  validDate: boolean = true;
  isNumberIn: boolean = false;
  isNumberInP: boolean = false;
  ma_nvAppVoucher: any = undefined

  constructor(
    private ApiService: ApiService,
    private _common: CommonService,
    private _protect: ProtectService,
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private router: Router,
    public _toast: ToastService,
  ) { }

  ngOnInit(): void {
    this.onFinmeInit(this.code)
    this.isWebview ? (this.getParams(), this.webviewNow = moment(this.now).format('DD-MM-YYYY'),
      this.bill.ngaY_HL = moment(this.bill.ngaY_HL).format('DD-MM-YYYY'),
      this.bill.ngaY_KT = moment(this.bill.ngaY_KT).format('DD-MM-YYYY')) : this.handleUserCode()
    if (this.appVoucher?.length == 10) {
      this.voucher.maVoucher = this.appVoucher
    }
    if (this.meetVoucher){
      this.voucher.maVoucher = this.meetVoucher
    }
  }

  onFinmeInit(code: string) {
    switch (code) {
      case 'MIC01':
        this.form.nv = '2BL'
        this.form.tensanpham = 'Bảo hiểm TNDS xe mô tô MIC'
        break
      case 'PTI01':
        this.form.nv = 'PTI01'
        this.form.tensanpham = 'Bảo hiểm TNDS xe mô tô PTI'
        break
      default:
        console.warn('I can not find you product, please check your database!!!')
    }
  }

  handleUserCode() {
    const user = this.route.snapshot.params['user']
    if (user?.length == 9) {
      this.form.magioithieu = user
      this.getLauncher(user)
    }
  }

  upload(e: any, type: number, isOther: boolean) {
    let formData = new FormData()
    for (let file of e.target.files) {
      formData.append('files', file)
    }
    this._common.uploads(formData).subscribe((res) => {
      this._toast.show('Tải file thành công !', { icon: 'check_circle', classname: 'bg-success text-light' })
      if (isOther) {
        type
          ? (this.tempFileM.back = res[0].name)
          : (this.tempFileM.front = res[0].name)
      } else {
        type
          ? (this.tempFile.back = res[0].name)
          : (this.tempFile.front = res[0].name)
      }
    })
  }

  confirmFile(isOther: boolean): void {
    this._toast.show('Cập nhật ảnh CMT/CCCD thành công !', { icon: 'check_circle', classname: 'bg-success text-light' })
    isOther
      ? (this.form.fileMs = this.tempFileM)
      : (this.form.files = this.tempFile)
  }

  removeFile(type: number, isOther: boolean): void {
    this._toast.show('Xóa file thành công !', { icon: 'check_circle', classname: 'bg-success text-light' })
    if (isOther) {
      type
        ? (this.tempFileM.back = '')
        : (this.tempFileM.front = '')
    } else {
      type
        ? (this.tempFile.back = '')
        : (this.tempFile.front = '')
    }
  }

  // Get date, month, year listen ngaY_HL, then set ngaY_KT>
  onChangeFromDate() {
    this.validDate = this._common.validDateString(this.bill.ngaY_HL, this.isWebview);
    this.isWebview ? this.bill.ngaY_KT = moment(this._common.onChangeFromDate(this._common.changeDateString(this.bill.ngaY_HL))).format('DD-MM-YYYY') : this.bill.ngaY_KT = this._common.onChangeFromDate(this.bill.ngaY_HL);
  }

  onValidBirthday(birthday: Date | string) {
    if (this.isPerson) {
      this.validBirth = this._common.validBirthday(birthday, this.isWebview)
    }
    else {
      this.validBirth = true;
    }
  }

  onValidOtherBirthday(birthday: Date | string) {
    if (this.isOtherPerson) {
      this.validOtherBirth = this._common.validBirthday(birthday, this.isWebview)
    }
    else {
      this.validOtherBirth = true;
    }
  }

  // If magioithieu.length < 1, set nguoiGioiThieu = ''
  getEmptyLauncher() {
    this.form.magioithieu = ''
    this.form.nguoiGioiThieu = ''
  }

  // If maVoucher.length < 1, set tongphi = phi
  getOldFee() {
    this.save = 0

    this.fee.tongphi = this.fee.phi
    this.form.ttoan = this.fee.tongphi = this.fee.phi
  }

  getFee() {
    const payload = {
      ...this.bill,
      ngaY_HL: this.isWebview ? this._common.changeDateString(this.bill.ngaY_HL) : this.bill.ngaY_HL,
      ngaY_KT: this.isWebview ? this._common.changeDateString(this.bill.ngaY_KT) : this.bill.ngaY_KT
    }
    this.fee = { ...FEE_TEMPLATE }
    this._common.getFee(API_PATH.MOTOR_FEE, payload).subscribe((data) => {
      this.fee = data
      this.payment.tongThanhToan = this.payment.tongphi = this.form.ttoan = data.strTong
      this.form.ttoan = this._common.numberWithCommas(this.form.ttoan)
      this.voucher.maVoucher ? this.getDiscount(this.voucher.maVoucher) : null
    });
  }

  // BAOHIEM20220824015137
  getDiscount(param: string) {
    this.voucher = {
      ...VOUCHER_TEMPLATE
    }
    this.appVoucher? (this.voucher.maVoucher = this.appVoucher, param=this.appVoucher) : null
    this.save = 0
    this.form.ttoan = this._common.numberWithCommas(this.fee.strTong)
    this.form.magioithieu = this.userCode ? this.userCode : this.form.magioithieu
    // this.form.nguoiGioiThieu = this.userCode? this.form.nguoiGioiThieu: ''
    if(param.includes('MGVOUCHER')){
      this.form.magioithieu = this.userCode = ''
      this.form.nguoiGioiThieu = ''
    }
    this.ma_nvAppVoucher = undefined
    if (!this.fee.phi) {
      this._toast.show('Chưa có phí để áp dụng voucher, hãy hoàn thành các thông tin!', { icon: 'error', classname: 'bg-danger text-light' });
    }
    this._common.getDiscount(param, this.fee.tongphi, this.code,this.form.user_Code, this.isWebview).subscribe((data) => {
      if (data.status) {
        this.voucher = data,
          this.save = data.save
        this.form.ttoan = data.newTongPhi
        data.ma_nv ? (this.form.magioithieu = data.ma_nv, this.getLauncher(this.form.magioithieu)) : null
        data.ma_nv ? this.ma_nvAppVoucher = data.ma_nv : null
        this.isTrueVoucher == true ? this.isTrueVoucher = true
          : (this.isTrueVoucher = true, this._toast.show('Dùng voucher thành công !', { icon: 'check_circle', classname: 'bg-success text-light' }))
      } else {
        this._toast.show('Dùng voucher thất bại! Hãy kiểm tra lại.', { icon: 'error', classname: 'bg-danger text-light' });
        this.isTrueVoucher = false
      }
    })
  }

  getLauncher(param: string) {
    param = param?.trim()
    this.form.nguoiGioiThieu = ''
    this.isTrueLauncher = false;
    if (param && param.includes('A') && param.length == 9) {
      this.ApiService.getLauncher(param).subscribe((data) => {
        this.form.nguoiGioiThieu = data?.ho_ten
        data?.ho_ten ? (this.form.user_Code = this.payment.user_Code = param, this.isTrueLauncher = true) : null
      })
    }
    else if (param && !param.includes('A') && param.length == 10) {
      this.ApiService.getLauncherByPhone(param).subscribe((data) => {
        this.form.nguoiGioiThieu = data[0]?.ho_ten
        data[0]?.ho_ten ? (this.form.user_Code = this.payment.user_Code = data[0]?.ma_nv, this.isTrueLauncher = true) : null
      })
    }
  }


  openCentered(content: TemplateRef<any>) {
    this.modalService.open(content, { centered: true })
  }

  openImageModal(content: TemplateRef<any>, isOther: boolean) {
    this.isOtherView = isOther
    this.modalService.open(content, { size: window.innerWidth > 500 ? 'xl' : 'lg' });
  }

  getParams() {
    this.router.url.includes('&')
      ? (this.form.user_Code = this.payment.user_Code = this.router.url.slice(
        -9,
      ))
      : ''
  }

  getBack() {
    this.modalService.dismissAll()

    this.isPayment = false
    this.setIsPayment.emit(this.isPayment)
    // Hàm getBack sẽ ghi lại isPayment bằng false, đồng thời thông qua EventEmitter bắn value isPayment này ra component cha Product

    this.isBack = false
  }

  onChangeCheckbox() {
    this.isPerson && this.isCorp === false
      ? (this.form.lkh = 'C')
      : (this.form.lkh = 'T')
    this.isOther ? (this.form.dbhm = 'C') : (this.form.dbhm = 'K')
    if (this.isOther) {
      this.isOtherPerson && this.isOtherCorp === false
        ? (this.form.lkhm = 'C')
        : (this.form.lkhm = 'T')
    }
  }

  onSubmit(f: any) {
    if (f.invalid || this.fee.phi == '') {
      this._toast.show('Hãy nhập đúng phiếu thông tin !', { icon: 'error', classname: 'bg-danger text-light' });

      f.controls['loaI_XE']!.touched = true
      f.controls['bieN_XE']!.touched = true
      f.controls['sO_MAY']!.touched = true
      f.controls['sO_KHUNG']!.touched = true
      f.controls['mobi']!.touched = true
      f.controls['ten']!.touched = true
      f.controls['cmt']!.touched = true
      f.controls['nG_SINH']!.touched = true
      f.controls['email']!.touched = true
      f.controls['dchi']!.touched = true

      if (this.isOther) {
        f.controls['mobim']!.touched = true
        f.controls['tenm']!.touched = true
        f.controls['cmtm']!.touched = true
        f.controls['nG_SINHM']!.touched = true
        f.controls['emailm']!.touched = true
        f.controls['dchim']!.touched = true
      }
    } else {
      this.submited = true
      this.onChangeCheckbox()
      const totalFee = this.fee.tongphi as string
      const totalPay = this.form.ttoan as string
      this.payment.tongThanhToan = parseFloat(totalPay.replace(/,/g, '')).toString()
      this.payment.tongphi = parseFloat(totalFee.replace(/,/g, '')).toString()
      let payload = {
        ...this.form,
        ...this.payment,
        ...this.bill,
        email: this.form.email || '',
        nG_SINH: this.isWebview ? this._common.changeDateString(this.form.nG_SINH) : this.form.nG_SINH,
        ngaY_HL: this.isWebview ? this._common.changeDateString(this.bill.ngaY_HL) : this.bill.ngaY_HL,
        ngaY_KT: this.isWebview ? this._common.changeDateString(this.bill.ngaY_KT) : this.bill.ngaY_KT,
        voucher: this.voucher.maVoucher
      }
      let formValue = Object.assign(payload)
      localStorage.setItem('form-data', JSON.stringify(formValue))

      this.ApiService.postPayment(payload).subscribe((res) => {
        if (this.isApp) {
          // Gửi message vào app
          window.postMessage(JSON.stringify({ type: 'getLocalStorage' }));
          const submitButton = document.getElementById('submit');
          submitButton && submitButton.setAttribute('data-ttoan', this.payment.tongThanhToan || '0')
          window.location.href = 'webview/notification/app'
        } else {
          res.paymentUrl && (window.location.href = res.paymentUrl)
        }
        // res.paymentUrl && (window.location.href = res.paymentUrl)
        this.submited = false
      })
    }
  }

  onController(e: number, f: any) {
    // If any input in any step has invalid, open modal notice "Please fill the form", then warning
    // If all input in any step hasn't invalid, fee next step
    switch (this.stepValue) {
      case 1:
        if (
          f.controls['loaI_XE']?.invalid |
          f.controls['bieN_XE']?.invalid |
          f.controls['sO_MAY']?.invalid |
          f.controls['sO_KHUNG']?.invalid
        ) {
          this._toast.show('Hãy nhập đúng phiếu thông tin !', { icon: 'error', classname: 'bg-danger text-light' });

          f.controls['loaI_XE']!.touched = true
          f.controls['bieN_XE']!.touched = true
          f.controls['sO_MAY']!.touched = true
          f.controls['sO_KHUNG']!.touched = true
        } else this.stepValue += e
        break
      case 2:
        if (f.controls['ngaY_HL']?.invalid | f.controls['ngaY_KT']?.invalid || this.fee.phi == '') {
          this._toast.show('Hãy nhập đúng phiếu thông tin !', { icon: 'error', classname: 'bg-danger text-light' });
        } else this.stepValue += e
        break
      case 3:
        if (
          f.controls['mobi']?.invalid |
          f.controls['ten']?.invalid |
          f.controls['cmtm']?.invalid |
          f.controls['nG_SINHM']?.invalid |
          f.controls['email']?.invalid |
          f.controls['dchi']?.invalid || this.fee.phi == ''
        ) {
          if (
            this.isOther &&
            f.controls['mobim']?.invalid |
            f.controls['tenm']?.invalid |
            f.controls['cmtm']?.invalid |
            f.controls['nG_SINHM']?.invalid |
            f.controls['emailm']?.invalid |
            f.controls['dchim']?.invalid
          ) {
            f.controls['mobim']!.touched = true
            f.controls['tenm']!.touched = true
            f.controls['cmtm']!.touched = true
            f.controls['nG_SINHM']!.touched = true
            f.controls['emailm']!.touched = true
            f.controls['dchim']!.touched = true
          }
          this._toast.show('Hãy nhập đúng phiếu thông tin !', { icon: 'error', classname: 'bg-danger text-light' });

          f.controls['mobi']!.touched = true
          f.controls['ten']!.touched = true
          f.controls['cmt']!.touched = true
          f.controls['nG_SINH']!.touched = true
          f.controls['email']!.touched = true
          f.controls['dchi']!.touched = true
        } else {
          this.stepValue += e

          if (this.isWebview && e === 1) {
            this.onSaveClient()
          }
        }
        break
      case 4:
        this.stepValue += e
        break
      default:
        this._toast.show('Hãy nhập đúng phiếu thông tin !', { icon: 'error', classname: 'bg-danger text-light' });
    }
  }

  onSaveClient() {
    const totalFee = this.fee.tongphi as string
    const totalPay = this.form.ttoan as string
    this.payment.tongThanhToan = parseFloat(totalPay.replace(/,/g, '')).toString()
    this.payment.tongphi = parseFloat(totalFee.replace(/,/g, '')).toString()
    let payload = {
      ...this.payment,
      user_Code: this.form.user_Code || this.payment.user_Code || '',
      hoten: this.form.ten.trim(),
      ngaysinh: this.isWebview ? this._common.changeDateString(this.form.nG_SINH) : this.form.nG_SINH,
      ngaytao: this.now,
      diachi: this.form.dchi.trim(),
      sodienthoai: this.form.mobi,
      cccd: this.form.cmt,
      email: this.form.email,
      sanPham: this.form.tensanpham,
      thietBi: 'app',
      loaibaohiem: this.form.tensanpham,
      goibaohiem: 'Gói bảo hiểm TNDS Moto',
      thoihan: '12',
      thoigianthanhtoan: this.now,
      soKhung: this.form.sO_KHUNG,
      soMay: this.form.sO_MAY,
      bienXE: this.form.bieN_XE
    }

    this.ApiService.postPayment(payload).subscribe(res => {
      this.payment.idDangKy = this.form.iddangky = res.idDangKy
    })
  }

  onCapitalize(type: number): void {
    type
      ? this.form.ten = this._common.onCapitalize(this.form.ten)
      : this.form.tenm = this._common.onCapitalize(this.form.tenm)
  }

  onNumberIn(type: number) {
    type
      ? this.isNumberIn = this._common.onNumberIn(this.form.ten)
      : this.isNumberInP = this._common.onNumberIn(this.form.tenm)
  }

  now = now
  eff = eff
  pattern = VALID
  error = ERROR
  steps = MOTO_STEPS
  loaI_XE_types = MOTO_VEHICLE_TYPES
  TL_types = MOTO_RESPONS
  voucherInfor = voucherInfor
}
