import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-introduce',
  templateUrl: './introduce.component.html',
  styleUrls: ['./introduce.component.css']
})
export class IntroduceComponent implements OnInit {
  title = 'Finme - Introduce'

  constructor(private titleService:Title) { }

  ngOnInit(): void {
    this.titleService.setTitle(this.title);
  }
  upImage = [
    {
      id: 1, img: '/assets/frontend/images/career/about_company_tab.png',
    },
    {
      id: 2, img: '/assets/frontend/images/career/about_investors.png',
    },
    {
      id: 3, img: '/assets/frontend/images/career/about_team.png',
    },
    {
      id: 4, img: '/assets/frontend/images/career/about_award.png',
    },
  ]
  upContentFirt = [
    {
      id: 1, head: 'INTRO.1HEAD1', label: 'INTRO.1LABEL1', img: '/assets/frontend/images/career/BandoFinlife1.png',
    },
    {
      id: 2, head: 'INTRO.1HEAD2', label: 'INTRO.1LABEL2', img: '/assets/frontend/images/career/growing.png',
    },
    {
      id: 3, head: 'INTRO.1HEAD3', label: 'INTRO.1LABEL3', img: '/assets/frontend/images/career/visitor.png',
    },
  ]
  upContentSecond = [
    {
      id: 1, head: 'INTRO.2HEAD1', label: 'INTRO.2LABEL1', img: '/assets/frontend/images/career/mission.png',
    },
    {
      id: 2, head: 'INTRO.2HEAD2', label: 'INTRO.2LABEL2', img: '/assets/frontend/images/career/values.png',
    },
    {
      id: 3, head: 'INTRO.2HEAD3', label: 'INTRO.2LABEL3', img: '/assets/frontend/images/career/vision.png',
    },
  ]
}
