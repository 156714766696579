import { Component, OnInit, TemplateRef, Pipe, PipeTransform } from '@angular/core';
import { ExportService } from '@services/export.service';
import * as FileSaver from 'file-saver';
import { CartService } from '@services/cart/cart.service';
import { ITCart, ITCartBSHCar } from '@models/cart.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ERROR, VALID } from 'src/utils/validation';
import { GENDERS, STATUS_SELECTOR, STATUS_USER } from 'src/utils/constant';
import { ApiService } from '@services/api.service';
import { environment } from '@environments/environment';
import { ITCategory } from '@models/category.model';
import { ITProduct } from '@models/product.model';
import { ToastService } from '@services/toast.service';
import { ProtectService } from '@services/protect/protect.service';
import { BHSK_RELATIVE } from 'src/utils/product/vbicare';
import { CommonService } from '@services/common/common.service';
import { TokenService } from '@services/token.service';

@Component({
  selector: 'app-collaborators-manager',
  templateUrl: './collaborators-manager.component.html',
  styleUrls: ['./collaborators-manager.component.css']
})
export class CollaboratorsManagerComponent implements OnInit {
  prodEnv: boolean = environment.production
  cols:any=[]
  page: number = 1
  total: number = 10
  pageSize: number = 10
  trangthai: string = ''
  user: string = ''
  ma_nv: string = ''
  ma_nv_login: string = ''
  mess: string = ''
  isTrue:boolean=false
  dialogType: string = ''
  disableInput: boolean = false
  colInfo:any
  filter: FormGroup = this._formBuilder.group({
    idProduct: [''],
    idCategory: [''],
    keyword: [''],
    fromDate: [''],
    toDate: [new Date()],
    status: ['Thành công'],
  })
  form: FormGroup = this._formBuilder.group({
    ma_nv: ['', Validators.required],
    ho_ten: ['', Validators.required],
    cmnd: ['', Validators.required],
    so_dien_thoai: ['', Validators.required],
    email: ['', Validators.required],
    k_status: ['', Validators.required],
  })


  addForm: FormGroup = this._formBuilder.group({
    ma_nv: ['', Validators.required]
  })


  constructor(
    private _service: CartService,
    private ApiService: ApiService,
    private _formBuilder: FormBuilder,
    public _toast: ToastService,
    private _common: CommonService,
    private _modal: NgbModal,
    private _protect: ProtectService,
    private tokenStorage: TokenService,
  ) { }

  ngOnInit(): void {
    this.ma_nv_login = this.tokenStorage.getUser().ma_nv;
    this.getContractPages()
    this.getUserList(this.ma_nv_login, this.page, this.pageSize)
  }

  getUserList(user:string, page?:number, pageSize?:number, status?:number, user_code?:string){
    this._service.getUserList(user, page, pageSize).subscribe(res => {
      this.cols = res
    })
  }

  getUserByCode(code: number) {
    this._service.getUserByCode(code).subscribe(res => {
      // console.log(res)
      this.onInitData(res[0])
    })
  }

  getContractPages() {
    this._service.getContractPages(this.pageSize).subscribe(res => {
      this.total = res.tongbanghi
    })
  }


  clearOldData(){
    this.form.controls['ma_nv'].setValue('')
    this.form.controls['ho_ten'].setValue('')
    this.form.controls['cmnd'].setValue('')
    this.form.controls['so_dien_thoai'].setValue('')
    this.form.controls['email'].setValue('')
    this.form.controls['k_status'].setValue('')
  }
  onInitData(data: any) {
    // console.log(data)
    let trangthai = this.transfromStatus(data.k_status)
    this.clearOldData()
    this.form.controls['ma_nv'].setValue(data.ma_nv)
    this.form.controls['ho_ten'].setValue(data.ho_ten)
    this.form.controls['cmnd'].setValue(data.cmnd)
    this.form.controls['so_dien_thoai'].setValue(data.so_dien_thoai)
    this.form.controls['email'].setValue(data.email)
    this.form.controls['k_status'].setValue(trangthai)
  }

  onSave() {
    let payload = {
      ma_nv: this.form.controls['ma_nv'].value,
      ho_ten: this.form.controls['ho_ten'].value,
      cmnd: this.form.controls['cmnd'].value||null,
      email: this.form.controls['email'].value,
      so_dien_thoai: this.form.controls['so_dien_thoai'].value,
      k_status: this.form.controls['k_status'].value,
    }
    console.log(payload)
    return
    // this._service.putAcc(this.ma_nv, payload).subscribe(res => {
    //   console.log(res)
    //   res && (
    //     // this.getAccs(this.page,this.pageSize,this.trangthai,this.user),
    //     this._toast.show('Cập nhật thành công!', { icon: 'check_circle', classname: 'bg-success text-light' })
    //   )
    // })
  }

  onCheck(){
    let ma_nv = this.addForm.controls['ma_nv'].value
    this.mess=''
    this.isTrue=false
    this.colInfo=undefined
    if(!ma_nv){return}
    this.ApiService.getColInfo(ma_nv).subscribe(data=>{
      if(!data[0]){
        this.mess='Không tìm thấy thông tin Cộng tác viên!'
      }
      if(data[0].ma_nv==ma_nv){
        this.isTrue=true
        this.colInfo=data[0]
      }
    })
  }

  onAdd(){
    let ma_nv = this.addForm.controls['ma_nv'].value
    this.ApiService.postCol(this.ma_nv_login,ma_nv).subscribe(data=>{
      if(data){
        this._toast.show('Thêm Cộng tác viên thành công!', { icon: 'check_circle', classname: 'bg-success text-light' })
      }
    })
  }

  transfromStatus(number:number){
    let rs=''
    switch(number){
      case 0: 
      rs= 'Chưa xác thực' 
      break;
      case 1: 
      rs= 'Đã xác thực' 
      break;
      case 2: 
      rs= 'Đã khóa' 
      break;
      case 3: 
      rs= 'Chờ xác thực' 
      break;
      default:'Chưa xác thực'
    }
    return rs
  }

  onSort() {
  }

  onDelete() {

  }

  dialogHandler(type: string, row: any, content: TemplateRef<any>) {
    this.dialogType = type
    type === 'view' ? (this.disableInput = true) : (this.disableInput = false)
    type === 'edit' && true
    this.getUserByCode(row)
    this._modal.open(content, { size: 'xl', centered: true, scrollable: true });
  }

  openModal(content: TemplateRef<any>) {
    this._modal.open(content, { size: 'xl', centered: true, scrollable: true });
  }

  error = ERROR
  pattern = VALID
  genders = GENDERS
  statusSeletor = STATUS_USER
  mqhnguoimua = BHSK_RELATIVE
}
