import { ApiService } from '@services/api.service';
import { ITMediplusFee, ITMediplusBill, ITDanhSach } from '@models/mediplusfee.model';
import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { ITFee } from '@models/fee.model';
import { API_PATH, GENDERS } from 'src/utils/constant';
import { MEDIPLUS_STEPS } from 'src/utils/steps';
import { ITVoucher } from '@models/voucher.model';
import { eff, FEE_TEMPLATE, now, PAYMENT_TEMPLATE, VOUCHER_TEMPLATE, year } from 'src/utils/product';
import { MEDIPLUS_BILL_TEMPLATE, MEDIPLUS_CLIENT_TYPES, MEDIPLUS_DURATIONS, MEDIPLUS_LIST_CUSTOMER_TEMPLATE, MEDIPLUS_PROTECTOR_TYPES, MEDIPLUS_RESPONS } from 'src/utils/product/mediplus';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ERROR, VALID } from 'src/utils/validation';
import { ITPayment } from '@models/payment.model';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from '@services/common/common.service';
import { ProtectService } from '@services/protect/protect.service';
import * as moment from 'moment';
import { ToastService } from '@services/toast.service';

@Component({
  selector: 'app-mediplus',
  templateUrl: './mediplus.component.html',
  styleUrls: ['./mediplus.component.css']
})
export class MediplusComponent implements OnInit {
  @Input() isWebview: boolean = false
  @Input() isApp: boolean = false
  @Input() isShared: boolean = false
  @Output() setIsPayment = new EventEmitter()
  @Input() appVoucher: any = null
  @Input() userCode: any = null
  @Input() meetVoucher: any = null
  isPayment: boolean = true
  public isBack = false
  public stepValue = 1
  public isPerson = true
  public isCorp = false
  public isProtector = true
  public isInner = false
  public paymentPath: any = ''
  public save = 0
  public fee: ITFee = {
    ...FEE_TEMPLATE
  }
  year = year
  now = now
  eff = eff
  docs: ITDanhSach[] = {
    ...MEDIPLUS_LIST_CUSTOMER_TEMPLATE
  }
  files: any
  public bill: ITMediplusFee = {
    ...MEDIPLUS_BILL_TEMPLATE
  }
  public form: ITMediplusBill = {
    nv: 'NG_MC',
    kieU_HD: 'G',
    ttoan: '',
    giamtl: '',
    sO_DT: '1',
    dbh: 'M',
    nguoI_DS: this.docs,
    nG_HUONG: '',
    lkh: 'C',
    ten: '',
    cmt: '',
    files: {
      front: '',
      back: '',
    },
    nG_SINH: '',
    gioi: '',
    mobi: '',
    email: '',
    dchi: '',
    nguoiGioiThieu: '',
    tensanpham: 'Bảo hiểm Mediplus',
    goisanpham: '',
    thoihan: '',
    iddangky: '',
    magioithieu: '',
    moiquanhe: '',
    user_Code: '',
  }
  public voucher: ITVoucher = {
    ...VOUCHER_TEMPLATE
  }
  public payment:ITPayment = {
    ...PAYMENT_TEMPLATE
  }
  submited: boolean = false
  tempFile: any = {
    back: '',
    front: '',
  }
  webviewNow: any;
  isNumberIn: boolean = false;
  validDate:boolean=true;
  validBirth: boolean = true;
  constructor(
    private ApiService: ApiService,
    private _common: CommonService,
    private _protect: ProtectService,
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private router: Router,
    public _toast: ToastService,
  ) { }

  ngOnInit(): void {
    
    this.isWebview ? (this.getParams(), this.webviewNow = moment(this.now).format('DD-MM-YYYY'),
    this.bill.ngaY_HL = moment(this.bill.ngaY_HL).format('DD-MM-YYYY'),
    this.bill.ngaY_KT = moment(this.bill.ngaY_KT).format('DD-MM-YYYY')) : this.handleUserCode()
    this.getFee()
  }

  handleUserCode() {
    const user = this.route.snapshot.params['user']
    this.form.magioithieu = user
    this.getLauncher(user)
  }

  onGetFeeTable(index: number) {
    this.docs[index].dS_TEN.length > 0 && this.docs[index].dS_CMT.length > 0 && this.docs[index].dS_NG_SINH.length > 0 ? this.getFee() : ''
  }

  getParams() {
    this.router.url.includes('&') ? this.form.user_Code = this.payment.user_Code = this.router.url.slice(-9) : ''
  }

  onValidBirthday(birthday: any) {
    if(this.isPerson){
      this.validBirth = this._common.validBirthdayString(birthday, this.isWebview)
    }
    else{
      this.validBirth = true;
    }
}

  upload(e: any, type: number) {
    let formData = new FormData()
    for (let file of e.target.files) {
      formData.append('files', file)
    }
    this._common.uploads(formData).subscribe((res) => {
      this._toast.show('Tải file thành công !', { icon: 'check_circle', classname: 'bg-success text-light' })
      type
        ? (this.tempFile.back = res[0].name)
        : (this.tempFile.front = res[0].name)
    })
  }

  confirmFile(): void {
    this._toast.show('Cập nhật ảnh CMT/CCCD thành công !', { icon: 'check_circle', classname: 'bg-success text-light' })
    this.form.files = this.tempFile
  }

  removeFile(type: number): void {
    this._toast.show('Xóa file thành công !', { icon: 'check_circle', classname: 'bg-success text-light' })
    type
      ? (this.tempFile.back = '')
      : (this.tempFile.front = '')
  }

  // Get date, month, year listen ngaY_HL, then set ngaY_KT
  onChangeFromDate() {
    this.validDate = this._common.validDateString(this.bill.ngaY_HL, this.isWebview);
    this.isWebview ? this.bill.ngaY_KT = moment(this._common.onChangeFromDate(this._common.changeDateString(this.bill.ngaY_HL))).format('DD-MM-YYYY') : this.bill.ngaY_KT = this._common.onChangeFromDate(this.bill.ngaY_HL);
    this.getFee()
  }

  onController(e: number, f: any) {
    // If any input in any step has invalid, open modal notice "Please fill the form", then warning
    // If all input in any step hasn't invalid, fee next step
    switch (this.stepValue) {
      case 1:
        if (f.controls['ngaY_HL']?.invalid
        || !this.validDate || this.fee.phi == '') {
          this._toast.show('Hãy nhập đúng phiếu thông tin !', { icon: 'error', classname: 'bg-danger text-light' });

          f.controls['ngaY_HL']!.touched = true
        } else this.stepValue += e
        break
      case 2:
        if (f.controls['mobi']?.invalid | f.controls['ten']?.invalid | f.controls['cmt']?.invalid | f.controls['nG_SINH']?.invalid | f.controls['email']?.invalid | f.controls['dchi']?.invalid
        || !this.validBirth || this.fee.phi == '') {
          this._toast.show('Hãy nhập đúng phiếu thông tin !', { icon: 'error', classname: 'bg-danger text-light' });

          f.controls['mobi']!.touched = true
          f.controls['ten']!.touched = true
          f.controls['cmt']!.touched = true
          f.controls['nG_SINH']!.touched = true
          f.controls['email']!.touched = true
          f.controls['dchi']!.touched = true

          if (this.isPerson && f.controls['gioi']?.invalid) {
            f.controls['gioi']!.touched = true
          }
        } else {
          this.stepValue += e

          if (this.isWebview && e === 1) {
            this.onSaveClient()
          }
        }
        break
      case 3:
        this.stepValue += e
        break
      default:
        this._toast.show('Hãy nhập đúng phiếu thông tin !', { icon: 'error', classname: 'bg-danger text-light' });

    }
  }

  onSaveClient() {
    let payload = {
      ...this.payment,
      user_Code: this.form.user_Code || '',
      hoten: this.form.ten.trim(),
      ngaysinh: this.isWebview ? this._common.changeDateString(this.form.nG_SINH) : this.form.nG_SINH,
      ngaytao: this.now,
      diachi: this.form.dchi.trim(),
      sodienthoai: this.form.mobi,
      cccd: this.form.cmt,
      email: this.form.email,
      thietBi: 'app',
      sanPham: 'Bảo hiểm trợ cấp viện phí',
      loaibaohiem: 'Bảo hiểm trợ cấp viện phí',
      goibaohiem: this.respons.find(i => i.value === +this.bill.muc)?.label_api,
      thoihan: this.durations.find(i => i.value == +this.bill.han)?.value.toString(),
      thoigianthanhtoan: this.now,
    }
    this.ApiService.postPayment(payload).subscribe(res => {
      this.payment.idDangKy = this.form.iddangky = res.idDangKy
    })
  }

  // If magioithieu.length < 1, set nguoiGioiThieu = ''
  getEmptyLauncher() {
    this.form.magioithieu = ''
    this.form.nguoiGioiThieu = ''
  }

  getOldFee() {
    this.save = 0

    this.fee.tongphi = this.fee.phi
  }

  getFee() {
    const payload = {
      ...this.bill,
      ngaY_HL: this.isWebview ? this._common.changeDateString(this.bill.ngaY_HL) : this.bill.ngaY_HL,
      ngaY_KT: this.isWebview ? this._common.changeDateString(this.bill.ngaY_KT) : this.bill.ngaY_KT
    }
    this.fee = {...FEE_TEMPLATE}
    this._common.getFee( API_PATH.MEDIPLUS_FEE, payload ).subscribe((data) => {
      this.fee = data
      this.payment.tongThanhToan = this.payment.tongphi = this.form.ttoan = data.strTong
    });
  }

  // BAOHIEM20220824015137
  getDiscount(param: string) {
    this._common.getDiscount(param, this.fee.tongphi).subscribe((data) => {
      if (data.status) {
        this.voucher = data,
        this.save = data.save
        this.form.ttoan = data.newTongPhi
      } else {
        this._toast.show('Hãy nhập đúng voucher !', { icon: 'error', classname: 'bg-danger text-light' });
      }
    })
  }

  getLauncher(param: string) {
    this.ApiService.getLauncher(param).subscribe(data => {
      this.form.nguoiGioiThieu = data.ho_ten;
    })
  }

  openCentered(content: TemplateRef<any>) {
    this.modalService.open(content, { centered: true });
  }

  openImageModal(content: TemplateRef<any>) {
    this.modalService.open(content, { size: window.innerWidth > 500 ? 'xl' : 'lg' });
  }

  getBack() {
    this.modalService.dismissAll();

    this.isPayment = false
    this.setIsPayment.emit(this.isPayment)
    // Hàm getBack sẽ ghi lại isPayment bằng false, đồng thời thông qua EventEmitter bắn value isPayment này ra component cha Product

    this.isBack = false
  }

  onChangeCheckbox() {
    this.isInner ? this.form.dbh = 'D' : this.form.dbh = 'M'
    this.isPerson && this.isCorp === false ? this.form.lkh = 'C' : this.form.lkh = 'T'
  }

  onSubmit(f: any) {
    if (f.invalid || !this.validDate || !this.validBirth || this.fee.phi == '') {
      this._toast.show('Hãy nhập đúng phiếu thông tin !', { icon: 'error', classname: 'bg-danger text-light' });

      f.controls['mobi']!.touched = true
      f.controls['ten']!.touched = true
      f.controls['cmt']!.touched = true
      f.controls['nG_SINH']!.touched = true
      f.controls['email']!.touched = true
      f.controls['dchi']!.touched = true

      if (this.isPerson && f.controls['gioi']?.invalid) {
        f.controls['gioi']!.touched = true
      }
    } else {
      this.submited = true
      this.onChangeCheckbox()
      let payload = {
        ...this.form,
        ...this.payment,
        ...this.bill,
        email: this.form.email || '',
        gioitinh: this.genders.find((i: any) => i.value === this.form.gioi)?.label,
        nG_SINH: this.isWebview ? this._common.changeDateString(this.form.nG_SINH) : this.form.nG_SINH,
        goibaohiem: this.respons.find((i: any) => i.value === this.bill.muc)?.label_api,
        goisanpham: this.respons.find((i: any) => i.value === this.bill.muc)?.label_api,
        ngaY_HL: this.isWebview ? this._common.changeDateString(this.bill.ngaY_HL) : this.bill.ngaY_HL,
        ngaY_KT: this.isWebview ? this._common.changeDateString(this.bill.ngaY_KT) : this.bill.ngaY_KT
      }
      let formValue = Object.assign(payload)
      localStorage.setItem('form-data', JSON.stringify(formValue))

      const totalFee = this.fee.tongphi as string
      this.payment.tongThanhToan = this.payment.tongphi = parseFloat(totalFee.replace(/,/g, '')).toString()

      this.ApiService.postPayment(payload)
        .subscribe(res => {
          if (this.isApp) {
            // Gửi message vào app
            window.postMessage(JSON.stringify({ type: 'getLocalStorage' }));
            const submitButton = document.getElementById('submit');
            submitButton&&submitButton.setAttribute('data-ttoan', this.payment.tongThanhToan || '0')
            window.location.href ='webview/notification/app'
          } else {
            res.paymentUrl && (window.location.href = res.paymentUrl)
          }
          // res.paymentUrl && (window.location.href = res.paymentUrl)
        this.submited = false
        })
    }
  }

  policyHandler() {
    this.form.goisanpham = this.respons.find(item => item.label === this.bill.muc)?.label
  }

  onCapitalize(): void {
    this.form.ten = this._common.onCapitalize(this.form.ten)
  }

  onNumberIn() {
    this.isNumberIn = this._common.onNumberIn(this.form.ten)
  }

  pattern = VALID
  error = ERROR
  genders = GENDERS
  steps = MEDIPLUS_STEPS
  durations = MEDIPLUS_DURATIONS
  respons = MEDIPLUS_RESPONS
  client_types = MEDIPLUS_CLIENT_TYPES
  protector_types = MEDIPLUS_PROTECTOR_TYPES
}
