import { ApiService } from '@services/api.service';
import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { ITCarFee, ITCarForm } from '@models/carfee.model';
import { ITFee } from '@models/fee.model';
import { ITVoucher } from '@models/voucher.model';
import { OTO_STEPS } from 'src/utils/steps';
import { API_PATH } from 'src/utils/constant';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ERROR, VALID } from 'src/utils/validation';
import { ITPayment } from '@models/payment.model';
import { ActivatedRoute, Router } from '@angular/router';
import { eff, FEE_TEMPLATE, now, PAYMENT_TEMPLATE, VOUCHER_TEMPLATE } from 'src/utils/product';
import { CAR_BILL_TEMPLATE, CAR_FORM_TEMPLATE, CAR_ORIGINAL, CAR_RESPONS_ASSETS, CAR_RESPONS_DRIVERS, CAR_RESPONS_PEOLPE } from 'src/utils/product/car';
import { CommonService } from '@services/common/common.service';
import { ProtectService } from '@services/protect/protect.service';
import * as moment from 'moment';
import { ToastService } from '@services/toast.service';
import { registerLocaleData } from '@angular/common';
import localeVi from '@angular/common/locales/vi';
registerLocaleData(localeVi, 'vi');

@Component({
  selector: 'app-car',
  templateUrl: './car.component.html',
  styleUrls: ['./car.component.css']
})
export class CarComponent implements OnInit {
  @Input() code: string = ''
  @Input() isWebview: boolean = false
  @Input() isApp: boolean = false
  @Input() isShared: boolean = false
  @Output() setIsPayment = new EventEmitter()
  @Input() appVoucher: any = null
  @Input() userCode: any = null
  @Input() meetVoucher: any = null
  isPayment: boolean = true
  isTrueVoucher: boolean = true;
  public isBack = false
  public stepValue = 1
  public formdsd = 'mdsdn'
  public formdsdn = 'xeN1'
  public formdsdh = ''
  public formdsdt = ''
  public isPerson = true
  public isCorp = false
  public isOther = false
  public isOtherView = false
  public isOtherPerson = true
  public isOtherCorp = false
  public save = 0
  public fee: ITFee = {
    ...FEE_TEMPLATE
  }
  public brands: any = null
  public series: any = null
  birthYear: any = new Date().setFullYear(new Date().getFullYear() - 18)
  maxBirth: any = new Date(this.birthYear).toISOString().substring(0, 10)
  public validBirth = true
  public validOtherBirth = true
  isTrueLauncher: boolean = true;
  files: any
  fileMs: any
  ma_nvAppVoucher: any = undefined
  isBSHCar: boolean = false
  public bill: ITCarFee = {
    ...CAR_BILL_TEMPLATE
  }
  public form: ITCarForm = {
    ...CAR_FORM_TEMPLATE
  }
  public voucher: ITVoucher = {
    ...VOUCHER_TEMPLATE
  }
  public payment: ITPayment = {
    ...PAYMENT_TEMPLATE
  }
  submited: boolean = false
  tempFile: any = {
    back: '',
    front: '',
  }
  tempFileM: any = {
    back: '',
    front: '',
  }
  webviewNow: any;
  validDate: boolean = true;
  isNumberIn: boolean = false;
  isNumberInP: boolean = false;

  constructor(
    private ApiService: ApiService,
    private _common: CommonService,
    private _protect: ProtectService,
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private router: Router,
    public _toast: ToastService,
  ) { }

  ngOnInit(): void {
    this.onFinmeInit(this.code)
    this.getCarBrand()
    this.isWebview ? (this.getParams(), this.webviewNow = moment(this.now).format('DD-MM-YYYY'),
      this.bill.ngaY_HL = moment(this.bill.ngaY_HL).format('DD-MM-YYYY'),
      this.bill.ngaY_KT = moment(this.bill.ngaY_KT).format('DD-MM-YYYY')) : this.handleUserCode()
      if (this.appVoucher?.length == 10) {
        this.voucher.maVoucher = this.appVoucher
      }
      if (this.meetVoucher){
        this.voucher.maVoucher = this.meetVoucher
      }
  }

  onFinmeInit(code: string) {
    switch (code) {
      case 'MIC02':
        this.form.nv = 'XEL'
        this.form.tensanpham = 'Bảo hiểm TNDS ôtô MIC'
        break
      case 'BSH02':
        this.form.nv = 'BSH02'
        this.form.tensanpham = 'Bảo hiểm TNDS ôtô BSH'
        this.isBSHCar = true

        this.bill = {
          ...this.bill,
          xeH9: 'K',
          xeH10: 'K',
          nguoN_GOC: '',
          ngaY_HL: this.bill.ngaY_HL,
          ngaY_KT: this.bill.ngaY_KT
        }
        break
      default:
        console.warn('I can not find you product, please check your database!!!')
    }
  }

  handleUserCode() {
    const user = this.route.snapshot.params['user']
    if (user?.length == 9) {
      this.form.magioithieu = user
      this.getLauncher(user)
    }
  }

  getCarBrand() {
    this.ApiService.getCarBrand()
      .subscribe(data => {
        this.brands = data;
      })
  }

  getCarSeri(brand: string) {
    this.ApiService.getCarSeri(brand)
      .subscribe(data => {
        this.series = data;
      })
  }

  getParams() {
    this.router.url.includes('&') ? this.form.user_Code = this.payment.user_Code = this.router.url.slice(-9) : ''
  }

  onResetRadio() {
    this.bill.mdsdn = 'K'
    this.bill.xeN1 = 'K'
    this.bill.xeN2 = 'K'
    this.bill.xeN3 = 'K'
    this.bill.xeN4 = 'K'
    this.bill.xeN5 = 'K'
    this.bill.xeN6 = 'K'
    this.bill.xeN7 = 'K'
    this.bill.xeN8 = 'K'
    this.bill.xeN10 = 'K'

    this.bill.mdsdh = 'K'
    this.bill.xeH1 = 'K'
    this.bill.xeH2 = 'K'
    this.bill.xeH3 = 'K'
    this.bill.xeH4 = 'K'
    this.bill.xeH5 = 'K'
    this.bill.xeH6 = 'K'
    this.bill.xeH7 = 'K'
    this.bill.xeH8 = 'K'
    this.isBSHCar && (this.bill.xeH9 = 'K')
    this.isBSHCar && (this.bill.xeH10 = 'K')

    this.bill.mdsdt = 'K'
    this.bill.xeT1 = 'K'
    this.bill.xeT2 = 'K'
  }

  onForMdsdn() {
    switch (this.formdsdn) {
      case 'xeN1':
        this.bill.xeN1 = 'C'
        break
      case 'xeN2':
        this.bill.xeN2 = 'C'
        break
      case 'xeN3':
        this.bill.xeN3 = 'C'
        break
      case 'xeN4':
        this.bill.xeN4 = 'C'
        break
      case 'xeN5':
        this.bill.xeN5 = 'C'
        break
      case 'xeN6':
        this.bill.xeN6 = 'C'
        break
      case 'xeN7':
        this.bill.xeN7 = 'C'
        break
      case 'xeN8':
        this.bill.xeN8 = 'C'
        break
      case 'xeN10':
        this.bill.xeN10 = 'C'
        break
      default:
        console.log();
    }
  }

  onForMdsdh() {
    switch (this.formdsdh) {
      case 'xeH1':
        this.bill.xeH1 = 'C'
        break
      case 'xeH2':
        this.bill.xeH2 = 'C'
        break
      case 'xeH3':
        this.bill.xeH3 = 'C'
        break
      case 'xeH4':
        this.bill.xeH4 = 'C'
        break
      case 'xeH5':
        this.bill.xeH5 = 'C'
        break
      case 'xeH6':
        this.bill.xeH6 = 'C'
        break
      case 'xeH7':
        this.bill.xeH7 = 'C'
        break
      case 'xeH8':
        this.bill.xeH8 = 'C'
        break
      case 'xeH9':
        this.bill.xeH9 = 'C'
        break
      case 'xeH10':
        this.bill.xeH10 = 'C'
        break
      default:
        console.log();
    }
  }

  onForMdsdt() {
    switch (this.formdsdt) {
      case 'xeT1':
        this.bill.xeT1 = 'C'
        break
      case 'xeT2':
        this.bill.xeT2 = 'C'
        break
      default:
        console.log();
    }
  }

  onSetRadio() {
    switch (this.formdsd) {
      case 'mdsdn':
        this.bill.mdsdn = 'C'
        this.onForMdsdn()
        break
      case 'mdsdh':
        this.bill.mdsdh = 'C'
        this.onForMdsdh()
        break
      case 'mdsdt':
        this.bill.mdsdt = 'C'
        this.onForMdsdt()
        break
      default:
        console.log();
    }
  }

  onGetFeeWithRadio() {
    this.onResetRadio()
    this.onSetRadio()

    this.getFee()
  }

  upload(e: any, type: number, isOther: boolean) {
    let formData = new FormData()
    for (let file of e.target.files) {
      formData.append('files', file)
    }
    this._common.uploads(formData).subscribe((res) => {
      this._toast.show('Tải file thành công !', { icon: 'check_circle', classname: 'bg-success text-light' })
      if (isOther) {
        type
          ? (this.tempFileM.back = res[0].name)
          : (this.tempFileM.front = res[0].name)
      } else {
        type
          ? (this.tempFile.back = res[0].name)
          : (this.tempFile.front = res[0].name)
      }
    })
  }

  confirmFile(isOther: boolean): void {
    this._toast.show('Cập nhật ảnh CMT/CCCD thành công !', { icon: 'check_circle', classname: 'bg-success text-light' })
    isOther
      ? (this.form.fileMs = this.tempFileM)
      : (this.form.files = this.tempFile)
  }

  removeFile(type: number, isOther: boolean): void {
    this._toast.show('Xóa file thành công !', { icon: 'check_circle', classname: 'bg-success text-light' })
    if (isOther) {
      type
        ? (this.tempFileM.back = '')
        : (this.tempFileM.front = '')
    } else {
      type
        ? (this.tempFile.back = '')
        : (this.tempFile.front = '')
    }
  }

  onChangeFromDate() {
    this.validDate = this._common.validDateString(this.bill.ngaY_HL, this.isWebview);
    this.isWebview ? this.bill.ngaY_KT = moment(this._common.onChangeFromDate(this._common.changeDateString(this.bill.ngaY_HL))).format('DD-MM-YYYY') : this.bill.ngaY_KT = this._common.onChangeFromDate(this.bill.ngaY_HL);
  }

  onValidBirthday(birthday: any) {
    if (this.isPerson) {
      this.validBirth = this._common.validBirthday(birthday, this.isWebview)
    }
    else {
      this.validBirth = true;
    }
  }

  onValidOtherBirthday(birthday: any) {
    if (this.isOtherPerson) {
      this.validOtherBirth = this._common.validBirthday(birthday, this.isWebview)
    }
    else {
      this.validOtherBirth = true;
    }
  }

  // If maVoucher.length < 1, set tongphi = phi
  getEmptyLauncher() {
    this.form.magioithieu = ''
    this.form.nguoiGioiThieu = ''
  }

  // If maVoucher.length < 1, set tongphi = phi
  getOldFee() {
    this.save = 0

    this.fee.tongphi = this.fee.phi
    this.form.ttoan = this.fee.tongphi
      = this.fee.phi
  }

  getFee() {
    const path = this.code === 'MIC02' ? API_PATH.CAR_FEE : API_PATH.BSHCAR_FEE
    if (this.bill.bieN_XE && this.bill.naM_SX && this.bill.sO_CN) {
      const payload = {
        ...this.bill,
        ngaY_HL: this.isWebview ? this._common.changeDateString(this.bill.ngaY_HL) : this.bill.ngaY_HL,
        ngaY_KT: this.isWebview ? this._common.changeDateString(this.bill.ngaY_KT) : this.bill.ngaY_KT
      }
      this.fee = { ...FEE_TEMPLATE }
      this._common.getFee(path, payload).subscribe((data) => {
        this.fee = data
        this.payment.tongThanhToan = this.payment.tongphi = this.form.ttoan = data.strTong
        this.form.ttoan = this._common.numberWithCommas(this.form.ttoan)
        this.voucher.maVoucher ? this.getDiscount(this.voucher.maVoucher) : null
      });
    }
  }

  // BAOHIEM20220824015137
  getDiscount(param: string) {
    this.voucher = {
      ...VOUCHER_TEMPLATE
    }
    this.appVoucher? (this.voucher.maVoucher = this.appVoucher, param=this.appVoucher) : null
    this.save = 0
    this.form.ttoan = this._common.numberWithCommas(this.fee.strTong)
    this.form.magioithieu = this.userCode ? this.userCode : this.form.magioithieu
    // this.form.nguoiGioiThieu = this.userCode? this.form.nguoiGioiThieu: ''
    if(param.includes('MGVOUCHER')){
      this.form.magioithieu = this.userCode = ''
      this.form.nguoiGioiThieu = ''
    }
    this.ma_nvAppVoucher = undefined
    if (!this.fee.phi) {
      this._toast.show('Chưa có phí để áp dụng voucher, hãy hoàn thành các thông tin!', { icon: 'error', classname: 'bg-danger text-light' });
    }
    this._common.getDiscount(param, this.fee.tongphi, this.code,this.form.user_Code, this.isWebview).subscribe((data) => {
      if (data.status) {
        this.voucher = data,
          this.save = data.save
        this.form.ttoan = data.newTongPhi + this.fee.thue
        data.ma_nv ? (this.form.magioithieu = data.ma_nv, this.getLauncher(this.form.magioithieu)) : null
        data.ma_nv ? this.ma_nvAppVoucher = data.ma_nv : null
        this.isTrueVoucher == true ? this.isTrueVoucher = true
          : (this.isTrueVoucher = true, this._toast.show('Dùng voucher thành công !', { icon: 'check_circle', classname: 'bg-success text-light' }))
      } else {
        this._toast.show('Dùng voucher thất bại! Hãy kiểm tra lại.', { icon: 'error', classname: 'bg-danger text-light' });
        this.isTrueVoucher = false
      }
    })
  }

  getLauncher(param: string) {
    param= param?.trim()
    this.form.nguoiGioiThieu=''
    this.isTrueLauncher=false;
    if(param&&param.includes('A')&&param.length==9){
      this.ApiService.getLauncher(param).subscribe((data) => {
        this.form.nguoiGioiThieu = data?.ho_ten
        data?.ho_ten?(this.form.user_Code= this.payment.user_Code = param,this.isTrueLauncher=true):null
      })
    }
    else if(param&&!param.includes('A')&&param.length==10){
      this.ApiService.getLauncherByPhone(param).subscribe((data) => {
        this.form.nguoiGioiThieu = data[0]?.ho_ten
        data[0]?.ho_ten?(this.form.user_Code= this.payment.user_Code = data[0]?.ma_nv,this.isTrueLauncher=true):null
      })
    } 
  }


  openCentered(content: TemplateRef<any>) {
    this.modalService.open(content, { centered: true });
  }

  openImageModal(content: TemplateRef<any>, isOther: boolean) {
    this.isOtherView = isOther
    this.modalService.open(content, { size: window.innerWidth > 500 ? 'xl' : 'lg' });
  }

  getBack() {
    this.modalService.dismissAll();

    this.isPayment = false
    this.setIsPayment.emit(this.isPayment)
    // Hàm getBack sẽ ghi lại isPayment bằng false, đồng thời thông qua EventEmitter bắn value isPayment này ra component cha Product

    this.isBack = false
  }

  onChangeCheckbox() {
    this.isPerson && this.isCorp === false ? this.form.lkh = 'C' : this.form.lkh = 'T'
    this.isOther ? this.form.dbhm = 'C' : this.form.dbhm = 'K'
    if (this.isOther) {
      this.isOtherPerson && this.isOtherCorp === false ? this.form.lkhm = 'C' : this.form.lkhm = 'T'
    }
  }

  onSubmit(f: any) {
    if (f.invalid || !this.validDate || !this.validBirth || !this.validOtherBirth || this.fee.phi == '') {
      this._toast.show('Hãy nhập đúng phiếu thông tin !', { icon: 'error', classname: 'bg-danger text-light' });

      f.controls['bieN_XE']!.touched = true
      f.controls['sO_MAY']!.touched = true
      f.controls['sO_KHUNG']!.touched = true
      this.isBSHCar && (f.controls['nguoN_GOC']!.touched = true)
      f.controls['hanG_XE']!.touched = true
      f.controls['hieU_XE']!.touched = true
      f.controls['naM_SX']!.touched = true
      f.controls['sO_CN']!.touched = true
      f.controls['ngaY_HL']!.touched = true
      f.controls['mobi']!.touched = true
      f.controls['ten']!.touched = true
      f.controls['cmt']!.touched = true
      f.controls['nG_SINH']!.touched = true
      f.controls['email']!.touched = true
      f.controls['dchi']!.touched = true

      if (this.isOther) {
        f.controls['mobim']!.touched = true
        f.controls['tenm']!.touched = true
        f.controls['cmtm']!.touched = true
        f.controls['nG_SINHM']!.touched = true
        f.controls['emailm']!.touched = true
        f.controls['dchim']!.touched = true
      }
    } else {
      this.submited = true
      this.onChangeCheckbox()
      const totalFee = this.fee.tongphi as string
      const totalPay = this.form.ttoan as string
      this.payment.tongThanhToan = parseFloat(totalPay.replace(/,/g, '')).toString()
      this.payment.tongphi = parseFloat(totalFee.replace(/,/g, '')).toString()
      let payload = {
        ...this.form,
        ...this.payment,
        ...this.bill,
        email: this.form.email || '',
        goisanpham: 'Gói bảo hiểm với mức trách nghiệm',
        nG_SINH: this.isWebview ? this._common.changeDateString(this.form.nG_SINH) : this.form.nG_SINH,
        ngaY_HL: this.isWebview ? this._common.changeDateString(this.bill.ngaY_HL) : this.bill.ngaY_HL,
        ngaY_KT: this.isWebview ? this._common.changeDateString(this.bill.ngaY_KT) : this.bill.ngaY_KT,
        voucher: this.voucher.maVoucher
      }
      let formValue = Object.assign(payload)
      localStorage.setItem('form-data', JSON.stringify(formValue))

      this.ApiService.postPayment(payload).subscribe(res => {
        if (this.isApp) {
          // Gửi message vào app
          window.postMessage(JSON.stringify({ type: 'getLocalStorage' }));
          const submitButton = document.getElementById('submit');
          submitButton && submitButton.setAttribute('data-ttoan', this.payment.tongThanhToan || '0')
          window.location.href = 'webview/notification/app'
        } else {
          res.paymentUrl && (window.location.href = res.paymentUrl)
        }
        // res.paymentUrl && (window.location.href = res.paymentUrl)
        this.submited = false
      })
    }
  }

  onController(e: number, f: any) {
    // If any input in any step has invalid, open modal notice "Please fill the form", then warning
    // If all input in any step hasn't invalid, fee next step
    switch (this.stepValue) {
      case 1:
        if (f.controls['bieN_XE']?.invalid | f.controls['sO_MAY']?.invalid | f.controls['sO_KHUNG']?.invalid | f.controls['nguoN_GOC']?.invalid | f.controls['hanG_XE']?.invalid | f.controls['hieU_XE']?.invalid | f.controls['naM_SX']?.invalid | f.controls['sO_CN']?.invalid | f.controls['ngaY_HL']?.invalid
          || !this.validDate) {
          this._toast.show('Hãy nhập đúng phiếu thông tin !', { icon: 'error', classname: 'bg-danger text-light' });

          f.controls['bieN_XE']!.touched = true
          f.controls['sO_MAY']!.touched = true
          f.controls['sO_KHUNG']!.touched = true
          this.isBSHCar && (f.controls['nguoN_GOC']!.touched = true)
          f.controls['hanG_XE']!.touched = true
          f.controls['hieU_XE']!.touched = true
          f.controls['naM_SX']!.touched = true
          f.controls['sO_CN']!.touched = true
          f.controls['ngaY_HL']!.touched = true
        } else this.stepValue += e
        break
      case 2:
        if (this.fee.phi == '') {
          this._toast.show('Hãy kiểm tra thông tin phí !', { icon: 'error', classname: 'bg-danger text-light' });
        }
        else { this.stepValue += e }
        break
      case 3:
        if (f.controls['mobi']?.invalid | f.controls['ten']?.invalid | f.controls['cmt']?.invalid | f.controls['nG_SINH']?.invalid | f.controls['email']?.invalid | f.controls['dchi']?.invalid
          || !this.validBirth || !this.validOtherBirth || this.fee.phi == '') {
          if (this.isOther && f.controls['mobim']?.invalid | f.controls['tenm']?.invalid | f.controls['cmtm']?.invalid | f.controls['nG_SINHM']?.invalid | f.controls['emailm']?.invalid | f.controls['dchim']?.invalid) {
            f.controls['mobim']!.touched = true
            f.controls['tenm']!.touched = true
            f.controls['cmtm']!.touched = true
            f.controls['nG_SINHM']!.touched = true
            f.controls['emailm']!.touched = true
            f.controls['dchim']!.touched = true
          }
          this._toast.show('Hãy nhập đúng phiếu thông tin !', { icon: 'error', classname: 'bg-danger text-light' });

          f.controls['mobi']!.touched = true
          f.controls['ten']!.touched = true
          f.controls['cmt']!.touched = true
          f.controls['nG_SINH']!.touched = true
          f.controls['email']!.touched = true
          f.controls['dchi']!.touched = true
        } else {
          this.stepValue += e

          if (this.isWebview && e === 1) {
            this.onSaveClient()
          }
        }
        break
      case 4:
        this.stepValue += e
        break
      default:
        this._toast.show('Hãy nhập đúng phiếu thông tin !', { icon: 'error', classname: 'bg-danger text-light' });

    }
  }


  onSaveClient() {
    const totalFee = this.fee.tongphi as string
    const totalPay = this.form.ttoan as string
    this.payment.tongThanhToan = parseFloat(totalPay.replace(/,/g, '')).toString()
    this.payment.tongphi = parseFloat(totalFee.replace(/,/g, '')).toString()
    let payload = {
      ...this.payment,
      user_Code: this.form.user_Code || this.payment.user_Code || '',
      hoten: this.form.ten.trim(),
      ngaysinh: this.isWebview ? this._common.changeDateString(this.form.nG_SINH) : this.form.nG_SINH,
      ngaytao: this.now,
      diachi: this.form.dchi.trim(),
      sodienthoai: this.form.mobi,
      cccd: this.form.cmt,
      email: this.form.email,
      thietBi: 'app',
      sanPham: this.form.tensanpham,
      loaibaohiem: this.form.tensanpham,
      goibaohiem: 'Gói bảo hiểm với mức trách nghiệm',
      thoihan: '12',
      thoigianthanhtoan: this.now,
      tongThanhToan: this.payment.tongThanhToan.toString(),
      tongphi: this.payment.tongThanhToan.toString(),
      soKhung: this.bill.sO_KHUNG,
      soMay: this.bill.sO_MAY,
      bienXe: this.bill.bieN_XE
    }

    this.ApiService.postPayment(payload).subscribe(res => {
      this.payment.idDangKy = this.form.iddangky = res.idDangKy
    })
  }

  onCapitalize(type: number): void {
    type
      ? this.form.ten = this._common.onCapitalize(this.form.ten)
      : this.form.tenm = this._common.onCapitalize(this.form.tenm)
  }

  onNumberIn(type: number) {
    type
      ? this.isNumberIn = this._common.onNumberIn(this.form.ten)
      : this.isNumberInP = this._common.onNumberIn(this.form.tenm)
  }

  now = now
  eff = eff
  pattern = VALID
  error = ERROR
  steps = OTO_STEPS
  respons_assets = CAR_RESPONS_ASSETS
  respons_people = CAR_RESPONS_PEOLPE
  respons_drivers = CAR_RESPONS_DRIVERS
  original = CAR_ORIGINAL
}
