import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-feedbacks-manager',
  templateUrl: './feedbacks-manager.component.html',
  styleUrls: ['./feedbacks-manager.component.css']
})
export class FeedbacksManagerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void { }

}
