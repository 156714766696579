import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { ExportService } from '@services/export.service';
// import { ExportService } from '../_services/export.service';

@Directive({
  selector: '[appExport]'
})
export class ExportDirective {

  constructor(private exportService: ExportService) { }

  @Input('appExport') contracts: any[] = []
  @Input() fileName: string =  ''

  @HostListener('click', ['$event']) onClick($event: MouseEvent) {
    console.log('clicked: ' + $event);
    this.exportService.exportExcel(this.contracts, this.fileName);
  }

}
