import { DOCUMENT } from '@angular/common';
import { Injectable, Inject } from '@angular/core';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class ProtectService {

  constructor(
    @Inject(DOCUMENT) private document: Document
  ) {}

  disableRightClick() {
    this.document.addEventListener('contextmenu', (event) =>
      event.preventDefault()
    );
  }

  get windowRef() {
    return window
  }

  formatMetaTile(value: string): string {
    // Remove diacritical marks
    value = value.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    // Replace Vietnamese-specific letters with English equivalents
    value = value.replace(/đ/g, 'd').replace(/ư/g, 'u').replace(/ơ/g, 'o');
    // Replace spaces with hyphens
    value = value.replace(/ /g, '-');
    // Capitalize the first letter of the sentence
    value = value.charAt(0).toUpperCase() + value.slice(1);
    // Convert the formatted string to lowercase
    value = value.toLowerCase();
    return value;
  }

  formatDate(value: string): string {
    if (/[-/]/.test(value)) {
      moment(value, 'DD/MM/YYYY').format('YYYY-MM-DD')
    } else {
      moment(value, 'YYYY-MM-DD').format('YYYY-MM-DD')
    }
    return value;
  }

  switchLanguage(value: string): void {
    console.log("🚀 ~ file: protect.service.ts:48 ~ ProtectService ~ switchLanguage ~ value:", value)
    value && (
      window.sessionStorage.setItem('language', value)
    )
  }

  getLanguage(): string | null {
    return sessionStorage.getItem('language');
  }

}
