import { ApiService } from '@services/api.service'
import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core'
import { ITFee } from '@models/fee.model'
import { DATA_TO_DEFINE } from 'src/utils/constant'
import { BHTCNV_STEPS } from 'src/utils/steps'
import { ITVoucher } from '@models/voucher.model'
import { FEE_TEMPLATE, now, PAYMENT_TEMPLATE, VOUCHER_TEMPLATE } from 'src/utils/product'
import { TCNV_FORM_TEMPLATE } from 'src/utils/product/uic'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { ERROR, VALID } from 'src/utils/validation'
import { ITPayment } from '@models/payment.model'
import { ActivatedRoute, Router } from '@angular/router'
import { ITBHTCNVBill, ITBHTCNVFeeBSH } from '@models/baohiemtrocapnamvien.model'
import * as moment from 'moment'
import { CommonService } from '@services/common/common.service'
import { ProtectService } from '@services/protect/protect.service'
import { ToastService } from '@services/toast.service'
import { JsonPipe } from '@angular/common'

@Component({
  selector: 'app-baohiemtainancanhan-bsh',
  templateUrl: './baohiemtainancanhan-bsh.component.html',
  styleUrls: ['./baohiemtainancanhan-bsh.component.css']
})
export class BaohiemtainancanhanBSHComponent implements OnInit {
  @Input() code: string = ''
  @Input() isWebview: boolean = false
  @Input() isApp: boolean = false
  @Input() isShared: boolean = false
  @Output() setIsPayment = new EventEmitter()
  @Input() appVoucher: any = null
  @Input() userCode: any = null
  @Input() meetVoucher: any = null
  isPayment: boolean = true
  isTrueVoucher: boolean = true;
  public isBack = false
  public stepValue = 1
  public isPerson = true
  public isValidCmt = true
  public isValidCmtMain = true
  public isFamily = false
  public apiPath: string = ''
  public paymentPath: any = ''
  public moiquanhe: any = [
    { id: 1, value: 'Vợ/Chồng', label: 'PRODUCTS.REL4', label_api: 'Vợ/Chồng' },
    { id: 2, value: 'Con đẻ/nuôi hợp pháp', label: 'PRODUCTS.REL8', label_api: 'Con đẻ/nuôi hợp pháp' },
    { id: 3, value: 'Bố/Mẹ của vợ/chồng', label: 'PRODUCTS.REL7', label_api: 'Bố/Mẹ của vợ/chồng' },
    { id: 4, value: 'Anh/Chị/Em ruột', label: 'PRODUCTS.REL3', label_api: 'Anh/Chị/Em ruột' },
    { id: 5, value: 'Bố/Mẹ', label: 'PRODUCTS.REL2', label_api: 'Bố/Mẹ' },
  ]
  public genders: any = [
    { id: 1, value: 'Nam', vie: 'NAM', label: 'COMMON.MALE', label_api: 'Nam' },
    { id: 2, value: 'Nữ', vie: 'NU', label: 'COMMON.FEMALE', label_api: 'Nữ' },
  ]
  public save = 0
  public person: any = {
    moiquanhe: "",
    moiquanhe_label: "",
    gioitinh: "",
    gioitinh_label: "",
    name: "",
    ngaysinh: "",
    cmt: ""
  }
  public dsduocbaohiem: any = [{ moiquanhe_label: "PRODUCTS.REL1", }]
  public fee: ITFee = {
    ...FEE_TEMPLATE
  }
  now = now
  ma_nvAppVoucher: any = undefined
  files: any
  public bill: ITBHTCNVFeeBSH = {
    thoihan: '0',
    goi: 'goi_1',
    canhan: true,
    soluong: 1
  }
  public form: ITBHTCNVBill = {
    ...TCNV_FORM_TEMPLATE
  }
  public voucher: ITVoucher = {
    ...VOUCHER_TEMPLATE
  }
  public payment: ITPayment = {
    ...PAYMENT_TEMPLATE
  }
  dynamicVal: any
  currentDate: number = new Date().getDate()
  currentMonth: number = new Date().getMonth()
  currentYear: number = new Date().getFullYear()
  minBirth: any = new Date(this.currentYear - 65, this.currentMonth, this.currentDate + 1).toISOString().substring(0, 10)
  maxBirth: any = new Date(this.currentYear - 18, this.currentMonth, this.currentDate + 1).toISOString().substring(0, 10)
  wrongBirth: boolean = false
  wrongBirthP: boolean = false
  durations: any
  respons: any
  dynamic: any
  submited: boolean = false
  isTrueLauncher: boolean = true;
  tempFile: any = {
    back: '',
    front: '',
  }
  webviewNow: any;
  validDate: boolean = true;
  isNumberIn: boolean = false;
  isNumberIn1: boolean = false;
  ngaY_HL_Text: string = '';
  ngaY_KT_Text: string = '';
  idEdit: any;

  constructor(
    private ApiService: ApiService,
    private _common: CommonService,
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private router: Router,
    private _protect: ProtectService,
    public _toast: ToastService,
  ) { }

  ngOnInit(): void {
    this.onFinmeInit(this.code)
    this.isWebview ? (this.getParams(), this.webviewNow = moment(this.now).format('DD-MM-YYYY'),
      this.form.ngaY_HL = moment(this.form.ngaY_HL).format('DD-MM-YYYY'),
      this.form.ngaY_KT = moment(this.form.ngaY_KT).format('DD-MM-YYYY')) : this.handleUserCode();
    this.setTextDate();
    this.onChangeDate(this.bill.thoihan, (this.isWebview ? this.ngaY_HL_Text : this.form.ngaY_HL));
    if (this.appVoucher?.length == 10) {
      this.voucher.maVoucher = this.appVoucher
    }
    if (this.meetVoucher){
      this.voucher.maVoucher = this.meetVoucher
    }
  }

  onFinmeInit(code: string) {
    const data = DATA_TO_DEFINE.filter(item => item.code === code)[0]
    this.form.nv = data.nv
    this.form.tensanpham = data.name
    this.durations = data.durations
    this.respons = data.respons
    this.dynamic = data.dynamic
    this.apiPath = data.path
    this.bill.thoihan = data.thoihan || '0'
    data.isChangeDate && this.onChangeDate(this.bill.thoihan, this.form.ngaY_HL)
    data.isDefaultFee && this.getFee()
  }

  handleUserCode() {
    const user = this.route.snapshot.params['user']
    if (user?.length == 9) {
      this.form.magioithieu = user
      this.getLauncher(user)
    }
  }

  getParams() {
    this.router.url.includes('&')
      ? (this.form.user_Code = this.payment.user_Code = this.router.url.slice(-9))
      : ''
  }

  upload(e: any, type: number) {
    let formData = new FormData()
    for (let file of e.target.files) {
      formData.append('files', file)
    }
    this._common.uploads(formData).subscribe((res) => {
      this._toast.show('Tải file thành công !', { icon: 'check_circle', classname: 'bg-success text-light' })
      type
        ? (this.tempFile.back = res[0].name)
        : (this.tempFile.front = res[0].name)
    })
  }

  confirmFile(): void {
    this._toast.show('Cập nhật ảnh CMT/CCCD thành công !', { icon: 'check_circle', classname: 'bg-success text-light' })
    this.form.files = this.tempFile
  }

  removeFile(type: number): void {
    this._toast.show('Xóa file thành công !', { icon: 'check_circle', classname: 'bg-success text-light' })
    type
      ? (this.tempFile.back = '')
      : (this.tempFile.front = '')
  }

  setTextDate() {
    this.ngaY_HL_Text = this._common.changeDateString(this.form.ngaY_HL);
    this.ngaY_KT_Text = this._common.changeDateString(this.form.ngaY_KT);
    this.validDate = this._common.validDateString(this.form.ngaY_HL, this.isWebview);
  }
  // Get date, month, year listen thoihan, ngaY_HL, then set ngaY_KT
  onChangeDate(range: any, date: any) {
    const fromDate = new Date(date).getDate()
    const fromMonth = new Date(date).getMonth()
    const fromYear = new Date(date).getFullYear()
    let tempMonth = fromMonth + parseInt(range)
    this.ngaY_KT_Text = JSON.parse(JSON.stringify(this.form.ngaY_KT));
    if (tempMonth > 12) {
      this.form.ngaY_KT = new Date(fromYear + 1, tempMonth - 12, fromDate + 1)
        .toISOString()
        .substring(0, 10)
    } else {
      this.form.ngaY_KT = new Date(fromYear, tempMonth, fromDate + 1)
        .toISOString()
        .substring(0, 10)
    }
    this.ngaY_KT_Text = JSON.parse(JSON.stringify(this.form.ngaY_KT));
    this.isWebview ? this.form.ngaY_KT = moment(this.form.ngaY_KT).format('DD-MM-YYYY') : this.form.ngaY_KT = this.form.ngaY_KT;
    this.getFee()
  }

  onChangeBirthDay(birthday: any) {
    this.isWebview ? birthday = this._common.changeDateString(birthday) : birthday;
    const minBirth = new Date(this.currentYear - 65, this.currentMonth, this.currentDate)
    const maxBirth = new Date(this.currentYear - 18, this.currentMonth, this.currentDate + 1)
    const birthdayDate = new Date(birthday);
    if (birthdayDate >= minBirth && birthdayDate <= maxBirth) {
      this.wrongBirth = false
    }
    else {
      this.wrongBirth = true
    }
  }

  onChangeBirthDayP(birthday: any) {
    this.isWebview ? birthday = this._common.changeDateString(birthday) : birthday;
    const minBirth = new Date(this.currentYear - 70, this.currentMonth, this.currentDate)
    const maxBirth = new Date(this.currentYear - 1, this.currentMonth, this.currentDate + 1)
    const birthdayDate = new Date(birthday);
    if (birthdayDate >= minBirth && birthdayDate <= maxBirth) {
      this.wrongBirthP = false
    }
    else {
      this.wrongBirthP = true
    }
  }

  policyHandler() {
    this.dynamicVal = this.dynamic.find((item: any) => item.value === this.bill.goi)
    this.form.goisanpham = this.respons.find((item: any) => item.value === this.bill.goi)?.label
  }

  onController(e: number, f: any) {
    // If any input in any step has invalid, open modal notice "Please fill the form", then warning
    // If all input in any step hasn't invalid, fee next step
    switch (this.stepValue) {
      case 1:
        if (f.controls['goi']?.invalid || f.controls['thoihan']?.invalid || !this.bill.thoihan || f.controls['ngaY_HL']?.invalid
          || !this.validDate || this.fee.phi == '') {
          this._toast.show('Hãy nhập đúng phiếu thông tin !', { icon: 'error', classname: 'bg-danger text-light' });

          f.controls['goi']!.touched = true
          f.controls['thoihan']!.touched = true
          f.controls['ngaY_HL']!.touched = true
        } else this.stepValue += e
        break
      case 2:
        if (f.invalid || this.wrongBirth || !this.isValidCmt || !this.isValidCmtMain || this.fee.phi == '') {
          this._toast.show('Hãy nhập đúng phiếu thông tin !', { icon: 'error', classname: 'bg-danger text-light' });

          f.controls['cmt']!.touched = true
          f.controls['mobi']!.touched = true
          f.controls['ten']!.touched = true
          f.controls['nG_SINH']!.touched = true
          f.controls['gioi']!.touched = true
          f.controls['email']!.touched = true
          f.controls['dchi']!.touched = true
        } else {
          this.stepValue += e

          if (this.isWebview && e === 1) {
            this.onSaveClient()
          }
        }
        break
      case 3:
        this.stepValue += e
        break
      default:
        this._toast.show('Hãy nhập đúng phiếu thông tin !', { icon: 'error', classname: 'bg-danger text-light' });
    }
  }

  numberWithCommas(price: any) {
    return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }

  // If magioithieu.length < 1, set nguoiGioiThieu = ''
  getEmptyLauncher() {
    this.form.magioithieu = ''
    this.form.nguoiGioiThieu = ''
  }

  getOldFee() {
    this.save = 0

    this.fee.tongphi = this.fee.phi
  }

  getFee() {
    this.isPerson ? this.bill.canhan = true : this.bill.canhan = false;
    if (!(this.bill.thoihan && this.bill.goi)) {
      return
    }
    let payload = {
      canhan: this.bill.canhan,
      soluongnguoi: this.dsduocbaohiem.length == 0 ? 1 : this.dsduocbaohiem.length,
      goi: this.bill.goi
    }
    this.fee = { ...FEE_TEMPLATE }
    this._common.getFee(this.apiPath, payload).subscribe((data) => {
      this.fee = data
      this.payment.tongThanhToan = this.payment.tongphi = this.form.ttoan = data.strTong
      this.form.ttoan = this._common.numberWithCommas(this.form.ttoan)
      this.voucher.maVoucher ? this.getDiscount(this.voucher.maVoucher) : null
    });
  }

  // BAOHIEM20220824015137
  getDiscount(param: string) {
    this.voucher = {
      ...VOUCHER_TEMPLATE
    }
    this.appVoucher? (this.voucher.maVoucher = this.appVoucher, param=this.appVoucher) : null
    this.save = 0
    this.form.ttoan = this._common.numberWithCommas(this.fee.strTong)
    this.form.magioithieu = this.userCode ? this.userCode : this.form.magioithieu
    // this.form.nguoiGioiThieu = this.userCode? this.form.nguoiGioiThieu: ''
    if(param.includes('MGVOUCHER')){
      this.form.magioithieu = this.userCode = ''
      this.form.nguoiGioiThieu = ''
    }
    this.ma_nvAppVoucher = undefined
    if (!this.fee.phi) {
      this._toast.show('Chưa có phí để áp dụng voucher, hãy hoàn thành các thông tin!', { icon: 'error', classname: 'bg-danger text-light' });
    }
    this._common.getDiscount(param, this.fee.tongphi, this.code,this.form.user_Code, this.isWebview).subscribe((data) => {
      if (data.status) {
        this.voucher = data,
          this.save = data.save
        this.form.ttoan = data.newTongPhi
        data.ma_nv ? (this.form.magioithieu = data.ma_nv, this.getLauncher(this.form.magioithieu)) : null
        data.ma_nv ? this.ma_nvAppVoucher = data.ma_nv : null
        this.isTrueVoucher == true ? this.isTrueVoucher = true
          : (this.isTrueVoucher = true, this._toast.show('Dùng voucher thành công !', { icon: 'check_circle', classname: 'bg-success text-light' }))
      } else {
        this._toast.show('Dùng voucher thất bại! Hãy kiểm tra lại.', { icon: 'error', classname: 'bg-danger text-light' });
        this.isTrueVoucher = false
      }
    })
  }

  getLauncher(param: string) {
    param = param?.trim()
    this.form.nguoiGioiThieu = ''
    this.isTrueLauncher = false;
    if (param && param.includes('A') && param.length == 9) {
      this.ApiService.getLauncher(param).subscribe((data) => {
        this.form.nguoiGioiThieu = data?.ho_ten
        data?.ho_ten ? (this.form.user_Code = this.payment.user_Code = param, this.isTrueLauncher = true) : null
      })
    }
    else if (param && !param.includes('A') && param.length == 10) {
      this.ApiService.getLauncherByPhone(param).subscribe((data) => {
        this.form.nguoiGioiThieu = data[0]?.ho_ten
        data[0]?.ho_ten ? (this.form.user_Code = this.payment.user_Code = data[0]?.ma_nv, this.isTrueLauncher = true) : null
      })
    }
  }


  openCentered(content: TemplateRef<any>) {
    this.modalService.open(content, { centered: true })
  }

  openImageModal(content: TemplateRef<any>) {
    this.modalService.open(content, {
      size: window.innerWidth > 500 ? 'xl' : 'lg',
    })
  }

  openAddModal(content: TemplateRef<any>) {
    this.modalService.open(content, {
      size: 'md',
    })
  }

  onCapitalize(number: number): void {
    if (number == 0) {
      this.form.ten = this._common.onCapitalize(this.form.ten)
    }
    if (number == 1) {
      this.person.name = this._common.onCapitalize(this.person.name)
    }
  }

  onNumberIn(number: number) {
    if (number == 0) {
      this.isNumberIn = false;
      this.isNumberIn = this._common.onNumberIn(this.form.ten)
    }
    if (number == 1) {
      this.isNumberIn = false
      this.isNumberIn1 = this._common.onNumberIn(this.person.name)
    }
  }

  getBack() {
    this.modalService.dismissAll()

    this.isPayment = false
    this.setIsPayment.emit(this.isPayment)
    // Hàm getBack sẽ ghi lại isPayment bằng false, đồng thời thông qua EventEmitter bắn value isPayment này ra component cha Product

    this.isBack = false
  }

  // autoFill(){
  //   if(this.person.moiquanhe === '1'){
  //     this.person.name = this.form.ten
  //     this.person.cmt = this.form.cmt
  //     this.person.gioitinh = this.form.gioi
  //     this.person.ngaysinh = this.form.nG_SINH
  //   }
  //   else{
  //     this.person.name = ''
  //     this.person.cmt = ''
  //     this.person.gioitinh = ''
  //     this.person.ngaysinh = ''
  //   }
  // }

  editHandler(row: any, content: TemplateRef<any>) {
    this.loadP(row)
    this.modalService.open(content, { size: 'md', centered: true, scrollable: true });
  }

  checkPersonsListLength() {
    if (this.isPerson == false) {
      this.dsduocbaohiem.splice(4)
    }
  }

  checkPerson() {
    this.isValidCmt = true;
    for (let i = 0; i < this.dsduocbaohiem.length; i++) {
      if (this.dsduocbaohiem[i]?.cmt == this.person.cmt) {
        this.isValidCmt = false;
        return
      }
      else {
        this.isValidCmt = true;
      }
    }
  }

  checkPersonMain() {
    this.isValidCmtMain = true;
    for (let i = 1; i < this.dsduocbaohiem.length; i++) {
      if (this.dsduocbaohiem[i]?.cmt == this.form.cmt) {
        this.isValidCmtMain = false;
        return
      }
      else {
        this.isValidCmtMain = true;
      }
    }
  }

  addSelf() {
    let payload = {
      moiquanhe_label: "PRODUCTS.REL1",
      moiquanhe: 'Bản thân',
      gioitinh_label: this.genders.find((i: any) => i.value === this.form.gioi)?.label,
      gioitinh: this.form.gioi,
      name: this.form.ten,
      ngaysinh: this.form.nG_SINH,
      cmt: this.form.cmt
    }
    this.dsduocbaohiem[0] = payload
  }

  addP() {
    this.checkPerson()
    let payload = {
      ...this.person,
      gioitinh_label: this.genders.find((i: any) => i.value === this.person.gioitinh)?.label,
      moiquanhe_label: this.moiquanhe.find((i: any) => i.value === this.person.moiquanhe)?.label
    }
    this.dsduocbaohiem.push(payload);
    this.person = {
      moiquanhe: "",
      moiquanhe_label: "",
      gioitinh: "",
      gioitinh_label: "",
      name: "",
      ngaysinh: "",
      cmt: ""
    }
  }

  loadP(i: any) {
    this.person.moiquanhe = this.dsduocbaohiem[i].moiquanhe
    this.person.gioitinh = this.dsduocbaohiem[i].gioitinh
    this.person.name = this.dsduocbaohiem[i].name
    this.person.ngaysinh = this.dsduocbaohiem[i].ngaysinh
    this.person.cmt = this.dsduocbaohiem[i].cmt
    this.idEdit = i
  }

  editP() {
    let moiquanhe_label = this.moiquanhe.find((i: any) => i.value === this.person.moiquanhe)?.label
    let gioitinh_label = this.genders.find((i: any) => i.value === this.person.gioitinh)?.label
    this.dsduocbaohiem[this.idEdit].moiquanhe = this.person.moiquanhe
    this.dsduocbaohiem[this.idEdit].gioitinh = this.person.gioitinh
    this.dsduocbaohiem[this.idEdit].name = this.person.name
    this.dsduocbaohiem[this.idEdit].ngaysinh = this.person.ngaysinh
    this.dsduocbaohiem[this.idEdit].cmt = this.person.cmt
    this.dsduocbaohiem[this.idEdit].moiquanhe_label = moiquanhe_label
    this.dsduocbaohiem[this.idEdit].gioitinh_label = gioitinh_label
  }

  deletePerson(i: any) {
    this.dsduocbaohiem.splice(i, 1)
  }

  onSubmit(f: any) {
    if (f.invalid || this.wrongBirth || !this.validDate || !this.isValidCmt || !this.isValidCmtMain || this.fee.phi == '') {
      this._toast.show('Hãy nhập đúng phiếu thông tin !', { icon: 'error', classname: 'bg-danger text-light' });

      f.controls['cmt']!.touched = true
      f.controls['mobi']!.touched = true
      f.controls['ten']!.touched = true
      f.controls['nG_SINH']!.touched = true
      f.controls['gioi']!.touched = true
      f.controls['email']!.touched = true
      f.controls['dchi']!.touched = true
    } else {
      this.submited = true
      let nguoI_DS = []
      for (let i = 0; i < this.dsduocbaohiem.length; i++) {
        let nguoiI = {
          dS_TEN: this.dsduocbaohiem[i].name,
          dS_NG_SINH: this.dsduocbaohiem[i].ngaysinh,
          dS_GIOI: this.dsduocbaohiem[i].gioitinh,
          dS_CMT: this.dsduocbaohiem[i].cmt,
          dS_MOBI: '',
          dS_EMAIL: '',
          dS_MOI_QUAN_HE: this.dsduocbaohiem[i].moiquanhe
        }
        nguoI_DS.push(nguoiI);
      }
      const totalFee = this.fee.tongphi as string
      const totalPay = this.form.ttoan as string
      this.payment.tongThanhToan = parseFloat(totalPay.replace(/,/g, '')).toString()
      this.payment.tongphi = parseFloat(totalFee.replace(/,/g, '')).toString()
      let payload = {
        ...this.form,
        ...this.payment,
        ...this.bill,
        webview: this.isWebview.toString(),
        email: this.form.email || '',
        gioitinh: this.genders.find((i: any) => i.value === this.form.gioi)?.label,
        nG_SINH: this.isWebview ? this._common.changeDateString(this.form.nG_SINH) : this.form.nG_SINH,
        ngaY_HL: this.isWebview ? this.ngaY_HL_Text : this.form.ngaY_HL,
        ngaY_KT: this.isWebview ? this.ngaY_KT_Text : this.form.ngaY_KT,
        goibaohiem: this.respons.find((i: any) => i.value === this.bill.goi)?.label_api,
        goisanpham: this.respons.find((i: any) => i.value === this.bill.goi)?.label_api,
        moiquanhe: (this.bill.canhan == true) ? 'Cá nhân' : 'Gia đình 4 người',
        canhan: this.bill.canhan,
        soluongnguoi: this.dsduocbaohiem.length,
        nguoI_DS: nguoI_DS,
        voucher: this.voucher.maVoucher
      }
      let formValue = Object.assign(payload)

      localStorage.setItem('form-data', JSON.stringify(formValue))


      this.ApiService.postPayment({ ...payload, moiquanhe: (this.bill.canhan == true) ? 'Cá nhân' : 'Gia đình 4 người', soluongnguoi: this.dsduocbaohiem.length, nguoI_DS: nguoI_DS }).subscribe((res) => {
        if (this.isApp) {
          // Gửi message vào app

          window.postMessage(JSON.stringify({ type: 'getLocalStorage' }));
          const submitButton = document.getElementById('submit');
          submitButton && submitButton.setAttribute('data-ttoan', this.payment.tongThanhToan || '0')
          window.location.href = 'webview/notification/app'
        } else {
          res.paymentUrl && (window.location.href = res.paymentUrl)
        }
        // res.paymentUrl && (window.location.href = res.paymentUrl)
        this.submited = false
      })

    }
  }

  onSaveClient() {
    let nguoI_DS = []
    for (let i = 0; i < this.dsduocbaohiem.length; i++) {
      let nguoiI = {
        dS_TEN: this.dsduocbaohiem[i].name,
        dS_NG_SINH: this.dsduocbaohiem[i].ngaysinh,
        dS_GIOI: this.dsduocbaohiem[i].gioitinh,
        dS_CMT: this.dsduocbaohiem[i].ngaysinh,
        dS_MOBI: '',
        dS_EMAIL: '',
        dS_MOI_QUAN_HE: this.dsduocbaohiem[i].moiquanhe
      }
      nguoI_DS.push(nguoiI);
    }
    const totalFee = this.fee.tongphi as string
    const totalPay = this.form.ttoan as string
    this.payment.tongThanhToan = parseFloat(totalPay.replace(/,/g, '')).toString()
    this.payment.tongphi = parseFloat(totalFee.replace(/,/g, '')).toString()
    let payload = {
      ...this.payment,
      user_Code: this.form.user_Code || this.payment.user_Code || '',
      hoten: this.form.ten.trim(),
      ngaysinh: this.isWebview ? this._common.changeDateString(this.form.nG_SINH) : this.form.nG_SINH,
      ngaytao: this.now,
      diachi: this.form.dchi.trim(),
      sodienthoai: this.form.mobi,
      cccd: this.form.cmt,
      email: this.form.email,
      sanPham: this.form.tensanpham,
      thietBi: 'app',
      loaibaohiem: this.form.tensanpham,
      goibaohiem: this.respons.find((i: any) => i.value === this.bill.goi)?.label_api,
      thoihan: this.durations.find((i: any) => i.value == this.bill.thoihan)?.value,
      thoigianthanhtoan: this.now,
      webview: this.isWebview.toString(),
      gioitinh: this.genders.find((i: any) => i.value === this.form.gioi)?.label_api,
      moiquanhe: (this.bill.canhan == true) ? 'Cá nhân' : 'Gia đình 4 người',
      soluong: this.bill.soluong,
      canhan: this.bill.canhan,
      soluongnguoi: this.dsduocbaohiem.length,
      nguoI_DS: nguoI_DS
    }

    this.ApiService.postPayment(payload).subscribe(res => {
      this.payment.idDangKy = this.form.iddangky = res.idDangKy
    })
  }

  pattern = VALID
  error = ERROR
  steps = BHTCNV_STEPS
}
